import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import { convertDate } from "helper/convertDate";

import MDButton from "components/MDButton";
import { useActionLike } from "context/content";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItemButton,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import Tiktok from "assets/images/tik-tok.png";
import Iconlks from "assets/icon-lks.png";

const Demo = styled("div")(() => ({
  backgroundColor: "#eeeef",
}));

export default function LKSGabunganCard({ data }) {
  const navigate = useNavigate();
  const { loading, onProcess } = useActionLike();
  return (
    <Grid item xs={12} sm={4} md={3}>
      {/* <Box
        sx={{
          flexGrow: 1,
          maxWidth: 752,
          boxShadow: 5,
          borderRadius: "8px",
          m: 0.5,
        }}
      > */}
      <Card
        sx={{
          display: "flex",
          p: 1,
          m: 1,
          alignItems: "center",
        }}
      >
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            "& .MuiListItem-root": {
              flexDirection: { xs: "column", sm: "column", md: "columns" },
            },
          }}
        >
          <ListItem alignItems={{ xs: "center", sm: "center", md: "flex-start" }}>
            <ListItemAvatar sx={{ textAlign: "-webkit-center" }}>
              <Avatar>
                <Box
                  component="img"
                  sx={{
                    height: "50%",
                    width: "50%",
                  }}
                  alt="JualKirim Logo"
                  src={Tiktok}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{
                "& .MuiListItemText-primary": {
                  fontSize: "1rem",
                  textAlign: "center",
                },
              }}
              primary={
                data.thumbnail?.tiktok_account[0] === "@"
                  ? data.thumbnail?.tiktok_account
                  : `@${data.thumbnail?.tiktok_account}`
              }
              secondary={
                <React.Fragment>
                  <Typography component="span" variant="body2" color="text.primary">
                    {`${convertDate(data.updatedAt, "bebas")}`}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemButton
              contained
              variant="contained"
              sx={{
                background: "#bd454f",
                borderRadius: "8px",
                alignSelf: "center",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#bd454f",
                },
                height: "100%",
                width: { xs: "50%" },
                maxWidth: { xs: "50%", sm: "100px", md: "100px" },
              }}
              onClick={() => {
                Swal.fire({
                  imageUrl: Iconlks,
                  imageWidth: 150,
                  imageHeight: 45,
                  text: "Ditonton sampai habis ya..\njangan lupa like,komen, & share",
                  confirmButtonColor: "#BD454F",
                  confirmButtonText: "Lanjutkan ke Tiktok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    onProcess(data.id);
                    Swal.fire("Berhasil!", "LKS Berhasil.", "success");
                    navigate("/dashboard");
                    window.open(data.url_video, "_blank");
                  }
                });
              }}
            >
              LKS
            </ListItemButton>
          </ListItem>
        </List>
      </Card>
      {/* </Box> */}
    </Grid>
  );
}
