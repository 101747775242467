import * as React from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

// @mui material components
import ImageList from "@mui/material/ImageList";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Icon
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, ImageListItem, ImageListItemBar, Skeleton, Typography } from "@mui/material";
import Logo from "assets/images/logos/jualkirim.png";
import NoImages from "assets/images/image-not-available.png";
import { useGetThumbnail } from "context/content";
import { useState } from "react";

function LKSSendiri() {
  const sm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const md = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [pagination, setPagination] = useState(1);

  const { onProcess, loading, data, res } = useGetThumbnail();

  React.useEffect(() => {
    Swal.fire({
      title: "Mohon Tunggu Sebentar...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: async () => {
        Swal.showLoading();

        const result = await onProcess(pagination);

        if (result === "ok") {
          Swal.close();
        } else {
          Swal.close();
        }
      },
    });
  }, [onProcess, pagination]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox id="delete-account" mt={2}>
        <MDBox p={2}>
          <MDTypography variant="h5" fontWeight="medium">
            <MDButton
              variant="outlined"
              size="small"
              color="primary"
              component={NavLink}
              to="/dashboard"
              iconOnly
            >
              <ArrowBackIcon />
            </MDButton>
            &nbsp;&nbsp; Bantuan LKS
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox textAlign="center">
        <ImageList sx={{ width: "100%" }} cols={sm ? (md ? 4 : 3) : 2}>
          {loading ? (
            //skeletonn
            <>
              <ImageListItem>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  sx={{ height: { xs: 301, md: 658 } }}
                />
                <Skeleton />
                <Skeleton width="60%" />
              </ImageListItem>
              <ImageListItem>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  sx={{ height: { xs: 301, md: 658 } }}
                />
                <Skeleton />
                <Skeleton width="60%" />
              </ImageListItem>
              <ImageListItem>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  sx={{ height: { xs: 301, md: 658 } }}
                />
                <Skeleton />
                <Skeleton width="60%" />
              </ImageListItem>
              <ImageListItem>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  sx={{ height: { xs: 301, md: 658 } }}
                />
                <Skeleton />
                <Skeleton width="60%" />
              </ImageListItem>
            </>
          ) : res.message || data.yourLKS?.length === 0 ? (
            <ImageListItem sx={{ minWidth: { xs: "114px" }, background: "white" }}>
              <img src={`${NoImages}`} alt="noImages" loading="lazy" />
              <ImageListItemBar title={res.message ? res.message : "No Data"} position="below" />
            </ImageListItem>
          ) : (
            data.yourLKS?.map((item) => (
              <ImageListItem
                key={item.id}
                onClick={() => {
                  window.open(item.url_video, "_blank");
                }}
                sx={{
                  minWidth: {
                    xs: "114px",
                    margin: "8px",
                    "& .MuiImageListItemBar-positionTop": {
                      borderRadius: "8px",
                    },
                  },
                  background: "white",
                  borderRadius: "8px",
                  boxShadow: 5,
                }}
              >
                <ImageListItemBar
                  sx={{
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                  }}
                  actionIcon={
                    <Box
                      component="img"
                      sx={{
                        height: { xs: 25, md: 35 },
                        width: { xs: 50, md: 70 },
                      }}
                      alt="JualKirim Logo"
                      src={Logo}
                    />
                  }
                  position="top"
                  actionPosition="right"
                />
                {item?.thumbnail?.source ? (
                  <img
                    src={`${item?.thumbnail?.source}`}
                    alt={item.id}
                    loading="lazy"
                    style={{ borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}
                  />
                ) : (
                  <Skeleton />
                )}
                <ImageListItemBar
                  title={`@${
                    item?.thumbnail?.tiktok_account ? item?.thumbnail?.tiktok_account : ""
                  }`}
                  subtitle={
                    <React.Fragment>
                      <Typography variant="caption" component="span" sx={{ fontSize: "0.66rem" }}>
                        Jumlah Dibantu : {item.Likes?.length ? item.Likes?.length : 0}
                      </Typography>
                    </React.Fragment>
                  }
                  position="below"
                  sx={{ fontSize: "0.92rem", fontWeight: "bold", lineHeight: "20px", ml: 1 }}
                />
              </ImageListItem>
            ))
          )}
        </ImageList>
        <MDBox display="flex" justifyContent="center">
          <MDButton
            sx={{
              width: "60px",
              mt: 2,
              background: "linear-gradient(to left top, #bd454f, #343333)",
              color: "#ffffff",
            }}
            disabled={pagination === 1}
            onClick={() => {
              setPagination(pagination - 1);
            }}
          >
            Back
          </MDButton>
          <MDTypography
            sx={{
              width: "60px",
              mt: 2,
              mx: 2,
              alignSelf: "center",
            }}
          >
            {pagination}/{data.totalPage ? data.totalPage : 1}
          </MDTypography>
          <MDButton
            sx={{
              width: "60px",
              mt: 2,
              background: "linear-gradient(to left top, #bd454f, #343333)",
              color: "#ffffff",
            }}
            disabled={pagination >= data.totalPage}
            onClick={() => {
              setPagination(pagination + 1);
            }}
          >
            Next
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default LKSSendiri;
