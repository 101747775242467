import x from "axios";

const gtw = x.create({
  // baseURL: process.env.REACT_APP_GTW || 'http://localhost:3003',
  // baseURL: "http://localhost:3003",
  baseURL: "https://api-bd.unsircle.id",
  // baseURL: "http://192.168.18.12:3003",
});

export default gtw;
