import { useContext, useState, useCallback, useMemo } from "react";
import x from "axios";
import gtw from "./apis";
import { AuthContext } from "./utils/auth";
import { GlobalContext } from "./utils/global";
import { convertDataToQuery } from "../helper/custom";
import { handleError, useCancelInterval, useCancelRequest } from "./helpers";
import { useForm } from "../components/spam";

export const useCount = () => {
  const [request, setRequest] = useState(null),
    [interval, setIntervals] = useState(null),
    { headers } = useContext(AuthContext),
    { notif, setNotif } = useContext(GlobalContext),
    onProcess = useCallback(async () => {
      async function fetchData() {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          // const { data } = await gtw({ method: "get", url: `/kol/logs/true/un-read`, headers });
          // setNotif(data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return;
        }
      }
      await fetchData();
      setIntervals(
        setInterval(async () => {
          await fetchData();
        }, 15 * 60 * 1000)
      );
    }, [headers, setNotif]);

  useCancelRequest(request);
  useCancelInterval(interval);

  return { onProcess, notif };
};

export const useListLogs = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    [data, setData] = useState({ count: 0, data: [] }),
    [query, setQuery] = useState({ offset: 0, limit: 20, orderBy: "create_date", sortBy: "desc" }),
    { headers } = useContext(AuthContext),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          async function getSellerId(sellerId) {
            return new Promise((resolve) => {
              gtw({ method: "get", url: `/content/seller/${sellerId}`, headers })
                .then(({ data }) => resolve(data.data || null))
                .catch((err) => resolve(null));
            });
          }
          const _req = x.CancelToken.source();
          setRequest(_req);
          let _query = { ...query };
          if (_query.limit) delete _query.limit;
          if (_query.offset) delete _query.offset;
          // const { data: _data } = await gtw({
          //   method: "get",
          //   url: `/kol/logs/true/list/${query.offset}/${query.limit}${convertDataToQuery(_query)}`,
          //   headers,
          //   cancelToken: _req.token,
          // });
          // if (_data.data?.length > 0) {
          //   let newData = [],
          //     dataSeller = [];

          //   for (const notif of _data.data) {
          //     if (!notif.seller_id) {
          //       newData.push(notif);
          //       continue;
          //     }

          //     if (dataSeller.length === 0) {
          //       const seller = await getSellerId(notif.seller_id);
          //       dataSeller.push({ ...seller, id: notif.seller_id });
          //       newData.push({ ...notif, seller });
          //     } else {
          //       const getSeller = await (async () => {
          //         const getOne = dataSeller.find((el) => el.id === notif.seller_id);
          //         if (getOne && getOne.id) return getOne;
          //         const seller = await getSellerId(notif.seller_id);
          //         dataSeller.push({ ...seller, id: notif.seller_id });
          //         return seller;
          //       })();
          //       newData.push({ ...notif, seller: getSeller });
          //     }
          //   }
          //   if (query.offset === 0) return setData({ ..._data, data: newData });

          //   return setData((prev) => ({ ...prev, data: [...prev.data, ...newData] }));
          // }
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, query]),
    hasMore = useMemo(() => {
      const _count = Math.floor(data.count / query.limit);
      return query.offset < _count;
    }, [query.offset, query.limit, data.count]),
    selectItems = useMemo(() => {
      if (data.data.length === 0) return [];
      return data.data.filter((el) => el.isChecked);
    }, [data.data]),
    updateOne = ({ id, key, value, payload }) =>
      setData((prev) => ({
        ...prev,
        data: prev.data.map((el) => {
          if (el.id === id)
            return payload !== undefined ? { ...el, ...payload } : { ...el, [key]: value };
          return el;
        }),
      })),
    updateAll = ({ key, value, payload }) =>
      setData((prev) => ({
        ...prev,
        data: prev.data.map((el) =>
          payload !== undefined ? { ...el, ...payload } : { ...el, [key]: value }
        ),
      }));

  useCancelRequest(request);

  return { onProcess, loading, res, setQuery, selectItems, data, hasMore, updateOne, updateAll };
};

export const useReadLogs = () => {
  const [request, setRequest] = useState(null),
    { notif, setNotif } = useContext(GlobalContext),
    { headers } = useContext(AuthContext),
    onProcess = async (log_id) => {
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        // const { data } = await gtw({
        //   method: "patch",
        //   url: `/kol/logs/true/read/${log_id}`,
        //   headers,
        //   cancelToken: _req.token,
        // });
        // if (data.message) {
        //   setNotif(notif - 1);
        //   return true;
        // }
        return false;
      } catch (e) {
        return false;
      }
    };
  useCancelRequest(request);
  return { onProcess };
};

export const useCreateRating = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    { setRes } = useContext(GlobalContext),
    { data, onChange, clear } = useForm({ rate_int: 0, comments: "" }),
    onProcess =
      (callback = undefined) =>
      async (e) => {
        setLoading(true);
        const cancel = await (async () => {
          try {
            const req = x.CancelToken.source();
            setRequest(req);
            const { data: _data } = await gtw({
              method: "post",
              url: "/kol/rating",
              data,
              headers,
              cancelToken: req.token,
            });
            if (_data.id) {
              clear();
              if (callback) callback();
              return setRes({ status: "ok", message: "Terima kasih telah memberikan feedback" });
            }
            setRes({
              status: "error",
              message: "tidak ada respon dari server, silahkan coba lagi beberapa saat lagi",
            });
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoading(false);
      };
  useCancelRequest(request);
  return { onProcess, data, onChange, loading };
};
