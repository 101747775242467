import { createContext, useReducer, useCallback } from "react";
import jwtDecoded from "jwt-decode";

const defaultAuth = {
  id: null,
  full_name: "",
  email: "",
  sosmed: {
    tiktok: "",
  },
  phone_number: "",
  followers: {
    man: 0,
    woman: 0,
  },
  gender: "",
  how_sell: "",
  count_content: 0,
  paid: false,
  categories: [],
  verification: {
    email: false,
    whatsapp: false,
  },
  create_date: null,
  update_date: null,
  address: [],
  konsep_video: {},
  ranking: {},
};

let initialState = {
  headers: {
    Authorization: "",
  },
  auth: defaultAuth,
};

const token = localStorage.getItem("token");
if (token) {
  try {
    const decode = jwtDecoded(token);
    if (decode.exp && decode.exp * 1000 <= Date.now()) localStorage.removeItem("token");
    else
      initialState = {
        ...initialState,
        auth: {
          ...initialState.auth,
          ...decode,
          categories: decode?.categories?.length > 3 ? ["Semua Kategory"] : decode.categories,
        },
        headers: { Authorization: `Bearer ${token}` },
      };
  } catch (e) {
    console.error(e);
  }
}

const AuthContext = createContext({
  headers: {
    Authorization: "",
  },
  auth: defaultAuth,
  login: (token) => {},
  logout: (_) => {},
  setAddress: (payload = []) => {},
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        ...action.payload,
        auth: {
          ...action.payload.auth,
          address: [],
          categories:
            action.payload.auth?.categories?.length > 3
              ? ["Semua Kategory"]
              : action.payload.auth.categories || [],
        },
      };
    case "LOGOUT":
      return { ...state, headers: { Authorization: "" }, auth: defaultAuth };
    case "SET_ADDRESS":
      return { ...state, auth: { ...state.auth, address: action.payload } };
    case "SET_RANKING":
      return { ...state, auth: { ...state.auth, ranking: action.payload } };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, {
      ...initialState,
      setAddress: (payload) => null,
      login: (token) => null,
      logout: (_) => null,
    }),
    login = (token) => {
      if (!token) throw new Error("tidak ada token dari server");

      const decoded = jwtDecoded(token);
      localStorage.setItem("token", token);
      if (decoded && decoded.id)
        return dispatch({
          type: "LOGIN",
          payload: {
            headers: { Authorization: `Bearer ${token}` },
            auth: { ...decoded },
          },
        });

      localStorage.removeItem("token");
      dispatch({ type: "LOGOUT", payload: null });
    },
    logout = (_) => {
      localStorage.removeItem("token");
      dispatch({ type: "LOGOUT", payload: null });
    },
    setRanking = (payload) => {
      dispatch({ type: "SET_RANKING", payload });
    },
    setAddress = useCallback((payload) => dispatch({ type: "SET_ADDRESS", payload }), [dispatch]);
  return (
    <AuthContext.Provider value={{ ...state, login, logout, setAddress, setRanking }} {...props} />
  );
}

export { AuthContext, AuthProvider };
