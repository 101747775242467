import PropTypes from "prop-types";

import { useContext, useEffect, useState, Fragment, useCallback } from "react";
import {
  Snackbar,
  Alert,
  IconButton,
  Pagination,
  Stack,
  Divider,
  TextField,
  CircularProgress,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Sheet from "react-modal-sheet";
import { GlobalContext } from "../context/utils/global";

export function BdPagination({ page, onChange = () => null, total }) {
  return <Pagination style={{ padding: 10 }} count={total} page={page} onChange={onChange} />;
}

BdPagination.propTypes = {
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export function UIAlertGlobal() {
  const { res, clear } = useContext(GlobalContext);

  if (!res.status || !res.message) return null;

  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      open
      autoHideDuration={res.autoHide || 10000}
      onClose={clear}
    >
      <Alert severity={res.status === "ok" ? "success" : res.status}>{res.message}</Alert>
    </Snackbar>
  );
}

export function UIAlert({
  open,
  onClose,
  autoHideDuration = 3000,
  res = { status: "", message: "" },
}) {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={() => onClose(false)}
    >
      <Alert severity={res.status === "ok" ? "success" : res.status}>{res.message}</Alert>
    </Snackbar>
  );
}

UIAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number.isRequired,
  res: PropTypes.objectOf(PropTypes.string).isRequired,
};

export function SafeArea({ height = 10 }) {
  return <div style={{ height, width: "100%" }} />;
}

export function SheetModal({ open = false, close, snapPoints = [350], children }) {
  return (
    <Sheet rootId="root" isOpen={open} onClose={() => close(false)} snapPoints={snapPoints}>
      <Sheet.Container>
        <Sheet.Header>
          <IconButton
            style={{ position: "absolute", top: 0, right: 0, zIndex: 9 }}
            onClick={() => close(false)}
            size="small"
            variant="standard"
            color="primary"
          >
            <CloseOutlined />
          </IconButton>
        </Sheet.Header>
        <Sheet.Content
          style={{
            position: "relative",
            margin: 5,
            display: "flex",
            width: "auto",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <SafeArea height={30} />
          {children}
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}

export function useForm(payload, depedencies = undefined) {
  const [data, setData] = useState(payload);
  const onChange = useCallback(
    ({ target: { name = "", value = "", payload = undefined } }) =>
      setData((prev) =>
        payload === undefined ? { ...prev, [name]: value } : { ...prev, ...payload }
      ),
    []
  );

  useEffect(() => {
    if (depedencies && depedencies.length > 0) setData(payload);
  }, [depedencies, payload]);

  return { onChange, data, clear: () => setData(payload) };
}

export function SearchAutoComplete({ loading, onSelectItem, value, onChange, options, close }) {
  return (
    <Stack style={{ position: "relative", width: "100%", zIndex: 5 }}>
      {(value.length > 0 || loading) && (
        <div
          style={{ zIndex: 5, position: "fixed", top: 0, left: 0, width: "100%", height: "100%" }}
          onClick={close}
        />
      )}
      <TextField
        value={value}
        style={{ zIndex: 7 }}
        onChange={onChange}
        margin="dense"
        label="Kota / Provinsi / Kecamatan"
        placeholder="cari disini"
        size="small"
      />

      {options.length > 0 || loading ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: 55,
            boxShadow: "0px 0px 2px 5px rgba(0, 0, 0, 0.25)",
            borderRadius: 5,
            backgroundColor: "white",
            height: 300,
            overflow: "auto",
            zIndex: 9,
          }}
        >
          {loading ? (
            <CircularProgress size={25} />
          ) : options.length > 0 ? (
            options.map((option) => (
              <Fragment key={option.index}>
                <li
                  style={{ display: "inline-block", cursor: "pointer", marginTop: 2 }}
                  onClick={onSelectItem(option)}
                >
                  {option.index}. {option.district_name || ""} {option.subdistrict_name || ""},
                  <br />
                  &nbsp;{option.city_name || ""} {option.province_name || ""},{" "}
                  {option.zip_code || "0"}
                </li>
                <Divider />
              </Fragment>
            ))
          ) : null}
        </div>
      ) : null}
    </Stack>
  );
}
