// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import Header from "layouts/profile/components/Header";

// Data
import MDButton from "components/MDButton";
import { useUpdateProfile } from "context/auth";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "context/utils/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { CardHeader, Checkbox, CircularProgress } from "@mui/material";
import { getQueryUrl } from "helper/custom";

// Images

export default function KonsepVideo() {
  const { data, onChange, loading, onProcess } = useUpdateProfile();
  const { auth } = useContext(AuthContext);
  const { state, search } = useLocation();
  const push = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mb={3}>
          <Grid
            container
            height="auto"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Grid item sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <MDBox pt={4} pb={3} px={1}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDTypography variant="h3">Konsep Video</MDTypography>
                    <MDTypography variant="caption">
                      silahkan pilih Konsep Video Untuk akun anda
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={0.5}>
                    <CardHeader
                      sx={{ px: 2, py: 1 }}
                      avatar={
                        <Checkbox
                          variant="outlined"
                          checked={data.konsep_video.ads}
                          onChange={({ target: {} }) => {
                            onChange({
                              target: {
                                name: "konsep_video",
                                value: { ...data.konsep_video, ads: !data.konsep_video.ads },
                              },
                            });
                          }}
                        />
                      }
                      title={<MDTypography variant="h6">ADS</MDTypography>}
                      subheader={
                        <MDTypography variant="overline">
                          <a
                            href="https://www.tiktok.com/@mursid241/video/7177642491322141978?is_copy_url=1&is_from_webapp=v1"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Contoh Video
                          </a>
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  <MDBox mb={0.5}>
                    <CardHeader
                      sx={{ px: 2, py: 1 }}
                      avatar={
                        <Checkbox
                          variant="outlined"
                          checked={data.konsep_video.storyTelling}
                          onChange={({ target: {} }) => {
                            onChange({
                              target: {
                                name: "konsep_video",
                                value: {
                                  ...data.konsep_video,
                                  storyTelling: !data.konsep_video.storyTelling,
                                },
                              },
                            });
                          }}
                        />
                      }
                      title={<MDTypography variant="h6">Story Telling</MDTypography>}
                      subheader={
                        <MDTypography variant="overline">
                          <a
                            href="https://vt.tiktok.com/ZS8jqc3ka/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Contoh Video
                          </a>
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  <MDBox mb={0.5}>
                    <CardHeader
                      sx={{ px: 2, py: 1 }}
                      avatar={
                        <Checkbox
                          variant="outlined"
                          checked={data.konsep_video.duet}
                          onChange={({ target: {} }) => {
                            onChange({
                              target: {
                                name: "konsep_video",
                                value: { ...data.konsep_video, duet: !data.konsep_video.duet },
                              },
                            });
                          }}
                        />
                      }
                      title={<MDTypography variant="h6">Duet</MDTypography>}
                      subheader={
                        <MDTypography variant="overline">
                          <a
                            href="https://vt.tiktok.com/ZS8jqHj2b/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Contoh Video
                          </a>
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  <MDBox mb={0.5}>
                    <CardHeader
                      sx={{ px: 2, py: 1 }}
                      avatar={
                        <Checkbox
                          variant="outlined"
                          checked={data.konsep_video.slideShow}
                          onChange={({ target: {} }) => {
                            onChange({
                              target: {
                                name: "konsep_video",
                                value: {
                                  ...data.konsep_video,
                                  slideShow: !data.konsep_video.slideShow,
                                },
                              },
                            });
                          }}
                        />
                      }
                      title={<MDTypography variant="h6">Slide Show</MDTypography>}
                      subheader={
                        <MDTypography variant="overline">
                          <a
                            href="https://vt.tiktok.com/ZS8jqXbYE/"
                            // href="https://www.tiktok.com/@tiktokshop_lyna/video/7140653183847730459?_r=1&_t=8YWV04s4VDV&is_from_webapp=v1&item_id=7140653183847730459"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Contoh Video
                          </a>
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  <MDBox mb={0.5}>
                    <CardHeader
                      sx={{ px: 2, py: 1 }}
                      avatar={
                        <Checkbox
                          variant="outlined"
                          checked={data.konsep_video.freestyle}
                          onChange={({ target: {} }) => {
                            onChange({
                              target: {
                                name: "konsep_video",
                                value: {
                                  ...data.konsep_video,
                                  freestyle: !data.konsep_video.freestyle,
                                },
                              },
                            });
                          }}
                        />
                      }
                      title={<MDTypography variant="h6">Freestyle (Konten Bebas)</MDTypography>}
                      subheader={
                        <MDTypography variant="overline">
                          <a
                            href="https://vt.tiktok.com/ZS8jqj3w8/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Contoh Video
                          </a>
                        </MDTypography>
                      }
                    />
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="inherit"
                      sx={{
                        backgroundColor: "#bd454f",
                      }}
                      fullWidth
                      onClick={() => {
                        onProcess();
                        setTimeout(() => {
                          push(`/dashboard`);
                        }, 800);
                      }}
                    >
                      {loading ? <CircularProgress size={30} /> : "Simpan"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}
