export function convertDate(tanggal, type = "all") {
  let date = new Date(tanggal);
  // console.log(date.getDay());
  const jam = new Date(tanggal).toLocaleTimeString();

  const year = date.getFullYear();
  let nameOfTheMonth = date.getMonth() + 1;

  if (nameOfTheMonth === 1) nameOfTheMonth = "Jan";
  if (nameOfTheMonth === 2) nameOfTheMonth = "Feb";
  if (nameOfTheMonth === 3) nameOfTheMonth = "Maret";
  if (nameOfTheMonth === 4) nameOfTheMonth = "April";
  if (nameOfTheMonth === 5) nameOfTheMonth = "Mei";
  if (nameOfTheMonth === 6) nameOfTheMonth = "Juni";
  if (nameOfTheMonth === 7) nameOfTheMonth = "Juli";
  if (nameOfTheMonth === 8) nameOfTheMonth = "August";
  if (nameOfTheMonth === 9) nameOfTheMonth = "Sept";
  if (nameOfTheMonth === 10) nameOfTheMonth = "Okt";
  if (nameOfTheMonth === 11) nameOfTheMonth = "Nov";
  if (nameOfTheMonth === 12) nameOfTheMonth = "Des";

  const day = date.getDate();
  let nameOfTheDay = date.getDay();

  if (nameOfTheDay === 1) nameOfTheDay = "Senin";
  if (nameOfTheDay === 2) nameOfTheDay = "Selasa";
  if (nameOfTheDay === 3) nameOfTheDay = "Rabu";
  if (nameOfTheDay === 4) nameOfTheDay = "Kamis";
  if (nameOfTheDay === 5) nameOfTheDay = "Jum'at";
  if (nameOfTheDay === 6) nameOfTheDay = "Sabtu";
  if (nameOfTheDay === 0) nameOfTheDay = "Minggu";

  if (type === "all") {
    return `${nameOfTheDay}, ${day} ${nameOfTheMonth} ${year} ${jam.substring(0, 5)} WIB`;
  } else if (type === "number") {
    return `${day}/${date.getMonth() + 1}/${`${year}`.slice(-2)}`;
  } else {
    return `${nameOfTheDay}, ${day} ${nameOfTheMonth} ${year}`;
  }
}
