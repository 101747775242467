// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import Header from "layouts/profile/components/Header";

// Data
import MDButton from "components/MDButton";
import { useUpdateProfile } from "context/auth";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "context/utils/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { MultipleChipSelect } from "components/select-box";
import { categories } from "helper/values";
import MDInput from "components/MDInput";
import { getQueryUrl } from "helper/custom";

// Images

export default function Overview() {
  const { data, onChange, loading, onProcess } = useUpdateProfile();
  const { auth } = useContext(AuthContext);
  const primaryAddress = useMemo(() => {
    return auth.address.filter((el) => el.primary) || [];
  }, [auth.address]);
  const [show, setShow] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const { state, search } = useLocation();
  const [addPop, setAddPop] = useState(false);
  const push = useNavigate();
  const theme = useTheme();

  const getStyles = (name, personName, theme) => ({
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  });

  const handleChange = (event) => {
    const {
        target: { value },
      } = event,
      _value = typeof value === "string" ? value.split(",") : value,
      checkAllCategories = _value.findIndex((el) => el === "Semua Kategory");

    if (checkAllCategories !== -1) {
      onChange({ target: { value: ["Semua Kategory"], name: "categories" } });
      setOpenCategory(false);
      return;
    }

    if (_value.length > 3) return;
    onChange({ target: { value: _value, name: "categories" } });
    if (_value.length === 3) setOpenCategory(false);
  };

  useEffect(() => {
    if (search) {
      const { q } = getQueryUrl(search) || { q: "" };
      if (q === "address") setAddPop(true);
    } else setAddPop(false);
  }, [search]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mb={3}>
          <Grid
            container
            height="auto"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Grid item sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <MDBox pt={4} pb={3} px={1}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDTypography variant="h3">Profile</MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Nama Lengkap"
                      variant="standard"
                      fullWidth
                      required
                      onChange={onChange}
                      name="full_name"
                      value={data.full_name}
                      placeholder="eg: first lastname"
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Nomor HP"
                      variant="standard"
                      fullWidth
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MDTypography variant="caption" color="black" fontWeight="bolder">
                              +62
                            </MDTypography>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOutlined />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        const {
                          target: { value, name },
                        } = event;
                        if (!Number.isNaN(Number(value))) return onChange(event);

                        return onChange({ target: { value: "", name } });
                      }}
                      name="phone_number"
                      value={data.phone_number}
                      placeholder="eg: 82xxxxxx"
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      disabled
                      variant="standard"
                      placeholder="eg: jualkirim@example.com"
                      fullWidth
                      onChange={onChange}
                      name="email"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOutlined />
                          </InputAdornment>
                        ),
                      }}
                      value={data.email}
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <ListItemText
                      primary="Alamat Utama"
                      secondary={
                        primaryAddress.length > 0
                          ? `${primaryAddress[0]?.identity || "-"} - ${
                              primaryAddress[0]?.name || "-"
                            }`
                          : "-"
                      }
                    />
                    <MDButton
                      size="small"
                      variant="outlined"
                      color="primary"
                      disabled
                      onClick={(_) => push(`/profile/edit?q=address`)}
                      style={{ textTransform: "capitalize", marginTop: 5, marginBottom: 5 }}
                    >
                      Kelola Alamat
                    </MDButton>
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      required
                      name="old_password"
                      label="Password Lama"
                      variant="standard"
                      fullWidth
                      type={show ? "text" : "password"}
                      onChange={onChange}
                      value={data.old_password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              variant="standard"
                              onClick={() => setShow((prev) => !prev)}
                            >
                              {show ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="eg: old pass"
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      required
                      name="password"
                      label="Password"
                      variant="standard"
                      fullWidth
                      type={show ? "text" : "password"}
                      onChange={onChange}
                      value={data.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              variant="standard"
                              onClick={() => setShow((prev) => !prev)}
                            >
                              {show ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="eg: pass"
                    />
                  </MDBox>

                  <Box mb={2} sx={{ minWidth: 120 }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-label" sx={{ p: 0 }}>
                        Jenis Kelamin
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.gender}
                        onChange={onChange}
                        required
                        name="gender"
                      >
                        <MenuItem value="pria">Pria</MenuItem>
                        <MenuItem value="wanita">Wanita</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Username Tiktok"
                      variant="standard"
                      onChange={({ target: { value } }) =>
                        onChange({ target: { payload: { sosmed: { tiktok: value } } } })
                      }
                      placeholder="eg: Jual_Kirim"
                      fullWidth
                      value={data.sosmed.tiktok}
                    />
                  </MDBox>

                  <Box mb={2} sx={{ minWidth: 120 }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-label">Cara Jualan</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Cara Jualan"
                        name="how_sell"
                        value={data.how_sell}
                        onChange={onChange}
                      >
                        <MenuItem value="Live streaming">Live streaming</MenuItem>
                        <MenuItem value="Share Video Content">Share Video Content</MenuItem>
                        <MenuItem value="Live streaming,Share Video Content">Kedua-duanya</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box
                    mb={2}
                    sx={{
                      "& .MuiInputBase-root": { minHeight: "45.13px", mt: 2, pt: 1, pb: 1 },
                      "& .MuiFormLabel-root": { transformOrigin: "top", pl: "4px", pt: "12px" },
                    }}
                  >
                    <MultipleChipSelect
                      open={openCategory}
                      required
                      onClose={setOpenCategory}
                      onChange={handleChange}
                      value={data.categories || []}
                      label="Pilih 3 kategori"
                    >
                      {["Semua Kategory", ...categories].map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, data.categories, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </MultipleChipSelect>
                  </Box>

                  <FormControl fullWidth margin="dense" size="small">
                    <MDTypography variant="caption">Persentase Followers</MDTypography>

                    <Grid
                      item
                      display="flex"
                      alignItems="center"
                      justifyContent="space-around"
                      width="100%"
                    >
                      <TextField
                        size="small"
                        variant="standard"
                        required
                        style={{ width: "35%" }}
                        color="primary"
                        margin="dense"
                        label="Pria"
                        value={data.followers.man}
                        placeholder="eg: 80"
                        onChange={({ target: { value } }) => {
                          const checkValue = !Number.isNaN(Number(value));
                          onChange({
                            target: {
                              payload: {
                                followers: {
                                  man:
                                    checkValue && value && parseInt(value, 10) <= 100 ? value : "",
                                  woman:
                                    checkValue && value && parseInt(value, 10) <= 100
                                      ? `${100 - parseInt(value, 10)}`
                                      : "",
                                },
                              },
                            },
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <MDTypography fontWeight="bolder">%</MDTypography>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        size="small"
                        variant="standard"
                        color="primary"
                        margin="dense"
                        required
                        style={{ marginLeft: 20, width: "35%" }}
                        label="Wanita"
                        placeholder="eg: 20"
                        value={data.followers.woman}
                        onChange={({ target: { value } }) => {
                          const checkValue = !Number.isNaN(Number(value));
                          onChange({
                            target: {
                              payload: {
                                followers: {
                                  woman:
                                    checkValue && value && parseInt(value, 10) <= 100 ? value : "",
                                  man:
                                    checkValue && value && parseInt(value, 10) <= 100
                                      ? `${100 - parseInt(value, 10)}`
                                      : "",
                                },
                              },
                            },
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <MDTypography fontWeight="bolder">%</MDTypography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </FormControl>

                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Checkbox
                      checked={!data.paid}
                      onChange={({ target: { checked } }) =>
                        onChange({ target: { value: !checked, name: "paid" } })
                      }
                      sx={{
                        color: "#bd454f",
                        "&.Mui-checked": {
                          color: "#bd454f",
                        },
                      }}
                    />
                    <MDTypography
                      variant="caption"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Saya ingin menggunakan program &nbsp;&nbsp;gratis
                      <i style={{ color: "red" }}>*tidak wajib*</i>
                    </MDTypography>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="inherit"
                      sx={{
                        backgroundColor: "#bd454f",
                      }}
                      fullWidth
                      onClick={onProcess}
                    >
                      {loading ? <CircularProgress size={30} /> : "Ubah Profile"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}