// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {
  Alert,
  CardActions,
  CardHeader,
  Chip,
  ListItemText,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { convertDate } from "helper/convertDate";

function Selesai({ bulan, konten, paid = "true" }) {
  return (
    <MDBox
      sx={{
        width: "100%",
        mt: 2,
        ml: { xs: 0, sm: 1, md: 1 },
        mr: { xs: 0, sm: 1, md: 1 },
        background: "white",
        borderRadius: "8px",
        boxShadow: 5,
      }}
    >
      <MDBox sx={{ width: "100%", mt: 2 }}>
        <MDTypography sx={{ ml: 2, textDecoration: "underline" }}>{bulan}</MDTypography>
      </MDBox>
      <MDBox sx={{ ml: 3, mr: 3, mt: 2, mb: 2, display: "flex", justifyContent: "center" }}>
        <Grid container spacing={2}>
          {konten.map((x) => (
            <Grid item xs={12} sm={6} md={6}>
              <Card
                sx={{
                  maxWidth: "100%",
                  boxShadow: 5,
                }}
              >
                <CardHeader
                  avatar={
                    <Chip
                      variant="filled"
                      sx={{
                        fontWeight: "bolder",
                        backgroundColor: x?.paid ? "#5fa776" : "#bd454f",
                        color: "white",
                      }}
                      size="small"
                      label={x?.id}
                    />
                  }
                  titleTypographyProps={{ textTransform: "capitalize" }}
                  title={x?.User.username || "-"}
                  subheader={`Tanggal Upload: ${convertDate(x.updatedAt, "number")}`}
                />
                <CardActions disableSpacing>
                  <Stack direction="column" justifyContent="flex-end" alignItems="flex-start">
                    <hr
                      style={{
                        background: x?.paid ? "#5fa776" : "#bd454f",
                        color: x?.paid ? "#5fa776" : "#bd454f",
                        borderColor: x?.paid ? "#5fa776" : "#bd454f",
                        width: "110%",
                      }}
                    />
                    <Typography
                      sx={{
                        color: x?.paid ? "#5fa776" : "#bd454f",
                        fontWeight: "bold",
                        fontSize: "60%",
                      }}
                    >
                      {x?.paid ? "Rp. 13.000" : "Gratis"}
                    </Typography>
                  </Stack>
                </CardActions>
                <Grid spacing={2} sx={{ p: 2 }}>
                  {x.rating || x.comment ? (
                    <Stack alignItems="center">
                      <Rating name="read-only" value={x.rating} readOnly />
                      <MDTypography variant="h6">{x.comment ? x.comment : "-"}</MDTypography>
                    </Stack>
                  ) : (
                    <Alert severity={"warning"} justifyContent="center">
                      {"Belum Dirating"}
                    </Alert>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the Selesai
Selesai.propTypes = {
  // bulan: PropTypes.arrayOf(PropTypes.string).isRequired,
  // konten: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  // paid: PropTypes.string.isRequired,
};

export default Selesai;
