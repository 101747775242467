import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import x from "axios";
import gtw from "./apis";
import { AuthContext } from "./utils/auth";
import { GlobalContext } from "./utils/global";
import { handleError, useCancelRequest } from "./helpers";
import { convertDataToQuery, getQueryUrl } from "../helper/custom";

export const useCountDashboard = () => {
  const [loading, setLoading] = useState(false);
  const { headers, auth } = useContext(AuthContext);
  const [request, setRequest] = useState(null);
  const [data, setData] = useState({ paid: 0, notPaid: 0 });
  const onProcess = useCallback(async () => {
    setLoading(true);
    const cancel = await (async () => {
      try {
        const req = x.CancelToken.source();
        setRequest(req);
        const { data: _data } = await gtw({
          method: "get",
          url: `/content/count/${auth.id}`,
          headers,
        });
        setData(_data);

        return;
      } catch (e) {
        const message = handleError(e);
        if (message.cancel) return true;
      }
    })();
    if (cancel) return;
    setLoading(false);
  }, [auth.id, headers]);

  useCancelRequest(request);

  return { onProcess, loading, data };
};

// export const useContentById = () => {
//   const [loading, setLoading] = useState(false);
//   const { headers } = useContext(AuthContext);
//   const { content_id } = useParams();
//   const [request, setRequest] = useState(null);
//   const [res, setRes] = useState({ status: "", message: "" });
//   const [data, setData] = useState(null);
//   const onProcess = useCallback(
//     async (contentParams) => {
//       setLoading(true);
//       setRes({ status: "", message: "" });
//       setData(null);
//       const cancel = await (async () => {
//         try {
//           const req = x.CancelToken.source();
//           setRequest(req);
//           const { data: _data } = await gtw({
//             method: "get",
//             url: `/content/detail/${contentParams || content_id}?kol=true`,
//             headers,
//             cancelToken: req.token,
//           });
//           if (_data.data) setData(_data.data);
//         } catch (e) {
//           const message = handleError(e);
//           if (message.cancel) return true;
//           setRes({ status: "error", message });
//         }
//       })();
//       if (cancel) return;
//       setLoading(false);
//     },
//     [headers, content_id]
//   );

//   useCancelRequest(request);
//   return { onProcess, loading, res, data };
// };

export const useActionContent = () => {
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(null);
  const { headers } = useContext(AuthContext);
  const { setRes } = useContext(GlobalContext);
  const { content_id } = useParams();
  const onProcess = async ({ hardContentId, method, data /*callback*/ }) => {
    setLoading(true);

    const title = "Konten";
    const cancel = await (async () => {
      try {
        const req = x.CancelToken.source();
        let d = new Date();
        d.setDate(d.getDate() + 14);
        setRequest(req);

        const { data: _data } = await gtw({
          method,
          url: `/content/${hardContentId || content_id}`,
          data: { ...data, estimate_time: Math.floor(+d / 1000) },
          headers,
          cancelToken: req.token,
        });

        if (_data.message) {
          setRes({ status: "ok", message: _data.message, title });
          // callback(true);
          return;
        }
        setRes({
          status: "error",
          message: "tidak ada respon dari server, silahkan coba lagi",
          title,
        });
      } catch (e) {
        const message = handleError(e);
        if (message.cancel) return true;
        setRes({ status: "error", message, title });
      }
    })();
    if (cancel) return "ok";
    setLoading(false);
  };

  useCancelRequest(request);
  return { onProcess, loading };
};

export const useContentList = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    [data, setData] = useState({ count: 0, data: [] }),
    onProcess = useCallback(
      async (approval_status) => {
        setLoading(true);
        setRes({ status: "", message: "" });
        const cancel = await (async () => {
          try {
            const req = x.CancelToken.source();
            setRequest(req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/content?page=0&perPage=1000&orderBy=createdAt&sortBy=desc&kol=true&type=${approval_status}`,
              headers,
              cancelToken: req.token,
            });
            setData(_data);
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [headers]
    );
  useCancelRequest(request);

  return { onProcess, loading, data, res };
};

export const useCountAll = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers, auth } = useContext(AuthContext),
    [data, setData] = useState({ count: 0, data: [] }),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/content/getDashboardCount/${auth.id}`,
            headers,
            cancelToken: req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers]);
  useCancelRequest(request);

  return { onProcess, loading, data, res };
};

export const useRequestVideo = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { type } = useParams(),
    push = useNavigate(),
    { search } = useLocation(),
    [res, setRes] = useState({ status: "", message: "" }),
    query = useMemo(() => {
      if (!search) return {};
      return getQueryUrl(search);
    }, [search]),
    { headers } = useContext(AuthContext),
    [data, setData] = useState({ count: 0, data: [] }),
    onPush = (payload) => {
      let _payload = { ...payload },
        fetchType = type;
      if (_payload.type) {
        fetchType = _payload.type;
        delete _payload.type;
      }
      push(`/request-video/${fetchType}/list${convertDataToQuery(_payload)}`);
    },
    onProcess = useCallback(async () => {
      if (!search) return;
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/content/request-video${search}&kol=true&type=${type}`,
            headers,
            cancelToken: req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, search, type]);

  useEffect(() => {
    if (!search)
      push(`/request-video/${type}/list?page=0&perPage=10&orderBy=createdAt&sortBy=desc`);
  }, [type, push, search]);

  useCancelRequest(request);

  return { onProcess, loading, data, query, type, res, onPush };
};

export const useActionContentVideo = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    { setRes } = useContext(GlobalContext),
    { content_id } = useParams(),
    onProcess = async ({ hardContentId, method, data, callback = (success) => null }) => {
      setLoading(true);
      const title = "Kontent";
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method,
            url: `/content/request-video/${hardContentId || content_id}`,
            data: { ...data },
            headers,
            cancelToken: req.token,
          });
          if (_data.message) {
            setRes({ status: "ok", message: _data.message, title });
            callback(true);

            await gtw({
              method: "put",
              url: `/content/request-video/expired-date/${hardContentId || content_id}`,
              data: { ...data },
              headers,
              cancelToken: req.token,
            });

            return;
          }
          setRes({
            status: "error",
            message: "tidak ada respon dari server, silahkan coba lagi",
            title,
          });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message, title });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcess, loading };
};

export const useActionContentPromoted = () => {
  const [loading, setLoading] = useState(false),
    { headers, auth } = useContext(AuthContext),
    [request, setRequest] = useState(null),
    [data, setData] = useState({ promoted: 0 }),
    onProcess = useCallback(
      async (kol_id) => {
        setLoading(true);
        const cancel = await (async () => {
          try {
            const req = x.CancelToken.source();
            setRequest(req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/content/promotedContent`,
              data: { ...data },
              headers,
              cancelToken: req.token,
            });

            let currentDate = 0,
              expiredDate = 0;

            if (_data?.promoted.count > 0) {
              const d = new Date(_data?.promoted?.rows[0]?.createdAt);

              currentDate = new Date().getTime();

              expiredDate = new Date(d.getTime() + 24 * 60 * 60 * 1000 * 8).getTime();

              _data.promoted.validate = [currentDate, expiredDate];

              setData(_data);
            }

            if (kol_id) {
              await gtw({
                method: "post",
                url: `/content/promotedContent`,
                data: { kol_id },
                headers,
                cancelToken: req.token,
              });
            }
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [auth.id, headers]
    );

  useCancelRequest(request);

  return { onProcess, loading, data };
};

export const useActionGetReting = () => {
  const [loadingRating, setLoadingRating] = useState(false),
    { headers, auth } = useContext(AuthContext),
    [request, setRequest] = useState(null),
    [dataRating, setDataRating] = useState(false),
    [lastCount, setLastCount] = useState(0),
    onProcessRating = useCallback(async () => {
      setLoadingRating(true);
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/rating?type=kol&id=${auth.id}`,
            headers,
            cancelToken: req.token,
          });
          setDataRating(_data.isRating);
          setLastCount(_data.last_count);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
        }
      })();
      if (cancel) return;
      setLoadingRating(false);
    }, []);

  useCancelRequest(request);

  return { onProcessRating, loadingRating, dataRating, setDataRating, lastCount };
};

export const useActionPostRating = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers, auth } = useContext(AuthContext),
    { setRes } = useContext(GlobalContext),
    onProcess = async (method, data) => {
      setLoading(true);
      const title = "Kontent";
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method,
            url: `/rating`,
            data: { ...data, id_user: auth.id },
            headers,
            cancelToken: req.token,
          });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message, title });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcess, loading };
};

export const useActionKirim = () => {
  const [loadingKirim, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers, auth } = useContext(AuthContext),
    { setRes } = useContext(GlobalContext),
    onProcessKirim = async (id, url_product) => {
      setLoading(true);
      const title = "kirim";
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "put",
            url: `/content/kirim`,
            data: { id, url_product },
            headers,
            cancelToken: req.token,
          });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message, title });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcessKirim, loadingKirim };
};

export const useGetThumbnail = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers, auth } = useContext(AuthContext),
    [data, setData] = useState({ count: 0, data: [] }),
    [id, setId] = useState([]),
    onProcess = useCallback(
      async (pagination) => {
        setLoading(true);
        setRes({ status: "", message: "" });
        const cancel = await (async () => {
          try {
            const req = x.CancelToken.source();
            setRequest(req);

            let { data: _data } = await gtw({
              method: "get",
              url: `/content/lks/${auth.id}?pagination=${pagination}`,
              headers,
              cancelToken: req.token,
            });
            setData(_data);
            // setId(_data.id);
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return "ok";
        setLoading(false);
      },
      [headers]
    );
  // if (data?.id) {
  //   gtw({
  //     method: "get",
  //     url: `/content/lks/${auth.id}/thumbnail?id=${data.id}`,
  //     headers,
  //   }).then((data) => setData(data.data));
  // }
  useCancelRequest(request);

  return { onProcess, loading, data, res, setData, id };
};

export const useGetThumbnailPicture = () => {
  const [loadingThumbnail, setLoadingThumbnail] = useState(false),
    [request, setRequest] = useState(null),
    [resThumbnail, setRes] = useState({ status: "", message: "" }),
    { headers, auth } = useContext(AuthContext),
    onProcessThumbnail = useCallback(
      async (id, pagination = 1, setData) => {
        setLoadingThumbnail(true);
        setRes({ status: "", message: "" });
        const cancel = await (async () => {
          try {
            const req = x.CancelToken.source();
            setRequest(req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/content/lks/${auth.id}/thumbnail?id=${id}&pagination=${pagination}`,
              headers,
              cancelToken: req.token,
            });
            setData(_data);
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoadingThumbnail(false);
      },
      [headers]
    );

  useCancelRequest(request);

  return { onProcessThumbnail, loadingThumbnail, resThumbnail };
};

export const useGetKontenSelesai = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers, auth } = useContext(AuthContext),
    [data, setData] = useState({ count: 0, data: [] }),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/content/kontenSelesaiBaru/${auth.id}`,
            headers,
            cancelToken: req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers]);
  useCancelRequest(request);

  return { onProcess, loading, data, res };
};

export const useLKSGabungan = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers, auth } = useContext(AuthContext),
    [data, setData] = useState({ count: 0, data: [] }),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/content/lksGabungan/${auth.id}`,
            headers,
            cancelToken: req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers]);
  useCancelRequest(request);

  return { onProcess, loading, data, res };
};

export const useActionLike = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers, auth } = useContext(AuthContext),
    { setRes } = useContext(GlobalContext),
    onProcess = async (hardContentId) => {
      setLoading(true);
      const title = "Likes";
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          let d = new Date();
          d.setDate(d.getDate() + 14);
          setRequest(req);
          const { data: _data } = await gtw({
            method: "post",
            url: `/like`,
            data: {
              kol_liked_id: auth.id,
              kol_liked_name: auth.full_name,
              KontenId: hardContentId,
            },
            headers,
            cancelToken: req.token,
          });
          if (_data.message) {
            setRes({ status: "ok", message: _data.message, title });
            // callback(true);
            return;
          }
          setRes({
            status: "error",
            message: "tidak ada respon dari server, silahkan coba lagi",
            title,
          });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message, title });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcess, loading };
};

export const useGetRankingLKS = () => {
  const { setRanking } = useContext(AuthContext);
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers, auth } = useContext(AuthContext),
    [data, setData] = useState({ count: 0, data: [] }),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/content/getRankingLKS/${auth.id}`,
            headers,
            cancelToken: req.token,
          });
          setData(_data);
          setRanking(_data.level);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers]);
  useCancelRequest(request);

  return { onProcess, loading, data, res };
};

export const useGetDisplay = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers, auth } = useContext(AuthContext),
    [data, setData] = useState({ status: 0, data: [] }),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/display?kol_id=${auth.id}`,
            headers,
            cancelToken: req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers]);
  useCancelRequest(request);

  return { onProcess, loading, data, res };
};

export const useActionCreateContentEtalase = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers, auth } = useContext(AuthContext),
    { setRes } = useContext(GlobalContext),
    onProcess = async (data, callback = (success) => null) => {
      setLoading(true);
      const title = "Create Content Etalase";
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "post",
            url: `/display/createFormKerjaSamaDariEtalase`,
            data: { ...data, kol_id: auth.id, kol_name: auth.full_name },
            headers,
            cancelToken: req.token,
          });

          if (_data.data === "not_sample") {
            setRes({
              status: "ok",
              message:
                "Anda setuju untuk membuat konten tanpa sample. Silahkan cek di Isi Link Konten.",
              title,
            });
            callback(true);
            return;
          }
          if (_data.data === "ada_sample") {
            setRes({
              status: "ok",
              message:
                "Sampel sudah dianda dan segera buatkan video sekreatif mungkin dan Isi Link Konten.",
              title,
            });
            callback(true);
            return;
          }
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message, title });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcess, loading };
};

export const useActionCreateSampleRequest = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers, auth } = useContext(AuthContext),
    { setRes } = useContext(GlobalContext),
    onProcess = async (data, callback = (success) => null) => {
      setLoading(true);
      const title = "Create Sample Request";
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "post",
            url: `/display/createSample/${auth.id}`,
            data: { ...data },
            headers,
            cancelToken: req.token,
          });

          if ((_data.status = 201)) {
            setRes({ status: "ok", message: "Berhasil mengajukan sample ke seller.", title });
            callback(true);
            return;
          }
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message, title });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcess, loading };
};

export const useGetAverageRating = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers, auth } = useContext(AuthContext),
    [data, setData] = useState({ status: 0, average: 0, totalKonten: 0 }),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/content/rating/${auth.id}`,
            headers,
            cancelToken: req.token,
          });
          setData(_data);

          return;
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      console.log("masuk sini ?");
      setLoading(false);
    }, [headers]);
  useCancelRequest(request);

  return { onProcess, loading, data, res };
};
