import { useEffect } from "react";

export function handleError(err) {
  if (!window.navigator.onLine) return "Anda tidak terhubung dengan internet / anda sedang offline";

  let message = err.message || "server sedang sibuk";
  if (err.response !== undefined && err.response) {
    const { data = { message: "", errors: undefined, tokenError: false }, status = 500 } =
      err.response;

    if ([401, 403].indexOf(status) !== -1 && data.tokenError) {
      localStorage.removeItem("token");
      window.location.reload(true);

      return "your session has expired, if the page does not reload automatically, please click the logout button and log back in";
    }

    if (
      status >= 500 &&
      (/connect/.test(data.message) ||
        /refused/i.test(data.message) ||
        /network/i.test(data.message))
    )
      return "Mohon Maaf, server sedang dalam maintenance berkala.. atau bisa hub kami melalui 'whatsapp'";

    if (data.message) {
      if (
        typeof data.message !== "string" &&
        Array.isArray(data.message) &&
        data.message.length > 0
      )
        message = data.message[0].msg || message;
      else message = data.message;
    }

    if (data.errors !== undefined && Array.isArray(data.errors)) message = data.errors[0];

    if (data.header !== undefined && data.header.msg && typeof data.header.msg === "string")
      message = data.header.msg;
  }

  if (/request cancel/i.test(message)) return { cancel: true };

  if (/Executor error during find command/i.test(message))
    return "demi kenyamanan bersama kami telah memberikan limit untuk pencarian data order, harap mencari order tsb di kolom yang tersedia";

  return message;
}

export function useCancelRequest(request) {
  useEffect(
    function () {
      return function () {
        if (request && request.cancel) request.cancel("request cancel");
      };
    },
    [request]
  );
}

export function useCancelTimeout(timeout) {
  useEffect(() => {
    return function () {
      if (timeout) clearTimeout(timeout);
    };
  }, [timeout]);
}

export function useCancelInterval(interval) {
  useEffect(() => {
    return function () {
      if (interval) clearInterval(interval);
    };
  }, [interval]);
}
