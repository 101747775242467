import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function MultipleChipSelect({
  open = undefined,
  onClose = undefined,
  required = false,
  label,
  error = false,
  children,
  value = [],
  onChange = () => null,
}) {
  return (
    <FormControl required={required} error={error} variant="standard" fullWidth>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        open={open}
        onClose={() => onClose(false)}
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        onOpen={() => onClose(true)}
        fullWidth
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput idnames="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((val) => (
              <Chip key={val} label={val} style={{ fontSize: 13 }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {children}
      </Select>
    </FormControl>
  );
}

MultipleChipSelect.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export function BasicSelect({
  required = false,
  value,
  name,
  onChange,
  label,
  children,
  error = false,
}) {
  return (
    <FormControl required={required} error={error} fullWidth margin="dense" size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        name={name}
        fullWidth
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
      >
        {children}
      </Select>
    </FormControl>
  );
}

BasicSelect.propTypes = {
  required: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};
