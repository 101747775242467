import { useState, useEffect, useContext } from "react";

// react-router components
import { NavLink, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import { Alert, CircularProgress, Divider, Rating } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  // navbarIconButton,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import { useMaterialUIController, setTransparentNavbar } from "context";

// Avatar Profile
// import Man from "assets/images/3551739.jpg";
// import Man from "assets/images/man1.png";
// import Woman from "assets/images/woman1.png";
import Pemula from "assets/border/pemula.png";
import Pejuang from "assets/border/pejuang.png";
import Kapten from "assets/border/kapten.png";
import Jendral from "assets/border/jenderal.png";
import Pahlawan from "assets/border/pahlawan.png";
// import ManGanjil from "assets/images/avatar/avatar-cowo-2.png";
// import WomanGanjil from "assets/images/avatar/avatar-cewe-2.png";
import Bintang from "assets/border/bintang.png";

// MUI Material
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AuthContext } from "context/utils/auth";
import { useCount } from "context/logs";
import { Logout, MenuOutlined, Person, VideoCameraFront } from "@mui/icons-material";
import { useGetRankingLKS, useGetAverageRating } from "context/content";
import RatingLks from "./RankingLks";

function DashboardNavbar({ absolute, light }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [openDialog, setOpenDialog] = useState(false);
  const { onProcess } = useCount();
  const { logout } = useContext(AuthContext);
  const { auth } = useContext(AuthContext);
  const { onProcess: _onProsesRank, loading, data } = useGetRankingLKS();
  const { onProcess: rating, loading: loadingRating, data: dataRating } = useGetAverageRating();
  //! avarage rating masih tidak bisa, solusi memasang loading tetapi loading juga datanya true trus tidak pernah false
  function AvatarGender(gender, id) {
    const man1 = "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-1.png";
    const man2 = "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-2.png";
    const woman1 = "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-3.png";
    const woman2 = "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-4.png";

    if (gender === "pria") {
      if (id % 2 === 0) {
        return man1;
      }
      return man2;
    }

    if (gender === "wanita") {
      if (id % 2 === 0) {
        return woman1;
      }
      return woman2;
    }
  }

  useEffect(() => {
    // Setting the navbar type
    onProcess();
    rating();
    _onProsesRank();
    setNavbarType("static");
    // if (fixedNavbar) {
    //   setNavbarType("sticky");
    // } else {
    //   setNavbarType("static");
    // }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
    The event listener that's calling the handleTransparentNavbar function when 
    scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar, onProcess, _onProsesRank]);

  const handleClose = (name) => () => {
    if (name === "logout") logout();
  };

  const border = (level) => {
    if (level === "Pejuang") return Pejuang;
    if (level === "Kapten") return Kapten;
    if (level === "Jendral") return Jendral;
    if (level === "Pahlawan") return Pahlawan;

    return Pemula;
  };

  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Person />} title="Edit Profile" component={NavLink} to="/profile" />
      <NotificationItem
        icon={<VideoCameraFront />}
        title="Konsep Video"
        component={NavLink}
        to="/konsep-video"
      />
      <NotificationItem icon={<Logout />} title="Logout" onClick={handleClose("logout")} />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar: false, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox display="flex" width="100%">
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Apa itu Ranking LKS ?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Jadi, setiap kamu membantu teman yang lain untuk like, komen, share video maka kamu
                akan mendapat poin dari JualKirim, dan ranking kamu bertambah dan merubah status
                kamu yang urutannya yaitu : Pemula, Pejuang, Kapten, Jendral, Pahlawan.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton color="info" onClick={() => setOpenDialog(false)}>
                Close
              </MDButton>
            </DialogActions>
          </Dialog>

          <Grid container>
            <Grid item xs={4} md={4} lg={4}>
              <Breadcrumbs
                icon="home"
                title={route[route.length - 1]}
                route={route}
                light={light}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{ placeItems: "center" }}
              >
                <MDBox
                  color="inherit"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  mt={1}
                >
                  <IconButton
                    size="large"
                    color="inherit"
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    disableRipple
                  >
                    <NavLink to="/profile">
                      {data.level ? (
                        <img
                          src={border(data.level)}
                          alt={loading ? "" : `Border`}
                          loading="lazy"
                          style={{
                            position: "absolute",
                            top: "-4%",
                            left: "-1%",
                            width: "100px",
                            height: "100px",
                          }}
                        />
                      ) : null}
                      <img
                        src={AvatarGender(auth.gender, auth.id)}
                        alt="Avatar"
                        loading="lazy"
                        style={{ width: "76px", height: "76px" }}
                      />
                      {auth.id === 147 ? (
                        <img
                          src={Bintang}
                          alt="Avatar"
                          loading="lazy"
                          style={{
                            position: "absolute",
                            width: "35px",
                            height: "35px",
                            top: "66%",
                            left: "32.5%",
                          }}
                        />
                      ) : null}
                    </NavLink>
                  </IconButton>
                </MDBox>
                <MDTypography
                  variant="h5"
                  color="inherit"
                  align="center"
                  mb={1}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {auth.full_name || "Creator JualKirim"}
                </MDTypography>
                <MDTypography variant="caption" display="block" gutterBottom align="center">
                  Ranking LKS <HelpOutlineIcon color="info" onClick={() => setOpenDialog(true)} />
                </MDTypography>
                <RatingLks loading={loading} data={data} />
                <MDBox
                  sx={{
                    width: "100%",
                    minWidth: { xs: "70vw", sm: "50vw", md: "100%" },
                    textAlign: "-webkit-center",
                  }}
                >
                  <MDTypography variant="caption">
                    Rata-rata Penilaian <br />
                  </MDTypography>
                  {!loadingRating ? (
                    <NavLink to="/konten/selesai">
                      {dataRating?.average ? (
                        <>
                          <Rating
                            name="half-rating-read"
                            defaultValue={dataRating?.average}
                            precision={0.5}
                            readOnly
                            size="small"
                          />{" "}
                          <MDTypography variant="button">
                            | {dataRating?.totalKonten} Konten
                          </MDTypography>
                        </>
                      ) : (
                        <Alert size="small" severity="warning" sx={{ width: "69%" }}>
                          <MDTypography variant="caption">Belum Ada Penilaian</MDTypography>
                        </Alert>
                      )}
                    </NavLink>
                  ) : (
                    <CircularProgress style={{ margin: 5 }} size={25} />
                  )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  size="large"
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  onClick={handleOpenMenu}
                  color="success"
                >
                  <MenuOutlined sx={iconsStyle} />
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {renderMenu()}
      </Toolbar>
      <Divider variant="inset" />
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
};

export default DashboardNavbar;
