import {useEffect, useContext} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Grid, Typography} from '@mui/material';
import { GlobalContext } from 'context/utils/global';
import { getQueryUrl } from 'helper/custom';

export const VerificationPage = () => {
	const {search} = useLocation(),
		{setRes} = useContext(GlobalContext),
		push = useNavigate();

	useEffect(() => {
		if(search) {
			const query = getQueryUrl(search);
			if(query && query.status && query.message)
				setRes({status: query.status, message: decodeURIComponent(query.message), title: 'Verification'});
		}
		push('/')
	}, [search, push, setRes])

	return (
		<Grid container spacer={3} width='100%' height='100%' alignItems='center' justifyContent='center'>
			<Typography variant='h5'>Redirecting...</Typography>
		</Grid>
	)
}