// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import CoverLayoutSignUp from "layouts/authentication/components/CoverLayout/CoverLayoutSignUp";

// Images
// import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import MuiLink from "@mui/material/Link";
import { useState } from "react";
import { MultipleChipSelect, BasicSelect } from "components/select-box";
import { categories, baseColor, konsepVideo } from "helper/values";
import { useRegister } from "context/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LogoSign from "../sign-in/logo-navbar-jualkirim.png";

function Cover() {
  const { data, onChange, loading, res, onProcess } = useRegister();
  const [howSell, setHowSell] = useState("");
  const [gender, setGender] = useState("");
  const [openCategory, setOpenCategory] = useState(false);
  const [openKonsepVideo, setOpenKonsepVideo] = useState(false);
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const getStyles = (name, personName, themes) => ({
    fontWeight:
      personName.indexOf(name) === -1
        ? themes.typography.fontWeightRegular
        : themes.typography.fontWeightMedium,
  });
  // const handleChange = (event) => {
  //   setHowSell(event.target.value);
  // };
  // const handleChangeGender = (event) => {
  //   setGender(event.target.value);
  // };
  // const handleChangeCategory = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   const val = typeof value === "string" ? value.split(",") : value;
  //   const checkAllCategories = val.findIndex((el) => el === "Semua Kategory");

  //   if (checkAllCategories !== -1) {
  //     onChange({ target: { value: ["Semua Kategory"], name: "categories" } });
  //     setOpenCategory(false);
  //     return;
  //   }

  //   if (val.length > 3) return;
  //   onChange({ target: { value: val, name: "categories" } });
  //   if (val.length === 3) setOpenCategory(false);
  // };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const val = typeof value === "string" ? value.split(",") : value;
    const checkAllCategories = val.findIndex((el) => el === "Semua Kategory");

    if (checkAllCategories !== -1) {
      onChange({ target: { value: ["Semua Kategory"], name: "categories" } });
      setOpenCategory(false);
      return;
    }

    if (val.length > 3) return;
    onChange({ target: { value: val, name: "categories" } });
    if (val.length === 3) setOpenCategory(false);
  };

  const handleChangeKonsepVideo = (event) => {
    const {
      target: { value },
    } = event;
    const val = typeof value === "string" ? value.split(",") : value;
    const checkAllCategories = val.findIndex((el) => el === "Pilih Semua");

    if (checkAllCategories !== -1) {
      onChange({ target: { value: ["Pilih Semua"], name: "konsepVideo" } });
      setOpenKonsepVideo(false);
      return;
    }

    if (val.length > 3) return;
    onChange({ target: { value: val, name: "konsepVideo" } });
    if (val.length === 3) setOpenKonsepVideo(false);
  };

  // console.log(loading, res, onProcess, gender);

  return (
    <CoverLayoutSignUp>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
          sx={{
            bgcolor: "#bd454f",
          }}
        >
          <FormControl fullWidth size="small" margin="normal">
            {res.message ? (
              <Alert severity={res.status === "ok" ? "success" : "error"}>{res.message}</Alert>
            ) : (
              <Alert style={{ opacity: 0 }} />
            )}
          </FormControl>
          <Grid container justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <MDTypography component={MuiLink} variant="h6" color="white" noWrap>
              <img alt="jualkirim Logo" src={LogoSign} style={{ alignItems: "center" }} />
            </MDTypography>
          </Grid>
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Registrasi Kreator
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Silahkan Isi Data Diri untuk Daftar
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Nama Lengkap"
                variant="standard"
                fullWidth
                required
                onChange={onChange}
                error={res.message && /nama lengkap/i.test(res.message)}
                name="full_name"
                value={data.full_name}
                placeholder="eg: first lastname"
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Nomor HP"
                variant="standard"
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDTypography variant="caption" color="black" fontWeight="bolder">
                        +62
                      </MDTypography>
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  const {
                    target: { value, name },
                  } = event;
                  if (!Number.isNaN(Number(value))) return onChange(event);

                  return onChange({ target: { value: "", name } });
                }}
                name="phone_number"
                error={res.message && (/phone/i.test(res.message) || /telfon/i.test(res.message))}
                value={data.phone_number}
                placeholder="eg: 82xxxxxx"
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                required
                variant="standard"
                placeholder="eg: jualkirim@example.com"
                fullWidth
                onChange={onChange}
                name="email"
                error={res.status === "error" && res.message && /email/i.test(res.message)}
                value={data.email}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                required
                name="password"
                label="Password"
                variant="standard"
                fullWidth
                type={show ? "text" : "password"}
                onChange={onChange}
                error={res.message && /password/i.test(res.message)}
                value={data.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        variant="standard"
                        onClick={() => setShow((prev) => !prev)}
                      >
                        {show ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="eg: pass"
              />
            </MDBox>

            <Box mb={2} sx={{ minWidth: 120 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-label" sx={{ p: 0 }}>
                  Jenis Kelamin
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.gender}
                  onChange={onChange}
                  required
                  name="gender"
                >
                  <MenuItem value="pria">Pria</MenuItem>
                  <MenuItem value="wanita">Wanita</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Username Tiktok"
                variant="standard"
                error={res.message && /tiktok/i.test(res.message)}
                onChange={({ target: { value } }) =>
                  onChange({ target: { payload: { sosmed: { tiktok: value } } } })
                }
                placeholder="eg: Jual_Kirim"
                fullWidth
                value={data.sosmed.tiktok}
              />
            </MDBox>

            <Box mb={2} sx={{ minWidth: 120 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-label">Cara Jualan</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Cara Jualan"
                  error={res.message && /menjual/i.test(res.message)}
                  name="how_sell"
                  value={data.how_sell}
                  onChange={onChange}
                >
                  <MenuItem value="Live streaming">Live streaming</MenuItem>
                  <MenuItem value="Share Video Content">Share Video Content</MenuItem>
                  <MenuItem value="Live streaming,Share Video Content">Kedua-duanya</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              mb={2}
              sx={{
                "& .MuiInputBase-root": { minHeight: "45.13px", mt: 2, pt: 1, pb: 1 },
                "& .MuiFormLabel-root": { transformOrigin: "top", pl: "4px", pt: "12px" },
              }}
            >
              <MultipleChipSelect
                open={openCategory}
                required
                onClose={setOpenCategory}
                error={res.message && /category/i.test(res.message)}
                onChange={handleChange}
                value={data.categories || []}
                label="Pilih 3 kategori"
              >
                {["Semua Kategory", ...categories].map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, data.categories, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </MultipleChipSelect>
            </Box>

            {/* <Box
              mb={2}
              sx={{
                "& .MuiInputBase-root": { minHeight: "45.13px", mt: 2, pt: 1, pb: 1 },
                "& .MuiFormLabel-root": { transformOrigin: "top", pl: "4px", pt: "12px" },
              }}
            >
              <MultipleChipSelect
                open={openKonsepVideo}
                required
                onClose={setOpenKonsepVideo}
                error={res.message && /category/i.test(res.message)}
                onChange={handleChangeKonsepVideo}
                value={[]}
                label="Pilih Konsep Video"
              >
                {["Pilih Semua", ...konsepVideo].map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, data.categories, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </MultipleChipSelect>
            </Box> */}

            <FormControl fullWidth margin="dense" size="small">
              <MDTypography variant="caption">Persentase Followers</MDTypography>

              <Grid
                item
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                width="100%"
              >
                <TextField
                  size="small"
                  variant="standard"
                  required
                  style={{ width: "35%" }}
                  color="primary"
                  margin="dense"
                  error={res.message && /followers/i.test(res.message)}
                  label="Pria"
                  value={data.followers.man}
                  placeholder="eg: 80"
                  onChange={({ target: { value } }) => {
                    const checkValue = !Number.isNaN(Number(value));
                    onChange({
                      target: {
                        payload: {
                          followers: {
                            man: checkValue && value && parseInt(value, 10) <= 100 ? value : "",
                            woman:
                              checkValue && value && parseInt(value, 10) <= 100
                                ? `${100 - parseInt(value, 10)}`
                                : "",
                          },
                        },
                      },
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MDTypography fontWeight="bolder">%</MDTypography>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  size="small"
                  variant="standard"
                  color="primary"
                  margin="dense"
                  required
                  error={res.message && /followers/i.test(res.message)}
                  style={{ marginLeft: 20, width: "35%" }}
                  label="Wanita"
                  placeholder="eg: 20"
                  value={data.followers.woman}
                  onChange={({ target: { value } }) => {
                    const checkValue = !Number.isNaN(Number(value));
                    onChange({
                      target: {
                        payload: {
                          followers: {
                            woman: checkValue && value && parseInt(value, 10) <= 100 ? value : "",
                            man:
                              checkValue && value && parseInt(value, 10) <= 100
                                ? `${100 - parseInt(value, 10)}`
                                : "",
                          },
                        },
                      },
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MDTypography fontWeight="bolder">%</MDTypography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </FormControl>

            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                checked={!data.paid}
                onChange={({ target: { checked } }) =>
                  onChange({ target: { value: !checked, name: "paid" } })
                }
                sx={{
                  color: baseColor,
                  "&.Mui-checked": {
                    color: baseColor,
                  },
                }}
              />
              <MDTypography
                variant="caption"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Saya ingin menggunakan program &nbsp;&nbsp;gratis
                <i style={{ color: "red" }}>*tidak wajib*</i>
              </MDTypography>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="inherit"
                sx={{
                  backgroundColor: "#bd454f",
                }}
                fullWidth
                onClick={onProcess}
              >
                {loading ? <CircularProgress size={30} /> : "Register"}
              </MDButton>

              <FormControl mt={1} fullWidth size="small" margin="normal">
                {res.message ? (
                  <Alert severity={res.status === "ok" ? "success" : "error"}>{res.message}</Alert>
                ) : (
                  <Alert style={{ opacity: 0 }} />
                )}
              </FormControl>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Sudah punya akun?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login disini
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayoutSignUp>
  );
}

export default Cover;
