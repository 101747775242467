import { useContext, useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import x from "axios";
import gtw from "./apis";
import { AuthContext } from "./utils/auth";
import { GlobalContext } from "./utils/global";
import { handleError, useCancelRequest } from "./helpers";
import { useForm } from "../components/spam";
import { getQueryUrl } from "../helper/custom";
import { categories } from "../helper/values";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const { data, onChange, clear } = useForm({
    request: "",
    password: "",
  });
  const [request, setRequest] = useState(null);
  const { login } = useContext(AuthContext);
  const { res, setRes } = useContext(GlobalContext);
  const onProcess = async (event) => {
    if (event?.preventDefault) event.preventDefault();
    if (loading) return;
    setLoading(true);
    const cancel = await (async () => {
      try {
        const req = x.CancelToken.source();
        setRequest(req);
        const { data: _data } = await gtw({
          method: "post",
          url: "/kol",
          data,
          cancelToken: req.token,
          // headers: { "Clear-Site-Data": "cache" },
        });
        clear();
        login(_data);
      } catch (e) {
        const message = handleError(e);
        console.log(message);
        if (message.cancel) return true;
        setRes({ status: "error", message, title: "login" });
      }
    })();
    if (cancel) return "ok";
    setLoading(false);
  };

  useCancelRequest(request);
  return { onProcess, loading, res, data, onChange };
};

export const useRegister = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { data, onChange, clear } = useForm({
      full_name: "",
      phone_number: "",
      email: "",
      password: "",
      gender: "pria",
      sosmed: { tiktok: "" },
      categories: [],
      paid: true,
      how_sell: "",
      followers: {
        man: "",
        woman: "",
      },
    }),
    onProcess = async (event) => {
      if (event?.preventDefault) event.preventDefault();

      if (loading) return;
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const getCategories = (() => {
            try {
              const getIndex = data.categories.findIndex((el) => el === "Semua Kategory");

              if (getIndex !== -1) return categories.map((name) => ({ name }));
              return data.categories.map((name) => ({ name }));
            } catch (e) {
              return data.categories.map((name) => ({ name }));
            }
          })();

          const { data: _data } = await gtw({
            method: "post",
            url: "/kol/register",
            data: {
              ...data,
              categories: getCategories,
              followers: {
                man: parseInt(data.followers.man, 10),
                woman: parseInt(data.followers.woman, 10),
              },
            },
            cancelToken: req.token,
          });
          if (_data.message) {
            clear();
            return setRes({ status: "ok", message: _data.message });
          }
          setRes({
            status: "error",
            message: "server sedang sibuk, silahkan coba beberapa saat lagi",
          });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };
  useCancelRequest(request);

  return { onProcess, loading, res, data, onChange };
};

export const useUpdateProfile = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { auth, headers, login } = useContext(AuthContext),
    { data, onChange } = useForm(
      {
        full_name: auth.full_name || "",
        phone_number: auth.phone_number ? auth.phone_number.substring(2) : "",
        email: auth.email || "",
        password: "",
        old_password: "",
        gender: auth.gender || "",
        sosmed: auth.sosmed || { tiktok: "" },
        categories:
          auth.categories?.length > 0
            ? !/semua kategory/i.test(auth.categories[0])
              ? auth.categories.map((el) => el.name)
              : auth.categories
            : [],
        paid: auth.paid || false,
        how_sell: auth.how_sell,
        followers: auth.followers || {
          man: "",
          woman: "",
        },
        konsep_video: auth.konsep_video || {
          ads: false,
          duet: false,
          freestyle: false,
          slideShow: false,
          storyTelling: false,
        },
      },
      auth
    ),
    { setRes } = useContext(GlobalContext),
    payload = useMemo(() => {
      const __payload = { ...data };
      if (__payload.followers) {
        const { man, woman } = __payload.followers;
        __payload.followers = {
          man: parseInt(man || "0", 10),
          woman: parseInt(woman || "100", 10),
        };
      }

      if (__payload.email) delete __payload.email;
      if (__payload.phone_number) delete __payload.phone_number;

      for (let key in __payload) {
        if (key === "categories" && __payload.categories) {
          if (
            JSON.stringify(__payload.categories) ===
            JSON.stringify(auth.categories.map((el) => el.name))
          )
            delete __payload.categories;
        }
        if (key === "sosmed") {
          if (!__payload.sosmed.tiktok) delete __payload.sosmed;
        }
        if (__payload[key] === "" || JSON.stringify(__payload[key]) === JSON.stringify(auth[key]))
          delete __payload[key];
      }

      if (__payload.categories) {
        const getCategories = (() => {
          try {
            const getIndex = __payload.categories.findIndex((el) => el === "Semua Kategory");

            if (getIndex !== -1) return categories.map((name) => ({ name }));
            return __payload.categories.map((name) => ({ name }));
          } catch (e) {
            return __payload.categories.map((name) => ({ name }));
          }
        })();

        __payload.categories = getCategories;
      }

      return __payload;
    }, [auth, data]),
    onProcess = async (e) => {
      if (e?.preventDefault) e.preventDefault();
      if (loading) return;
      setLoading(true);
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          if (Object.keys(payload).length === 0)
            return setRes({
              status: "error",
              message: "anda tidak merubah apapun",
              title: "Update Profile",
            });
          const { data: _data } = await gtw({
            method: "put",
            url: "/kol",
            data: payload,
            headers,
            cancelToken: _req.token,
          });

          login(_data);
          setRes({
            status: "ok",
            message: "Profile anda berhasil diubah",
            title: "Update Profile",
          });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message, title: "Update Profile" });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };
  useCancelRequest(request);
  return { onProcess, data, onChange, loading };
};

export const useForgotPassword = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { res, setRes } = useContext(GlobalContext),
    onProcess = (request) => async (event) => {
      if (event?.preventDefault) event.preventDefault();
      setLoading(true);
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);
          const { data } = await gtw({
            method: "post",
            url: "/kol/forgot-password",
            data: { request },
            cancelToken: req.token,
          });

          if (data.message)
            return setRes({ status: "ok", message: data.message, title: "Lupa Password" });

          setRes({
            status: "error",
            message: "tidak dapat respon dari server, silahkan coba lagi",
            title: "Lupa Password",
          });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcess, loading, res };
};

export const useChangePassword = () => {
  const [loading, setLoading] = useState(false),
    { data, onChange, clear } = useForm({ new_password: "", conf_password: "" }),
    { search } = useLocation(),
    [request, setRequest] = useState(null),
    push = useNavigate(),
    { res, setRes } = useContext(GlobalContext),
    token = useMemo(() => {
      if (!search) return "";
      const _query = getQueryUrl(search);
      return _query && _query.token ? _query.token : "";
    }, [search]),
    onProcess = async (event) => {
      if (event?.preventDefault) event?.preventDefault();
      setLoading(true);
      const cancel = await (async () => {
        try {
          const req = x.CancelToken.source();
          setRequest(req);

          if (!data.new_password || !data.conf_password)
            return setRes({ status: "error", message: "harap password / konfirmasi password" });

          if (data.new_password !== data.conf_password)
            return setRes({
              status: "error",
              message: "password dan konfirmasi password tidak sama",
            });
          const { data: _data } = await gtw({
            method: "patch",
            url: "/kol/change-password",
            data: { token, password: data.conf_password },
            cancelToken: req.token,
          });

          if (_data.message) {
            clear();
            return setRes({ status: "ok", message: _data.message, title: "Ubah password" });
          }
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useEffect(() => {
    if (!token) {
      setRes({ status: "error", message: "missing parameter, harap cek kembali di email anda" });
      push("/");
    }
  }, [token, push, setRes]);

  useCancelRequest(request);
  return { onProcess, data, loading, res, onChange };
};

export const useRetryVerification = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { setRes } = useContext(GlobalContext),
    onProcess =
      (request, cb = (ok) => null) =>
      async (_) => {
        if (loading) return;
        setLoading(true);
        const cancel = await (async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data } = await gtw({
              method: "patch",
              url: "/kol/retry-verification",
              data: { request },
              cancelToken: _req.token,
            });
            if (data.message) {
              setRes({ status: "ok", message: data.message });
              cb(true);
              return;
            }

            setRes({ status: "error", message: "tidak dapat respon dari server" });
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message, title: "Retry Send" });
          }
        })();
        if (cancel) return;
        setLoading(false);
      };

  useCancelRequest(request);
  return { onProcess, loading };
};

export const useListAddresses = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    push = useNavigate(),
    { headers, setAddress, auth } = useContext(AuthContext),
    onProcess = useCallback(
      async (hard = false) => {
        if (auth.address.length > 0 && !hard) return;
        setLoading(true);
        const cancel = await (async function () {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data } = await gtw({
              method: "get",
              url: "/kol/address/list",
              headers,
              cancelToken: _req.token,
            });

            if (data.length === 0) return push("/profile/edit?q=address&a=create");
            setAddress(data);
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [headers, setAddress, auth.address, push]
    );

  useCancelRequest(request);

  return { loading, onProcess };
};

export const useCreateAddress = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers, setAddress, auth } = useContext(AuthContext),
    { search } = useLocation(),
    push = useNavigate(),
    [res, setRes] = useState({ status: "", message: "" }),
    { data, onChange, clear } = useForm({
      identity: "",
      name: "",
      primary: auth.address?.length === 0 ? true : false,
      destination_id: 0,
      country_name: "",
      province_name: "",
      city_name: "",
      district_name: "",
      subdistrict_name: "",
      zip_code: 0,
      tariff_code: "",
      origin_code: "",
      origin_name: "",
    }),
    onProcess = async (_) => {
      if (loading) return;
      setLoading(true);
      const cancel = await (async () => {
        try {
          if (!data.origin_code || !data.origin_name) throw new Error("coba pilih alamat lain");
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "post",
            url: `/kol/address`,
            data,
            cancelToken: _req.token,
            headers,
          });
          if (_data.id) {
            setAddress([...auth.address, _data]);
            clear();
            return setRes({ status: "ok", message: "Alamat berhasil di tambahkan" });
          }
          setRes({ status: "error", message: "tidak dapat terhubung ke server" });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    },
    getByid = useCallback(
      async (id) => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data } = await gtw({
            method: "get",
            url: `/kol/address/single/${id}`,
            headers,
            cancelToken: _req.token,
          });
          if (data && data.id) return onChange({ target: { payload: data } });
          throw new Error("not responding");
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return;
          push("/profile/edit?q=address");
        }
      },
      [headers, push, onChange]
    );

  useEffect(() => {
    if (search) {
      const { a, id } = getQueryUrl(search) || { a: "", id: undefined };
      if (a === "edit" && id) getByid(id);
    }
  }, [search, getByid]);

  useCancelRequest(request);
  return { onProcess, loading, res, onChange, data, clear };
};

export const useUpdateAddress = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers, setAddress, auth } = useContext(AuthContext),
    { setRes } = useContext(GlobalContext),
    onProcess = async (id, data, callback = undefined) => {
      setLoading(true);
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "put",
            url: `/kol/address/${id}`,
            data,
            headers,
            cancelToken: _req.token,
          });
          if (_data && _data.id) {
            const newAddress = auth.address.map((el) => {
              if (el.id === _data.id) return _data;
              if (_data.primary) return { ...el, primary: false };
              return el;
            });
            setAddress(
              _data.primary ? newAddress.sort((a, b) => b.primary - a.primary) : newAddress
            );

            if (callback) callback("ok");
            return setRes({ status: "ok", message: "Data berhasil diubah" });
          }

          setRes({ status: "error", message: "tidak dapat respon dari server" });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcess, loading };
};

export const useDeleteAddress = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers, setAddress, auth } = useContext(AuthContext),
    { res, setRes } = useContext(GlobalContext),
    onProcess = async (id) => {
      if (loading) return;

      setLoading(true);
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data } = await gtw({
            method: "delete",
            url: `/kol/address/${id}`,
            headers,
            cancelToken: _req.token,
          });
          if (data.message) {
            let data = auth.address.slice();
            setAddress(data.filter((el) => el.id !== id));
            setRes({ status: "ok", message: data.message });
            return;
          }
          setRes({ status: "error", message: "tidak dapat respon dari server" });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcess, loading, res };
};

export const useListDestination = () => {
  const [loading, setLoading] = useState(false),
    [values, setValues] = useState(""),
    [request, setRequest] = useState(null),
    [data, setData] = useState([]),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    onProcess = useCallback(
      async ({ target: { value } }) => {
        setValues(value);
        setLoading(true);
        const cancel = await (async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/api/awb/destination/jne?search=${value}`,
              headers,
              cancelToken: _req.token,
            });
            if (_data.data) setData(_data.data.map((el, index) => ({ ...el, index: index + 1 })));
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();

        if (cancel) return;
        setLoading(false);
      },
      [headers]
    ),
    clear = (_) => setData([]),
    getAwbCode = async (zip_code) => {
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const { data: _data } = await gtw({
          method: "get",
          url: `/awb/get-code?zip=${zip_code}`,
          headers,
          cancelToken: _req.token,
        });
        if (_data.data)
          return {
            origin_code: _data.data["Origin code"] || "",
            origin_name: _data.data["Origin name"] || "",
          };

        throw new Error("server is not responding");
      } catch (e) {
        return { origin_code: "", origin_name: "" };
      }
    };
  useCancelRequest(request);
  return { data, res, onProcess, loading, clear, values, setValues, getAwbCode };
};
