import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Card, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useChangePassword } from "context/auth";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import BasicLayout from "./components/BasicLayout";
import MuiLink from "@mui/material/Link";
import LogoSign from "./sign-in/logo-navbar-jualkirim.png";
import MDButton from "components/MDButton";

export function ChangePasswordPage() {
  const [show, setShow] = useState(false);
  const { onChange, data, loading, onProcess, res } = useChangePassword();

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="default"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          sx={{
            bgcolor: "#bd454f",
          }}
        >
          <Grid container justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <MDTypography component={MuiLink} variant="h6" color="white" noWrap>
              <img alt="jualkirim Logo" src={LogoSign} alignItems="center" />
            </MDTypography>
          </Grid>
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Change Password
          </MDTypography>
          <MDTypography variant="caption" fontWeight="small" color="white">
            Silahkan isi password baru untuk akun anda
          </MDTypography>
        </MDBox>
        <MDBox pt={5} pb={3} px={3}>
          <form className="form-input" onSubmit={onProcess}>
            <MDBox component="form" role="form">
              <MDBox mb={3}>
                <TextField
                  name="new_password"
                  label="New Password"
                  fullWidth
                  required
                  type={show ? "text" : "password"}
                  error={res.status !== "ok" && /password/i.test(res.message) === true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          variant="standard"
                          onClick={(_) => setShow((prev) => !prev)}
                        >
                          {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={onChange}
                  value={data.new_password}
                  placeholder="eg: pass"
                />
              </MDBox>
              <MDBox mb={3}>
                <TextField
                  label="Ulangi Password"
                  fullWidth
                  name="conf_password"
                  type={show ? "text" : "password"}
                  error={res.status !== "ok" && /konfirmasi/i.test(res.message) === true}
                  onChange={onChange}
                  required
                  value={data.conf_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          variant="standard"
                          onClick={(_) => setShow((prev) => !prev)}
                        >
                          {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="eg: pass"
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                <NavLink to="/">
                  <MDTypography variant="caption" fontWeight="medium" textGradient color="info">
                    Kembali Login
                  </MDTypography>
                </NavLink>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="inherit"
                  fullWidth
                  type="submit"
                  sx={{
                    backgroundColor: "#bd454f",
                  }}
                  onClick={onProcess}
                >
                  {loading ? <CircularProgress size={20} /> : "Ubah"}
                </MDButton>
              </MDBox>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}
