// @mui material components
import { NavLink } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Icon
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContentList } from "context/content";
import { useEffect, useState } from "react";
import { Alert, Box, CircularProgress, Skeleton } from "@mui/material";
import EtalaseCard from "layouts/billing/components/Bill/EtalaseCard";
import { useGetDisplay } from "context/content";

function Etalase() {
  const { onProcess, loading, data } = useGetDisplay();
  const [afterRequest, setAfterRequest] = useState("");

  useEffect(() => {
    onProcess();
  }, [afterRequest]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox id="delete-account">
        <MDBox mt={2}>
          <MDTypography variant="h5" fontWeight="medium">
            <MDButton
              variant="outlined"
              size="small"
              color="primary"
              component={NavLink}
              to="/dashboard"
              iconOnly
            >
              <ArrowBackIcon />
            </MDButton>
            &nbsp;&nbsp; Etalase
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {loading ? (
              // <CircularProgress style={{ margin: 5 }} size={25} />
              <>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
              </>
            ) : (
              // : res.messagge || data.data.length === 0 ? (
              //   <Alert severity={res.message ? "error" : "warning"}>{res.message || "No Data"}</Alert>
              // )
              data.data.map((result, index) => {
                return (
                  <EtalaseCard
                    setAfterRequest={setAfterRequest}
                    key={index}
                    DisplayId={result.id}
                    nama_product={result.item_name}
                    nama_seller={result.User?.username}
                    komisi={result.item_commission}
                    notes={result.item_notes}
                    linkProduct={result.display_link_product}
                    tanggal={result.createdAt}
                    cod={result.item_cod}
                    kelebihan_produk={result.item_selling_point}
                    jumlah_sample={result.item_sample_quantity}
                    category={result.item_category}
                    result={result}
                  />
                );
              })
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Etalase;
