import { useEffect, useState, useContext } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//sweetalert2
import Swal from "sweetalert2";
import IconKirim from "assets/icon-oper.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Alert, Chip, Divider, IconButton, Snackbar } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { convertDate } from "helper/convertDate";
import { useActionCreateContentEtalase } from "context/content";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import ModalSample from "./modalSample";
import { AuthContext } from "context/utils/auth";

export default function EtalaseCard({
  DisplayId,
  nama_seller,
  komisi,
  nama_product,
  category,
  notes,
  linkProduct,
  tanggal,
  kelebihan_produk,
  cod,
  jumlah_sample,
  result,
  setAfterRequest,
}) {
  const navigate = useNavigate();
  const { loading, onProcess } = useActionCreateContentEtalase();
  const [openAlert, setOpenAlert] = useState(false);
  const [urls, setUrls] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    const arr = linkProduct?.split(",") || [];
    setUrls(arr);
  }, [linkProduct]);

  function sisaSample() {
    return result.item_sample_quantity - result.SisaSample.length;
  }

  function createWithoutSample() {
    onProcess({
      url_product: result.display_link_product,
      user_id: result.user_id,
      note: result.item_notes,
      commission: result.item_commission,
      cod: result.item_cod,
      DisplayId: result.id,
    });

    setTimeout(() => {
      setAfterRequest("Create Succes");
    }, 300);
  }

  function confirmSampleAndCreateContent() {
    onProcess({
      url_product: result.display_link_product,
      user_id: result.user_id,
      note: result.item_notes,
      commission: result.item_commission,
      cod: result.item_cod,
      DisplayId: result.id,
      DataSamples: result.Samples[0],
    });
    setTimeout(() => {
      setAfterRequest("Create Succes");
    }, 300);
  }

  return (
    <>
      <Snackbar anchorOrigin={{ horizontal: "center", vertical: "top" }} open={openAlert}>
        <Alert severity={"success"}>{alertMessage}</Alert>
      </Snackbar>
      <ModalSample
        open={open}
        setOpen={setOpen}
        result={result}
        setAfterRequest={setAfterRequest}
      />
      <MDBox
        component="li"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        mt={2}
        sx={{ overflowWrap: "anywhere" }}
      >
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          bgColor="grey-100"
          sx={{
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            boxShadow: "5",
          }}
        >
          <MDBox
            display="flex"
            // justifyContent="space-between"
            // alignItems="flex-start"
            flexDirection={{ xs: "column", md: "row" }}
            p={1}
          >
            <MDBox textAlign="end">
              {cod ? (
                <Chip
                  label={"Bisa COD"}
                  color={"success"}
                  variant="outlined"
                  size="small"
                  sx={{ mr: 0.5 }}
                />
              ) : null}
              <Chip
                variant="filled"
                style={{
                  fontWeight: "bolder",
                  backgroundColor: "#eeeef",
                  color: "black",
                }}
                size="small"
                label={DisplayId}
              />
            </MDBox>
            <MDBox
              lineHeight={0}
              width="90px"
              minWidth="120px"
              alignSelf="center"
              textAlign={{ xs: "center", md: "start" }}
            >
              <MDTypography variant="h6" fontWeight="small" textTransform="capitalize">
                {nama_seller}
              </MDTypography>
              <MDTypography variant="caption">{convertDate(tanggal)}</MDTypography>
            </MDBox>

            <MDBox mb={1} mt={2} lineHeight={0}>
              <MDTypography variant="h6" fontWeight="medium">
                {nama_product}
              </MDTypography>
              <MDTypography variant="caption" color="text">
                Komisi: {komisi}%
              </MDTypography>
            </MDBox>
            <Divider />
            <MDBox mt={1} lineHeight={0}>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="caption" color="text">
                  Kelebihan:&nbsp;&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="caption" fontWeight="medium">
                  {notes.length <= 0 ? "-" : notes}&nbsp;{kelebihan_produk}
                </MDTypography>
              </MDBox>
              <Divider />
              <MDTypography variant="caption" color="text">
                Link Product:&nbsp;&nbsp;&nbsp;
              </MDTypography>
              <br />
              <MDBox>
                {urls.map((url, index) => {
                  return (
                    <MDTypography variant="caption" fontWeight="medium" key={index}>
                      <br />
                      {index + 1}{" "}
                      <a
                        href={/http/i.test(url) ? url : `https://${url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {url.length > 40 ? url.substring(0, 40) + "..." : url}
                      </a>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(url);
                          setAlertMessage("Link Berhasil di Copy");
                          setOpenAlert(true);
                          setTimeout(() => {
                            setOpenAlert(false);
                          }, 3000);
                        }}
                      >
                        &nbsp;
                        <ContentCopy style={{ width: "0.9rem", height: "0.9rem" }} />
                      </IconButton>
                    </MDTypography>
                  );
                })}
              </MDBox>
              <Divider />
              <MDTypography variant="caption" color="text">
                Categori Video:&nbsp;&nbsp;&nbsp;
              </MDTypography>
              <br />
              <MDBox>
                <MDTypography variant="caption" fontWeight="medium">
                  <br />
                  {category}
                </MDTypography>
              </MDBox>
            </MDBox>
            {result.item_sample ? (
              <MDBox sx={{ mt: 2, mb: 1 }}>
                <Chip
                  label={`Sisa Sample: ${sisaSample()}`}
                  color={"error"}
                  variant="outlined"
                  size="small"
                />
              </MDBox>
            ) : null}
            {result.Samples[0]?.sample_tracking?.status === "delivering" ? (
              <MDBox textAlign="center" lineHeight={0.65}>
                <MDTypography
                  variant="caption"
                  color="success"
                  fontWeight="medium"
                  textAlign="center"
                >
                  Sampel telah dikirim. Jika sudah diterima Mohon konfirmasi dan lakukan pengerjaan
                  Video
                </MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
          {result.Samples?.length ? (
            <MDBox
              display="flex"
              flexDirection={{
                xs: "row",
                sm: result.Samples[0]?.sample_tracking?.status !== "delivering" ? "column" : "row",
              }}
              width={{ sm: "102px" }}
              height={{ xs: "60px", sm: "106px" }}
              alignSelf={{ sm: "end" }}
              justifyContent={"center"}
            >
              <MDBox
                sx={{
                  width: { xs: "50%", sm: "100%" },
                  height: { xs: "100%", sm: "50%" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  borderTopRightRadius: { sm: "8px" },
                  borderBottomLeftRadius: { xs: "8px", sm: "0px", md: "0px" },
                }}
              >
                {result.Samples[0]?.sample_tracking?.status === "requesting" ? (
                  <MDTypography
                    variant="caption"
                    color="warning"
                    fontWeight="medium"
                    textAlign="center"
                  >
                    Menunggu Persetujuan Seller untuk memberikan Sampel
                  </MDTypography>
                ) : null}
                {result.Samples[0]?.sample_tracking?.status === "delivering" ? (
                  <MDBox>
                    <MDBox>
                      <MDButton color="error" onClick={() => confirmSampleAndCreateContent()}>
                        Sampel Diterima
                      </MDButton>
                    </MDBox>
                  </MDBox>
                ) : null}
                {result.Samples[0]?.sample_tracking?.status === "content_making" ||
                result.Addons?.length ? (
                  <MDTypography
                    variant="caption"
                    color="error"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    Saatnya kamu membuat dan memasukkan link Konten di menu Isi Link Konten
                  </MDTypography>
                ) : null}
                {result.Samples[0]?.sample_tracking?.status === "rejected" &&
                !result.Addons?.length ? (
                  <>
                    <MDButton
                      color="error"
                      onClick={() => {
                        createWithoutSample();
                      }}
                    >
                      Buat Tanpa Sample
                    </MDButton>
                  </>
                ) : null}
              </MDBox>
            </MDBox>
          ) : null}
          {result.Addons?.length && !result.Samples?.length ? (
            <MDBox
              display="flex"
              flexDirection={{ xs: "row", sm: "column" }}
              width={{ sm: "102px" }}
              height={{ xs: "60px", sm: "106px" }}
              alignSelf={{ sm: "end" }}
              justifyContent="center"
            >
              <MDBox
                sx={{
                  width: { xs: "50%", sm: "100%" },
                  height: { xs: "100%", sm: "50%" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  borderTopRightRadius: { sm: "8px" },
                  borderBottomLeftRadius: { xs: "8px", sm: "0px", md: "0px" },
                }}
              >
                {result.Addons[0]?.Content?.approval_status === "accepted" ? (
                  <MDTypography
                    variant="caption"
                    color="error"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    Saatnya kamu membuat dan memasukkan link Konten di menu Isi Link Konten
                  </MDTypography>
                ) : null}
              </MDBox>
            </MDBox>
          ) : null}
          {!result.Addons?.length && !result.Samples?.length ? (
            <MDBox
              display="flex"
              flexDirection={{ xs: "row", sm: "column" }}
              width={{ sm: "102px" }}
              height={{ xs: "60px", sm: "106px" }}
              alignSelf={{ sm: "end" }}
            >
              {(auth.ranking === "Jendral" || auth.ranking === "Pahlawan") &&
              result.item_sample &&
              sisaSample() !== 0 ? (
                <MDBox
                  sx={{
                    width: { xs: "50%", sm: "100%" },
                    height: { xs: "100%", sm: "50%" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    borderTopRightRadius: { sm: "8px" },
                    borderBottomLeftRadius: { xs: "8px", sm: "0px", md: "0px" },
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <MDTypography variant="caption" color="dark" fontWeight="bold">
                    Ajukan Sample
                  </MDTypography>
                </MDBox>
              ) : null}
              <MDBox
                sx={{
                  minWidth: "102px",
                  width: { xs: "50%", sm: "100%" },
                  height: { xs: "100%", sm: "50%" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
                onClick={() => {
                  createWithoutSample();
                }}
              >
                <MDBox
                  sx={{
                    width: "90%",
                    height: "70%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    background: "#bd454f",
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                >
                  <MDTypography variant="caption" color="white" fontWeight="bold">
                    Buat Tanpa Sample
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          ) : null}
        </MDBox>
      </MDBox>
    </>
  );
}

// Typechecking props for the Bill
EtalaseCard.propTypes = {
  DisplayId: PropTypes.number.isRequired,
  nama_seller: PropTypes.string.isRequired,
  komisi: PropTypes.number.isRequired,
  notes: PropTypes.string.isRequired,
  linkProduct: PropTypes.string.isRequired,
  tanggal: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  kelebihan_produk: PropTypes.string.isRequired,
  cod: PropTypes.bool.isRequired,
  jumlah_sample: PropTypes.number.isRequired,
};
