import * as React from "react";
import { useState } from "react";

import { NavLink } from "react-router-dom";
import { useLKSGabungan } from "context/content";

import Grid from "@mui/material/Grid";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import LKSGabunganCard from "layouts/billing/components/Bill/LKSGabunganCard";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, CircularProgress, Skeleton, Stack } from "@mui/material";

export default function LKS2() {
  // const [loading, setLoading] = useState(true);
  const { onProcess, loading, data, res } = useLKSGabungan();

  useState(() => {
    onProcess();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={2} sx={{ display: "flex", flexDirection: "column" }}>
        <MDBox mt={2} mb={2}>
          <MDTypography variant="h5" fontWeight="medium">
            <MDButton
              variant="outlined"
              size="small"
              color="primary"
              component={NavLink}
              to="/dashboard"
              iconOnly
            >
              <ArrowBackIcon />
            </MDButton>
            &nbsp;&nbsp; Like, Komen, Share
          </MDTypography>
          <MDTypography variant="caption" ml="40px" sx={{ position: "absolute" }}>
            Yuk support creator lain..
          </MDTypography>
        </MDBox>
        <MDBox mt={2}>
          <MDBox sx={{ display: "flex", flexDirection: "row" }}>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
              {loading ? (
                <>
                  <Stack spacing={1} m={1}>
                    <Skeleton variant="rectangular" width={335} height={60} />
                  </Stack>
                  <Stack spacing={1} m={1}>
                    <Skeleton variant="rectangular" width={335} height={60} />
                  </Stack>
                  <Stack spacing={1} m={1}>
                    <Skeleton variant="rectangular" width={335} height={60} />
                  </Stack>
                  <Stack spacing={1} m={1}>
                    <Skeleton variant="rectangular" width={335} height={60} />
                  </Stack>
                  <Stack spacing={1} m={1}>
                    <Skeleton variant="rectangular" width={335} height={60} />
                  </Stack>
                  <Stack spacing={1} m={1}>
                    <Skeleton variant="rectangular" width={335} height={60} />
                  </Stack>
                </>
              ) : res.message || data.allLKS?.length === 0 ? (
                <Alert severity={res.message ? "error" : "warning"}>
                  {res.message || "No Data"}
                </Alert>
              ) : (
                data.allLKS?.map((x, i) => <LKSGabunganCard key={i} data={x} />)
              )}
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
