// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import images
import Logo from "./jualkirim.png";
import { Link } from "react-router-dom";

function Breadcrumbs({ light }) {
  return (
    <MDBox mr={{ xs: 0, xl: 8 }} mb={1} pt={"12px"}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      />
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {/* {title.replace("-", " ")} */}
        <Link to="/dashboard">
          <Box
            component="img"
            sx={{
              height: { xs: 35, md: 50 },
              width: { xs: 70, md: 100 },
            }}
            alt="JualKirim Logo"
            src={Logo}
          />
        </Link>
        {/* <img alt="JualKirim Logo" src={Logo} style={{ width: "70px" }} /> */}
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  light: PropTypes.bool,
};

export default Breadcrumbs;
