import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components

// @mui material components
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsBarChart configurations
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

function ReportsBarChart({ title, description, name }) {
  return (
    <MDBox sx={{ width: "100%" }}>
      <MDBox
        sx={{
          bgcolor: "#ffffff",
          borderColor: "grey.500",
          borderRadius: 2,
          height: "135px",
          boxShadow: 5
        }}
      >
        <MDBox pt={1} pb={1} px={1}>
          <MDTypography variant="h3" textAlign="center">
            {title}
          </MDTypography>
          <Divider sx={{ mb: 0, p: 0 }} />
          <MDBox>
            <MDTypography
              mt={2}
              mb={2}
              textAlign="center"
              fontSize={{ xs: "3.1vw", sm: "15px", md: "15px" }}
              fontWeight="medium"
              variant="h5"
            >
              {description}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          background: "linear-gradient(to left top, #bd454f, #343333)",
          mt: -2,
          borderColor: "grey.500",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          height: "50px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          boxShadow: 5 
        }}
      >
        <Button
          component={NavLink}
          to={description === "Tawaran Konten" ? "/konten/penawaran" : "/konten/isi-link"}
          variant="contained"
          size="small"
          sx={[
            { border: 2, background: "none", borderColor: "#ffffff" },
            { "&:hover": { background: "#bd454f" } },
          ]}
        >
          <MDTypography variant="body" textAlign="center" sx={{ color: "#ffffff" }}>
            {name}
          </MDTypography>
        </Button>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  title: PropTypes.number.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
};

export default ReportsBarChart;
