import { createContext, useReducer, useCallback } from "react";

const initialState = {
  loading: false,
  notif: 0,
  res: {
    status: "",
    message: "",
    title: "",
    autoHide: 0,
  },
};

const GlobalContext = createContext({
  ...initialState,
  setLoading: (newValue) => {},
  setNotif: (newValue) => {},
  setRes: (newValue) => {},
  clear: (_) => {},
});

function globalReducer(state, action) {
  switch (action.type) {
    case "ON_LOAD":
      return { ...state, loading: action.payload };
    case "ON_RES":
      return { ...state, res: { ...state.res, ...action.payload } };
    case "ON_CLEAR":
      return { ...state, res: initialState.res };
    case "ON_NOTIF":
      return { ...state, notif: action.payload };
    default:
      return state;
  }
}

function GlobalProvider(props) {
  const [state, dispatch] = useReducer(globalReducer, {
      ...initialState,
      setLoading: (value) => null,
      setNotif: (value) => null,
      setRes: ({ status = "", message = "", title = "", autoHide = 0 }) => null,
      clear: () => null,
    }),
    setLoading = (payload) => dispatch({ type: "ON_LOAD", payload }),
    setRes = useCallback((payload) => dispatch({ type: "ON_RES", payload }), [dispatch]),
    setNotif = useCallback((payload) => dispatch({ type: "ON_NOTIF", payload }), [dispatch]),
    clear = (_) => dispatch({ type: "ON_CLEAR", payload: null });

  return (
    <GlobalContext.Provider value={{ ...state, setLoading, setRes, setNotif, clear }} {...props} />
  );
}

export { GlobalContext, GlobalProvider };
