export const provinceWithCityWithSubdistrict = [
  {
    province_id: "1",
    province: "Bali",
    cities: [
      {
        city_id: "17",
        type: "Kabupaten",
        city_name: "Badung",
        subdistricts: [
          {
            subdistrict_id: "258",
            subdistrict_name: "Abiansemal",
          },
          {
            subdistrict_id: "259",
            subdistrict_name: "Kuta",
          },
          {
            subdistrict_id: "260",
            subdistrict_name: "Kuta Selatan",
          },
          {
            subdistrict_id: "261",
            subdistrict_name: "Kuta Utara",
          },
          {
            subdistrict_id: "262",
            subdistrict_name: "Mengwi",
          },
          {
            subdistrict_id: "263",
            subdistrict_name: "Petang",
          },
        ],
      },
      {
        city_id: "32",
        type: "Kabupaten",
        city_name: "Bangli",
        subdistricts: [
          {
            subdistrict_id: "472",
            subdistrict_name: "Bangli",
          },
          {
            subdistrict_id: "473",
            subdistrict_name: "Kintamani",
          },
          {
            subdistrict_id: "474",
            subdistrict_name: "Susut",
          },
          {
            subdistrict_id: "475",
            subdistrict_name: "Tembuku",
          },
        ],
      },
      {
        city_id: "94",
        type: "Kabupaten",
        city_name: "Buleleng",
        subdistricts: [
          {
            subdistrict_id: "1279",
            subdistrict_name: "Banjar",
          },
          {
            subdistrict_id: "1280",
            subdistrict_name: "Buleleng",
          },
          {
            subdistrict_id: "1281",
            subdistrict_name: "Busungbiu",
          },
          {
            subdistrict_id: "1282",
            subdistrict_name: "Gerokgak",
          },
          {
            subdistrict_id: "1283",
            subdistrict_name: "Kubutambahan",
          },
          {
            subdistrict_id: "1284",
            subdistrict_name: "Sawan",
          },
          {
            subdistrict_id: "1285",
            subdistrict_name: "Seririt",
          },
          {
            subdistrict_id: "1286",
            subdistrict_name: "Sukasada",
          },
          {
            subdistrict_id: "1287",
            subdistrict_name: "Tejakula",
          },
        ],
      },
      {
        city_id: "114",
        type: "Kota",
        city_name: "Denpasar",
        subdistricts: [
          {
            subdistrict_id: "1573",
            subdistrict_name: "Denpasar Barat",
          },
          {
            subdistrict_id: "1574",
            subdistrict_name: "Denpasar Selatan",
          },
          {
            subdistrict_id: "1575",
            subdistrict_name: "Denpasar Timur",
          },
          {
            subdistrict_id: "1576",
            subdistrict_name: "Denpasar Utara",
          },
        ],
      },
      {
        city_id: "128",
        type: "Kabupaten",
        city_name: "Gianyar",
        subdistricts: [
          {
            subdistrict_id: "1764",
            subdistrict_name: "Belah Batuh (Blahbatuh)",
          },
          {
            subdistrict_id: "1765",
            subdistrict_name: "Gianyar",
          },
          {
            subdistrict_id: "1766",
            subdistrict_name: "Payangan",
          },
          {
            subdistrict_id: "1767",
            subdistrict_name: "Sukawati",
          },
          {
            subdistrict_id: "1768",
            subdistrict_name: "Tampak Siring",
          },
          {
            subdistrict_id: "1769",
            subdistrict_name: "Tegallalang",
          },
          {
            subdistrict_id: "1770",
            subdistrict_name: "Ubud",
          },
        ],
      },
      {
        city_id: "161",
        type: "Kabupaten",
        city_name: "Jembrana",
        subdistricts: [
          {
            subdistrict_id: "2232",
            subdistrict_name: "Jembrana",
          },
          {
            subdistrict_id: "2233",
            subdistrict_name: "Melaya",
          },
          {
            subdistrict_id: "2234",
            subdistrict_name: "Mendoyo",
          },
          {
            subdistrict_id: "2235",
            subdistrict_name: "Negara",
          },
          {
            subdistrict_id: "2236",
            subdistrict_name: "Pekutatan",
          },
        ],
      },
      {
        city_id: "170",
        type: "Kabupaten",
        city_name: "Karangasem",
        subdistricts: [
          {
            subdistrict_id: "2370",
            subdistrict_name: "Abang",
          },
          {
            subdistrict_id: "2371",
            subdistrict_name: "Bebandem",
          },
          {
            subdistrict_id: "2372",
            subdistrict_name: "Karang Asem",
          },
          {
            subdistrict_id: "2373",
            subdistrict_name: "Kubu",
          },
          {
            subdistrict_id: "2374",
            subdistrict_name: "Manggis",
          },
          {
            subdistrict_id: "2375",
            subdistrict_name: "Rendang",
          },
          {
            subdistrict_id: "2376",
            subdistrict_name: "Selat",
          },
          {
            subdistrict_id: "2377",
            subdistrict_name: "Sidemen",
          },
        ],
      },
      {
        city_id: "197",
        type: "Kabupaten",
        city_name: "Klungkung",
        subdistricts: [
          {
            subdistrict_id: "2748",
            subdistrict_name: "Banjarangkan",
          },
          {
            subdistrict_id: "2749",
            subdistrict_name: "Dawan",
          },
          {
            subdistrict_id: "2750",
            subdistrict_name: "Klungkung",
          },
          {
            subdistrict_id: "2751",
            subdistrict_name: "Nusapenida",
          },
        ],
      },
      {
        city_id: "447",
        type: "Kabupaten",
        city_name: "Tabanan",
        subdistricts: [
          {
            subdistrict_id: "6179",
            subdistrict_name: "Baturiti",
          },
          {
            subdistrict_id: "6180",
            subdistrict_name: "Kediri",
          },
          {
            subdistrict_id: "6181",
            subdistrict_name: "Kerambitan",
          },
          {
            subdistrict_id: "6182",
            subdistrict_name: "Marga",
          },
          {
            subdistrict_id: "6183",
            subdistrict_name: "Penebel",
          },
          {
            subdistrict_id: "6184",
            subdistrict_name: "Pupuan",
          },
          {
            subdistrict_id: "6185",
            subdistrict_name: "Selemadeg",
          },
          {
            subdistrict_id: "6186",
            subdistrict_name: "Selemadeg / Salamadeg Timur",
          },
          {
            subdistrict_id: "6187",
            subdistrict_name: "Selemadeg / Salemadeg Barat",
          },
          {
            subdistrict_id: "6188",
            subdistrict_name: "Tabanan",
          },
        ],
      },
    ],
  },
  {
    province_id: "2",
    province: "Bangka Belitung",
    cities: [
      {
        city_id: "27",
        type: "Kabupaten",
        city_name: "Bangka",
        subdistricts: [
          {
            subdistrict_id: "426",
            subdistrict_name: "Bakam",
          },
          {
            subdistrict_id: "427",
            subdistrict_name: "Belinyu",
          },
          {
            subdistrict_id: "428",
            subdistrict_name: "Mendo Barat",
          },
          {
            subdistrict_id: "429",
            subdistrict_name: "Merawang",
          },
          {
            subdistrict_id: "430",
            subdistrict_name: "Pemali",
          },
          {
            subdistrict_id: "431",
            subdistrict_name: "Puding Besar",
          },
          {
            subdistrict_id: "432",
            subdistrict_name: "Riau Silip",
          },
          {
            subdistrict_id: "433",
            subdistrict_name: "Sungai Liat",
          },
        ],
      },
      {
        city_id: "28",
        type: "Kabupaten",
        city_name: "Bangka Barat",
        subdistricts: [
          {
            subdistrict_id: "434",
            subdistrict_name: "Jebus",
          },
          {
            subdistrict_id: "435",
            subdistrict_name: "Kelapa",
          },
          {
            subdistrict_id: "436",
            subdistrict_name: "Mentok (Muntok)",
          },
          {
            subdistrict_id: "437",
            subdistrict_name: "Parittiga",
          },
          {
            subdistrict_id: "438",
            subdistrict_name: "Simpang Teritip",
          },
          {
            subdistrict_id: "439",
            subdistrict_name: "Tempilang",
          },
        ],
      },
      {
        city_id: "29",
        type: "Kabupaten",
        city_name: "Bangka Selatan",
        subdistricts: [
          {
            subdistrict_id: "440",
            subdistrict_name: "Air Gegas",
          },
          {
            subdistrict_id: "441",
            subdistrict_name: "Kepulauan Pongok",
          },
          {
            subdistrict_id: "442",
            subdistrict_name: "Lepar Pongok",
          },
          {
            subdistrict_id: "443",
            subdistrict_name: "Payung",
          },
          {
            subdistrict_id: "444",
            subdistrict_name: "Pulau Besar",
          },
          {
            subdistrict_id: "445",
            subdistrict_name: "Simpang Rimba",
          },
          {
            subdistrict_id: "446",
            subdistrict_name: "Toboali",
          },
          {
            subdistrict_id: "447",
            subdistrict_name: "Tukak Sadai",
          },
        ],
      },
      {
        city_id: "30",
        type: "Kabupaten",
        city_name: "Bangka Tengah",
        subdistricts: [
          {
            subdistrict_id: "448",
            subdistrict_name: "Koba",
          },
          {
            subdistrict_id: "449",
            subdistrict_name: "Lubuk Besar",
          },
          {
            subdistrict_id: "450",
            subdistrict_name: "Namang",
          },
          {
            subdistrict_id: "451",
            subdistrict_name: "Pangkalan Baru",
          },
          {
            subdistrict_id: "452",
            subdistrict_name: "Simpang Katis",
          },
          {
            subdistrict_id: "453",
            subdistrict_name: "Sungai Selan",
          },
        ],
      },
      {
        city_id: "56",
        type: "Kabupaten",
        city_name: "Belitung",
        subdistricts: [
          {
            subdistrict_id: "761",
            subdistrict_name: "Badau",
          },
          {
            subdistrict_id: "762",
            subdistrict_name: "Membalong",
          },
          {
            subdistrict_id: "763",
            subdistrict_name: "Selat Nasik",
          },
          {
            subdistrict_id: "764",
            subdistrict_name: "Sijuk",
          },
          {
            subdistrict_id: "765",
            subdistrict_name: "Tanjung Pandan",
          },
        ],
      },
      {
        city_id: "57",
        type: "Kabupaten",
        city_name: "Belitung Timur",
        subdistricts: [
          {
            subdistrict_id: "766",
            subdistrict_name: "Damar",
          },
          {
            subdistrict_id: "767",
            subdistrict_name: "Dendang",
          },
          {
            subdistrict_id: "768",
            subdistrict_name: "Gantung",
          },
          {
            subdistrict_id: "769",
            subdistrict_name: "Kelapa Kampit",
          },
          {
            subdistrict_id: "770",
            subdistrict_name: "Manggar",
          },
          {
            subdistrict_id: "771",
            subdistrict_name: "Simpang Pesak",
          },
          {
            subdistrict_id: "772",
            subdistrict_name: "Simpang Renggiang",
          },
        ],
      },
      {
        city_id: "334",
        type: "Kota",
        city_name: "Pangkal Pinang",
        subdistricts: [
          {
            subdistrict_id: "4713",
            subdistrict_name: "Bukit Intan",
          },
          {
            subdistrict_id: "4714",
            subdistrict_name: "Gabek",
          },
          {
            subdistrict_id: "4715",
            subdistrict_name: "Gerunggang",
          },
          {
            subdistrict_id: "4716",
            subdistrict_name: "Girimaya",
          },
          {
            subdistrict_id: "4717",
            subdistrict_name: "Pangkal Balam",
          },
          {
            subdistrict_id: "4718",
            subdistrict_name: "Rangkui",
          },
          {
            subdistrict_id: "4719",
            subdistrict_name: "Taman Sari",
          },
        ],
      },
    ],
  },
  {
    province_id: "3",
    province: "Banten",
    cities: [
      {
        city_id: "106",
        type: "Kota",
        city_name: "Cilegon",
        subdistricts: [
          {
            subdistrict_id: "1461",
            subdistrict_name: "Cibeber",
          },
          {
            subdistrict_id: "1462",
            subdistrict_name: "Cilegon",
          },
          {
            subdistrict_id: "1463",
            subdistrict_name: "Citangkil",
          },
          {
            subdistrict_id: "1464",
            subdistrict_name: "Ciwandan",
          },
          {
            subdistrict_id: "1465",
            subdistrict_name: "Gerogol",
          },
          {
            subdistrict_id: "1466",
            subdistrict_name: "Jombang",
          },
          {
            subdistrict_id: "1467",
            subdistrict_name: "Pulomerak",
          },
          {
            subdistrict_id: "1468",
            subdistrict_name: "Purwakarta",
          },
        ],
      },
      {
        city_id: "232",
        type: "Kabupaten",
        city_name: "Lebak",
        subdistricts: [
          {
            subdistrict_id: "3298",
            subdistrict_name: "Banjarsari",
          },
          {
            subdistrict_id: "3299",
            subdistrict_name: "Bayah",
          },
          {
            subdistrict_id: "3300",
            subdistrict_name: "Bojongmanik",
          },
          {
            subdistrict_id: "3301",
            subdistrict_name: "Cibadak",
          },
          {
            subdistrict_id: "3302",
            subdistrict_name: "Cibeber",
          },
          {
            subdistrict_id: "3303",
            subdistrict_name: "Cigemblong",
          },
          {
            subdistrict_id: "3304",
            subdistrict_name: "Cihara",
          },
          {
            subdistrict_id: "3305",
            subdistrict_name: "Cijaku",
          },
          {
            subdistrict_id: "3306",
            subdistrict_name: "Cikulur",
          },
          {
            subdistrict_id: "3307",
            subdistrict_name: "Cileles",
          },
          {
            subdistrict_id: "3308",
            subdistrict_name: "Cilograng",
          },
          {
            subdistrict_id: "3309",
            subdistrict_name: "Cimarga",
          },
          {
            subdistrict_id: "3310",
            subdistrict_name: "Cipanas",
          },
          {
            subdistrict_id: "3311",
            subdistrict_name: "Cirinten",
          },
          {
            subdistrict_id: "3312",
            subdistrict_name: "Curugbitung",
          },
          {
            subdistrict_id: "3313",
            subdistrict_name: "Gunung Kencana",
          },
          {
            subdistrict_id: "3314",
            subdistrict_name: "Kalanganyar",
          },
          {
            subdistrict_id: "3315",
            subdistrict_name: "Lebakgedong",
          },
          {
            subdistrict_id: "3316",
            subdistrict_name: "Leuwidamar",
          },
          {
            subdistrict_id: "3317",
            subdistrict_name: "Maja",
          },
          {
            subdistrict_id: "3318",
            subdistrict_name: "Malingping",
          },
          {
            subdistrict_id: "3319",
            subdistrict_name: "Muncang",
          },
          {
            subdistrict_id: "3320",
            subdistrict_name: "Panggarangan",
          },
          {
            subdistrict_id: "3321",
            subdistrict_name: "Rangkasbitung",
          },
          {
            subdistrict_id: "3322",
            subdistrict_name: "Sajira",
          },
          {
            subdistrict_id: "3323",
            subdistrict_name: "Sobang",
          },
          {
            subdistrict_id: "3324",
            subdistrict_name: "Wanasalam",
          },
          {
            subdistrict_id: "3325",
            subdistrict_name: "Warunggunung",
          },
        ],
      },
      {
        city_id: "331",
        type: "Kabupaten",
        city_name: "Pandeglang",
        subdistricts: [
          {
            subdistrict_id: "4655",
            subdistrict_name: "Angsana",
          },
          {
            subdistrict_id: "4656",
            subdistrict_name: "Banjar",
          },
          {
            subdistrict_id: "4657",
            subdistrict_name: "Bojong",
          },
          {
            subdistrict_id: "4658",
            subdistrict_name: "Cadasari",
          },
          {
            subdistrict_id: "4659",
            subdistrict_name: "Carita",
          },
          {
            subdistrict_id: "4660",
            subdistrict_name: "Cibaliung",
          },
          {
            subdistrict_id: "4661",
            subdistrict_name: "Cibitung",
          },
          {
            subdistrict_id: "4662",
            subdistrict_name: "Cigeulis",
          },
          {
            subdistrict_id: "4663",
            subdistrict_name: "Cikeudal (Cikedal)",
          },
          {
            subdistrict_id: "4664",
            subdistrict_name: "Cikeusik",
          },
          {
            subdistrict_id: "4665",
            subdistrict_name: "Cimanggu",
          },
          {
            subdistrict_id: "4666",
            subdistrict_name: "Cimanuk",
          },
          {
            subdistrict_id: "4667",
            subdistrict_name: "Cipeucang",
          },
          {
            subdistrict_id: "4668",
            subdistrict_name: "Cisata",
          },
          {
            subdistrict_id: "4669",
            subdistrict_name: "Jiput",
          },
          {
            subdistrict_id: "4670",
            subdistrict_name: "Kaduhejo",
          },
          {
            subdistrict_id: "4671",
            subdistrict_name: "Karang Tanjung",
          },
          {
            subdistrict_id: "4672",
            subdistrict_name: "Koroncong",
          },
          {
            subdistrict_id: "4673",
            subdistrict_name: "Labuan",
          },
          {
            subdistrict_id: "4674",
            subdistrict_name: "Majasari",
          },
          {
            subdistrict_id: "4675",
            subdistrict_name: "Mandalawangi",
          },
          {
            subdistrict_id: "4676",
            subdistrict_name: "Mekarjaya",
          },
          {
            subdistrict_id: "4677",
            subdistrict_name: "Menes",
          },
          {
            subdistrict_id: "4678",
            subdistrict_name: "Munjul",
          },
          {
            subdistrict_id: "4679",
            subdistrict_name: "Pagelaran",
          },
          {
            subdistrict_id: "4680",
            subdistrict_name: "Pandeglang",
          },
          {
            subdistrict_id: "4681",
            subdistrict_name: "Panimbang",
          },
          {
            subdistrict_id: "4682",
            subdistrict_name: "Patia",
          },
          {
            subdistrict_id: "4683",
            subdistrict_name: "Picung",
          },
          {
            subdistrict_id: "4684",
            subdistrict_name: "Pulosari",
          },
          {
            subdistrict_id: "4685",
            subdistrict_name: "Saketi",
          },
          {
            subdistrict_id: "4686",
            subdistrict_name: "Sindangresmi",
          },
          {
            subdistrict_id: "4687",
            subdistrict_name: "Sobang",
          },
          {
            subdistrict_id: "4688",
            subdistrict_name: "Sukaresmi",
          },
          {
            subdistrict_id: "4689",
            subdistrict_name: "Sumur",
          },
        ],
      },
      {
        city_id: "402",
        type: "Kabupaten",
        city_name: "Serang",
        subdistricts: [
          {
            subdistrict_id: "5540",
            subdistrict_name: "Anyar",
          },
          {
            subdistrict_id: "5541",
            subdistrict_name: "Bandung",
          },
          {
            subdistrict_id: "5542",
            subdistrict_name: "Baros",
          },
          {
            subdistrict_id: "5543",
            subdistrict_name: "Binuang",
          },
          {
            subdistrict_id: "5544",
            subdistrict_name: "Bojonegara",
          },
          {
            subdistrict_id: "5545",
            subdistrict_name: "Carenang (Cerenang)",
          },
          {
            subdistrict_id: "5546",
            subdistrict_name: "Cikande",
          },
          {
            subdistrict_id: "5547",
            subdistrict_name: "Cikeusal",
          },
          {
            subdistrict_id: "5548",
            subdistrict_name: "Cinangka",
          },
          {
            subdistrict_id: "5549",
            subdistrict_name: "Ciomas",
          },
          {
            subdistrict_id: "5550",
            subdistrict_name: "Ciruas",
          },
          {
            subdistrict_id: "5551",
            subdistrict_name: "Gunungsari",
          },
          {
            subdistrict_id: "5552",
            subdistrict_name: "Jawilan",
          },
          {
            subdistrict_id: "5553",
            subdistrict_name: "Kibin",
          },
          {
            subdistrict_id: "5554",
            subdistrict_name: "Kopo",
          },
          {
            subdistrict_id: "5555",
            subdistrict_name: "Kragilan",
          },
          {
            subdistrict_id: "5556",
            subdistrict_name: "Kramatwatu",
          },
          {
            subdistrict_id: "5557",
            subdistrict_name: "Lebak Wangi",
          },
          {
            subdistrict_id: "5558",
            subdistrict_name: "Mancak",
          },
          {
            subdistrict_id: "5559",
            subdistrict_name: "Pabuaran",
          },
          {
            subdistrict_id: "5560",
            subdistrict_name: "Padarincang",
          },
          {
            subdistrict_id: "5561",
            subdistrict_name: "Pamarayan",
          },
          {
            subdistrict_id: "5562",
            subdistrict_name: "Petir",
          },
          {
            subdistrict_id: "5563",
            subdistrict_name: "Pontang",
          },
          {
            subdistrict_id: "5564",
            subdistrict_name: "Pulo Ampel",
          },
          {
            subdistrict_id: "5565",
            subdistrict_name: "Tanara",
          },
          {
            subdistrict_id: "5566",
            subdistrict_name: "Tirtayasa",
          },
          {
            subdistrict_id: "5567",
            subdistrict_name: "Tunjung Teja",
          },
          {
            subdistrict_id: "5568",
            subdistrict_name: "Waringin Kurung",
          },
        ],
      },
      {
        city_id: "403",
        type: "Kota",
        city_name: "Serang",
        subdistricts: [
          {
            subdistrict_id: "5569",
            subdistrict_name: "Cipocok Jaya",
          },
          {
            subdistrict_id: "5570",
            subdistrict_name: "Curug",
          },
          {
            subdistrict_id: "5571",
            subdistrict_name: "Kasemen",
          },
          {
            subdistrict_id: "5572",
            subdistrict_name: "Serang",
          },
          {
            subdistrict_id: "5573",
            subdistrict_name: "Taktakan",
          },
          {
            subdistrict_id: "5574",
            subdistrict_name: "Walantaka",
          },
        ],
      },
      {
        city_id: "455",
        type: "Kabupaten",
        city_name: "Tangerang",
        subdistricts: [
          {
            subdistrict_id: "6268",
            subdistrict_name: "Balaraja",
          },
          {
            subdistrict_id: "6269",
            subdistrict_name: "Cikupa",
          },
          {
            subdistrict_id: "6270",
            subdistrict_name: "Cisauk",
          },
          {
            subdistrict_id: "6271",
            subdistrict_name: "Cisoka",
          },
          {
            subdistrict_id: "6272",
            subdistrict_name: "Curug",
          },
          {
            subdistrict_id: "6273",
            subdistrict_name: "Gunung Kaler",
          },
          {
            subdistrict_id: "6274",
            subdistrict_name: "Jambe",
          },
          {
            subdistrict_id: "6275",
            subdistrict_name: "Jayanti",
          },
          {
            subdistrict_id: "6276",
            subdistrict_name: "Kelapa Dua",
          },
          {
            subdistrict_id: "6277",
            subdistrict_name: "Kemiri",
          },
          {
            subdistrict_id: "6278",
            subdistrict_name: "Kosambi",
          },
          {
            subdistrict_id: "6279",
            subdistrict_name: "Kresek",
          },
          {
            subdistrict_id: "6280",
            subdistrict_name: "Kronjo",
          },
          {
            subdistrict_id: "6281",
            subdistrict_name: "Legok",
          },
          {
            subdistrict_id: "6282",
            subdistrict_name: "Mauk",
          },
          {
            subdistrict_id: "6283",
            subdistrict_name: "Mekar Baru",
          },
          {
            subdistrict_id: "6284",
            subdistrict_name: "Pagedangan",
          },
          {
            subdistrict_id: "6285",
            subdistrict_name: "Pakuhaji",
          },
          {
            subdistrict_id: "6286",
            subdistrict_name: "Panongan",
          },
          {
            subdistrict_id: "6287",
            subdistrict_name: "Pasar Kemis",
          },
          {
            subdistrict_id: "6288",
            subdistrict_name: "Rajeg",
          },
          {
            subdistrict_id: "6289",
            subdistrict_name: "Sepatan",
          },
          {
            subdistrict_id: "6290",
            subdistrict_name: "Sepatan Timur",
          },
          {
            subdistrict_id: "6291",
            subdistrict_name: "Sindang Jaya",
          },
          {
            subdistrict_id: "6292",
            subdistrict_name: "Solear",
          },
          {
            subdistrict_id: "6293",
            subdistrict_name: "Sukadiri",
          },
          {
            subdistrict_id: "6294",
            subdistrict_name: "Sukamulya",
          },
          {
            subdistrict_id: "6295",
            subdistrict_name: "Teluknaga",
          },
          {
            subdistrict_id: "6296",
            subdistrict_name: "Tigaraksa",
          },
        ],
      },
      {
        city_id: "456",
        type: "Kota",
        city_name: "Tangerang",
        subdistricts: [
          {
            subdistrict_id: "6297",
            subdistrict_name: "Batuceper",
          },
          {
            subdistrict_id: "6298",
            subdistrict_name: "Benda",
          },
          {
            subdistrict_id: "6299",
            subdistrict_name: "Cibodas",
          },
          {
            subdistrict_id: "6300",
            subdistrict_name: "Ciledug",
          },
          {
            subdistrict_id: "6301",
            subdistrict_name: "Cipondoh",
          },
          {
            subdistrict_id: "6302",
            subdistrict_name: "Jatiuwung",
          },
          {
            subdistrict_id: "6303",
            subdistrict_name: "Karang Tengah",
          },
          {
            subdistrict_id: "6304",
            subdistrict_name: "Karawaci",
          },
          {
            subdistrict_id: "6305",
            subdistrict_name: "Larangan",
          },
          {
            subdistrict_id: "6306",
            subdistrict_name: "Neglasari",
          },
          {
            subdistrict_id: "6307",
            subdistrict_name: "Periuk",
          },
          {
            subdistrict_id: "6308",
            subdistrict_name: "Pinang (Penang)",
          },
          {
            subdistrict_id: "6309",
            subdistrict_name: "Tangerang",
          },
        ],
      },
      {
        city_id: "457",
        type: "Kota",
        city_name: "Tangerang Selatan",
        subdistricts: [
          {
            subdistrict_id: "6310",
            subdistrict_name: "Ciputat",
          },
          {
            subdistrict_id: "6311",
            subdistrict_name: "Ciputat Timur",
          },
          {
            subdistrict_id: "6312",
            subdistrict_name: "Pamulang",
          },
          {
            subdistrict_id: "6313",
            subdistrict_name: "Pondok Aren",
          },
          {
            subdistrict_id: "6314",
            subdistrict_name: "Serpong",
          },
          {
            subdistrict_id: "6315",
            subdistrict_name: "Serpong Utara",
          },
          {
            subdistrict_id: "6316",
            subdistrict_name: "Setu",
          },
        ],
      },
    ],
  },
  {
    province_id: "4",
    province: "Bengkulu",
    cities: [
      {
        city_id: "62",
        type: "Kota",
        city_name: "Bengkulu",
        subdistricts: [
          {
            subdistrict_id: "832",
            subdistrict_name: "Gading Cempaka",
          },
          {
            subdistrict_id: "833",
            subdistrict_name: "Kampung Melayu",
          },
          {
            subdistrict_id: "834",
            subdistrict_name: "Muara Bangka Hulu",
          },
          {
            subdistrict_id: "835",
            subdistrict_name: "Ratu Agung",
          },
          {
            subdistrict_id: "836",
            subdistrict_name: "Ratu Samban",
          },
          {
            subdistrict_id: "837",
            subdistrict_name: "Selebar",
          },
          {
            subdistrict_id: "838",
            subdistrict_name: "Singaran Pati",
          },
          {
            subdistrict_id: "839",
            subdistrict_name: "Sungai Serut",
          },
          {
            subdistrict_id: "840",
            subdistrict_name: "Teluk Segara",
          },
        ],
      },
      {
        city_id: "63",
        type: "Kabupaten",
        city_name: "Bengkulu Selatan",
        subdistricts: [
          {
            subdistrict_id: "841",
            subdistrict_name: "Air Nipis",
          },
          {
            subdistrict_id: "842",
            subdistrict_name: "Bunga Mas",
          },
          {
            subdistrict_id: "843",
            subdistrict_name: "Kedurang",
          },
          {
            subdistrict_id: "844",
            subdistrict_name: "Kedurang Ilir",
          },
          {
            subdistrict_id: "845",
            subdistrict_name: "Kota Manna",
          },
          {
            subdistrict_id: "846",
            subdistrict_name: "Manna",
          },
          {
            subdistrict_id: "847",
            subdistrict_name: "Pasar Manna",
          },
          {
            subdistrict_id: "848",
            subdistrict_name: "Pino",
          },
          {
            subdistrict_id: "849",
            subdistrict_name: "Pinoraya",
          },
          {
            subdistrict_id: "850",
            subdistrict_name: "Seginim",
          },
          {
            subdistrict_id: "851",
            subdistrict_name: "Ulu Manna",
          },
        ],
      },
      {
        city_id: "64",
        type: "Kabupaten",
        city_name: "Bengkulu Tengah",
        subdistricts: [
          {
            subdistrict_id: "852",
            subdistrict_name: "Bang Haji",
          },
          {
            subdistrict_id: "853",
            subdistrict_name: "Karang Tinggi",
          },
          {
            subdistrict_id: "854",
            subdistrict_name: "Merigi Kelindang",
          },
          {
            subdistrict_id: "855",
            subdistrict_name: "Merigi Sakti",
          },
          {
            subdistrict_id: "856",
            subdistrict_name: "Pagar Jati",
          },
          {
            subdistrict_id: "857",
            subdistrict_name: "Pematang Tiga",
          },
          {
            subdistrict_id: "858",
            subdistrict_name: "Pondok Kelapa",
          },
          {
            subdistrict_id: "859",
            subdistrict_name: "Pondok Kubang",
          },
          {
            subdistrict_id: "860",
            subdistrict_name: "Taba Penanjung",
          },
          {
            subdistrict_id: "861",
            subdistrict_name: "Talang Empat",
          },
        ],
      },
      {
        city_id: "65",
        type: "Kabupaten",
        city_name: "Bengkulu Utara",
        subdistricts: [
          {
            subdistrict_id: "862",
            subdistrict_name: "Air Besi",
          },
          {
            subdistrict_id: "863",
            subdistrict_name: "Air Napal",
          },
          {
            subdistrict_id: "864",
            subdistrict_name: "Air Padang",
          },
          {
            subdistrict_id: "865",
            subdistrict_name: "Arga Makmur",
          },
          {
            subdistrict_id: "866",
            subdistrict_name: "Arma Jaya",
          },
          {
            subdistrict_id: "867",
            subdistrict_name: "Batik Nau",
          },
          {
            subdistrict_id: "868",
            subdistrict_name: "Enggano",
          },
          {
            subdistrict_id: "869",
            subdistrict_name: "Giri Mulia",
          },
          {
            subdistrict_id: "870",
            subdistrict_name: "Hulu Palik",
          },
          {
            subdistrict_id: "871",
            subdistrict_name: "Kerkap",
          },
          {
            subdistrict_id: "872",
            subdistrict_name: "Ketahun",
          },
          {
            subdistrict_id: "873",
            subdistrict_name: "Lais",
          },
          {
            subdistrict_id: "874",
            subdistrict_name: "Napal Putih",
          },
          {
            subdistrict_id: "875",
            subdistrict_name: "Padang Jaya",
          },
          {
            subdistrict_id: "876",
            subdistrict_name: "Putri Hijau",
          },
          {
            subdistrict_id: "877",
            subdistrict_name: "Tanjung Agung Palik",
          },
          {
            subdistrict_id: "878",
            subdistrict_name: "Ulok Kupai",
          },
        ],
      },
      {
        city_id: "175",
        type: "Kabupaten",
        city_name: "Kaur",
        subdistricts: [
          {
            subdistrict_id: "2450",
            subdistrict_name: "Kaur Selatan",
          },
          {
            subdistrict_id: "2451",
            subdistrict_name: "Kaur Tengah",
          },
          {
            subdistrict_id: "2452",
            subdistrict_name: "Kaur Utara",
          },
          {
            subdistrict_id: "2453",
            subdistrict_name: "Kelam Tengah",
          },
          {
            subdistrict_id: "2454",
            subdistrict_name: "Kinal",
          },
          {
            subdistrict_id: "2455",
            subdistrict_name: "Luas",
          },
          {
            subdistrict_id: "2456",
            subdistrict_name: "Lungkang Kule",
          },
          {
            subdistrict_id: "2457",
            subdistrict_name: "Maje",
          },
          {
            subdistrict_id: "2458",
            subdistrict_name: "Muara Sahung",
          },
          {
            subdistrict_id: "2459",
            subdistrict_name: "Nasal",
          },
          {
            subdistrict_id: "2460",
            subdistrict_name: "Padang Guci Hilir",
          },
          {
            subdistrict_id: "2461",
            subdistrict_name: "Padang Guci Hulu",
          },
          {
            subdistrict_id: "2462",
            subdistrict_name: "Semidang Gumai (Gumay)",
          },
          {
            subdistrict_id: "2463",
            subdistrict_name: "Tanjung Kemuning",
          },
          {
            subdistrict_id: "2464",
            subdistrict_name: "Tetap (Muara Tetap)",
          },
        ],
      },
      {
        city_id: "183",
        type: "Kabupaten",
        city_name: "Kepahiang",
        subdistricts: [
          {
            subdistrict_id: "2563",
            subdistrict_name: "Bermani Ilir",
          },
          {
            subdistrict_id: "2564",
            subdistrict_name: "Kebawetan (Kabawetan)",
          },
          {
            subdistrict_id: "2565",
            subdistrict_name: "Kepahiang",
          },
          {
            subdistrict_id: "2566",
            subdistrict_name: "Merigi",
          },
          {
            subdistrict_id: "2567",
            subdistrict_name: "Muara Kemumu",
          },
          {
            subdistrict_id: "2568",
            subdistrict_name: "Seberang Musi",
          },
          {
            subdistrict_id: "2569",
            subdistrict_name: "Tebat Karai",
          },
          {
            subdistrict_id: "2570",
            subdistrict_name: "Ujan Mas",
          },
        ],
      },
      {
        city_id: "233",
        type: "Kabupaten",
        city_name: "Lebong",
        subdistricts: [
          {
            subdistrict_id: "3326",
            subdistrict_name: "Amen",
          },
          {
            subdistrict_id: "3327",
            subdistrict_name: "Bingin Kuning",
          },
          {
            subdistrict_id: "3328",
            subdistrict_name: "Lebong Atas",
          },
          {
            subdistrict_id: "3329",
            subdistrict_name: "Lebong Sakti",
          },
          {
            subdistrict_id: "3330",
            subdistrict_name: "Lebong Selatan",
          },
          {
            subdistrict_id: "3331",
            subdistrict_name: "Lebong Tengah",
          },
          {
            subdistrict_id: "3332",
            subdistrict_name: "Lebong Utara",
          },
          {
            subdistrict_id: "3333",
            subdistrict_name: "Pelabai",
          },
          {
            subdistrict_id: "3334",
            subdistrict_name: "Pinang Belapis",
          },
          {
            subdistrict_id: "3335",
            subdistrict_name: "Rimbo Pengadang",
          },
          {
            subdistrict_id: "3336",
            subdistrict_name: "Topos",
          },
          {
            subdistrict_id: "3337",
            subdistrict_name: "Uram Jaya",
          },
        ],
      },
      {
        city_id: "294",
        type: "Kabupaten",
        city_name: "Muko Muko",
        subdistricts: [
          {
            subdistrict_id: "4142",
            subdistrict_name: "Air Dikit",
          },
          {
            subdistrict_id: "4143",
            subdistrict_name: "Air Majunto",
          },
          {
            subdistrict_id: "4144",
            subdistrict_name: "Air Rami",
          },
          {
            subdistrict_id: "4145",
            subdistrict_name: "Ipuh (Muko-Muko Selatan)",
          },
          {
            subdistrict_id: "4146",
            subdistrict_name: "Kota Mukomuko (Mukomuko Utara)",
          },
          {
            subdistrict_id: "4147",
            subdistrict_name: "Lubuk Pinang",
          },
          {
            subdistrict_id: "4148",
            subdistrict_name: "Malin Deman",
          },
          {
            subdistrict_id: "4149",
            subdistrict_name: "Penarik",
          },
          {
            subdistrict_id: "4150",
            subdistrict_name: "Pondok Suguh",
          },
          {
            subdistrict_id: "4151",
            subdistrict_name: "Selagan Raya",
          },
          {
            subdistrict_id: "4152",
            subdistrict_name: "Sungai Rumbai",
          },
          {
            subdistrict_id: "4153",
            subdistrict_name: "Teramang Jaya",
          },
          {
            subdistrict_id: "4154",
            subdistrict_name: "Teras Terunjam",
          },
          {
            subdistrict_id: "4155",
            subdistrict_name: "V Koto",
          },
          {
            subdistrict_id: "4156",
            subdistrict_name: "XIV Koto",
          },
        ],
      },
      {
        city_id: "379",
        type: "Kabupaten",
        city_name: "Rejang Lebong",
        subdistricts: [
          {
            subdistrict_id: "5274",
            subdistrict_name: "Bermani Ulu",
          },
          {
            subdistrict_id: "5275",
            subdistrict_name: "Bermani Ulu Raya",
          },
          {
            subdistrict_id: "5276",
            subdistrict_name: "Binduriang",
          },
          {
            subdistrict_id: "5277",
            subdistrict_name: "Curup",
          },
          {
            subdistrict_id: "5278",
            subdistrict_name: "Curup Selatan",
          },
          {
            subdistrict_id: "5279",
            subdistrict_name: "Curup Tengah",
          },
          {
            subdistrict_id: "5280",
            subdistrict_name: "Curup Timur",
          },
          {
            subdistrict_id: "5281",
            subdistrict_name: "Curup Utara",
          },
          {
            subdistrict_id: "5282",
            subdistrict_name: "Kota Padang",
          },
          {
            subdistrict_id: "5283",
            subdistrict_name: "Padang Ulak Tanding",
          },
          {
            subdistrict_id: "5284",
            subdistrict_name: "Selupu Rejang",
          },
          {
            subdistrict_id: "5285",
            subdistrict_name: "Sindang Beliti Ilir",
          },
          {
            subdistrict_id: "5286",
            subdistrict_name: "Sindang Beliti Ulu",
          },
          {
            subdistrict_id: "5287",
            subdistrict_name: "Sindang Daratan",
          },
          {
            subdistrict_id: "5288",
            subdistrict_name: "Sindang Kelingi",
          },
        ],
      },
      {
        city_id: "397",
        type: "Kabupaten",
        city_name: "Seluma",
        subdistricts: [
          {
            subdistrict_id: "5465",
            subdistrict_name: "Air Periukan",
          },
          {
            subdistrict_id: "5466",
            subdistrict_name: "Ilir Talo",
          },
          {
            subdistrict_id: "5467",
            subdistrict_name: "Lubuk Sandi",
          },
          {
            subdistrict_id: "5468",
            subdistrict_name: "Seluma",
          },
          {
            subdistrict_id: "5469",
            subdistrict_name: "Seluma Barat",
          },
          {
            subdistrict_id: "5470",
            subdistrict_name: "Seluma Selatan",
          },
          {
            subdistrict_id: "5471",
            subdistrict_name: "Seluma Timur",
          },
          {
            subdistrict_id: "5472",
            subdistrict_name: "Seluma Utara",
          },
          {
            subdistrict_id: "5473",
            subdistrict_name: "Semidang Alas",
          },
          {
            subdistrict_id: "5474",
            subdistrict_name: "Semidang Alas Maras",
          },
          {
            subdistrict_id: "5475",
            subdistrict_name: "Sukaraja",
          },
          {
            subdistrict_id: "5476",
            subdistrict_name: "Talo",
          },
          {
            subdistrict_id: "5477",
            subdistrict_name: "Talo Kecil",
          },
          {
            subdistrict_id: "5478",
            subdistrict_name: "Ulu Talo",
          },
        ],
      },
    ],
  },
  {
    province_id: "5",
    province: "DI Yogyakarta",
    cities: [
      {
        city_id: "39",
        type: "Kabupaten",
        city_name: "Bantul",
        subdistricts: [
          {
            subdistrict_id: "537",
            subdistrict_name: "Bambang Lipuro",
          },
          {
            subdistrict_id: "538",
            subdistrict_name: "Banguntapan",
          },
          {
            subdistrict_id: "539",
            subdistrict_name: "Bantul",
          },
          {
            subdistrict_id: "540",
            subdistrict_name: "Dlingo",
          },
          {
            subdistrict_id: "541",
            subdistrict_name: "Imogiri",
          },
          {
            subdistrict_id: "542",
            subdistrict_name: "Jetis",
          },
          {
            subdistrict_id: "543",
            subdistrict_name: "Kasihan",
          },
          {
            subdistrict_id: "544",
            subdistrict_name: "Kretek",
          },
          {
            subdistrict_id: "545",
            subdistrict_name: "Pajangan",
          },
          {
            subdistrict_id: "546",
            subdistrict_name: "Pandak",
          },
          {
            subdistrict_id: "547",
            subdistrict_name: "Piyungan",
          },
          {
            subdistrict_id: "548",
            subdistrict_name: "Pleret",
          },
          {
            subdistrict_id: "549",
            subdistrict_name: "Pundong",
          },
          {
            subdistrict_id: "550",
            subdistrict_name: "Sanden",
          },
          {
            subdistrict_id: "551",
            subdistrict_name: "Sedayu",
          },
          {
            subdistrict_id: "552",
            subdistrict_name: "Sewon",
          },
          {
            subdistrict_id: "553",
            subdistrict_name: "Srandakan",
          },
        ],
      },
      {
        city_id: "135",
        type: "Kabupaten",
        city_name: "Gunung Kidul",
        subdistricts: [
          {
            subdistrict_id: "1865",
            subdistrict_name: "Gedang Sari",
          },
          {
            subdistrict_id: "1866",
            subdistrict_name: "Girisubo",
          },
          {
            subdistrict_id: "1867",
            subdistrict_name: "Karangmojo",
          },
          {
            subdistrict_id: "1868",
            subdistrict_name: "Ngawen",
          },
          {
            subdistrict_id: "1869",
            subdistrict_name: "Nglipar",
          },
          {
            subdistrict_id: "1870",
            subdistrict_name: "Paliyan",
          },
          {
            subdistrict_id: "1871",
            subdistrict_name: "Panggang",
          },
          {
            subdistrict_id: "1872",
            subdistrict_name: "Patuk",
          },
          {
            subdistrict_id: "1873",
            subdistrict_name: "Playen",
          },
          {
            subdistrict_id: "1874",
            subdistrict_name: "Ponjong",
          },
          {
            subdistrict_id: "1875",
            subdistrict_name: "Purwosari",
          },
          {
            subdistrict_id: "1876",
            subdistrict_name: "Rongkop",
          },
          {
            subdistrict_id: "1877",
            subdistrict_name: "Sapto Sari",
          },
          {
            subdistrict_id: "1878",
            subdistrict_name: "Semanu",
          },
          {
            subdistrict_id: "1879",
            subdistrict_name: "Semin",
          },
          {
            subdistrict_id: "1880",
            subdistrict_name: "Tanjungsari",
          },
          {
            subdistrict_id: "1881",
            subdistrict_name: "Tepus",
          },
          {
            subdistrict_id: "1882",
            subdistrict_name: "Wonosari",
          },
        ],
      },
      {
        city_id: "210",
        type: "Kabupaten",
        city_name: "Kulon Progo",
        subdistricts: [
          {
            subdistrict_id: "2930",
            subdistrict_name: "Galur",
          },
          {
            subdistrict_id: "2931",
            subdistrict_name: "Girimulyo",
          },
          {
            subdistrict_id: "2932",
            subdistrict_name: "Kalibawang",
          },
          {
            subdistrict_id: "2933",
            subdistrict_name: "Kokap",
          },
          {
            subdistrict_id: "2934",
            subdistrict_name: "Lendah",
          },
          {
            subdistrict_id: "2935",
            subdistrict_name: "Nanggulan",
          },
          {
            subdistrict_id: "2936",
            subdistrict_name: "Panjatan",
          },
          {
            subdistrict_id: "2937",
            subdistrict_name: "Pengasih",
          },
          {
            subdistrict_id: "2938",
            subdistrict_name: "Samigaluh",
          },
          {
            subdistrict_id: "2939",
            subdistrict_name: "Sentolo",
          },
          {
            subdistrict_id: "2940",
            subdistrict_name: "Temon",
          },
          {
            subdistrict_id: "2941",
            subdistrict_name: "Wates",
          },
        ],
      },
      {
        city_id: "419",
        type: "Kabupaten",
        city_name: "Sleman",
        subdistricts: [
          {
            subdistrict_id: "5779",
            subdistrict_name: "Berbah",
          },
          {
            subdistrict_id: "5780",
            subdistrict_name: "Cangkringan",
          },
          {
            subdistrict_id: "5781",
            subdistrict_name: "Depok",
          },
          {
            subdistrict_id: "5782",
            subdistrict_name: "Gamping",
          },
          {
            subdistrict_id: "5783",
            subdistrict_name: "Godean",
          },
          {
            subdistrict_id: "5784",
            subdistrict_name: "Kalasan",
          },
          {
            subdistrict_id: "5785",
            subdistrict_name: "Minggir",
          },
          {
            subdistrict_id: "5786",
            subdistrict_name: "Mlati",
          },
          {
            subdistrict_id: "5787",
            subdistrict_name: "Moyudan",
          },
          {
            subdistrict_id: "5788",
            subdistrict_name: "Ngaglik",
          },
          {
            subdistrict_id: "5789",
            subdistrict_name: "Ngemplak",
          },
          {
            subdistrict_id: "5790",
            subdistrict_name: "Pakem",
          },
          {
            subdistrict_id: "5791",
            subdistrict_name: "Prambanan",
          },
          {
            subdistrict_id: "5792",
            subdistrict_name: "Seyegan",
          },
          {
            subdistrict_id: "5793",
            subdistrict_name: "Sleman",
          },
          {
            subdistrict_id: "5794",
            subdistrict_name: "Tempel",
          },
          {
            subdistrict_id: "5795",
            subdistrict_name: "Turi",
          },
        ],
      },
      {
        city_id: "501",
        type: "Kota",
        city_name: "Yogyakarta",
        subdistricts: [
          {
            subdistrict_id: "6981",
            subdistrict_name: "Danurejan",
          },
          {
            subdistrict_id: "6982",
            subdistrict_name: "Gedong Tengen",
          },
          {
            subdistrict_id: "6983",
            subdistrict_name: "Gondokusuman",
          },
          {
            subdistrict_id: "6984",
            subdistrict_name: "Gondomanan",
          },
          {
            subdistrict_id: "6985",
            subdistrict_name: "Jetis",
          },
          {
            subdistrict_id: "6986",
            subdistrict_name: "Kotagede",
          },
          {
            subdistrict_id: "6987",
            subdistrict_name: "Kraton",
          },
          {
            subdistrict_id: "6988",
            subdistrict_name: "Mantrijeron",
          },
          {
            subdistrict_id: "6989",
            subdistrict_name: "Mergangsan",
          },
          {
            subdistrict_id: "6990",
            subdistrict_name: "Ngampilan",
          },
          {
            subdistrict_id: "6991",
            subdistrict_name: "Pakualaman",
          },
          {
            subdistrict_id: "6992",
            subdistrict_name: "Tegalrejo",
          },
          {
            subdistrict_id: "6993",
            subdistrict_name: "Umbulharjo",
          },
          {
            subdistrict_id: "6994",
            subdistrict_name: "Wirobrajan",
          },
        ],
      },
    ],
  },
  {
    province_id: "6",
    province: "DKI Jakarta",
    cities: [
      {
        city_id: "151",
        type: "Kota",
        city_name: "Jakarta Barat",
        subdistricts: [
          {
            subdistrict_id: "2087",
            subdistrict_name: "Cengkareng",
          },
          {
            subdistrict_id: "2088",
            subdistrict_name: "Grogol Petamburan",
          },
          {
            subdistrict_id: "2089",
            subdistrict_name: "Kalideres",
          },
          {
            subdistrict_id: "2090",
            subdistrict_name: "Kebon Jeruk",
          },
          {
            subdistrict_id: "2091",
            subdistrict_name: "Kembangan",
          },
          {
            subdistrict_id: "2092",
            subdistrict_name: "Palmerah",
          },
          {
            subdistrict_id: "2093",
            subdistrict_name: "Taman Sari",
          },
          {
            subdistrict_id: "2094",
            subdistrict_name: "Tambora",
          },
        ],
      },
      {
        city_id: "152",
        type: "Kota",
        city_name: "Jakarta Pusat",
        subdistricts: [
          {
            subdistrict_id: "2095",
            subdistrict_name: "Cempaka Putih",
          },
          {
            subdistrict_id: "2096",
            subdistrict_name: "Gambir",
          },
          {
            subdistrict_id: "2097",
            subdistrict_name: "Johar Baru",
          },
          {
            subdistrict_id: "2098",
            subdistrict_name: "Kemayoran",
          },
          {
            subdistrict_id: "2099",
            subdistrict_name: "Menteng",
          },
          {
            subdistrict_id: "2100",
            subdistrict_name: "Sawah Besar",
          },
          {
            subdistrict_id: "2101",
            subdistrict_name: "Senen",
          },
          {
            subdistrict_id: "2102",
            subdistrict_name: "Tanah Abang",
          },
        ],
      },
      {
        city_id: "153",
        type: "Kota",
        city_name: "Jakarta Selatan",
        subdistricts: [
          {
            subdistrict_id: "2103",
            subdistrict_name: "Cilandak",
          },
          {
            subdistrict_id: "2104",
            subdistrict_name: "Jagakarsa",
          },
          {
            subdistrict_id: "2105",
            subdistrict_name: "Kebayoran Baru",
          },
          {
            subdistrict_id: "2106",
            subdistrict_name: "Kebayoran Lama",
          },
          {
            subdistrict_id: "2107",
            subdistrict_name: "Mampang Prapatan",
          },
          {
            subdistrict_id: "2108",
            subdistrict_name: "Pancoran",
          },
          {
            subdistrict_id: "2109",
            subdistrict_name: "Pasar Minggu",
          },
          {
            subdistrict_id: "2110",
            subdistrict_name: "Pesanggrahan",
          },
          {
            subdistrict_id: "2111",
            subdistrict_name: "Setia Budi",
          },
          {
            subdistrict_id: "2112",
            subdistrict_name: "Tebet",
          },
        ],
      },
      {
        city_id: "154",
        type: "Kota",
        city_name: "Jakarta Timur",
        subdistricts: [
          {
            subdistrict_id: "2113",
            subdistrict_name: "Cakung",
          },
          {
            subdistrict_id: "2114",
            subdistrict_name: "Cipayung",
          },
          {
            subdistrict_id: "2115",
            subdistrict_name: "Ciracas",
          },
          {
            subdistrict_id: "2116",
            subdistrict_name: "Duren Sawit",
          },
          {
            subdistrict_id: "2117",
            subdistrict_name: "Jatinegara",
          },
          {
            subdistrict_id: "2118",
            subdistrict_name: "Kramat Jati",
          },
          {
            subdistrict_id: "2119",
            subdistrict_name: "Makasar",
          },
          {
            subdistrict_id: "2120",
            subdistrict_name: "Matraman",
          },
          {
            subdistrict_id: "2121",
            subdistrict_name: "Pasar Rebo",
          },
          {
            subdistrict_id: "2122",
            subdistrict_name: "Pulo Gadung",
          },
        ],
      },
      {
        city_id: "155",
        type: "Kota",
        city_name: "Jakarta Utara",
        subdistricts: [
          {
            subdistrict_id: "2123",
            subdistrict_name: "Cilincing",
          },
          {
            subdistrict_id: "2124",
            subdistrict_name: "Kelapa Gading",
          },
          {
            subdistrict_id: "2125",
            subdistrict_name: "Koja",
          },
          {
            subdistrict_id: "2126",
            subdistrict_name: "Pademangan",
          },
          {
            subdistrict_id: "2127",
            subdistrict_name: "Penjaringan",
          },
          {
            subdistrict_id: "2128",
            subdistrict_name: "Tanjung Priok",
          },
        ],
      },
      {
        city_id: "189",
        type: "Kabupaten",
        city_name: "Kepulauan Seribu",
        subdistricts: [
          {
            subdistrict_id: "2622",
            subdistrict_name: "Kepulauan Seribu Selatan",
          },
          {
            subdistrict_id: "2623",
            subdistrict_name: "Kepulauan Seribu Utara",
          },
        ],
      },
    ],
  },
  {
    province_id: "7",
    province: "Gorontalo",
    cities: [
      {
        city_id: "77",
        type: "Kabupaten",
        city_name: "Boalemo",
        subdistricts: [
          {
            subdistrict_id: "1015",
            subdistrict_name: "Botumoita (Botumoito)",
          },
          {
            subdistrict_id: "1016",
            subdistrict_name: "Dulupi",
          },
          {
            subdistrict_id: "1017",
            subdistrict_name: "Mananggu",
          },
          {
            subdistrict_id: "1018",
            subdistrict_name: "Paguyaman",
          },
          {
            subdistrict_id: "1019",
            subdistrict_name: "Paguyaman Pantai",
          },
          {
            subdistrict_id: "1020",
            subdistrict_name: "Tilamuta",
          },
          {
            subdistrict_id: "1021",
            subdistrict_name: "Wonosari",
          },
        ],
      },
      {
        city_id: "88",
        type: "Kabupaten",
        city_name: "Bone Bolango",
        subdistricts: [
          {
            subdistrict_id: "1199",
            subdistrict_name: "Bone",
          },
          {
            subdistrict_id: "1200",
            subdistrict_name: "Bone Raya",
          },
          {
            subdistrict_id: "1201",
            subdistrict_name: "Bonepantai",
          },
          {
            subdistrict_id: "1202",
            subdistrict_name: "Botu Pingge",
          },
          {
            subdistrict_id: "1203",
            subdistrict_name: "Bulango Selatan",
          },
          {
            subdistrict_id: "1204",
            subdistrict_name: "Bulango Timur",
          },
          {
            subdistrict_id: "1205",
            subdistrict_name: "Bulango Ulu",
          },
          {
            subdistrict_id: "1206",
            subdistrict_name: "Bulango Utara",
          },
          {
            subdistrict_id: "1207",
            subdistrict_name: "Bulawa",
          },
          {
            subdistrict_id: "1208",
            subdistrict_name: "Kabila",
          },
          {
            subdistrict_id: "1209",
            subdistrict_name: "Kabila Bone",
          },
          {
            subdistrict_id: "1210",
            subdistrict_name: "Pinogu",
          },
          {
            subdistrict_id: "1211",
            subdistrict_name: "Suwawa",
          },
          {
            subdistrict_id: "1212",
            subdistrict_name: "Suwawa Selatan",
          },
          {
            subdistrict_id: "1213",
            subdistrict_name: "Suwawa Tengah",
          },
          {
            subdistrict_id: "1214",
            subdistrict_name: "Suwawa Timur",
          },
          {
            subdistrict_id: "1215",
            subdistrict_name: "Tapa",
          },
          {
            subdistrict_id: "1216",
            subdistrict_name: "Tilongkabila",
          },
        ],
      },
      {
        city_id: "129",
        type: "Kabupaten",
        city_name: "Gorontalo",
        subdistricts: [
          {
            subdistrict_id: "1771",
            subdistrict_name: "Asparaga",
          },
          {
            subdistrict_id: "1772",
            subdistrict_name: "Batudaa",
          },
          {
            subdistrict_id: "1773",
            subdistrict_name: "Batudaa Pantai",
          },
          {
            subdistrict_id: "1774",
            subdistrict_name: "Bilato",
          },
          {
            subdistrict_id: "1775",
            subdistrict_name: "Biluhu",
          },
          {
            subdistrict_id: "1776",
            subdistrict_name: "Boliohuto (Boliyohuto)",
          },
          {
            subdistrict_id: "1777",
            subdistrict_name: "Bongomeme",
          },
          {
            subdistrict_id: "1778",
            subdistrict_name: "Dungaliyo",
          },
          {
            subdistrict_id: "1779",
            subdistrict_name: "Limboto",
          },
          {
            subdistrict_id: "1780",
            subdistrict_name: "Limboto Barat",
          },
          {
            subdistrict_id: "1781",
            subdistrict_name: "Mootilango",
          },
          {
            subdistrict_id: "1782",
            subdistrict_name: "Pulubala",
          },
          {
            subdistrict_id: "1783",
            subdistrict_name: "Tabongo",
          },
          {
            subdistrict_id: "1784",
            subdistrict_name: "Telaga",
          },
          {
            subdistrict_id: "1785",
            subdistrict_name: "Telaga Biru",
          },
          {
            subdistrict_id: "1786",
            subdistrict_name: "Telaga Jaya",
          },
          {
            subdistrict_id: "1787",
            subdistrict_name: "Tibawa",
          },
          {
            subdistrict_id: "1788",
            subdistrict_name: "Tilango",
          },
          {
            subdistrict_id: "1789",
            subdistrict_name: "Tolangohula",
          },
        ],
      },
      {
        city_id: "130",
        type: "Kota",
        city_name: "Gorontalo",
        subdistricts: [
          {
            subdistrict_id: "1790",
            subdistrict_name: "Dumbo Raya",
          },
          {
            subdistrict_id: "1791",
            subdistrict_name: "Dungingi",
          },
          {
            subdistrict_id: "1792",
            subdistrict_name: "Hulonthalangi",
          },
          {
            subdistrict_id: "1793",
            subdistrict_name: "Kota Barat",
          },
          {
            subdistrict_id: "1794",
            subdistrict_name: "Kota Selatan",
          },
          {
            subdistrict_id: "1795",
            subdistrict_name: "Kota Tengah",
          },
          {
            subdistrict_id: "1796",
            subdistrict_name: "Kota Timur",
          },
          {
            subdistrict_id: "1797",
            subdistrict_name: "Kota Utara",
          },
          {
            subdistrict_id: "1798",
            subdistrict_name: "Sipatana",
          },
        ],
      },
      {
        city_id: "131",
        type: "Kabupaten",
        city_name: "Gorontalo Utara",
        subdistricts: [
          {
            subdistrict_id: "1799",
            subdistrict_name: "Anggrek",
          },
          {
            subdistrict_id: "1800",
            subdistrict_name: "Atinggola",
          },
          {
            subdistrict_id: "1801",
            subdistrict_name: "Biau",
          },
          {
            subdistrict_id: "1802",
            subdistrict_name: "Gentuma Raya",
          },
          {
            subdistrict_id: "1803",
            subdistrict_name: "Kwandang",
          },
          {
            subdistrict_id: "1804",
            subdistrict_name: "Monano",
          },
          {
            subdistrict_id: "1805",
            subdistrict_name: "Ponelo Kepulauan",
          },
          {
            subdistrict_id: "1806",
            subdistrict_name: "Sumalata",
          },
          {
            subdistrict_id: "1807",
            subdistrict_name: "Sumalata Timur",
          },
          {
            subdistrict_id: "1808",
            subdistrict_name: "Tolinggula",
          },
          {
            subdistrict_id: "1809",
            subdistrict_name: "Tomolito",
          },
        ],
      },
      {
        city_id: "361",
        type: "Kabupaten",
        city_name: "Pohuwato",
        subdistricts: [
          {
            subdistrict_id: "5042",
            subdistrict_name: "Buntulia",
          },
          {
            subdistrict_id: "5043",
            subdistrict_name: "Dengilo",
          },
          {
            subdistrict_id: "5044",
            subdistrict_name: "Duhiadaa",
          },
          {
            subdistrict_id: "5045",
            subdistrict_name: "Lemito",
          },
          {
            subdistrict_id: "5046",
            subdistrict_name: "Marisa",
          },
          {
            subdistrict_id: "5047",
            subdistrict_name: "Paguat",
          },
          {
            subdistrict_id: "5048",
            subdistrict_name: "Patilanggio",
          },
          {
            subdistrict_id: "5049",
            subdistrict_name: "Popayato",
          },
          {
            subdistrict_id: "5050",
            subdistrict_name: "Popayato Barat",
          },
          {
            subdistrict_id: "5051",
            subdistrict_name: "Popayato Timur",
          },
          {
            subdistrict_id: "5052",
            subdistrict_name: "Randangan",
          },
          {
            subdistrict_id: "5053",
            subdistrict_name: "Taluditi (Taluduti)",
          },
          {
            subdistrict_id: "5054",
            subdistrict_name: "Wanggarasi",
          },
        ],
      },
    ],
  },
  {
    province_id: "8",
    province: "Jambi",
    cities: [
      {
        city_id: "50",
        type: "Kabupaten",
        city_name: "Batang Hari",
        subdistricts: [
          {
            subdistrict_id: "700",
            subdistrict_name: "Bajubang",
          },
          {
            subdistrict_id: "701",
            subdistrict_name: "Batin XXIV",
          },
          {
            subdistrict_id: "702",
            subdistrict_name: "Maro Sebo Ilir",
          },
          {
            subdistrict_id: "703",
            subdistrict_name: "Maro Sebo Ulu",
          },
          {
            subdistrict_id: "704",
            subdistrict_name: "Mersam",
          },
          {
            subdistrict_id: "705",
            subdistrict_name: "Muara Bulian",
          },
          {
            subdistrict_id: "706",
            subdistrict_name: "Muara Tembesi",
          },
          {
            subdistrict_id: "707",
            subdistrict_name: "Pemayung",
          },
        ],
      },
      {
        city_id: "97",
        type: "Kabupaten",
        city_name: "Bungo",
        subdistricts: [
          {
            subdistrict_id: "1308",
            subdistrict_name: "Bathin II Babeko",
          },
          {
            subdistrict_id: "1309",
            subdistrict_name: "Bathin II Pelayang",
          },
          {
            subdistrict_id: "1310",
            subdistrict_name: "Bathin III",
          },
          {
            subdistrict_id: "1311",
            subdistrict_name: "Bathin III Ulu",
          },
          {
            subdistrict_id: "1312",
            subdistrict_name: "Bungo Dani",
          },
          {
            subdistrict_id: "1313",
            subdistrict_name: "Jujuhan",
          },
          {
            subdistrict_id: "1314",
            subdistrict_name: "Jujuhan Ilir",
          },
          {
            subdistrict_id: "1315",
            subdistrict_name: "Limbur Lubuk Mengkuang",
          },
          {
            subdistrict_id: "1316",
            subdistrict_name: "Muko-Muko Batin VII",
          },
          {
            subdistrict_id: "1317",
            subdistrict_name: "Pasar Muara Bungo",
          },
          {
            subdistrict_id: "1318",
            subdistrict_name: "Pelepat",
          },
          {
            subdistrict_id: "1319",
            subdistrict_name: "Pelepat Ilir",
          },
          {
            subdistrict_id: "1320",
            subdistrict_name: "Rantau Pandan",
          },
          {
            subdistrict_id: "1321",
            subdistrict_name: "Rimbo Tengah",
          },
          {
            subdistrict_id: "1322",
            subdistrict_name: "Tanah Sepenggal",
          },
          {
            subdistrict_id: "1323",
            subdistrict_name: "Tanah Sepenggal Lintas",
          },
          {
            subdistrict_id: "1324",
            subdistrict_name: "Tanah Tumbuh",
          },
        ],
      },
      {
        city_id: "156",
        type: "Kota",
        city_name: "Jambi",
        subdistricts: [
          {
            subdistrict_id: "2129",
            subdistrict_name: "Danau Teluk",
          },
          {
            subdistrict_id: "2130",
            subdistrict_name: "Jambi Selatan",
          },
          {
            subdistrict_id: "2131",
            subdistrict_name: "Jambi Timur",
          },
          {
            subdistrict_id: "2132",
            subdistrict_name: "Jelutung",
          },
          {
            subdistrict_id: "2133",
            subdistrict_name: "Kota Baru",
          },
          {
            subdistrict_id: "2134",
            subdistrict_name: "Pasar Jambi",
          },
          {
            subdistrict_id: "2135",
            subdistrict_name: "Pelayangan",
          },
          {
            subdistrict_id: "2136",
            subdistrict_name: "Telanaipura",
          },
        ],
      },
      {
        city_id: "194",
        type: "Kabupaten",
        city_name: "Kerinci",
        subdistricts: [
          {
            subdistrict_id: "2686",
            subdistrict_name: "Air Hangat",
          },
          {
            subdistrict_id: "2687",
            subdistrict_name: "Air Hangat Barat",
          },
          {
            subdistrict_id: "2688",
            subdistrict_name: "Air Hangat Timur",
          },
          {
            subdistrict_id: "2689",
            subdistrict_name: "Batang Merangin",
          },
          {
            subdistrict_id: "2690",
            subdistrict_name: "Bukitkerman",
          },
          {
            subdistrict_id: "2691",
            subdistrict_name: "Danau Kerinci",
          },
          {
            subdistrict_id: "2692",
            subdistrict_name: "Depati Tujuh",
          },
          {
            subdistrict_id: "2693",
            subdistrict_name: "Gunung Kerinci",
          },
          {
            subdistrict_id: "2694",
            subdistrict_name: "Gunung Raya",
          },
          {
            subdistrict_id: "2695",
            subdistrict_name: "Gunung Tujuh",
          },
          {
            subdistrict_id: "2696",
            subdistrict_name: "Kayu Aro",
          },
          {
            subdistrict_id: "2697",
            subdistrict_name: "Kayu Aro Barat",
          },
          {
            subdistrict_id: "2698",
            subdistrict_name: "Keliling Danau",
          },
          {
            subdistrict_id: "2699",
            subdistrict_name: "Sitinjau Laut",
          },
          {
            subdistrict_id: "2700",
            subdistrict_name: "Siulak",
          },
          {
            subdistrict_id: "2701",
            subdistrict_name: "Siulak Mukai",
          },
        ],
      },
      {
        city_id: "280",
        type: "Kabupaten",
        city_name: "Merangin",
        subdistricts: [
          {
            subdistrict_id: "3938",
            subdistrict_name: "Bangko",
          },
          {
            subdistrict_id: "3939",
            subdistrict_name: "Bangko Barat",
          },
          {
            subdistrict_id: "3940",
            subdistrict_name: "Batang Masumai",
          },
          {
            subdistrict_id: "3941",
            subdistrict_name: "Jangkat",
          },
          {
            subdistrict_id: "3942",
            subdistrict_name: "Lembah Masurai",
          },
          {
            subdistrict_id: "3943",
            subdistrict_name: "Margo Tabir",
          },
          {
            subdistrict_id: "3944",
            subdistrict_name: "Muara Siau",
          },
          {
            subdistrict_id: "3945",
            subdistrict_name: "Nalo Tantan",
          },
          {
            subdistrict_id: "3946",
            subdistrict_name: "Pamenang",
          },
          {
            subdistrict_id: "3947",
            subdistrict_name: "Pamenang Barat",
          },
          {
            subdistrict_id: "3948",
            subdistrict_name: "Pamenang Selatan",
          },
          {
            subdistrict_id: "3949",
            subdistrict_name: "Pangkalan Jambu",
          },
          {
            subdistrict_id: "3950",
            subdistrict_name: "Renah Pembarap",
          },
          {
            subdistrict_id: "3951",
            subdistrict_name: "Renah Pemenang",
          },
          {
            subdistrict_id: "3952",
            subdistrict_name: "Sungai Manau",
          },
          {
            subdistrict_id: "3953",
            subdistrict_name: "Sungai Tenang",
          },
          {
            subdistrict_id: "3954",
            subdistrict_name: "Tabir",
          },
          {
            subdistrict_id: "3955",
            subdistrict_name: "Tabir Barat",
          },
          {
            subdistrict_id: "3956",
            subdistrict_name: "Tabir Ilir",
          },
          {
            subdistrict_id: "3957",
            subdistrict_name: "Tabir Lintas",
          },
          {
            subdistrict_id: "3958",
            subdistrict_name: "Tabir Selatan",
          },
          {
            subdistrict_id: "3959",
            subdistrict_name: "Tabir Timur",
          },
          {
            subdistrict_id: "3960",
            subdistrict_name: "Tabir Ulu",
          },
          {
            subdistrict_id: "3961",
            subdistrict_name: "Tiang Pumpung",
          },
        ],
      },
      {
        city_id: "293",
        type: "Kabupaten",
        city_name: "Muaro Jambi",
        subdistricts: [
          {
            subdistrict_id: "4131",
            subdistrict_name: "Bahar Selatan",
          },
          {
            subdistrict_id: "4132",
            subdistrict_name: "Bahar Utara",
          },
          {
            subdistrict_id: "4133",
            subdistrict_name: "Jambi Luar Kota",
          },
          {
            subdistrict_id: "4134",
            subdistrict_name: "Kumpeh",
          },
          {
            subdistrict_id: "4135",
            subdistrict_name: "Kumpeh Ulu",
          },
          {
            subdistrict_id: "4136",
            subdistrict_name: "Maro Sebo",
          },
          {
            subdistrict_id: "4137",
            subdistrict_name: "Mestong",
          },
          {
            subdistrict_id: "4138",
            subdistrict_name: "Sekernan",
          },
          {
            subdistrict_id: "4139",
            subdistrict_name: "Sungai Bahar",
          },
          {
            subdistrict_id: "4140",
            subdistrict_name: "Sungai Gelam",
          },
          {
            subdistrict_id: "4141",
            subdistrict_name: "Taman Rajo / Rejo",
          },
        ],
      },
      {
        city_id: "393",
        type: "Kabupaten",
        city_name: "Sarolangun",
        subdistricts: [
          {
            subdistrict_id: "5433",
            subdistrict_name: "Air Hitam",
          },
          {
            subdistrict_id: "5434",
            subdistrict_name: "Batang Asai",
          },
          {
            subdistrict_id: "5435",
            subdistrict_name: "Bathin VIII (Batin VIII)",
          },
          {
            subdistrict_id: "5436",
            subdistrict_name: "Cermin Nan Gadang",
          },
          {
            subdistrict_id: "5437",
            subdistrict_name: "Limun",
          },
          {
            subdistrict_id: "5438",
            subdistrict_name: "Mandiangin",
          },
          {
            subdistrict_id: "5439",
            subdistrict_name: "Pauh",
          },
          {
            subdistrict_id: "5440",
            subdistrict_name: "Pelawan",
          },
          {
            subdistrict_id: "5441",
            subdistrict_name: "Sarolangun",
          },
          {
            subdistrict_id: "5442",
            subdistrict_name: "Singkut",
          },
        ],
      },
      {
        city_id: "442",
        type: "Kota",
        city_name: "Sungaipenuh",
        subdistricts: [
          {
            subdistrict_id: "6118",
            subdistrict_name: "Hamparan Rawang",
          },
          {
            subdistrict_id: "6119",
            subdistrict_name: "Koto Baru",
          },
          {
            subdistrict_id: "6120",
            subdistrict_name: "Kumun Debai",
          },
          {
            subdistrict_id: "6121",
            subdistrict_name: "Pesisir Bukit",
          },
          {
            subdistrict_id: "6122",
            subdistrict_name: "Pondok Tinggi",
          },
          {
            subdistrict_id: "6123",
            subdistrict_name: "Sungai Bungkal",
          },
          {
            subdistrict_id: "6124",
            subdistrict_name: "Sungai Penuh",
          },
          {
            subdistrict_id: "6125",
            subdistrict_name: "Tanah Kampung",
          },
        ],
      },
      {
        city_id: "460",
        type: "Kabupaten",
        city_name: "Tanjung Jabung Barat",
        subdistricts: [
          {
            subdistrict_id: "6343",
            subdistrict_name: "Batang Asam",
          },
          {
            subdistrict_id: "6344",
            subdistrict_name: "Betara",
          },
          {
            subdistrict_id: "6345",
            subdistrict_name: "Bram Itam",
          },
          {
            subdistrict_id: "6346",
            subdistrict_name: "Kuala Betara",
          },
          {
            subdistrict_id: "6347",
            subdistrict_name: "Merlung",
          },
          {
            subdistrict_id: "6348",
            subdistrict_name: "Muara Papalik",
          },
          {
            subdistrict_id: "6349",
            subdistrict_name: "Pengabuan",
          },
          {
            subdistrict_id: "6350",
            subdistrict_name: "Renah Mendaluh",
          },
          {
            subdistrict_id: "6351",
            subdistrict_name: "Seberang Kota",
          },
          {
            subdistrict_id: "6352",
            subdistrict_name: "Senyerang",
          },
          {
            subdistrict_id: "6353",
            subdistrict_name: "Tebing Tinggi",
          },
          {
            subdistrict_id: "6354",
            subdistrict_name: "Tungkal Ilir",
          },
          {
            subdistrict_id: "6355",
            subdistrict_name: "Tungkal Ulu",
          },
        ],
      },
      {
        city_id: "461",
        type: "Kabupaten",
        city_name: "Tanjung Jabung Timur",
        subdistricts: [
          {
            subdistrict_id: "6356",
            subdistrict_name: "Berbak",
          },
          {
            subdistrict_id: "6357",
            subdistrict_name: "Dendang",
          },
          {
            subdistrict_id: "6358",
            subdistrict_name: "Geragai",
          },
          {
            subdistrict_id: "6359",
            subdistrict_name: "Kuala Jambi",
          },
          {
            subdistrict_id: "6360",
            subdistrict_name: "Mendahara",
          },
          {
            subdistrict_id: "6361",
            subdistrict_name: "Mendahara Ulu",
          },
          {
            subdistrict_id: "6362",
            subdistrict_name: "Muara Sabak Barat",
          },
          {
            subdistrict_id: "6363",
            subdistrict_name: "Muara Sabak Timur",
          },
          {
            subdistrict_id: "6364",
            subdistrict_name: "Nipah Panjang",
          },
          {
            subdistrict_id: "6365",
            subdistrict_name: "Rantau Rasau",
          },
          {
            subdistrict_id: "6366",
            subdistrict_name: "Sadu",
          },
        ],
      },
      {
        city_id: "471",
        type: "Kabupaten",
        city_name: "Tebo",
        subdistricts: [
          {
            subdistrict_id: "6490",
            subdistrict_name: "Muara Tabir",
          },
          {
            subdistrict_id: "6491",
            subdistrict_name: "Rimbo Bujang",
          },
          {
            subdistrict_id: "6492",
            subdistrict_name: "Rimbo Ilir",
          },
          {
            subdistrict_id: "6493",
            subdistrict_name: "Rimbo Ulu",
          },
          {
            subdistrict_id: "6494",
            subdistrict_name: "Serai Serumpun",
          },
          {
            subdistrict_id: "6495",
            subdistrict_name: "Sumay",
          },
          {
            subdistrict_id: "6496",
            subdistrict_name: "Tebo Ilir",
          },
          {
            subdistrict_id: "6497",
            subdistrict_name: "Tebo Tengah",
          },
          {
            subdistrict_id: "6498",
            subdistrict_name: "Tebo Ulu",
          },
          {
            subdistrict_id: "6499",
            subdistrict_name: "Tengah Ilir",
          },
          {
            subdistrict_id: "6500",
            subdistrict_name: "VII Koto",
          },
          {
            subdistrict_id: "6501",
            subdistrict_name: "VII Koto Ilir",
          },
        ],
      },
    ],
  },
  {
    province_id: "9",
    province: "Jawa Barat",
    cities: [
      {
        city_id: "22",
        type: "Kabupaten",
        city_name: "Bandung",
        subdistricts: [
          {
            subdistrict_id: "307",
            subdistrict_name: "Arjasari",
          },
          {
            subdistrict_id: "308",
            subdistrict_name: "Baleendah",
          },
          {
            subdistrict_id: "309",
            subdistrict_name: "Banjaran",
          },
          {
            subdistrict_id: "310",
            subdistrict_name: "Bojongsoang",
          },
          {
            subdistrict_id: "311",
            subdistrict_name: "Cangkuang",
          },
          {
            subdistrict_id: "312",
            subdistrict_name: "Cicalengka",
          },
          {
            subdistrict_id: "313",
            subdistrict_name: "Cikancung",
          },
          {
            subdistrict_id: "314",
            subdistrict_name: "Cilengkrang",
          },
          {
            subdistrict_id: "315",
            subdistrict_name: "Cileunyi",
          },
          {
            subdistrict_id: "316",
            subdistrict_name: "Cimaung",
          },
          {
            subdistrict_id: "317",
            subdistrict_name: "Cimeunyan",
          },
          {
            subdistrict_id: "318",
            subdistrict_name: "Ciparay",
          },
          {
            subdistrict_id: "319",
            subdistrict_name: "Ciwidey",
          },
          {
            subdistrict_id: "320",
            subdistrict_name: "Dayeuhkolot",
          },
          {
            subdistrict_id: "321",
            subdistrict_name: "Ibun",
          },
          {
            subdistrict_id: "322",
            subdistrict_name: "Katapang",
          },
          {
            subdistrict_id: "323",
            subdistrict_name: "Kertasari",
          },
          {
            subdistrict_id: "324",
            subdistrict_name: "Kutawaringin",
          },
          {
            subdistrict_id: "325",
            subdistrict_name: "Majalaya",
          },
          {
            subdistrict_id: "326",
            subdistrict_name: "Margaasih",
          },
          {
            subdistrict_id: "327",
            subdistrict_name: "Margahayu",
          },
          {
            subdistrict_id: "328",
            subdistrict_name: "Nagreg",
          },
          {
            subdistrict_id: "329",
            subdistrict_name: "Pacet",
          },
          {
            subdistrict_id: "330",
            subdistrict_name: "Pameungpeuk",
          },
          {
            subdistrict_id: "331",
            subdistrict_name: "Pangalengan",
          },
          {
            subdistrict_id: "332",
            subdistrict_name: "Paseh",
          },
          {
            subdistrict_id: "333",
            subdistrict_name: "Pasirjambu",
          },
          {
            subdistrict_id: "334",
            subdistrict_name: "Ranca Bali",
          },
          {
            subdistrict_id: "335",
            subdistrict_name: "Rancaekek",
          },
          {
            subdistrict_id: "336",
            subdistrict_name: "Solokan Jeruk",
          },
          {
            subdistrict_id: "337",
            subdistrict_name: "Soreang",
          },
        ],
      },
      {
        city_id: "23",
        type: "Kota",
        city_name: "Bandung",
        subdistricts: [
          {
            subdistrict_id: "338",
            subdistrict_name: "Andir",
          },
          {
            subdistrict_id: "339",
            subdistrict_name: "Antapani (Cicadas)",
          },
          {
            subdistrict_id: "340",
            subdistrict_name: "Arcamanik",
          },
          {
            subdistrict_id: "341",
            subdistrict_name: "Astana Anyar",
          },
          {
            subdistrict_id: "342",
            subdistrict_name: "Babakan Ciparay",
          },
          {
            subdistrict_id: "343",
            subdistrict_name: "Bandung Kidul",
          },
          {
            subdistrict_id: "344",
            subdistrict_name: "Bandung Kulon",
          },
          {
            subdistrict_id: "345",
            subdistrict_name: "Bandung Wetan",
          },
          {
            subdistrict_id: "346",
            subdistrict_name: "Batununggal",
          },
          {
            subdistrict_id: "347",
            subdistrict_name: "Bojongloa Kaler",
          },
          {
            subdistrict_id: "348",
            subdistrict_name: "Bojongloa Kidul",
          },
          {
            subdistrict_id: "349",
            subdistrict_name: "Buahbatu (Margacinta)",
          },
          {
            subdistrict_id: "350",
            subdistrict_name: "Cibeunying Kaler",
          },
          {
            subdistrict_id: "351",
            subdistrict_name: "Cibeunying Kidul",
          },
          {
            subdistrict_id: "352",
            subdistrict_name: "Cibiru",
          },
          {
            subdistrict_id: "353",
            subdistrict_name: "Cicendo",
          },
          {
            subdistrict_id: "354",
            subdistrict_name: "Cidadap",
          },
          {
            subdistrict_id: "355",
            subdistrict_name: "Cinambo",
          },
          {
            subdistrict_id: "356",
            subdistrict_name: "Coblong",
          },
          {
            subdistrict_id: "357",
            subdistrict_name: "Gedebage",
          },
          {
            subdistrict_id: "358",
            subdistrict_name: "Kiaracondong",
          },
          {
            subdistrict_id: "359",
            subdistrict_name: "Lengkong",
          },
          {
            subdistrict_id: "360",
            subdistrict_name: "Mandalajati",
          },
          {
            subdistrict_id: "361",
            subdistrict_name: "Panyileukan",
          },
          {
            subdistrict_id: "362",
            subdistrict_name: "Rancasari",
          },
          {
            subdistrict_id: "363",
            subdistrict_name: "Regol",
          },
          {
            subdistrict_id: "364",
            subdistrict_name: "Sukajadi",
          },
          {
            subdistrict_id: "365",
            subdistrict_name: "Sukasari",
          },
          {
            subdistrict_id: "366",
            subdistrict_name: "Sumur Bandung",
          },
          {
            subdistrict_id: "367",
            subdistrict_name: "Ujung Berung",
          },
        ],
      },
      {
        city_id: "24",
        type: "Kabupaten",
        city_name: "Bandung Barat",
        subdistricts: [
          {
            subdistrict_id: "368",
            subdistrict_name: "Batujajar",
          },
          {
            subdistrict_id: "369",
            subdistrict_name: "Cihampelas",
          },
          {
            subdistrict_id: "370",
            subdistrict_name: "Cikalong Wetan",
          },
          {
            subdistrict_id: "371",
            subdistrict_name: "Cililin",
          },
          {
            subdistrict_id: "372",
            subdistrict_name: "Cipatat",
          },
          {
            subdistrict_id: "373",
            subdistrict_name: "Cipeundeuy",
          },
          {
            subdistrict_id: "374",
            subdistrict_name: "Cipongkor",
          },
          {
            subdistrict_id: "375",
            subdistrict_name: "Cisarua",
          },
          {
            subdistrict_id: "376",
            subdistrict_name: "Gununghalu",
          },
          {
            subdistrict_id: "377",
            subdistrict_name: "Lembang",
          },
          {
            subdistrict_id: "378",
            subdistrict_name: "Ngamprah",
          },
          {
            subdistrict_id: "379",
            subdistrict_name: "Padalarang",
          },
          {
            subdistrict_id: "380",
            subdistrict_name: "Parongpong",
          },
          {
            subdistrict_id: "381",
            subdistrict_name: "Rongga",
          },
          {
            subdistrict_id: "382",
            subdistrict_name: "Saguling",
          },
          {
            subdistrict_id: "383",
            subdistrict_name: "Sindangkerta",
          },
        ],
      },
      {
        city_id: "34",
        type: "Kota",
        city_name: "Banjar",
        subdistricts: [
          {
            subdistrict_id: "495",
            subdistrict_name: "Banjar",
          },
          {
            subdistrict_id: "496",
            subdistrict_name: "Langensari",
          },
          {
            subdistrict_id: "497",
            subdistrict_name: "Pataruman",
          },
          {
            subdistrict_id: "498",
            subdistrict_name: "Purwaharja",
          },
        ],
      },
      {
        city_id: "54",
        type: "Kabupaten",
        city_name: "Bekasi",
        subdistricts: [
          {
            subdistrict_id: "726",
            subdistrict_name: "Babelan",
          },
          {
            subdistrict_id: "727",
            subdistrict_name: "Bojongmangu",
          },
          {
            subdistrict_id: "728",
            subdistrict_name: "Cabangbungin",
          },
          {
            subdistrict_id: "729",
            subdistrict_name: "Cibarusah",
          },
          {
            subdistrict_id: "730",
            subdistrict_name: "Cibitung",
          },
          {
            subdistrict_id: "731",
            subdistrict_name: "Cikarang Barat",
          },
          {
            subdistrict_id: "732",
            subdistrict_name: "Cikarang Pusat",
          },
          {
            subdistrict_id: "733",
            subdistrict_name: "Cikarang Selatan",
          },
          {
            subdistrict_id: "734",
            subdistrict_name: "Cikarang Timur",
          },
          {
            subdistrict_id: "735",
            subdistrict_name: "Cikarang Utara",
          },
          {
            subdistrict_id: "736",
            subdistrict_name: "Karangbahagia",
          },
          {
            subdistrict_id: "737",
            subdistrict_name: "Kedung Waringin",
          },
          {
            subdistrict_id: "738",
            subdistrict_name: "Muara Gembong",
          },
          {
            subdistrict_id: "739",
            subdistrict_name: "Pebayuran",
          },
          {
            subdistrict_id: "740",
            subdistrict_name: "Serang Baru",
          },
          {
            subdistrict_id: "741",
            subdistrict_name: "Setu",
          },
          {
            subdistrict_id: "742",
            subdistrict_name: "Sukakarya",
          },
          {
            subdistrict_id: "743",
            subdistrict_name: "Sukatani",
          },
          {
            subdistrict_id: "744",
            subdistrict_name: "Sukawangi",
          },
          {
            subdistrict_id: "745",
            subdistrict_name: "Tambelang",
          },
          {
            subdistrict_id: "746",
            subdistrict_name: "Tambun Selatan",
          },
          {
            subdistrict_id: "747",
            subdistrict_name: "Tambun Utara",
          },
          {
            subdistrict_id: "748",
            subdistrict_name: "Tarumajaya",
          },
        ],
      },
      {
        city_id: "55",
        type: "Kota",
        city_name: "Bekasi",
        subdistricts: [
          {
            subdistrict_id: "749",
            subdistrict_name: "Bantar Gebang",
          },
          {
            subdistrict_id: "750",
            subdistrict_name: "Bekasi Barat",
          },
          {
            subdistrict_id: "751",
            subdistrict_name: "Bekasi Selatan",
          },
          {
            subdistrict_id: "752",
            subdistrict_name: "Bekasi Timur",
          },
          {
            subdistrict_id: "753",
            subdistrict_name: "Bekasi Utara",
          },
          {
            subdistrict_id: "754",
            subdistrict_name: "Jati Sampurna",
          },
          {
            subdistrict_id: "755",
            subdistrict_name: "Jatiasih",
          },
          {
            subdistrict_id: "756",
            subdistrict_name: "Medan Satria",
          },
          {
            subdistrict_id: "757",
            subdistrict_name: "Mustika Jaya",
          },
          {
            subdistrict_id: "758",
            subdistrict_name: "Pondok Gede",
          },
          {
            subdistrict_id: "759",
            subdistrict_name: "Pondok Melati",
          },
          {
            subdistrict_id: "760",
            subdistrict_name: "Rawalumbu",
          },
        ],
      },
      {
        city_id: "78",
        type: "Kabupaten",
        city_name: "Bogor",
        subdistricts: [
          {
            subdistrict_id: "1022",
            subdistrict_name: "Babakan Madang",
          },
          {
            subdistrict_id: "1023",
            subdistrict_name: "Bojonggede",
          },
          {
            subdistrict_id: "1024",
            subdistrict_name: "Caringin",
          },
          {
            subdistrict_id: "1025",
            subdistrict_name: "Cariu",
          },
          {
            subdistrict_id: "1026",
            subdistrict_name: "Ciampea",
          },
          {
            subdistrict_id: "1027",
            subdistrict_name: "Ciawi",
          },
          {
            subdistrict_id: "1028",
            subdistrict_name: "Cibinong",
          },
          {
            subdistrict_id: "1029",
            subdistrict_name: "Cibungbulang",
          },
          {
            subdistrict_id: "1030",
            subdistrict_name: "Cigombong",
          },
          {
            subdistrict_id: "1031",
            subdistrict_name: "Cigudeg",
          },
          {
            subdistrict_id: "1032",
            subdistrict_name: "Cijeruk",
          },
          {
            subdistrict_id: "1033",
            subdistrict_name: "Cileungsi",
          },
          {
            subdistrict_id: "1034",
            subdistrict_name: "Ciomas",
          },
          {
            subdistrict_id: "1035",
            subdistrict_name: "Cisarua",
          },
          {
            subdistrict_id: "1036",
            subdistrict_name: "Ciseeng",
          },
          {
            subdistrict_id: "1037",
            subdistrict_name: "Citeureup",
          },
          {
            subdistrict_id: "1038",
            subdistrict_name: "Dramaga",
          },
          {
            subdistrict_id: "1039",
            subdistrict_name: "Gunung Putri",
          },
          {
            subdistrict_id: "1040",
            subdistrict_name: "Gunung Sindur",
          },
          {
            subdistrict_id: "1041",
            subdistrict_name: "Jasinga",
          },
          {
            subdistrict_id: "1042",
            subdistrict_name: "Jonggol",
          },
          {
            subdistrict_id: "1043",
            subdistrict_name: "Kemang",
          },
          {
            subdistrict_id: "1044",
            subdistrict_name: "Klapa Nunggal (Kelapa Nunggal)",
          },
          {
            subdistrict_id: "1045",
            subdistrict_name: "Leuwiliang",
          },
          {
            subdistrict_id: "1046",
            subdistrict_name: "Leuwisadeng",
          },
          {
            subdistrict_id: "1047",
            subdistrict_name: "Megamendung",
          },
          {
            subdistrict_id: "1048",
            subdistrict_name: "Nanggung",
          },
          {
            subdistrict_id: "1049",
            subdistrict_name: "Pamijahan",
          },
          {
            subdistrict_id: "1050",
            subdistrict_name: "Parung",
          },
          {
            subdistrict_id: "1051",
            subdistrict_name: "Parung Panjang",
          },
          {
            subdistrict_id: "1052",
            subdistrict_name: "Ranca Bungur",
          },
          {
            subdistrict_id: "1053",
            subdistrict_name: "Rumpin",
          },
          {
            subdistrict_id: "1054",
            subdistrict_name: "Sukajaya",
          },
          {
            subdistrict_id: "1055",
            subdistrict_name: "Sukamakmur",
          },
          {
            subdistrict_id: "1056",
            subdistrict_name: "Sukaraja",
          },
          {
            subdistrict_id: "1057",
            subdistrict_name: "Tajurhalang",
          },
          {
            subdistrict_id: "1058",
            subdistrict_name: "Tamansari",
          },
          {
            subdistrict_id: "1059",
            subdistrict_name: "Tanjungsari",
          },
          {
            subdistrict_id: "1060",
            subdistrict_name: "Tenjo",
          },
          {
            subdistrict_id: "1061",
            subdistrict_name: "Tenjolaya",
          },
        ],
      },
      {
        city_id: "79",
        type: "Kota",
        city_name: "Bogor",
        subdistricts: [
          {
            subdistrict_id: "1062",
            subdistrict_name: "Bogor Barat - Kota",
          },
          {
            subdistrict_id: "1063",
            subdistrict_name: "Bogor Selatan - Kota",
          },
          {
            subdistrict_id: "1064",
            subdistrict_name: "Bogor Tengah - Kota",
          },
          {
            subdistrict_id: "1065",
            subdistrict_name: "Bogor Timur - Kota",
          },
          {
            subdistrict_id: "1066",
            subdistrict_name: "Bogor Utara - Kota",
          },
          {
            subdistrict_id: "1067",
            subdistrict_name: "Tanah Sereal",
          },
        ],
      },
      {
        city_id: "103",
        type: "Kabupaten",
        city_name: "Ciamis",
        subdistricts: [
          {
            subdistrict_id: "1379",
            subdistrict_name: "Banjarsari",
          },
          {
            subdistrict_id: "1380",
            subdistrict_name: "Baregbeg",
          },
          {
            subdistrict_id: "1381",
            subdistrict_name: "Ciamis",
          },
          {
            subdistrict_id: "1382",
            subdistrict_name: "Cidolog",
          },
          {
            subdistrict_id: "1383",
            subdistrict_name: "Cihaurbeuti",
          },
          {
            subdistrict_id: "1384",
            subdistrict_name: "Cijeungjing",
          },
          {
            subdistrict_id: "1385",
            subdistrict_name: "Cikoneng",
          },
          {
            subdistrict_id: "1386",
            subdistrict_name: "Cimaragas",
          },
          {
            subdistrict_id: "1387",
            subdistrict_name: "Cipaku",
          },
          {
            subdistrict_id: "1388",
            subdistrict_name: "Cisaga",
          },
          {
            subdistrict_id: "1389",
            subdistrict_name: "Jatinagara",
          },
          {
            subdistrict_id: "1390",
            subdistrict_name: "Kawali",
          },
          {
            subdistrict_id: "1391",
            subdistrict_name: "Lakbok",
          },
          {
            subdistrict_id: "1392",
            subdistrict_name: "Lumbung",
          },
          {
            subdistrict_id: "1393",
            subdistrict_name: "Pamarican",
          },
          {
            subdistrict_id: "1394",
            subdistrict_name: "Panawangan",
          },
          {
            subdistrict_id: "1395",
            subdistrict_name: "Panjalu",
          },
          {
            subdistrict_id: "1396",
            subdistrict_name: "Panumbangan",
          },
          {
            subdistrict_id: "1397",
            subdistrict_name: "Purwadadi",
          },
          {
            subdistrict_id: "1398",
            subdistrict_name: "Rajadesa",
          },
          {
            subdistrict_id: "1399",
            subdistrict_name: "Rancah",
          },
          {
            subdistrict_id: "1400",
            subdistrict_name: "Sadananya",
          },
          {
            subdistrict_id: "1401",
            subdistrict_name: "Sindangkasih",
          },
          {
            subdistrict_id: "1402",
            subdistrict_name: "Sukadana",
          },
          {
            subdistrict_id: "1403",
            subdistrict_name: "Sukamantri",
          },
          {
            subdistrict_id: "1404",
            subdistrict_name: "Tambaksari",
          },
        ],
      },
      {
        city_id: "104",
        type: "Kabupaten",
        city_name: "Cianjur",
        subdistricts: [
          {
            subdistrict_id: "1405",
            subdistrict_name: "Agrabinta",
          },
          {
            subdistrict_id: "1406",
            subdistrict_name: "Bojongpicung",
          },
          {
            subdistrict_id: "1407",
            subdistrict_name: "Campaka",
          },
          {
            subdistrict_id: "1408",
            subdistrict_name: "Campaka Mulya",
          },
          {
            subdistrict_id: "1409",
            subdistrict_name: "Cianjur",
          },
          {
            subdistrict_id: "1410",
            subdistrict_name: "Cibeber",
          },
          {
            subdistrict_id: "1411",
            subdistrict_name: "Cibinong",
          },
          {
            subdistrict_id: "1412",
            subdistrict_name: "Cidaun",
          },
          {
            subdistrict_id: "1413",
            subdistrict_name: "Cijati",
          },
          {
            subdistrict_id: "1414",
            subdistrict_name: "Cikadu",
          },
          {
            subdistrict_id: "1415",
            subdistrict_name: "Cikalongkulon",
          },
          {
            subdistrict_id: "1416",
            subdistrict_name: "Cilaku",
          },
          {
            subdistrict_id: "1417",
            subdistrict_name: "Cipanas",
          },
          {
            subdistrict_id: "1418",
            subdistrict_name: "Ciranjang",
          },
          {
            subdistrict_id: "1419",
            subdistrict_name: "Cugenang",
          },
          {
            subdistrict_id: "1420",
            subdistrict_name: "Gekbrong",
          },
          {
            subdistrict_id: "1421",
            subdistrict_name: "Haurwangi",
          },
          {
            subdistrict_id: "1422",
            subdistrict_name: "Kadupandak",
          },
          {
            subdistrict_id: "1423",
            subdistrict_name: "Karangtengah",
          },
          {
            subdistrict_id: "1424",
            subdistrict_name: "Leles",
          },
          {
            subdistrict_id: "1425",
            subdistrict_name: "Mande",
          },
          {
            subdistrict_id: "1426",
            subdistrict_name: "Naringgul",
          },
          {
            subdistrict_id: "1427",
            subdistrict_name: "Pacet",
          },
          {
            subdistrict_id: "1428",
            subdistrict_name: "Pagelaran",
          },
          {
            subdistrict_id: "1429",
            subdistrict_name: "Pasirkuda",
          },
          {
            subdistrict_id: "1430",
            subdistrict_name: "Sindangbarang",
          },
          {
            subdistrict_id: "1431",
            subdistrict_name: "Sukaluyu",
          },
          {
            subdistrict_id: "1432",
            subdistrict_name: "Sukanagara",
          },
          {
            subdistrict_id: "1433",
            subdistrict_name: "Sukaresmi",
          },
          {
            subdistrict_id: "1434",
            subdistrict_name: "Takokak",
          },
          {
            subdistrict_id: "1435",
            subdistrict_name: "Tanggeung",
          },
          {
            subdistrict_id: "1436",
            subdistrict_name: "Warungkondang",
          },
        ],
      },
      {
        city_id: "107",
        type: "Kota",
        city_name: "Cimahi",
        subdistricts: [
          {
            subdistrict_id: "1469",
            subdistrict_name: "Cimahi Selatan",
          },
          {
            subdistrict_id: "1470",
            subdistrict_name: "Cimahi Tengah",
          },
          {
            subdistrict_id: "1471",
            subdistrict_name: "Cimahi Utara",
          },
        ],
      },
      {
        city_id: "108",
        type: "Kabupaten",
        city_name: "Cirebon",
        subdistricts: [
          {
            subdistrict_id: "1472",
            subdistrict_name: "Arjawinangun",
          },
          {
            subdistrict_id: "1473",
            subdistrict_name: "Astanajapura",
          },
          {
            subdistrict_id: "1474",
            subdistrict_name: "Babakan",
          },
          {
            subdistrict_id: "1475",
            subdistrict_name: "Beber",
          },
          {
            subdistrict_id: "1476",
            subdistrict_name: "Ciledug",
          },
          {
            subdistrict_id: "1477",
            subdistrict_name: "Ciwaringin",
          },
          {
            subdistrict_id: "1478",
            subdistrict_name: "Depok",
          },
          {
            subdistrict_id: "1479",
            subdistrict_name: "Dukupuntang",
          },
          {
            subdistrict_id: "1480",
            subdistrict_name: "Gebang",
          },
          {
            subdistrict_id: "1481",
            subdistrict_name: "Gegesik",
          },
          {
            subdistrict_id: "1482",
            subdistrict_name: "Gempol",
          },
          {
            subdistrict_id: "1483",
            subdistrict_name: "Greged (Greget)",
          },
          {
            subdistrict_id: "1484",
            subdistrict_name: "Gunung Jati (Cirebon Utara)",
          },
          {
            subdistrict_id: "1485",
            subdistrict_name: "Jamblang",
          },
          {
            subdistrict_id: "1486",
            subdistrict_name: "Kaliwedi",
          },
          {
            subdistrict_id: "1487",
            subdistrict_name: "Kapetakan",
          },
          {
            subdistrict_id: "1488",
            subdistrict_name: "Karangsembung",
          },
          {
            subdistrict_id: "1489",
            subdistrict_name: "Karangwareng",
          },
          {
            subdistrict_id: "1490",
            subdistrict_name: "Kedawung",
          },
          {
            subdistrict_id: "1491",
            subdistrict_name: "Klangenan",
          },
          {
            subdistrict_id: "1492",
            subdistrict_name: "Lemahabang",
          },
          {
            subdistrict_id: "1493",
            subdistrict_name: "Losari",
          },
          {
            subdistrict_id: "1494",
            subdistrict_name: "Mundu",
          },
          {
            subdistrict_id: "1495",
            subdistrict_name: "Pabedilan",
          },
          {
            subdistrict_id: "1496",
            subdistrict_name: "Pabuaran",
          },
          {
            subdistrict_id: "1497",
            subdistrict_name: "Palimanan",
          },
          {
            subdistrict_id: "1498",
            subdistrict_name: "Pangenan",
          },
          {
            subdistrict_id: "1499",
            subdistrict_name: "Panguragan",
          },
          {
            subdistrict_id: "1500",
            subdistrict_name: "Pasaleman",
          },
          {
            subdistrict_id: "1501",
            subdistrict_name: "Plered",
          },
          {
            subdistrict_id: "1502",
            subdistrict_name: "Plumbon",
          },
          {
            subdistrict_id: "1503",
            subdistrict_name: "Sedong",
          },
          {
            subdistrict_id: "1504",
            subdistrict_name: "Sumber",
          },
          {
            subdistrict_id: "1505",
            subdistrict_name: "Suranenggala",
          },
          {
            subdistrict_id: "1506",
            subdistrict_name: "Susukan",
          },
          {
            subdistrict_id: "1507",
            subdistrict_name: "Susukan Lebak",
          },
          {
            subdistrict_id: "1508",
            subdistrict_name: "Talun (Cirebon Selatan)",
          },
          {
            subdistrict_id: "1509",
            subdistrict_name: "Tengah Tani",
          },
          {
            subdistrict_id: "1510",
            subdistrict_name: "Waled",
          },
          {
            subdistrict_id: "1511",
            subdistrict_name: "Weru",
          },
        ],
      },
      {
        city_id: "109",
        type: "Kota",
        city_name: "Cirebon",
        subdistricts: [
          {
            subdistrict_id: "1512",
            subdistrict_name: "Harjamukti",
          },
          {
            subdistrict_id: "1513",
            subdistrict_name: "Kejaksan",
          },
          {
            subdistrict_id: "1514",
            subdistrict_name: "Kesambi",
          },
          {
            subdistrict_id: "1515",
            subdistrict_name: "Lemahwungkuk",
          },
          {
            subdistrict_id: "1516",
            subdistrict_name: "Pekalipan",
          },
        ],
      },
      {
        city_id: "115",
        type: "Kota",
        city_name: "Depok",
        subdistricts: [
          {
            subdistrict_id: "1577",
            subdistrict_name: "Beji",
          },
          {
            subdistrict_id: "1578",
            subdistrict_name: "Bojongsari",
          },
          {
            subdistrict_id: "1579",
            subdistrict_name: "Cilodong",
          },
          {
            subdistrict_id: "1580",
            subdistrict_name: "Cimanggis",
          },
          {
            subdistrict_id: "1581",
            subdistrict_name: "Cinere",
          },
          {
            subdistrict_id: "1582",
            subdistrict_name: "Cipayung",
          },
          {
            subdistrict_id: "1583",
            subdistrict_name: "Limo",
          },
          {
            subdistrict_id: "1584",
            subdistrict_name: "Pancoran Mas",
          },
          {
            subdistrict_id: "1585",
            subdistrict_name: "Sawangan",
          },
          {
            subdistrict_id: "1586",
            subdistrict_name: "Sukmajaya",
          },
          {
            subdistrict_id: "1587",
            subdistrict_name: "Tapos",
          },
        ],
      },
      {
        city_id: "126",
        type: "Kabupaten",
        city_name: "Garut",
        subdistricts: [
          {
            subdistrict_id: "1711",
            subdistrict_name: "Banjarwangi",
          },
          {
            subdistrict_id: "1712",
            subdistrict_name: "Banyuresmi",
          },
          {
            subdistrict_id: "1713",
            subdistrict_name: "Bayongbong",
          },
          {
            subdistrict_id: "1714",
            subdistrict_name: "Blubur Limbangan",
          },
          {
            subdistrict_id: "1715",
            subdistrict_name: "Bungbulang",
          },
          {
            subdistrict_id: "1716",
            subdistrict_name: "Caringin",
          },
          {
            subdistrict_id: "1717",
            subdistrict_name: "Cibalong",
          },
          {
            subdistrict_id: "1718",
            subdistrict_name: "Cibatu",
          },
          {
            subdistrict_id: "1719",
            subdistrict_name: "Cibiuk",
          },
          {
            subdistrict_id: "1720",
            subdistrict_name: "Cigedug",
          },
          {
            subdistrict_id: "1721",
            subdistrict_name: "Cihurip",
          },
          {
            subdistrict_id: "1722",
            subdistrict_name: "Cikajang",
          },
          {
            subdistrict_id: "1723",
            subdistrict_name: "Cikelet",
          },
          {
            subdistrict_id: "1724",
            subdistrict_name: "Cilawu",
          },
          {
            subdistrict_id: "1725",
            subdistrict_name: "Cisewu",
          },
          {
            subdistrict_id: "1726",
            subdistrict_name: "Cisompet",
          },
          {
            subdistrict_id: "1727",
            subdistrict_name: "Cisurupan",
          },
          {
            subdistrict_id: "1728",
            subdistrict_name: "Garut Kota",
          },
          {
            subdistrict_id: "1729",
            subdistrict_name: "Kadungora",
          },
          {
            subdistrict_id: "1730",
            subdistrict_name: "Karangpawitan",
          },
          {
            subdistrict_id: "1731",
            subdistrict_name: "Karangtengah",
          },
          {
            subdistrict_id: "1732",
            subdistrict_name: "Kersamanah",
          },
          {
            subdistrict_id: "1733",
            subdistrict_name: "Leles",
          },
          {
            subdistrict_id: "1734",
            subdistrict_name: "Leuwigoong",
          },
          {
            subdistrict_id: "1735",
            subdistrict_name: "Malangbong",
          },
          {
            subdistrict_id: "1736",
            subdistrict_name: "Mekarmukti",
          },
          {
            subdistrict_id: "1737",
            subdistrict_name: "Pakenjeng",
          },
          {
            subdistrict_id: "1738",
            subdistrict_name: "Pameungpeuk",
          },
          {
            subdistrict_id: "1739",
            subdistrict_name: "Pamulihan",
          },
          {
            subdistrict_id: "1740",
            subdistrict_name: "Pangatikan",
          },
          {
            subdistrict_id: "1741",
            subdistrict_name: "Pasirwangi",
          },
          {
            subdistrict_id: "1742",
            subdistrict_name: "Peundeuy",
          },
          {
            subdistrict_id: "1743",
            subdistrict_name: "Samarang",
          },
          {
            subdistrict_id: "1744",
            subdistrict_name: "Selaawi",
          },
          {
            subdistrict_id: "1745",
            subdistrict_name: "Singajaya",
          },
          {
            subdistrict_id: "1746",
            subdistrict_name: "Sucinaraja",
          },
          {
            subdistrict_id: "1747",
            subdistrict_name: "Sukaresmi",
          },
          {
            subdistrict_id: "1748",
            subdistrict_name: "Sukawening",
          },
          {
            subdistrict_id: "1749",
            subdistrict_name: "Talegong",
          },
          {
            subdistrict_id: "1750",
            subdistrict_name: "Tarogong Kaler",
          },
          {
            subdistrict_id: "1751",
            subdistrict_name: "Tarogong Kidul",
          },
          {
            subdistrict_id: "1752",
            subdistrict_name: "Wanaraja",
          },
        ],
      },
      {
        city_id: "149",
        type: "Kabupaten",
        city_name: "Indramayu",
        subdistricts: [
          {
            subdistrict_id: "2050",
            subdistrict_name: "Anjatan",
          },
          {
            subdistrict_id: "2051",
            subdistrict_name: "Arahan",
          },
          {
            subdistrict_id: "2052",
            subdistrict_name: "Balongan",
          },
          {
            subdistrict_id: "2053",
            subdistrict_name: "Bangodua",
          },
          {
            subdistrict_id: "2054",
            subdistrict_name: "Bongas",
          },
          {
            subdistrict_id: "2055",
            subdistrict_name: "Cantigi",
          },
          {
            subdistrict_id: "2056",
            subdistrict_name: "Cikedung",
          },
          {
            subdistrict_id: "2057",
            subdistrict_name: "Gabuswetan",
          },
          {
            subdistrict_id: "2058",
            subdistrict_name: "Gantar",
          },
          {
            subdistrict_id: "2059",
            subdistrict_name: "Haurgeulis",
          },
          {
            subdistrict_id: "2060",
            subdistrict_name: "Indramayu",
          },
          {
            subdistrict_id: "2061",
            subdistrict_name: "Jatibarang",
          },
          {
            subdistrict_id: "2062",
            subdistrict_name: "Juntinyuat",
          },
          {
            subdistrict_id: "2063",
            subdistrict_name: "Kandanghaur",
          },
          {
            subdistrict_id: "2064",
            subdistrict_name: "Karangampel",
          },
          {
            subdistrict_id: "2065",
            subdistrict_name: "Kedokan Bunder",
          },
          {
            subdistrict_id: "2066",
            subdistrict_name: "Kertasemaya",
          },
          {
            subdistrict_id: "2067",
            subdistrict_name: "Krangkeng",
          },
          {
            subdistrict_id: "2068",
            subdistrict_name: "Kroya",
          },
          {
            subdistrict_id: "2069",
            subdistrict_name: "Lelea",
          },
          {
            subdistrict_id: "2070",
            subdistrict_name: "Lohbener",
          },
          {
            subdistrict_id: "2071",
            subdistrict_name: "Losarang",
          },
          {
            subdistrict_id: "2072",
            subdistrict_name: "Pasekan",
          },
          {
            subdistrict_id: "2073",
            subdistrict_name: "Patrol",
          },
          {
            subdistrict_id: "2074",
            subdistrict_name: "Sindang",
          },
          {
            subdistrict_id: "2075",
            subdistrict_name: "Sliyeg",
          },
          {
            subdistrict_id: "2076",
            subdistrict_name: "Sukagumiwang",
          },
          {
            subdistrict_id: "2077",
            subdistrict_name: "Sukra",
          },
          {
            subdistrict_id: "2078",
            subdistrict_name: "Trisi/Terisi",
          },
          {
            subdistrict_id: "2079",
            subdistrict_name: "Tukdana",
          },
          {
            subdistrict_id: "2080",
            subdistrict_name: "Widasari",
          },
        ],
      },
      {
        city_id: "171",
        type: "Kabupaten",
        city_name: "Karawang",
        subdistricts: [
          {
            subdistrict_id: "2378",
            subdistrict_name: "Banyusari",
          },
          {
            subdistrict_id: "2379",
            subdistrict_name: "Batujaya",
          },
          {
            subdistrict_id: "2380",
            subdistrict_name: "Ciampel",
          },
          {
            subdistrict_id: "2381",
            subdistrict_name: "Cibuaya",
          },
          {
            subdistrict_id: "2382",
            subdistrict_name: "Cikampek",
          },
          {
            subdistrict_id: "2383",
            subdistrict_name: "Cilamaya Kulon",
          },
          {
            subdistrict_id: "2384",
            subdistrict_name: "Cilamaya Wetan",
          },
          {
            subdistrict_id: "2385",
            subdistrict_name: "Cilebar",
          },
          {
            subdistrict_id: "2386",
            subdistrict_name: "Jatisari",
          },
          {
            subdistrict_id: "2387",
            subdistrict_name: "Jayakerta",
          },
          {
            subdistrict_id: "2388",
            subdistrict_name: "Karawang Barat",
          },
          {
            subdistrict_id: "2389",
            subdistrict_name: "Karawang Timur",
          },
          {
            subdistrict_id: "2390",
            subdistrict_name: "Klari",
          },
          {
            subdistrict_id: "2391",
            subdistrict_name: "Kotabaru",
          },
          {
            subdistrict_id: "2392",
            subdistrict_name: "Kutawaluya",
          },
          {
            subdistrict_id: "2393",
            subdistrict_name: "Lemahabang",
          },
          {
            subdistrict_id: "2394",
            subdistrict_name: "Majalaya",
          },
          {
            subdistrict_id: "2395",
            subdistrict_name: "Pakisjaya",
          },
          {
            subdistrict_id: "2396",
            subdistrict_name: "Pangkalan",
          },
          {
            subdistrict_id: "2397",
            subdistrict_name: "Pedes",
          },
          {
            subdistrict_id: "2398",
            subdistrict_name: "Purwasari",
          },
          {
            subdistrict_id: "2399",
            subdistrict_name: "Rawamerta",
          },
          {
            subdistrict_id: "2400",
            subdistrict_name: "Rengasdengklok",
          },
          {
            subdistrict_id: "2401",
            subdistrict_name: "Talagasari",
          },
          {
            subdistrict_id: "2402",
            subdistrict_name: "Tegalwaru",
          },
          {
            subdistrict_id: "2403",
            subdistrict_name: "Telukjambe Barat",
          },
          {
            subdistrict_id: "2404",
            subdistrict_name: "Telukjambe Timur",
          },
          {
            subdistrict_id: "2405",
            subdistrict_name: "Tempuran",
          },
          {
            subdistrict_id: "2406",
            subdistrict_name: "Tirtajaya",
          },
          {
            subdistrict_id: "2407",
            subdistrict_name: "Tirtamulya",
          },
        ],
      },
      {
        city_id: "211",
        type: "Kabupaten",
        city_name: "Kuningan",
        subdistricts: [
          {
            subdistrict_id: "2942",
            subdistrict_name: "Ciawigebang",
          },
          {
            subdistrict_id: "2943",
            subdistrict_name: "Cibeureum",
          },
          {
            subdistrict_id: "2944",
            subdistrict_name: "Cibingbin",
          },
          {
            subdistrict_id: "2945",
            subdistrict_name: "Cidahu",
          },
          {
            subdistrict_id: "2946",
            subdistrict_name: "Cigandamekar",
          },
          {
            subdistrict_id: "2947",
            subdistrict_name: "Cigugur",
          },
          {
            subdistrict_id: "2948",
            subdistrict_name: "Cilebak",
          },
          {
            subdistrict_id: "2949",
            subdistrict_name: "Cilimus",
          },
          {
            subdistrict_id: "2950",
            subdistrict_name: "Cimahi",
          },
          {
            subdistrict_id: "2951",
            subdistrict_name: "Ciniru",
          },
          {
            subdistrict_id: "2952",
            subdistrict_name: "Cipicung",
          },
          {
            subdistrict_id: "2953",
            subdistrict_name: "Ciwaru",
          },
          {
            subdistrict_id: "2954",
            subdistrict_name: "Darma",
          },
          {
            subdistrict_id: "2955",
            subdistrict_name: "Garawangi",
          },
          {
            subdistrict_id: "2956",
            subdistrict_name: "Hantara",
          },
          {
            subdistrict_id: "2957",
            subdistrict_name: "Jalaksana",
          },
          {
            subdistrict_id: "2958",
            subdistrict_name: "Japara",
          },
          {
            subdistrict_id: "2959",
            subdistrict_name: "Kadugede",
          },
          {
            subdistrict_id: "2960",
            subdistrict_name: "Kalimanggis",
          },
          {
            subdistrict_id: "2961",
            subdistrict_name: "Karangkancana",
          },
          {
            subdistrict_id: "2962",
            subdistrict_name: "Kramat Mulya",
          },
          {
            subdistrict_id: "2963",
            subdistrict_name: "Kuningan",
          },
          {
            subdistrict_id: "2964",
            subdistrict_name: "Lebakwangi",
          },
          {
            subdistrict_id: "2965",
            subdistrict_name: "Luragung",
          },
          {
            subdistrict_id: "2966",
            subdistrict_name: "Maleber",
          },
          {
            subdistrict_id: "2967",
            subdistrict_name: "Mandirancan",
          },
          {
            subdistrict_id: "2968",
            subdistrict_name: "Nusaherang",
          },
          {
            subdistrict_id: "2969",
            subdistrict_name: "Pancalang",
          },
          {
            subdistrict_id: "2970",
            subdistrict_name: "Pasawahan",
          },
          {
            subdistrict_id: "2971",
            subdistrict_name: "Selajambe",
          },
          {
            subdistrict_id: "2972",
            subdistrict_name: "Sindangagung",
          },
          {
            subdistrict_id: "2973",
            subdistrict_name: "Subang",
          },
        ],
      },
      {
        city_id: "252",
        type: "Kabupaten",
        city_name: "Majalengka",
        subdistricts: [
          {
            subdistrict_id: "3553",
            subdistrict_name: "Argapura",
          },
          {
            subdistrict_id: "3554",
            subdistrict_name: "Banjaran",
          },
          {
            subdistrict_id: "3555",
            subdistrict_name: "Bantarujeg",
          },
          {
            subdistrict_id: "3556",
            subdistrict_name: "Cigasong",
          },
          {
            subdistrict_id: "3557",
            subdistrict_name: "Cikijing",
          },
          {
            subdistrict_id: "3558",
            subdistrict_name: "Cingambul",
          },
          {
            subdistrict_id: "3559",
            subdistrict_name: "Dawuan",
          },
          {
            subdistrict_id: "3560",
            subdistrict_name: "Jatitujuh",
          },
          {
            subdistrict_id: "3561",
            subdistrict_name: "Jatiwangi",
          },
          {
            subdistrict_id: "3562",
            subdistrict_name: "Kadipaten",
          },
          {
            subdistrict_id: "3563",
            subdistrict_name: "Kasokandel",
          },
          {
            subdistrict_id: "3564",
            subdistrict_name: "Kertajati",
          },
          {
            subdistrict_id: "3565",
            subdistrict_name: "Lemahsugih",
          },
          {
            subdistrict_id: "3566",
            subdistrict_name: "Leuwimunding",
          },
          {
            subdistrict_id: "3567",
            subdistrict_name: "Ligung",
          },
          {
            subdistrict_id: "3568",
            subdistrict_name: "Maja",
          },
          {
            subdistrict_id: "3569",
            subdistrict_name: "Majalengka",
          },
          {
            subdistrict_id: "3570",
            subdistrict_name: "Malausma",
          },
          {
            subdistrict_id: "3571",
            subdistrict_name: "Palasah",
          },
          {
            subdistrict_id: "3572",
            subdistrict_name: "Panyingkiran",
          },
          {
            subdistrict_id: "3573",
            subdistrict_name: "Rajagaluh",
          },
          {
            subdistrict_id: "3574",
            subdistrict_name: "Sindang",
          },
          {
            subdistrict_id: "3575",
            subdistrict_name: "Sindangwangi",
          },
          {
            subdistrict_id: "3576",
            subdistrict_name: "Sukahaji",
          },
          {
            subdistrict_id: "3577",
            subdistrict_name: "Sumberjaya",
          },
          {
            subdistrict_id: "3578",
            subdistrict_name: "Talaga",
          },
        ],
      },
      {
        city_id: "332",
        type: "Kabupaten",
        city_name: "Pangandaran",
        subdistricts: [
          {
            subdistrict_id: "4690",
            subdistrict_name: "Cigugur",
          },
          {
            subdistrict_id: "4691",
            subdistrict_name: "Cijulang",
          },
          {
            subdistrict_id: "4692",
            subdistrict_name: "Cimerak",
          },
          {
            subdistrict_id: "4693",
            subdistrict_name: "Kalipucang",
          },
          {
            subdistrict_id: "4694",
            subdistrict_name: "Langkaplancar",
          },
          {
            subdistrict_id: "4695",
            subdistrict_name: "Mangunjaya",
          },
          {
            subdistrict_id: "4696",
            subdistrict_name: "Padaherang",
          },
          {
            subdistrict_id: "4697",
            subdistrict_name: "Pangandaran",
          },
          {
            subdistrict_id: "4698",
            subdistrict_name: "Parigi",
          },
          {
            subdistrict_id: "4699",
            subdistrict_name: "Sidamulih",
          },
        ],
      },
      {
        city_id: "376",
        type: "Kabupaten",
        city_name: "Purwakarta",
        subdistricts: [
          {
            subdistrict_id: "5217",
            subdistrict_name: "Babakancikao",
          },
          {
            subdistrict_id: "5218",
            subdistrict_name: "Bojong",
          },
          {
            subdistrict_id: "5219",
            subdistrict_name: "Bungursari",
          },
          {
            subdistrict_id: "5220",
            subdistrict_name: "Campaka",
          },
          {
            subdistrict_id: "5221",
            subdistrict_name: "Cibatu",
          },
          {
            subdistrict_id: "5222",
            subdistrict_name: "Darangdan",
          },
          {
            subdistrict_id: "5223",
            subdistrict_name: "Jatiluhur",
          },
          {
            subdistrict_id: "5224",
            subdistrict_name: "Kiarapedes",
          },
          {
            subdistrict_id: "5225",
            subdistrict_name: "Maniis",
          },
          {
            subdistrict_id: "5226",
            subdistrict_name: "Pasawahan",
          },
          {
            subdistrict_id: "5227",
            subdistrict_name: "Plered",
          },
          {
            subdistrict_id: "5228",
            subdistrict_name: "Pondoksalam",
          },
          {
            subdistrict_id: "5229",
            subdistrict_name: "Purwakarta",
          },
          {
            subdistrict_id: "5230",
            subdistrict_name: "Sukasari",
          },
          {
            subdistrict_id: "5231",
            subdistrict_name: "Sukatani",
          },
          {
            subdistrict_id: "5232",
            subdistrict_name: "Tegal Waru",
          },
          {
            subdistrict_id: "5233",
            subdistrict_name: "Wanayasa",
          },
        ],
      },
      {
        city_id: "428",
        type: "Kabupaten",
        city_name: "Subang",
        subdistricts: [
          {
            subdistrict_id: "5883",
            subdistrict_name: "Binong",
          },
          {
            subdistrict_id: "5884",
            subdistrict_name: "Blanakan",
          },
          {
            subdistrict_id: "5885",
            subdistrict_name: "Ciasem",
          },
          {
            subdistrict_id: "5886",
            subdistrict_name: "Ciater",
          },
          {
            subdistrict_id: "5887",
            subdistrict_name: "Cibogo",
          },
          {
            subdistrict_id: "5888",
            subdistrict_name: "Cijambe",
          },
          {
            subdistrict_id: "5889",
            subdistrict_name: "Cikaum",
          },
          {
            subdistrict_id: "5890",
            subdistrict_name: "Cipeundeuy",
          },
          {
            subdistrict_id: "5891",
            subdistrict_name: "Cipunagara",
          },
          {
            subdistrict_id: "5892",
            subdistrict_name: "Cisalak",
          },
          {
            subdistrict_id: "5893",
            subdistrict_name: "Compreng",
          },
          {
            subdistrict_id: "5894",
            subdistrict_name: "Dawuan",
          },
          {
            subdistrict_id: "5895",
            subdistrict_name: "Jalancagak",
          },
          {
            subdistrict_id: "5896",
            subdistrict_name: "Kalijati",
          },
          {
            subdistrict_id: "5897",
            subdistrict_name: "Kasomalang",
          },
          {
            subdistrict_id: "5898",
            subdistrict_name: "Legonkulon",
          },
          {
            subdistrict_id: "5899",
            subdistrict_name: "Pabuaran",
          },
          {
            subdistrict_id: "5900",
            subdistrict_name: "Pagaden",
          },
          {
            subdistrict_id: "5901",
            subdistrict_name: "Pagaden Barat",
          },
          {
            subdistrict_id: "5902",
            subdistrict_name: "Pamanukan",
          },
          {
            subdistrict_id: "5903",
            subdistrict_name: "Patokbeusi",
          },
          {
            subdistrict_id: "5904",
            subdistrict_name: "Purwadadi",
          },
          {
            subdistrict_id: "5905",
            subdistrict_name: "Pusakajaya",
          },
          {
            subdistrict_id: "5906",
            subdistrict_name: "Pusakanagara",
          },
          {
            subdistrict_id: "5907",
            subdistrict_name: "Sagalaherang",
          },
          {
            subdistrict_id: "5908",
            subdistrict_name: "Serangpanjang",
          },
          {
            subdistrict_id: "5909",
            subdistrict_name: "Subang",
          },
          {
            subdistrict_id: "5910",
            subdistrict_name: "Sukasari",
          },
          {
            subdistrict_id: "5911",
            subdistrict_name: "Tambakdahan",
          },
          {
            subdistrict_id: "5912",
            subdistrict_name: "Tanjungsiang",
          },
        ],
      },
      {
        city_id: "430",
        type: "Kabupaten",
        city_name: "Sukabumi",
        subdistricts: [
          {
            subdistrict_id: "5918",
            subdistrict_name: "Bantargadung",
          },
          {
            subdistrict_id: "5919",
            subdistrict_name: "Bojong Genteng",
          },
          {
            subdistrict_id: "5920",
            subdistrict_name: "Caringin",
          },
          {
            subdistrict_id: "5921",
            subdistrict_name: "Ciambar",
          },
          {
            subdistrict_id: "5922",
            subdistrict_name: "Cibadak",
          },
          {
            subdistrict_id: "5923",
            subdistrict_name: "Cibitung",
          },
          {
            subdistrict_id: "5924",
            subdistrict_name: "Cicantayan",
          },
          {
            subdistrict_id: "5925",
            subdistrict_name: "Cicurug",
          },
          {
            subdistrict_id: "5926",
            subdistrict_name: "Cidadap",
          },
          {
            subdistrict_id: "5927",
            subdistrict_name: "Cidahu",
          },
          {
            subdistrict_id: "5928",
            subdistrict_name: "Cidolog",
          },
          {
            subdistrict_id: "5929",
            subdistrict_name: "Ciemas",
          },
          {
            subdistrict_id: "5930",
            subdistrict_name: "Cikakak",
          },
          {
            subdistrict_id: "5931",
            subdistrict_name: "Cikembar",
          },
          {
            subdistrict_id: "5932",
            subdistrict_name: "Cikidang",
          },
          {
            subdistrict_id: "5933",
            subdistrict_name: "Cimanggu",
          },
          {
            subdistrict_id: "5934",
            subdistrict_name: "Ciracap",
          },
          {
            subdistrict_id: "5935",
            subdistrict_name: "Cireunghas",
          },
          {
            subdistrict_id: "5936",
            subdistrict_name: "Cisaat",
          },
          {
            subdistrict_id: "5937",
            subdistrict_name: "Cisolok",
          },
          {
            subdistrict_id: "5938",
            subdistrict_name: "Curugkembar",
          },
          {
            subdistrict_id: "5939",
            subdistrict_name: "Geger Bitung",
          },
          {
            subdistrict_id: "5940",
            subdistrict_name: "Gunung Guruh",
          },
          {
            subdistrict_id: "5941",
            subdistrict_name: "Jampang Kulon",
          },
          {
            subdistrict_id: "5942",
            subdistrict_name: "Jampang Tengah",
          },
          {
            subdistrict_id: "5943",
            subdistrict_name: "Kabandungan",
          },
          {
            subdistrict_id: "5944",
            subdistrict_name: "Kadudampit",
          },
          {
            subdistrict_id: "5945",
            subdistrict_name: "Kalapa Nunggal",
          },
          {
            subdistrict_id: "5946",
            subdistrict_name: "Kali Bunder",
          },
          {
            subdistrict_id: "5947",
            subdistrict_name: "Kebonpedes",
          },
          {
            subdistrict_id: "5948",
            subdistrict_name: "Lengkong",
          },
          {
            subdistrict_id: "5949",
            subdistrict_name: "Nagrak",
          },
          {
            subdistrict_id: "5950",
            subdistrict_name: "Nyalindung",
          },
          {
            subdistrict_id: "5951",
            subdistrict_name: "Pabuaran",
          },
          {
            subdistrict_id: "5952",
            subdistrict_name: "Parakan Salak",
          },
          {
            subdistrict_id: "5953",
            subdistrict_name: "Parung Kuda",
          },
          {
            subdistrict_id: "5954",
            subdistrict_name: "Pelabuhan/Palabuhan Ratu",
          },
          {
            subdistrict_id: "5955",
            subdistrict_name: "Purabaya",
          },
          {
            subdistrict_id: "5956",
            subdistrict_name: "Sagaranten",
          },
          {
            subdistrict_id: "5957",
            subdistrict_name: "Simpenan",
          },
          {
            subdistrict_id: "5958",
            subdistrict_name: "Sukabumi",
          },
          {
            subdistrict_id: "5959",
            subdistrict_name: "Sukalarang",
          },
          {
            subdistrict_id: "5960",
            subdistrict_name: "Sukaraja",
          },
          {
            subdistrict_id: "5961",
            subdistrict_name: "Surade",
          },
          {
            subdistrict_id: "5962",
            subdistrict_name: "Tegal Buleud",
          },
          {
            subdistrict_id: "5963",
            subdistrict_name: "Waluran",
          },
          {
            subdistrict_id: "5964",
            subdistrict_name: "Warung Kiara",
          },
        ],
      },
      {
        city_id: "431",
        type: "Kota",
        city_name: "Sukabumi",
        subdistricts: [
          {
            subdistrict_id: "5965",
            subdistrict_name: "Baros",
          },
          {
            subdistrict_id: "5966",
            subdistrict_name: "Cibeureum",
          },
          {
            subdistrict_id: "5967",
            subdistrict_name: "Cikole",
          },
          {
            subdistrict_id: "5968",
            subdistrict_name: "Citamiang",
          },
          {
            subdistrict_id: "5969",
            subdistrict_name: "Gunung Puyuh",
          },
          {
            subdistrict_id: "5970",
            subdistrict_name: "Lembursitu",
          },
          {
            subdistrict_id: "5971",
            subdistrict_name: "Warudoyong",
          },
        ],
      },
      {
        city_id: "440",
        type: "Kabupaten",
        city_name: "Sumedang",
        subdistricts: [
          {
            subdistrict_id: "6065",
            subdistrict_name: "Buahdua",
          },
          {
            subdistrict_id: "6066",
            subdistrict_name: "Cibugel",
          },
          {
            subdistrict_id: "6067",
            subdistrict_name: "Cimalaka",
          },
          {
            subdistrict_id: "6068",
            subdistrict_name: "Cimanggung",
          },
          {
            subdistrict_id: "6069",
            subdistrict_name: "Cisarua",
          },
          {
            subdistrict_id: "6070",
            subdistrict_name: "Cisitu",
          },
          {
            subdistrict_id: "6071",
            subdistrict_name: "Conggeang",
          },
          {
            subdistrict_id: "6072",
            subdistrict_name: "Darmaraja",
          },
          {
            subdistrict_id: "6073",
            subdistrict_name: "Ganeas",
          },
          {
            subdistrict_id: "6074",
            subdistrict_name: "Jatigede",
          },
          {
            subdistrict_id: "6075",
            subdistrict_name: "Jatinangor",
          },
          {
            subdistrict_id: "6076",
            subdistrict_name: "Jatinunggal",
          },
          {
            subdistrict_id: "6077",
            subdistrict_name: "Pamulihan",
          },
          {
            subdistrict_id: "6078",
            subdistrict_name: "Paseh",
          },
          {
            subdistrict_id: "6079",
            subdistrict_name: "Rancakalong",
          },
          {
            subdistrict_id: "6080",
            subdistrict_name: "Situraja",
          },
          {
            subdistrict_id: "6081",
            subdistrict_name: "Sukasari",
          },
          {
            subdistrict_id: "6082",
            subdistrict_name: "Sumedang Selatan",
          },
          {
            subdistrict_id: "6083",
            subdistrict_name: "Sumedang Utara",
          },
          {
            subdistrict_id: "6084",
            subdistrict_name: "Surian",
          },
          {
            subdistrict_id: "6085",
            subdistrict_name: "Tanjungkerta",
          },
          {
            subdistrict_id: "6086",
            subdistrict_name: "Tanjungmedar",
          },
          {
            subdistrict_id: "6087",
            subdistrict_name: "Tanjungsari",
          },
          {
            subdistrict_id: "6088",
            subdistrict_name: "Tomo",
          },
          {
            subdistrict_id: "6089",
            subdistrict_name: "Ujungjaya",
          },
          {
            subdistrict_id: "6090",
            subdistrict_name: "Wado",
          },
        ],
      },
      {
        city_id: "468",
        type: "Kabupaten",
        city_name: "Tasikmalaya",
        subdistricts: [
          {
            subdistrict_id: "6436",
            subdistrict_name: "Bantarkalong",
          },
          {
            subdistrict_id: "6437",
            subdistrict_name: "Bojongasih",
          },
          {
            subdistrict_id: "6438",
            subdistrict_name: "Bojonggambir",
          },
          {
            subdistrict_id: "6439",
            subdistrict_name: "Ciawi",
          },
          {
            subdistrict_id: "6440",
            subdistrict_name: "Cibalong",
          },
          {
            subdistrict_id: "6441",
            subdistrict_name: "Cigalontang",
          },
          {
            subdistrict_id: "6442",
            subdistrict_name: "Cikalong",
          },
          {
            subdistrict_id: "6443",
            subdistrict_name: "Cikatomas",
          },
          {
            subdistrict_id: "6444",
            subdistrict_name: "Cineam",
          },
          {
            subdistrict_id: "6445",
            subdistrict_name: "Cipatujah",
          },
          {
            subdistrict_id: "6446",
            subdistrict_name: "Cisayong",
          },
          {
            subdistrict_id: "6447",
            subdistrict_name: "Culamega",
          },
          {
            subdistrict_id: "6448",
            subdistrict_name: "Gunung Tanjung",
          },
          {
            subdistrict_id: "6449",
            subdistrict_name: "Jamanis",
          },
          {
            subdistrict_id: "6450",
            subdistrict_name: "Jatiwaras",
          },
          {
            subdistrict_id: "6451",
            subdistrict_name: "Kadipaten",
          },
          {
            subdistrict_id: "6452",
            subdistrict_name: "Karang Jaya",
          },
          {
            subdistrict_id: "6453",
            subdistrict_name: "Karangnunggal",
          },
          {
            subdistrict_id: "6454",
            subdistrict_name: "Leuwisari",
          },
          {
            subdistrict_id: "6455",
            subdistrict_name: "Mangunreja",
          },
          {
            subdistrict_id: "6456",
            subdistrict_name: "Manonjaya",
          },
          {
            subdistrict_id: "6457",
            subdistrict_name: "Padakembang",
          },
          {
            subdistrict_id: "6458",
            subdistrict_name: "Pagerageung",
          },
          {
            subdistrict_id: "6459",
            subdistrict_name: "Pancatengah",
          },
          {
            subdistrict_id: "6460",
            subdistrict_name: "Parungponteng",
          },
          {
            subdistrict_id: "6461",
            subdistrict_name: "Puspahiang",
          },
          {
            subdistrict_id: "6462",
            subdistrict_name: "Rajapolah",
          },
          {
            subdistrict_id: "6463",
            subdistrict_name: "Salawu",
          },
          {
            subdistrict_id: "6464",
            subdistrict_name: "Salopa",
          },
          {
            subdistrict_id: "6465",
            subdistrict_name: "Sariwangi",
          },
          {
            subdistrict_id: "6466",
            subdistrict_name: "Singaparna",
          },
          {
            subdistrict_id: "6467",
            subdistrict_name: "Sodonghilir",
          },
          {
            subdistrict_id: "6468",
            subdistrict_name: "Sukahening",
          },
          {
            subdistrict_id: "6469",
            subdistrict_name: "Sukaraja",
          },
          {
            subdistrict_id: "6470",
            subdistrict_name: "Sukarame",
          },
          {
            subdistrict_id: "6471",
            subdistrict_name: "Sukaratu",
          },
          {
            subdistrict_id: "6472",
            subdistrict_name: "Sukaresik",
          },
          {
            subdistrict_id: "6473",
            subdistrict_name: "Tanjungjaya",
          },
          {
            subdistrict_id: "6474",
            subdistrict_name: "Taraju",
          },
        ],
      },
      {
        city_id: "469",
        type: "Kota",
        city_name: "Tasikmalaya",
        subdistricts: [
          {
            subdistrict_id: "6475",
            subdistrict_name: "Bungursari",
          },
          {
            subdistrict_id: "6476",
            subdistrict_name: "Cibeureum",
          },
          {
            subdistrict_id: "6477",
            subdistrict_name: "Cihideung",
          },
          {
            subdistrict_id: "6478",
            subdistrict_name: "Cipedes",
          },
          {
            subdistrict_id: "6479",
            subdistrict_name: "Indihiang",
          },
          {
            subdistrict_id: "6480",
            subdistrict_name: "Kawalu",
          },
          {
            subdistrict_id: "6481",
            subdistrict_name: "Mangkubumi",
          },
          {
            subdistrict_id: "6482",
            subdistrict_name: "Purbaratu",
          },
          {
            subdistrict_id: "6483",
            subdistrict_name: "Tamansari",
          },
          {
            subdistrict_id: "6484",
            subdistrict_name: "Tawang",
          },
        ],
      },
    ],
  },
  {
    province_id: "10",
    province: "Jawa Tengah",
    cities: [
      {
        city_id: "37",
        type: "Kabupaten",
        city_name: "Banjarnegara",
        subdistricts: [
          {
            subdistrict_id: "509",
            subdistrict_name: "Banjarmangu",
          },
          {
            subdistrict_id: "510",
            subdistrict_name: "Banjarnegara",
          },
          {
            subdistrict_id: "511",
            subdistrict_name: "Batur",
          },
          {
            subdistrict_id: "512",
            subdistrict_name: "Bawang",
          },
          {
            subdistrict_id: "513",
            subdistrict_name: "Kalibening",
          },
          {
            subdistrict_id: "514",
            subdistrict_name: "Karangkobar",
          },
          {
            subdistrict_id: "515",
            subdistrict_name: "Madukara",
          },
          {
            subdistrict_id: "516",
            subdistrict_name: "Mandiraja",
          },
          {
            subdistrict_id: "517",
            subdistrict_name: "Pagedongan",
          },
          {
            subdistrict_id: "518",
            subdistrict_name: "Pagentan",
          },
          {
            subdistrict_id: "519",
            subdistrict_name: "Pandanarum",
          },
          {
            subdistrict_id: "520",
            subdistrict_name: "Pejawaran",
          },
          {
            subdistrict_id: "521",
            subdistrict_name: "Punggelan",
          },
          {
            subdistrict_id: "522",
            subdistrict_name: "Purwonegoro",
          },
          {
            subdistrict_id: "523",
            subdistrict_name: "Purworejo Klampok",
          },
          {
            subdistrict_id: "524",
            subdistrict_name: "Rakit",
          },
          {
            subdistrict_id: "525",
            subdistrict_name: "Sigaluh",
          },
          {
            subdistrict_id: "526",
            subdistrict_name: "Susukan",
          },
          {
            subdistrict_id: "527",
            subdistrict_name: "Wanadadi (Wonodadi)",
          },
          {
            subdistrict_id: "528",
            subdistrict_name: "Wanayasa",
          },
        ],
      },
      {
        city_id: "41",
        type: "Kabupaten",
        city_name: "Banyumas",
        subdistricts: [
          {
            subdistrict_id: "573",
            subdistrict_name: "Ajibarang",
          },
          {
            subdistrict_id: "574",
            subdistrict_name: "Banyumas",
          },
          {
            subdistrict_id: "575",
            subdistrict_name: "Baturaden",
          },
          {
            subdistrict_id: "576",
            subdistrict_name: "Cilongok",
          },
          {
            subdistrict_id: "577",
            subdistrict_name: "Gumelar",
          },
          {
            subdistrict_id: "578",
            subdistrict_name: "Jatilawang",
          },
          {
            subdistrict_id: "579",
            subdistrict_name: "Kalibagor",
          },
          {
            subdistrict_id: "580",
            subdistrict_name: "Karanglewas",
          },
          {
            subdistrict_id: "581",
            subdistrict_name: "Kebasen",
          },
          {
            subdistrict_id: "582",
            subdistrict_name: "Kedung Banteng",
          },
          {
            subdistrict_id: "583",
            subdistrict_name: "Kembaran",
          },
          {
            subdistrict_id: "584",
            subdistrict_name: "Kemranjen",
          },
          {
            subdistrict_id: "585",
            subdistrict_name: "Lumbir",
          },
          {
            subdistrict_id: "586",
            subdistrict_name: "Patikraja",
          },
          {
            subdistrict_id: "587",
            subdistrict_name: "Pekuncen",
          },
          {
            subdistrict_id: "588",
            subdistrict_name: "Purwojati",
          },
          {
            subdistrict_id: "589",
            subdistrict_name: "Purwokerto Barat",
          },
          {
            subdistrict_id: "590",
            subdistrict_name: "Purwokerto Selatan",
          },
          {
            subdistrict_id: "591",
            subdistrict_name: "Purwokerto Timur",
          },
          {
            subdistrict_id: "592",
            subdistrict_name: "Purwokerto Utara",
          },
          {
            subdistrict_id: "593",
            subdistrict_name: "Rawalo",
          },
          {
            subdistrict_id: "594",
            subdistrict_name: "Sokaraja",
          },
          {
            subdistrict_id: "595",
            subdistrict_name: "Somagede",
          },
          {
            subdistrict_id: "596",
            subdistrict_name: "Sumbang",
          },
          {
            subdistrict_id: "597",
            subdistrict_name: "Sumpiuh",
          },
          {
            subdistrict_id: "598",
            subdistrict_name: "Tambak",
          },
          {
            subdistrict_id: "599",
            subdistrict_name: "Wangon",
          },
        ],
      },
      {
        city_id: "49",
        type: "Kabupaten",
        city_name: "Batang",
        subdistricts: [
          {
            subdistrict_id: "685",
            subdistrict_name: "Bandar",
          },
          {
            subdistrict_id: "686",
            subdistrict_name: "Banyuputih",
          },
          {
            subdistrict_id: "687",
            subdistrict_name: "Batang",
          },
          {
            subdistrict_id: "688",
            subdistrict_name: "Bawang",
          },
          {
            subdistrict_id: "689",
            subdistrict_name: "Blado",
          },
          {
            subdistrict_id: "690",
            subdistrict_name: "Gringsing",
          },
          {
            subdistrict_id: "691",
            subdistrict_name: "Kandeman",
          },
          {
            subdistrict_id: "692",
            subdistrict_name: "Limpung",
          },
          {
            subdistrict_id: "693",
            subdistrict_name: "Pecalungan",
          },
          {
            subdistrict_id: "694",
            subdistrict_name: "Reban",
          },
          {
            subdistrict_id: "695",
            subdistrict_name: "Subah",
          },
          {
            subdistrict_id: "696",
            subdistrict_name: "Tersono",
          },
          {
            subdistrict_id: "697",
            subdistrict_name: "Tulis",
          },
          {
            subdistrict_id: "698",
            subdistrict_name: "Warungasem",
          },
          {
            subdistrict_id: "699",
            subdistrict_name: "Wonotunggal",
          },
        ],
      },
      {
        city_id: "76",
        type: "Kabupaten",
        city_name: "Blora",
        subdistricts: [
          {
            subdistrict_id: "999",
            subdistrict_name: "Banjarejo",
          },
          {
            subdistrict_id: "1000",
            subdistrict_name: "Blora kota",
          },
          {
            subdistrict_id: "1001",
            subdistrict_name: "Bogorejo",
          },
          {
            subdistrict_id: "1002",
            subdistrict_name: "Cepu",
          },
          {
            subdistrict_id: "1003",
            subdistrict_name: "Japah",
          },
          {
            subdistrict_id: "1004",
            subdistrict_name: "Jati",
          },
          {
            subdistrict_id: "1005",
            subdistrict_name: "Jepon",
          },
          {
            subdistrict_id: "1006",
            subdistrict_name: "Jiken",
          },
          {
            subdistrict_id: "1007",
            subdistrict_name: "Kedungtuban",
          },
          {
            subdistrict_id: "1008",
            subdistrict_name: "Kradenan",
          },
          {
            subdistrict_id: "1009",
            subdistrict_name: "Kunduran",
          },
          {
            subdistrict_id: "1010",
            subdistrict_name: "Ngawen",
          },
          {
            subdistrict_id: "1011",
            subdistrict_name: "Randublatung",
          },
          {
            subdistrict_id: "1012",
            subdistrict_name: "Sambong",
          },
          {
            subdistrict_id: "1013",
            subdistrict_name: "Todanan",
          },
          {
            subdistrict_id: "1014",
            subdistrict_name: "Tunjungan",
          },
        ],
      },
      {
        city_id: "91",
        type: "Kabupaten",
        city_name: "Boyolali",
        subdistricts: [
          {
            subdistrict_id: "1240",
            subdistrict_name: "Ampel",
          },
          {
            subdistrict_id: "1241",
            subdistrict_name: "Andong",
          },
          {
            subdistrict_id: "1242",
            subdistrict_name: "Banyudono",
          },
          {
            subdistrict_id: "1243",
            subdistrict_name: "Boyolali",
          },
          {
            subdistrict_id: "1244",
            subdistrict_name: "Cepogo",
          },
          {
            subdistrict_id: "1245",
            subdistrict_name: "Juwangi",
          },
          {
            subdistrict_id: "1246",
            subdistrict_name: "Karanggede",
          },
          {
            subdistrict_id: "1247",
            subdistrict_name: "Kemusu",
          },
          {
            subdistrict_id: "1248",
            subdistrict_name: "Klego",
          },
          {
            subdistrict_id: "1249",
            subdistrict_name: "Mojosongo",
          },
          {
            subdistrict_id: "1250",
            subdistrict_name: "Musuk",
          },
          {
            subdistrict_id: "1251",
            subdistrict_name: "Ngemplak",
          },
          {
            subdistrict_id: "1252",
            subdistrict_name: "Nogosari",
          },
          {
            subdistrict_id: "1253",
            subdistrict_name: "Sambi",
          },
          {
            subdistrict_id: "1254",
            subdistrict_name: "Sawit",
          },
          {
            subdistrict_id: "1255",
            subdistrict_name: "Selo",
          },
          {
            subdistrict_id: "1256",
            subdistrict_name: "Simo",
          },
          {
            subdistrict_id: "1257",
            subdistrict_name: "Teras",
          },
          {
            subdistrict_id: "1258",
            subdistrict_name: "Wonosegoro",
          },
        ],
      },
      {
        city_id: "92",
        type: "Kabupaten",
        city_name: "Brebes",
        subdistricts: [
          {
            subdistrict_id: "1259",
            subdistrict_name: "Banjarharjo",
          },
          {
            subdistrict_id: "1260",
            subdistrict_name: "Bantarkawung",
          },
          {
            subdistrict_id: "1261",
            subdistrict_name: "Brebes",
          },
          {
            subdistrict_id: "1262",
            subdistrict_name: "Bulakamba",
          },
          {
            subdistrict_id: "1263",
            subdistrict_name: "Bumiayu",
          },
          {
            subdistrict_id: "1264",
            subdistrict_name: "Jatibarang",
          },
          {
            subdistrict_id: "1265",
            subdistrict_name: "Kersana",
          },
          {
            subdistrict_id: "1266",
            subdistrict_name: "Ketanggungan",
          },
          {
            subdistrict_id: "1267",
            subdistrict_name: "Larangan",
          },
          {
            subdistrict_id: "1268",
            subdistrict_name: "Losari",
          },
          {
            subdistrict_id: "1269",
            subdistrict_name: "Paguyangan",
          },
          {
            subdistrict_id: "1270",
            subdistrict_name: "Salem",
          },
          {
            subdistrict_id: "1271",
            subdistrict_name: "Sirampog",
          },
          {
            subdistrict_id: "1272",
            subdistrict_name: "Songgom",
          },
          {
            subdistrict_id: "1273",
            subdistrict_name: "Tanjung",
          },
          {
            subdistrict_id: "1274",
            subdistrict_name: "Tonjong",
          },
          {
            subdistrict_id: "1275",
            subdistrict_name: "Wanasari",
          },
        ],
      },
      {
        city_id: "105",
        type: "Kabupaten",
        city_name: "Cilacap",
        subdistricts: [
          {
            subdistrict_id: "1437",
            subdistrict_name: "Adipala",
          },
          {
            subdistrict_id: "1438",
            subdistrict_name: "Bantarsari",
          },
          {
            subdistrict_id: "1439",
            subdistrict_name: "Binangun",
          },
          {
            subdistrict_id: "1440",
            subdistrict_name: "Cilacap Selatan",
          },
          {
            subdistrict_id: "1441",
            subdistrict_name: "Cilacap Tengah",
          },
          {
            subdistrict_id: "1442",
            subdistrict_name: "Cilacap Utara",
          },
          {
            subdistrict_id: "1443",
            subdistrict_name: "Cimanggu",
          },
          {
            subdistrict_id: "1444",
            subdistrict_name: "Cipari",
          },
          {
            subdistrict_id: "1445",
            subdistrict_name: "Dayeuhluhur",
          },
          {
            subdistrict_id: "1446",
            subdistrict_name: "Gandrungmangu",
          },
          {
            subdistrict_id: "1447",
            subdistrict_name: "Jeruklegi",
          },
          {
            subdistrict_id: "1448",
            subdistrict_name: "Kampung Laut",
          },
          {
            subdistrict_id: "1449",
            subdistrict_name: "Karangpucung",
          },
          {
            subdistrict_id: "1450",
            subdistrict_name: "Kawunganten",
          },
          {
            subdistrict_id: "1451",
            subdistrict_name: "Kedungreja",
          },
          {
            subdistrict_id: "1452",
            subdistrict_name: "Kesugihan",
          },
          {
            subdistrict_id: "1453",
            subdistrict_name: "Kroya",
          },
          {
            subdistrict_id: "1454",
            subdistrict_name: "Majenang",
          },
          {
            subdistrict_id: "1455",
            subdistrict_name: "Maos",
          },
          {
            subdistrict_id: "1456",
            subdistrict_name: "Nusawungu",
          },
          {
            subdistrict_id: "1457",
            subdistrict_name: "Patimuan",
          },
          {
            subdistrict_id: "1458",
            subdistrict_name: "Sampang",
          },
          {
            subdistrict_id: "1459",
            subdistrict_name: "Sidareja",
          },
          {
            subdistrict_id: "1460",
            subdistrict_name: "Wanareja",
          },
        ],
      },
      {
        city_id: "113",
        type: "Kabupaten",
        city_name: "Demak",
        subdistricts: [
          {
            subdistrict_id: "1559",
            subdistrict_name: "Bonang",
          },
          {
            subdistrict_id: "1560",
            subdistrict_name: "Demak",
          },
          {
            subdistrict_id: "1561",
            subdistrict_name: "Dempet",
          },
          {
            subdistrict_id: "1562",
            subdistrict_name: "Gajah",
          },
          {
            subdistrict_id: "1563",
            subdistrict_name: "Guntur",
          },
          {
            subdistrict_id: "1564",
            subdistrict_name: "Karang Tengah",
          },
          {
            subdistrict_id: "1565",
            subdistrict_name: "Karanganyar",
          },
          {
            subdistrict_id: "1566",
            subdistrict_name: "Karangawen",
          },
          {
            subdistrict_id: "1567",
            subdistrict_name: "Kebonagung",
          },
          {
            subdistrict_id: "1568",
            subdistrict_name: "Mijen",
          },
          {
            subdistrict_id: "1569",
            subdistrict_name: "Mranggen",
          },
          {
            subdistrict_id: "1570",
            subdistrict_name: "Sayung",
          },
          {
            subdistrict_id: "1571",
            subdistrict_name: "Wedung",
          },
          {
            subdistrict_id: "1572",
            subdistrict_name: "Wonosalam",
          },
        ],
      },
      {
        city_id: "134",
        type: "Kabupaten",
        city_name: "Grobogan",
        subdistricts: [
          {
            subdistrict_id: "1846",
            subdistrict_name: "Brati",
          },
          {
            subdistrict_id: "1847",
            subdistrict_name: "Gabus",
          },
          {
            subdistrict_id: "1848",
            subdistrict_name: "Geyer",
          },
          {
            subdistrict_id: "1849",
            subdistrict_name: "Godong",
          },
          {
            subdistrict_id: "1850",
            subdistrict_name: "Grobogan",
          },
          {
            subdistrict_id: "1851",
            subdistrict_name: "Gubug",
          },
          {
            subdistrict_id: "1852",
            subdistrict_name: "Karangrayung",
          },
          {
            subdistrict_id: "1853",
            subdistrict_name: "Kedungjati",
          },
          {
            subdistrict_id: "1854",
            subdistrict_name: "Klambu",
          },
          {
            subdistrict_id: "1855",
            subdistrict_name: "Kradenan",
          },
          {
            subdistrict_id: "1856",
            subdistrict_name: "Ngaringan",
          },
          {
            subdistrict_id: "1857",
            subdistrict_name: "Penawangan",
          },
          {
            subdistrict_id: "1858",
            subdistrict_name: "Pulokulon",
          },
          {
            subdistrict_id: "1859",
            subdistrict_name: "Purwodadi",
          },
          {
            subdistrict_id: "1860",
            subdistrict_name: "Tanggungharjo",
          },
          {
            subdistrict_id: "1861",
            subdistrict_name: "Tawangharjo",
          },
          {
            subdistrict_id: "1862",
            subdistrict_name: "Tegowanu",
          },
          {
            subdistrict_id: "1863",
            subdistrict_name: "Toroh",
          },
          {
            subdistrict_id: "1864",
            subdistrict_name: "Wirosari",
          },
        ],
      },
      {
        city_id: "163",
        type: "Kabupaten",
        city_name: "Jepara",
        subdistricts: [
          {
            subdistrict_id: "2248",
            subdistrict_name: "Bangsri",
          },
          {
            subdistrict_id: "2249",
            subdistrict_name: "Batealit",
          },
          {
            subdistrict_id: "2250",
            subdistrict_name: "Donorojo",
          },
          {
            subdistrict_id: "2251",
            subdistrict_name: "Jepara",
          },
          {
            subdistrict_id: "2252",
            subdistrict_name: "Kalinyamatan",
          },
          {
            subdistrict_id: "2253",
            subdistrict_name: "Karimunjawa",
          },
          {
            subdistrict_id: "2254",
            subdistrict_name: "Kedung",
          },
          {
            subdistrict_id: "2255",
            subdistrict_name: "Keling",
          },
          {
            subdistrict_id: "2256",
            subdistrict_name: "Kembang",
          },
          {
            subdistrict_id: "2257",
            subdistrict_name: "Mayong",
          },
          {
            subdistrict_id: "2258",
            subdistrict_name: "Mlonggo",
          },
          {
            subdistrict_id: "2259",
            subdistrict_name: "Nalumsari",
          },
          {
            subdistrict_id: "2260",
            subdistrict_name: "Pakis Aji",
          },
          {
            subdistrict_id: "2261",
            subdistrict_name: "Pecangaan",
          },
          {
            subdistrict_id: "2262",
            subdistrict_name: "Tahunan",
          },
          {
            subdistrict_id: "2263",
            subdistrict_name: "Welahan",
          },
        ],
      },
      {
        city_id: "169",
        type: "Kabupaten",
        city_name: "Karanganyar",
        subdistricts: [
          {
            subdistrict_id: "2353",
            subdistrict_name: "Colomadu",
          },
          {
            subdistrict_id: "2354",
            subdistrict_name: "Gondangrejo",
          },
          {
            subdistrict_id: "2355",
            subdistrict_name: "Jaten",
          },
          {
            subdistrict_id: "2356",
            subdistrict_name: "Jatipuro",
          },
          {
            subdistrict_id: "2357",
            subdistrict_name: "Jatiyoso",
          },
          {
            subdistrict_id: "2358",
            subdistrict_name: "Jenawi",
          },
          {
            subdistrict_id: "2359",
            subdistrict_name: "Jumantono",
          },
          {
            subdistrict_id: "2360",
            subdistrict_name: "Jumapolo",
          },
          {
            subdistrict_id: "2361",
            subdistrict_name: "Karanganyar",
          },
          {
            subdistrict_id: "2362",
            subdistrict_name: "Karangpandan",
          },
          {
            subdistrict_id: "2363",
            subdistrict_name: "Kebakkramat",
          },
          {
            subdistrict_id: "2364",
            subdistrict_name: "Kerjo",
          },
          {
            subdistrict_id: "2365",
            subdistrict_name: "Matesih",
          },
          {
            subdistrict_id: "2366",
            subdistrict_name: "Mojogedang",
          },
          {
            subdistrict_id: "2367",
            subdistrict_name: "Ngargoyoso",
          },
          {
            subdistrict_id: "2368",
            subdistrict_name: "Tasikmadu",
          },
          {
            subdistrict_id: "2369",
            subdistrict_name: "Tawangmangu",
          },
        ],
      },
      {
        city_id: "177",
        type: "Kabupaten",
        city_name: "Kebumen",
        subdistricts: [
          {
            subdistrict_id: "2471",
            subdistrict_name: "Adimulyo",
          },
          {
            subdistrict_id: "2472",
            subdistrict_name: "Alian/Aliyan",
          },
          {
            subdistrict_id: "2473",
            subdistrict_name: "Ambal",
          },
          {
            subdistrict_id: "2474",
            subdistrict_name: "Ayah",
          },
          {
            subdistrict_id: "2475",
            subdistrict_name: "Bonorowo",
          },
          {
            subdistrict_id: "2476",
            subdistrict_name: "Buayan",
          },
          {
            subdistrict_id: "2477",
            subdistrict_name: "Buluspesantren",
          },
          {
            subdistrict_id: "2478",
            subdistrict_name: "Gombong",
          },
          {
            subdistrict_id: "2479",
            subdistrict_name: "Karanganyar",
          },
          {
            subdistrict_id: "2480",
            subdistrict_name: "Karanggayam",
          },
          {
            subdistrict_id: "2481",
            subdistrict_name: "Karangsambung",
          },
          {
            subdistrict_id: "2482",
            subdistrict_name: "Kebumen",
          },
          {
            subdistrict_id: "2483",
            subdistrict_name: "Klirong",
          },
          {
            subdistrict_id: "2484",
            subdistrict_name: "Kutowinangun",
          },
          {
            subdistrict_id: "2485",
            subdistrict_name: "Kuwarasan",
          },
          {
            subdistrict_id: "2486",
            subdistrict_name: "Mirit",
          },
          {
            subdistrict_id: "2487",
            subdistrict_name: "Padureso",
          },
          {
            subdistrict_id: "2488",
            subdistrict_name: "Pejagoan",
          },
          {
            subdistrict_id: "2489",
            subdistrict_name: "Petanahan",
          },
          {
            subdistrict_id: "2490",
            subdistrict_name: "Poncowarno",
          },
          {
            subdistrict_id: "2491",
            subdistrict_name: "Prembun",
          },
          {
            subdistrict_id: "2492",
            subdistrict_name: "Puring",
          },
          {
            subdistrict_id: "2493",
            subdistrict_name: "Rowokele",
          },
          {
            subdistrict_id: "2494",
            subdistrict_name: "Sadang",
          },
          {
            subdistrict_id: "2495",
            subdistrict_name: "Sempor",
          },
          {
            subdistrict_id: "2496",
            subdistrict_name: "Sruweng",
          },
        ],
      },
      {
        city_id: "181",
        type: "Kabupaten",
        city_name: "Kendal",
        subdistricts: [
          {
            subdistrict_id: "2533",
            subdistrict_name: "Boja",
          },
          {
            subdistrict_id: "2534",
            subdistrict_name: "Brangsong",
          },
          {
            subdistrict_id: "2535",
            subdistrict_name: "Cepiring",
          },
          {
            subdistrict_id: "2536",
            subdistrict_name: "Gemuh",
          },
          {
            subdistrict_id: "2537",
            subdistrict_name: "Kaliwungu",
          },
          {
            subdistrict_id: "2538",
            subdistrict_name: "Kaliwungu Selatan",
          },
          {
            subdistrict_id: "2539",
            subdistrict_name: "Kangkung",
          },
          {
            subdistrict_id: "2540",
            subdistrict_name: "Kendal",
          },
          {
            subdistrict_id: "2541",
            subdistrict_name: "Limbangan",
          },
          {
            subdistrict_id: "2542",
            subdistrict_name: "Ngampel",
          },
          {
            subdistrict_id: "2543",
            subdistrict_name: "Pagerruyung",
          },
          {
            subdistrict_id: "2544",
            subdistrict_name: "Patean",
          },
          {
            subdistrict_id: "2545",
            subdistrict_name: "Patebon",
          },
          {
            subdistrict_id: "2546",
            subdistrict_name: "Pegandon",
          },
          {
            subdistrict_id: "2547",
            subdistrict_name: "Plantungan",
          },
          {
            subdistrict_id: "2548",
            subdistrict_name: "Ringinarum",
          },
          {
            subdistrict_id: "2549",
            subdistrict_name: "Rowosari",
          },
          {
            subdistrict_id: "2550",
            subdistrict_name: "Singorojo",
          },
          {
            subdistrict_id: "2551",
            subdistrict_name: "Sukorejo",
          },
          {
            subdistrict_id: "2552",
            subdistrict_name: "Weleri",
          },
        ],
      },
      {
        city_id: "196",
        type: "Kabupaten",
        city_name: "Klaten",
        subdistricts: [
          {
            subdistrict_id: "2722",
            subdistrict_name: "Bayat",
          },
          {
            subdistrict_id: "2723",
            subdistrict_name: "Cawas",
          },
          {
            subdistrict_id: "2724",
            subdistrict_name: "Ceper",
          },
          {
            subdistrict_id: "2725",
            subdistrict_name: "Delanggu",
          },
          {
            subdistrict_id: "2726",
            subdistrict_name: "Gantiwarno",
          },
          {
            subdistrict_id: "2727",
            subdistrict_name: "Jatinom",
          },
          {
            subdistrict_id: "2728",
            subdistrict_name: "Jogonalan",
          },
          {
            subdistrict_id: "2729",
            subdistrict_name: "Juwiring",
          },
          {
            subdistrict_id: "2730",
            subdistrict_name: "Kalikotes",
          },
          {
            subdistrict_id: "2731",
            subdistrict_name: "Karanganom",
          },
          {
            subdistrict_id: "2732",
            subdistrict_name: "Karangdowo",
          },
          {
            subdistrict_id: "2733",
            subdistrict_name: "Karangnongko",
          },
          {
            subdistrict_id: "2734",
            subdistrict_name: "Kebonarum",
          },
          {
            subdistrict_id: "2735",
            subdistrict_name: "Kemalang",
          },
          {
            subdistrict_id: "2736",
            subdistrict_name: "Klaten Selatan",
          },
          {
            subdistrict_id: "2737",
            subdistrict_name: "Klaten Tengah",
          },
          {
            subdistrict_id: "2738",
            subdistrict_name: "Klaten Utara",
          },
          {
            subdistrict_id: "2739",
            subdistrict_name: "Manisrenggo",
          },
          {
            subdistrict_id: "2740",
            subdistrict_name: "Ngawen",
          },
          {
            subdistrict_id: "2741",
            subdistrict_name: "Pedan",
          },
          {
            subdistrict_id: "2742",
            subdistrict_name: "Polanharjo",
          },
          {
            subdistrict_id: "2743",
            subdistrict_name: "Prambanan",
          },
          {
            subdistrict_id: "2744",
            subdistrict_name: "Trucuk",
          },
          {
            subdistrict_id: "2745",
            subdistrict_name: "Tulung",
          },
          {
            subdistrict_id: "2746",
            subdistrict_name: "Wedi",
          },
          {
            subdistrict_id: "2747",
            subdistrict_name: "Wonosari",
          },
        ],
      },
      {
        city_id: "209",
        type: "Kabupaten",
        city_name: "Kudus",
        subdistricts: [
          {
            subdistrict_id: "2921",
            subdistrict_name: "Bae",
          },
          {
            subdistrict_id: "2922",
            subdistrict_name: "Dawe",
          },
          {
            subdistrict_id: "2923",
            subdistrict_name: "Gebog",
          },
          {
            subdistrict_id: "2924",
            subdistrict_name: "Jati",
          },
          {
            subdistrict_id: "2925",
            subdistrict_name: "Jekulo",
          },
          {
            subdistrict_id: "2926",
            subdistrict_name: "Kaliwungu",
          },
          {
            subdistrict_id: "2927",
            subdistrict_name: "Kudus Kota",
          },
          {
            subdistrict_id: "2928",
            subdistrict_name: "Mejobo",
          },
          {
            subdistrict_id: "2929",
            subdistrict_name: "Undaan",
          },
        ],
      },
      {
        city_id: "249",
        type: "Kabupaten",
        city_name: "Magelang",
        subdistricts: [
          {
            subdistrict_id: "3511",
            subdistrict_name: "Bandongan",
          },
          {
            subdistrict_id: "3512",
            subdistrict_name: "Borobudur",
          },
          {
            subdistrict_id: "3513",
            subdistrict_name: "Candimulyo",
          },
          {
            subdistrict_id: "3514",
            subdistrict_name: "Dukun",
          },
          {
            subdistrict_id: "3515",
            subdistrict_name: "Grabag",
          },
          {
            subdistrict_id: "3516",
            subdistrict_name: "Kajoran",
          },
          {
            subdistrict_id: "3517",
            subdistrict_name: "Kaliangkrik",
          },
          {
            subdistrict_id: "3518",
            subdistrict_name: "Mertoyudan",
          },
          {
            subdistrict_id: "3519",
            subdistrict_name: "Mungkid",
          },
          {
            subdistrict_id: "3520",
            subdistrict_name: "Muntilan",
          },
          {
            subdistrict_id: "3521",
            subdistrict_name: "Ngablak",
          },
          {
            subdistrict_id: "3522",
            subdistrict_name: "Ngluwar",
          },
          {
            subdistrict_id: "3523",
            subdistrict_name: "Pakis",
          },
          {
            subdistrict_id: "3524",
            subdistrict_name: "Salam",
          },
          {
            subdistrict_id: "3525",
            subdistrict_name: "Salaman",
          },
          {
            subdistrict_id: "3526",
            subdistrict_name: "Sawangan",
          },
          {
            subdistrict_id: "3527",
            subdistrict_name: "Secang",
          },
          {
            subdistrict_id: "3528",
            subdistrict_name: "Srumbung",
          },
          {
            subdistrict_id: "3529",
            subdistrict_name: "Tegalrejo",
          },
          {
            subdistrict_id: "3530",
            subdistrict_name: "Tempuran",
          },
          {
            subdistrict_id: "3531",
            subdistrict_name: "Windusari",
          },
        ],
      },
      {
        city_id: "250",
        type: "Kota",
        city_name: "Magelang",
        subdistricts: [
          {
            subdistrict_id: "3532",
            subdistrict_name: "Magelang Selatan",
          },
          {
            subdistrict_id: "3533",
            subdistrict_name: "Magelang Tengah",
          },
          {
            subdistrict_id: "3534",
            subdistrict_name: "Magelang Utara",
          },
        ],
      },
      {
        city_id: "344",
        type: "Kabupaten",
        city_name: "Pati",
        subdistricts: [
          {
            subdistrict_id: "4821",
            subdistrict_name: "Batangan",
          },
          {
            subdistrict_id: "4822",
            subdistrict_name: "Cluwak",
          },
          {
            subdistrict_id: "4823",
            subdistrict_name: "Dukuhseti",
          },
          {
            subdistrict_id: "4824",
            subdistrict_name: "Gabus",
          },
          {
            subdistrict_id: "4825",
            subdistrict_name: "Gembong",
          },
          {
            subdistrict_id: "4826",
            subdistrict_name: "Gunungwungkal",
          },
          {
            subdistrict_id: "4827",
            subdistrict_name: "Jaken",
          },
          {
            subdistrict_id: "4828",
            subdistrict_name: "Jakenan",
          },
          {
            subdistrict_id: "4829",
            subdistrict_name: "Juwana",
          },
          {
            subdistrict_id: "4830",
            subdistrict_name: "Kayen",
          },
          {
            subdistrict_id: "4831",
            subdistrict_name: "Margorejo",
          },
          {
            subdistrict_id: "4832",
            subdistrict_name: "Margoyoso",
          },
          {
            subdistrict_id: "4833",
            subdistrict_name: "Pati",
          },
          {
            subdistrict_id: "4834",
            subdistrict_name: "Pucakwangi",
          },
          {
            subdistrict_id: "4835",
            subdistrict_name: "Sukolilo",
          },
          {
            subdistrict_id: "4836",
            subdistrict_name: "Tambakromo",
          },
          {
            subdistrict_id: "4837",
            subdistrict_name: "Tayu",
          },
          {
            subdistrict_id: "4838",
            subdistrict_name: "Tlogowungu",
          },
          {
            subdistrict_id: "4839",
            subdistrict_name: "Trangkil",
          },
          {
            subdistrict_id: "4840",
            subdistrict_name: "Wedarijaksa",
          },
          {
            subdistrict_id: "4841",
            subdistrict_name: "Winong",
          },
        ],
      },
      {
        city_id: "348",
        type: "Kabupaten",
        city_name: "Pekalongan",
        subdistricts: [
          {
            subdistrict_id: "4891",
            subdistrict_name: "Bojong",
          },
          {
            subdistrict_id: "4892",
            subdistrict_name: "Buaran",
          },
          {
            subdistrict_id: "4893",
            subdistrict_name: "Doro",
          },
          {
            subdistrict_id: "4894",
            subdistrict_name: "Kajen",
          },
          {
            subdistrict_id: "4895",
            subdistrict_name: "Kandangserang",
          },
          {
            subdistrict_id: "4896",
            subdistrict_name: "Karanganyar",
          },
          {
            subdistrict_id: "4897",
            subdistrict_name: "Karangdadap",
          },
          {
            subdistrict_id: "4898",
            subdistrict_name: "Kedungwuni",
          },
          {
            subdistrict_id: "4899",
            subdistrict_name: "Kesesi",
          },
          {
            subdistrict_id: "4900",
            subdistrict_name: "Lebakbarang",
          },
          {
            subdistrict_id: "4901",
            subdistrict_name: "Paninggaran",
          },
          {
            subdistrict_id: "4902",
            subdistrict_name: "Petungkriono/Petungkriyono",
          },
          {
            subdistrict_id: "4903",
            subdistrict_name: "Siwalan",
          },
          {
            subdistrict_id: "4904",
            subdistrict_name: "Sragi",
          },
          {
            subdistrict_id: "4905",
            subdistrict_name: "Talun",
          },
          {
            subdistrict_id: "4906",
            subdistrict_name: "Tirto",
          },
          {
            subdistrict_id: "4907",
            subdistrict_name: "Wiradesa",
          },
          {
            subdistrict_id: "4908",
            subdistrict_name: "Wonokerto",
          },
          {
            subdistrict_id: "4909",
            subdistrict_name: "Wonopringgo",
          },
        ],
      },
      {
        city_id: "349",
        type: "Kota",
        city_name: "Pekalongan",
        subdistricts: [
          {
            subdistrict_id: "4910",
            subdistrict_name: "Pekalongan Barat",
          },
          {
            subdistrict_id: "4911",
            subdistrict_name: "Pekalongan Selatan",
          },
          {
            subdistrict_id: "4912",
            subdistrict_name: "Pekalongan Timur",
          },
          {
            subdistrict_id: "4913",
            subdistrict_name: "Pekalongan Utara",
          },
        ],
      },
      {
        city_id: "352",
        type: "Kabupaten",
        city_name: "Pemalang",
        subdistricts: [
          {
            subdistrict_id: "4938",
            subdistrict_name: "Ampelgading",
          },
          {
            subdistrict_id: "4939",
            subdistrict_name: "Bantarbolang",
          },
          {
            subdistrict_id: "4940",
            subdistrict_name: "Belik",
          },
          {
            subdistrict_id: "4941",
            subdistrict_name: "Bodeh",
          },
          {
            subdistrict_id: "4942",
            subdistrict_name: "Comal",
          },
          {
            subdistrict_id: "4943",
            subdistrict_name: "Moga",
          },
          {
            subdistrict_id: "4944",
            subdistrict_name: "Pemalang",
          },
          {
            subdistrict_id: "4945",
            subdistrict_name: "Petarukan",
          },
          {
            subdistrict_id: "4946",
            subdistrict_name: "Pulosari",
          },
          {
            subdistrict_id: "4947",
            subdistrict_name: "Randudongkal",
          },
          {
            subdistrict_id: "4948",
            subdistrict_name: "Taman",
          },
          {
            subdistrict_id: "4949",
            subdistrict_name: "Ulujami",
          },
          {
            subdistrict_id: "4950",
            subdistrict_name: "Warungpring",
          },
          {
            subdistrict_id: "4951",
            subdistrict_name: "Watukumpul",
          },
        ],
      },
      {
        city_id: "375",
        type: "Kabupaten",
        city_name: "Purbalingga",
        subdistricts: [
          {
            subdistrict_id: "5199",
            subdistrict_name: "Bobotsari",
          },
          {
            subdistrict_id: "5200",
            subdistrict_name: "Bojongsari",
          },
          {
            subdistrict_id: "5201",
            subdistrict_name: "Bukateja",
          },
          {
            subdistrict_id: "5202",
            subdistrict_name: "Kaligondang",
          },
          {
            subdistrict_id: "5203",
            subdistrict_name: "Kalimanah",
          },
          {
            subdistrict_id: "5204",
            subdistrict_name: "Karanganyar",
          },
          {
            subdistrict_id: "5205",
            subdistrict_name: "Karangjambu",
          },
          {
            subdistrict_id: "5206",
            subdistrict_name: "Karangmoncol",
          },
          {
            subdistrict_id: "5207",
            subdistrict_name: "Karangreja",
          },
          {
            subdistrict_id: "5208",
            subdistrict_name: "Kejobong",
          },
          {
            subdistrict_id: "5209",
            subdistrict_name: "Kemangkon",
          },
          {
            subdistrict_id: "5210",
            subdistrict_name: "Kertanegara",
          },
          {
            subdistrict_id: "5211",
            subdistrict_name: "Kutasari",
          },
          {
            subdistrict_id: "5212",
            subdistrict_name: "Mrebet",
          },
          {
            subdistrict_id: "5213",
            subdistrict_name: "Padamara",
          },
          {
            subdistrict_id: "5214",
            subdistrict_name: "Pengadegan",
          },
          {
            subdistrict_id: "5215",
            subdistrict_name: "Purbalingga",
          },
          {
            subdistrict_id: "5216",
            subdistrict_name: "Rembang",
          },
        ],
      },
      {
        city_id: "377",
        type: "Kabupaten",
        city_name: "Purworejo",
        subdistricts: [
          {
            subdistrict_id: "5234",
            subdistrict_name: "Bagelen",
          },
          {
            subdistrict_id: "5235",
            subdistrict_name: "Banyuurip",
          },
          {
            subdistrict_id: "5236",
            subdistrict_name: "Bayan",
          },
          {
            subdistrict_id: "5237",
            subdistrict_name: "Bener",
          },
          {
            subdistrict_id: "5238",
            subdistrict_name: "Bruno",
          },
          {
            subdistrict_id: "5239",
            subdistrict_name: "Butuh",
          },
          {
            subdistrict_id: "5240",
            subdistrict_name: "Gebang",
          },
          {
            subdistrict_id: "5241",
            subdistrict_name: "Grabag",
          },
          {
            subdistrict_id: "5242",
            subdistrict_name: "Kaligesing",
          },
          {
            subdistrict_id: "5243",
            subdistrict_name: "Kemiri",
          },
          {
            subdistrict_id: "5244",
            subdistrict_name: "Kutoarjo",
          },
          {
            subdistrict_id: "5245",
            subdistrict_name: "Loano",
          },
          {
            subdistrict_id: "5246",
            subdistrict_name: "Ngombol",
          },
          {
            subdistrict_id: "5247",
            subdistrict_name: "Pituruh",
          },
          {
            subdistrict_id: "5248",
            subdistrict_name: "Purwodadi",
          },
          {
            subdistrict_id: "5249",
            subdistrict_name: "Purworejo",
          },
        ],
      },
      {
        city_id: "380",
        type: "Kabupaten",
        city_name: "Rembang",
        subdistricts: [
          {
            subdistrict_id: "5289",
            subdistrict_name: "Bulu",
          },
          {
            subdistrict_id: "5290",
            subdistrict_name: "Gunem",
          },
          {
            subdistrict_id: "5291",
            subdistrict_name: "Kaliori",
          },
          {
            subdistrict_id: "5292",
            subdistrict_name: "Kragan",
          },
          {
            subdistrict_id: "5293",
            subdistrict_name: "Lasem",
          },
          {
            subdistrict_id: "5294",
            subdistrict_name: "Pamotan",
          },
          {
            subdistrict_id: "5295",
            subdistrict_name: "Pancur",
          },
          {
            subdistrict_id: "5296",
            subdistrict_name: "Rembang",
          },
          {
            subdistrict_id: "5297",
            subdistrict_name: "Sale",
          },
          {
            subdistrict_id: "5298",
            subdistrict_name: "Sarang",
          },
          {
            subdistrict_id: "5299",
            subdistrict_name: "Sedan",
          },
          {
            subdistrict_id: "5300",
            subdistrict_name: "Sluke",
          },
          {
            subdistrict_id: "5301",
            subdistrict_name: "Sulang",
          },
          {
            subdistrict_id: "5302",
            subdistrict_name: "Sumber",
          },
        ],
      },
      {
        city_id: "386",
        type: "Kota",
        city_name: "Salatiga",
        subdistricts: [
          {
            subdistrict_id: "5352",
            subdistrict_name: "Argomulyo",
          },
          {
            subdistrict_id: "5353",
            subdistrict_name: "Sidomukti",
          },
          {
            subdistrict_id: "5354",
            subdistrict_name: "Sidorejo",
          },
          {
            subdistrict_id: "5355",
            subdistrict_name: "Tingkir",
          },
        ],
      },
      {
        city_id: "398",
        type: "Kabupaten",
        city_name: "Semarang",
        subdistricts: [
          {
            subdistrict_id: "5479",
            subdistrict_name: "Ambarawa",
          },
          {
            subdistrict_id: "5480",
            subdistrict_name: "Bancak",
          },
          {
            subdistrict_id: "5481",
            subdistrict_name: "Bandungan",
          },
          {
            subdistrict_id: "5482",
            subdistrict_name: "Banyubiru",
          },
          {
            subdistrict_id: "5483",
            subdistrict_name: "Bawen",
          },
          {
            subdistrict_id: "5484",
            subdistrict_name: "Bergas",
          },
          {
            subdistrict_id: "5485",
            subdistrict_name: "Bringin",
          },
          {
            subdistrict_id: "5486",
            subdistrict_name: "Getasan",
          },
          {
            subdistrict_id: "5487",
            subdistrict_name: "Jambu",
          },
          {
            subdistrict_id: "5488",
            subdistrict_name: "Kaliwungu",
          },
          {
            subdistrict_id: "5489",
            subdistrict_name: "Pabelan",
          },
          {
            subdistrict_id: "5490",
            subdistrict_name: "Pringapus",
          },
          {
            subdistrict_id: "5491",
            subdistrict_name: "Sumowono",
          },
          {
            subdistrict_id: "5492",
            subdistrict_name: "Suruh",
          },
          {
            subdistrict_id: "5493",
            subdistrict_name: "Susukan",
          },
          {
            subdistrict_id: "5494",
            subdistrict_name: "Tengaran",
          },
          {
            subdistrict_id: "5495",
            subdistrict_name: "Tuntang",
          },
          {
            subdistrict_id: "5496",
            subdistrict_name: "Ungaran Barat",
          },
          {
            subdistrict_id: "5497",
            subdistrict_name: "Ungaran Timur",
          },
        ],
      },
      {
        city_id: "399",
        type: "Kota",
        city_name: "Semarang",
        subdistricts: [
          {
            subdistrict_id: "5498",
            subdistrict_name: "Banyumanik",
          },
          {
            subdistrict_id: "5499",
            subdistrict_name: "Candisari",
          },
          {
            subdistrict_id: "5500",
            subdistrict_name: "Gajah Mungkur",
          },
          {
            subdistrict_id: "5501",
            subdistrict_name: "Gayamsari",
          },
          {
            subdistrict_id: "5502",
            subdistrict_name: "Genuk",
          },
          {
            subdistrict_id: "5503",
            subdistrict_name: "Gunungpati",
          },
          {
            subdistrict_id: "5504",
            subdistrict_name: "Mijen",
          },
          {
            subdistrict_id: "5505",
            subdistrict_name: "Ngaliyan",
          },
          {
            subdistrict_id: "5506",
            subdistrict_name: "Pedurungan",
          },
          {
            subdistrict_id: "5507",
            subdistrict_name: "Semarang Barat",
          },
          {
            subdistrict_id: "5508",
            subdistrict_name: "Semarang Selatan",
          },
          {
            subdistrict_id: "5509",
            subdistrict_name: "Semarang Tengah",
          },
          {
            subdistrict_id: "5510",
            subdistrict_name: "Semarang Timur",
          },
          {
            subdistrict_id: "5511",
            subdistrict_name: "Semarang Utara",
          },
          {
            subdistrict_id: "5512",
            subdistrict_name: "Tembalang",
          },
          {
            subdistrict_id: "5513",
            subdistrict_name: "Tugu",
          },
        ],
      },
      {
        city_id: "427",
        type: "Kabupaten",
        city_name: "Sragen",
        subdistricts: [
          {
            subdistrict_id: "5863",
            subdistrict_name: "Gemolong",
          },
          {
            subdistrict_id: "5864",
            subdistrict_name: "Gesi",
          },
          {
            subdistrict_id: "5865",
            subdistrict_name: "Gondang",
          },
          {
            subdistrict_id: "5866",
            subdistrict_name: "Jenar",
          },
          {
            subdistrict_id: "5867",
            subdistrict_name: "Kalijambe",
          },
          {
            subdistrict_id: "5868",
            subdistrict_name: "Karangmalang",
          },
          {
            subdistrict_id: "5869",
            subdistrict_name: "Kedawung",
          },
          {
            subdistrict_id: "5870",
            subdistrict_name: "Masaran",
          },
          {
            subdistrict_id: "5871",
            subdistrict_name: "Miri",
          },
          {
            subdistrict_id: "5872",
            subdistrict_name: "Mondokan",
          },
          {
            subdistrict_id: "5873",
            subdistrict_name: "Ngrampal",
          },
          {
            subdistrict_id: "5874",
            subdistrict_name: "Plupuh",
          },
          {
            subdistrict_id: "5875",
            subdistrict_name: "Sambirejo",
          },
          {
            subdistrict_id: "5876",
            subdistrict_name: "Sambung Macan",
          },
          {
            subdistrict_id: "5877",
            subdistrict_name: "Sidoharjo",
          },
          {
            subdistrict_id: "5878",
            subdistrict_name: "Sragen",
          },
          {
            subdistrict_id: "5879",
            subdistrict_name: "Sukodono",
          },
          {
            subdistrict_id: "5880",
            subdistrict_name: "Sumberlawang",
          },
          {
            subdistrict_id: "5881",
            subdistrict_name: "Tangen",
          },
          {
            subdistrict_id: "5882",
            subdistrict_name: "Tanon",
          },
        ],
      },
      {
        city_id: "433",
        type: "Kabupaten",
        city_name: "Sukoharjo",
        subdistricts: [
          {
            subdistrict_id: "5977",
            subdistrict_name: "Baki",
          },
          {
            subdistrict_id: "5978",
            subdistrict_name: "Bendosari",
          },
          {
            subdistrict_id: "5979",
            subdistrict_name: "Bulu",
          },
          {
            subdistrict_id: "5980",
            subdistrict_name: "Gatak",
          },
          {
            subdistrict_id: "5981",
            subdistrict_name: "Grogol",
          },
          {
            subdistrict_id: "5982",
            subdistrict_name: "Kartasura",
          },
          {
            subdistrict_id: "5983",
            subdistrict_name: "Mojolaban",
          },
          {
            subdistrict_id: "5984",
            subdistrict_name: "Nguter",
          },
          {
            subdistrict_id: "5985",
            subdistrict_name: "Polokarto",
          },
          {
            subdistrict_id: "5986",
            subdistrict_name: "Sukoharjo",
          },
          {
            subdistrict_id: "5987",
            subdistrict_name: "Tawangsari",
          },
          {
            subdistrict_id: "5988",
            subdistrict_name: "Weru",
          },
        ],
      },
      {
        city_id: "445",
        type: "Kota",
        city_name: "Surakarta (Solo)",
        subdistricts: [
          {
            subdistrict_id: "6162",
            subdistrict_name: "Banjarsari",
          },
          {
            subdistrict_id: "6163",
            subdistrict_name: "Jebres",
          },
          {
            subdistrict_id: "6164",
            subdistrict_name: "Laweyan",
          },
          {
            subdistrict_id: "6165",
            subdistrict_name: "Pasar Kliwon",
          },
          {
            subdistrict_id: "6166",
            subdistrict_name: "Serengan",
          },
        ],
      },
      {
        city_id: "472",
        type: "Kabupaten",
        city_name: "Tegal",
        subdistricts: [
          {
            subdistrict_id: "6502",
            subdistrict_name: "Adiwerna",
          },
          {
            subdistrict_id: "6503",
            subdistrict_name: "Balapulang",
          },
          {
            subdistrict_id: "6504",
            subdistrict_name: "Bojong",
          },
          {
            subdistrict_id: "6505",
            subdistrict_name: "Bumijawa",
          },
          {
            subdistrict_id: "6506",
            subdistrict_name: "Dukuhturi",
          },
          {
            subdistrict_id: "6507",
            subdistrict_name: "Dukuhwaru",
          },
          {
            subdistrict_id: "6508",
            subdistrict_name: "Jatinegara",
          },
          {
            subdistrict_id: "6509",
            subdistrict_name: "Kedung Banteng",
          },
          {
            subdistrict_id: "6510",
            subdistrict_name: "Kramat",
          },
          {
            subdistrict_id: "6511",
            subdistrict_name: "Lebaksiu",
          },
          {
            subdistrict_id: "6512",
            subdistrict_name: "Margasari",
          },
          {
            subdistrict_id: "6513",
            subdistrict_name: "Pagerbarang",
          },
          {
            subdistrict_id: "6514",
            subdistrict_name: "Pangkah",
          },
          {
            subdistrict_id: "6515",
            subdistrict_name: "Slawi",
          },
          {
            subdistrict_id: "6516",
            subdistrict_name: "Surodadi",
          },
          {
            subdistrict_id: "6517",
            subdistrict_name: "Talang",
          },
          {
            subdistrict_id: "6518",
            subdistrict_name: "Tarub",
          },
          {
            subdistrict_id: "6519",
            subdistrict_name: "Warurejo",
          },
        ],
      },
      {
        city_id: "473",
        type: "Kota",
        city_name: "Tegal",
        subdistricts: [
          {
            subdistrict_id: "6520",
            subdistrict_name: "Margadana",
          },
          {
            subdistrict_id: "6521",
            subdistrict_name: "Tegal Barat",
          },
          {
            subdistrict_id: "6522",
            subdistrict_name: "Tegal Selatan",
          },
          {
            subdistrict_id: "6523",
            subdistrict_name: "Tegal Timur",
          },
        ],
      },
      {
        city_id: "476",
        type: "Kabupaten",
        city_name: "Temanggung",
        subdistricts: [
          {
            subdistrict_id: "6561",
            subdistrict_name: "Bansari",
          },
          {
            subdistrict_id: "6562",
            subdistrict_name: "Bejen",
          },
          {
            subdistrict_id: "6563",
            subdistrict_name: "Bulu",
          },
          {
            subdistrict_id: "6564",
            subdistrict_name: "Candiroto",
          },
          {
            subdistrict_id: "6565",
            subdistrict_name: "Gemawang",
          },
          {
            subdistrict_id: "6566",
            subdistrict_name: "Jumo",
          },
          {
            subdistrict_id: "6567",
            subdistrict_name: "Kaloran",
          },
          {
            subdistrict_id: "6568",
            subdistrict_name: "Kandangan",
          },
          {
            subdistrict_id: "6569",
            subdistrict_name: "Kedu",
          },
          {
            subdistrict_id: "6570",
            subdistrict_name: "Kledung",
          },
          {
            subdistrict_id: "6571",
            subdistrict_name: "Kranggan",
          },
          {
            subdistrict_id: "6572",
            subdistrict_name: "Ngadirejo",
          },
          {
            subdistrict_id: "6573",
            subdistrict_name: "Parakan",
          },
          {
            subdistrict_id: "6574",
            subdistrict_name: "Pringsurat",
          },
          {
            subdistrict_id: "6575",
            subdistrict_name: "Selopampang",
          },
          {
            subdistrict_id: "6576",
            subdistrict_name: "Temanggung",
          },
          {
            subdistrict_id: "6577",
            subdistrict_name: "Tembarak",
          },
          {
            subdistrict_id: "6578",
            subdistrict_name: "Tlogomulyo",
          },
          {
            subdistrict_id: "6579",
            subdistrict_name: "Tretep",
          },
          {
            subdistrict_id: "6580",
            subdistrict_name: "Wonoboyo",
          },
        ],
      },
      {
        city_id: "497",
        type: "Kabupaten",
        city_name: "Wonogiri",
        subdistricts: [
          {
            subdistrict_id: "6885",
            subdistrict_name: "Baturetno",
          },
          {
            subdistrict_id: "6886",
            subdistrict_name: "Batuwarno",
          },
          {
            subdistrict_id: "6887",
            subdistrict_name: "Bulukerto",
          },
          {
            subdistrict_id: "6888",
            subdistrict_name: "Eromoko",
          },
          {
            subdistrict_id: "6889",
            subdistrict_name: "Girimarto",
          },
          {
            subdistrict_id: "6890",
            subdistrict_name: "Giritontro",
          },
          {
            subdistrict_id: "6891",
            subdistrict_name: "Giriwoyo",
          },
          {
            subdistrict_id: "6892",
            subdistrict_name: "Jatipurno",
          },
          {
            subdistrict_id: "6893",
            subdistrict_name: "Jatiroto",
          },
          {
            subdistrict_id: "6894",
            subdistrict_name: "Jatisrono",
          },
          {
            subdistrict_id: "6895",
            subdistrict_name: "Karangtengah",
          },
          {
            subdistrict_id: "6896",
            subdistrict_name: "Kismantoro",
          },
          {
            subdistrict_id: "6897",
            subdistrict_name: "Manyaran",
          },
          {
            subdistrict_id: "6898",
            subdistrict_name: "Ngadirojo",
          },
          {
            subdistrict_id: "6899",
            subdistrict_name: "Nguntoronadi",
          },
          {
            subdistrict_id: "6900",
            subdistrict_name: "Paranggupito",
          },
          {
            subdistrict_id: "6901",
            subdistrict_name: "Pracimantoro",
          },
          {
            subdistrict_id: "6902",
            subdistrict_name: "Puhpelem",
          },
          {
            subdistrict_id: "6903",
            subdistrict_name: "Purwantoro",
          },
          {
            subdistrict_id: "6904",
            subdistrict_name: "Selogiri",
          },
          {
            subdistrict_id: "6905",
            subdistrict_name: "Sidoharjo",
          },
          {
            subdistrict_id: "6906",
            subdistrict_name: "Slogohimo",
          },
          {
            subdistrict_id: "6907",
            subdistrict_name: "Tirtomoyo",
          },
          {
            subdistrict_id: "6908",
            subdistrict_name: "Wonogiri",
          },
          {
            subdistrict_id: "6909",
            subdistrict_name: "Wuryantoro",
          },
        ],
      },
      {
        city_id: "498",
        type: "Kabupaten",
        city_name: "Wonosobo",
        subdistricts: [
          {
            subdistrict_id: "6910",
            subdistrict_name: "Garung",
          },
          {
            subdistrict_id: "6911",
            subdistrict_name: "Kalibawang",
          },
          {
            subdistrict_id: "6912",
            subdistrict_name: "Kalikajar",
          },
          {
            subdistrict_id: "6913",
            subdistrict_name: "Kaliwiro",
          },
          {
            subdistrict_id: "6914",
            subdistrict_name: "Kejajar",
          },
          {
            subdistrict_id: "6915",
            subdistrict_name: "Kepil",
          },
          {
            subdistrict_id: "6916",
            subdistrict_name: "Kertek",
          },
          {
            subdistrict_id: "6917",
            subdistrict_name: "Leksono",
          },
          {
            subdistrict_id: "6918",
            subdistrict_name: "Mojotengah",
          },
          {
            subdistrict_id: "6919",
            subdistrict_name: "Sapuran",
          },
          {
            subdistrict_id: "6920",
            subdistrict_name: "Selomerto",
          },
          {
            subdistrict_id: "6921",
            subdistrict_name: "Sukoharjo",
          },
          {
            subdistrict_id: "6922",
            subdistrict_name: "Wadaslintang",
          },
          {
            subdistrict_id: "6923",
            subdistrict_name: "Watumalang",
          },
          {
            subdistrict_id: "6924",
            subdistrict_name: "Wonosobo",
          },
        ],
      },
    ],
  },
  {
    province_id: "11",
    province: "Jawa Timur",
    cities: [
      {
        city_id: "31",
        type: "Kabupaten",
        city_name: "Bangkalan",
        subdistricts: [
          {
            subdistrict_id: "454",
            subdistrict_name: "Arosbaya",
          },
          {
            subdistrict_id: "455",
            subdistrict_name: "Bangkalan",
          },
          {
            subdistrict_id: "456",
            subdistrict_name: "Blega",
          },
          {
            subdistrict_id: "457",
            subdistrict_name: "Burneh",
          },
          {
            subdistrict_id: "458",
            subdistrict_name: "Galis",
          },
          {
            subdistrict_id: "459",
            subdistrict_name: "Geger",
          },
          {
            subdistrict_id: "460",
            subdistrict_name: "Kamal",
          },
          {
            subdistrict_id: "461",
            subdistrict_name: "Klampis",
          },
          {
            subdistrict_id: "462",
            subdistrict_name: "Kokop",
          },
          {
            subdistrict_id: "463",
            subdistrict_name: "Konang",
          },
          {
            subdistrict_id: "464",
            subdistrict_name: "Kwanyar",
          },
          {
            subdistrict_id: "465",
            subdistrict_name: "Labang",
          },
          {
            subdistrict_id: "466",
            subdistrict_name: "Modung",
          },
          {
            subdistrict_id: "467",
            subdistrict_name: "Sepulu",
          },
          {
            subdistrict_id: "468",
            subdistrict_name: "Socah",
          },
          {
            subdistrict_id: "469",
            subdistrict_name: "Tanah Merah",
          },
          {
            subdistrict_id: "470",
            subdistrict_name: "Tanjungbumi",
          },
          {
            subdistrict_id: "471",
            subdistrict_name: "Tragah",
          },
        ],
      },
      {
        city_id: "42",
        type: "Kabupaten",
        city_name: "Banyuwangi",
        subdistricts: [
          {
            subdistrict_id: "600",
            subdistrict_name: "Bangorejo",
          },
          {
            subdistrict_id: "601",
            subdistrict_name: "Banyuwangi",
          },
          {
            subdistrict_id: "602",
            subdistrict_name: "Cluring",
          },
          {
            subdistrict_id: "603",
            subdistrict_name: "Gambiran",
          },
          {
            subdistrict_id: "604",
            subdistrict_name: "Genteng",
          },
          {
            subdistrict_id: "605",
            subdistrict_name: "Giri",
          },
          {
            subdistrict_id: "606",
            subdistrict_name: "Glagah",
          },
          {
            subdistrict_id: "607",
            subdistrict_name: "Glenmore",
          },
          {
            subdistrict_id: "608",
            subdistrict_name: "Kabat",
          },
          {
            subdistrict_id: "609",
            subdistrict_name: "Kalibaru",
          },
          {
            subdistrict_id: "610",
            subdistrict_name: "Kalipuro",
          },
          {
            subdistrict_id: "611",
            subdistrict_name: "Licin",
          },
          {
            subdistrict_id: "612",
            subdistrict_name: "Muncar",
          },
          {
            subdistrict_id: "613",
            subdistrict_name: "Pesanggaran",
          },
          {
            subdistrict_id: "614",
            subdistrict_name: "Purwoharjo",
          },
          {
            subdistrict_id: "615",
            subdistrict_name: "Rogojampi",
          },
          {
            subdistrict_id: "616",
            subdistrict_name: "Sempu",
          },
          {
            subdistrict_id: "617",
            subdistrict_name: "Siliragung",
          },
          {
            subdistrict_id: "618",
            subdistrict_name: "Singojuruh",
          },
          {
            subdistrict_id: "619",
            subdistrict_name: "Songgon",
          },
          {
            subdistrict_id: "620",
            subdistrict_name: "Srono",
          },
          {
            subdistrict_id: "621",
            subdistrict_name: "Tegaldlimo",
          },
          {
            subdistrict_id: "622",
            subdistrict_name: "Tegalsari",
          },
          {
            subdistrict_id: "623",
            subdistrict_name: "Wongsorejo",
          },
        ],
      },
      {
        city_id: "51",
        type: "Kota",
        city_name: "Batu",
        subdistricts: [
          {
            subdistrict_id: "708",
            subdistrict_name: "Batu",
          },
          {
            subdistrict_id: "709",
            subdistrict_name: "Bumiaji",
          },
          {
            subdistrict_id: "710",
            subdistrict_name: "Junrejo",
          },
        ],
      },
      {
        city_id: "74",
        type: "Kabupaten",
        city_name: "Blitar",
        subdistricts: [
          {
            subdistrict_id: "974",
            subdistrict_name: "Bakung",
          },
          {
            subdistrict_id: "975",
            subdistrict_name: "Binangun",
          },
          {
            subdistrict_id: "976",
            subdistrict_name: "Doko",
          },
          {
            subdistrict_id: "977",
            subdistrict_name: "Gandusari",
          },
          {
            subdistrict_id: "978",
            subdistrict_name: "Garum",
          },
          {
            subdistrict_id: "979",
            subdistrict_name: "Kademangan",
          },
          {
            subdistrict_id: "980",
            subdistrict_name: "Kanigoro",
          },
          {
            subdistrict_id: "981",
            subdistrict_name: "Kesamben",
          },
          {
            subdistrict_id: "982",
            subdistrict_name: "Nglegok",
          },
          {
            subdistrict_id: "983",
            subdistrict_name: "Panggungrejo",
          },
          {
            subdistrict_id: "984",
            subdistrict_name: "Ponggok",
          },
          {
            subdistrict_id: "985",
            subdistrict_name: "Sanan Kulon",
          },
          {
            subdistrict_id: "986",
            subdistrict_name: "Selopuro",
          },
          {
            subdistrict_id: "987",
            subdistrict_name: "Selorejo",
          },
          {
            subdistrict_id: "988",
            subdistrict_name: "Srengat",
          },
          {
            subdistrict_id: "989",
            subdistrict_name: "Sutojayan",
          },
          {
            subdistrict_id: "990",
            subdistrict_name: "Talun",
          },
          {
            subdistrict_id: "991",
            subdistrict_name: "Udanawu",
          },
          {
            subdistrict_id: "992",
            subdistrict_name: "Wates",
          },
          {
            subdistrict_id: "993",
            subdistrict_name: "Wlingi",
          },
          {
            subdistrict_id: "994",
            subdistrict_name: "Wonodadi",
          },
          {
            subdistrict_id: "995",
            subdistrict_name: "Wonotirto",
          },
        ],
      },
      {
        city_id: "75",
        type: "Kota",
        city_name: "Blitar",
        subdistricts: [
          {
            subdistrict_id: "996",
            subdistrict_name: "Kepanjen Kidul",
          },
          {
            subdistrict_id: "997",
            subdistrict_name: "Sanan Wetan",
          },
          {
            subdistrict_id: "998",
            subdistrict_name: "Sukorejo",
          },
        ],
      },
      {
        city_id: "80",
        type: "Kabupaten",
        city_name: "Bojonegoro",
        subdistricts: [
          {
            subdistrict_id: "1068",
            subdistrict_name: "Balen",
          },
          {
            subdistrict_id: "1069",
            subdistrict_name: "Baureno",
          },
          {
            subdistrict_id: "1070",
            subdistrict_name: "Bojonegoro",
          },
          {
            subdistrict_id: "1071",
            subdistrict_name: "Bubulan",
          },
          {
            subdistrict_id: "1072",
            subdistrict_name: "Dander",
          },
          {
            subdistrict_id: "1073",
            subdistrict_name: "Gayam",
          },
          {
            subdistrict_id: "1074",
            subdistrict_name: "Gondang",
          },
          {
            subdistrict_id: "1075",
            subdistrict_name: "Kalitidu",
          },
          {
            subdistrict_id: "1076",
            subdistrict_name: "Kanor",
          },
          {
            subdistrict_id: "1077",
            subdistrict_name: "Kapas",
          },
          {
            subdistrict_id: "1078",
            subdistrict_name: "Kasiman",
          },
          {
            subdistrict_id: "1079",
            subdistrict_name: "Kedewan",
          },
          {
            subdistrict_id: "1080",
            subdistrict_name: "Kedungadem",
          },
          {
            subdistrict_id: "1081",
            subdistrict_name: "Kepoh Baru",
          },
          {
            subdistrict_id: "1082",
            subdistrict_name: "Malo",
          },
          {
            subdistrict_id: "1083",
            subdistrict_name: "Margomulyo",
          },
          {
            subdistrict_id: "1084",
            subdistrict_name: "Ngambon",
          },
          {
            subdistrict_id: "1085",
            subdistrict_name: "Ngasem",
          },
          {
            subdistrict_id: "1086",
            subdistrict_name: "Ngraho",
          },
          {
            subdistrict_id: "1087",
            subdistrict_name: "Padangan",
          },
          {
            subdistrict_id: "1088",
            subdistrict_name: "Purwosari",
          },
          {
            subdistrict_id: "1089",
            subdistrict_name: "Sekar",
          },
          {
            subdistrict_id: "1090",
            subdistrict_name: "Sugihwaras",
          },
          {
            subdistrict_id: "1091",
            subdistrict_name: "Sukosewu",
          },
          {
            subdistrict_id: "1092",
            subdistrict_name: "Sumberrejo",
          },
          {
            subdistrict_id: "1093",
            subdistrict_name: "Tambakrejo",
          },
          {
            subdistrict_id: "1094",
            subdistrict_name: "Temayang",
          },
          {
            subdistrict_id: "1095",
            subdistrict_name: "Trucuk",
          },
        ],
      },
      {
        city_id: "86",
        type: "Kabupaten",
        city_name: "Bondowoso",
        subdistricts: [
          {
            subdistrict_id: "1149",
            subdistrict_name: "Binakal",
          },
          {
            subdistrict_id: "1150",
            subdistrict_name: "Bondowoso",
          },
          {
            subdistrict_id: "1151",
            subdistrict_name: "Botolinggo",
          },
          {
            subdistrict_id: "1152",
            subdistrict_name: "Cermee",
          },
          {
            subdistrict_id: "1153",
            subdistrict_name: "Curahdami",
          },
          {
            subdistrict_id: "1154",
            subdistrict_name: "Grujugan",
          },
          {
            subdistrict_id: "1155",
            subdistrict_name: "Jambe Sari Darus Sholah",
          },
          {
            subdistrict_id: "1156",
            subdistrict_name: "Klabang",
          },
          {
            subdistrict_id: "1157",
            subdistrict_name: "Maesan",
          },
          {
            subdistrict_id: "1158",
            subdistrict_name: "Pakem",
          },
          {
            subdistrict_id: "1159",
            subdistrict_name: "Prajekan",
          },
          {
            subdistrict_id: "1160",
            subdistrict_name: "Pujer",
          },
          {
            subdistrict_id: "1161",
            subdistrict_name: "Sempol",
          },
          {
            subdistrict_id: "1162",
            subdistrict_name: "Sukosari",
          },
          {
            subdistrict_id: "1163",
            subdistrict_name: "Sumber Wringin",
          },
          {
            subdistrict_id: "1164",
            subdistrict_name: "Taman Krocok",
          },
          {
            subdistrict_id: "1165",
            subdistrict_name: "Tamanan",
          },
          {
            subdistrict_id: "1166",
            subdistrict_name: "Tapen",
          },
          {
            subdistrict_id: "1167",
            subdistrict_name: "Tegalampel",
          },
          {
            subdistrict_id: "1168",
            subdistrict_name: "Tenggarang",
          },
          {
            subdistrict_id: "1169",
            subdistrict_name: "Tlogosari",
          },
          {
            subdistrict_id: "1170",
            subdistrict_name: "Wonosari",
          },
          {
            subdistrict_id: "1171",
            subdistrict_name: "Wringin",
          },
        ],
      },
      {
        city_id: "133",
        type: "Kabupaten",
        city_name: "Gresik",
        subdistricts: [
          {
            subdistrict_id: "1828",
            subdistrict_name: "Balong Panggang",
          },
          {
            subdistrict_id: "1829",
            subdistrict_name: "Benjeng",
          },
          {
            subdistrict_id: "1830",
            subdistrict_name: "Bungah",
          },
          {
            subdistrict_id: "1831",
            subdistrict_name: "Cerme",
          },
          {
            subdistrict_id: "1832",
            subdistrict_name: "Driyorejo",
          },
          {
            subdistrict_id: "1833",
            subdistrict_name: "Duduk Sampeyan",
          },
          {
            subdistrict_id: "1834",
            subdistrict_name: "Dukun",
          },
          {
            subdistrict_id: "1835",
            subdistrict_name: "Gresik",
          },
          {
            subdistrict_id: "1836",
            subdistrict_name: "Kebomas",
          },
          {
            subdistrict_id: "1837",
            subdistrict_name: "Kedamean",
          },
          {
            subdistrict_id: "1838",
            subdistrict_name: "Manyar",
          },
          {
            subdistrict_id: "1839",
            subdistrict_name: "Menganti",
          },
          {
            subdistrict_id: "1840",
            subdistrict_name: "Panceng",
          },
          {
            subdistrict_id: "1841",
            subdistrict_name: "Sangkapura",
          },
          {
            subdistrict_id: "1842",
            subdistrict_name: "Sidayu",
          },
          {
            subdistrict_id: "1843",
            subdistrict_name: "Tambak",
          },
          {
            subdistrict_id: "1844",
            subdistrict_name: "Ujung Pangkah",
          },
          {
            subdistrict_id: "1845",
            subdistrict_name: "Wringin Anom",
          },
        ],
      },
      {
        city_id: "160",
        type: "Kabupaten",
        city_name: "Jember",
        subdistricts: [
          {
            subdistrict_id: "2201",
            subdistrict_name: "Ajung",
          },
          {
            subdistrict_id: "2202",
            subdistrict_name: "Ambulu",
          },
          {
            subdistrict_id: "2203",
            subdistrict_name: "Arjasa",
          },
          {
            subdistrict_id: "2204",
            subdistrict_name: "Balung",
          },
          {
            subdistrict_id: "2205",
            subdistrict_name: "Bangsalsari",
          },
          {
            subdistrict_id: "2206",
            subdistrict_name: "Gumuk Mas",
          },
          {
            subdistrict_id: "2207",
            subdistrict_name: "Jelbuk",
          },
          {
            subdistrict_id: "2208",
            subdistrict_name: "Jenggawah",
          },
          {
            subdistrict_id: "2209",
            subdistrict_name: "Jombang",
          },
          {
            subdistrict_id: "2210",
            subdistrict_name: "Kalisat",
          },
          {
            subdistrict_id: "2211",
            subdistrict_name: "Kaliwates",
          },
          {
            subdistrict_id: "2212",
            subdistrict_name: "Kencong",
          },
          {
            subdistrict_id: "2213",
            subdistrict_name: "Ledokombo",
          },
          {
            subdistrict_id: "2214",
            subdistrict_name: "Mayang",
          },
          {
            subdistrict_id: "2215",
            subdistrict_name: "Mumbulsari",
          },
          {
            subdistrict_id: "2216",
            subdistrict_name: "Pakusari",
          },
          {
            subdistrict_id: "2217",
            subdistrict_name: "Panti",
          },
          {
            subdistrict_id: "2218",
            subdistrict_name: "Patrang",
          },
          {
            subdistrict_id: "2219",
            subdistrict_name: "Puger",
          },
          {
            subdistrict_id: "2220",
            subdistrict_name: "Rambipuji",
          },
          {
            subdistrict_id: "2221",
            subdistrict_name: "Semboro",
          },
          {
            subdistrict_id: "2222",
            subdistrict_name: "Silo",
          },
          {
            subdistrict_id: "2223",
            subdistrict_name: "Sukorambi",
          },
          {
            subdistrict_id: "2224",
            subdistrict_name: "Sukowono",
          },
          {
            subdistrict_id: "2225",
            subdistrict_name: "Sumber Baru",
          },
          {
            subdistrict_id: "2226",
            subdistrict_name: "Sumber Jambe",
          },
          {
            subdistrict_id: "2227",
            subdistrict_name: "Sumber Sari",
          },
          {
            subdistrict_id: "2228",
            subdistrict_name: "Tanggul",
          },
          {
            subdistrict_id: "2229",
            subdistrict_name: "Tempurejo",
          },
          {
            subdistrict_id: "2230",
            subdistrict_name: "Umbulsari",
          },
          {
            subdistrict_id: "2231",
            subdistrict_name: "Wuluhan",
          },
        ],
      },
      {
        city_id: "164",
        type: "Kabupaten",
        city_name: "Jombang",
        subdistricts: [
          {
            subdistrict_id: "2264",
            subdistrict_name: "Bandar Kedung Mulyo",
          },
          {
            subdistrict_id: "2265",
            subdistrict_name: "Bareng",
          },
          {
            subdistrict_id: "2266",
            subdistrict_name: "Diwek",
          },
          {
            subdistrict_id: "2267",
            subdistrict_name: "Gudo",
          },
          {
            subdistrict_id: "2268",
            subdistrict_name: "Jogoroto",
          },
          {
            subdistrict_id: "2269",
            subdistrict_name: "Jombang",
          },
          {
            subdistrict_id: "2270",
            subdistrict_name: "Kabuh",
          },
          {
            subdistrict_id: "2271",
            subdistrict_name: "Kesamben",
          },
          {
            subdistrict_id: "2272",
            subdistrict_name: "Kudu",
          },
          {
            subdistrict_id: "2273",
            subdistrict_name: "Megaluh",
          },
          {
            subdistrict_id: "2274",
            subdistrict_name: "Mojoagung",
          },
          {
            subdistrict_id: "2275",
            subdistrict_name: "Mojowarno",
          },
          {
            subdistrict_id: "2276",
            subdistrict_name: "Ngoro",
          },
          {
            subdistrict_id: "2277",
            subdistrict_name: "Ngusikan",
          },
          {
            subdistrict_id: "2278",
            subdistrict_name: "Perak",
          },
          {
            subdistrict_id: "2279",
            subdistrict_name: "Peterongan",
          },
          {
            subdistrict_id: "2280",
            subdistrict_name: "Plandaan",
          },
          {
            subdistrict_id: "2281",
            subdistrict_name: "Ploso",
          },
          {
            subdistrict_id: "2282",
            subdistrict_name: "Sumobito",
          },
          {
            subdistrict_id: "2283",
            subdistrict_name: "Tembelang",
          },
          {
            subdistrict_id: "2284",
            subdistrict_name: "Wonosalam",
          },
        ],
      },
      {
        city_id: "178",
        type: "Kabupaten",
        city_name: "Kediri",
        subdistricts: [
          {
            subdistrict_id: "2497",
            subdistrict_name: "Badas",
          },
          {
            subdistrict_id: "2498",
            subdistrict_name: "Banyakan",
          },
          {
            subdistrict_id: "2499",
            subdistrict_name: "Gampengrejo",
          },
          {
            subdistrict_id: "2500",
            subdistrict_name: "Grogol",
          },
          {
            subdistrict_id: "2501",
            subdistrict_name: "Gurah",
          },
          {
            subdistrict_id: "2502",
            subdistrict_name: "Kandangan",
          },
          {
            subdistrict_id: "2503",
            subdistrict_name: "Kandat",
          },
          {
            subdistrict_id: "2504",
            subdistrict_name: "Kayen Kidul",
          },
          {
            subdistrict_id: "2505",
            subdistrict_name: "Kepung",
          },
          {
            subdistrict_id: "2506",
            subdistrict_name: "Kras",
          },
          {
            subdistrict_id: "2507",
            subdistrict_name: "Kunjang",
          },
          {
            subdistrict_id: "2508",
            subdistrict_name: "Mojo",
          },
          {
            subdistrict_id: "2509",
            subdistrict_name: "Ngadiluwih",
          },
          {
            subdistrict_id: "2510",
            subdistrict_name: "Ngancar",
          },
          {
            subdistrict_id: "2511",
            subdistrict_name: "Ngasem",
          },
          {
            subdistrict_id: "2512",
            subdistrict_name: "Pagu",
          },
          {
            subdistrict_id: "2513",
            subdistrict_name: "Papar",
          },
          {
            subdistrict_id: "2514",
            subdistrict_name: "Pare",
          },
          {
            subdistrict_id: "2515",
            subdistrict_name: "Plemahan",
          },
          {
            subdistrict_id: "2516",
            subdistrict_name: "Plosoklaten",
          },
          {
            subdistrict_id: "2517",
            subdistrict_name: "Puncu",
          },
          {
            subdistrict_id: "2518",
            subdistrict_name: "Purwoasri",
          },
          {
            subdistrict_id: "2519",
            subdistrict_name: "Ringinrejo",
          },
          {
            subdistrict_id: "2520",
            subdistrict_name: "Semen",
          },
          {
            subdistrict_id: "2521",
            subdistrict_name: "Tarokan",
          },
          {
            subdistrict_id: "2522",
            subdistrict_name: "Wates",
          },
        ],
      },
      {
        city_id: "179",
        type: "Kota",
        city_name: "Kediri",
        subdistricts: [
          {
            subdistrict_id: "2523",
            subdistrict_name: "Kediri Kota",
          },
          {
            subdistrict_id: "2524",
            subdistrict_name: "Mojoroto",
          },
          {
            subdistrict_id: "2525",
            subdistrict_name: "Pesantren",
          },
        ],
      },
      {
        city_id: "222",
        type: "Kabupaten",
        city_name: "Lamongan",
        subdistricts: [
          {
            subdistrict_id: "3113",
            subdistrict_name: "Babat",
          },
          {
            subdistrict_id: "3114",
            subdistrict_name: "Bluluk",
          },
          {
            subdistrict_id: "3115",
            subdistrict_name: "Brondong",
          },
          {
            subdistrict_id: "3116",
            subdistrict_name: "Deket",
          },
          {
            subdistrict_id: "3117",
            subdistrict_name: "Glagah",
          },
          {
            subdistrict_id: "3118",
            subdistrict_name: "Kalitengah",
          },
          {
            subdistrict_id: "3119",
            subdistrict_name: "Karang Geneng",
          },
          {
            subdistrict_id: "3120",
            subdistrict_name: "Karangbinangun",
          },
          {
            subdistrict_id: "3121",
            subdistrict_name: "Kedungpring",
          },
          {
            subdistrict_id: "3122",
            subdistrict_name: "Kembangbahu",
          },
          {
            subdistrict_id: "3123",
            subdistrict_name: "Lamongan",
          },
          {
            subdistrict_id: "3124",
            subdistrict_name: "Laren",
          },
          {
            subdistrict_id: "3125",
            subdistrict_name: "Maduran",
          },
          {
            subdistrict_id: "3126",
            subdistrict_name: "Mantup",
          },
          {
            subdistrict_id: "3127",
            subdistrict_name: "Modo",
          },
          {
            subdistrict_id: "3128",
            subdistrict_name: "Ngimbang",
          },
          {
            subdistrict_id: "3129",
            subdistrict_name: "Paciran",
          },
          {
            subdistrict_id: "3130",
            subdistrict_name: "Pucuk",
          },
          {
            subdistrict_id: "3131",
            subdistrict_name: "Sambeng",
          },
          {
            subdistrict_id: "3132",
            subdistrict_name: "Sarirejo",
          },
          {
            subdistrict_id: "3133",
            subdistrict_name: "Sekaran",
          },
          {
            subdistrict_id: "3134",
            subdistrict_name: "Solokuro",
          },
          {
            subdistrict_id: "3135",
            subdistrict_name: "Sugio",
          },
          {
            subdistrict_id: "3136",
            subdistrict_name: "Sukodadi",
          },
          {
            subdistrict_id: "3137",
            subdistrict_name: "Sukorame",
          },
          {
            subdistrict_id: "3138",
            subdistrict_name: "Tikung",
          },
          {
            subdistrict_id: "3139",
            subdistrict_name: "Turi",
          },
        ],
      },
      {
        city_id: "243",
        type: "Kabupaten",
        city_name: "Lumajang",
        subdistricts: [
          {
            subdistrict_id: "3427",
            subdistrict_name: "Candipuro",
          },
          {
            subdistrict_id: "3428",
            subdistrict_name: "Gucialit",
          },
          {
            subdistrict_id: "3429",
            subdistrict_name: "Jatiroto",
          },
          {
            subdistrict_id: "3430",
            subdistrict_name: "Kedungjajang",
          },
          {
            subdistrict_id: "3431",
            subdistrict_name: "Klakah",
          },
          {
            subdistrict_id: "3432",
            subdistrict_name: "Kunir",
          },
          {
            subdistrict_id: "3433",
            subdistrict_name: "Lumajang",
          },
          {
            subdistrict_id: "3434",
            subdistrict_name: "Padang",
          },
          {
            subdistrict_id: "3435",
            subdistrict_name: "Pasirian",
          },
          {
            subdistrict_id: "3436",
            subdistrict_name: "Pasrujambe/Pasujambe",
          },
          {
            subdistrict_id: "3437",
            subdistrict_name: "Pronojiwo",
          },
          {
            subdistrict_id: "3438",
            subdistrict_name: "Randuagung",
          },
          {
            subdistrict_id: "3439",
            subdistrict_name: "Ranuyoso",
          },
          {
            subdistrict_id: "3440",
            subdistrict_name: "Rowokangkung",
          },
          {
            subdistrict_id: "3441",
            subdistrict_name: "Senduro",
          },
          {
            subdistrict_id: "3442",
            subdistrict_name: "Sukodono",
          },
          {
            subdistrict_id: "3443",
            subdistrict_name: "Sumbersuko",
          },
          {
            subdistrict_id: "3444",
            subdistrict_name: "Tekung",
          },
          {
            subdistrict_id: "3445",
            subdistrict_name: "Tempeh",
          },
          {
            subdistrict_id: "3446",
            subdistrict_name: "Tempursari",
          },
          {
            subdistrict_id: "3447",
            subdistrict_name: "Yosowilangun",
          },
        ],
      },
      {
        city_id: "247",
        type: "Kabupaten",
        city_name: "Madiun",
        subdistricts: [
          {
            subdistrict_id: "3493",
            subdistrict_name: "Balerejo",
          },
          {
            subdistrict_id: "3494",
            subdistrict_name: "Dagangan",
          },
          {
            subdistrict_id: "3495",
            subdistrict_name: "Dolopo",
          },
          {
            subdistrict_id: "3496",
            subdistrict_name: "Geger",
          },
          {
            subdistrict_id: "3497",
            subdistrict_name: "Gemarang",
          },
          {
            subdistrict_id: "3498",
            subdistrict_name: "Jiwan",
          },
          {
            subdistrict_id: "3499",
            subdistrict_name: "Kare",
          },
          {
            subdistrict_id: "3500",
            subdistrict_name: "Kebonsari",
          },
          {
            subdistrict_id: "3501",
            subdistrict_name: "Madiun",
          },
          {
            subdistrict_id: "3502",
            subdistrict_name: "Mejayan",
          },
          {
            subdistrict_id: "3503",
            subdistrict_name: "Pilangkenceng",
          },
          {
            subdistrict_id: "3504",
            subdistrict_name: "Saradan",
          },
          {
            subdistrict_id: "3505",
            subdistrict_name: "Sawahan",
          },
          {
            subdistrict_id: "3506",
            subdistrict_name: "Wonoasri",
          },
          {
            subdistrict_id: "3507",
            subdistrict_name: "Wungu",
          },
        ],
      },
      {
        city_id: "248",
        type: "Kota",
        city_name: "Madiun",
        subdistricts: [
          {
            subdistrict_id: "3508",
            subdistrict_name: "Kartoharjo",
          },
          {
            subdistrict_id: "3509",
            subdistrict_name: "Manguharjo",
          },
          {
            subdistrict_id: "3510",
            subdistrict_name: "Taman",
          },
        ],
      },
      {
        city_id: "251",
        type: "Kabupaten",
        city_name: "Magetan",
        subdistricts: [
          {
            subdistrict_id: "3535",
            subdistrict_name: "Barat",
          },
          {
            subdistrict_id: "3536",
            subdistrict_name: "Bendo",
          },
          {
            subdistrict_id: "3537",
            subdistrict_name: "Karangrejo",
          },
          {
            subdistrict_id: "3538",
            subdistrict_name: "Karas",
          },
          {
            subdistrict_id: "3539",
            subdistrict_name: "Kartoharjo (Kertoharjo)",
          },
          {
            subdistrict_id: "3540",
            subdistrict_name: "Kawedanan",
          },
          {
            subdistrict_id: "3541",
            subdistrict_name: "Lembeyan",
          },
          {
            subdistrict_id: "3542",
            subdistrict_name: "Magetan",
          },
          {
            subdistrict_id: "3543",
            subdistrict_name: "Maospati",
          },
          {
            subdistrict_id: "3544",
            subdistrict_name: "Ngariboyo",
          },
          {
            subdistrict_id: "3545",
            subdistrict_name: "Nguntoronadi",
          },
          {
            subdistrict_id: "3546",
            subdistrict_name: "Panekan",
          },
          {
            subdistrict_id: "3547",
            subdistrict_name: "Parang",
          },
          {
            subdistrict_id: "3548",
            subdistrict_name: "Plaosan",
          },
          {
            subdistrict_id: "3549",
            subdistrict_name: "Poncol",
          },
          {
            subdistrict_id: "3550",
            subdistrict_name: "Sidorejo",
          },
          {
            subdistrict_id: "3551",
            subdistrict_name: "Sukomoro",
          },
          {
            subdistrict_id: "3552",
            subdistrict_name: "Takeran",
          },
        ],
      },
      {
        city_id: "255",
        type: "Kabupaten",
        city_name: "Malang",
        subdistricts: [
          {
            subdistrict_id: "3601",
            subdistrict_name: "Ampelgading",
          },
          {
            subdistrict_id: "3602",
            subdistrict_name: "Bantur",
          },
          {
            subdistrict_id: "3603",
            subdistrict_name: "Bululawang",
          },
          {
            subdistrict_id: "3604",
            subdistrict_name: "Dampit",
          },
          {
            subdistrict_id: "3605",
            subdistrict_name: "Dau",
          },
          {
            subdistrict_id: "3606",
            subdistrict_name: "Donomulyo",
          },
          {
            subdistrict_id: "3607",
            subdistrict_name: "Gedangan",
          },
          {
            subdistrict_id: "3608",
            subdistrict_name: "Gondanglegi",
          },
          {
            subdistrict_id: "3609",
            subdistrict_name: "Jabung",
          },
          {
            subdistrict_id: "3610",
            subdistrict_name: "Kalipare",
          },
          {
            subdistrict_id: "3611",
            subdistrict_name: "Karangploso",
          },
          {
            subdistrict_id: "3612",
            subdistrict_name: "Kasembon",
          },
          {
            subdistrict_id: "3613",
            subdistrict_name: "Kepanjen",
          },
          {
            subdistrict_id: "3614",
            subdistrict_name: "Kromengan",
          },
          {
            subdistrict_id: "3615",
            subdistrict_name: "Lawang",
          },
          {
            subdistrict_id: "3616",
            subdistrict_name: "Ngajung (Ngajum)",
          },
          {
            subdistrict_id: "3617",
            subdistrict_name: "Ngantang",
          },
          {
            subdistrict_id: "3618",
            subdistrict_name: "Pagak",
          },
          {
            subdistrict_id: "3619",
            subdistrict_name: "Pagelaran",
          },
          {
            subdistrict_id: "3620",
            subdistrict_name: "Pakis",
          },
          {
            subdistrict_id: "3621",
            subdistrict_name: "Pakisaji",
          },
          {
            subdistrict_id: "3622",
            subdistrict_name: "Poncokusumo",
          },
          {
            subdistrict_id: "3623",
            subdistrict_name: "Pujon",
          },
          {
            subdistrict_id: "3624",
            subdistrict_name: "Singosari",
          },
          {
            subdistrict_id: "3625",
            subdistrict_name: "Sumbermanjing Wetan",
          },
          {
            subdistrict_id: "3626",
            subdistrict_name: "Sumberpucung",
          },
          {
            subdistrict_id: "3627",
            subdistrict_name: "Tajinan",
          },
          {
            subdistrict_id: "3628",
            subdistrict_name: "Tirtoyudo",
          },
          {
            subdistrict_id: "3629",
            subdistrict_name: "Tumpang",
          },
          {
            subdistrict_id: "3630",
            subdistrict_name: "Turen",
          },
          {
            subdistrict_id: "3631",
            subdistrict_name: "Wagir",
          },
          {
            subdistrict_id: "3632",
            subdistrict_name: "Wajak",
          },
          {
            subdistrict_id: "3633",
            subdistrict_name: "Wonosari",
          },
        ],
      },
      {
        city_id: "256",
        type: "Kota",
        city_name: "Malang",
        subdistricts: [
          {
            subdistrict_id: "3634",
            subdistrict_name: "Blimbing",
          },
          {
            subdistrict_id: "3635",
            subdistrict_name: "Kedungkandang",
          },
          {
            subdistrict_id: "3636",
            subdistrict_name: "Klojen",
          },
          {
            subdistrict_id: "3637",
            subdistrict_name: "Lowokwaru",
          },
          {
            subdistrict_id: "3638",
            subdistrict_name: "Sukun",
          },
        ],
      },
      {
        city_id: "289",
        type: "Kabupaten",
        city_name: "Mojokerto",
        subdistricts: [
          {
            subdistrict_id: "4070",
            subdistrict_name: "Bangsal",
          },
          {
            subdistrict_id: "4071",
            subdistrict_name: "Dawar Blandong",
          },
          {
            subdistrict_id: "4072",
            subdistrict_name: "Dlanggu",
          },
          {
            subdistrict_id: "4073",
            subdistrict_name: "Gedeg",
          },
          {
            subdistrict_id: "4074",
            subdistrict_name: "Gondang",
          },
          {
            subdistrict_id: "4075",
            subdistrict_name: "Jatirejo",
          },
          {
            subdistrict_id: "4076",
            subdistrict_name: "Jetis",
          },
          {
            subdistrict_id: "4077",
            subdistrict_name: "Kemlagi",
          },
          {
            subdistrict_id: "4078",
            subdistrict_name: "Kutorejo",
          },
          {
            subdistrict_id: "4079",
            subdistrict_name: "Mojoanyar",
          },
          {
            subdistrict_id: "4080",
            subdistrict_name: "Mojosari",
          },
          {
            subdistrict_id: "4081",
            subdistrict_name: "Ngoro",
          },
          {
            subdistrict_id: "4082",
            subdistrict_name: "Pacet",
          },
          {
            subdistrict_id: "4083",
            subdistrict_name: "Pungging",
          },
          {
            subdistrict_id: "4084",
            subdistrict_name: "Puri",
          },
          {
            subdistrict_id: "4085",
            subdistrict_name: "Sooko",
          },
          {
            subdistrict_id: "4086",
            subdistrict_name: "Trawas",
          },
          {
            subdistrict_id: "4087",
            subdistrict_name: "Trowulan",
          },
        ],
      },
      {
        city_id: "290",
        type: "Kota",
        city_name: "Mojokerto",
        subdistricts: [
          {
            subdistrict_id: "4088",
            subdistrict_name: "Magersari",
          },
          {
            subdistrict_id: "4089",
            subdistrict_name: "Prajurit Kulon",
          },
        ],
      },
      {
        city_id: "305",
        type: "Kabupaten",
        city_name: "Nganjuk",
        subdistricts: [
          {
            subdistrict_id: "4323",
            subdistrict_name: "Bagor",
          },
          {
            subdistrict_id: "4324",
            subdistrict_name: "Baron",
          },
          {
            subdistrict_id: "4325",
            subdistrict_name: "Berbek",
          },
          {
            subdistrict_id: "4326",
            subdistrict_name: "Gondang",
          },
          {
            subdistrict_id: "4327",
            subdistrict_name: "Jatikalen",
          },
          {
            subdistrict_id: "4328",
            subdistrict_name: "Kertosono",
          },
          {
            subdistrict_id: "4329",
            subdistrict_name: "Lengkong",
          },
          {
            subdistrict_id: "4330",
            subdistrict_name: "Loceret",
          },
          {
            subdistrict_id: "4331",
            subdistrict_name: "Nganjuk",
          },
          {
            subdistrict_id: "4332",
            subdistrict_name: "Ngetos",
          },
          {
            subdistrict_id: "4333",
            subdistrict_name: "Ngluyu",
          },
          {
            subdistrict_id: "4334",
            subdistrict_name: "Ngronggot",
          },
          {
            subdistrict_id: "4335",
            subdistrict_name: "Pace",
          },
          {
            subdistrict_id: "4336",
            subdistrict_name: "Patianrowo",
          },
          {
            subdistrict_id: "4337",
            subdistrict_name: "Prambon",
          },
          {
            subdistrict_id: "4338",
            subdistrict_name: "Rejoso",
          },
          {
            subdistrict_id: "4339",
            subdistrict_name: "Sawahan",
          },
          {
            subdistrict_id: "4340",
            subdistrict_name: "Sukomoro",
          },
          {
            subdistrict_id: "4341",
            subdistrict_name: "Tanjunganom",
          },
          {
            subdistrict_id: "4342",
            subdistrict_name: "Wilangan",
          },
        ],
      },
      {
        city_id: "306",
        type: "Kabupaten",
        city_name: "Ngawi",
        subdistricts: [
          {
            subdistrict_id: "4343",
            subdistrict_name: "Bringin",
          },
          {
            subdistrict_id: "4344",
            subdistrict_name: "Geneng",
          },
          {
            subdistrict_id: "4345",
            subdistrict_name: "Gerih",
          },
          {
            subdistrict_id: "4346",
            subdistrict_name: "Jogorogo",
          },
          {
            subdistrict_id: "4347",
            subdistrict_name: "Karanganyar",
          },
          {
            subdistrict_id: "4348",
            subdistrict_name: "Karangjati",
          },
          {
            subdistrict_id: "4349",
            subdistrict_name: "Kasreman",
          },
          {
            subdistrict_id: "4350",
            subdistrict_name: "Kedunggalar",
          },
          {
            subdistrict_id: "4351",
            subdistrict_name: "Kendal",
          },
          {
            subdistrict_id: "4352",
            subdistrict_name: "Kwadungan",
          },
          {
            subdistrict_id: "4353",
            subdistrict_name: "Mantingan",
          },
          {
            subdistrict_id: "4354",
            subdistrict_name: "Ngawi",
          },
          {
            subdistrict_id: "4355",
            subdistrict_name: "Ngrambe",
          },
          {
            subdistrict_id: "4356",
            subdistrict_name: "Padas",
          },
          {
            subdistrict_id: "4357",
            subdistrict_name: "Pangkur",
          },
          {
            subdistrict_id: "4358",
            subdistrict_name: "Paron",
          },
          {
            subdistrict_id: "4359",
            subdistrict_name: "Pitu",
          },
          {
            subdistrict_id: "4360",
            subdistrict_name: "Sine",
          },
          {
            subdistrict_id: "4361",
            subdistrict_name: "Widodaren",
          },
        ],
      },
      {
        city_id: "317",
        type: "Kabupaten",
        city_name: "Pacitan",
        subdistricts: [
          {
            subdistrict_id: "4522",
            subdistrict_name: "Arjosari",
          },
          {
            subdistrict_id: "4523",
            subdistrict_name: "Bandar",
          },
          {
            subdistrict_id: "4524",
            subdistrict_name: "Donorojo",
          },
          {
            subdistrict_id: "4525",
            subdistrict_name: "Kebon Agung",
          },
          {
            subdistrict_id: "4526",
            subdistrict_name: "Nawangan",
          },
          {
            subdistrict_id: "4527",
            subdistrict_name: "Ngadirojo",
          },
          {
            subdistrict_id: "4528",
            subdistrict_name: "Pacitan",
          },
          {
            subdistrict_id: "4529",
            subdistrict_name: "Pringkuku",
          },
          {
            subdistrict_id: "4530",
            subdistrict_name: "Punung",
          },
          {
            subdistrict_id: "4531",
            subdistrict_name: "Sudimoro",
          },
          {
            subdistrict_id: "4532",
            subdistrict_name: "Tegalombo",
          },
          {
            subdistrict_id: "4533",
            subdistrict_name: "Tulakan",
          },
        ],
      },
      {
        city_id: "330",
        type: "Kabupaten",
        city_name: "Pamekasan",
        subdistricts: [
          {
            subdistrict_id: "4642",
            subdistrict_name: "Batumarmar",
          },
          {
            subdistrict_id: "4643",
            subdistrict_name: "Galis",
          },
          {
            subdistrict_id: "4644",
            subdistrict_name: "Kadur",
          },
          {
            subdistrict_id: "4645",
            subdistrict_name: "Larangan",
          },
          {
            subdistrict_id: "4646",
            subdistrict_name: "Pademawu",
          },
          {
            subdistrict_id: "4647",
            subdistrict_name: "Pakong",
          },
          {
            subdistrict_id: "4648",
            subdistrict_name: "Palenga'an",
          },
          {
            subdistrict_id: "4649",
            subdistrict_name: "Pamekasan",
          },
          {
            subdistrict_id: "4650",
            subdistrict_name: "Pasean",
          },
          {
            subdistrict_id: "4651",
            subdistrict_name: "Pegantenan",
          },
          {
            subdistrict_id: "4652",
            subdistrict_name: "Proppo",
          },
          {
            subdistrict_id: "4653",
            subdistrict_name: "Tlanakan",
          },
          {
            subdistrict_id: "4654",
            subdistrict_name: "Waru",
          },
        ],
      },
      {
        city_id: "342",
        type: "Kabupaten",
        city_name: "Pasuruan",
        subdistricts: [
          {
            subdistrict_id: "4793",
            subdistrict_name: "Bangil",
          },
          {
            subdistrict_id: "4794",
            subdistrict_name: "Beji",
          },
          {
            subdistrict_id: "4795",
            subdistrict_name: "Gempol",
          },
          {
            subdistrict_id: "4796",
            subdistrict_name: "Gondang Wetan",
          },
          {
            subdistrict_id: "4797",
            subdistrict_name: "Grati",
          },
          {
            subdistrict_id: "4798",
            subdistrict_name: "Kejayan",
          },
          {
            subdistrict_id: "4799",
            subdistrict_name: "Kraton",
          },
          {
            subdistrict_id: "4800",
            subdistrict_name: "Lekok",
          },
          {
            subdistrict_id: "4801",
            subdistrict_name: "Lumbang",
          },
          {
            subdistrict_id: "4802",
            subdistrict_name: "Nguling",
          },
          {
            subdistrict_id: "4803",
            subdistrict_name: "Pandaan",
          },
          {
            subdistrict_id: "4804",
            subdistrict_name: "Pasrepan",
          },
          {
            subdistrict_id: "4805",
            subdistrict_name: "Pohjentrek",
          },
          {
            subdistrict_id: "4806",
            subdistrict_name: "Prigen",
          },
          {
            subdistrict_id: "4807",
            subdistrict_name: "Purwodadi",
          },
          {
            subdistrict_id: "4808",
            subdistrict_name: "Purwosari",
          },
          {
            subdistrict_id: "4809",
            subdistrict_name: "Puspo",
          },
          {
            subdistrict_id: "4810",
            subdistrict_name: "Rejoso",
          },
          {
            subdistrict_id: "4811",
            subdistrict_name: "Rembang",
          },
          {
            subdistrict_id: "4812",
            subdistrict_name: "Sukorejo",
          },
          {
            subdistrict_id: "4813",
            subdistrict_name: "Tosari",
          },
          {
            subdistrict_id: "4814",
            subdistrict_name: "Tutur",
          },
          {
            subdistrict_id: "4815",
            subdistrict_name: "Winongan",
          },
          {
            subdistrict_id: "4816",
            subdistrict_name: "Wonorejo",
          },
        ],
      },
      {
        city_id: "343",
        type: "Kota",
        city_name: "Pasuruan",
        subdistricts: [
          {
            subdistrict_id: "4817",
            subdistrict_name: "Bugul Kidul",
          },
          {
            subdistrict_id: "4818",
            subdistrict_name: "Gadingrejo",
          },
          {
            subdistrict_id: "4819",
            subdistrict_name: "Panggungrejo",
          },
          {
            subdistrict_id: "4820",
            subdistrict_name: "Purworejo",
          },
        ],
      },
      {
        city_id: "363",
        type: "Kabupaten",
        city_name: "Ponorogo",
        subdistricts: [
          {
            subdistrict_id: "5071",
            subdistrict_name: "Babadan",
          },
          {
            subdistrict_id: "5072",
            subdistrict_name: "Badegan",
          },
          {
            subdistrict_id: "5073",
            subdistrict_name: "Balong",
          },
          {
            subdistrict_id: "5074",
            subdistrict_name: "Bungkal",
          },
          {
            subdistrict_id: "5075",
            subdistrict_name: "Jambon",
          },
          {
            subdistrict_id: "5076",
            subdistrict_name: "Jenangan",
          },
          {
            subdistrict_id: "5077",
            subdistrict_name: "Jetis",
          },
          {
            subdistrict_id: "5078",
            subdistrict_name: "Kauman",
          },
          {
            subdistrict_id: "5079",
            subdistrict_name: "Mlarak",
          },
          {
            subdistrict_id: "5080",
            subdistrict_name: "Ngebel",
          },
          {
            subdistrict_id: "5081",
            subdistrict_name: "Ngrayun",
          },
          {
            subdistrict_id: "5082",
            subdistrict_name: "Ponorogo",
          },
          {
            subdistrict_id: "5083",
            subdistrict_name: "Pudak",
          },
          {
            subdistrict_id: "5084",
            subdistrict_name: "Pulung",
          },
          {
            subdistrict_id: "5085",
            subdistrict_name: "Sambit",
          },
          {
            subdistrict_id: "5086",
            subdistrict_name: "Sampung",
          },
          {
            subdistrict_id: "5087",
            subdistrict_name: "Sawoo",
          },
          {
            subdistrict_id: "5088",
            subdistrict_name: "Siman",
          },
          {
            subdistrict_id: "5089",
            subdistrict_name: "Slahung",
          },
          {
            subdistrict_id: "5090",
            subdistrict_name: "Sooko",
          },
          {
            subdistrict_id: "5091",
            subdistrict_name: "Sukorejo",
          },
        ],
      },
      {
        city_id: "369",
        type: "Kabupaten",
        city_name: "Probolinggo",
        subdistricts: [
          {
            subdistrict_id: "5141",
            subdistrict_name: "Bantaran",
          },
          {
            subdistrict_id: "5142",
            subdistrict_name: "Banyu Anyar",
          },
          {
            subdistrict_id: "5143",
            subdistrict_name: "Besuk",
          },
          {
            subdistrict_id: "5144",
            subdistrict_name: "Dringu",
          },
          {
            subdistrict_id: "5145",
            subdistrict_name: "Gading",
          },
          {
            subdistrict_id: "5146",
            subdistrict_name: "Gending",
          },
          {
            subdistrict_id: "5147",
            subdistrict_name: "Kota Anyar",
          },
          {
            subdistrict_id: "5148",
            subdistrict_name: "Kraksaan",
          },
          {
            subdistrict_id: "5149",
            subdistrict_name: "Krejengan",
          },
          {
            subdistrict_id: "5150",
            subdistrict_name: "Krucil",
          },
          {
            subdistrict_id: "5151",
            subdistrict_name: "Kuripan",
          },
          {
            subdistrict_id: "5152",
            subdistrict_name: "Leces",
          },
          {
            subdistrict_id: "5153",
            subdistrict_name: "Lumbang",
          },
          {
            subdistrict_id: "5154",
            subdistrict_name: "Maron",
          },
          {
            subdistrict_id: "5155",
            subdistrict_name: "Paiton",
          },
          {
            subdistrict_id: "5156",
            subdistrict_name: "Pajarakan",
          },
          {
            subdistrict_id: "5157",
            subdistrict_name: "Pakuniran",
          },
          {
            subdistrict_id: "5158",
            subdistrict_name: "Sukapura",
          },
          {
            subdistrict_id: "5159",
            subdistrict_name: "Sumber",
          },
          {
            subdistrict_id: "5160",
            subdistrict_name: "Sumberasih",
          },
          {
            subdistrict_id: "5161",
            subdistrict_name: "Tegal Siwalan",
          },
          {
            subdistrict_id: "5162",
            subdistrict_name: "Tiris",
          },
          {
            subdistrict_id: "5163",
            subdistrict_name: "Tongas",
          },
          {
            subdistrict_id: "5164",
            subdistrict_name: "Wonomerto",
          },
        ],
      },
      {
        city_id: "370",
        type: "Kota",
        city_name: "Probolinggo",
        subdistricts: [
          {
            subdistrict_id: "5165",
            subdistrict_name: "Kademangan",
          },
          {
            subdistrict_id: "5166",
            subdistrict_name: "Kanigaran",
          },
          {
            subdistrict_id: "5167",
            subdistrict_name: "Kedopok (Kedopak)",
          },
          {
            subdistrict_id: "5168",
            subdistrict_name: "Mayangan",
          },
          {
            subdistrict_id: "5169",
            subdistrict_name: "Wonoasih",
          },
        ],
      },
      {
        city_id: "390",
        type: "Kabupaten",
        city_name: "Sampang",
        subdistricts: [
          {
            subdistrict_id: "5394",
            subdistrict_name: "Banyuates",
          },
          {
            subdistrict_id: "5395",
            subdistrict_name: "Camplong",
          },
          {
            subdistrict_id: "5396",
            subdistrict_name: "Jrengik",
          },
          {
            subdistrict_id: "5397",
            subdistrict_name: "Karang Penang",
          },
          {
            subdistrict_id: "5398",
            subdistrict_name: "Kedungdung",
          },
          {
            subdistrict_id: "5399",
            subdistrict_name: "Ketapang",
          },
          {
            subdistrict_id: "5400",
            subdistrict_name: "Omben",
          },
          {
            subdistrict_id: "5401",
            subdistrict_name: "Pangarengan",
          },
          {
            subdistrict_id: "5402",
            subdistrict_name: "Robatal",
          },
          {
            subdistrict_id: "5403",
            subdistrict_name: "Sampang",
          },
          {
            subdistrict_id: "5404",
            subdistrict_name: "Sokobanah",
          },
          {
            subdistrict_id: "5405",
            subdistrict_name: "Sreseh",
          },
          {
            subdistrict_id: "5406",
            subdistrict_name: "Tambelangan",
          },
          {
            subdistrict_id: "5407",
            subdistrict_name: "Torjun",
          },
        ],
      },
      {
        city_id: "409",
        type: "Kabupaten",
        city_name: "Sidoarjo",
        subdistricts: [
          {
            subdistrict_id: "5631",
            subdistrict_name: "Balongbendo",
          },
          {
            subdistrict_id: "5632",
            subdistrict_name: "Buduran",
          },
          {
            subdistrict_id: "5633",
            subdistrict_name: "Candi",
          },
          {
            subdistrict_id: "5634",
            subdistrict_name: "Gedangan",
          },
          {
            subdistrict_id: "5635",
            subdistrict_name: "Jabon",
          },
          {
            subdistrict_id: "5636",
            subdistrict_name: "Krembung",
          },
          {
            subdistrict_id: "5637",
            subdistrict_name: "Krian",
          },
          {
            subdistrict_id: "5638",
            subdistrict_name: "Porong",
          },
          {
            subdistrict_id: "5639",
            subdistrict_name: "Prambon",
          },
          {
            subdistrict_id: "5640",
            subdistrict_name: "Sedati",
          },
          {
            subdistrict_id: "5641",
            subdistrict_name: "Sidoarjo",
          },
          {
            subdistrict_id: "5642",
            subdistrict_name: "Sukodono",
          },
          {
            subdistrict_id: "5643",
            subdistrict_name: "Taman",
          },
          {
            subdistrict_id: "5644",
            subdistrict_name: "Tanggulangin",
          },
          {
            subdistrict_id: "5645",
            subdistrict_name: "Tarik",
          },
          {
            subdistrict_id: "5646",
            subdistrict_name: "Tulangan",
          },
          {
            subdistrict_id: "5647",
            subdistrict_name: "Waru",
          },
          {
            subdistrict_id: "5648",
            subdistrict_name: "Wonoayu",
          },
        ],
      },
      {
        city_id: "418",
        type: "Kabupaten",
        city_name: "Situbondo",
        subdistricts: [
          {
            subdistrict_id: "5762",
            subdistrict_name: "Arjasa",
          },
          {
            subdistrict_id: "5763",
            subdistrict_name: "Asembagus",
          },
          {
            subdistrict_id: "5764",
            subdistrict_name: "Banyuglugur",
          },
          {
            subdistrict_id: "5765",
            subdistrict_name: "Banyuputih",
          },
          {
            subdistrict_id: "5766",
            subdistrict_name: "Besuki",
          },
          {
            subdistrict_id: "5767",
            subdistrict_name: "Bungatan",
          },
          {
            subdistrict_id: "5768",
            subdistrict_name: "Jangkar",
          },
          {
            subdistrict_id: "5769",
            subdistrict_name: "Jatibanteng",
          },
          {
            subdistrict_id: "5770",
            subdistrict_name: "Kapongan",
          },
          {
            subdistrict_id: "5771",
            subdistrict_name: "Kendit",
          },
          {
            subdistrict_id: "5772",
            subdistrict_name: "Mangaran",
          },
          {
            subdistrict_id: "5773",
            subdistrict_name: "Mlandingan",
          },
          {
            subdistrict_id: "5774",
            subdistrict_name: "Panarukan",
          },
          {
            subdistrict_id: "5775",
            subdistrict_name: "Panji",
          },
          {
            subdistrict_id: "5776",
            subdistrict_name: "Situbondo",
          },
          {
            subdistrict_id: "5777",
            subdistrict_name: "Suboh",
          },
          {
            subdistrict_id: "5778",
            subdistrict_name: "Sumbermalang",
          },
        ],
      },
      {
        city_id: "441",
        type: "Kabupaten",
        city_name: "Sumenep",
        subdistricts: [
          {
            subdistrict_id: "6091",
            subdistrict_name: "Ambunten",
          },
          {
            subdistrict_id: "6092",
            subdistrict_name: "Arjasa",
          },
          {
            subdistrict_id: "6093",
            subdistrict_name: "Batang Batang",
          },
          {
            subdistrict_id: "6094",
            subdistrict_name: "Batuan",
          },
          {
            subdistrict_id: "6095",
            subdistrict_name: "Batuputih",
          },
          {
            subdistrict_id: "6096",
            subdistrict_name: "Bluto",
          },
          {
            subdistrict_id: "6097",
            subdistrict_name: "Dasuk",
          },
          {
            subdistrict_id: "6098",
            subdistrict_name: "Dungkek",
          },
          {
            subdistrict_id: "6099",
            subdistrict_name: "Ganding",
          },
          {
            subdistrict_id: "6100",
            subdistrict_name: "Gapura",
          },
          {
            subdistrict_id: "6101",
            subdistrict_name: "Gayam",
          },
          {
            subdistrict_id: "6102",
            subdistrict_name: "Gili Ginting (Giligenteng)",
          },
          {
            subdistrict_id: "6103",
            subdistrict_name: "Guluk Guluk",
          },
          {
            subdistrict_id: "6104",
            subdistrict_name: "Kalianget",
          },
          {
            subdistrict_id: "6105",
            subdistrict_name: "Kangayan",
          },
          {
            subdistrict_id: "6106",
            subdistrict_name: "Kota Sumenep",
          },
          {
            subdistrict_id: "6107",
            subdistrict_name: "Lenteng",
          },
          {
            subdistrict_id: "6108",
            subdistrict_name: "Manding",
          },
          {
            subdistrict_id: "6109",
            subdistrict_name: "Masalembu",
          },
          {
            subdistrict_id: "6110",
            subdistrict_name: "Nonggunong",
          },
          {
            subdistrict_id: "6111",
            subdistrict_name: "Pasongsongan",
          },
          {
            subdistrict_id: "6112",
            subdistrict_name: "Pragaan",
          },
          {
            subdistrict_id: "6113",
            subdistrict_name: "Ra'as (Raas)",
          },
          {
            subdistrict_id: "6114",
            subdistrict_name: "Rubaru",
          },
          {
            subdistrict_id: "6115",
            subdistrict_name: "Sapeken",
          },
          {
            subdistrict_id: "6116",
            subdistrict_name: "Saronggi",
          },
          {
            subdistrict_id: "6117",
            subdistrict_name: "Talango",
          },
        ],
      },
      {
        city_id: "444",
        type: "Kota",
        city_name: "Surabaya",
        subdistricts: [
          {
            subdistrict_id: "6131",
            subdistrict_name: "Asemrowo",
          },
          {
            subdistrict_id: "6132",
            subdistrict_name: "Benowo",
          },
          {
            subdistrict_id: "6133",
            subdistrict_name: "Bubutan",
          },
          {
            subdistrict_id: "6134",
            subdistrict_name: "Bulak",
          },
          {
            subdistrict_id: "6135",
            subdistrict_name: "Dukuh Pakis",
          },
          {
            subdistrict_id: "6136",
            subdistrict_name: "Gayungan",
          },
          {
            subdistrict_id: "6137",
            subdistrict_name: "Genteng",
          },
          {
            subdistrict_id: "6138",
            subdistrict_name: "Gubeng",
          },
          {
            subdistrict_id: "6139",
            subdistrict_name: "Gununganyar",
          },
          {
            subdistrict_id: "6140",
            subdistrict_name: "Jambangan",
          },
          {
            subdistrict_id: "6141",
            subdistrict_name: "Karangpilang",
          },
          {
            subdistrict_id: "6142",
            subdistrict_name: "Kenjeran",
          },
          {
            subdistrict_id: "6143",
            subdistrict_name: "Krembangan",
          },
          {
            subdistrict_id: "6144",
            subdistrict_name: "Lakar Santri",
          },
          {
            subdistrict_id: "6145",
            subdistrict_name: "Mulyorejo",
          },
          {
            subdistrict_id: "6146",
            subdistrict_name: "Pabean Cantikan",
          },
          {
            subdistrict_id: "6147",
            subdistrict_name: "Pakal",
          },
          {
            subdistrict_id: "6148",
            subdistrict_name: "Rungkut",
          },
          {
            subdistrict_id: "6149",
            subdistrict_name: "Sambikerep",
          },
          {
            subdistrict_id: "6150",
            subdistrict_name: "Sawahan",
          },
          {
            subdistrict_id: "6151",
            subdistrict_name: "Semampir",
          },
          {
            subdistrict_id: "6152",
            subdistrict_name: "Simokerto",
          },
          {
            subdistrict_id: "6153",
            subdistrict_name: "Sukolilo",
          },
          {
            subdistrict_id: "6154",
            subdistrict_name: "Sukomanunggal",
          },
          {
            subdistrict_id: "6155",
            subdistrict_name: "Tambaksari",
          },
          {
            subdistrict_id: "6156",
            subdistrict_name: "Tandes",
          },
          {
            subdistrict_id: "6157",
            subdistrict_name: "Tegalsari",
          },
          {
            subdistrict_id: "6158",
            subdistrict_name: "Tenggilis Mejoyo",
          },
          {
            subdistrict_id: "6159",
            subdistrict_name: "Wiyung",
          },
          {
            subdistrict_id: "6160",
            subdistrict_name: "Wonocolo",
          },
          {
            subdistrict_id: "6161",
            subdistrict_name: "Wonokromo",
          },
        ],
      },
      {
        city_id: "487",
        type: "Kabupaten",
        city_name: "Trenggalek",
        subdistricts: [
          {
            subdistrict_id: "6759",
            subdistrict_name: "Bendungan",
          },
          {
            subdistrict_id: "6760",
            subdistrict_name: "Dongko",
          },
          {
            subdistrict_id: "6761",
            subdistrict_name: "Durenan",
          },
          {
            subdistrict_id: "6762",
            subdistrict_name: "Gandusari",
          },
          {
            subdistrict_id: "6763",
            subdistrict_name: "Kampak",
          },
          {
            subdistrict_id: "6764",
            subdistrict_name: "Karangan",
          },
          {
            subdistrict_id: "6765",
            subdistrict_name: "Munjungan",
          },
          {
            subdistrict_id: "6766",
            subdistrict_name: "Panggul",
          },
          {
            subdistrict_id: "6767",
            subdistrict_name: "Pogalan",
          },
          {
            subdistrict_id: "6768",
            subdistrict_name: "Pule",
          },
          {
            subdistrict_id: "6769",
            subdistrict_name: "Suruh",
          },
          {
            subdistrict_id: "6770",
            subdistrict_name: "Trenggalek",
          },
          {
            subdistrict_id: "6771",
            subdistrict_name: "Tugu",
          },
          {
            subdistrict_id: "6772",
            subdistrict_name: "Watulimo",
          },
        ],
      },
      {
        city_id: "489",
        type: "Kabupaten",
        city_name: "Tuban",
        subdistricts: [
          {
            subdistrict_id: "6778",
            subdistrict_name: "Bancar",
          },
          {
            subdistrict_id: "6779",
            subdistrict_name: "Bangilan",
          },
          {
            subdistrict_id: "6780",
            subdistrict_name: "Grabagan",
          },
          {
            subdistrict_id: "6781",
            subdistrict_name: "Jatirogo",
          },
          {
            subdistrict_id: "6782",
            subdistrict_name: "Jenu",
          },
          {
            subdistrict_id: "6783",
            subdistrict_name: "Kenduruan",
          },
          {
            subdistrict_id: "6784",
            subdistrict_name: "Kerek",
          },
          {
            subdistrict_id: "6785",
            subdistrict_name: "Merakurak",
          },
          {
            subdistrict_id: "6786",
            subdistrict_name: "Montong",
          },
          {
            subdistrict_id: "6787",
            subdistrict_name: "Palang",
          },
          {
            subdistrict_id: "6788",
            subdistrict_name: "Parengan",
          },
          {
            subdistrict_id: "6789",
            subdistrict_name: "Plumpang",
          },
          {
            subdistrict_id: "6790",
            subdistrict_name: "Rengel",
          },
          {
            subdistrict_id: "6791",
            subdistrict_name: "Semanding",
          },
          {
            subdistrict_id: "6792",
            subdistrict_name: "Senori",
          },
          {
            subdistrict_id: "6793",
            subdistrict_name: "Singgahan",
          },
          {
            subdistrict_id: "6794",
            subdistrict_name: "Soko",
          },
          {
            subdistrict_id: "6795",
            subdistrict_name: "Tambakboyo",
          },
          {
            subdistrict_id: "6796",
            subdistrict_name: "Tuban",
          },
          {
            subdistrict_id: "6797",
            subdistrict_name: "Widang",
          },
        ],
      },
      {
        city_id: "492",
        type: "Kabupaten",
        city_name: "Tulungagung",
        subdistricts: [
          {
            subdistrict_id: "6821",
            subdistrict_name: "Bandung",
          },
          {
            subdistrict_id: "6822",
            subdistrict_name: "Besuki",
          },
          {
            subdistrict_id: "6823",
            subdistrict_name: "Boyolangu",
          },
          {
            subdistrict_id: "6824",
            subdistrict_name: "Campur Darat",
          },
          {
            subdistrict_id: "6825",
            subdistrict_name: "Gondang",
          },
          {
            subdistrict_id: "6826",
            subdistrict_name: "Kalidawir",
          },
          {
            subdistrict_id: "6827",
            subdistrict_name: "Karang Rejo",
          },
          {
            subdistrict_id: "6828",
            subdistrict_name: "Kauman",
          },
          {
            subdistrict_id: "6829",
            subdistrict_name: "Kedungwaru",
          },
          {
            subdistrict_id: "6830",
            subdistrict_name: "Ngantru",
          },
          {
            subdistrict_id: "6831",
            subdistrict_name: "Ngunut",
          },
          {
            subdistrict_id: "6832",
            subdistrict_name: "Pagerwojo",
          },
          {
            subdistrict_id: "6833",
            subdistrict_name: "Pakel",
          },
          {
            subdistrict_id: "6834",
            subdistrict_name: "Pucanglaban",
          },
          {
            subdistrict_id: "6835",
            subdistrict_name: "Rejotangan",
          },
          {
            subdistrict_id: "6836",
            subdistrict_name: "Sendang",
          },
          {
            subdistrict_id: "6837",
            subdistrict_name: "Sumbergempol",
          },
          {
            subdistrict_id: "6838",
            subdistrict_name: "Tanggung Gunung",
          },
          {
            subdistrict_id: "6839",
            subdistrict_name: "Tulungagung",
          },
        ],
      },
    ],
  },
  {
    province_id: "12",
    province: "Kalimantan Barat",
    cities: [
      {
        city_id: "61",
        type: "Kabupaten",
        city_name: "Bengkayang",
        subdistricts: [
          {
            subdistrict_id: "815",
            subdistrict_name: "Bengkayang",
          },
          {
            subdistrict_id: "816",
            subdistrict_name: "Capkala",
          },
          {
            subdistrict_id: "817",
            subdistrict_name: "Jagoi Babang",
          },
          {
            subdistrict_id: "818",
            subdistrict_name: "Ledo",
          },
          {
            subdistrict_id: "819",
            subdistrict_name: "Lembah Bawang",
          },
          {
            subdistrict_id: "820",
            subdistrict_name: "Lumar",
          },
          {
            subdistrict_id: "821",
            subdistrict_name: "Monterado",
          },
          {
            subdistrict_id: "822",
            subdistrict_name: "Samalantan",
          },
          {
            subdistrict_id: "823",
            subdistrict_name: "Sanggau Ledo",
          },
          {
            subdistrict_id: "824",
            subdistrict_name: "Seluas",
          },
          {
            subdistrict_id: "825",
            subdistrict_name: "Siding",
          },
          {
            subdistrict_id: "826",
            subdistrict_name: "Sungai Betung",
          },
          {
            subdistrict_id: "827",
            subdistrict_name: "Sungai Raya",
          },
          {
            subdistrict_id: "828",
            subdistrict_name: "Sungai Raya Kepulauan",
          },
          {
            subdistrict_id: "829",
            subdistrict_name: "Suti Semarang",
          },
          {
            subdistrict_id: "830",
            subdistrict_name: "Teriak",
          },
          {
            subdistrict_id: "831",
            subdistrict_name: "Tujuh Belas",
          },
        ],
      },
      {
        city_id: "168",
        type: "Kabupaten",
        city_name: "Kapuas Hulu",
        subdistricts: [
          {
            subdistrict_id: "2330",
            subdistrict_name: "Badau",
          },
          {
            subdistrict_id: "2331",
            subdistrict_name: "Batang Lupar",
          },
          {
            subdistrict_id: "2332",
            subdistrict_name: "Bika",
          },
          {
            subdistrict_id: "2333",
            subdistrict_name: "Boyan Tanjung",
          },
          {
            subdistrict_id: "2334",
            subdistrict_name: "Bunut Hilir",
          },
          {
            subdistrict_id: "2335",
            subdistrict_name: "Bunut Hulu",
          },
          {
            subdistrict_id: "2336",
            subdistrict_name: "Embaloh Hilir",
          },
          {
            subdistrict_id: "2337",
            subdistrict_name: "Embaloh Hulu",
          },
          {
            subdistrict_id: "2338",
            subdistrict_name: "Empanang",
          },
          {
            subdistrict_id: "2339",
            subdistrict_name: "Hulu Gurung",
          },
          {
            subdistrict_id: "2340",
            subdistrict_name: "Jongkong (Jengkong)",
          },
          {
            subdistrict_id: "2341",
            subdistrict_name: "Kalis",
          },
          {
            subdistrict_id: "2342",
            subdistrict_name: "Mentebah",
          },
          {
            subdistrict_id: "2343",
            subdistrict_name: "Pengkadan (Batu Datu)",
          },
          {
            subdistrict_id: "2344",
            subdistrict_name: "Puring Kencana",
          },
          {
            subdistrict_id: "2345",
            subdistrict_name: "Putussibau Selatan",
          },
          {
            subdistrict_id: "2346",
            subdistrict_name: "Putussibau Utara",
          },
          {
            subdistrict_id: "2347",
            subdistrict_name: "Seberuang",
          },
          {
            subdistrict_id: "2348",
            subdistrict_name: "Selimbau",
          },
          {
            subdistrict_id: "2349",
            subdistrict_name: "Semitau",
          },
          {
            subdistrict_id: "2350",
            subdistrict_name: "Silat Hilir",
          },
          {
            subdistrict_id: "2351",
            subdistrict_name: "Silat Hulu",
          },
          {
            subdistrict_id: "2352",
            subdistrict_name: "Suhaid",
          },
        ],
      },
      {
        city_id: "176",
        type: "Kabupaten",
        city_name: "Kayong Utara",
        subdistricts: [
          {
            subdistrict_id: "2465",
            subdistrict_name: "Kepulauan Karimata",
          },
          {
            subdistrict_id: "2466",
            subdistrict_name: "Pulau Maya (Pulau Maya Karimata)",
          },
          {
            subdistrict_id: "2467",
            subdistrict_name: "Seponti",
          },
          {
            subdistrict_id: "2468",
            subdistrict_name: "Simpang Hilir",
          },
          {
            subdistrict_id: "2469",
            subdistrict_name: "Sukadana",
          },
          {
            subdistrict_id: "2470",
            subdistrict_name: "Teluk Batang",
          },
        ],
      },
      {
        city_id: "195",
        type: "Kabupaten",
        city_name: "Ketapang",
        subdistricts: [
          {
            subdistrict_id: "2702",
            subdistrict_name: "Air Upas",
          },
          {
            subdistrict_id: "2703",
            subdistrict_name: "Benua Kayong",
          },
          {
            subdistrict_id: "2704",
            subdistrict_name: "Delta Pawan",
          },
          {
            subdistrict_id: "2705",
            subdistrict_name: "Hulu Sungai",
          },
          {
            subdistrict_id: "2706",
            subdistrict_name: "Jelai Hulu",
          },
          {
            subdistrict_id: "2707",
            subdistrict_name: "Kendawangan",
          },
          {
            subdistrict_id: "2708",
            subdistrict_name: "Manis Mata",
          },
          {
            subdistrict_id: "2709",
            subdistrict_name: "Marau",
          },
          {
            subdistrict_id: "2710",
            subdistrict_name: "Matan Hilir Selatan",
          },
          {
            subdistrict_id: "2711",
            subdistrict_name: "Matan Hilir Utara",
          },
          {
            subdistrict_id: "2712",
            subdistrict_name: "Muara Pawan",
          },
          {
            subdistrict_id: "2713",
            subdistrict_name: "Nanga Tayap",
          },
          {
            subdistrict_id: "2714",
            subdistrict_name: "Pemahan",
          },
          {
            subdistrict_id: "2715",
            subdistrict_name: "Sandai",
          },
          {
            subdistrict_id: "2716",
            subdistrict_name: "Simpang Dua",
          },
          {
            subdistrict_id: "2717",
            subdistrict_name: "Simpang Hulu",
          },
          {
            subdistrict_id: "2718",
            subdistrict_name: "Singkup",
          },
          {
            subdistrict_id: "2719",
            subdistrict_name: "Sungai Laur",
          },
          {
            subdistrict_id: "2720",
            subdistrict_name: "Sungai Melayu Rayak",
          },
          {
            subdistrict_id: "2721",
            subdistrict_name: "Tumbang Titi",
          },
        ],
      },
      {
        city_id: "208",
        type: "Kabupaten",
        city_name: "Kubu Raya",
        subdistricts: [
          {
            subdistrict_id: "2912",
            subdistrict_name: "Batu Ampar",
          },
          {
            subdistrict_id: "2913",
            subdistrict_name: "Kuala Mandor-B",
          },
          {
            subdistrict_id: "2914",
            subdistrict_name: "Kubu",
          },
          {
            subdistrict_id: "2915",
            subdistrict_name: "Rasau Jaya",
          },
          {
            subdistrict_id: "2916",
            subdistrict_name: "Sei/Sungai Ambawang",
          },
          {
            subdistrict_id: "2917",
            subdistrict_name: "Sei/Sungai Kakap",
          },
          {
            subdistrict_id: "2918",
            subdistrict_name: "Sei/Sungai Raya",
          },
          {
            subdistrict_id: "2919",
            subdistrict_name: "Teluk/Telok Pakedai",
          },
          {
            subdistrict_id: "2920",
            subdistrict_name: "Terentang",
          },
        ],
      },
      {
        city_id: "228",
        type: "Kabupaten",
        city_name: "Landak",
        subdistricts: [
          {
            subdistrict_id: "3247",
            subdistrict_name: "Air Besar",
          },
          {
            subdistrict_id: "3248",
            subdistrict_name: "Banyuke Hulu",
          },
          {
            subdistrict_id: "3249",
            subdistrict_name: "Jelimpo",
          },
          {
            subdistrict_id: "3250",
            subdistrict_name: "Kuala Behe",
          },
          {
            subdistrict_id: "3251",
            subdistrict_name: "Mandor",
          },
          {
            subdistrict_id: "3252",
            subdistrict_name: "Mempawah Hulu",
          },
          {
            subdistrict_id: "3253",
            subdistrict_name: "Menjalin",
          },
          {
            subdistrict_id: "3254",
            subdistrict_name: "Menyuke",
          },
          {
            subdistrict_id: "3255",
            subdistrict_name: "Meranti",
          },
          {
            subdistrict_id: "3256",
            subdistrict_name: "Ngabang",
          },
          {
            subdistrict_id: "3257",
            subdistrict_name: "Sebangki",
          },
          {
            subdistrict_id: "3258",
            subdistrict_name: "Sengah Temila",
          },
          {
            subdistrict_id: "3259",
            subdistrict_name: "Sompak",
          },
        ],
      },
      {
        city_id: "279",
        type: "Kabupaten",
        city_name: "Melawi",
        subdistricts: [
          {
            subdistrict_id: "3927",
            subdistrict_name: "Belimbing",
          },
          {
            subdistrict_id: "3928",
            subdistrict_name: "Belimbing Hulu",
          },
          {
            subdistrict_id: "3929",
            subdistrict_name: "Ella Hilir",
          },
          {
            subdistrict_id: "3930",
            subdistrict_name: "Menukung",
          },
          {
            subdistrict_id: "3931",
            subdistrict_name: "Nanga Pinoh",
          },
          {
            subdistrict_id: "3932",
            subdistrict_name: "Pinoh Selatan",
          },
          {
            subdistrict_id: "3933",
            subdistrict_name: "Pinoh Utara",
          },
          {
            subdistrict_id: "3934",
            subdistrict_name: "Sayan",
          },
          {
            subdistrict_id: "3935",
            subdistrict_name: "Sokan",
          },
          {
            subdistrict_id: "3936",
            subdistrict_name: "Tanah Pinoh",
          },
          {
            subdistrict_id: "3937",
            subdistrict_name: "Tanah Pinoh Barat",
          },
        ],
      },
      {
        city_id: "364",
        type: "Kabupaten",
        city_name: "Pontianak",
        subdistricts: [
          {
            subdistrict_id: "5092",
            subdistrict_name: "Anjongan",
          },
          {
            subdistrict_id: "5093",
            subdistrict_name: "Mempawah Hilir",
          },
          {
            subdistrict_id: "5094",
            subdistrict_name: "Mempawah Timur",
          },
          {
            subdistrict_id: "5095",
            subdistrict_name: "Sadaniang",
          },
          {
            subdistrict_id: "5096",
            subdistrict_name: "Segedong",
          },
          {
            subdistrict_id: "5097",
            subdistrict_name: "Sei/Sungai Kunyit",
          },
          {
            subdistrict_id: "5098",
            subdistrict_name: "Sei/Sungai Pinyuh",
          },
          {
            subdistrict_id: "5099",
            subdistrict_name: "Siantan",
          },
          {
            subdistrict_id: "5100",
            subdistrict_name: "Toho",
          },
        ],
      },
      {
        city_id: "365",
        type: "Kota",
        city_name: "Pontianak",
        subdistricts: [
          {
            subdistrict_id: "5101",
            subdistrict_name: "Pontianak Barat",
          },
          {
            subdistrict_id: "5102",
            subdistrict_name: "Pontianak Kota",
          },
          {
            subdistrict_id: "5103",
            subdistrict_name: "Pontianak Selatan",
          },
          {
            subdistrict_id: "5104",
            subdistrict_name: "Pontianak Tenggara",
          },
          {
            subdistrict_id: "5105",
            subdistrict_name: "Pontianak Timur",
          },
          {
            subdistrict_id: "5106",
            subdistrict_name: "Pontianak Utara",
          },
        ],
      },
      {
        city_id: "388",
        type: "Kabupaten",
        city_name: "Sambas",
        subdistricts: [
          {
            subdistrict_id: "5366",
            subdistrict_name: "Galing",
          },
          {
            subdistrict_id: "5367",
            subdistrict_name: "Jawai",
          },
          {
            subdistrict_id: "5368",
            subdistrict_name: "Jawai Selatan",
          },
          {
            subdistrict_id: "5369",
            subdistrict_name: "Paloh",
          },
          {
            subdistrict_id: "5370",
            subdistrict_name: "Pemangkat",
          },
          {
            subdistrict_id: "5371",
            subdistrict_name: "Sajad",
          },
          {
            subdistrict_id: "5372",
            subdistrict_name: "Sajingan Besar",
          },
          {
            subdistrict_id: "5373",
            subdistrict_name: "Salatiga",
          },
          {
            subdistrict_id: "5374",
            subdistrict_name: "Sambas",
          },
          {
            subdistrict_id: "5375",
            subdistrict_name: "Sebawi",
          },
          {
            subdistrict_id: "5376",
            subdistrict_name: "Sejangkung",
          },
          {
            subdistrict_id: "5377",
            subdistrict_name: "Selakau",
          },
          {
            subdistrict_id: "5378",
            subdistrict_name: "Selakau Timur",
          },
          {
            subdistrict_id: "5379",
            subdistrict_name: "Semparuk",
          },
          {
            subdistrict_id: "5380",
            subdistrict_name: "Subah",
          },
          {
            subdistrict_id: "5381",
            subdistrict_name: "Tangaran",
          },
          {
            subdistrict_id: "5382",
            subdistrict_name: "Tebas",
          },
          {
            subdistrict_id: "5383",
            subdistrict_name: "Tekarang",
          },
          {
            subdistrict_id: "5384",
            subdistrict_name: "Teluk Keramat",
          },
        ],
      },
      {
        city_id: "391",
        type: "Kabupaten",
        city_name: "Sanggau",
        subdistricts: [
          {
            subdistrict_id: "5408",
            subdistrict_name: "Balai",
          },
          {
            subdistrict_id: "5409",
            subdistrict_name: "Beduai (Beduwai)",
          },
          {
            subdistrict_id: "5410",
            subdistrict_name: "Bonti",
          },
          {
            subdistrict_id: "5411",
            subdistrict_name: "Entikong",
          },
          {
            subdistrict_id: "5412",
            subdistrict_name: "Jangkang",
          },
          {
            subdistrict_id: "5413",
            subdistrict_name: "Kapuas (Sanggau Kapuas)",
          },
          {
            subdistrict_id: "5414",
            subdistrict_name: "Kembayan",
          },
          {
            subdistrict_id: "5415",
            subdistrict_name: "Meliau",
          },
          {
            subdistrict_id: "5416",
            subdistrict_name: "Mukok",
          },
          {
            subdistrict_id: "5417",
            subdistrict_name: "Noyan",
          },
          {
            subdistrict_id: "5418",
            subdistrict_name: "Parindu",
          },
          {
            subdistrict_id: "5419",
            subdistrict_name: "Sekayam",
          },
          {
            subdistrict_id: "5420",
            subdistrict_name: "Tayan Hilir",
          },
          {
            subdistrict_id: "5421",
            subdistrict_name: "Tayan Hulu",
          },
          {
            subdistrict_id: "5422",
            subdistrict_name: "Toba",
          },
        ],
      },
      {
        city_id: "395",
        type: "Kabupaten",
        city_name: "Sekadau",
        subdistricts: [
          {
            subdistrict_id: "5447",
            subdistrict_name: "Belitang",
          },
          {
            subdistrict_id: "5448",
            subdistrict_name: "Belitang Hilir",
          },
          {
            subdistrict_id: "5449",
            subdistrict_name: "Belitang Hulu",
          },
          {
            subdistrict_id: "5450",
            subdistrict_name: "Nanga Mahap",
          },
          {
            subdistrict_id: "5451",
            subdistrict_name: "Nanga Taman",
          },
          {
            subdistrict_id: "5452",
            subdistrict_name: "Sekadau Hilir",
          },
          {
            subdistrict_id: "5453",
            subdistrict_name: "Sekadau Hulu",
          },
        ],
      },
      {
        city_id: "415",
        type: "Kota",
        city_name: "Singkawang",
        subdistricts: [
          {
            subdistrict_id: "5734",
            subdistrict_name: "Singkawang Barat",
          },
          {
            subdistrict_id: "5735",
            subdistrict_name: "Singkawang Selatan",
          },
          {
            subdistrict_id: "5736",
            subdistrict_name: "Singkawang Tengah",
          },
          {
            subdistrict_id: "5737",
            subdistrict_name: "Singkawang Timur",
          },
          {
            subdistrict_id: "5738",
            subdistrict_name: "Singkawang Utara",
          },
        ],
      },
      {
        city_id: "417",
        type: "Kabupaten",
        city_name: "Sintang",
        subdistricts: [
          {
            subdistrict_id: "5748",
            subdistrict_name: "Ambalau",
          },
          {
            subdistrict_id: "5749",
            subdistrict_name: "Binjai Hulu",
          },
          {
            subdistrict_id: "5750",
            subdistrict_name: "Dedai",
          },
          {
            subdistrict_id: "5751",
            subdistrict_name: "Kayan Hilir",
          },
          {
            subdistrict_id: "5752",
            subdistrict_name: "Kayan Hulu",
          },
          {
            subdistrict_id: "5753",
            subdistrict_name: "Kelam Permai",
          },
          {
            subdistrict_id: "5754",
            subdistrict_name: "Ketungau Hilir",
          },
          {
            subdistrict_id: "5755",
            subdistrict_name: "Ketungau Hulu",
          },
          {
            subdistrict_id: "5756",
            subdistrict_name: "Ketungau Tengah",
          },
          {
            subdistrict_id: "5757",
            subdistrict_name: "Sepauk",
          },
          {
            subdistrict_id: "5758",
            subdistrict_name: "Serawai (Nanga Serawai)",
          },
          {
            subdistrict_id: "5759",
            subdistrict_name: "Sintang",
          },
          {
            subdistrict_id: "5760",
            subdistrict_name: "Sungai Tebelian",
          },
          {
            subdistrict_id: "5761",
            subdistrict_name: "Tempunak",
          },
        ],
      },
    ],
  },
  {
    province_id: "13",
    province: "Kalimantan Selatan",
    cities: [
      {
        city_id: "18",
        type: "Kabupaten",
        city_name: "Balangan",
        subdistricts: [
          {
            subdistrict_id: "264",
            subdistrict_name: "Awayan",
          },
          {
            subdistrict_id: "265",
            subdistrict_name: "Batu Mandi",
          },
          {
            subdistrict_id: "266",
            subdistrict_name: "Halong",
          },
          {
            subdistrict_id: "267",
            subdistrict_name: "Juai",
          },
          {
            subdistrict_id: "268",
            subdistrict_name: "Lampihong",
          },
          {
            subdistrict_id: "269",
            subdistrict_name: "Paringin",
          },
          {
            subdistrict_id: "270",
            subdistrict_name: "Paringin Selatan",
          },
          {
            subdistrict_id: "271",
            subdistrict_name: "Tebing Tinggi",
          },
        ],
      },
      {
        city_id: "33",
        type: "Kabupaten",
        city_name: "Banjar",
        subdistricts: [
          {
            subdistrict_id: "476",
            subdistrict_name: "Aluh-Aluh",
          },
          {
            subdistrict_id: "477",
            subdistrict_name: "Aranio",
          },
          {
            subdistrict_id: "478",
            subdistrict_name: "Astambul",
          },
          {
            subdistrict_id: "479",
            subdistrict_name: "Beruntung Baru",
          },
          {
            subdistrict_id: "480",
            subdistrict_name: "Gambut",
          },
          {
            subdistrict_id: "481",
            subdistrict_name: "Karang Intan",
          },
          {
            subdistrict_id: "482",
            subdistrict_name: "Kertak Hanyar",
          },
          {
            subdistrict_id: "483",
            subdistrict_name: "Martapura Barat",
          },
          {
            subdistrict_id: "484",
            subdistrict_name: "Martapura Kota",
          },
          {
            subdistrict_id: "485",
            subdistrict_name: "Martapura Timur",
          },
          {
            subdistrict_id: "486",
            subdistrict_name: "Mataraman",
          },
          {
            subdistrict_id: "487",
            subdistrict_name: "Pengaron",
          },
          {
            subdistrict_id: "488",
            subdistrict_name: "Peramasan",
          },
          {
            subdistrict_id: "489",
            subdistrict_name: "Sambung Makmur",
          },
          {
            subdistrict_id: "490",
            subdistrict_name: "Sei/Sungai Pinang",
          },
          {
            subdistrict_id: "491",
            subdistrict_name: "Sei/Sungai Tabuk",
          },
          {
            subdistrict_id: "492",
            subdistrict_name: "Simpang Empat",
          },
          {
            subdistrict_id: "493",
            subdistrict_name: "Tatah Makmur",
          },
          {
            subdistrict_id: "494",
            subdistrict_name: "Telaga Bauntung",
          },
        ],
      },
      {
        city_id: "35",
        type: "Kota",
        city_name: "Banjarbaru",
        subdistricts: [
          {
            subdistrict_id: "499",
            subdistrict_name: "Banjar Baru Selatan",
          },
          {
            subdistrict_id: "500",
            subdistrict_name: "Banjar Baru Utara",
          },
          {
            subdistrict_id: "501",
            subdistrict_name: "Cempaka",
          },
          {
            subdistrict_id: "502",
            subdistrict_name: "Landasan Ulin",
          },
          {
            subdistrict_id: "503",
            subdistrict_name: "Liang Anggang",
          },
        ],
      },
      {
        city_id: "36",
        type: "Kota",
        city_name: "Banjarmasin",
        subdistricts: [
          {
            subdistrict_id: "504",
            subdistrict_name: "Banjarmasin Barat",
          },
          {
            subdistrict_id: "505",
            subdistrict_name: "Banjarmasin Selatan",
          },
          {
            subdistrict_id: "506",
            subdistrict_name: "Banjarmasin Tengah",
          },
          {
            subdistrict_id: "507",
            subdistrict_name: "Banjarmasin Timur",
          },
          {
            subdistrict_id: "508",
            subdistrict_name: "Banjarmasin Utara",
          },
        ],
      },
      {
        city_id: "43",
        type: "Kabupaten",
        city_name: "Barito Kuala",
        subdistricts: [
          {
            subdistrict_id: "624",
            subdistrict_name: "Alalak",
          },
          {
            subdistrict_id: "625",
            subdistrict_name: "Anjir Muara",
          },
          {
            subdistrict_id: "626",
            subdistrict_name: "Anjir Pasar",
          },
          {
            subdistrict_id: "627",
            subdistrict_name: "Bakumpai",
          },
          {
            subdistrict_id: "628",
            subdistrict_name: "Barambai",
          },
          {
            subdistrict_id: "629",
            subdistrict_name: "Belawang",
          },
          {
            subdistrict_id: "630",
            subdistrict_name: "Cerbon",
          },
          {
            subdistrict_id: "631",
            subdistrict_name: "Jejangkit",
          },
          {
            subdistrict_id: "632",
            subdistrict_name: "Kuripan",
          },
          {
            subdistrict_id: "633",
            subdistrict_name: "Mandastana",
          },
          {
            subdistrict_id: "634",
            subdistrict_name: "Marabahan",
          },
          {
            subdistrict_id: "635",
            subdistrict_name: "Mekar Sari",
          },
          {
            subdistrict_id: "636",
            subdistrict_name: "Rantau Badauh",
          },
          {
            subdistrict_id: "637",
            subdistrict_name: "Tabukan",
          },
          {
            subdistrict_id: "638",
            subdistrict_name: "Tabunganen",
          },
          {
            subdistrict_id: "639",
            subdistrict_name: "Tamban",
          },
          {
            subdistrict_id: "640",
            subdistrict_name: "Wanaraya",
          },
        ],
      },
      {
        city_id: "143",
        type: "Kabupaten",
        city_name: "Hulu Sungai Selatan",
        subdistricts: [
          {
            subdistrict_id: "1974",
            subdistrict_name: "Angkinang",
          },
          {
            subdistrict_id: "1975",
            subdistrict_name: "Daha Barat",
          },
          {
            subdistrict_id: "1976",
            subdistrict_name: "Daha Selatan",
          },
          {
            subdistrict_id: "1977",
            subdistrict_name: "Daha Utara",
          },
          {
            subdistrict_id: "1978",
            subdistrict_name: "Kalumpang (Kelumpang)",
          },
          {
            subdistrict_id: "1979",
            subdistrict_name: "Kandangan",
          },
          {
            subdistrict_id: "1980",
            subdistrict_name: "Loksado",
          },
          {
            subdistrict_id: "1981",
            subdistrict_name: "Padang Batung",
          },
          {
            subdistrict_id: "1982",
            subdistrict_name: "Simpur",
          },
          {
            subdistrict_id: "1983",
            subdistrict_name: "Sungai Raya",
          },
          {
            subdistrict_id: "1984",
            subdistrict_name: "Telaga Langsat",
          },
        ],
      },
      {
        city_id: "144",
        type: "Kabupaten",
        city_name: "Hulu Sungai Tengah",
        subdistricts: [
          {
            subdistrict_id: "1985",
            subdistrict_name: "Barabai",
          },
          {
            subdistrict_id: "1986",
            subdistrict_name: "Batang Alai Selatan",
          },
          {
            subdistrict_id: "1987",
            subdistrict_name: "Batang Alai Timur",
          },
          {
            subdistrict_id: "1988",
            subdistrict_name: "Batang Alai Utara",
          },
          {
            subdistrict_id: "1989",
            subdistrict_name: "Batu Benawa",
          },
          {
            subdistrict_id: "1990",
            subdistrict_name: "Hantakan",
          },
          {
            subdistrict_id: "1991",
            subdistrict_name: "Haruyan",
          },
          {
            subdistrict_id: "1992",
            subdistrict_name: "Labuan Amas Selatan",
          },
          {
            subdistrict_id: "1993",
            subdistrict_name: "Labuan Amas Utara",
          },
          {
            subdistrict_id: "1994",
            subdistrict_name: "Limpasu",
          },
          {
            subdistrict_id: "1995",
            subdistrict_name: "Pandawan",
          },
        ],
      },
      {
        city_id: "145",
        type: "Kabupaten",
        city_name: "Hulu Sungai Utara",
        subdistricts: [
          {
            subdistrict_id: "1996",
            subdistrict_name: "Amuntai Selatan",
          },
          {
            subdistrict_id: "1997",
            subdistrict_name: "Amuntai Tengah",
          },
          {
            subdistrict_id: "1998",
            subdistrict_name: "Amuntai Utara",
          },
          {
            subdistrict_id: "1999",
            subdistrict_name: "Babirik",
          },
          {
            subdistrict_id: "2000",
            subdistrict_name: "Banjang",
          },
          {
            subdistrict_id: "2001",
            subdistrict_name: "Danau Panggang",
          },
          {
            subdistrict_id: "2002",
            subdistrict_name: "Haur Gading",
          },
          {
            subdistrict_id: "2003",
            subdistrict_name: "Paminggir",
          },
          {
            subdistrict_id: "2004",
            subdistrict_name: "Sungai Pandan",
          },
          {
            subdistrict_id: "2005",
            subdistrict_name: "Sungai Tabukan",
          },
        ],
      },
      {
        city_id: "203",
        type: "Kabupaten",
        city_name: "Kotabaru",
        subdistricts: [
          {
            subdistrict_id: "2849",
            subdistrict_name: "Hampang",
          },
          {
            subdistrict_id: "2850",
            subdistrict_name: "Kelumpang Barat",
          },
          {
            subdistrict_id: "2851",
            subdistrict_name: "Kelumpang Hilir",
          },
          {
            subdistrict_id: "2852",
            subdistrict_name: "Kelumpang Hulu",
          },
          {
            subdistrict_id: "2853",
            subdistrict_name: "Kelumpang Selatan",
          },
          {
            subdistrict_id: "2854",
            subdistrict_name: "Kelumpang Tengah",
          },
          {
            subdistrict_id: "2855",
            subdistrict_name: "Kelumpang Utara",
          },
          {
            subdistrict_id: "2856",
            subdistrict_name: "Pamukan Barat",
          },
          {
            subdistrict_id: "2857",
            subdistrict_name: "Pamukan Selatan",
          },
          {
            subdistrict_id: "2858",
            subdistrict_name: "Pamukan Utara",
          },
          {
            subdistrict_id: "2859",
            subdistrict_name: "Pulau Laut Barat",
          },
          {
            subdistrict_id: "2860",
            subdistrict_name: "Pulau Laut Kepulauan",
          },
          {
            subdistrict_id: "2861",
            subdistrict_name: "Pulau Laut Selatan",
          },
          {
            subdistrict_id: "2862",
            subdistrict_name: "Pulau Laut Tanjung Selayar",
          },
          {
            subdistrict_id: "2863",
            subdistrict_name: "Pulau Laut Tengah",
          },
          {
            subdistrict_id: "2864",
            subdistrict_name: "Pulau Laut Timur",
          },
          {
            subdistrict_id: "2865",
            subdistrict_name: "Pulau Laut Utara",
          },
          {
            subdistrict_id: "2866",
            subdistrict_name: "Pulau Sebuku",
          },
          {
            subdistrict_id: "2867",
            subdistrict_name: "Pulau Sembilan",
          },
          {
            subdistrict_id: "2868",
            subdistrict_name: "Sampanahan",
          },
          {
            subdistrict_id: "2869",
            subdistrict_name: "Sungai Durian",
          },
        ],
      },
      {
        city_id: "446",
        type: "Kabupaten",
        city_name: "Tabalong",
        subdistricts: [
          {
            subdistrict_id: "6167",
            subdistrict_name: "Banua Lawas",
          },
          {
            subdistrict_id: "6168",
            subdistrict_name: "Bintang Ara",
          },
          {
            subdistrict_id: "6169",
            subdistrict_name: "Haruai",
          },
          {
            subdistrict_id: "6170",
            subdistrict_name: "Jaro",
          },
          {
            subdistrict_id: "6171",
            subdistrict_name: "Kelua (Klua)",
          },
          {
            subdistrict_id: "6172",
            subdistrict_name: "Muara Harus",
          },
          {
            subdistrict_id: "6173",
            subdistrict_name: "Muara Uya",
          },
          {
            subdistrict_id: "6174",
            subdistrict_name: "Murung Pudak",
          },
          {
            subdistrict_id: "6175",
            subdistrict_name: "Pugaan",
          },
          {
            subdistrict_id: "6176",
            subdistrict_name: "Tanjung",
          },
          {
            subdistrict_id: "6177",
            subdistrict_name: "Tanta",
          },
          {
            subdistrict_id: "6178",
            subdistrict_name: "Upau",
          },
        ],
      },
      {
        city_id: "452",
        type: "Kabupaten",
        city_name: "Tanah Bumbu",
        subdistricts: [
          {
            subdistrict_id: "6233",
            subdistrict_name: "Angsana",
          },
          {
            subdistrict_id: "6234",
            subdistrict_name: "Batulicin",
          },
          {
            subdistrict_id: "6235",
            subdistrict_name: "Karang Bintang",
          },
          {
            subdistrict_id: "6236",
            subdistrict_name: "Kuranji",
          },
          {
            subdistrict_id: "6237",
            subdistrict_name: "Kusan Hilir",
          },
          {
            subdistrict_id: "6238",
            subdistrict_name: "Kusan Hulu",
          },
          {
            subdistrict_id: "6239",
            subdistrict_name: "Mantewe",
          },
          {
            subdistrict_id: "6240",
            subdistrict_name: "Satui",
          },
          {
            subdistrict_id: "6241",
            subdistrict_name: "Simpang Empat",
          },
          {
            subdistrict_id: "6242",
            subdistrict_name: "Sungai Loban",
          },
        ],
      },
      {
        city_id: "454",
        type: "Kabupaten",
        city_name: "Tanah Laut",
        subdistricts: [
          {
            subdistrict_id: "6257",
            subdistrict_name: "Bajuin",
          },
          {
            subdistrict_id: "6258",
            subdistrict_name: "Bati-Bati",
          },
          {
            subdistrict_id: "6259",
            subdistrict_name: "Batu Ampar",
          },
          {
            subdistrict_id: "6260",
            subdistrict_name: "Bumi Makmur",
          },
          {
            subdistrict_id: "6261",
            subdistrict_name: "Jorong",
          },
          {
            subdistrict_id: "6262",
            subdistrict_name: "Kintap",
          },
          {
            subdistrict_id: "6263",
            subdistrict_name: "Kurau",
          },
          {
            subdistrict_id: "6264",
            subdistrict_name: "Panyipatan",
          },
          {
            subdistrict_id: "6265",
            subdistrict_name: "Pelaihari",
          },
          {
            subdistrict_id: "6266",
            subdistrict_name: "Takisung",
          },
          {
            subdistrict_id: "6267",
            subdistrict_name: "Tambang Ulang",
          },
        ],
      },
      {
        city_id: "466",
        type: "Kabupaten",
        city_name: "Tapin",
        subdistricts: [
          {
            subdistrict_id: "6420",
            subdistrict_name: "Bakarangan",
          },
          {
            subdistrict_id: "6421",
            subdistrict_name: "Binuang",
          },
          {
            subdistrict_id: "6422",
            subdistrict_name: "Bungur",
          },
          {
            subdistrict_id: "6423",
            subdistrict_name: "Candi Laras Selatan",
          },
          {
            subdistrict_id: "6424",
            subdistrict_name: "Candi Laras Utara",
          },
          {
            subdistrict_id: "6425",
            subdistrict_name: "Hatungun",
          },
          {
            subdistrict_id: "6426",
            subdistrict_name: "Lokpaikat",
          },
          {
            subdistrict_id: "6427",
            subdistrict_name: "Piani",
          },
          {
            subdistrict_id: "6428",
            subdistrict_name: "Salam Babaris",
          },
          {
            subdistrict_id: "6429",
            subdistrict_name: "Tapin Selatan",
          },
          {
            subdistrict_id: "6430",
            subdistrict_name: "Tapin Tengah",
          },
          {
            subdistrict_id: "6431",
            subdistrict_name: "Tapin Utara",
          },
        ],
      },
    ],
  },
  {
    province_id: "14",
    province: "Kalimantan Tengah",
    cities: [
      {
        city_id: "44",
        type: "Kabupaten",
        city_name: "Barito Selatan",
        subdistricts: [
          {
            subdistrict_id: "641",
            subdistrict_name: "Dusun Hilir",
          },
          {
            subdistrict_id: "642",
            subdistrict_name: "Dusun Selatan",
          },
          {
            subdistrict_id: "643",
            subdistrict_name: "Dusun Utara",
          },
          {
            subdistrict_id: "644",
            subdistrict_name: "Gunung Bintang Awai",
          },
          {
            subdistrict_id: "645",
            subdistrict_name: "Jenamas",
          },
          {
            subdistrict_id: "646",
            subdistrict_name: "Karau Kuala",
          },
        ],
      },
      {
        city_id: "45",
        type: "Kabupaten",
        city_name: "Barito Timur",
        subdistricts: [
          {
            subdistrict_id: "647",
            subdistrict_name: "Awang",
          },
          {
            subdistrict_id: "648",
            subdistrict_name: "Benua Lima",
          },
          {
            subdistrict_id: "649",
            subdistrict_name: "Dusun Tengah",
          },
          {
            subdistrict_id: "650",
            subdistrict_name: "Dusun Timur",
          },
          {
            subdistrict_id: "651",
            subdistrict_name: "Karusen Janang",
          },
          {
            subdistrict_id: "652",
            subdistrict_name: "Paju Epat",
          },
          {
            subdistrict_id: "653",
            subdistrict_name: "Paku",
          },
          {
            subdistrict_id: "654",
            subdistrict_name: "Patangkep Tutui",
          },
          {
            subdistrict_id: "655",
            subdistrict_name: "Pematang Karau",
          },
          {
            subdistrict_id: "656",
            subdistrict_name: "Raren Batuah",
          },
        ],
      },
      {
        city_id: "46",
        type: "Kabupaten",
        city_name: "Barito Utara",
        subdistricts: [
          {
            subdistrict_id: "657",
            subdistrict_name: "Gunung Purei",
          },
          {
            subdistrict_id: "658",
            subdistrict_name: "Gunung Timang",
          },
          {
            subdistrict_id: "659",
            subdistrict_name: "Lahei",
          },
          {
            subdistrict_id: "660",
            subdistrict_name: "Lahei Barat",
          },
          {
            subdistrict_id: "661",
            subdistrict_name: "Montallat (Montalat)",
          },
          {
            subdistrict_id: "662",
            subdistrict_name: "Teweh Baru",
          },
          {
            subdistrict_id: "663",
            subdistrict_name: "Teweh Selatan",
          },
          {
            subdistrict_id: "664",
            subdistrict_name: "Teweh Tengah",
          },
          {
            subdistrict_id: "665",
            subdistrict_name: "Teweh Timur",
          },
        ],
      },
      {
        city_id: "136",
        type: "Kabupaten",
        city_name: "Gunung Mas",
        subdistricts: [
          {
            subdistrict_id: "1883",
            subdistrict_name: "Damang Batu",
          },
          {
            subdistrict_id: "1884",
            subdistrict_name: "Kahayan Hulu Utara",
          },
          {
            subdistrict_id: "1885",
            subdistrict_name: "Kurun",
          },
          {
            subdistrict_id: "1886",
            subdistrict_name: "Manuhing",
          },
          {
            subdistrict_id: "1887",
            subdistrict_name: "Manuhing Raya",
          },
          {
            subdistrict_id: "1888",
            subdistrict_name: "Mihing Raya",
          },
          {
            subdistrict_id: "1889",
            subdistrict_name: "Miri Manasa",
          },
          {
            subdistrict_id: "1890",
            subdistrict_name: "Rungan",
          },
          {
            subdistrict_id: "1891",
            subdistrict_name: "Rungan Barat",
          },
          {
            subdistrict_id: "1892",
            subdistrict_name: "Rungan Hulu",
          },
          {
            subdistrict_id: "1893",
            subdistrict_name: "Sepang (Sepang Simin)",
          },
          {
            subdistrict_id: "1894",
            subdistrict_name: "Tewah",
          },
        ],
      },
      {
        city_id: "167",
        type: "Kabupaten",
        city_name: "Kapuas",
        subdistricts: [
          {
            subdistrict_id: "2313",
            subdistrict_name: "Basarang",
          },
          {
            subdistrict_id: "2314",
            subdistrict_name: "Bataguh",
          },
          {
            subdistrict_id: "2315",
            subdistrict_name: "Dadahup",
          },
          {
            subdistrict_id: "2316",
            subdistrict_name: "Kapuas Barat",
          },
          {
            subdistrict_id: "2317",
            subdistrict_name: "Kapuas Hilir",
          },
          {
            subdistrict_id: "2318",
            subdistrict_name: "Kapuas Hulu",
          },
          {
            subdistrict_id: "2319",
            subdistrict_name: "Kapuas Kuala",
          },
          {
            subdistrict_id: "2320",
            subdistrict_name: "Kapuas Murung",
          },
          {
            subdistrict_id: "2321",
            subdistrict_name: "Kapuas Tengah",
          },
          {
            subdistrict_id: "2322",
            subdistrict_name: "Kapuas Timur",
          },
          {
            subdistrict_id: "2323",
            subdistrict_name: "Mandau Talawang",
          },
          {
            subdistrict_id: "2324",
            subdistrict_name: "Mantangai",
          },
          {
            subdistrict_id: "2325",
            subdistrict_name: "Pasak Talawang",
          },
          {
            subdistrict_id: "2326",
            subdistrict_name: "Pulau Petak",
          },
          {
            subdistrict_id: "2327",
            subdistrict_name: "Selat",
          },
          {
            subdistrict_id: "2328",
            subdistrict_name: "Tamban Catur",
          },
          {
            subdistrict_id: "2329",
            subdistrict_name: "Timpah",
          },
        ],
      },
      {
        city_id: "174",
        type: "Kabupaten",
        city_name: "Katingan",
        subdistricts: [
          {
            subdistrict_id: "2437",
            subdistrict_name: "Bukit Raya",
          },
          {
            subdistrict_id: "2438",
            subdistrict_name: "Kamipang",
          },
          {
            subdistrict_id: "2439",
            subdistrict_name: "Katingan Hilir",
          },
          {
            subdistrict_id: "2440",
            subdistrict_name: "Katingan Hulu",
          },
          {
            subdistrict_id: "2441",
            subdistrict_name: "Katingan Kuala",
          },
          {
            subdistrict_id: "2442",
            subdistrict_name: "Katingan Tengah",
          },
          {
            subdistrict_id: "2443",
            subdistrict_name: "Marikit",
          },
          {
            subdistrict_id: "2444",
            subdistrict_name: "Mendawai",
          },
          {
            subdistrict_id: "2445",
            subdistrict_name: "Petak Malai",
          },
          {
            subdistrict_id: "2446",
            subdistrict_name: "Pulau Malan",
          },
          {
            subdistrict_id: "2447",
            subdistrict_name: "Sanaman Mantikei (Senamang Mantikei)",
          },
          {
            subdistrict_id: "2448",
            subdistrict_name: "Tasik Payawan",
          },
          {
            subdistrict_id: "2449",
            subdistrict_name: "Tewang Sanggalang Garing (Sangalang)",
          },
        ],
      },
      {
        city_id: "205",
        type: "Kabupaten",
        city_name: "Kotawaringin Barat",
        subdistricts: [
          {
            subdistrict_id: "2874",
            subdistrict_name: "Arut Selatan",
          },
          {
            subdistrict_id: "2875",
            subdistrict_name: "Arut Utara",
          },
          {
            subdistrict_id: "2876",
            subdistrict_name: "Kotawaringin Lama",
          },
          {
            subdistrict_id: "2877",
            subdistrict_name: "Kumai",
          },
          {
            subdistrict_id: "2878",
            subdistrict_name: "Pangkalan Banteng",
          },
          {
            subdistrict_id: "2879",
            subdistrict_name: "Pangkalan Lada",
          },
        ],
      },
      {
        city_id: "206",
        type: "Kabupaten",
        city_name: "Kotawaringin Timur",
        subdistricts: [
          {
            subdistrict_id: "2880",
            subdistrict_name: "Antang Kalang",
          },
          {
            subdistrict_id: "2881",
            subdistrict_name: "Baamang",
          },
          {
            subdistrict_id: "2882",
            subdistrict_name: "Bukit Santuei",
          },
          {
            subdistrict_id: "2883",
            subdistrict_name: "Cempaga",
          },
          {
            subdistrict_id: "2884",
            subdistrict_name: "Cempaga Hulu",
          },
          {
            subdistrict_id: "2885",
            subdistrict_name: "Kota Besi",
          },
          {
            subdistrict_id: "2886",
            subdistrict_name: "Mentawa Baru (Ketapang)",
          },
          {
            subdistrict_id: "2887",
            subdistrict_name: "Mentaya Hilir Selatan",
          },
          {
            subdistrict_id: "2888",
            subdistrict_name: "Mentaya Hilir Utara",
          },
          {
            subdistrict_id: "2889",
            subdistrict_name: "Mentaya Hulu",
          },
          {
            subdistrict_id: "2890",
            subdistrict_name: "Parenggean",
          },
          {
            subdistrict_id: "2891",
            subdistrict_name: "Pulau Hanaut",
          },
          {
            subdistrict_id: "2892",
            subdistrict_name: "Seranau",
          },
          {
            subdistrict_id: "2893",
            subdistrict_name: "Telaga Antang",
          },
          {
            subdistrict_id: "2894",
            subdistrict_name: "Telawang",
          },
          {
            subdistrict_id: "2895",
            subdistrict_name: "Teluk Sampit",
          },
          {
            subdistrict_id: "2896",
            subdistrict_name: "Tualan Hulu",
          },
        ],
      },
      {
        city_id: "221",
        type: "Kabupaten",
        city_name: "Lamandau",
        subdistricts: [
          {
            subdistrict_id: "3105",
            subdistrict_name: "Batangkawa",
          },
          {
            subdistrict_id: "3106",
            subdistrict_name: "Belantikan Raya",
          },
          {
            subdistrict_id: "3107",
            subdistrict_name: "Bulik",
          },
          {
            subdistrict_id: "3108",
            subdistrict_name: "Bulik Timur",
          },
          {
            subdistrict_id: "3109",
            subdistrict_name: "Delang",
          },
          {
            subdistrict_id: "3110",
            subdistrict_name: "Lamandau",
          },
          {
            subdistrict_id: "3111",
            subdistrict_name: "Menthobi Raya",
          },
          {
            subdistrict_id: "3112",
            subdistrict_name: "Sematu Jaya",
          },
        ],
      },
      {
        city_id: "296",
        type: "Kabupaten",
        city_name: "Murung Raya",
        subdistricts: [
          {
            subdistrict_id: "4190",
            subdistrict_name: "Barito Tuhup Raya",
          },
          {
            subdistrict_id: "4191",
            subdistrict_name: "Laung Tuhup",
          },
          {
            subdistrict_id: "4192",
            subdistrict_name: "Murung",
          },
          {
            subdistrict_id: "4193",
            subdistrict_name: "Permata Intan",
          },
          {
            subdistrict_id: "4194",
            subdistrict_name: "Seribu Riam",
          },
          {
            subdistrict_id: "4195",
            subdistrict_name: "Sumber Barito",
          },
          {
            subdistrict_id: "4196",
            subdistrict_name: "Sungai Babuat",
          },
          {
            subdistrict_id: "4197",
            subdistrict_name: "Tanah Siang",
          },
          {
            subdistrict_id: "4198",
            subdistrict_name: "Tanah Siang Selatan",
          },
          {
            subdistrict_id: "4199",
            subdistrict_name: "Uut Murung",
          },
        ],
      },
      {
        city_id: "326",
        type: "Kota",
        city_name: "Palangka Raya",
        subdistricts: [
          {
            subdistrict_id: "4604",
            subdistrict_name: "Bukit Batu",
          },
          {
            subdistrict_id: "4605",
            subdistrict_name: "Jekan Raya",
          },
          {
            subdistrict_id: "4606",
            subdistrict_name: "Pahandut",
          },
          {
            subdistrict_id: "4607",
            subdistrict_name: "Rakumpit",
          },
          {
            subdistrict_id: "4608",
            subdistrict_name: "Sebangau",
          },
        ],
      },
      {
        city_id: "371",
        type: "Kabupaten",
        city_name: "Pulang Pisau",
        subdistricts: [
          {
            subdistrict_id: "5170",
            subdistrict_name: "Banama Tingang",
          },
          {
            subdistrict_id: "5171",
            subdistrict_name: "Jabiren Raya",
          },
          {
            subdistrict_id: "5172",
            subdistrict_name: "Kahayan Hilir",
          },
          {
            subdistrict_id: "5173",
            subdistrict_name: "Kahayan Kuala",
          },
          {
            subdistrict_id: "5174",
            subdistrict_name: "Kahayan Tengah",
          },
          {
            subdistrict_id: "5175",
            subdistrict_name: "Maliku",
          },
          {
            subdistrict_id: "5176",
            subdistrict_name: "Pandih Batu",
          },
          {
            subdistrict_id: "5177",
            subdistrict_name: "Sebangau Kuala",
          },
        ],
      },
      {
        city_id: "405",
        type: "Kabupaten",
        city_name: "Seruyan",
        subdistricts: [
          {
            subdistrict_id: "5592",
            subdistrict_name: "Batu Ampar",
          },
          {
            subdistrict_id: "5593",
            subdistrict_name: "Danau Seluluk",
          },
          {
            subdistrict_id: "5594",
            subdistrict_name: "Danau Sembuluh",
          },
          {
            subdistrict_id: "5595",
            subdistrict_name: "Hanau",
          },
          {
            subdistrict_id: "5596",
            subdistrict_name: "Seruyan Hilir",
          },
          {
            subdistrict_id: "5597",
            subdistrict_name: "Seruyan Hilir Timur",
          },
          {
            subdistrict_id: "5598",
            subdistrict_name: "Seruyan Hulu",
          },
          {
            subdistrict_id: "5599",
            subdistrict_name: "Seruyan Raya",
          },
          {
            subdistrict_id: "5600",
            subdistrict_name: "Seruyan Tengah",
          },
          {
            subdistrict_id: "5601",
            subdistrict_name: "Suling Tambun",
          },
        ],
      },
      {
        city_id: "432",
        type: "Kabupaten",
        city_name: "Sukamara",
        subdistricts: [
          {
            subdistrict_id: "5972",
            subdistrict_name: "Balai Riam",
          },
          {
            subdistrict_id: "5973",
            subdistrict_name: "Jelai",
          },
          {
            subdistrict_id: "5974",
            subdistrict_name: "Pantai Lunci",
          },
          {
            subdistrict_id: "5975",
            subdistrict_name: "Permata Kecubung",
          },
          {
            subdistrict_id: "5976",
            subdistrict_name: "Sukamara",
          },
        ],
      },
    ],
  },
  {
    province_id: "15",
    province: "Kalimantan Timur",
    cities: [
      {
        city_id: "19",
        type: "Kota",
        city_name: "Balikpapan",
        subdistricts: [
          {
            subdistrict_id: "272",
            subdistrict_name: "Balikpapan Barat",
          },
          {
            subdistrict_id: "273",
            subdistrict_name: "Balikpapan Kota",
          },
          {
            subdistrict_id: "274",
            subdistrict_name: "Balikpapan Selatan",
          },
          {
            subdistrict_id: "275",
            subdistrict_name: "Balikpapan Tengah",
          },
          {
            subdistrict_id: "276",
            subdistrict_name: "Balikpapan Timur",
          },
          {
            subdistrict_id: "277",
            subdistrict_name: "Balikpapan Utara",
          },
        ],
      },
      {
        city_id: "66",
        type: "Kabupaten",
        city_name: "Berau",
        subdistricts: [
          {
            subdistrict_id: "879",
            subdistrict_name: "Batu Putih",
          },
          {
            subdistrict_id: "880",
            subdistrict_name: "Biatan",
          },
          {
            subdistrict_id: "881",
            subdistrict_name: "Biduk-Biduk",
          },
          {
            subdistrict_id: "882",
            subdistrict_name: "Derawan (Pulau Derawan)",
          },
          {
            subdistrict_id: "883",
            subdistrict_name: "Gunung Tabur",
          },
          {
            subdistrict_id: "884",
            subdistrict_name: "Kelay",
          },
          {
            subdistrict_id: "885",
            subdistrict_name: "Maratua",
          },
          {
            subdistrict_id: "886",
            subdistrict_name: "Sambaliung",
          },
          {
            subdistrict_id: "887",
            subdistrict_name: "Segah",
          },
          {
            subdistrict_id: "888",
            subdistrict_name: "Tabalar",
          },
          {
            subdistrict_id: "889",
            subdistrict_name: "Talisayan",
          },
          {
            subdistrict_id: "890",
            subdistrict_name: "Tanjung Redeb",
          },
          {
            subdistrict_id: "891",
            subdistrict_name: "Teluk Bayur",
          },
        ],
      },
      {
        city_id: "89",
        type: "Kota",
        city_name: "Bontang",
        subdistricts: [
          {
            subdistrict_id: "1217",
            subdistrict_name: "Bontang Barat",
          },
          {
            subdistrict_id: "1218",
            subdistrict_name: "Bontang Selatan",
          },
          {
            subdistrict_id: "1219",
            subdistrict_name: "Bontang Utara",
          },
        ],
      },
      {
        city_id: "214",
        type: "Kabupaten",
        city_name: "Kutai Barat",
        subdistricts: [
          {
            subdistrict_id: "3004",
            subdistrict_name: "Barong Tongkok",
          },
          {
            subdistrict_id: "3005",
            subdistrict_name: "Bentian Besar",
          },
          {
            subdistrict_id: "3006",
            subdistrict_name: "Bongan",
          },
          {
            subdistrict_id: "3007",
            subdistrict_name: "Damai",
          },
          {
            subdistrict_id: "3008",
            subdistrict_name: "Jempang",
          },
          {
            subdistrict_id: "3009",
            subdistrict_name: "Laham",
          },
          {
            subdistrict_id: "3010",
            subdistrict_name: "Linggang Bigung",
          },
          {
            subdistrict_id: "3011",
            subdistrict_name: "Long Apari",
          },
          {
            subdistrict_id: "3012",
            subdistrict_name: "Long Bagun",
          },
          {
            subdistrict_id: "3013",
            subdistrict_name: "Long Hubung",
          },
          {
            subdistrict_id: "3014",
            subdistrict_name: "Long Iram",
          },
          {
            subdistrict_id: "3015",
            subdistrict_name: "Long Pahangai",
          },
          {
            subdistrict_id: "3016",
            subdistrict_name: "Manor Bulatin (Mook Manaar Bulatn)",
          },
          {
            subdistrict_id: "3017",
            subdistrict_name: "Melak",
          },
          {
            subdistrict_id: "3018",
            subdistrict_name: "Muara Lawa",
          },
          {
            subdistrict_id: "3019",
            subdistrict_name: "Muara Pahu",
          },
          {
            subdistrict_id: "3020",
            subdistrict_name: "Nyuatan",
          },
          {
            subdistrict_id: "3021",
            subdistrict_name: "Penyinggahan",
          },
          {
            subdistrict_id: "3022",
            subdistrict_name: "Sekolaq Darat",
          },
          {
            subdistrict_id: "3023",
            subdistrict_name: "Siluq Ngurai",
          },
          {
            subdistrict_id: "3024",
            subdistrict_name: "Tering",
          },
        ],
      },
      {
        city_id: "215",
        type: "Kabupaten",
        city_name: "Kutai Kartanegara",
        subdistricts: [
          {
            subdistrict_id: "3025",
            subdistrict_name: "Anggana",
          },
          {
            subdistrict_id: "3026",
            subdistrict_name: "Kembang Janggut",
          },
          {
            subdistrict_id: "3027",
            subdistrict_name: "Kenohan",
          },
          {
            subdistrict_id: "3028",
            subdistrict_name: "Kota Bangun",
          },
          {
            subdistrict_id: "3029",
            subdistrict_name: "Loa Janan",
          },
          {
            subdistrict_id: "3030",
            subdistrict_name: "Loa Kulu",
          },
          {
            subdistrict_id: "3031",
            subdistrict_name: "Marang Kayu",
          },
          {
            subdistrict_id: "3032",
            subdistrict_name: "Muara Badak",
          },
          {
            subdistrict_id: "3033",
            subdistrict_name: "Muara Jawa",
          },
          {
            subdistrict_id: "3034",
            subdistrict_name: "Muara Kaman",
          },
          {
            subdistrict_id: "3035",
            subdistrict_name: "Muara Muntai",
          },
          {
            subdistrict_id: "3036",
            subdistrict_name: "Muara Wis",
          },
          {
            subdistrict_id: "3037",
            subdistrict_name: "Samboja (Semboja)",
          },
          {
            subdistrict_id: "3038",
            subdistrict_name: "Sanga-Sanga",
          },
          {
            subdistrict_id: "3039",
            subdistrict_name: "Sebulu",
          },
          {
            subdistrict_id: "3040",
            subdistrict_name: "Tabang",
          },
          {
            subdistrict_id: "3041",
            subdistrict_name: "Tenggarong",
          },
          {
            subdistrict_id: "3042",
            subdistrict_name: "Tenggarong Seberang",
          },
        ],
      },
      {
        city_id: "216",
        type: "Kabupaten",
        city_name: "Kutai Timur",
        subdistricts: [
          {
            subdistrict_id: "3043",
            subdistrict_name: "Batu Ampar",
          },
          {
            subdistrict_id: "3044",
            subdistrict_name: "Bengalon",
          },
          {
            subdistrict_id: "3045",
            subdistrict_name: "Busang",
          },
          {
            subdistrict_id: "3046",
            subdistrict_name: "Kaliorang",
          },
          {
            subdistrict_id: "3047",
            subdistrict_name: "Karangan",
          },
          {
            subdistrict_id: "3048",
            subdistrict_name: "Kaubun",
          },
          {
            subdistrict_id: "3049",
            subdistrict_name: "Kongbeng",
          },
          {
            subdistrict_id: "3050",
            subdistrict_name: "Long Mesangat (Mesengat)",
          },
          {
            subdistrict_id: "3051",
            subdistrict_name: "Muara Ancalong",
          },
          {
            subdistrict_id: "3052",
            subdistrict_name: "Muara Bengkal",
          },
          {
            subdistrict_id: "3053",
            subdistrict_name: "Muara Wahau",
          },
          {
            subdistrict_id: "3054",
            subdistrict_name: "Rantau Pulung",
          },
          {
            subdistrict_id: "3055",
            subdistrict_name: "Sandaran",
          },
          {
            subdistrict_id: "3056",
            subdistrict_name: "Sangatta Selatan",
          },
          {
            subdistrict_id: "3057",
            subdistrict_name: "Sangatta Utara",
          },
          {
            subdistrict_id: "3058",
            subdistrict_name: "Sangkulirang",
          },
          {
            subdistrict_id: "3059",
            subdistrict_name: "Telen",
          },
          {
            subdistrict_id: "3060",
            subdistrict_name: "Teluk Pandan",
          },
        ],
      },
      {
        city_id: "341",
        type: "Kabupaten",
        city_name: "Paser",
        subdistricts: [
          {
            subdistrict_id: "4783",
            subdistrict_name: "Batu Engau",
          },
          {
            subdistrict_id: "4784",
            subdistrict_name: "Batu Sopang",
          },
          {
            subdistrict_id: "4785",
            subdistrict_name: "Kuaro",
          },
          {
            subdistrict_id: "4786",
            subdistrict_name: "Long Ikis",
          },
          {
            subdistrict_id: "4787",
            subdistrict_name: "Long Kali",
          },
          {
            subdistrict_id: "4788",
            subdistrict_name: "Muara Komam",
          },
          {
            subdistrict_id: "4789",
            subdistrict_name: "Muara Samu",
          },
          {
            subdistrict_id: "4790",
            subdistrict_name: "Pasir Belengkong",
          },
          {
            subdistrict_id: "4791",
            subdistrict_name: "Tanah Grogot",
          },
          {
            subdistrict_id: "4792",
            subdistrict_name: "Tanjung Harapan",
          },
        ],
      },
      {
        city_id: "354",
        type: "Kabupaten",
        city_name: "Penajam Paser Utara",
        subdistricts: [
          {
            subdistrict_id: "4960",
            subdistrict_name: "Babulu",
          },
          {
            subdistrict_id: "4961",
            subdistrict_name: "Penajam",
          },
          {
            subdistrict_id: "4962",
            subdistrict_name: "Sepaku",
          },
          {
            subdistrict_id: "4963",
            subdistrict_name: "Waru",
          },
        ],
      },
      {
        city_id: "387",
        type: "Kota",
        city_name: "Samarinda",
        subdistricts: [
          {
            subdistrict_id: "5356",
            subdistrict_name: "Loa Janan Ilir",
          },
          {
            subdistrict_id: "5357",
            subdistrict_name: "Palaran",
          },
          {
            subdistrict_id: "5358",
            subdistrict_name: "Samarinda Ilir",
          },
          {
            subdistrict_id: "5359",
            subdistrict_name: "Samarinda Kota",
          },
          {
            subdistrict_id: "5360",
            subdistrict_name: "Samarinda Seberang",
          },
          {
            subdistrict_id: "5361",
            subdistrict_name: "Samarinda Ulu",
          },
          {
            subdistrict_id: "5362",
            subdistrict_name: "Samarinda Utara",
          },
          {
            subdistrict_id: "5363",
            subdistrict_name: "Sambutan",
          },
          {
            subdistrict_id: "5364",
            subdistrict_name: "Sungai Kunjang",
          },
          {
            subdistrict_id: "5365",
            subdistrict_name: "Sungai Pinang",
          },
        ],
      },
    ],
  },
  {
    province_id: "16",
    province: "Kalimantan Utara",
    cities: [
      {
        city_id: "96",
        type: "Kabupaten",
        city_name: "Bulungan (Bulongan)",
        subdistricts: [
          {
            subdistrict_id: "1298",
            subdistrict_name: "Peso",
          },
          {
            subdistrict_id: "1299",
            subdistrict_name: "Peso Hilir/Ilir",
          },
          {
            subdistrict_id: "1300",
            subdistrict_name: "Pulau Bunyu",
          },
          {
            subdistrict_id: "1301",
            subdistrict_name: "Sekatak",
          },
          {
            subdistrict_id: "1302",
            subdistrict_name: "Tanjung Palas",
          },
          {
            subdistrict_id: "1303",
            subdistrict_name: "Tanjung Palas Barat",
          },
          {
            subdistrict_id: "1304",
            subdistrict_name: "Tanjung Palas Tengah",
          },
          {
            subdistrict_id: "1305",
            subdistrict_name: "Tanjung Palas Timur",
          },
          {
            subdistrict_id: "1306",
            subdistrict_name: "Tanjung Palas Utara",
          },
          {
            subdistrict_id: "1307",
            subdistrict_name: "Tanjung Selor",
          },
        ],
      },
      {
        city_id: "257",
        type: "Kabupaten",
        city_name: "Malinau",
        subdistricts: [
          {
            subdistrict_id: "3639",
            subdistrict_name: "Bahau Hulu",
          },
          {
            subdistrict_id: "3640",
            subdistrict_name: "Kayan Hilir",
          },
          {
            subdistrict_id: "3641",
            subdistrict_name: "Kayan Hulu",
          },
          {
            subdistrict_id: "3642",
            subdistrict_name: "Kayan Selatan",
          },
          {
            subdistrict_id: "3643",
            subdistrict_name: "Malinau Barat",
          },
          {
            subdistrict_id: "3644",
            subdistrict_name: "Malinau Kota",
          },
          {
            subdistrict_id: "3645",
            subdistrict_name: "Malinau Selatan",
          },
          {
            subdistrict_id: "3646",
            subdistrict_name: "Malinau Selatan Hilir",
          },
          {
            subdistrict_id: "3647",
            subdistrict_name: "Malinau Selatan Hulu",
          },
          {
            subdistrict_id: "3648",
            subdistrict_name: "Malinau Utara",
          },
          {
            subdistrict_id: "3649",
            subdistrict_name: "Mentarang",
          },
          {
            subdistrict_id: "3650",
            subdistrict_name: "Mentarang Hulu",
          },
          {
            subdistrict_id: "3651",
            subdistrict_name: "Pujungan",
          },
          {
            subdistrict_id: "3652",
            subdistrict_name: "Sungai Boh",
          },
          {
            subdistrict_id: "3653",
            subdistrict_name: "Sungai Tubu",
          },
        ],
      },
      {
        city_id: "311",
        type: "Kabupaten",
        city_name: "Nunukan",
        subdistricts: [
          {
            subdistrict_id: "4422",
            subdistrict_name: "Krayan",
          },
          {
            subdistrict_id: "4423",
            subdistrict_name: "Krayan Selatan",
          },
          {
            subdistrict_id: "4424",
            subdistrict_name: "Lumbis",
          },
          {
            subdistrict_id: "4425",
            subdistrict_name: "Lumbis Ogong",
          },
          {
            subdistrict_id: "4426",
            subdistrict_name: "Nunukan",
          },
          {
            subdistrict_id: "4427",
            subdistrict_name: "Nunukan Selatan",
          },
          {
            subdistrict_id: "4428",
            subdistrict_name: "Sebatik",
          },
          {
            subdistrict_id: "4429",
            subdistrict_name: "Sebatik Barat",
          },
          {
            subdistrict_id: "4430",
            subdistrict_name: "Sebatik Tengah",
          },
          {
            subdistrict_id: "4431",
            subdistrict_name: "Sebatik Timur",
          },
          {
            subdistrict_id: "4432",
            subdistrict_name: "Sebatik Utara",
          },
          {
            subdistrict_id: "4433",
            subdistrict_name: "Sebuku",
          },
          {
            subdistrict_id: "4434",
            subdistrict_name: "Sei Menggaris",
          },
          {
            subdistrict_id: "4435",
            subdistrict_name: "Sembakung",
          },
          {
            subdistrict_id: "4436",
            subdistrict_name: "Tulin Onsoi",
          },
        ],
      },
      {
        city_id: "450",
        type: "Kabupaten",
        city_name: "Tana Tidung",
        subdistricts: [
          {
            subdistrict_id: "6210",
            subdistrict_name: "Betayau",
          },
          {
            subdistrict_id: "6211",
            subdistrict_name: "Sesayap",
          },
          {
            subdistrict_id: "6212",
            subdistrict_name: "Sesayap Hilir",
          },
          {
            subdistrict_id: "6213",
            subdistrict_name: "Tana Lia (Tanah Lia)",
          },
        ],
      },
      {
        city_id: "467",
        type: "Kota",
        city_name: "Tarakan",
        subdistricts: [
          {
            subdistrict_id: "6432",
            subdistrict_name: "Tarakan Barat",
          },
          {
            subdistrict_id: "6433",
            subdistrict_name: "Tarakan Tengah",
          },
          {
            subdistrict_id: "6434",
            subdistrict_name: "Tarakan Timur",
          },
          {
            subdistrict_id: "6435",
            subdistrict_name: "Tarakan Utara",
          },
        ],
      },
    ],
  },
  {
    province_id: "17",
    province: "Kepulauan Riau",
    cities: [
      {
        city_id: "48",
        type: "Kota",
        city_name: "Batam",
        subdistricts: [
          {
            subdistrict_id: "673",
            subdistrict_name: "Batam Kota",
          },
          {
            subdistrict_id: "674",
            subdistrict_name: "Batu Aji",
          },
          {
            subdistrict_id: "675",
            subdistrict_name: "Batu Ampar",
          },
          {
            subdistrict_id: "676",
            subdistrict_name: "Belakang Padang",
          },
          {
            subdistrict_id: "677",
            subdistrict_name: "Bengkong",
          },
          {
            subdistrict_id: "678",
            subdistrict_name: "Bulang",
          },
          {
            subdistrict_id: "679",
            subdistrict_name: "Galang",
          },
          {
            subdistrict_id: "680",
            subdistrict_name: "Lubuk Baja",
          },
          {
            subdistrict_id: "681",
            subdistrict_name: "Nongsa",
          },
          {
            subdistrict_id: "682",
            subdistrict_name: "Sagulung",
          },
          {
            subdistrict_id: "683",
            subdistrict_name: "Sei/Sungai Beduk",
          },
          {
            subdistrict_id: "684",
            subdistrict_name: "Sekupang",
          },
        ],
      },
      {
        city_id: "71",
        type: "Kabupaten",
        city_name: "Bintan",
        subdistricts: [
          {
            subdistrict_id: "939",
            subdistrict_name: "Bintan Pesisir",
          },
          {
            subdistrict_id: "940",
            subdistrict_name: "Bintan Timur",
          },
          {
            subdistrict_id: "941",
            subdistrict_name: "Bintan Utara",
          },
          {
            subdistrict_id: "942",
            subdistrict_name: "Gunung Kijang",
          },
          {
            subdistrict_id: "943",
            subdistrict_name: "Mantang",
          },
          {
            subdistrict_id: "944",
            subdistrict_name: "Seri/Sri Kuala Lobam",
          },
          {
            subdistrict_id: "945",
            subdistrict_name: "Tambelan",
          },
          {
            subdistrict_id: "946",
            subdistrict_name: "Teluk Bintan",
          },
          {
            subdistrict_id: "947",
            subdistrict_name: "Teluk Sebong",
          },
          {
            subdistrict_id: "948",
            subdistrict_name: "Toapaya",
          },
        ],
      },
      {
        city_id: "172",
        type: "Kabupaten",
        city_name: "Karimun",
        subdistricts: [
          {
            subdistrict_id: "2408",
            subdistrict_name: "Belat",
          },
          {
            subdistrict_id: "2409",
            subdistrict_name: "Buru",
          },
          {
            subdistrict_id: "2410",
            subdistrict_name: "Durai",
          },
          {
            subdistrict_id: "2411",
            subdistrict_name: "Karimun",
          },
          {
            subdistrict_id: "2412",
            subdistrict_name: "Kundur",
          },
          {
            subdistrict_id: "2413",
            subdistrict_name: "Kundur Barat",
          },
          {
            subdistrict_id: "2414",
            subdistrict_name: "Kundur Utara",
          },
          {
            subdistrict_id: "2415",
            subdistrict_name: "Meral",
          },
          {
            subdistrict_id: "2416",
            subdistrict_name: "Meral Barat",
          },
          {
            subdistrict_id: "2417",
            subdistrict_name: "Moro",
          },
          {
            subdistrict_id: "2418",
            subdistrict_name: "Tebing",
          },
          {
            subdistrict_id: "2419",
            subdistrict_name: "Ungar",
          },
        ],
      },
      {
        city_id: "184",
        type: "Kabupaten",
        city_name: "Kepulauan Anambas",
        subdistricts: [
          {
            subdistrict_id: "2571",
            subdistrict_name: "Jemaja",
          },
          {
            subdistrict_id: "2572",
            subdistrict_name: "Jemaja Timur",
          },
          {
            subdistrict_id: "2573",
            subdistrict_name: "Palmatak",
          },
          {
            subdistrict_id: "2574",
            subdistrict_name: "Siantan",
          },
          {
            subdistrict_id: "2575",
            subdistrict_name: "Siantan Selatan",
          },
          {
            subdistrict_id: "2576",
            subdistrict_name: "Siantan Tengah",
          },
          {
            subdistrict_id: "2577",
            subdistrict_name: "Siantan Timur",
          },
        ],
      },
      {
        city_id: "237",
        type: "Kabupaten",
        city_name: "Lingga",
        subdistricts: [
          {
            subdistrict_id: "3364",
            subdistrict_name: "Lingga",
          },
          {
            subdistrict_id: "3365",
            subdistrict_name: "Lingga Timur",
          },
          {
            subdistrict_id: "3366",
            subdistrict_name: "Lingga Utara",
          },
          {
            subdistrict_id: "3367",
            subdistrict_name: "Selayar",
          },
          {
            subdistrict_id: "3368",
            subdistrict_name: "Senayang",
          },
          {
            subdistrict_id: "3369",
            subdistrict_name: "Singkep",
          },
          {
            subdistrict_id: "3370",
            subdistrict_name: "Singkep Barat",
          },
          {
            subdistrict_id: "3371",
            subdistrict_name: "Singkep Pesisir",
          },
        ],
      },
      {
        city_id: "302",
        type: "Kabupaten",
        city_name: "Natuna",
        subdistricts: [
          {
            subdistrict_id: "4267",
            subdistrict_name: "Bunguran Barat",
          },
          {
            subdistrict_id: "4268",
            subdistrict_name: "Bunguran Selatan",
          },
          {
            subdistrict_id: "4269",
            subdistrict_name: "Bunguran Tengah",
          },
          {
            subdistrict_id: "4270",
            subdistrict_name: "Bunguran Timur",
          },
          {
            subdistrict_id: "4271",
            subdistrict_name: "Bunguran Timur Laut",
          },
          {
            subdistrict_id: "4272",
            subdistrict_name: "Bunguran Utara",
          },
          {
            subdistrict_id: "4273",
            subdistrict_name: "Midai",
          },
          {
            subdistrict_id: "4274",
            subdistrict_name: "Pulau Laut",
          },
          {
            subdistrict_id: "4275",
            subdistrict_name: "Pulau Tiga",
          },
          {
            subdistrict_id: "4276",
            subdistrict_name: "Serasan",
          },
          {
            subdistrict_id: "4277",
            subdistrict_name: "Serasan Timur",
          },
          {
            subdistrict_id: "4278",
            subdistrict_name: "Subi",
          },
        ],
      },
      {
        city_id: "462",
        type: "Kota",
        city_name: "Tanjung Pinang",
        subdistricts: [
          {
            subdistrict_id: "6367",
            subdistrict_name: "Bukit Bestari",
          },
          {
            subdistrict_id: "6368",
            subdistrict_name: "Tanjung Pinang Barat",
          },
          {
            subdistrict_id: "6369",
            subdistrict_name: "Tanjung Pinang Kota",
          },
          {
            subdistrict_id: "6370",
            subdistrict_name: "Tanjung Pinang Timur",
          },
        ],
      },
    ],
  },
  {
    province_id: "18",
    province: "Lampung",
    cities: [
      {
        city_id: "21",
        type: "Kota",
        city_name: "Bandar Lampung",
        subdistricts: [
          {
            subdistrict_id: "287",
            subdistrict_name: "Bumi Waras",
          },
          {
            subdistrict_id: "288",
            subdistrict_name: "Enggal",
          },
          {
            subdistrict_id: "289",
            subdistrict_name: "Kedamaian",
          },
          {
            subdistrict_id: "290",
            subdistrict_name: "Kedaton",
          },
          {
            subdistrict_id: "291",
            subdistrict_name: "Kemiling",
          },
          {
            subdistrict_id: "292",
            subdistrict_name: "Labuhan Ratu",
          },
          {
            subdistrict_id: "293",
            subdistrict_name: "Langkapura",
          },
          {
            subdistrict_id: "294",
            subdistrict_name: "Panjang",
          },
          {
            subdistrict_id: "295",
            subdistrict_name: "Rajabasa",
          },
          {
            subdistrict_id: "296",
            subdistrict_name: "Sukabumi",
          },
          {
            subdistrict_id: "297",
            subdistrict_name: "Sukarame",
          },
          {
            subdistrict_id: "298",
            subdistrict_name: "Tanjung Karang Barat",
          },
          {
            subdistrict_id: "299",
            subdistrict_name: "Tanjung Karang Pusat",
          },
          {
            subdistrict_id: "300",
            subdistrict_name: "Tanjung Karang Timur",
          },
          {
            subdistrict_id: "301",
            subdistrict_name: "Tanjung Senang",
          },
          {
            subdistrict_id: "302",
            subdistrict_name: "Telukbetung Barat",
          },
          {
            subdistrict_id: "303",
            subdistrict_name: "Telukbetung Selatan",
          },
          {
            subdistrict_id: "304",
            subdistrict_name: "Telukbetung Timur",
          },
          {
            subdistrict_id: "305",
            subdistrict_name: "Telukbetung Utara",
          },
          {
            subdistrict_id: "306",
            subdistrict_name: "Way Halim",
          },
        ],
      },
      {
        city_id: "223",
        type: "Kabupaten",
        city_name: "Lampung Barat",
        subdistricts: [
          {
            subdistrict_id: "3140",
            subdistrict_name: "Air Hitam",
          },
          {
            subdistrict_id: "3141",
            subdistrict_name: "Balik Bukit",
          },
          {
            subdistrict_id: "3142",
            subdistrict_name: "Bandar Negeri Suoh",
          },
          {
            subdistrict_id: "3143",
            subdistrict_name: "Batu Brak",
          },
          {
            subdistrict_id: "3144",
            subdistrict_name: "Batu Ketulis",
          },
          {
            subdistrict_id: "3145",
            subdistrict_name: "Belalau",
          },
          {
            subdistrict_id: "3146",
            subdistrict_name: "Gedung Surian",
          },
          {
            subdistrict_id: "3147",
            subdistrict_name: "Kebun Tebu",
          },
          {
            subdistrict_id: "3148",
            subdistrict_name: "Lumbok Seminung",
          },
          {
            subdistrict_id: "3149",
            subdistrict_name: "Pagar Dewa",
          },
          {
            subdistrict_id: "3150",
            subdistrict_name: "Sekincau",
          },
          {
            subdistrict_id: "3151",
            subdistrict_name: "Sukau",
          },
          {
            subdistrict_id: "3152",
            subdistrict_name: "Sumber Jaya",
          },
          {
            subdistrict_id: "3153",
            subdistrict_name: "Suoh",
          },
          {
            subdistrict_id: "3154",
            subdistrict_name: "Way Tenong",
          },
        ],
      },
      {
        city_id: "224",
        type: "Kabupaten",
        city_name: "Lampung Selatan",
        subdistricts: [
          {
            subdistrict_id: "3155",
            subdistrict_name: "Bakauheni",
          },
          {
            subdistrict_id: "3156",
            subdistrict_name: "Candipuro",
          },
          {
            subdistrict_id: "3157",
            subdistrict_name: "Jati Agung",
          },
          {
            subdistrict_id: "3158",
            subdistrict_name: "Kalianda",
          },
          {
            subdistrict_id: "3159",
            subdistrict_name: "Katibung",
          },
          {
            subdistrict_id: "3160",
            subdistrict_name: "Ketapang",
          },
          {
            subdistrict_id: "3161",
            subdistrict_name: "Merbau Mataram",
          },
          {
            subdistrict_id: "3162",
            subdistrict_name: "Natar",
          },
          {
            subdistrict_id: "3163",
            subdistrict_name: "Palas",
          },
          {
            subdistrict_id: "3164",
            subdistrict_name: "Penengahan",
          },
          {
            subdistrict_id: "3165",
            subdistrict_name: "Rajabasa",
          },
          {
            subdistrict_id: "3166",
            subdistrict_name: "Sidomulyo",
          },
          {
            subdistrict_id: "3167",
            subdistrict_name: "Sragi",
          },
          {
            subdistrict_id: "3168",
            subdistrict_name: "Tanjung Bintang",
          },
          {
            subdistrict_id: "3169",
            subdistrict_name: "Tanjung Sari",
          },
          {
            subdistrict_id: "3170",
            subdistrict_name: "Way Panji",
          },
          {
            subdistrict_id: "3171",
            subdistrict_name: "Way Sulan",
          },
        ],
      },
      {
        city_id: "225",
        type: "Kabupaten",
        city_name: "Lampung Tengah",
        subdistricts: [
          {
            subdistrict_id: "3172",
            subdistrict_name: "Anak Ratu Aji",
          },
          {
            subdistrict_id: "3173",
            subdistrict_name: "Anak Tuha",
          },
          {
            subdistrict_id: "3174",
            subdistrict_name: "Bandar Mataram",
          },
          {
            subdistrict_id: "3175",
            subdistrict_name: "Bandar Surabaya",
          },
          {
            subdistrict_id: "3176",
            subdistrict_name: "Bangunrejo",
          },
          {
            subdistrict_id: "3177",
            subdistrict_name: "Bekri",
          },
          {
            subdistrict_id: "3178",
            subdistrict_name: "Bumi Nabung",
          },
          {
            subdistrict_id: "3179",
            subdistrict_name: "Bumi Ratu Nuban",
          },
          {
            subdistrict_id: "3180",
            subdistrict_name: "Gunung Sugih",
          },
          {
            subdistrict_id: "3181",
            subdistrict_name: "Kalirejo",
          },
          {
            subdistrict_id: "3182",
            subdistrict_name: "Kota Gajah",
          },
          {
            subdistrict_id: "3183",
            subdistrict_name: "Padang Ratu",
          },
          {
            subdistrict_id: "3184",
            subdistrict_name: "Pubian",
          },
          {
            subdistrict_id: "3185",
            subdistrict_name: "Punggur",
          },
          {
            subdistrict_id: "3186",
            subdistrict_name: "Putra Rumbia",
          },
          {
            subdistrict_id: "3187",
            subdistrict_name: "Rumbia",
          },
          {
            subdistrict_id: "3188",
            subdistrict_name: "Selagai Lingga",
          },
          {
            subdistrict_id: "3189",
            subdistrict_name: "Sendang Agung",
          },
          {
            subdistrict_id: "3190",
            subdistrict_name: "Seputih Agung",
          },
          {
            subdistrict_id: "3191",
            subdistrict_name: "Seputih Banyak",
          },
          {
            subdistrict_id: "3192",
            subdistrict_name: "Seputih Mataram",
          },
          {
            subdistrict_id: "3193",
            subdistrict_name: "Seputih Raman",
          },
          {
            subdistrict_id: "3194",
            subdistrict_name: "Seputih Surabaya",
          },
          {
            subdistrict_id: "3195",
            subdistrict_name: "Terbanggi Besar",
          },
          {
            subdistrict_id: "3196",
            subdistrict_name: "Terusan Nunyai",
          },
          {
            subdistrict_id: "3197",
            subdistrict_name: "Trimurjo",
          },
          {
            subdistrict_id: "3198",
            subdistrict_name: "Way Pangubuan (Pengubuan)",
          },
          {
            subdistrict_id: "3199",
            subdistrict_name: "Way Seputih",
          },
        ],
      },
      {
        city_id: "226",
        type: "Kabupaten",
        city_name: "Lampung Timur",
        subdistricts: [
          {
            subdistrict_id: "3200",
            subdistrict_name: "Bandar Sribawono",
          },
          {
            subdistrict_id: "3201",
            subdistrict_name: "Batanghari",
          },
          {
            subdistrict_id: "3202",
            subdistrict_name: "Batanghari Nuban",
          },
          {
            subdistrict_id: "3203",
            subdistrict_name: "Braja Slebah",
          },
          {
            subdistrict_id: "3204",
            subdistrict_name: "Bumi Agung",
          },
          {
            subdistrict_id: "3205",
            subdistrict_name: "Gunung Pelindung",
          },
          {
            subdistrict_id: "3206",
            subdistrict_name: "Jabung",
          },
          {
            subdistrict_id: "3207",
            subdistrict_name: "Labuhan Maringgai",
          },
          {
            subdistrict_id: "3208",
            subdistrict_name: "Labuhan Ratu",
          },
          {
            subdistrict_id: "3209",
            subdistrict_name: "Marga Sekampung",
          },
          {
            subdistrict_id: "3210",
            subdistrict_name: "Margatiga",
          },
          {
            subdistrict_id: "3211",
            subdistrict_name: "Mataram Baru",
          },
          {
            subdistrict_id: "3212",
            subdistrict_name: "Melinting",
          },
          {
            subdistrict_id: "3213",
            subdistrict_name: "Metro Kibang",
          },
          {
            subdistrict_id: "3214",
            subdistrict_name: "Pasir Sakti",
          },
          {
            subdistrict_id: "3215",
            subdistrict_name: "Pekalongan",
          },
          {
            subdistrict_id: "3216",
            subdistrict_name: "Purbolinggo",
          },
          {
            subdistrict_id: "3217",
            subdistrict_name: "Raman Utara",
          },
          {
            subdistrict_id: "3218",
            subdistrict_name: "Sekampung",
          },
          {
            subdistrict_id: "3219",
            subdistrict_name: "Sekampung Udik",
          },
          {
            subdistrict_id: "3220",
            subdistrict_name: "Sukadana",
          },
          {
            subdistrict_id: "3221",
            subdistrict_name: "Waway Karya",
          },
          {
            subdistrict_id: "3222",
            subdistrict_name: "Way Bungur (Purbolinggo Utara)",
          },
          {
            subdistrict_id: "3223",
            subdistrict_name: "Way Jepara",
          },
        ],
      },
      {
        city_id: "227",
        type: "Kabupaten",
        city_name: "Lampung Utara",
        subdistricts: [
          {
            subdistrict_id: "3224",
            subdistrict_name: "Abung Barat",
          },
          {
            subdistrict_id: "3225",
            subdistrict_name: "Abung Kunang",
          },
          {
            subdistrict_id: "3226",
            subdistrict_name: "Abung Pekurun",
          },
          {
            subdistrict_id: "3227",
            subdistrict_name: "Abung Selatan",
          },
          {
            subdistrict_id: "3228",
            subdistrict_name: "Abung Semuli",
          },
          {
            subdistrict_id: "3229",
            subdistrict_name: "Abung Surakarta",
          },
          {
            subdistrict_id: "3230",
            subdistrict_name: "Abung Tengah",
          },
          {
            subdistrict_id: "3231",
            subdistrict_name: "Abung Timur",
          },
          {
            subdistrict_id: "3232",
            subdistrict_name: "Abung Tinggi",
          },
          {
            subdistrict_id: "3233",
            subdistrict_name: "Blambangan Pagar",
          },
          {
            subdistrict_id: "3234",
            subdistrict_name: "Bukit Kemuning",
          },
          {
            subdistrict_id: "3235",
            subdistrict_name: "Bunga Mayang",
          },
          {
            subdistrict_id: "3236",
            subdistrict_name: "Hulu Sungkai",
          },
          {
            subdistrict_id: "3237",
            subdistrict_name: "Kotabumi",
          },
          {
            subdistrict_id: "3238",
            subdistrict_name: "Kotabumi Selatan",
          },
          {
            subdistrict_id: "3239",
            subdistrict_name: "Kotabumi Utara",
          },
          {
            subdistrict_id: "3240",
            subdistrict_name: "Muara Sungkai",
          },
          {
            subdistrict_id: "3241",
            subdistrict_name: "Sungkai Barat",
          },
          {
            subdistrict_id: "3242",
            subdistrict_name: "Sungkai Jaya",
          },
          {
            subdistrict_id: "3243",
            subdistrict_name: "Sungkai Selatan",
          },
          {
            subdistrict_id: "3244",
            subdistrict_name: "Sungkai Tengah",
          },
          {
            subdistrict_id: "3245",
            subdistrict_name: "Sungkai Utara",
          },
          {
            subdistrict_id: "3246",
            subdistrict_name: "Tanjung Raja",
          },
        ],
      },
      {
        city_id: "282",
        type: "Kabupaten",
        city_name: "Mesuji",
        subdistricts: [
          {
            subdistrict_id: "3982",
            subdistrict_name: "Mesuji",
          },
          {
            subdistrict_id: "3983",
            subdistrict_name: "Mesuji Timur",
          },
          {
            subdistrict_id: "3984",
            subdistrict_name: "Panca Jaya",
          },
          {
            subdistrict_id: "3985",
            subdistrict_name: "Rawa Jitu Utara",
          },
          {
            subdistrict_id: "3986",
            subdistrict_name: "Simpang Pematang",
          },
          {
            subdistrict_id: "3987",
            subdistrict_name: "Tanjung Raya",
          },
          {
            subdistrict_id: "3988",
            subdistrict_name: "Way Serdang",
          },
        ],
      },
      {
        city_id: "283",
        type: "Kota",
        city_name: "Metro",
        subdistricts: [
          {
            subdistrict_id: "3989",
            subdistrict_name: "Metro Barat",
          },
          {
            subdistrict_id: "3990",
            subdistrict_name: "Metro Pusat",
          },
          {
            subdistrict_id: "3991",
            subdistrict_name: "Metro Selatan",
          },
          {
            subdistrict_id: "3992",
            subdistrict_name: "Metro Timur",
          },
          {
            subdistrict_id: "3993",
            subdistrict_name: "Metro Utara",
          },
        ],
      },
      {
        city_id: "355",
        type: "Kabupaten",
        city_name: "Pesawaran",
        subdistricts: [
          {
            subdistrict_id: "4964",
            subdistrict_name: "Gedong Tataan (Gedung Tataan)",
          },
          {
            subdistrict_id: "4965",
            subdistrict_name: "Kedondong",
          },
          {
            subdistrict_id: "4966",
            subdistrict_name: "Marga Punduh",
          },
          {
            subdistrict_id: "4967",
            subdistrict_name: "Negeri Katon",
          },
          {
            subdistrict_id: "4968",
            subdistrict_name: "Padang Cermin",
          },
          {
            subdistrict_id: "4969",
            subdistrict_name: "Punduh Pidada (Pedada)",
          },
          {
            subdistrict_id: "4970",
            subdistrict_name: "Tegineneng",
          },
          {
            subdistrict_id: "4971",
            subdistrict_name: "Way Khilau",
          },
          {
            subdistrict_id: "4972",
            subdistrict_name: "Way Lima",
          },
        ],
      },
      {
        city_id: "356",
        type: "Kabupaten",
        city_name: "Pesisir Barat",
        subdistricts: [
          {
            subdistrict_id: "4973",
            subdistrict_name: "Bengkunat",
          },
          {
            subdistrict_id: "4974",
            subdistrict_name: "Bengkunat Belimbing",
          },
          {
            subdistrict_id: "4975",
            subdistrict_name: "Karya Penggawa",
          },
          {
            subdistrict_id: "4976",
            subdistrict_name: "Krui Selatan",
          },
          {
            subdistrict_id: "4977",
            subdistrict_name: "Lemong",
          },
          {
            subdistrict_id: "4978",
            subdistrict_name: "Ngambur",
          },
          {
            subdistrict_id: "4979",
            subdistrict_name: "Pesisir Selatan",
          },
          {
            subdistrict_id: "4980",
            subdistrict_name: "Pesisir Tengah",
          },
          {
            subdistrict_id: "4981",
            subdistrict_name: "Pesisir Utara",
          },
          {
            subdistrict_id: "4982",
            subdistrict_name: "Pulau Pisang",
          },
          {
            subdistrict_id: "4983",
            subdistrict_name: "Way Krui",
          },
        ],
      },
      {
        city_id: "368",
        type: "Kabupaten",
        city_name: "Pringsewu",
        subdistricts: [
          {
            subdistrict_id: "5132",
            subdistrict_name: "Adi Luwih",
          },
          {
            subdistrict_id: "5133",
            subdistrict_name: "Ambarawa",
          },
          {
            subdistrict_id: "5134",
            subdistrict_name: "Banyumas",
          },
          {
            subdistrict_id: "5135",
            subdistrict_name: "Gading Rejo",
          },
          {
            subdistrict_id: "5136",
            subdistrict_name: "Pagelaran",
          },
          {
            subdistrict_id: "5137",
            subdistrict_name: "Pagelaran Utara",
          },
          {
            subdistrict_id: "5138",
            subdistrict_name: "Pardasuka",
          },
          {
            subdistrict_id: "5139",
            subdistrict_name: "Pringsewu",
          },
          {
            subdistrict_id: "5140",
            subdistrict_name: "Sukoharjo",
          },
        ],
      },
      {
        city_id: "458",
        type: "Kabupaten",
        city_name: "Tanggamus",
        subdistricts: [
          {
            subdistrict_id: "6317",
            subdistrict_name: "Air Naningan",
          },
          {
            subdistrict_id: "6318",
            subdistrict_name: "Bandar Negeri Semuong",
          },
          {
            subdistrict_id: "6319",
            subdistrict_name: "Bulok",
          },
          {
            subdistrict_id: "6320",
            subdistrict_name: "Cukuh Balak",
          },
          {
            subdistrict_id: "6321",
            subdistrict_name: "Gisting",
          },
          {
            subdistrict_id: "6322",
            subdistrict_name: "Gunung Alip",
          },
          {
            subdistrict_id: "6323",
            subdistrict_name: "Kelumbayan",
          },
          {
            subdistrict_id: "6324",
            subdistrict_name: "Kelumbayan Barat",
          },
          {
            subdistrict_id: "6325",
            subdistrict_name: "Kota Agung (Kota Agung Pusat)",
          },
          {
            subdistrict_id: "6326",
            subdistrict_name: "Kota Agung Barat",
          },
          {
            subdistrict_id: "6327",
            subdistrict_name: "Kota Agung Timur",
          },
          {
            subdistrict_id: "6328",
            subdistrict_name: "Limau",
          },
          {
            subdistrict_id: "6329",
            subdistrict_name: "Pematang Sawa",
          },
          {
            subdistrict_id: "6330",
            subdistrict_name: "Pugung",
          },
          {
            subdistrict_id: "6331",
            subdistrict_name: "Pulau Panggung",
          },
          {
            subdistrict_id: "6332",
            subdistrict_name: "Semaka",
          },
          {
            subdistrict_id: "6333",
            subdistrict_name: "Sumberejo",
          },
          {
            subdistrict_id: "6334",
            subdistrict_name: "Talang Padang",
          },
          {
            subdistrict_id: "6335",
            subdistrict_name: "Ulubelu",
          },
          {
            subdistrict_id: "6336",
            subdistrict_name: "Wonosobo",
          },
        ],
      },
      {
        city_id: "490",
        type: "Kabupaten",
        city_name: "Tulang Bawang",
        subdistricts: [
          {
            subdistrict_id: "6798",
            subdistrict_name: "Banjar Agung",
          },
          {
            subdistrict_id: "6799",
            subdistrict_name: "Banjar Baru",
          },
          {
            subdistrict_id: "6800",
            subdistrict_name: "Banjar Margo",
          },
          {
            subdistrict_id: "6801",
            subdistrict_name: "Dente Teladas",
          },
          {
            subdistrict_id: "6802",
            subdistrict_name: "Gedung Aji",
          },
          {
            subdistrict_id: "6803",
            subdistrict_name: "Gedung Aji Baru",
          },
          {
            subdistrict_id: "6804",
            subdistrict_name: "Gedung Meneng",
          },
          {
            subdistrict_id: "6805",
            subdistrict_name: "Menggala",
          },
          {
            subdistrict_id: "6806",
            subdistrict_name: "Menggala Timur",
          },
          {
            subdistrict_id: "6807",
            subdistrict_name: "Meraksa Aji",
          },
          {
            subdistrict_id: "6808",
            subdistrict_name: "Penawar Aji",
          },
          {
            subdistrict_id: "6809",
            subdistrict_name: "Penawar Tama",
          },
          {
            subdistrict_id: "6810",
            subdistrict_name: "Rawa Pitu",
          },
          {
            subdistrict_id: "6811",
            subdistrict_name: "Rawajitu Selatan",
          },
          {
            subdistrict_id: "6812",
            subdistrict_name: "Rawajitu Timur",
          },
        ],
      },
      {
        city_id: "491",
        type: "Kabupaten",
        city_name: "Tulang Bawang Barat",
        subdistricts: [
          {
            subdistrict_id: "6813",
            subdistrict_name: "Gunung Agung",
          },
          {
            subdistrict_id: "6814",
            subdistrict_name: "Gunung Terang",
          },
          {
            subdistrict_id: "6815",
            subdistrict_name: "Lambu Kibang",
          },
          {
            subdistrict_id: "6816",
            subdistrict_name: "Pagar Dewa",
          },
          {
            subdistrict_id: "6817",
            subdistrict_name: "Tulang Bawang Tengah",
          },
          {
            subdistrict_id: "6818",
            subdistrict_name: "Tulang Bawang Udik",
          },
          {
            subdistrict_id: "6819",
            subdistrict_name: "Tumijajar",
          },
          {
            subdistrict_id: "6820",
            subdistrict_name: "Way Kenanga",
          },
        ],
      },
      {
        city_id: "496",
        type: "Kabupaten",
        city_name: "Way Kanan",
        subdistricts: [
          {
            subdistrict_id: "6871",
            subdistrict_name: "Bahuga",
          },
          {
            subdistrict_id: "6872",
            subdistrict_name: "Banjit",
          },
          {
            subdistrict_id: "6873",
            subdistrict_name: "Baradatu",
          },
          {
            subdistrict_id: "6874",
            subdistrict_name: "Blambangan Umpu",
          },
          {
            subdistrict_id: "6875",
            subdistrict_name: "Buay Bahuga",
          },
          {
            subdistrict_id: "6876",
            subdistrict_name: "Bumi Agung",
          },
          {
            subdistrict_id: "6877",
            subdistrict_name: "Gunung Labuhan",
          },
          {
            subdistrict_id: "6878",
            subdistrict_name: "Kasui",
          },
          {
            subdistrict_id: "6879",
            subdistrict_name: "Negara Batin",
          },
          {
            subdistrict_id: "6880",
            subdistrict_name: "Negeri Agung",
          },
          {
            subdistrict_id: "6881",
            subdistrict_name: "Negeri Besar",
          },
          {
            subdistrict_id: "6882",
            subdistrict_name: "Pakuan Ratu",
          },
          {
            subdistrict_id: "6883",
            subdistrict_name: "Rebang Tangkas",
          },
          {
            subdistrict_id: "6884",
            subdistrict_name: "Way Tuba",
          },
        ],
      },
    ],
  },
  {
    province_id: "19",
    province: "Maluku",
    cities: [
      {
        city_id: "14",
        type: "Kota",
        city_name: "Ambon",
        subdistricts: [
          {
            subdistrict_id: "209",
            subdistrict_name: "Baguala",
          },
          {
            subdistrict_id: "210",
            subdistrict_name: "Leitimur Selatan",
          },
          {
            subdistrict_id: "211",
            subdistrict_name: "Nusaniwe (Nusanive)",
          },
          {
            subdistrict_id: "212",
            subdistrict_name: "Sirimau",
          },
          {
            subdistrict_id: "213",
            subdistrict_name: "Teluk Ambon",
          },
        ],
      },
      {
        city_id: "99",
        type: "Kabupaten",
        city_name: "Buru",
        subdistricts: [
          {
            subdistrict_id: "1336",
            subdistrict_name: "Airbuaya",
          },
          {
            subdistrict_id: "1337",
            subdistrict_name: "Batabual",
          },
          {
            subdistrict_id: "1338",
            subdistrict_name: "Fena Leisela",
          },
          {
            subdistrict_id: "1339",
            subdistrict_name: "Lilialy",
          },
          {
            subdistrict_id: "1340",
            subdistrict_name: "Lolong Guba",
          },
          {
            subdistrict_id: "1341",
            subdistrict_name: "Namlea",
          },
          {
            subdistrict_id: "1342",
            subdistrict_name: "Teluk Kaiely",
          },
          {
            subdistrict_id: "1343",
            subdistrict_name: "Waeapo",
          },
          {
            subdistrict_id: "1344",
            subdistrict_name: "Waelata",
          },
          {
            subdistrict_id: "1345",
            subdistrict_name: "Waplau",
          },
        ],
      },
      {
        city_id: "100",
        type: "Kabupaten",
        city_name: "Buru Selatan",
        subdistricts: [
          {
            subdistrict_id: "1346",
            subdistrict_name: "Ambalau",
          },
          {
            subdistrict_id: "1347",
            subdistrict_name: "Fena Fafan",
          },
          {
            subdistrict_id: "1348",
            subdistrict_name: "Kepala Madan",
          },
          {
            subdistrict_id: "1349",
            subdistrict_name: "Leksula",
          },
          {
            subdistrict_id: "1350",
            subdistrict_name: "Namrole",
          },
          {
            subdistrict_id: "1351",
            subdistrict_name: "Waesama",
          },
        ],
      },
      {
        city_id: "185",
        type: "Kabupaten",
        city_name: "Kepulauan Aru",
        subdistricts: [
          {
            subdistrict_id: "2578",
            subdistrict_name: "Aru Selatan",
          },
          {
            subdistrict_id: "2579",
            subdistrict_name: "Aru Selatan Timur",
          },
          {
            subdistrict_id: "2580",
            subdistrict_name: "Aru Selatan Utara",
          },
          {
            subdistrict_id: "2581",
            subdistrict_name: "Aru Tengah",
          },
          {
            subdistrict_id: "2582",
            subdistrict_name: "Aru Tengah Selatan",
          },
          {
            subdistrict_id: "2583",
            subdistrict_name: "Aru Tengah Timur",
          },
          {
            subdistrict_id: "2584",
            subdistrict_name: "Aru Utara",
          },
          {
            subdistrict_id: "2585",
            subdistrict_name: "Aru Utara Timur Batuley",
          },
          {
            subdistrict_id: "2586",
            subdistrict_name: "Pulau-Pulau Aru",
          },
          {
            subdistrict_id: "2587",
            subdistrict_name: "Sir-Sir",
          },
        ],
      },
      {
        city_id: "258",
        type: "Kabupaten",
        city_name: "Maluku Barat Daya",
        subdistricts: [
          {
            subdistrict_id: "3654",
            subdistrict_name: "Damer",
          },
          {
            subdistrict_id: "3655",
            subdistrict_name: "Dawelor Dawera",
          },
          {
            subdistrict_id: "3656",
            subdistrict_name: "Kepulauan Romang",
          },
          {
            subdistrict_id: "3657",
            subdistrict_name: "Kisar Utara",
          },
          {
            subdistrict_id: "3658",
            subdistrict_name: "Mdona Hyera/Hiera",
          },
          {
            subdistrict_id: "3659",
            subdistrict_name: "Moa Lakor",
          },
          {
            subdistrict_id: "3660",
            subdistrict_name: "Pulau Lakor",
          },
          {
            subdistrict_id: "3661",
            subdistrict_name: "Pulau Letti (Leti Moa Lakor)",
          },
          {
            subdistrict_id: "3662",
            subdistrict_name: "Pulau Masela",
          },
          {
            subdistrict_id: "3663",
            subdistrict_name: "Pulau Pulau Babar",
          },
          {
            subdistrict_id: "3664",
            subdistrict_name: "Pulau Pulau Terselatan",
          },
          {
            subdistrict_id: "3665",
            subdistrict_name: "Pulau Wetang",
          },
          {
            subdistrict_id: "3666",
            subdistrict_name: "Pulau-Pulau Babar Timur",
          },
          {
            subdistrict_id: "3667",
            subdistrict_name: "Wetar",
          },
          {
            subdistrict_id: "3668",
            subdistrict_name: "Wetar Barat",
          },
          {
            subdistrict_id: "3669",
            subdistrict_name: "Wetar Timur",
          },
          {
            subdistrict_id: "3670",
            subdistrict_name: "Wetar Utara",
          },
        ],
      },
      {
        city_id: "259",
        type: "Kabupaten",
        city_name: "Maluku Tengah",
        subdistricts: [
          {
            subdistrict_id: "3671",
            subdistrict_name: "Amahai",
          },
          {
            subdistrict_id: "3672",
            subdistrict_name: "Banda",
          },
          {
            subdistrict_id: "3673",
            subdistrict_name: "Leihitu",
          },
          {
            subdistrict_id: "3674",
            subdistrict_name: "Leihitu Barat",
          },
          {
            subdistrict_id: "3675",
            subdistrict_name: "Masohi Kota",
          },
          {
            subdistrict_id: "3676",
            subdistrict_name: "Nusalaut",
          },
          {
            subdistrict_id: "3677",
            subdistrict_name: "Pulau Haruku",
          },
          {
            subdistrict_id: "3678",
            subdistrict_name: "Salahutu",
          },
          {
            subdistrict_id: "3679",
            subdistrict_name: "Saparua",
          },
          {
            subdistrict_id: "3680",
            subdistrict_name: "Saparua Timur",
          },
          {
            subdistrict_id: "3681",
            subdistrict_name: "Seram Utara",
          },
          {
            subdistrict_id: "3682",
            subdistrict_name: "Seram Utara Barat",
          },
          {
            subdistrict_id: "3683",
            subdistrict_name: "Seram Utara Timur Kobi",
          },
          {
            subdistrict_id: "3684",
            subdistrict_name: "Seram Utara Timur Seti",
          },
          {
            subdistrict_id: "3685",
            subdistrict_name: "Tehoru",
          },
          {
            subdistrict_id: "3686",
            subdistrict_name: "Teluk Elpaputih",
          },
          {
            subdistrict_id: "3687",
            subdistrict_name: "Telutih",
          },
          {
            subdistrict_id: "3688",
            subdistrict_name: "Teon Nila Serua",
          },
        ],
      },
      {
        city_id: "260",
        type: "Kabupaten",
        city_name: "Maluku Tenggara",
        subdistricts: [
          {
            subdistrict_id: "3689",
            subdistrict_name: "Hoat Sorbay",
          },
          {
            subdistrict_id: "3690",
            subdistrict_name: "Kei Besar",
          },
          {
            subdistrict_id: "3691",
            subdistrict_name: "Kei Besar Selatan",
          },
          {
            subdistrict_id: "3692",
            subdistrict_name: "Kei Besar Selatan Barat",
          },
          {
            subdistrict_id: "3693",
            subdistrict_name: "Kei Besar Utara Barat",
          },
          {
            subdistrict_id: "3694",
            subdistrict_name: "Kei Besar Utara Timur",
          },
          {
            subdistrict_id: "3695",
            subdistrict_name: "Kei Kecil",
          },
          {
            subdistrict_id: "3696",
            subdistrict_name: "Kei Kecil Barat",
          },
          {
            subdistrict_id: "3697",
            subdistrict_name: "Kei Kecil Timur",
          },
          {
            subdistrict_id: "3698",
            subdistrict_name: "Kei Kecil Timur Selatan",
          },
          {
            subdistrict_id: "3699",
            subdistrict_name: "Manyeuw",
          },
        ],
      },
      {
        city_id: "261",
        type: "Kabupaten",
        city_name: "Maluku Tenggara Barat",
        subdistricts: [
          {
            subdistrict_id: "3700",
            subdistrict_name: "Kormomolin",
          },
          {
            subdistrict_id: "3701",
            subdistrict_name: "Molu Maru",
          },
          {
            subdistrict_id: "3702",
            subdistrict_name: "Nirunmas",
          },
          {
            subdistrict_id: "3703",
            subdistrict_name: "Selaru",
          },
          {
            subdistrict_id: "3704",
            subdistrict_name: "Tanimbar Selatan",
          },
          {
            subdistrict_id: "3705",
            subdistrict_name: "Tanimbar Utara",
          },
          {
            subdistrict_id: "3706",
            subdistrict_name: "Wermakatian (Wer Maktian)",
          },
          {
            subdistrict_id: "3707",
            subdistrict_name: "Wertamrian",
          },
          {
            subdistrict_id: "3708",
            subdistrict_name: "Wuarlabobar",
          },
          {
            subdistrict_id: "3709",
            subdistrict_name: "Yaru",
          },
        ],
      },
      {
        city_id: "400",
        type: "Kabupaten",
        city_name: "Seram Bagian Barat",
        subdistricts: [
          {
            subdistrict_id: "5514",
            subdistrict_name: "Amalatu",
          },
          {
            subdistrict_id: "5515",
            subdistrict_name: "Elpaputih",
          },
          {
            subdistrict_id: "5516",
            subdistrict_name: "Huamual",
          },
          {
            subdistrict_id: "5517",
            subdistrict_name: "Huamual Belakang (Waisala)",
          },
          {
            subdistrict_id: "5518",
            subdistrict_name: "Inamosol",
          },
          {
            subdistrict_id: "5519",
            subdistrict_name: "Kairatu",
          },
          {
            subdistrict_id: "5520",
            subdistrict_name: "Kairatu Barat",
          },
          {
            subdistrict_id: "5521",
            subdistrict_name: "Kepulauan Manipa",
          },
          {
            subdistrict_id: "5522",
            subdistrict_name: "Seram Barat",
          },
          {
            subdistrict_id: "5523",
            subdistrict_name: "Taniwel",
          },
          {
            subdistrict_id: "5524",
            subdistrict_name: "Taniwel Timur",
          },
        ],
      },
      {
        city_id: "401",
        type: "Kabupaten",
        city_name: "Seram Bagian Timur",
        subdistricts: [
          {
            subdistrict_id: "5525",
            subdistrict_name: "Bula",
          },
          {
            subdistrict_id: "5526",
            subdistrict_name: "Bula Barat",
          },
          {
            subdistrict_id: "5527",
            subdistrict_name: "Gorom Timur",
          },
          {
            subdistrict_id: "5528",
            subdistrict_name: "Kian Darat",
          },
          {
            subdistrict_id: "5529",
            subdistrict_name: "Kilmury",
          },
          {
            subdistrict_id: "5530",
            subdistrict_name: "Pulau Gorong (Gorom)",
          },
          {
            subdistrict_id: "5531",
            subdistrict_name: "Pulau Panjang",
          },
          {
            subdistrict_id: "5532",
            subdistrict_name: "Seram Timur",
          },
          {
            subdistrict_id: "5533",
            subdistrict_name: "Siritaun Wida Timur",
          },
          {
            subdistrict_id: "5534",
            subdistrict_name: "Siwalalat",
          },
          {
            subdistrict_id: "5535",
            subdistrict_name: "Teluk Waru",
          },
          {
            subdistrict_id: "5536",
            subdistrict_name: "Teor",
          },
          {
            subdistrict_id: "5537",
            subdistrict_name: "Tutuk Tolu",
          },
          {
            subdistrict_id: "5538",
            subdistrict_name: "Wakate",
          },
          {
            subdistrict_id: "5539",
            subdistrict_name: "Werinama",
          },
        ],
      },
      {
        city_id: "488",
        type: "Kota",
        city_name: "Tual",
        subdistricts: [
          {
            subdistrict_id: "6773",
            subdistrict_name: "Kur Selatan",
          },
          {
            subdistrict_id: "6774",
            subdistrict_name: "Pulau Dullah Selatan",
          },
          {
            subdistrict_id: "6775",
            subdistrict_name: "Pulau Dullah Utara",
          },
          {
            subdistrict_id: "6776",
            subdistrict_name: "Pulau Tayando Tam",
          },
          {
            subdistrict_id: "6777",
            subdistrict_name: "Pulau-Pulau Kur",
          },
        ],
      },
    ],
  },
  {
    province_id: "20",
    province: "Maluku Utara",
    cities: [
      {
        city_id: "138",
        type: "Kabupaten",
        city_name: "Halmahera Barat",
        subdistricts: [
          {
            subdistrict_id: "1901",
            subdistrict_name: "Ibu",
          },
          {
            subdistrict_id: "1902",
            subdistrict_name: "Ibu Selatan",
          },
          {
            subdistrict_id: "1903",
            subdistrict_name: "Ibu Utara",
          },
          {
            subdistrict_id: "1904",
            subdistrict_name: "Jailolo",
          },
          {
            subdistrict_id: "1905",
            subdistrict_name: "Jailolo Selatan",
          },
          {
            subdistrict_id: "1906",
            subdistrict_name: "Loloda",
          },
          {
            subdistrict_id: "1907",
            subdistrict_name: "Sahu",
          },
          {
            subdistrict_id: "1908",
            subdistrict_name: "Sahu Timur",
          },
        ],
      },
      {
        city_id: "139",
        type: "Kabupaten",
        city_name: "Halmahera Selatan",
        subdistricts: [
          {
            subdistrict_id: "1909",
            subdistrict_name: "Bacan",
          },
          {
            subdistrict_id: "1910",
            subdistrict_name: "Bacan Barat",
          },
          {
            subdistrict_id: "1911",
            subdistrict_name: "Bacan Barat Utara",
          },
          {
            subdistrict_id: "1912",
            subdistrict_name: "Bacan Selatan",
          },
          {
            subdistrict_id: "1913",
            subdistrict_name: "Bacan Timur",
          },
          {
            subdistrict_id: "1914",
            subdistrict_name: "Bacan Timur Selatan",
          },
          {
            subdistrict_id: "1915",
            subdistrict_name: "Bacan Timur Tengah",
          },
          {
            subdistrict_id: "1916",
            subdistrict_name: "Gane Barat",
          },
          {
            subdistrict_id: "1917",
            subdistrict_name: "Gane Barat Selatan",
          },
          {
            subdistrict_id: "1918",
            subdistrict_name: "Gane Barat Utara",
          },
          {
            subdistrict_id: "1919",
            subdistrict_name: "Gane Timur",
          },
          {
            subdistrict_id: "1920",
            subdistrict_name: "Gane Timur Selatan",
          },
          {
            subdistrict_id: "1921",
            subdistrict_name: "Gane Timur Tengah",
          },
          {
            subdistrict_id: "1922",
            subdistrict_name: "Kasiruta Barat",
          },
          {
            subdistrict_id: "1923",
            subdistrict_name: "Kasiruta Timur",
          },
          {
            subdistrict_id: "1924",
            subdistrict_name: "Kayoa",
          },
          {
            subdistrict_id: "1925",
            subdistrict_name: "Kayoa Barat",
          },
          {
            subdistrict_id: "1926",
            subdistrict_name: "Kayoa Selatan",
          },
          {
            subdistrict_id: "1927",
            subdistrict_name: "Kayoa Utara",
          },
          {
            subdistrict_id: "1928",
            subdistrict_name: "Kepulauan Botanglomang",
          },
          {
            subdistrict_id: "1929",
            subdistrict_name: "Kepulauan Joronga",
          },
          {
            subdistrict_id: "1930",
            subdistrict_name: "Makian (Pulau Makian)",
          },
          {
            subdistrict_id: "1931",
            subdistrict_name: "Makian Barat (Pulau Makian)",
          },
          {
            subdistrict_id: "1932",
            subdistrict_name: "Mandioli Selatan",
          },
          {
            subdistrict_id: "1933",
            subdistrict_name: "Mandioli Utara",
          },
          {
            subdistrict_id: "1934",
            subdistrict_name: "Obi",
          },
          {
            subdistrict_id: "1935",
            subdistrict_name: "Obi Barat",
          },
          {
            subdistrict_id: "1936",
            subdistrict_name: "Obi Selatan",
          },
          {
            subdistrict_id: "1937",
            subdistrict_name: "Obi Timur",
          },
          {
            subdistrict_id: "1938",
            subdistrict_name: "Obi Utara",
          },
        ],
      },
      {
        city_id: "140",
        type: "Kabupaten",
        city_name: "Halmahera Tengah",
        subdistricts: [
          {
            subdistrict_id: "1939",
            subdistrict_name: "Patani",
          },
          {
            subdistrict_id: "1940",
            subdistrict_name: "Patani Barat",
          },
          {
            subdistrict_id: "1941",
            subdistrict_name: "Patani Utara",
          },
          {
            subdistrict_id: "1942",
            subdistrict_name: "Pulau Gebe",
          },
          {
            subdistrict_id: "1943",
            subdistrict_name: "Weda",
          },
          {
            subdistrict_id: "1944",
            subdistrict_name: "Weda Selatan",
          },
          {
            subdistrict_id: "1945",
            subdistrict_name: "Weda Tengah",
          },
          {
            subdistrict_id: "1946",
            subdistrict_name: "Weda Utara",
          },
        ],
      },
      {
        city_id: "141",
        type: "Kabupaten",
        city_name: "Halmahera Timur",
        subdistricts: [
          {
            subdistrict_id: "1947",
            subdistrict_name: "Kota Maba",
          },
          {
            subdistrict_id: "1948",
            subdistrict_name: "Maba",
          },
          {
            subdistrict_id: "1949",
            subdistrict_name: "Maba Selatan",
          },
          {
            subdistrict_id: "1950",
            subdistrict_name: "Maba Tengah",
          },
          {
            subdistrict_id: "1951",
            subdistrict_name: "Maba Utara",
          },
          {
            subdistrict_id: "1952",
            subdistrict_name: "Wasile",
          },
          {
            subdistrict_id: "1953",
            subdistrict_name: "Wasile Selatan",
          },
          {
            subdistrict_id: "1954",
            subdistrict_name: "Wasile Tengah",
          },
          {
            subdistrict_id: "1955",
            subdistrict_name: "Wasile Timur",
          },
          {
            subdistrict_id: "1956",
            subdistrict_name: "Wasile Utara",
          },
        ],
      },
      {
        city_id: "142",
        type: "Kabupaten",
        city_name: "Halmahera Utara",
        subdistricts: [
          {
            subdistrict_id: "1957",
            subdistrict_name: "Galela",
          },
          {
            subdistrict_id: "1958",
            subdistrict_name: "Galela Barat",
          },
          {
            subdistrict_id: "1959",
            subdistrict_name: "Galela Selatan",
          },
          {
            subdistrict_id: "1960",
            subdistrict_name: "Galela Utara",
          },
          {
            subdistrict_id: "1961",
            subdistrict_name: "Kao",
          },
          {
            subdistrict_id: "1962",
            subdistrict_name: "Kao Barat",
          },
          {
            subdistrict_id: "1963",
            subdistrict_name: "Kao Teluk",
          },
          {
            subdistrict_id: "1964",
            subdistrict_name: "Kao Utara",
          },
          {
            subdistrict_id: "1965",
            subdistrict_name: "Loloda Kepulauan",
          },
          {
            subdistrict_id: "1966",
            subdistrict_name: "Loloda Utara",
          },
          {
            subdistrict_id: "1967",
            subdistrict_name: "Malifut",
          },
          {
            subdistrict_id: "1968",
            subdistrict_name: "Tobelo",
          },
          {
            subdistrict_id: "1969",
            subdistrict_name: "Tobelo Barat",
          },
          {
            subdistrict_id: "1970",
            subdistrict_name: "Tobelo Selatan",
          },
          {
            subdistrict_id: "1971",
            subdistrict_name: "Tobelo Tengah",
          },
          {
            subdistrict_id: "1972",
            subdistrict_name: "Tobelo Timur",
          },
          {
            subdistrict_id: "1973",
            subdistrict_name: "Tobelo Utara",
          },
        ],
      },
      {
        city_id: "191",
        type: "Kabupaten",
        city_name: "Kepulauan Sula",
        subdistricts: [
          {
            subdistrict_id: "2634",
            subdistrict_name: "Lede",
          },
          {
            subdistrict_id: "2635",
            subdistrict_name: "Mangoli Barat",
          },
          {
            subdistrict_id: "2636",
            subdistrict_name: "Mangoli Selatan",
          },
          {
            subdistrict_id: "2637",
            subdistrict_name: "Mangoli Tengah",
          },
          {
            subdistrict_id: "2638",
            subdistrict_name: "Mangoli Timur",
          },
          {
            subdistrict_id: "2639",
            subdistrict_name: "Mangoli Utara",
          },
          {
            subdistrict_id: "2640",
            subdistrict_name: "Mangoli Utara Timur",
          },
          {
            subdistrict_id: "2641",
            subdistrict_name: "Sanana",
          },
          {
            subdistrict_id: "2642",
            subdistrict_name: "Sanana Utara",
          },
          {
            subdistrict_id: "2643",
            subdistrict_name: "Sulabesi Barat",
          },
          {
            subdistrict_id: "2644",
            subdistrict_name: "Sulabesi Selatan",
          },
          {
            subdistrict_id: "2645",
            subdistrict_name: "Sulabesi Tengah",
          },
          {
            subdistrict_id: "2646",
            subdistrict_name: "Sulabesi Timur",
          },
          {
            subdistrict_id: "2647",
            subdistrict_name: "Taliabu Barat",
          },
          {
            subdistrict_id: "2648",
            subdistrict_name: "Taliabu Barat Laut",
          },
          {
            subdistrict_id: "2649",
            subdistrict_name: "Taliabu Selatan",
          },
          {
            subdistrict_id: "2650",
            subdistrict_name: "Taliabu Timur",
          },
          {
            subdistrict_id: "2651",
            subdistrict_name: "Taliabu Timur Selatan",
          },
          {
            subdistrict_id: "2652",
            subdistrict_name: "Taliabu Utara",
          },
        ],
      },
      {
        city_id: "372",
        type: "Kabupaten",
        city_name: "Pulau Morotai",
        subdistricts: [
          {
            subdistrict_id: "5178",
            subdistrict_name: "Morotai Jaya",
          },
          {
            subdistrict_id: "5179",
            subdistrict_name: "Morotai Selatan",
          },
          {
            subdistrict_id: "5180",
            subdistrict_name: "Morotai Selatan Barat",
          },
          {
            subdistrict_id: "5181",
            subdistrict_name: "Morotai Timur",
          },
          {
            subdistrict_id: "5182",
            subdistrict_name: "Morotai Utara",
          },
        ],
      },
      {
        city_id: "477",
        type: "Kota",
        city_name: "Ternate",
        subdistricts: [
          {
            subdistrict_id: "6581",
            subdistrict_name: "Moti (Pulau Moti)",
          },
          {
            subdistrict_id: "6582",
            subdistrict_name: "Pulau Batang Dua",
          },
          {
            subdistrict_id: "6583",
            subdistrict_name: "Pulau Hiri",
          },
          {
            subdistrict_id: "6584",
            subdistrict_name: "Pulau Ternate",
          },
          {
            subdistrict_id: "6585",
            subdistrict_name: "Ternate Selatan (Kota)",
          },
          {
            subdistrict_id: "6586",
            subdistrict_name: "Ternate Tengah (Kota)",
          },
          {
            subdistrict_id: "6587",
            subdistrict_name: "Ternate Utara (Kota)",
          },
        ],
      },
      {
        city_id: "478",
        type: "Kota",
        city_name: "Tidore Kepulauan",
        subdistricts: [
          {
            subdistrict_id: "6588",
            subdistrict_name: "Oba",
          },
          {
            subdistrict_id: "6589",
            subdistrict_name: "Oba Selatan",
          },
          {
            subdistrict_id: "6590",
            subdistrict_name: "Oba Tengah",
          },
          {
            subdistrict_id: "6591",
            subdistrict_name: "Oba Utara",
          },
          {
            subdistrict_id: "6592",
            subdistrict_name: "Tidore (Pulau Tidore)",
          },
          {
            subdistrict_id: "6593",
            subdistrict_name: "Tidore Selatan",
          },
          {
            subdistrict_id: "6594",
            subdistrict_name: "Tidore Timur (Pulau Tidore)",
          },
          {
            subdistrict_id: "6595",
            subdistrict_name: "Tidore Utara",
          },
        ],
      },
    ],
  },
  {
    province_id: "21",
    province: "Nanggroe Aceh Darussalam (NAD)",
    cities: [
      {
        city_id: "1",
        type: "Kabupaten",
        city_name: "Aceh Barat",
        subdistricts: [
          {
            subdistrict_id: "1",
            subdistrict_name: "Arongan Lambalek",
          },
          {
            subdistrict_id: "2",
            subdistrict_name: "Bubon",
          },
          {
            subdistrict_id: "3",
            subdistrict_name: "Johan Pahlawan",
          },
          {
            subdistrict_id: "4",
            subdistrict_name: "Kaway XVI",
          },
          {
            subdistrict_id: "5",
            subdistrict_name: "Meureubo",
          },
          {
            subdistrict_id: "6",
            subdistrict_name: "Pante Ceureumen (Pantai Ceuremen)",
          },
          {
            subdistrict_id: "7",
            subdistrict_name: "Panton Reu",
          },
          {
            subdistrict_id: "8",
            subdistrict_name: "Samatiga",
          },
          {
            subdistrict_id: "9",
            subdistrict_name: "Sungai Mas",
          },
          {
            subdistrict_id: "10",
            subdistrict_name: "Woyla",
          },
          {
            subdistrict_id: "11",
            subdistrict_name: "Woyla Barat",
          },
          {
            subdistrict_id: "12",
            subdistrict_name: "Woyla Timur",
          },
        ],
      },
      {
        city_id: "2",
        type: "Kabupaten",
        city_name: "Aceh Barat Daya",
        subdistricts: [
          {
            subdistrict_id: "13",
            subdistrict_name: "Babah Rot",
          },
          {
            subdistrict_id: "14",
            subdistrict_name: "Blang Pidie",
          },
          {
            subdistrict_id: "15",
            subdistrict_name: "Jeumpa",
          },
          {
            subdistrict_id: "16",
            subdistrict_name: "Kuala Batee",
          },
          {
            subdistrict_id: "17",
            subdistrict_name: "Lembah Sabil",
          },
          {
            subdistrict_id: "18",
            subdistrict_name: "Manggeng",
          },
          {
            subdistrict_id: "19",
            subdistrict_name: "Setia",
          },
          {
            subdistrict_id: "20",
            subdistrict_name: "Susoh",
          },
          {
            subdistrict_id: "21",
            subdistrict_name: "Tangan-Tangan",
          },
        ],
      },
      {
        city_id: "3",
        type: "Kabupaten",
        city_name: "Aceh Besar",
        subdistricts: [
          {
            subdistrict_id: "22",
            subdistrict_name: "Baitussalam",
          },
          {
            subdistrict_id: "23",
            subdistrict_name: "Blank Bintang",
          },
          {
            subdistrict_id: "24",
            subdistrict_name: "Darul Imarah",
          },
          {
            subdistrict_id: "25",
            subdistrict_name: "Darul Kamal",
          },
          {
            subdistrict_id: "26",
            subdistrict_name: "Darussalam",
          },
          {
            subdistrict_id: "27",
            subdistrict_name: "Indrapuri",
          },
          {
            subdistrict_id: "28",
            subdistrict_name: "Ingin Jaya",
          },
          {
            subdistrict_id: "29",
            subdistrict_name: "Kota Cot Glie (Kuta Cot Glie)",
          },
          {
            subdistrict_id: "30",
            subdistrict_name: "Kota Jantho",
          },
          {
            subdistrict_id: "31",
            subdistrict_name: "Kota Malaka (Kuta Malaka)",
          },
          {
            subdistrict_id: "32",
            subdistrict_name: "Krueng Barona Jaya",
          },
          {
            subdistrict_id: "33",
            subdistrict_name: "Kuta Baro",
          },
          {
            subdistrict_id: "34",
            subdistrict_name: "Lembah Seulawah",
          },
          {
            subdistrict_id: "35",
            subdistrict_name: "Leupung",
          },
          {
            subdistrict_id: "36",
            subdistrict_name: "Lhoknga (Lho'nga)",
          },
          {
            subdistrict_id: "37",
            subdistrict_name: "Lhoong",
          },
          {
            subdistrict_id: "38",
            subdistrict_name: "Mantasiek (Montasik)",
          },
          {
            subdistrict_id: "39",
            subdistrict_name: "Mesjid Raya",
          },
          {
            subdistrict_id: "40",
            subdistrict_name: "Peukan Bada",
          },
          {
            subdistrict_id: "41",
            subdistrict_name: "Pulo Aceh",
          },
          {
            subdistrict_id: "42",
            subdistrict_name: "Seulimeum",
          },
          {
            subdistrict_id: "43",
            subdistrict_name: "Simpang Tiga",
          },
          {
            subdistrict_id: "44",
            subdistrict_name: "Suka Makmur",
          },
        ],
      },
      {
        city_id: "4",
        type: "Kabupaten",
        city_name: "Aceh Jaya",
        subdistricts: [
          {
            subdistrict_id: "45",
            subdistrict_name: "Darul Hikmah",
          },
          {
            subdistrict_id: "46",
            subdistrict_name: "Indra Jaya",
          },
          {
            subdistrict_id: "47",
            subdistrict_name: "Jaya",
          },
          {
            subdistrict_id: "48",
            subdistrict_name: "Keude Panga",
          },
          {
            subdistrict_id: "49",
            subdistrict_name: "Krueng Sabee",
          },
          {
            subdistrict_id: "50",
            subdistrict_name: "Pasie Raya",
          },
          {
            subdistrict_id: "51",
            subdistrict_name: "Sampoiniet",
          },
          {
            subdistrict_id: "52",
            subdistrict_name: "Setia Bakti",
          },
          {
            subdistrict_id: "53",
            subdistrict_name: "Teunom",
          },
        ],
      },
      {
        city_id: "5",
        type: "Kabupaten",
        city_name: "Aceh Selatan",
        subdistricts: [
          {
            subdistrict_id: "54",
            subdistrict_name: "Bakongan",
          },
          {
            subdistrict_id: "55",
            subdistrict_name: "Bakongan Timur",
          },
          {
            subdistrict_id: "56",
            subdistrict_name: "Kluet Selatan",
          },
          {
            subdistrict_id: "57",
            subdistrict_name: "Kluet Tengah",
          },
          {
            subdistrict_id: "58",
            subdistrict_name: "Kluet Timur",
          },
          {
            subdistrict_id: "59",
            subdistrict_name: "Kluet Utara",
          },
          {
            subdistrict_id: "60",
            subdistrict_name: "Kota Bahagia",
          },
          {
            subdistrict_id: "61",
            subdistrict_name: "Labuhan Haji",
          },
          {
            subdistrict_id: "62",
            subdistrict_name: "Labuhan Haji Barat",
          },
          {
            subdistrict_id: "63",
            subdistrict_name: "Labuhan Haji Timur",
          },
          {
            subdistrict_id: "64",
            subdistrict_name: "Meukek",
          },
          {
            subdistrict_id: "65",
            subdistrict_name: "Pasie Raja",
          },
          {
            subdistrict_id: "66",
            subdistrict_name: "Sama Dua",
          },
          {
            subdistrict_id: "67",
            subdistrict_name: "Sawang",
          },
          {
            subdistrict_id: "68",
            subdistrict_name: "Tapak Tuan",
          },
          {
            subdistrict_id: "69",
            subdistrict_name: "Trumon",
          },
          {
            subdistrict_id: "70",
            subdistrict_name: "Trumon Tengah",
          },
          {
            subdistrict_id: "71",
            subdistrict_name: "Trumon Timur",
          },
        ],
      },
      {
        city_id: "6",
        type: "Kabupaten",
        city_name: "Aceh Singkil",
        subdistricts: [
          {
            subdistrict_id: "72",
            subdistrict_name: "Danau Paris",
          },
          {
            subdistrict_id: "73",
            subdistrict_name: "Gunung Meriah (Mariah)",
          },
          {
            subdistrict_id: "74",
            subdistrict_name: "Kota Baharu",
          },
          {
            subdistrict_id: "75",
            subdistrict_name: "Kuala Baru",
          },
          {
            subdistrict_id: "76",
            subdistrict_name: "Pulau Banyak",
          },
          {
            subdistrict_id: "77",
            subdistrict_name: "Pulau Banyak Barat",
          },
          {
            subdistrict_id: "78",
            subdistrict_name: "Simpang Kanan",
          },
          {
            subdistrict_id: "79",
            subdistrict_name: "Singkil",
          },
          {
            subdistrict_id: "80",
            subdistrict_name: "Singkil Utara",
          },
          {
            subdistrict_id: "81",
            subdistrict_name: "Singkohor",
          },
          {
            subdistrict_id: "82",
            subdistrict_name: "Suro Makmur",
          },
        ],
      },
      {
        city_id: "7",
        type: "Kabupaten",
        city_name: "Aceh Tamiang",
        subdistricts: [
          {
            subdistrict_id: "83",
            subdistrict_name: "Banda Mulia",
          },
          {
            subdistrict_id: "84",
            subdistrict_name: "Bandar Pusaka",
          },
          {
            subdistrict_id: "85",
            subdistrict_name: "Bendahara",
          },
          {
            subdistrict_id: "86",
            subdistrict_name: "Karang Baru",
          },
          {
            subdistrict_id: "87",
            subdistrict_name: "Kejuruan Muda",
          },
          {
            subdistrict_id: "88",
            subdistrict_name: "Kota Kuala Simpang",
          },
          {
            subdistrict_id: "89",
            subdistrict_name: "Manyak Payed",
          },
          {
            subdistrict_id: "90",
            subdistrict_name: "Rantau",
          },
          {
            subdistrict_id: "91",
            subdistrict_name: "Sekerak",
          },
          {
            subdistrict_id: "92",
            subdistrict_name: "Seruway",
          },
          {
            subdistrict_id: "93",
            subdistrict_name: "Tamiang Hulu",
          },
          {
            subdistrict_id: "94",
            subdistrict_name: "Tenggulun",
          },
        ],
      },
      {
        city_id: "8",
        type: "Kabupaten",
        city_name: "Aceh Tengah",
        subdistricts: [
          {
            subdistrict_id: "95",
            subdistrict_name: "Atu Lintang",
          },
          {
            subdistrict_id: "96",
            subdistrict_name: "Bebesen",
          },
          {
            subdistrict_id: "97",
            subdistrict_name: "Bies",
          },
          {
            subdistrict_id: "98",
            subdistrict_name: "Bintang",
          },
          {
            subdistrict_id: "99",
            subdistrict_name: "Celala",
          },
          {
            subdistrict_id: "100",
            subdistrict_name: "Jagong Jeget",
          },
          {
            subdistrict_id: "101",
            subdistrict_name: "Kebayakan",
          },
          {
            subdistrict_id: "102",
            subdistrict_name: "Ketol",
          },
          {
            subdistrict_id: "103",
            subdistrict_name: "Kute Panang",
          },
          {
            subdistrict_id: "104",
            subdistrict_name: "Linge",
          },
          {
            subdistrict_id: "105",
            subdistrict_name: "Lut Tawar",
          },
          {
            subdistrict_id: "106",
            subdistrict_name: "Pegasing",
          },
          {
            subdistrict_id: "107",
            subdistrict_name: "Rusip Antara",
          },
          {
            subdistrict_id: "108",
            subdistrict_name: "Silih Nara",
          },
        ],
      },
      {
        city_id: "9",
        type: "Kabupaten",
        city_name: "Aceh Tenggara",
        subdistricts: [
          {
            subdistrict_id: "109",
            subdistrict_name: "Babul Makmur",
          },
          {
            subdistrict_id: "110",
            subdistrict_name: "Babul Rahmah",
          },
          {
            subdistrict_id: "111",
            subdistrict_name: "Babussalam",
          },
          {
            subdistrict_id: "112",
            subdistrict_name: "Badar",
          },
          {
            subdistrict_id: "113",
            subdistrict_name: "Bambel",
          },
          {
            subdistrict_id: "114",
            subdistrict_name: "Bukit Tusam",
          },
          {
            subdistrict_id: "115",
            subdistrict_name: "Darul Hasanah",
          },
          {
            subdistrict_id: "116",
            subdistrict_name: "Deleng Pokhisen",
          },
          {
            subdistrict_id: "117",
            subdistrict_name: "Ketambe",
          },
          {
            subdistrict_id: "118",
            subdistrict_name: "Lawe Alas",
          },
          {
            subdistrict_id: "119",
            subdistrict_name: "Lawe Bulan",
          },
          {
            subdistrict_id: "120",
            subdistrict_name: "Lawe Sigala-Gala",
          },
          {
            subdistrict_id: "121",
            subdistrict_name: "Lawe Sumur",
          },
          {
            subdistrict_id: "122",
            subdistrict_name: "Leuser",
          },
          {
            subdistrict_id: "123",
            subdistrict_name: "Semadam",
          },
          {
            subdistrict_id: "124",
            subdistrict_name: "Tanah Alas",
          },
        ],
      },
      {
        city_id: "10",
        type: "Kabupaten",
        city_name: "Aceh Timur",
        subdistricts: [
          {
            subdistrict_id: "125",
            subdistrict_name: "Banda Alam",
          },
          {
            subdistrict_id: "126",
            subdistrict_name: "Birem Bayeun",
          },
          {
            subdistrict_id: "127",
            subdistrict_name: "Darul Aman",
          },
          {
            subdistrict_id: "128",
            subdistrict_name: "Darul Falah",
          },
          {
            subdistrict_id: "129",
            subdistrict_name: "Darul Iksan (Ihsan)",
          },
          {
            subdistrict_id: "130",
            subdistrict_name: "Idi Rayeuk",
          },
          {
            subdistrict_id: "131",
            subdistrict_name: "Idi Timur",
          },
          {
            subdistrict_id: "132",
            subdistrict_name: "Idi Tunong",
          },
          {
            subdistrict_id: "133",
            subdistrict_name: "Indra Makmur",
          },
          {
            subdistrict_id: "134",
            subdistrict_name: "Julok",
          },
          {
            subdistrict_id: "135",
            subdistrict_name: "Madat",
          },
          {
            subdistrict_id: "136",
            subdistrict_name: "Nurussalam",
          },
          {
            subdistrict_id: "137",
            subdistrict_name: "Pante Bidari (Beudari)",
          },
          {
            subdistrict_id: "138",
            subdistrict_name: "Peudawa",
          },
          {
            subdistrict_id: "139",
            subdistrict_name: "Peunaron",
          },
          {
            subdistrict_id: "140",
            subdistrict_name: "Peureulak",
          },
          {
            subdistrict_id: "141",
            subdistrict_name: "Peureulak Barat",
          },
          {
            subdistrict_id: "142",
            subdistrict_name: "Peureulak Timur",
          },
          {
            subdistrict_id: "143",
            subdistrict_name: "Rantau Selamat",
          },
          {
            subdistrict_id: "144",
            subdistrict_name: "Ranto Peureulak",
          },
          {
            subdistrict_id: "145",
            subdistrict_name: "Serba Jadi",
          },
          {
            subdistrict_id: "146",
            subdistrict_name: "Simpang Jernih",
          },
          {
            subdistrict_id: "147",
            subdistrict_name: "Simpang Ulim",
          },
          {
            subdistrict_id: "148",
            subdistrict_name: "Sungai Raya",
          },
        ],
      },
      {
        city_id: "11",
        type: "Kabupaten",
        city_name: "Aceh Utara",
        subdistricts: [
          {
            subdistrict_id: "149",
            subdistrict_name: "Baktiya",
          },
          {
            subdistrict_id: "150",
            subdistrict_name: "Baktiya Barat",
          },
          {
            subdistrict_id: "151",
            subdistrict_name: "Banda Baro",
          },
          {
            subdistrict_id: "152",
            subdistrict_name: "Cot Girek",
          },
          {
            subdistrict_id: "153",
            subdistrict_name: "Dewantara",
          },
          {
            subdistrict_id: "154",
            subdistrict_name: "Geuredong Pase",
          },
          {
            subdistrict_id: "155",
            subdistrict_name: "Kuta Makmur",
          },
          {
            subdistrict_id: "156",
            subdistrict_name: "Langkahan",
          },
          {
            subdistrict_id: "157",
            subdistrict_name: "Lapang",
          },
          {
            subdistrict_id: "158",
            subdistrict_name: "Lhoksukon",
          },
          {
            subdistrict_id: "159",
            subdistrict_name: "Matangkuli",
          },
          {
            subdistrict_id: "160",
            subdistrict_name: "Meurah Mulia",
          },
          {
            subdistrict_id: "161",
            subdistrict_name: "Muara Batu",
          },
          {
            subdistrict_id: "162",
            subdistrict_name: "Nibong",
          },
          {
            subdistrict_id: "163",
            subdistrict_name: "Nisam",
          },
          {
            subdistrict_id: "164",
            subdistrict_name: "Nisam Antara",
          },
          {
            subdistrict_id: "165",
            subdistrict_name: "Paya Bakong",
          },
          {
            subdistrict_id: "166",
            subdistrict_name: "Pirak Timur",
          },
          {
            subdistrict_id: "167",
            subdistrict_name: "Samudera",
          },
          {
            subdistrict_id: "168",
            subdistrict_name: "Sawang",
          },
          {
            subdistrict_id: "169",
            subdistrict_name: "Seunuddon (Seunudon)",
          },
          {
            subdistrict_id: "170",
            subdistrict_name: "Simpang Kramat (Keramat)",
          },
          {
            subdistrict_id: "171",
            subdistrict_name: "Syamtalira Aron",
          },
          {
            subdistrict_id: "172",
            subdistrict_name: "Syamtalira Bayu",
          },
          {
            subdistrict_id: "173",
            subdistrict_name: "Tanah Jambo Aye",
          },
          {
            subdistrict_id: "174",
            subdistrict_name: "Tanah Luas",
          },
          {
            subdistrict_id: "175",
            subdistrict_name: "Tanah Pasir",
          },
        ],
      },
      {
        city_id: "20",
        type: "Kota",
        city_name: "Banda Aceh",
        subdistricts: [
          {
            subdistrict_id: "278",
            subdistrict_name: "Baiturrahman",
          },
          {
            subdistrict_id: "279",
            subdistrict_name: "Banda Raya",
          },
          {
            subdistrict_id: "280",
            subdistrict_name: "Jaya Baru",
          },
          {
            subdistrict_id: "281",
            subdistrict_name: "Kuta Alam",
          },
          {
            subdistrict_id: "282",
            subdistrict_name: "Kuta Raja",
          },
          {
            subdistrict_id: "283",
            subdistrict_name: "Lueng Bata",
          },
          {
            subdistrict_id: "284",
            subdistrict_name: "Meuraxa",
          },
          {
            subdistrict_id: "285",
            subdistrict_name: "Syiah Kuala",
          },
          {
            subdistrict_id: "286",
            subdistrict_name: "Ulee Kareng",
          },
        ],
      },
      {
        city_id: "59",
        type: "Kabupaten",
        city_name: "Bener Meriah",
        subdistricts: [
          {
            subdistrict_id: "797",
            subdistrict_name: "Bandar",
          },
          {
            subdistrict_id: "798",
            subdistrict_name: "Bener Kelipah",
          },
          {
            subdistrict_id: "799",
            subdistrict_name: "Bukit",
          },
          {
            subdistrict_id: "800",
            subdistrict_name: "Gajah Putih",
          },
          {
            subdistrict_id: "801",
            subdistrict_name: "Mesidah",
          },
          {
            subdistrict_id: "802",
            subdistrict_name: "Permata",
          },
          {
            subdistrict_id: "803",
            subdistrict_name: "Pintu Rime Gayo",
          },
          {
            subdistrict_id: "804",
            subdistrict_name: "Syiah Utama",
          },
          {
            subdistrict_id: "805",
            subdistrict_name: "Timang Gajah",
          },
          {
            subdistrict_id: "806",
            subdistrict_name: "Wih Pesam",
          },
        ],
      },
      {
        city_id: "72",
        type: "Kabupaten",
        city_name: "Bireuen",
        subdistricts: [
          {
            subdistrict_id: "949",
            subdistrict_name: "Ganda Pura",
          },
          {
            subdistrict_id: "950",
            subdistrict_name: "Jangka",
          },
          {
            subdistrict_id: "951",
            subdistrict_name: "Jeumpa",
          },
          {
            subdistrict_id: "952",
            subdistrict_name: "Jeunieb",
          },
          {
            subdistrict_id: "953",
            subdistrict_name: "Juli",
          },
          {
            subdistrict_id: "954",
            subdistrict_name: "Kota Juang",
          },
          {
            subdistrict_id: "955",
            subdistrict_name: "Kuala",
          },
          {
            subdistrict_id: "956",
            subdistrict_name: "Kuta Blang",
          },
          {
            subdistrict_id: "957",
            subdistrict_name: "Makmur",
          },
          {
            subdistrict_id: "958",
            subdistrict_name: "Pandrah",
          },
          {
            subdistrict_id: "959",
            subdistrict_name: "Peudada",
          },
          {
            subdistrict_id: "960",
            subdistrict_name: "Peulimbang (Plimbang)",
          },
          {
            subdistrict_id: "961",
            subdistrict_name: "Peusangan",
          },
          {
            subdistrict_id: "962",
            subdistrict_name: "Peusangan Selatan",
          },
          {
            subdistrict_id: "963",
            subdistrict_name: "Peusangan Siblah Krueng",
          },
          {
            subdistrict_id: "964",
            subdistrict_name: "Samalanga",
          },
          {
            subdistrict_id: "965",
            subdistrict_name: "Simpang Mamplam",
          },
        ],
      },
      {
        city_id: "127",
        type: "Kabupaten",
        city_name: "Gayo Lues",
        subdistricts: [
          {
            subdistrict_id: "1753",
            subdistrict_name: "Blang Jerango",
          },
          {
            subdistrict_id: "1754",
            subdistrict_name: "Blang Kejeren",
          },
          {
            subdistrict_id: "1755",
            subdistrict_name: "Blang Pegayon",
          },
          {
            subdistrict_id: "1756",
            subdistrict_name: "Dabun Gelang (Debun Gelang)",
          },
          {
            subdistrict_id: "1757",
            subdistrict_name: "Kuta Panjang",
          },
          {
            subdistrict_id: "1758",
            subdistrict_name: "Pantan Cuaca",
          },
          {
            subdistrict_id: "1759",
            subdistrict_name: "Pining (Pinding)",
          },
          {
            subdistrict_id: "1760",
            subdistrict_name: "Putri Betung",
          },
          {
            subdistrict_id: "1761",
            subdistrict_name: "Rikit Gaib",
          },
          {
            subdistrict_id: "1762",
            subdistrict_name: "Terangun (Terangon)",
          },
          {
            subdistrict_id: "1763",
            subdistrict_name: "Teripe/Tripe Jaya",
          },
        ],
      },
      {
        city_id: "230",
        type: "Kota",
        city_name: "Langsa",
        subdistricts: [
          {
            subdistrict_id: "3283",
            subdistrict_name: "Langsa Barat",
          },
          {
            subdistrict_id: "3284",
            subdistrict_name: "Langsa Baro",
          },
          {
            subdistrict_id: "3285",
            subdistrict_name: "Langsa Kota",
          },
          {
            subdistrict_id: "3286",
            subdistrict_name: "Langsa Lama",
          },
          {
            subdistrict_id: "3287",
            subdistrict_name: "Langsa Timur",
          },
        ],
      },
      {
        city_id: "235",
        type: "Kota",
        city_name: "Lhokseumawe",
        subdistricts: [
          {
            subdistrict_id: "3347",
            subdistrict_name: "Banda Sakti",
          },
          {
            subdistrict_id: "3348",
            subdistrict_name: "Blang Mangat",
          },
          {
            subdistrict_id: "3349",
            subdistrict_name: "Muara Dua",
          },
          {
            subdistrict_id: "3350",
            subdistrict_name: "Muara Satu",
          },
        ],
      },
      {
        city_id: "300",
        type: "Kabupaten",
        city_name: "Nagan Raya",
        subdistricts: [
          {
            subdistrict_id: "4250",
            subdistrict_name: "Beutong",
          },
          {
            subdistrict_id: "4251",
            subdistrict_name: "Beutong Ateuh Banggalang",
          },
          {
            subdistrict_id: "4252",
            subdistrict_name: "Darul Makmur",
          },
          {
            subdistrict_id: "4253",
            subdistrict_name: "Kuala",
          },
          {
            subdistrict_id: "4254",
            subdistrict_name: "Kuala Pesisir",
          },
          {
            subdistrict_id: "4255",
            subdistrict_name: "Seunagan",
          },
          {
            subdistrict_id: "4256",
            subdistrict_name: "Seunagan Timur",
          },
          {
            subdistrict_id: "4257",
            subdistrict_name: "Suka Makmue",
          },
          {
            subdistrict_id: "4258",
            subdistrict_name: "Tadu Raya",
          },
          {
            subdistrict_id: "4259",
            subdistrict_name: "Tripa Makmur",
          },
        ],
      },
      {
        city_id: "358",
        type: "Kabupaten",
        city_name: "Pidie",
        subdistricts: [
          {
            subdistrict_id: "4999",
            subdistrict_name: "Batee",
          },
          {
            subdistrict_id: "5000",
            subdistrict_name: "Delima",
          },
          {
            subdistrict_id: "5001",
            subdistrict_name: "Geumpang",
          },
          {
            subdistrict_id: "5002",
            subdistrict_name: "Glumpang Baro",
          },
          {
            subdistrict_id: "5003",
            subdistrict_name: "Glumpang Tiga (Geulumpang Tiga)",
          },
          {
            subdistrict_id: "5004",
            subdistrict_name: "Grong Grong",
          },
          {
            subdistrict_id: "5005",
            subdistrict_name: "Indrajaya",
          },
          {
            subdistrict_id: "5006",
            subdistrict_name: "Kembang Tanjong (Tanjung)",
          },
          {
            subdistrict_id: "5007",
            subdistrict_name: "Keumala",
          },
          {
            subdistrict_id: "5008",
            subdistrict_name: "Kota Sigli",
          },
          {
            subdistrict_id: "5009",
            subdistrict_name: "Mane",
          },
          {
            subdistrict_id: "5010",
            subdistrict_name: "Mila",
          },
          {
            subdistrict_id: "5011",
            subdistrict_name: "Muara Tiga",
          },
          {
            subdistrict_id: "5012",
            subdistrict_name: "Mutiara",
          },
          {
            subdistrict_id: "5013",
            subdistrict_name: "Mutiara Timur",
          },
          {
            subdistrict_id: "5014",
            subdistrict_name: "Padang Tiji",
          },
          {
            subdistrict_id: "5015",
            subdistrict_name: "Peukan Baro",
          },
          {
            subdistrict_id: "5016",
            subdistrict_name: "Pidie",
          },
          {
            subdistrict_id: "5017",
            subdistrict_name: "Sakti",
          },
          {
            subdistrict_id: "5018",
            subdistrict_name: "Simpang Tiga",
          },
          {
            subdistrict_id: "5019",
            subdistrict_name: "Tangse",
          },
          {
            subdistrict_id: "5020",
            subdistrict_name: "Tiro/Truseb",
          },
          {
            subdistrict_id: "5021",
            subdistrict_name: "Titeue",
          },
        ],
      },
      {
        city_id: "359",
        type: "Kabupaten",
        city_name: "Pidie Jaya",
        subdistricts: [
          {
            subdistrict_id: "5022",
            subdistrict_name: "Bandar Baru",
          },
          {
            subdistrict_id: "5023",
            subdistrict_name: "Bandar Dua",
          },
          {
            subdistrict_id: "5024",
            subdistrict_name: "Jangka Buya",
          },
          {
            subdistrict_id: "5025",
            subdistrict_name: "Meurah Dua",
          },
          {
            subdistrict_id: "5026",
            subdistrict_name: "Meureudu",
          },
          {
            subdistrict_id: "5027",
            subdistrict_name: "Panteraja",
          },
          {
            subdistrict_id: "5028",
            subdistrict_name: "Trienggadeng",
          },
          {
            subdistrict_id: "5029",
            subdistrict_name: "Ulim",
          },
        ],
      },
      {
        city_id: "384",
        type: "Kota",
        city_name: "Sabang",
        subdistricts: [
          {
            subdistrict_id: "5344",
            subdistrict_name: "Sukajaya",
          },
          {
            subdistrict_id: "5345",
            subdistrict_name: "Sukakarya",
          },
        ],
      },
      {
        city_id: "414",
        type: "Kabupaten",
        city_name: "Simeulue",
        subdistricts: [
          {
            subdistrict_id: "5724",
            subdistrict_name: "Alapan (Alafan)",
          },
          {
            subdistrict_id: "5725",
            subdistrict_name: "Salang",
          },
          {
            subdistrict_id: "5726",
            subdistrict_name: "Simeuleu Barat",
          },
          {
            subdistrict_id: "5727",
            subdistrict_name: "Simeuleu Tengah",
          },
          {
            subdistrict_id: "5728",
            subdistrict_name: "Simeuleu Timur",
          },
          {
            subdistrict_id: "5729",
            subdistrict_name: "Simeulue Cut",
          },
          {
            subdistrict_id: "5730",
            subdistrict_name: "Teluk Dalam",
          },
          {
            subdistrict_id: "5731",
            subdistrict_name: "Teupah Barat",
          },
          {
            subdistrict_id: "5732",
            subdistrict_name: "Teupah Selatan",
          },
          {
            subdistrict_id: "5733",
            subdistrict_name: "Teupah Tengah",
          },
        ],
      },
      {
        city_id: "429",
        type: "Kota",
        city_name: "Subulussalam",
        subdistricts: [
          {
            subdistrict_id: "5913",
            subdistrict_name: "Longkib",
          },
          {
            subdistrict_id: "5914",
            subdistrict_name: "Penanggalan",
          },
          {
            subdistrict_id: "5915",
            subdistrict_name: "Rundeng",
          },
          {
            subdistrict_id: "5916",
            subdistrict_name: "Simpang Kiri",
          },
          {
            subdistrict_id: "5917",
            subdistrict_name: "Sultan Daulat",
          },
        ],
      },
    ],
  },
  {
    province_id: "22",
    province: "Nusa Tenggara Barat (NTB)",
    cities: [
      {
        city_id: "68",
        type: "Kabupaten",
        city_name: "Bima",
        subdistricts: [
          {
            subdistrict_id: "911",
            subdistrict_name: "Ambalawi",
          },
          {
            subdistrict_id: "912",
            subdistrict_name: "Belo",
          },
          {
            subdistrict_id: "913",
            subdistrict_name: "Bolo",
          },
          {
            subdistrict_id: "914",
            subdistrict_name: "Donggo",
          },
          {
            subdistrict_id: "915",
            subdistrict_name: "Lambitu",
          },
          {
            subdistrict_id: "916",
            subdistrict_name: "Lambu",
          },
          {
            subdistrict_id: "917",
            subdistrict_name: "Langgudu",
          },
          {
            subdistrict_id: "918",
            subdistrict_name: "Madapangga",
          },
          {
            subdistrict_id: "919",
            subdistrict_name: "Monta",
          },
          {
            subdistrict_id: "920",
            subdistrict_name: "Palibelo",
          },
          {
            subdistrict_id: "921",
            subdistrict_name: "Parado",
          },
          {
            subdistrict_id: "922",
            subdistrict_name: "Sanggar",
          },
          {
            subdistrict_id: "923",
            subdistrict_name: "Sape",
          },
          {
            subdistrict_id: "924",
            subdistrict_name: "Soromandi",
          },
          {
            subdistrict_id: "925",
            subdistrict_name: "Tambora",
          },
          {
            subdistrict_id: "926",
            subdistrict_name: "Wawo",
          },
          {
            subdistrict_id: "927",
            subdistrict_name: "Wera",
          },
          {
            subdistrict_id: "928",
            subdistrict_name: "Woha",
          },
        ],
      },
      {
        city_id: "69",
        type: "Kota",
        city_name: "Bima",
        subdistricts: [
          {
            subdistrict_id: "929",
            subdistrict_name: "Asakota",
          },
          {
            subdistrict_id: "930",
            subdistrict_name: "Mpunda",
          },
          {
            subdistrict_id: "931",
            subdistrict_name: "Raba",
          },
          {
            subdistrict_id: "932",
            subdistrict_name: "Rasanae Barat",
          },
          {
            subdistrict_id: "933",
            subdistrict_name: "Rasanae Timur",
          },
        ],
      },
      {
        city_id: "118",
        type: "Kabupaten",
        city_name: "Dompu",
        subdistricts: [
          {
            subdistrict_id: "1609",
            subdistrict_name: "Dompu",
          },
          {
            subdistrict_id: "1610",
            subdistrict_name: "Hu'u",
          },
          {
            subdistrict_id: "1611",
            subdistrict_name: "Kempo",
          },
          {
            subdistrict_id: "1612",
            subdistrict_name: "Kilo",
          },
          {
            subdistrict_id: "1613",
            subdistrict_name: "Menggelewa (Manggelewa)",
          },
          {
            subdistrict_id: "1614",
            subdistrict_name: "Pajo",
          },
          {
            subdistrict_id: "1615",
            subdistrict_name: "Pekat",
          },
          {
            subdistrict_id: "1616",
            subdistrict_name: "Woja",
          },
        ],
      },
      {
        city_id: "238",
        type: "Kabupaten",
        city_name: "Lombok Barat",
        subdistricts: [
          {
            subdistrict_id: "3372",
            subdistrict_name: "Batu Layar",
          },
          {
            subdistrict_id: "3373",
            subdistrict_name: "Gerung",
          },
          {
            subdistrict_id: "3374",
            subdistrict_name: "Gunungsari",
          },
          {
            subdistrict_id: "3375",
            subdistrict_name: "Kediri",
          },
          {
            subdistrict_id: "3376",
            subdistrict_name: "Kuripan",
          },
          {
            subdistrict_id: "3377",
            subdistrict_name: "Labuapi",
          },
          {
            subdistrict_id: "3378",
            subdistrict_name: "Lembar",
          },
          {
            subdistrict_id: "3379",
            subdistrict_name: "Lingsar",
          },
          {
            subdistrict_id: "3380",
            subdistrict_name: "Narmada",
          },
          {
            subdistrict_id: "3381",
            subdistrict_name: "Sekotong",
          },
        ],
      },
      {
        city_id: "239",
        type: "Kabupaten",
        city_name: "Lombok Tengah",
        subdistricts: [
          {
            subdistrict_id: "3382",
            subdistrict_name: "Batukliang",
          },
          {
            subdistrict_id: "3383",
            subdistrict_name: "Batukliang Utara",
          },
          {
            subdistrict_id: "3384",
            subdistrict_name: "Janapria",
          },
          {
            subdistrict_id: "3385",
            subdistrict_name: "Jonggat",
          },
          {
            subdistrict_id: "3386",
            subdistrict_name: "Kopang",
          },
          {
            subdistrict_id: "3387",
            subdistrict_name: "Praya",
          },
          {
            subdistrict_id: "3388",
            subdistrict_name: "Praya Barat",
          },
          {
            subdistrict_id: "3389",
            subdistrict_name: "Praya Barat Daya",
          },
          {
            subdistrict_id: "3390",
            subdistrict_name: "Praya Tengah",
          },
          {
            subdistrict_id: "3391",
            subdistrict_name: "Praya Timur",
          },
          {
            subdistrict_id: "3392",
            subdistrict_name: "Pringgarata",
          },
          {
            subdistrict_id: "3393",
            subdistrict_name: "Pujut",
          },
        ],
      },
      {
        city_id: "240",
        type: "Kabupaten",
        city_name: "Lombok Timur",
        subdistricts: [
          {
            subdistrict_id: "3394",
            subdistrict_name: "Aikmel",
          },
          {
            subdistrict_id: "3395",
            subdistrict_name: "Jerowaru",
          },
          {
            subdistrict_id: "3396",
            subdistrict_name: "Keruak",
          },
          {
            subdistrict_id: "3397",
            subdistrict_name: "Labuhan Haji",
          },
          {
            subdistrict_id: "3398",
            subdistrict_name: "Masbagik",
          },
          {
            subdistrict_id: "3399",
            subdistrict_name: "Montong Gading",
          },
          {
            subdistrict_id: "3400",
            subdistrict_name: "Pringgabaya",
          },
          {
            subdistrict_id: "3401",
            subdistrict_name: "Pringgasela",
          },
          {
            subdistrict_id: "3402",
            subdistrict_name: "Sakra",
          },
          {
            subdistrict_id: "3403",
            subdistrict_name: "Sakra Barat",
          },
          {
            subdistrict_id: "3404",
            subdistrict_name: "Sakra Timur",
          },
          {
            subdistrict_id: "3405",
            subdistrict_name: "Sambalia (Sambelia)",
          },
          {
            subdistrict_id: "3406",
            subdistrict_name: "Selong",
          },
          {
            subdistrict_id: "3407",
            subdistrict_name: "Sembalun",
          },
          {
            subdistrict_id: "3408",
            subdistrict_name: "Sikur",
          },
          {
            subdistrict_id: "3409",
            subdistrict_name: "Suela (Suwela)",
          },
          {
            subdistrict_id: "3410",
            subdistrict_name: "Sukamulia",
          },
          {
            subdistrict_id: "3411",
            subdistrict_name: "Suralaga",
          },
          {
            subdistrict_id: "3412",
            subdistrict_name: "Terara",
          },
          {
            subdistrict_id: "3413",
            subdistrict_name: "Wanasaba",
          },
        ],
      },
      {
        city_id: "241",
        type: "Kabupaten",
        city_name: "Lombok Utara",
        subdistricts: [
          {
            subdistrict_id: "3414",
            subdistrict_name: "Bayan",
          },
          {
            subdistrict_id: "3415",
            subdistrict_name: "Gangga",
          },
          {
            subdistrict_id: "3416",
            subdistrict_name: "Kayangan",
          },
          {
            subdistrict_id: "3417",
            subdistrict_name: "Pemenang",
          },
          {
            subdistrict_id: "3418",
            subdistrict_name: "Tanjung",
          },
        ],
      },
      {
        city_id: "276",
        type: "Kota",
        city_name: "Mataram",
        subdistricts: [
          {
            subdistrict_id: "3876",
            subdistrict_name: "Ampenan",
          },
          {
            subdistrict_id: "3877",
            subdistrict_name: "Cakranegara",
          },
          {
            subdistrict_id: "3878",
            subdistrict_name: "Mataram",
          },
          {
            subdistrict_id: "3879",
            subdistrict_name: "Sandubaya (Sandujaya)",
          },
          {
            subdistrict_id: "3880",
            subdistrict_name: "Sekarbela",
          },
          {
            subdistrict_id: "3881",
            subdistrict_name: "Selaparang (Selaprang)",
          },
        ],
      },
      {
        city_id: "438",
        type: "Kabupaten",
        city_name: "Sumbawa",
        subdistricts: [
          {
            subdistrict_id: "6033",
            subdistrict_name: "Alas",
          },
          {
            subdistrict_id: "6034",
            subdistrict_name: "Alas Barat",
          },
          {
            subdistrict_id: "6035",
            subdistrict_name: "Batulanteh",
          },
          {
            subdistrict_id: "6036",
            subdistrict_name: "Buer",
          },
          {
            subdistrict_id: "6037",
            subdistrict_name: "Empang",
          },
          {
            subdistrict_id: "6038",
            subdistrict_name: "Labangka",
          },
          {
            subdistrict_id: "6039",
            subdistrict_name: "Labuhan Badas",
          },
          {
            subdistrict_id: "6040",
            subdistrict_name: "Lantung",
          },
          {
            subdistrict_id: "6041",
            subdistrict_name: "Lape (Lape Lopok)",
          },
          {
            subdistrict_id: "6042",
            subdistrict_name: "Lenangguar",
          },
          {
            subdistrict_id: "6043",
            subdistrict_name: "Lopok",
          },
          {
            subdistrict_id: "6044",
            subdistrict_name: "Lunyuk",
          },
          {
            subdistrict_id: "6045",
            subdistrict_name: "Maronge",
          },
          {
            subdistrict_id: "6046",
            subdistrict_name: "Moyo Hilir",
          },
          {
            subdistrict_id: "6047",
            subdistrict_name: "Moyo Hulu",
          },
          {
            subdistrict_id: "6048",
            subdistrict_name: "Moyo Utara",
          },
          {
            subdistrict_id: "6049",
            subdistrict_name: "Orong Telu",
          },
          {
            subdistrict_id: "6050",
            subdistrict_name: "Plampang",
          },
          {
            subdistrict_id: "6051",
            subdistrict_name: "Rhee",
          },
          {
            subdistrict_id: "6052",
            subdistrict_name: "Ropang",
          },
          {
            subdistrict_id: "6053",
            subdistrict_name: "Sumbawa",
          },
          {
            subdistrict_id: "6054",
            subdistrict_name: "Tarano",
          },
          {
            subdistrict_id: "6055",
            subdistrict_name: "Unter Iwes (Unterwiris)",
          },
          {
            subdistrict_id: "6056",
            subdistrict_name: "Utan",
          },
        ],
      },
      {
        city_id: "439",
        type: "Kabupaten",
        city_name: "Sumbawa Barat",
        subdistricts: [
          {
            subdistrict_id: "6057",
            subdistrict_name: "Brang Ene",
          },
          {
            subdistrict_id: "6058",
            subdistrict_name: "Brang Rea",
          },
          {
            subdistrict_id: "6059",
            subdistrict_name: "Jereweh",
          },
          {
            subdistrict_id: "6060",
            subdistrict_name: "Maluk",
          },
          {
            subdistrict_id: "6061",
            subdistrict_name: "Poto Tano",
          },
          {
            subdistrict_id: "6062",
            subdistrict_name: "Sateluk (Seteluk)",
          },
          {
            subdistrict_id: "6063",
            subdistrict_name: "Sekongkang",
          },
          {
            subdistrict_id: "6064",
            subdistrict_name: "Taliwang",
          },
        ],
      },
    ],
  },
  {
    province_id: "23",
    province: "Nusa Tenggara Timur (NTT)",
    cities: [
      {
        city_id: "13",
        type: "Kabupaten",
        city_name: "Alor",
        subdistricts: [
          {
            subdistrict_id: "192",
            subdistrict_name: "Alor Barat Daya",
          },
          {
            subdistrict_id: "193",
            subdistrict_name: "Alor Barat Laut",
          },
          {
            subdistrict_id: "194",
            subdistrict_name: "Alor Selatan",
          },
          {
            subdistrict_id: "195",
            subdistrict_name: "Alor Tengah Utara",
          },
          {
            subdistrict_id: "196",
            subdistrict_name: "Alor Timur",
          },
          {
            subdistrict_id: "197",
            subdistrict_name: "Alor Timur Laut",
          },
          {
            subdistrict_id: "198",
            subdistrict_name: "Kabola",
          },
          {
            subdistrict_id: "199",
            subdistrict_name: "Lembur",
          },
          {
            subdistrict_id: "200",
            subdistrict_name: "Mataru",
          },
          {
            subdistrict_id: "201",
            subdistrict_name: "Pantar",
          },
          {
            subdistrict_id: "202",
            subdistrict_name: "Pantar Barat",
          },
          {
            subdistrict_id: "203",
            subdistrict_name: "Pantar Barat Laut",
          },
          {
            subdistrict_id: "204",
            subdistrict_name: "Pantar Tengah",
          },
          {
            subdistrict_id: "205",
            subdistrict_name: "Pantar Timur",
          },
          {
            subdistrict_id: "206",
            subdistrict_name: "Pulau Pura",
          },
          {
            subdistrict_id: "207",
            subdistrict_name: "Pureman",
          },
          {
            subdistrict_id: "208",
            subdistrict_name: "Teluk Mutiara",
          },
        ],
      },
      {
        city_id: "58",
        type: "Kabupaten",
        city_name: "Belu",
        subdistricts: [
          {
            subdistrict_id: "773",
            subdistrict_name: "Atambua Barat",
          },
          {
            subdistrict_id: "774",
            subdistrict_name: "Atambua Kota",
          },
          {
            subdistrict_id: "775",
            subdistrict_name: "Atambua Selatan",
          },
          {
            subdistrict_id: "776",
            subdistrict_name: "Botin Leo Bele",
          },
          {
            subdistrict_id: "777",
            subdistrict_name: "Io Kufeu",
          },
          {
            subdistrict_id: "778",
            subdistrict_name: "Kakuluk Mesak",
          },
          {
            subdistrict_id: "779",
            subdistrict_name: "Kobalima",
          },
          {
            subdistrict_id: "780",
            subdistrict_name: "Kobalima Timur",
          },
          {
            subdistrict_id: "781",
            subdistrict_name: "Laen Manen",
          },
          {
            subdistrict_id: "782",
            subdistrict_name: "Lamaknen",
          },
          {
            subdistrict_id: "783",
            subdistrict_name: "Lamaknen Selatan",
          },
          {
            subdistrict_id: "784",
            subdistrict_name: "Lasiolat",
          },
          {
            subdistrict_id: "785",
            subdistrict_name: "Malaka Barat",
          },
          {
            subdistrict_id: "786",
            subdistrict_name: "Malaka Tengah",
          },
          {
            subdistrict_id: "787",
            subdistrict_name: "Malaka Timur",
          },
          {
            subdistrict_id: "788",
            subdistrict_name: "Nanaet Duabesi",
          },
          {
            subdistrict_id: "789",
            subdistrict_name: "Raihat",
          },
          {
            subdistrict_id: "790",
            subdistrict_name: "Raimanuk",
          },
          {
            subdistrict_id: "791",
            subdistrict_name: "Rinhat",
          },
          {
            subdistrict_id: "792",
            subdistrict_name: "Sasitamean",
          },
          {
            subdistrict_id: "793",
            subdistrict_name: "Tasifeto Barat",
          },
          {
            subdistrict_id: "794",
            subdistrict_name: "Tasifeto Timur",
          },
          {
            subdistrict_id: "795",
            subdistrict_name: "Weliman",
          },
          {
            subdistrict_id: "796",
            subdistrict_name: "Wewiku",
          },
        ],
      },
      {
        city_id: "122",
        type: "Kabupaten",
        city_name: "Ende",
        subdistricts: [
          {
            subdistrict_id: "1650",
            subdistrict_name: "Detukeli",
          },
          {
            subdistrict_id: "1651",
            subdistrict_name: "Detusoko",
          },
          {
            subdistrict_id: "1652",
            subdistrict_name: "Ende",
          },
          {
            subdistrict_id: "1653",
            subdistrict_name: "Ende Selatan",
          },
          {
            subdistrict_id: "1654",
            subdistrict_name: "Ende Tengah",
          },
          {
            subdistrict_id: "1655",
            subdistrict_name: "Ende Timur",
          },
          {
            subdistrict_id: "1656",
            subdistrict_name: "Ende Utara",
          },
          {
            subdistrict_id: "1657",
            subdistrict_name: "Kelimutu",
          },
          {
            subdistrict_id: "1658",
            subdistrict_name: "Kotabaru",
          },
          {
            subdistrict_id: "1659",
            subdistrict_name: "Lepembusu Kelisoke",
          },
          {
            subdistrict_id: "1660",
            subdistrict_name: "Lio Timur",
          },
          {
            subdistrict_id: "1661",
            subdistrict_name: "Maukaro",
          },
          {
            subdistrict_id: "1662",
            subdistrict_name: "Maurole",
          },
          {
            subdistrict_id: "1663",
            subdistrict_name: "Nangapanda",
          },
          {
            subdistrict_id: "1664",
            subdistrict_name: "Ndona",
          },
          {
            subdistrict_id: "1665",
            subdistrict_name: "Ndona Timur",
          },
          {
            subdistrict_id: "1666",
            subdistrict_name: "Ndori",
          },
          {
            subdistrict_id: "1667",
            subdistrict_name: "Pulau Ende",
          },
          {
            subdistrict_id: "1668",
            subdistrict_name: "Wewaria",
          },
          {
            subdistrict_id: "1669",
            subdistrict_name: "Wolojita",
          },
          {
            subdistrict_id: "1670",
            subdistrict_name: "Wolowaru",
          },
        ],
      },
      {
        city_id: "125",
        type: "Kabupaten",
        city_name: "Flores Timur",
        subdistricts: [
          {
            subdistrict_id: "1692",
            subdistrict_name: "Adonara",
          },
          {
            subdistrict_id: "1693",
            subdistrict_name: "Adonara Barat",
          },
          {
            subdistrict_id: "1694",
            subdistrict_name: "Adonara Tengah",
          },
          {
            subdistrict_id: "1695",
            subdistrict_name: "Adonara Timur",
          },
          {
            subdistrict_id: "1696",
            subdistrict_name: "Demon Pagong",
          },
          {
            subdistrict_id: "1697",
            subdistrict_name: "Ile Boleng",
          },
          {
            subdistrict_id: "1698",
            subdistrict_name: "Ile Bura",
          },
          {
            subdistrict_id: "1699",
            subdistrict_name: "Ile Mandiri",
          },
          {
            subdistrict_id: "1700",
            subdistrict_name: "Kelubagolit (Klubagolit)",
          },
          {
            subdistrict_id: "1701",
            subdistrict_name: "Larantuka",
          },
          {
            subdistrict_id: "1702",
            subdistrict_name: "Lewolema",
          },
          {
            subdistrict_id: "1703",
            subdistrict_name: "Solor Barat",
          },
          {
            subdistrict_id: "1704",
            subdistrict_name: "Solor Selatan",
          },
          {
            subdistrict_id: "1705",
            subdistrict_name: "Solor Timur",
          },
          {
            subdistrict_id: "1706",
            subdistrict_name: "Tanjung Bunga",
          },
          {
            subdistrict_id: "1707",
            subdistrict_name: "Titehena",
          },
          {
            subdistrict_id: "1708",
            subdistrict_name: "Witihama (Watihama)",
          },
          {
            subdistrict_id: "1709",
            subdistrict_name: "Wotan Ulumado",
          },
          {
            subdistrict_id: "1710",
            subdistrict_name: "Wulanggitang",
          },
        ],
      },
      {
        city_id: "212",
        type: "Kabupaten",
        city_name: "Kupang",
        subdistricts: [
          {
            subdistrict_id: "2974",
            subdistrict_name: "Amabi Oefeto",
          },
          {
            subdistrict_id: "2975",
            subdistrict_name: "Amabi Oefeto Timur",
          },
          {
            subdistrict_id: "2976",
            subdistrict_name: "Amarasi",
          },
          {
            subdistrict_id: "2977",
            subdistrict_name: "Amarasi Barat",
          },
          {
            subdistrict_id: "2978",
            subdistrict_name: "Amarasi Selatan",
          },
          {
            subdistrict_id: "2979",
            subdistrict_name: "Amarasi Timur",
          },
          {
            subdistrict_id: "2980",
            subdistrict_name: "Amfoang Barat Daya",
          },
          {
            subdistrict_id: "2981",
            subdistrict_name: "Amfoang Barat Laut",
          },
          {
            subdistrict_id: "2982",
            subdistrict_name: "Amfoang Selatan",
          },
          {
            subdistrict_id: "2983",
            subdistrict_name: "Amfoang Tengah",
          },
          {
            subdistrict_id: "2984",
            subdistrict_name: "Amfoang Timur",
          },
          {
            subdistrict_id: "2985",
            subdistrict_name: "Amfoang Utara",
          },
          {
            subdistrict_id: "2986",
            subdistrict_name: "Fatuleu",
          },
          {
            subdistrict_id: "2987",
            subdistrict_name: "Fatuleu Barat",
          },
          {
            subdistrict_id: "2988",
            subdistrict_name: "Fatuleu Tengah",
          },
          {
            subdistrict_id: "2989",
            subdistrict_name: "Kupang Barat",
          },
          {
            subdistrict_id: "2990",
            subdistrict_name: "Kupang Tengah",
          },
          {
            subdistrict_id: "2991",
            subdistrict_name: "Kupang Timur",
          },
          {
            subdistrict_id: "2992",
            subdistrict_name: "Nekamese",
          },
          {
            subdistrict_id: "2993",
            subdistrict_name: "Semau",
          },
          {
            subdistrict_id: "2994",
            subdistrict_name: "Semau Selatan",
          },
          {
            subdistrict_id: "2995",
            subdistrict_name: "Sulamu",
          },
          {
            subdistrict_id: "2996",
            subdistrict_name: "Taebenu",
          },
          {
            subdistrict_id: "2997",
            subdistrict_name: "Takari",
          },
        ],
      },
      {
        city_id: "213",
        type: "Kota",
        city_name: "Kupang",
        subdistricts: [
          {
            subdistrict_id: "2998",
            subdistrict_name: "Alak",
          },
          {
            subdistrict_id: "2999",
            subdistrict_name: "Kelapa Lima",
          },
          {
            subdistrict_id: "3000",
            subdistrict_name: "Kota Lama",
          },
          {
            subdistrict_id: "3001",
            subdistrict_name: "Kota Raja",
          },
          {
            subdistrict_id: "3002",
            subdistrict_name: "Maulafa",
          },
          {
            subdistrict_id: "3003",
            subdistrict_name: "Oebobo",
          },
        ],
      },
      {
        city_id: "234",
        type: "Kabupaten",
        city_name: "Lembata",
        subdistricts: [
          {
            subdistrict_id: "3338",
            subdistrict_name: "Atadei",
          },
          {
            subdistrict_id: "3339",
            subdistrict_name: "Buyasuri (Buyasari)",
          },
          {
            subdistrict_id: "3340",
            subdistrict_name: "Ile Ape",
          },
          {
            subdistrict_id: "3341",
            subdistrict_name: "Ile Ape Timur",
          },
          {
            subdistrict_id: "3342",
            subdistrict_name: "Lebatukan",
          },
          {
            subdistrict_id: "3343",
            subdistrict_name: "Nagawutung",
          },
          {
            subdistrict_id: "3344",
            subdistrict_name: "Nubatukan",
          },
          {
            subdistrict_id: "3345",
            subdistrict_name: "Omesuri",
          },
          {
            subdistrict_id: "3346",
            subdistrict_name: "Wulandoni (Wulandioni)",
          },
        ],
      },
      {
        city_id: "269",
        type: "Kabupaten",
        city_name: "Manggarai",
        subdistricts: [
          {
            subdistrict_id: "3802",
            subdistrict_name: "Cibal",
          },
          {
            subdistrict_id: "3803",
            subdistrict_name: "Cibal Barat",
          },
          {
            subdistrict_id: "3804",
            subdistrict_name: "Langke Rembong",
          },
          {
            subdistrict_id: "3805",
            subdistrict_name: "Lelak",
          },
          {
            subdistrict_id: "3806",
            subdistrict_name: "Rahong Utara",
          },
          {
            subdistrict_id: "3807",
            subdistrict_name: "Reok",
          },
          {
            subdistrict_id: "3808",
            subdistrict_name: "Reok Barat",
          },
          {
            subdistrict_id: "3809",
            subdistrict_name: "Ruteng",
          },
          {
            subdistrict_id: "3810",
            subdistrict_name: "Satar Mese",
          },
          {
            subdistrict_id: "3811",
            subdistrict_name: "Satar Mese Barat",
          },
          {
            subdistrict_id: "3812",
            subdistrict_name: "Wae Rii",
          },
        ],
      },
      {
        city_id: "270",
        type: "Kabupaten",
        city_name: "Manggarai Barat",
        subdistricts: [
          {
            subdistrict_id: "3813",
            subdistrict_name: "Boleng",
          },
          {
            subdistrict_id: "3814",
            subdistrict_name: "Komodo",
          },
          {
            subdistrict_id: "3815",
            subdistrict_name: "Kuwus",
          },
          {
            subdistrict_id: "3816",
            subdistrict_name: "Lembor",
          },
          {
            subdistrict_id: "3817",
            subdistrict_name: "Lembor Selatan",
          },
          {
            subdistrict_id: "3818",
            subdistrict_name: "Macang Pacar",
          },
          {
            subdistrict_id: "3819",
            subdistrict_name: "Mbeliling",
          },
          {
            subdistrict_id: "3820",
            subdistrict_name: "Ndoso",
          },
          {
            subdistrict_id: "3821",
            subdistrict_name: "Sano Nggoang",
          },
          {
            subdistrict_id: "3822",
            subdistrict_name: "Welak",
          },
        ],
      },
      {
        city_id: "271",
        type: "Kabupaten",
        city_name: "Manggarai Timur",
        subdistricts: [
          {
            subdistrict_id: "3823",
            subdistrict_name: "Borong",
          },
          {
            subdistrict_id: "3824",
            subdistrict_name: "Elar",
          },
          {
            subdistrict_id: "3825",
            subdistrict_name: "Elar Selatan",
          },
          {
            subdistrict_id: "3826",
            subdistrict_name: "Kota Komba",
          },
          {
            subdistrict_id: "3827",
            subdistrict_name: "Lamba Leda",
          },
          {
            subdistrict_id: "3828",
            subdistrict_name: "Poco Ranaka",
          },
          {
            subdistrict_id: "3829",
            subdistrict_name: "Poco Ranaka Timur",
          },
          {
            subdistrict_id: "3830",
            subdistrict_name: "Rana Mese",
          },
          {
            subdistrict_id: "3831",
            subdistrict_name: "Sambi Rampas",
          },
        ],
      },
      {
        city_id: "301",
        type: "Kabupaten",
        city_name: "Nagekeo",
        subdistricts: [
          {
            subdistrict_id: "4260",
            subdistrict_name: "Aesesa",
          },
          {
            subdistrict_id: "4261",
            subdistrict_name: "Aesesa Selatan",
          },
          {
            subdistrict_id: "4262",
            subdistrict_name: "Boawae",
          },
          {
            subdistrict_id: "4263",
            subdistrict_name: "Keo Tengah",
          },
          {
            subdistrict_id: "4264",
            subdistrict_name: "Mauponggo",
          },
          {
            subdistrict_id: "4265",
            subdistrict_name: "Nangaroro",
          },
          {
            subdistrict_id: "4266",
            subdistrict_name: "Wolowae",
          },
        ],
      },
      {
        city_id: "304",
        type: "Kabupaten",
        city_name: "Ngada",
        subdistricts: [
          {
            subdistrict_id: "4311",
            subdistrict_name: "Aimere",
          },
          {
            subdistrict_id: "4312",
            subdistrict_name: "Bajawa",
          },
          {
            subdistrict_id: "4313",
            subdistrict_name: "Bajawa Utara",
          },
          {
            subdistrict_id: "4314",
            subdistrict_name: "Golewa",
          },
          {
            subdistrict_id: "4315",
            subdistrict_name: "Golewa Barat",
          },
          {
            subdistrict_id: "4316",
            subdistrict_name: "Golewa Selatan",
          },
          {
            subdistrict_id: "4317",
            subdistrict_name: "Inerie",
          },
          {
            subdistrict_id: "4318",
            subdistrict_name: "Jerebuu",
          },
          {
            subdistrict_id: "4319",
            subdistrict_name: "Riung",
          },
          {
            subdistrict_id: "4320",
            subdistrict_name: "Riung Barat",
          },
          {
            subdistrict_id: "4321",
            subdistrict_name: "Soa",
          },
          {
            subdistrict_id: "4322",
            subdistrict_name: "Wolomeze (Riung Selatan)",
          },
        ],
      },
      {
        city_id: "383",
        type: "Kabupaten",
        city_name: "Rote Ndao",
        subdistricts: [
          {
            subdistrict_id: "5334",
            subdistrict_name: "Landu Leko",
          },
          {
            subdistrict_id: "5335",
            subdistrict_name: "Lobalain",
          },
          {
            subdistrict_id: "5336",
            subdistrict_name: "Ndao Nuse",
          },
          {
            subdistrict_id: "5337",
            subdistrict_name: "Pantai Baru",
          },
          {
            subdistrict_id: "5338",
            subdistrict_name: "Rote Barat",
          },
          {
            subdistrict_id: "5339",
            subdistrict_name: "Rote Barat Daya",
          },
          {
            subdistrict_id: "5340",
            subdistrict_name: "Rote Barat Laut",
          },
          {
            subdistrict_id: "5341",
            subdistrict_name: "Rote Selatan",
          },
          {
            subdistrict_id: "5342",
            subdistrict_name: "Rote Tengah",
          },
          {
            subdistrict_id: "5343",
            subdistrict_name: "Rote Timur",
          },
        ],
      },
      {
        city_id: "385",
        type: "Kabupaten",
        city_name: "Sabu Raijua",
        subdistricts: [
          {
            subdistrict_id: "5346",
            subdistrict_name: "Hawu Mehara",
          },
          {
            subdistrict_id: "5347",
            subdistrict_name: "Raijua",
          },
          {
            subdistrict_id: "5348",
            subdistrict_name: "Sabu Barat",
          },
          {
            subdistrict_id: "5349",
            subdistrict_name: "Sabu Liae",
          },
          {
            subdistrict_id: "5350",
            subdistrict_name: "Sabu Tengah",
          },
          {
            subdistrict_id: "5351",
            subdistrict_name: "Sabu Timur",
          },
        ],
      },
      {
        city_id: "412",
        type: "Kabupaten",
        city_name: "Sikka",
        subdistricts: [
          {
            subdistrict_id: "5672",
            subdistrict_name: "Alok",
          },
          {
            subdistrict_id: "5673",
            subdistrict_name: "Alok Barat",
          },
          {
            subdistrict_id: "5674",
            subdistrict_name: "Alok Timur",
          },
          {
            subdistrict_id: "5675",
            subdistrict_name: "Bola",
          },
          {
            subdistrict_id: "5676",
            subdistrict_name: "Doreng",
          },
          {
            subdistrict_id: "5677",
            subdistrict_name: "Hewokloang",
          },
          {
            subdistrict_id: "5678",
            subdistrict_name: "Kangae",
          },
          {
            subdistrict_id: "5679",
            subdistrict_name: "Kewapante",
          },
          {
            subdistrict_id: "5680",
            subdistrict_name: "Koting",
          },
          {
            subdistrict_id: "5681",
            subdistrict_name: "Lela",
          },
          {
            subdistrict_id: "5682",
            subdistrict_name: "Magepanda",
          },
          {
            subdistrict_id: "5683",
            subdistrict_name: "Mapitara",
          },
          {
            subdistrict_id: "5684",
            subdistrict_name: "Mego",
          },
          {
            subdistrict_id: "5685",
            subdistrict_name: "Nelle (Maumerei)",
          },
          {
            subdistrict_id: "5686",
            subdistrict_name: "Nita",
          },
          {
            subdistrict_id: "5687",
            subdistrict_name: "Paga",
          },
          {
            subdistrict_id: "5688",
            subdistrict_name: "Palue",
          },
          {
            subdistrict_id: "5689",
            subdistrict_name: "Talibura",
          },
          {
            subdistrict_id: "5690",
            subdistrict_name: "Tana Wawo",
          },
          {
            subdistrict_id: "5691",
            subdistrict_name: "Waiblama",
          },
          {
            subdistrict_id: "5692",
            subdistrict_name: "Waigete",
          },
        ],
      },
      {
        city_id: "434",
        type: "Kabupaten",
        city_name: "Sumba Barat",
        subdistricts: [
          {
            subdistrict_id: "5989",
            subdistrict_name: "Kota Waikabubak",
          },
          {
            subdistrict_id: "5990",
            subdistrict_name: "Lamboya",
          },
          {
            subdistrict_id: "5991",
            subdistrict_name: "Lamboya Barat",
          },
          {
            subdistrict_id: "5992",
            subdistrict_name: "Loli",
          },
          {
            subdistrict_id: "5993",
            subdistrict_name: "Tana Righu",
          },
          {
            subdistrict_id: "5994",
            subdistrict_name: "Wanokaka",
          },
        ],
      },
      {
        city_id: "435",
        type: "Kabupaten",
        city_name: "Sumba Barat Daya",
        subdistricts: [
          {
            subdistrict_id: "5995",
            subdistrict_name: "Kodi",
          },
          {
            subdistrict_id: "5996",
            subdistrict_name: "Kodi Balaghar",
          },
          {
            subdistrict_id: "5997",
            subdistrict_name: "Kodi Bangedo",
          },
          {
            subdistrict_id: "5998",
            subdistrict_name: "Kodi Utara",
          },
          {
            subdistrict_id: "5999",
            subdistrict_name: "Kota Tambolaka",
          },
          {
            subdistrict_id: "6000",
            subdistrict_name: "Loura (Laura)",
          },
          {
            subdistrict_id: "6001",
            subdistrict_name: "Wewewa Barat",
          },
          {
            subdistrict_id: "6002",
            subdistrict_name: "Wewewa Selatan",
          },
          {
            subdistrict_id: "6003",
            subdistrict_name: "Wewewa Tengah (Wewera Tengah)",
          },
          {
            subdistrict_id: "6004",
            subdistrict_name: "Wewewa Timur",
          },
          {
            subdistrict_id: "6005",
            subdistrict_name: "Wewewa Utara",
          },
        ],
      },
      {
        city_id: "436",
        type: "Kabupaten",
        city_name: "Sumba Tengah",
        subdistricts: [
          {
            subdistrict_id: "6006",
            subdistrict_name: "Katikutana",
          },
          {
            subdistrict_id: "6007",
            subdistrict_name: "Katikutana Selatan",
          },
          {
            subdistrict_id: "6008",
            subdistrict_name: "Mamboro",
          },
          {
            subdistrict_id: "6009",
            subdistrict_name: "Umbu Ratu Nggay",
          },
          {
            subdistrict_id: "6010",
            subdistrict_name: "Umbu Ratu Nggay Barat",
          },
        ],
      },
      {
        city_id: "437",
        type: "Kabupaten",
        city_name: "Sumba Timur",
        subdistricts: [
          {
            subdistrict_id: "6011",
            subdistrict_name: "Haharu",
          },
          {
            subdistrict_id: "6012",
            subdistrict_name: "Kahaunguweti (Kahaungu Eti)",
          },
          {
            subdistrict_id: "6013",
            subdistrict_name: "Kambata Mapambuhang",
          },
          {
            subdistrict_id: "6014",
            subdistrict_name: "Kambera",
          },
          {
            subdistrict_id: "6015",
            subdistrict_name: "Kanatang",
          },
          {
            subdistrict_id: "6016",
            subdistrict_name: "Karera",
          },
          {
            subdistrict_id: "6017",
            subdistrict_name: "Katala Hamu Lingu",
          },
          {
            subdistrict_id: "6018",
            subdistrict_name: "Kota Waingapu",
          },
          {
            subdistrict_id: "6019",
            subdistrict_name: "Lewa",
          },
          {
            subdistrict_id: "6020",
            subdistrict_name: "Lewa Tidahu",
          },
          {
            subdistrict_id: "6021",
            subdistrict_name: "Mahu",
          },
          {
            subdistrict_id: "6022",
            subdistrict_name: "Matawai Lappau (La Pawu)",
          },
          {
            subdistrict_id: "6023",
            subdistrict_name: "Ngadu Ngala",
          },
          {
            subdistrict_id: "6024",
            subdistrict_name: "Nggaha Oriangu",
          },
          {
            subdistrict_id: "6025",
            subdistrict_name: "Paberiwai",
          },
          {
            subdistrict_id: "6026",
            subdistrict_name: "Pahunga Lodu",
          },
          {
            subdistrict_id: "6027",
            subdistrict_name: "Pandawai",
          },
          {
            subdistrict_id: "6028",
            subdistrict_name: "Pinupahar (Pirapahar)",
          },
          {
            subdistrict_id: "6029",
            subdistrict_name: "Rindi",
          },
          {
            subdistrict_id: "6030",
            subdistrict_name: "Tabundung",
          },
          {
            subdistrict_id: "6031",
            subdistrict_name: "Umalulu",
          },
          {
            subdistrict_id: "6032",
            subdistrict_name: "Wula Waijelu",
          },
        ],
      },
      {
        city_id: "479",
        type: "Kabupaten",
        city_name: "Timor Tengah Selatan",
        subdistricts: [
          {
            subdistrict_id: "6596",
            subdistrict_name: "Amanatun Selatan",
          },
          {
            subdistrict_id: "6597",
            subdistrict_name: "Amanatun Utara",
          },
          {
            subdistrict_id: "6598",
            subdistrict_name: "Amanuban Barat",
          },
          {
            subdistrict_id: "6599",
            subdistrict_name: "Amanuban Selatan",
          },
          {
            subdistrict_id: "6600",
            subdistrict_name: "Amanuban Tengah",
          },
          {
            subdistrict_id: "6601",
            subdistrict_name: "Amanuban Timur",
          },
          {
            subdistrict_id: "6602",
            subdistrict_name: "Batu Putih",
          },
          {
            subdistrict_id: "6603",
            subdistrict_name: "Boking",
          },
          {
            subdistrict_id: "6604",
            subdistrict_name: "Fatukopa",
          },
          {
            subdistrict_id: "6605",
            subdistrict_name: "Fatumnasi",
          },
          {
            subdistrict_id: "6606",
            subdistrict_name: "Fautmolo",
          },
          {
            subdistrict_id: "6607",
            subdistrict_name: "Kie (Ki'e)",
          },
          {
            subdistrict_id: "6608",
            subdistrict_name: "Kok Baun",
          },
          {
            subdistrict_id: "6609",
            subdistrict_name: "Kolbano",
          },
          {
            subdistrict_id: "6610",
            subdistrict_name: "Kot Olin",
          },
          {
            subdistrict_id: "6611",
            subdistrict_name: "Kota Soe",
          },
          {
            subdistrict_id: "6612",
            subdistrict_name: "Kualin",
          },
          {
            subdistrict_id: "6613",
            subdistrict_name: "Kuanfatu",
          },
          {
            subdistrict_id: "6614",
            subdistrict_name: "Kuatnana",
          },
          {
            subdistrict_id: "6615",
            subdistrict_name: "Mollo Barat",
          },
          {
            subdistrict_id: "6616",
            subdistrict_name: "Mollo Selatan",
          },
          {
            subdistrict_id: "6617",
            subdistrict_name: "Mollo Tengah",
          },
          {
            subdistrict_id: "6618",
            subdistrict_name: "Mollo Utara",
          },
          {
            subdistrict_id: "6619",
            subdistrict_name: "Noebana",
          },
          {
            subdistrict_id: "6620",
            subdistrict_name: "Noebeba",
          },
          {
            subdistrict_id: "6621",
            subdistrict_name: "Nunbena",
          },
          {
            subdistrict_id: "6622",
            subdistrict_name: "Nunkolo",
          },
          {
            subdistrict_id: "6623",
            subdistrict_name: "Oenino",
          },
          {
            subdistrict_id: "6624",
            subdistrict_name: "Polen",
          },
          {
            subdistrict_id: "6625",
            subdistrict_name: "Santian",
          },
          {
            subdistrict_id: "6626",
            subdistrict_name: "Tobu",
          },
          {
            subdistrict_id: "6627",
            subdistrict_name: "Toianas",
          },
        ],
      },
      {
        city_id: "480",
        type: "Kabupaten",
        city_name: "Timor Tengah Utara",
        subdistricts: [
          {
            subdistrict_id: "6628",
            subdistrict_name: "Biboki Anleu",
          },
          {
            subdistrict_id: "6629",
            subdistrict_name: "Biboki Feotleu",
          },
          {
            subdistrict_id: "6630",
            subdistrict_name: "Biboki Moenleu",
          },
          {
            subdistrict_id: "6631",
            subdistrict_name: "Biboki Selatan",
          },
          {
            subdistrict_id: "6632",
            subdistrict_name: "Biboki Tan Pah",
          },
          {
            subdistrict_id: "6633",
            subdistrict_name: "Biboki Utara",
          },
          {
            subdistrict_id: "6634",
            subdistrict_name: "Bikomi Nilulat",
          },
          {
            subdistrict_id: "6635",
            subdistrict_name: "Bikomi Selatan",
          },
          {
            subdistrict_id: "6636",
            subdistrict_name: "Bikomi Tengah",
          },
          {
            subdistrict_id: "6637",
            subdistrict_name: "Bikomi Utara",
          },
          {
            subdistrict_id: "6638",
            subdistrict_name: "Insana",
          },
          {
            subdistrict_id: "6639",
            subdistrict_name: "Insana Barat",
          },
          {
            subdistrict_id: "6640",
            subdistrict_name: "Insana Fafinesu",
          },
          {
            subdistrict_id: "6641",
            subdistrict_name: "Insana Tengah",
          },
          {
            subdistrict_id: "6642",
            subdistrict_name: "Insana Utara",
          },
          {
            subdistrict_id: "6643",
            subdistrict_name: "Kota Kefamenanu",
          },
          {
            subdistrict_id: "6644",
            subdistrict_name: "Miomaffo Barat",
          },
          {
            subdistrict_id: "6645",
            subdistrict_name: "Miomaffo Tengah",
          },
          {
            subdistrict_id: "6646",
            subdistrict_name: "Miomaffo Timur",
          },
          {
            subdistrict_id: "6647",
            subdistrict_name: "Musi",
          },
          {
            subdistrict_id: "6648",
            subdistrict_name: "Mutis",
          },
          {
            subdistrict_id: "6649",
            subdistrict_name: "Naibenu",
          },
          {
            subdistrict_id: "6650",
            subdistrict_name: "Noemuti",
          },
          {
            subdistrict_id: "6651",
            subdistrict_name: "Noemuti Timur",
          },
        ],
      },
    ],
  },
  {
    province_id: "24",
    province: "Papua",
    cities: [
      {
        city_id: "16",
        type: "Kabupaten",
        city_name: "Asmat",
        subdistricts: [
          {
            subdistrict_id: "239",
            subdistrict_name: "Agats",
          },
          {
            subdistrict_id: "240",
            subdistrict_name: "Akat",
          },
          {
            subdistrict_id: "241",
            subdistrict_name: "Atsy / Atsj",
          },
          {
            subdistrict_id: "242",
            subdistrict_name: "Ayip",
          },
          {
            subdistrict_id: "243",
            subdistrict_name: "Betcbamu",
          },
          {
            subdistrict_id: "244",
            subdistrict_name: "Der Koumur",
          },
          {
            subdistrict_id: "245",
            subdistrict_name: "Fayit",
          },
          {
            subdistrict_id: "246",
            subdistrict_name: "Jetsy",
          },
          {
            subdistrict_id: "247",
            subdistrict_name: "Joerat",
          },
          {
            subdistrict_id: "248",
            subdistrict_name: "Kolf Braza",
          },
          {
            subdistrict_id: "249",
            subdistrict_name: "Kopay",
          },
          {
            subdistrict_id: "250",
            subdistrict_name: "Pantai Kasuari",
          },
          {
            subdistrict_id: "251",
            subdistrict_name: "Pulau Tiga",
          },
          {
            subdistrict_id: "252",
            subdistrict_name: "Safan",
          },
          {
            subdistrict_id: "253",
            subdistrict_name: "Sawa Erma",
          },
          {
            subdistrict_id: "254",
            subdistrict_name: "Sirets",
          },
          {
            subdistrict_id: "255",
            subdistrict_name: "Suator",
          },
          {
            subdistrict_id: "256",
            subdistrict_name: "Suru-suru",
          },
          {
            subdistrict_id: "257",
            subdistrict_name: "Unir Sirau",
          },
        ],
      },
      {
        city_id: "67",
        type: "Kabupaten",
        city_name: "Biak Numfor",
        subdistricts: [
          {
            subdistrict_id: "892",
            subdistrict_name: "Aimando Padaido",
          },
          {
            subdistrict_id: "893",
            subdistrict_name: "Andey (Andei)",
          },
          {
            subdistrict_id: "894",
            subdistrict_name: "Biak Barat",
          },
          {
            subdistrict_id: "895",
            subdistrict_name: "Biak Kota",
          },
          {
            subdistrict_id: "896",
            subdistrict_name: "Biak Timur",
          },
          {
            subdistrict_id: "897",
            subdistrict_name: "Biak Utara",
          },
          {
            subdistrict_id: "898",
            subdistrict_name: "Bondifuar",
          },
          {
            subdistrict_id: "899",
            subdistrict_name: "Bruyadori",
          },
          {
            subdistrict_id: "900",
            subdistrict_name: "Numfor Barat",
          },
          {
            subdistrict_id: "901",
            subdistrict_name: "Numfor Timur",
          },
          {
            subdistrict_id: "902",
            subdistrict_name: "Oridek",
          },
          {
            subdistrict_id: "903",
            subdistrict_name: "Orkeri",
          },
          {
            subdistrict_id: "904",
            subdistrict_name: "Padaido",
          },
          {
            subdistrict_id: "905",
            subdistrict_name: "Poiru",
          },
          {
            subdistrict_id: "906",
            subdistrict_name: "Samofa",
          },
          {
            subdistrict_id: "907",
            subdistrict_name: "Swandiwe",
          },
          {
            subdistrict_id: "908",
            subdistrict_name: "Warsa",
          },
          {
            subdistrict_id: "909",
            subdistrict_name: "Yawosi",
          },
          {
            subdistrict_id: "910",
            subdistrict_name: "Yendidori",
          },
        ],
      },
      {
        city_id: "90",
        type: "Kabupaten",
        city_name: "Boven Digoel",
        subdistricts: [
          {
            subdistrict_id: "1220",
            subdistrict_name: "Ambatkwi (Ambatkui)",
          },
          {
            subdistrict_id: "1221",
            subdistrict_name: "Arimop",
          },
          {
            subdistrict_id: "1222",
            subdistrict_name: "Bomakia",
          },
          {
            subdistrict_id: "1223",
            subdistrict_name: "Firiwage",
          },
          {
            subdistrict_id: "1224",
            subdistrict_name: "Fofi",
          },
          {
            subdistrict_id: "1225",
            subdistrict_name: "Iniyandit",
          },
          {
            subdistrict_id: "1226",
            subdistrict_name: "Jair",
          },
          {
            subdistrict_id: "1227",
            subdistrict_name: "Kawagit",
          },
          {
            subdistrict_id: "1228",
            subdistrict_name: "Ki",
          },
          {
            subdistrict_id: "1229",
            subdistrict_name: "Kombay",
          },
          {
            subdistrict_id: "1230",
            subdistrict_name: "Kombut",
          },
          {
            subdistrict_id: "1231",
            subdistrict_name: "Kouh",
          },
          {
            subdistrict_id: "1232",
            subdistrict_name: "Mandobo",
          },
          {
            subdistrict_id: "1233",
            subdistrict_name: "Manggelum",
          },
          {
            subdistrict_id: "1234",
            subdistrict_name: "Mindiptana",
          },
          {
            subdistrict_id: "1235",
            subdistrict_name: "Ninati",
          },
          {
            subdistrict_id: "1236",
            subdistrict_name: "Sesnuk",
          },
          {
            subdistrict_id: "1237",
            subdistrict_name: "Subur",
          },
          {
            subdistrict_id: "1238",
            subdistrict_name: "Waropko",
          },
          {
            subdistrict_id: "1239",
            subdistrict_name: "Yaniruma",
          },
        ],
      },
      {
        city_id: "111",
        type: "Kabupaten",
        city_name: "Deiyai (Deliyai)",
        subdistricts: [
          {
            subdistrict_id: "1532",
            subdistrict_name: "Bowobado",
          },
          {
            subdistrict_id: "1533",
            subdistrict_name: "Kapiraya",
          },
          {
            subdistrict_id: "1534",
            subdistrict_name: "Tigi",
          },
          {
            subdistrict_id: "1535",
            subdistrict_name: "Tigi Barat",
          },
          {
            subdistrict_id: "1536",
            subdistrict_name: "Tigi Timur",
          },
        ],
      },
      {
        city_id: "117",
        type: "Kabupaten",
        city_name: "Dogiyai",
        subdistricts: [
          {
            subdistrict_id: "1599",
            subdistrict_name: "Dogiyai",
          },
          {
            subdistrict_id: "1600",
            subdistrict_name: "Kamu",
          },
          {
            subdistrict_id: "1601",
            subdistrict_name: "Kamu Selatan",
          },
          {
            subdistrict_id: "1602",
            subdistrict_name: "Kamu Timur",
          },
          {
            subdistrict_id: "1603",
            subdistrict_name: "Kamu Utara (Ikrar/Ikrat)",
          },
          {
            subdistrict_id: "1604",
            subdistrict_name: "Mapia",
          },
          {
            subdistrict_id: "1605",
            subdistrict_name: "Mapia Barat",
          },
          {
            subdistrict_id: "1606",
            subdistrict_name: "Mapia Tengah",
          },
          {
            subdistrict_id: "1607",
            subdistrict_name: "Piyaiye (Sukikai)",
          },
          {
            subdistrict_id: "1608",
            subdistrict_name: "Sukikai Selatan",
          },
        ],
      },
      {
        city_id: "150",
        type: "Kabupaten",
        city_name: "Intan Jaya",
        subdistricts: [
          {
            subdistrict_id: "2081",
            subdistrict_name: "Agisiga",
          },
          {
            subdistrict_id: "2082",
            subdistrict_name: "Biandoga",
          },
          {
            subdistrict_id: "2083",
            subdistrict_name: "Hitadipa",
          },
          {
            subdistrict_id: "2084",
            subdistrict_name: "Homeo (Homeyo)",
          },
          {
            subdistrict_id: "2085",
            subdistrict_name: "Sugapa",
          },
          {
            subdistrict_id: "2086",
            subdistrict_name: "Wandai",
          },
        ],
      },
      {
        city_id: "157",
        type: "Kabupaten",
        city_name: "Jayapura",
        subdistricts: [
          {
            subdistrict_id: "2137",
            subdistrict_name: "Airu",
          },
          {
            subdistrict_id: "2138",
            subdistrict_name: "Demta",
          },
          {
            subdistrict_id: "2139",
            subdistrict_name: "Depapre",
          },
          {
            subdistrict_id: "2140",
            subdistrict_name: "Ebungfau (Ebungfa)",
          },
          {
            subdistrict_id: "2141",
            subdistrict_name: "Gresi Selatan",
          },
          {
            subdistrict_id: "2142",
            subdistrict_name: "Kaureh",
          },
          {
            subdistrict_id: "2143",
            subdistrict_name: "Kemtuk",
          },
          {
            subdistrict_id: "2144",
            subdistrict_name: "Kemtuk Gresi",
          },
          {
            subdistrict_id: "2145",
            subdistrict_name: "Nambluong",
          },
          {
            subdistrict_id: "2146",
            subdistrict_name: "Nimbokrang",
          },
          {
            subdistrict_id: "2147",
            subdistrict_name: "Nimboran",
          },
          {
            subdistrict_id: "2148",
            subdistrict_name: "Ravenirara",
          },
          {
            subdistrict_id: "2149",
            subdistrict_name: "Sentani",
          },
          {
            subdistrict_id: "2150",
            subdistrict_name: "Sentani Barat",
          },
          {
            subdistrict_id: "2151",
            subdistrict_name: "Sentani Timur",
          },
          {
            subdistrict_id: "2152",
            subdistrict_name: "Unurum Guay",
          },
          {
            subdistrict_id: "2153",
            subdistrict_name: "Waibu",
          },
          {
            subdistrict_id: "2154",
            subdistrict_name: "Yapsi",
          },
          {
            subdistrict_id: "2155",
            subdistrict_name: "Yokari",
          },
        ],
      },
      {
        city_id: "158",
        type: "Kota",
        city_name: "Jayapura",
        subdistricts: [
          {
            subdistrict_id: "2156",
            subdistrict_name: "Abepura",
          },
          {
            subdistrict_id: "2157",
            subdistrict_name: "Heram",
          },
          {
            subdistrict_id: "2158",
            subdistrict_name: "Jayapura Selatan",
          },
          {
            subdistrict_id: "2159",
            subdistrict_name: "Jayapura Utara",
          },
          {
            subdistrict_id: "2160",
            subdistrict_name: "Muara Tami",
          },
        ],
      },
      {
        city_id: "159",
        type: "Kabupaten",
        city_name: "Jayawijaya",
        subdistricts: [
          {
            subdistrict_id: "2161",
            subdistrict_name: "Asologaima (Asalogaima)",
          },
          {
            subdistrict_id: "2162",
            subdistrict_name: "Asolokobal",
          },
          {
            subdistrict_id: "2163",
            subdistrict_name: "Asotipo",
          },
          {
            subdistrict_id: "2164",
            subdistrict_name: "Bolakme",
          },
          {
            subdistrict_id: "2165",
            subdistrict_name: "Bpiri",
          },
          {
            subdistrict_id: "2166",
            subdistrict_name: "Bugi",
          },
          {
            subdistrict_id: "2167",
            subdistrict_name: "Hubikiak",
          },
          {
            subdistrict_id: "2168",
            subdistrict_name: "Hubikosi (Hobikosi)",
          },
          {
            subdistrict_id: "2169",
            subdistrict_name: "Ibele",
          },
          {
            subdistrict_id: "2170",
            subdistrict_name: "Itlay Hisage",
          },
          {
            subdistrict_id: "2171",
            subdistrict_name: "Koragi",
          },
          {
            subdistrict_id: "2172",
            subdistrict_name: "Kurulu",
          },
          {
            subdistrict_id: "2173",
            subdistrict_name: "Libarek",
          },
          {
            subdistrict_id: "2174",
            subdistrict_name: "Maima",
          },
          {
            subdistrict_id: "2175",
            subdistrict_name: "Molagalome",
          },
          {
            subdistrict_id: "2176",
            subdistrict_name: "Muliama",
          },
          {
            subdistrict_id: "2177",
            subdistrict_name: "Musatfak",
          },
          {
            subdistrict_id: "2178",
            subdistrict_name: "Napua",
          },
          {
            subdistrict_id: "2179",
            subdistrict_name: "Pelebaga",
          },
          {
            subdistrict_id: "2180",
            subdistrict_name: "Piramid",
          },
          {
            subdistrict_id: "2181",
            subdistrict_name: "Pisugi",
          },
          {
            subdistrict_id: "2182",
            subdistrict_name: "Popugoba",
          },
          {
            subdistrict_id: "2183",
            subdistrict_name: "Siepkosi",
          },
          {
            subdistrict_id: "2184",
            subdistrict_name: "Silo Karno Doga",
          },
          {
            subdistrict_id: "2185",
            subdistrict_name: "Taelarek",
          },
          {
            subdistrict_id: "2186",
            subdistrict_name: "Tagime",
          },
          {
            subdistrict_id: "2187",
            subdistrict_name: "Tagineri",
          },
          {
            subdistrict_id: "2188",
            subdistrict_name: "Trikora",
          },
          {
            subdistrict_id: "2189",
            subdistrict_name: "Usilimo",
          },
          {
            subdistrict_id: "2190",
            subdistrict_name: "Wadangku",
          },
          {
            subdistrict_id: "2191",
            subdistrict_name: "Walaik",
          },
          {
            subdistrict_id: "2192",
            subdistrict_name: "Walelagama",
          },
          {
            subdistrict_id: "2193",
            subdistrict_name: "Wame",
          },
          {
            subdistrict_id: "2194",
            subdistrict_name: "Wamena",
          },
          {
            subdistrict_id: "2195",
            subdistrict_name: "Welesi",
          },
          {
            subdistrict_id: "2196",
            subdistrict_name: "Wesaput",
          },
          {
            subdistrict_id: "2197",
            subdistrict_name: "Wita Waya",
          },
          {
            subdistrict_id: "2198",
            subdistrict_name: "Wollo (Wolo)",
          },
          {
            subdistrict_id: "2199",
            subdistrict_name: "Wouma",
          },
          {
            subdistrict_id: "2200",
            subdistrict_name: "Yalengga",
          },
        ],
      },
      {
        city_id: "180",
        type: "Kabupaten",
        city_name: "Keerom",
        subdistricts: [
          {
            subdistrict_id: "2526",
            subdistrict_name: "Arso",
          },
          {
            subdistrict_id: "2527",
            subdistrict_name: "Arso Timur",
          },
          {
            subdistrict_id: "2528",
            subdistrict_name: "Senggi",
          },
          {
            subdistrict_id: "2529",
            subdistrict_name: "Skamto (Skanto)",
          },
          {
            subdistrict_id: "2530",
            subdistrict_name: "Towe",
          },
          {
            subdistrict_id: "2531",
            subdistrict_name: "Waris",
          },
          {
            subdistrict_id: "2532",
            subdistrict_name: "Web",
          },
        ],
      },
      {
        city_id: "193",
        type: "Kabupaten",
        city_name: "Kepulauan Yapen (Yapen Waropen)",
        subdistricts: [
          {
            subdistrict_id: "2672",
            subdistrict_name: "Angkaisera",
          },
          {
            subdistrict_id: "2673",
            subdistrict_name: "Kepulauan Ambai",
          },
          {
            subdistrict_id: "2674",
            subdistrict_name: "Kosiwo",
          },
          {
            subdistrict_id: "2675",
            subdistrict_name: "Poom",
          },
          {
            subdistrict_id: "2676",
            subdistrict_name: "Pulau Kurudu",
          },
          {
            subdistrict_id: "2677",
            subdistrict_name: "Pulau Yerui",
          },
          {
            subdistrict_id: "2678",
            subdistrict_name: "Raimbawi",
          },
          {
            subdistrict_id: "2679",
            subdistrict_name: "Teluk Ampimoi",
          },
          {
            subdistrict_id: "2680",
            subdistrict_name: "Windesi",
          },
          {
            subdistrict_id: "2681",
            subdistrict_name: "Wonawa",
          },
          {
            subdistrict_id: "2682",
            subdistrict_name: "Yapen Barat",
          },
          {
            subdistrict_id: "2683",
            subdistrict_name: "Yapen Selatan",
          },
          {
            subdistrict_id: "2684",
            subdistrict_name: "Yapen Timur",
          },
          {
            subdistrict_id: "2685",
            subdistrict_name: "Yapen Utara",
          },
        ],
      },
      {
        city_id: "231",
        type: "Kabupaten",
        city_name: "Lanny Jaya",
        subdistricts: [
          {
            subdistrict_id: "3288",
            subdistrict_name: "Balingga",
          },
          {
            subdistrict_id: "3289",
            subdistrict_name: "Dimba",
          },
          {
            subdistrict_id: "3290",
            subdistrict_name: "Gamelia",
          },
          {
            subdistrict_id: "3291",
            subdistrict_name: "Kuyawage",
          },
          {
            subdistrict_id: "3292",
            subdistrict_name: "Makki (Maki)",
          },
          {
            subdistrict_id: "3293",
            subdistrict_name: "Malagaineri (Malagineri)",
          },
          {
            subdistrict_id: "3294",
            subdistrict_name: "Pirime",
          },
          {
            subdistrict_id: "3295",
            subdistrict_name: "Poga",
          },
          {
            subdistrict_id: "3296",
            subdistrict_name: "Tiom",
          },
          {
            subdistrict_id: "3297",
            subdistrict_name: "Tiomneri",
          },
        ],
      },
      {
        city_id: "263",
        type: "Kabupaten",
        city_name: "Mamberamo Raya",
        subdistricts: [
          {
            subdistrict_id: "3727",
            subdistrict_name: "Benuki",
          },
          {
            subdistrict_id: "3728",
            subdistrict_name: "Mamberamo Hilir/Ilir",
          },
          {
            subdistrict_id: "3729",
            subdistrict_name: "Mamberamo Hulu/Ulu",
          },
          {
            subdistrict_id: "3730",
            subdistrict_name: "Mamberamo Tengah",
          },
          {
            subdistrict_id: "3731",
            subdistrict_name: "Mamberamo Tengah Timur",
          },
          {
            subdistrict_id: "3732",
            subdistrict_name: "Rofaer (Rufaer)",
          },
          {
            subdistrict_id: "3733",
            subdistrict_name: "Sawai",
          },
          {
            subdistrict_id: "3734",
            subdistrict_name: "Waropen Atas",
          },
        ],
      },
      {
        city_id: "264",
        type: "Kabupaten",
        city_name: "Mamberamo Tengah",
        subdistricts: [
          {
            subdistrict_id: "3735",
            subdistrict_name: "Eragayam",
          },
          {
            subdistrict_id: "3736",
            subdistrict_name: "Ilugwa",
          },
          {
            subdistrict_id: "3737",
            subdistrict_name: "Kelila",
          },
          {
            subdistrict_id: "3738",
            subdistrict_name: "Kobakma",
          },
          {
            subdistrict_id: "3739",
            subdistrict_name: "Megabilis (Megambilis)",
          },
        ],
      },
      {
        city_id: "274",
        type: "Kabupaten",
        city_name: "Mappi",
        subdistricts: [
          {
            subdistrict_id: "3847",
            subdistrict_name: "Assue",
          },
          {
            subdistrict_id: "3848",
            subdistrict_name: "Bamgi",
          },
          {
            subdistrict_id: "3849",
            subdistrict_name: "Citakmitak",
          },
          {
            subdistrict_id: "3850",
            subdistrict_name: "Edera",
          },
          {
            subdistrict_id: "3851",
            subdistrict_name: "Haju",
          },
          {
            subdistrict_id: "3852",
            subdistrict_name: "Kaibar",
          },
          {
            subdistrict_id: "3853",
            subdistrict_name: "Minyamur",
          },
          {
            subdistrict_id: "3854",
            subdistrict_name: "Nambioman Bapai (Mambioman)",
          },
          {
            subdistrict_id: "3855",
            subdistrict_name: "Obaa",
          },
          {
            subdistrict_id: "3856",
            subdistrict_name: "Passue",
          },
          {
            subdistrict_id: "3857",
            subdistrict_name: "Passue Bawah",
          },
          {
            subdistrict_id: "3858",
            subdistrict_name: "Syahcame",
          },
          {
            subdistrict_id: "3859",
            subdistrict_name: "Ti Zain",
          },
          {
            subdistrict_id: "3860",
            subdistrict_name: "Venaha",
          },
          {
            subdistrict_id: "3861",
            subdistrict_name: "Yakomi",
          },
        ],
      },
      {
        city_id: "281",
        type: "Kabupaten",
        city_name: "Merauke",
        subdistricts: [
          {
            subdistrict_id: "3962",
            subdistrict_name: "Animha",
          },
          {
            subdistrict_id: "3963",
            subdistrict_name: "Eligobel",
          },
          {
            subdistrict_id: "3964",
            subdistrict_name: "Ilyawab",
          },
          {
            subdistrict_id: "3965",
            subdistrict_name: "Jagebob",
          },
          {
            subdistrict_id: "3966",
            subdistrict_name: "Kaptel",
          },
          {
            subdistrict_id: "3967",
            subdistrict_name: "Kimaam",
          },
          {
            subdistrict_id: "3968",
            subdistrict_name: "Kurik",
          },
          {
            subdistrict_id: "3969",
            subdistrict_name: "Malind",
          },
          {
            subdistrict_id: "3970",
            subdistrict_name: "Merauke",
          },
          {
            subdistrict_id: "3971",
            subdistrict_name: "Muting",
          },
          {
            subdistrict_id: "3972",
            subdistrict_name: "Naukenjerai",
          },
          {
            subdistrict_id: "3973",
            subdistrict_name: "Ngguti (Nggunti)",
          },
          {
            subdistrict_id: "3974",
            subdistrict_name: "Okaba",
          },
          {
            subdistrict_id: "3975",
            subdistrict_name: "Semangga",
          },
          {
            subdistrict_id: "3976",
            subdistrict_name: "Sota",
          },
          {
            subdistrict_id: "3977",
            subdistrict_name: "Tabonji",
          },
          {
            subdistrict_id: "3978",
            subdistrict_name: "Tanah Miring",
          },
          {
            subdistrict_id: "3979",
            subdistrict_name: "Tubang",
          },
          {
            subdistrict_id: "3980",
            subdistrict_name: "Ulilin",
          },
          {
            subdistrict_id: "3981",
            subdistrict_name: "Waan",
          },
        ],
      },
      {
        city_id: "284",
        type: "Kabupaten",
        city_name: "Mimika",
        subdistricts: [
          {
            subdistrict_id: "3994",
            subdistrict_name: "Agimuga",
          },
          {
            subdistrict_id: "3995",
            subdistrict_name: "Jila",
          },
          {
            subdistrict_id: "3996",
            subdistrict_name: "Jita",
          },
          {
            subdistrict_id: "3997",
            subdistrict_name: "Kuala Kencana",
          },
          {
            subdistrict_id: "3998",
            subdistrict_name: "Mimika Barat (Mibar)",
          },
          {
            subdistrict_id: "3999",
            subdistrict_name: "Mimika Barat Jauh",
          },
          {
            subdistrict_id: "4000",
            subdistrict_name: "Mimika Barat Tengah",
          },
          {
            subdistrict_id: "4001",
            subdistrict_name: "Mimika Baru",
          },
          {
            subdistrict_id: "4002",
            subdistrict_name: "Mimika Timur",
          },
          {
            subdistrict_id: "4003",
            subdistrict_name: "Mimika Timur Jauh",
          },
          {
            subdistrict_id: "4004",
            subdistrict_name: "Mimika Timur Tengah",
          },
          {
            subdistrict_id: "4005",
            subdistrict_name: "Tembagapura",
          },
        ],
      },
      {
        city_id: "299",
        type: "Kabupaten",
        city_name: "Nabire",
        subdistricts: [
          {
            subdistrict_id: "4235",
            subdistrict_name: "Dipa",
          },
          {
            subdistrict_id: "4236",
            subdistrict_name: "Makimi",
          },
          {
            subdistrict_id: "4237",
            subdistrict_name: "Menou",
          },
          {
            subdistrict_id: "4238",
            subdistrict_name: "Moora",
          },
          {
            subdistrict_id: "4239",
            subdistrict_name: "Nabire",
          },
          {
            subdistrict_id: "4240",
            subdistrict_name: "Nabire Barat",
          },
          {
            subdistrict_id: "4241",
            subdistrict_name: "Napan",
          },
          {
            subdistrict_id: "4242",
            subdistrict_name: "Siriwo",
          },
          {
            subdistrict_id: "4243",
            subdistrict_name: "Teluk Kimi",
          },
          {
            subdistrict_id: "4244",
            subdistrict_name: "Teluk Umar",
          },
          {
            subdistrict_id: "4245",
            subdistrict_name: "Uwapa",
          },
          {
            subdistrict_id: "4246",
            subdistrict_name: "Wanggar",
          },
          {
            subdistrict_id: "4247",
            subdistrict_name: "Wapoga",
          },
          {
            subdistrict_id: "4248",
            subdistrict_name: "Yaro (Yaro Kabisay)",
          },
          {
            subdistrict_id: "4249",
            subdistrict_name: "Yaur",
          },
        ],
      },
      {
        city_id: "303",
        type: "Kabupaten",
        city_name: "Nduga",
        subdistricts: [
          {
            subdistrict_id: "4279",
            subdistrict_name: "Alama",
          },
          {
            subdistrict_id: "4280",
            subdistrict_name: "Dal",
          },
          {
            subdistrict_id: "4281",
            subdistrict_name: "Embetpen",
          },
          {
            subdistrict_id: "4282",
            subdistrict_name: "Gearek",
          },
          {
            subdistrict_id: "4283",
            subdistrict_name: "Geselma (Geselema)",
          },
          {
            subdistrict_id: "4284",
            subdistrict_name: "Inikgal",
          },
          {
            subdistrict_id: "4285",
            subdistrict_name: "Iniye",
          },
          {
            subdistrict_id: "4286",
            subdistrict_name: "Kegayem",
          },
          {
            subdistrict_id: "4287",
            subdistrict_name: "Kenyam",
          },
          {
            subdistrict_id: "4288",
            subdistrict_name: "Kilmid",
          },
          {
            subdistrict_id: "4289",
            subdistrict_name: "Kora",
          },
          {
            subdistrict_id: "4290",
            subdistrict_name: "Koroptak",
          },
          {
            subdistrict_id: "4291",
            subdistrict_name: "Krepkuri",
          },
          {
            subdistrict_id: "4292",
            subdistrict_name: "Mam",
          },
          {
            subdistrict_id: "4293",
            subdistrict_name: "Mapenduma",
          },
          {
            subdistrict_id: "4294",
            subdistrict_name: "Mbua (Mbuga)",
          },
          {
            subdistrict_id: "4295",
            subdistrict_name: "Mbua Tengah",
          },
          {
            subdistrict_id: "4296",
            subdistrict_name: "Mbulmu Yalma",
          },
          {
            subdistrict_id: "4297",
            subdistrict_name: "Mebarok",
          },
          {
            subdistrict_id: "4298",
            subdistrict_name: "Moba",
          },
          {
            subdistrict_id: "4299",
            subdistrict_name: "Mugi",
          },
          {
            subdistrict_id: "4300",
            subdistrict_name: "Nenggeagin",
          },
          {
            subdistrict_id: "4301",
            subdistrict_name: "Nirkuri",
          },
          {
            subdistrict_id: "4302",
            subdistrict_name: "Paro",
          },
          {
            subdistrict_id: "4303",
            subdistrict_name: "Pasir Putih",
          },
          {
            subdistrict_id: "4304",
            subdistrict_name: "Pija",
          },
          {
            subdistrict_id: "4305",
            subdistrict_name: "Wosak",
          },
          {
            subdistrict_id: "4306",
            subdistrict_name: "Wusi",
          },
          {
            subdistrict_id: "4307",
            subdistrict_name: "Wutpaga",
          },
          {
            subdistrict_id: "4308",
            subdistrict_name: "Yal",
          },
          {
            subdistrict_id: "4309",
            subdistrict_name: "Yenggelo",
          },
          {
            subdistrict_id: "4310",
            subdistrict_name: "Yigi",
          },
        ],
      },
      {
        city_id: "335",
        type: "Kabupaten",
        city_name: "Paniai",
        subdistricts: [
          {
            subdistrict_id: "4720",
            subdistrict_name: "Aradide",
          },
          {
            subdistrict_id: "4721",
            subdistrict_name: "Bibida",
          },
          {
            subdistrict_id: "4722",
            subdistrict_name: "Bogobaida",
          },
          {
            subdistrict_id: "4723",
            subdistrict_name: "Dumadama",
          },
          {
            subdistrict_id: "4724",
            subdistrict_name: "Ekadide",
          },
          {
            subdistrict_id: "4725",
            subdistrict_name: "Kebo",
          },
          {
            subdistrict_id: "4726",
            subdistrict_name: "Paniai Barat",
          },
          {
            subdistrict_id: "4727",
            subdistrict_name: "Paniai Timur",
          },
          {
            subdistrict_id: "4728",
            subdistrict_name: "Siriwo",
          },
          {
            subdistrict_id: "4729",
            subdistrict_name: "Yatamo",
          },
        ],
      },
      {
        city_id: "347",
        type: "Kabupaten",
        city_name: "Pegunungan Bintang",
        subdistricts: [
          {
            subdistrict_id: "4857",
            subdistrict_name: "Aboy",
          },
          {
            subdistrict_id: "4858",
            subdistrict_name: "Alemsom",
          },
          {
            subdistrict_id: "4859",
            subdistrict_name: "Awinbon",
          },
          {
            subdistrict_id: "4860",
            subdistrict_name: "Batani",
          },
          {
            subdistrict_id: "4861",
            subdistrict_name: "Batom",
          },
          {
            subdistrict_id: "4862",
            subdistrict_name: "Bime",
          },
          {
            subdistrict_id: "4863",
            subdistrict_name: "Borme",
          },
          {
            subdistrict_id: "4864",
            subdistrict_name: "Eipumek",
          },
          {
            subdistrict_id: "4865",
            subdistrict_name: "Iwur (Okiwur)",
          },
          {
            subdistrict_id: "4866",
            subdistrict_name: "Jetfa",
          },
          {
            subdistrict_id: "4867",
            subdistrict_name: "Kalomdol",
          },
          {
            subdistrict_id: "4868",
            subdistrict_name: "Kawor",
          },
          {
            subdistrict_id: "4869",
            subdistrict_name: "Kiwirok",
          },
          {
            subdistrict_id: "4870",
            subdistrict_name: "Kiwirok Timur",
          },
          {
            subdistrict_id: "4871",
            subdistrict_name: "Mofinop",
          },
          {
            subdistrict_id: "4872",
            subdistrict_name: "Murkim",
          },
          {
            subdistrict_id: "4873",
            subdistrict_name: "Nongme",
          },
          {
            subdistrict_id: "4874",
            subdistrict_name: "Ok Aom",
          },
          {
            subdistrict_id: "4875",
            subdistrict_name: "Okbab",
          },
          {
            subdistrict_id: "4876",
            subdistrict_name: "Okbape",
          },
          {
            subdistrict_id: "4877",
            subdistrict_name: "Okbemtau",
          },
          {
            subdistrict_id: "4878",
            subdistrict_name: "Okbibab",
          },
          {
            subdistrict_id: "4879",
            subdistrict_name: "Okhika",
          },
          {
            subdistrict_id: "4880",
            subdistrict_name: "Oklip",
          },
          {
            subdistrict_id: "4881",
            subdistrict_name: "Oksamol",
          },
          {
            subdistrict_id: "4882",
            subdistrict_name: "Oksebang",
          },
          {
            subdistrict_id: "4883",
            subdistrict_name: "Oksibil",
          },
          {
            subdistrict_id: "4884",
            subdistrict_name: "Oksop",
          },
          {
            subdistrict_id: "4885",
            subdistrict_name: "Pamek",
          },
          {
            subdistrict_id: "4886",
            subdistrict_name: "Pepera",
          },
          {
            subdistrict_id: "4887",
            subdistrict_name: "Serambakon",
          },
          {
            subdistrict_id: "4888",
            subdistrict_name: "Tarup",
          },
          {
            subdistrict_id: "4889",
            subdistrict_name: "Teiraplu",
          },
          {
            subdistrict_id: "4890",
            subdistrict_name: "Weime",
          },
        ],
      },
      {
        city_id: "373",
        type: "Kabupaten",
        city_name: "Puncak",
        subdistricts: [
          {
            subdistrict_id: "5183",
            subdistrict_name: "Agadugume",
          },
          {
            subdistrict_id: "5184",
            subdistrict_name: "Beoga",
          },
          {
            subdistrict_id: "5185",
            subdistrict_name: "Doufu",
          },
          {
            subdistrict_id: "5186",
            subdistrict_name: "Gome",
          },
          {
            subdistrict_id: "5187",
            subdistrict_name: "Ilaga",
          },
          {
            subdistrict_id: "5188",
            subdistrict_name: "Pogoma",
          },
          {
            subdistrict_id: "5189",
            subdistrict_name: "Sinak",
          },
          {
            subdistrict_id: "5190",
            subdistrict_name: "Wangbe",
          },
        ],
      },
      {
        city_id: "374",
        type: "Kabupaten",
        city_name: "Puncak Jaya",
        subdistricts: [
          {
            subdistrict_id: "5191",
            subdistrict_name: "Fawi",
          },
          {
            subdistrict_id: "5192",
            subdistrict_name: "Ilu",
          },
          {
            subdistrict_id: "5193",
            subdistrict_name: "Jigonikme",
          },
          {
            subdistrict_id: "5194",
            subdistrict_name: "Mewoluk (Mewulok)",
          },
          {
            subdistrict_id: "5195",
            subdistrict_name: "Mulia",
          },
          {
            subdistrict_id: "5196",
            subdistrict_name: "Tingginambut",
          },
          {
            subdistrict_id: "5197",
            subdistrict_name: "Torere",
          },
          {
            subdistrict_id: "5198",
            subdistrict_name: "Yamo",
          },
        ],
      },
      {
        city_id: "392",
        type: "Kabupaten",
        city_name: "Sarmi",
        subdistricts: [
          {
            subdistrict_id: "5423",
            subdistrict_name: "Apawer Hulu",
          },
          {
            subdistrict_id: "5424",
            subdistrict_name: "Bonggo",
          },
          {
            subdistrict_id: "5425",
            subdistrict_name: "Bonggo Timur",
          },
          {
            subdistrict_id: "5426",
            subdistrict_name: "Pantai Barat",
          },
          {
            subdistrict_id: "5427",
            subdistrict_name: "Pantai Timur",
          },
          {
            subdistrict_id: "5428",
            subdistrict_name: "Pantai Timur Barat",
          },
          {
            subdistrict_id: "5429",
            subdistrict_name: "Sarmi",
          },
          {
            subdistrict_id: "5430",
            subdistrict_name: "Sarmi Selatan",
          },
          {
            subdistrict_id: "5431",
            subdistrict_name: "Sarmi Timur",
          },
          {
            subdistrict_id: "5432",
            subdistrict_name: "Tor Atas",
          },
        ],
      },
      {
        city_id: "443",
        type: "Kabupaten",
        city_name: "Supiori",
        subdistricts: [
          {
            subdistrict_id: "6126",
            subdistrict_name: "Kepulauan Aruri",
          },
          {
            subdistrict_id: "6127",
            subdistrict_name: "Supiori Barat",
          },
          {
            subdistrict_id: "6128",
            subdistrict_name: "Supiori Selatan",
          },
          {
            subdistrict_id: "6129",
            subdistrict_name: "Supiori Timur",
          },
          {
            subdistrict_id: "6130",
            subdistrict_name: "Supiori Utara",
          },
        ],
      },
      {
        city_id: "484",
        type: "Kabupaten",
        city_name: "Tolikara",
        subdistricts: [
          {
            subdistrict_id: "6687",
            subdistrict_name: "Airgaram",
          },
          {
            subdistrict_id: "6688",
            subdistrict_name: "Anawi",
          },
          {
            subdistrict_id: "6689",
            subdistrict_name: "Aweku",
          },
          {
            subdistrict_id: "6690",
            subdistrict_name: "Bewani",
          },
          {
            subdistrict_id: "6691",
            subdistrict_name: "Biuk",
          },
          {
            subdistrict_id: "6692",
            subdistrict_name: "Bogonuk",
          },
          {
            subdistrict_id: "6693",
            subdistrict_name: "Bokondini",
          },
          {
            subdistrict_id: "6694",
            subdistrict_name: "Bokoneri",
          },
          {
            subdistrict_id: "6695",
            subdistrict_name: "Danime",
          },
          {
            subdistrict_id: "6696",
            subdistrict_name: "Dow",
          },
          {
            subdistrict_id: "6697",
            subdistrict_name: "Dundu (Ndundu)",
          },
          {
            subdistrict_id: "6698",
            subdistrict_name: "Egiam",
          },
          {
            subdistrict_id: "6699",
            subdistrict_name: "Geya",
          },
          {
            subdistrict_id: "6700",
            subdistrict_name: "Gika",
          },
          {
            subdistrict_id: "6701",
            subdistrict_name: "Gilubandu (Gilumbandu/Gilimbandu)",
          },
          {
            subdistrict_id: "6702",
            subdistrict_name: "Goyage",
          },
          {
            subdistrict_id: "6703",
            subdistrict_name: "Gundagi (Gudage)",
          },
          {
            subdistrict_id: "6704",
            subdistrict_name: "Kai",
          },
          {
            subdistrict_id: "6705",
            subdistrict_name: "Kamboneri",
          },
          {
            subdistrict_id: "6706",
            subdistrict_name: "Kanggime (Kanggima )",
          },
          {
            subdistrict_id: "6707",
            subdistrict_name: "Karubaga",
          },
          {
            subdistrict_id: "6708",
            subdistrict_name: "Kembu",
          },
          {
            subdistrict_id: "6709",
            subdistrict_name: "Kondaga (Konda)",
          },
          {
            subdistrict_id: "6710",
            subdistrict_name: "Kuari",
          },
          {
            subdistrict_id: "6711",
            subdistrict_name: "Kubu",
          },
          {
            subdistrict_id: "6712",
            subdistrict_name: "Li Anogomma",
          },
          {
            subdistrict_id: "6713",
            subdistrict_name: "Nabunage",
          },
          {
            subdistrict_id: "6714",
            subdistrict_name: "Nelawi",
          },
          {
            subdistrict_id: "6715",
            subdistrict_name: "Numba",
          },
          {
            subdistrict_id: "6716",
            subdistrict_name: "Nunggawi (Munggawi)",
          },
          {
            subdistrict_id: "6717",
            subdistrict_name: "Panaga",
          },
          {
            subdistrict_id: "6718",
            subdistrict_name: "Poganeri",
          },
          {
            subdistrict_id: "6719",
            subdistrict_name: "Tagime",
          },
          {
            subdistrict_id: "6720",
            subdistrict_name: "Tagineri",
          },
          {
            subdistrict_id: "6721",
            subdistrict_name: "Telenggeme",
          },
          {
            subdistrict_id: "6722",
            subdistrict_name: "Timori",
          },
          {
            subdistrict_id: "6723",
            subdistrict_name: "Umagi",
          },
          {
            subdistrict_id: "6724",
            subdistrict_name: "Wakuwo",
          },
          {
            subdistrict_id: "6725",
            subdistrict_name: "Wari (Taiyeve)",
          },
          {
            subdistrict_id: "6726",
            subdistrict_name: "Wenam",
          },
          {
            subdistrict_id: "6727",
            subdistrict_name: "Wina",
          },
          {
            subdistrict_id: "6728",
            subdistrict_name: "Wonoki (Woniki)",
          },
          {
            subdistrict_id: "6729",
            subdistrict_name: "Wugi",
          },
          {
            subdistrict_id: "6730",
            subdistrict_name: "Wunin (Wumin)",
          },
          {
            subdistrict_id: "6731",
            subdistrict_name: "Yuko",
          },
          {
            subdistrict_id: "6732",
            subdistrict_name: "Yuneri",
          },
        ],
      },
      {
        city_id: "495",
        type: "Kabupaten",
        city_name: "Waropen",
        subdistricts: [
          {
            subdistrict_id: "6862",
            subdistrict_name: "Demba Masirei",
          },
          {
            subdistrict_id: "6863",
            subdistrict_name: "Inggerus",
          },
          {
            subdistrict_id: "6864",
            subdistrict_name: "Kirihi",
          },
          {
            subdistrict_id: "6865",
            subdistrict_name: "Masirei",
          },
          {
            subdistrict_id: "6866",
            subdistrict_name: "Oudate Waropen",
          },
          {
            subdistrict_id: "6867",
            subdistrict_name: "Risei Sayati",
          },
          {
            subdistrict_id: "6868",
            subdistrict_name: "Ureifasei",
          },
          {
            subdistrict_id: "6869",
            subdistrict_name: "Wapoga Inggerus",
          },
          {
            subdistrict_id: "6870",
            subdistrict_name: "Waropen Bawah",
          },
        ],
      },
      {
        city_id: "499",
        type: "Kabupaten",
        city_name: "Yahukimo",
        subdistricts: [
          {
            subdistrict_id: "6925",
            subdistrict_name: "Amuma",
          },
          {
            subdistrict_id: "6926",
            subdistrict_name: "Anggruk",
          },
          {
            subdistrict_id: "6927",
            subdistrict_name: "Bomela",
          },
          {
            subdistrict_id: "6928",
            subdistrict_name: "Dekai",
          },
          {
            subdistrict_id: "6929",
            subdistrict_name: "Dirwemna (Diruwena)",
          },
          {
            subdistrict_id: "6930",
            subdistrict_name: "Duram",
          },
          {
            subdistrict_id: "6931",
            subdistrict_name: "Endomen",
          },
          {
            subdistrict_id: "6932",
            subdistrict_name: "Hereapini (Hereanini)",
          },
          {
            subdistrict_id: "6933",
            subdistrict_name: "Hilipuk",
          },
          {
            subdistrict_id: "6934",
            subdistrict_name: "Hogio (Hugio)",
          },
          {
            subdistrict_id: "6935",
            subdistrict_name: "Holuon",
          },
          {
            subdistrict_id: "6936",
            subdistrict_name: "Kabianggama (Kabianggema)",
          },
          {
            subdistrict_id: "6937",
            subdistrict_name: "Kayo",
          },
          {
            subdistrict_id: "6938",
            subdistrict_name: "Kona",
          },
          {
            subdistrict_id: "6939",
            subdistrict_name: "Koropun (Korupun)",
          },
          {
            subdistrict_id: "6940",
            subdistrict_name: "Kosarek",
          },
          {
            subdistrict_id: "6941",
            subdistrict_name: "Kurima",
          },
          {
            subdistrict_id: "6942",
            subdistrict_name: "Kwelemdua (Kwelamdua)",
          },
          {
            subdistrict_id: "6943",
            subdistrict_name: "Kwikma",
          },
          {
            subdistrict_id: "6944",
            subdistrict_name: "Langda",
          },
          {
            subdistrict_id: "6945",
            subdistrict_name: "Lolat",
          },
          {
            subdistrict_id: "6946",
            subdistrict_name: "Mugi",
          },
          {
            subdistrict_id: "6947",
            subdistrict_name: "Musaik",
          },
          {
            subdistrict_id: "6948",
            subdistrict_name: "Nalca",
          },
          {
            subdistrict_id: "6949",
            subdistrict_name: "Ninia",
          },
          {
            subdistrict_id: "6950",
            subdistrict_name: "Nipsan",
          },
          {
            subdistrict_id: "6951",
            subdistrict_name: "Obio",
          },
          {
            subdistrict_id: "6952",
            subdistrict_name: "Panggema",
          },
          {
            subdistrict_id: "6953",
            subdistrict_name: "Pasema",
          },
          {
            subdistrict_id: "6954",
            subdistrict_name: "Pronggoli (Proggoli)",
          },
          {
            subdistrict_id: "6955",
            subdistrict_name: "Puldama",
          },
          {
            subdistrict_id: "6956",
            subdistrict_name: "Samenage",
          },
          {
            subdistrict_id: "6957",
            subdistrict_name: "Sela",
          },
          {
            subdistrict_id: "6958",
            subdistrict_name: "Seredela (Seredala)",
          },
          {
            subdistrict_id: "6959",
            subdistrict_name: "Silimo",
          },
          {
            subdistrict_id: "6960",
            subdistrict_name: "Soba",
          },
          {
            subdistrict_id: "6961",
            subdistrict_name: "Sobaham",
          },
          {
            subdistrict_id: "6962",
            subdistrict_name: "Soloikma",
          },
          {
            subdistrict_id: "6963",
            subdistrict_name: "Sumo",
          },
          {
            subdistrict_id: "6964",
            subdistrict_name: "Suntamon",
          },
          {
            subdistrict_id: "6965",
            subdistrict_name: "Suru Suru",
          },
          {
            subdistrict_id: "6966",
            subdistrict_name: "Talambo",
          },
          {
            subdistrict_id: "6967",
            subdistrict_name: "Tangma",
          },
          {
            subdistrict_id: "6968",
            subdistrict_name: "Ubahak",
          },
          {
            subdistrict_id: "6969",
            subdistrict_name: "Ubalihi",
          },
          {
            subdistrict_id: "6970",
            subdistrict_name: "Ukha",
          },
          {
            subdistrict_id: "6971",
            subdistrict_name: "Walma",
          },
          {
            subdistrict_id: "6972",
            subdistrict_name: "Werima",
          },
          {
            subdistrict_id: "6973",
            subdistrict_name: "Wusuma",
          },
          {
            subdistrict_id: "6974",
            subdistrict_name: "Yahuliambut",
          },
          {
            subdistrict_id: "6975",
            subdistrict_name: "Yogosem",
          },
        ],
      },
      {
        city_id: "500",
        type: "Kabupaten",
        city_name: "Yalimo",
        subdistricts: [
          {
            subdistrict_id: "6976",
            subdistrict_name: "Abenaho",
          },
          {
            subdistrict_id: "6977",
            subdistrict_name: "Apalapsili",
          },
          {
            subdistrict_id: "6978",
            subdistrict_name: "Benawa",
          },
          {
            subdistrict_id: "6979",
            subdistrict_name: "Elelim",
          },
          {
            subdistrict_id: "6980",
            subdistrict_name: "Welarek",
          },
        ],
      },
    ],
  },
  {
    province_id: "25",
    province: "Papua Barat",
    cities: [
      {
        city_id: "124",
        type: "Kabupaten",
        city_name: "Fakfak",
        subdistricts: [
          {
            subdistrict_id: "1683",
            subdistrict_name: "Bombarai (Bomberay)",
          },
          {
            subdistrict_id: "1684",
            subdistrict_name: "Fakfak",
          },
          {
            subdistrict_id: "1685",
            subdistrict_name: "Fakfak Barat",
          },
          {
            subdistrict_id: "1686",
            subdistrict_name: "Fakfak Tengah",
          },
          {
            subdistrict_id: "1687",
            subdistrict_name: "Fakfak Timur",
          },
          {
            subdistrict_id: "1688",
            subdistrict_name: "Karas",
          },
          {
            subdistrict_id: "1689",
            subdistrict_name: "Kokas",
          },
          {
            subdistrict_id: "1690",
            subdistrict_name: "Kramongmongga (Kramamongga)",
          },
          {
            subdistrict_id: "1691",
            subdistrict_name: "Teluk Patipi",
          },
        ],
      },
      {
        city_id: "165",
        type: "Kabupaten",
        city_name: "Kaimana",
        subdistricts: [
          {
            subdistrict_id: "2285",
            subdistrict_name: "Buruway",
          },
          {
            subdistrict_id: "2286",
            subdistrict_name: "Kaimana",
          },
          {
            subdistrict_id: "2287",
            subdistrict_name: "Kambraw (Kamberau)",
          },
          {
            subdistrict_id: "2288",
            subdistrict_name: "Teluk Arguni Atas",
          },
          {
            subdistrict_id: "2289",
            subdistrict_name: "Teluk Arguni Bawah (Yerusi)",
          },
          {
            subdistrict_id: "2290",
            subdistrict_name: "Teluk Etna",
          },
          {
            subdistrict_id: "2291",
            subdistrict_name: "Yamor",
          },
        ],
      },
      {
        city_id: "272",
        type: "Kabupaten",
        city_name: "Manokwari",
        subdistricts: [
          {
            subdistrict_id: "3832",
            subdistrict_name: "Manokwari Barat",
          },
          {
            subdistrict_id: "3833",
            subdistrict_name: "Manokwari Selatan",
          },
          {
            subdistrict_id: "3834",
            subdistrict_name: "Manokwari Timur",
          },
          {
            subdistrict_id: "3835",
            subdistrict_name: "Manokwari Utara",
          },
          {
            subdistrict_id: "3836",
            subdistrict_name: "Masni",
          },
          {
            subdistrict_id: "3837",
            subdistrict_name: "Prafi",
          },
          {
            subdistrict_id: "3838",
            subdistrict_name: "Sidey",
          },
          {
            subdistrict_id: "3839",
            subdistrict_name: "Tanah Rubuh",
          },
          {
            subdistrict_id: "3840",
            subdistrict_name: "Warmare",
          },
        ],
      },
      {
        city_id: "273",
        type: "Kabupaten",
        city_name: "Manokwari Selatan",
        subdistricts: [
          {
            subdistrict_id: "3841",
            subdistrict_name: "Dataran Isim",
          },
          {
            subdistrict_id: "3842",
            subdistrict_name: "Momi Waren",
          },
          {
            subdistrict_id: "3843",
            subdistrict_name: "Neney (Nenei)",
          },
          {
            subdistrict_id: "3844",
            subdistrict_name: "Oransbari",
          },
          {
            subdistrict_id: "3845",
            subdistrict_name: "Ransiki",
          },
          {
            subdistrict_id: "3846",
            subdistrict_name: "Tahota (Tohota)",
          },
        ],
      },
      {
        city_id: "277",
        type: "Kabupaten",
        city_name: "Maybrat",
        subdistricts: [
          {
            subdistrict_id: "3882",
            subdistrict_name: "Aifat",
          },
          {
            subdistrict_id: "3883",
            subdistrict_name: "Aifat Selatan",
          },
          {
            subdistrict_id: "3884",
            subdistrict_name: "Aifat Timur",
          },
          {
            subdistrict_id: "3885",
            subdistrict_name: "Aifat Timur Jauh",
          },
          {
            subdistrict_id: "3886",
            subdistrict_name: "Aifat Timur Selatan",
          },
          {
            subdistrict_id: "3887",
            subdistrict_name: "Aifat Timur Tengah",
          },
          {
            subdistrict_id: "3888",
            subdistrict_name: "Aifat Utara",
          },
          {
            subdistrict_id: "3889",
            subdistrict_name: "Aitinyo",
          },
          {
            subdistrict_id: "3890",
            subdistrict_name: "Aitinyo Barat",
          },
          {
            subdistrict_id: "3891",
            subdistrict_name: "Aitinyo Raya",
          },
          {
            subdistrict_id: "3892",
            subdistrict_name: "Aitinyo Tengah",
          },
          {
            subdistrict_id: "3893",
            subdistrict_name: "Aitinyo Utara",
          },
          {
            subdistrict_id: "3894",
            subdistrict_name: "Ayamaru",
          },
          {
            subdistrict_id: "3895",
            subdistrict_name: "Ayamaru Barat",
          },
          {
            subdistrict_id: "3896",
            subdistrict_name: "Ayamaru Jaya",
          },
          {
            subdistrict_id: "3897",
            subdistrict_name: "Ayamaru Selatan",
          },
          {
            subdistrict_id: "3898",
            subdistrict_name: "Ayamaru Selatan Jaya",
          },
          {
            subdistrict_id: "3899",
            subdistrict_name: "Ayamaru Tengah",
          },
          {
            subdistrict_id: "3900",
            subdistrict_name: "Ayamaru Timur",
          },
          {
            subdistrict_id: "3901",
            subdistrict_name: "Ayamaru Timur Selatan",
          },
          {
            subdistrict_id: "3902",
            subdistrict_name: "Ayamaru Utara",
          },
          {
            subdistrict_id: "3903",
            subdistrict_name: "Ayamaru Utara Timur",
          },
          {
            subdistrict_id: "3904",
            subdistrict_name: "Mare",
          },
          {
            subdistrict_id: "3905",
            subdistrict_name: "Mare Selatan",
          },
        ],
      },
      {
        city_id: "346",
        type: "Kabupaten",
        city_name: "Pegunungan Arfak",
        subdistricts: [
          {
            subdistrict_id: "4847",
            subdistrict_name: "Anggi",
          },
          {
            subdistrict_id: "4848",
            subdistrict_name: "Anggi Gida",
          },
          {
            subdistrict_id: "4849",
            subdistrict_name: "Catubouw",
          },
          {
            subdistrict_id: "4850",
            subdistrict_name: "Didohu",
          },
          {
            subdistrict_id: "4851",
            subdistrict_name: "Hingk",
          },
          {
            subdistrict_id: "4852",
            subdistrict_name: "Membey",
          },
          {
            subdistrict_id: "4853",
            subdistrict_name: "Menyambouw (Minyambouw)",
          },
          {
            subdistrict_id: "4854",
            subdistrict_name: "Sururey",
          },
          {
            subdistrict_id: "4855",
            subdistrict_name: "Taige",
          },
          {
            subdistrict_id: "4856",
            subdistrict_name: "Testega",
          },
        ],
      },
      {
        city_id: "378",
        type: "Kabupaten",
        city_name: "Raja Ampat",
        subdistricts: [
          {
            subdistrict_id: "5250",
            subdistrict_name: "Ayau",
          },
          {
            subdistrict_id: "5251",
            subdistrict_name: "Batanta Selatan",
          },
          {
            subdistrict_id: "5252",
            subdistrict_name: "Batanta Utara",
          },
          {
            subdistrict_id: "5253",
            subdistrict_name: "Kepulauan Ayau",
          },
          {
            subdistrict_id: "5254",
            subdistrict_name: "Kepulauan Sembilan",
          },
          {
            subdistrict_id: "5255",
            subdistrict_name: "Kofiau",
          },
          {
            subdistrict_id: "5256",
            subdistrict_name: "Kota Waisai",
          },
          {
            subdistrict_id: "5257",
            subdistrict_name: "Meos Mansar",
          },
          {
            subdistrict_id: "5258",
            subdistrict_name: "Misool (Misool Utara)",
          },
          {
            subdistrict_id: "5259",
            subdistrict_name: "Misool Barat",
          },
          {
            subdistrict_id: "5260",
            subdistrict_name: "Misool Selatan",
          },
          {
            subdistrict_id: "5261",
            subdistrict_name: "Misool Timur",
          },
          {
            subdistrict_id: "5262",
            subdistrict_name: "Salawati Barat",
          },
          {
            subdistrict_id: "5263",
            subdistrict_name: "Salawati Tengah",
          },
          {
            subdistrict_id: "5264",
            subdistrict_name: "Salawati Utara (Samate)",
          },
          {
            subdistrict_id: "5265",
            subdistrict_name: "Supnin",
          },
          {
            subdistrict_id: "5266",
            subdistrict_name: "Teluk Mayalibit",
          },
          {
            subdistrict_id: "5267",
            subdistrict_name: "Tiplol Mayalibit",
          },
          {
            subdistrict_id: "5268",
            subdistrict_name: "Waigeo Barat",
          },
          {
            subdistrict_id: "5269",
            subdistrict_name: "Waigeo Barat Kepulauan",
          },
          {
            subdistrict_id: "5270",
            subdistrict_name: "Waigeo Selatan",
          },
          {
            subdistrict_id: "5271",
            subdistrict_name: "Waigeo Timur",
          },
          {
            subdistrict_id: "5272",
            subdistrict_name: "Waigeo Utara",
          },
          {
            subdistrict_id: "5273",
            subdistrict_name: "Warwabomi",
          },
        ],
      },
      {
        city_id: "424",
        type: "Kabupaten",
        city_name: "Sorong",
        subdistricts: [
          {
            subdistrict_id: "5827",
            subdistrict_name: "Aimas",
          },
          {
            subdistrict_id: "5828",
            subdistrict_name: "Beraur",
          },
          {
            subdistrict_id: "5829",
            subdistrict_name: "Klabot",
          },
          {
            subdistrict_id: "5830",
            subdistrict_name: "Klamono",
          },
          {
            subdistrict_id: "5831",
            subdistrict_name: "Klaso",
          },
          {
            subdistrict_id: "5832",
            subdistrict_name: "Klawak",
          },
          {
            subdistrict_id: "5833",
            subdistrict_name: "Klayili",
          },
          {
            subdistrict_id: "5834",
            subdistrict_name: "Makbon",
          },
          {
            subdistrict_id: "5835",
            subdistrict_name: "Mariat",
          },
          {
            subdistrict_id: "5836",
            subdistrict_name: "Maudus",
          },
          {
            subdistrict_id: "5837",
            subdistrict_name: "Mayamuk",
          },
          {
            subdistrict_id: "5838",
            subdistrict_name: "Moisegen",
          },
          {
            subdistrict_id: "5839",
            subdistrict_name: "Salawati",
          },
          {
            subdistrict_id: "5840",
            subdistrict_name: "Salawati Selatan",
          },
          {
            subdistrict_id: "5841",
            subdistrict_name: "Sayosa",
          },
          {
            subdistrict_id: "5842",
            subdistrict_name: "Seget",
          },
          {
            subdistrict_id: "5843",
            subdistrict_name: "Segun",
          },
        ],
      },
      {
        city_id: "425",
        type: "Kota",
        city_name: "Sorong",
        subdistricts: [
          {
            subdistrict_id: "5844",
            subdistrict_name: "Sorong",
          },
          {
            subdistrict_id: "5845",
            subdistrict_name: "Sorong Barat",
          },
          {
            subdistrict_id: "5846",
            subdistrict_name: "Sorong Kepulauan",
          },
          {
            subdistrict_id: "5847",
            subdistrict_name: "Sorong Manoi",
          },
          {
            subdistrict_id: "5848",
            subdistrict_name: "Sorong Timur",
          },
          {
            subdistrict_id: "5849",
            subdistrict_name: "Sorong Utara",
          },
        ],
      },
      {
        city_id: "426",
        type: "Kabupaten",
        city_name: "Sorong Selatan",
        subdistricts: [
          {
            subdistrict_id: "5850",
            subdistrict_name: "Fokour",
          },
          {
            subdistrict_id: "5851",
            subdistrict_name: "Inanwatan (Inawatan)",
          },
          {
            subdistrict_id: "5852",
            subdistrict_name: "Kais (Matemani Kais)",
          },
          {
            subdistrict_id: "5853",
            subdistrict_name: "Kokoda",
          },
          {
            subdistrict_id: "5854",
            subdistrict_name: "Kokoda Utara",
          },
          {
            subdistrict_id: "5855",
            subdistrict_name: "Konda",
          },
          {
            subdistrict_id: "5856",
            subdistrict_name: "Matemani",
          },
          {
            subdistrict_id: "5857",
            subdistrict_name: "Moswaren",
          },
          {
            subdistrict_id: "5858",
            subdistrict_name: "Saifi",
          },
          {
            subdistrict_id: "5859",
            subdistrict_name: "Sawiat",
          },
          {
            subdistrict_id: "5860",
            subdistrict_name: "Seremuk",
          },
          {
            subdistrict_id: "5861",
            subdistrict_name: "Teminabuan",
          },
          {
            subdistrict_id: "5862",
            subdistrict_name: "Wayer",
          },
        ],
      },
      {
        city_id: "449",
        type: "Kabupaten",
        city_name: "Tambrauw",
        subdistricts: [
          {
            subdistrict_id: "6198",
            subdistrict_name: "Abun",
          },
          {
            subdistrict_id: "6199",
            subdistrict_name: "Amberbaken",
          },
          {
            subdistrict_id: "6200",
            subdistrict_name: "Fef (Peef)",
          },
          {
            subdistrict_id: "6201",
            subdistrict_name: "Kebar",
          },
          {
            subdistrict_id: "6202",
            subdistrict_name: "Kwoor",
          },
          {
            subdistrict_id: "6203",
            subdistrict_name: "Miyah (Meyah)",
          },
          {
            subdistrict_id: "6204",
            subdistrict_name: "Moraid",
          },
          {
            subdistrict_id: "6205",
            subdistrict_name: "Mubrani",
          },
          {
            subdistrict_id: "6206",
            subdistrict_name: "Sausapor",
          },
          {
            subdistrict_id: "6207",
            subdistrict_name: "Senopi",
          },
          {
            subdistrict_id: "6208",
            subdistrict_name: "Syujak",
          },
          {
            subdistrict_id: "6209",
            subdistrict_name: "Yembun",
          },
        ],
      },
      {
        city_id: "474",
        type: "Kabupaten",
        city_name: "Teluk Bintuni",
        subdistricts: [
          {
            subdistrict_id: "6524",
            subdistrict_name: "Aranday",
          },
          {
            subdistrict_id: "6525",
            subdistrict_name: "Aroba",
          },
          {
            subdistrict_id: "6526",
            subdistrict_name: "Babo",
          },
          {
            subdistrict_id: "6527",
            subdistrict_name: "Bintuni",
          },
          {
            subdistrict_id: "6528",
            subdistrict_name: "Biscoop",
          },
          {
            subdistrict_id: "6529",
            subdistrict_name: "Dataran Beimes",
          },
          {
            subdistrict_id: "6530",
            subdistrict_name: "Fafurwar (Irorutu)",
          },
          {
            subdistrict_id: "6531",
            subdistrict_name: "Kaitaro",
          },
          {
            subdistrict_id: "6532",
            subdistrict_name: "Kamundan",
          },
          {
            subdistrict_id: "6533",
            subdistrict_name: "Kuri",
          },
          {
            subdistrict_id: "6534",
            subdistrict_name: "Manimeri",
          },
          {
            subdistrict_id: "6535",
            subdistrict_name: "Masyeta",
          },
          {
            subdistrict_id: "6536",
            subdistrict_name: "Mayado",
          },
          {
            subdistrict_id: "6537",
            subdistrict_name: "Merdey",
          },
          {
            subdistrict_id: "6538",
            subdistrict_name: "Moskona Barat",
          },
          {
            subdistrict_id: "6539",
            subdistrict_name: "Moskona Selatan",
          },
          {
            subdistrict_id: "6540",
            subdistrict_name: "Moskona Timur",
          },
          {
            subdistrict_id: "6541",
            subdistrict_name: "Moskona Utara",
          },
          {
            subdistrict_id: "6542",
            subdistrict_name: "Sumuri (Simuri)",
          },
          {
            subdistrict_id: "6543",
            subdistrict_name: "Tembuni",
          },
          {
            subdistrict_id: "6544",
            subdistrict_name: "Tomu",
          },
          {
            subdistrict_id: "6545",
            subdistrict_name: "Tuhiba",
          },
          {
            subdistrict_id: "6546",
            subdistrict_name: "Wamesa (Idoor)",
          },
          {
            subdistrict_id: "6547",
            subdistrict_name: "Weriagar",
          },
        ],
      },
      {
        city_id: "475",
        type: "Kabupaten",
        city_name: "Teluk Wondama",
        subdistricts: [
          {
            subdistrict_id: "6548",
            subdistrict_name: "Kuri Wamesa",
          },
          {
            subdistrict_id: "6549",
            subdistrict_name: "Naikere (Wasior Barat)",
          },
          {
            subdistrict_id: "6550",
            subdistrict_name: "Nikiwar",
          },
          {
            subdistrict_id: "6551",
            subdistrict_name: "Rasiei",
          },
          {
            subdistrict_id: "6552",
            subdistrict_name: "Roon",
          },
          {
            subdistrict_id: "6553",
            subdistrict_name: "Roswar",
          },
          {
            subdistrict_id: "6554",
            subdistrict_name: "Rumberpon",
          },
          {
            subdistrict_id: "6555",
            subdistrict_name: "Soug Jaya",
          },
          {
            subdistrict_id: "6556",
            subdistrict_name: "Teluk Duairi (Wasior Utara)",
          },
          {
            subdistrict_id: "6557",
            subdistrict_name: "Wamesa",
          },
          {
            subdistrict_id: "6558",
            subdistrict_name: "Wasior",
          },
          {
            subdistrict_id: "6559",
            subdistrict_name: "Windesi",
          },
          {
            subdistrict_id: "6560",
            subdistrict_name: "Wondiboy (Wasior Selatan)",
          },
        ],
      },
    ],
  },
  {
    province_id: "26",
    province: "Riau",
    cities: [
      {
        city_id: "60",
        type: "Kabupaten",
        city_name: "Bengkalis",
        subdistricts: [
          {
            subdistrict_id: "807",
            subdistrict_name: "Bantan",
          },
          {
            subdistrict_id: "808",
            subdistrict_name: "Bengkalis",
          },
          {
            subdistrict_id: "809",
            subdistrict_name: "Bukit Batu",
          },
          {
            subdistrict_id: "810",
            subdistrict_name: "Mandau",
          },
          {
            subdistrict_id: "811",
            subdistrict_name: "Pinggir",
          },
          {
            subdistrict_id: "812",
            subdistrict_name: "Rupat",
          },
          {
            subdistrict_id: "813",
            subdistrict_name: "Rupat Utara",
          },
          {
            subdistrict_id: "814",
            subdistrict_name: "Siak Kecil",
          },
        ],
      },
      {
        city_id: "120",
        type: "Kota",
        city_name: "Dumai",
        subdistricts: [
          {
            subdistrict_id: "1633",
            subdistrict_name: "Bukit Kapur",
          },
          {
            subdistrict_id: "1634",
            subdistrict_name: "Dumai Barat",
          },
          {
            subdistrict_id: "1635",
            subdistrict_name: "Dumai Kota",
          },
          {
            subdistrict_id: "1636",
            subdistrict_name: "Dumai Selatan",
          },
          {
            subdistrict_id: "1637",
            subdistrict_name: "Dumai Timur",
          },
          {
            subdistrict_id: "1638",
            subdistrict_name: "Medang Kampai",
          },
          {
            subdistrict_id: "1639",
            subdistrict_name: "Sungai Sembilan",
          },
        ],
      },
      {
        city_id: "147",
        type: "Kabupaten",
        city_name: "Indragiri Hilir",
        subdistricts: [
          {
            subdistrict_id: "2016",
            subdistrict_name: "Batang Tuaka",
          },
          {
            subdistrict_id: "2017",
            subdistrict_name: "Concong",
          },
          {
            subdistrict_id: "2018",
            subdistrict_name: "Enok",
          },
          {
            subdistrict_id: "2019",
            subdistrict_name: "Gaung",
          },
          {
            subdistrict_id: "2020",
            subdistrict_name: "Gaung Anak Serka",
          },
          {
            subdistrict_id: "2021",
            subdistrict_name: "Kateman",
          },
          {
            subdistrict_id: "2022",
            subdistrict_name: "Kempas",
          },
          {
            subdistrict_id: "2023",
            subdistrict_name: "Kemuning",
          },
          {
            subdistrict_id: "2024",
            subdistrict_name: "Keritang",
          },
          {
            subdistrict_id: "2025",
            subdistrict_name: "Kuala Indragiri",
          },
          {
            subdistrict_id: "2026",
            subdistrict_name: "Mandah",
          },
          {
            subdistrict_id: "2027",
            subdistrict_name: "Pelangiran",
          },
          {
            subdistrict_id: "2028",
            subdistrict_name: "Pulau Burung",
          },
          {
            subdistrict_id: "2029",
            subdistrict_name: "Reteh",
          },
          {
            subdistrict_id: "2030",
            subdistrict_name: "Sungai Batang",
          },
          {
            subdistrict_id: "2031",
            subdistrict_name: "Tanah Merah",
          },
          {
            subdistrict_id: "2032",
            subdistrict_name: "Teluk Belengkong",
          },
          {
            subdistrict_id: "2033",
            subdistrict_name: "Tembilahan",
          },
          {
            subdistrict_id: "2034",
            subdistrict_name: "Tembilahan Hulu",
          },
          {
            subdistrict_id: "2035",
            subdistrict_name: "Tempuling",
          },
        ],
      },
      {
        city_id: "148",
        type: "Kabupaten",
        city_name: "Indragiri Hulu",
        subdistricts: [
          {
            subdistrict_id: "2036",
            subdistrict_name: "Batang Cenaku",
          },
          {
            subdistrict_id: "2037",
            subdistrict_name: "Batang Gansal",
          },
          {
            subdistrict_id: "2038",
            subdistrict_name: "Batang Peranap",
          },
          {
            subdistrict_id: "2039",
            subdistrict_name: "Kelayang",
          },
          {
            subdistrict_id: "2040",
            subdistrict_name: "Kuala Cenaku",
          },
          {
            subdistrict_id: "2041",
            subdistrict_name: "Lirik",
          },
          {
            subdistrict_id: "2042",
            subdistrict_name: "Lubuk Batu Jaya",
          },
          {
            subdistrict_id: "2043",
            subdistrict_name: "Pasir Penyu",
          },
          {
            subdistrict_id: "2044",
            subdistrict_name: "Peranap",
          },
          {
            subdistrict_id: "2045",
            subdistrict_name: "Rakit Kulim",
          },
          {
            subdistrict_id: "2046",
            subdistrict_name: "Rengat",
          },
          {
            subdistrict_id: "2047",
            subdistrict_name: "Rengat Barat",
          },
          {
            subdistrict_id: "2048",
            subdistrict_name: "Seberida",
          },
          {
            subdistrict_id: "2049",
            subdistrict_name: "Sungai Lala",
          },
        ],
      },
      {
        city_id: "166",
        type: "Kabupaten",
        city_name: "Kampar",
        subdistricts: [
          {
            subdistrict_id: "2292",
            subdistrict_name: "Bangkinang",
          },
          {
            subdistrict_id: "2293",
            subdistrict_name: "Bangkinang Seberang",
          },
          {
            subdistrict_id: "2294",
            subdistrict_name: "Gunung Sahilan",
          },
          {
            subdistrict_id: "2295",
            subdistrict_name: "Kampar",
          },
          {
            subdistrict_id: "2296",
            subdistrict_name: "Kampar Kiri",
          },
          {
            subdistrict_id: "2297",
            subdistrict_name: "Kampar Kiri Hilir",
          },
          {
            subdistrict_id: "2298",
            subdistrict_name: "Kampar Kiri Hulu",
          },
          {
            subdistrict_id: "2299",
            subdistrict_name: "Kampar Kiri Tengah",
          },
          {
            subdistrict_id: "2300",
            subdistrict_name: "Kampar Timur",
          },
          {
            subdistrict_id: "2301",
            subdistrict_name: "Kampar Utara",
          },
          {
            subdistrict_id: "2302",
            subdistrict_name: "Koto Kampar Hulu",
          },
          {
            subdistrict_id: "2303",
            subdistrict_name: "Kuok (Bangkinang Barat)",
          },
          {
            subdistrict_id: "2304",
            subdistrict_name: "Perhentian Raja",
          },
          {
            subdistrict_id: "2305",
            subdistrict_name: "Rumbio Jaya",
          },
          {
            subdistrict_id: "2306",
            subdistrict_name: "Salo",
          },
          {
            subdistrict_id: "2307",
            subdistrict_name: "Siak Hulu",
          },
          {
            subdistrict_id: "2308",
            subdistrict_name: "Tambang",
          },
          {
            subdistrict_id: "2309",
            subdistrict_name: "Tapung",
          },
          {
            subdistrict_id: "2310",
            subdistrict_name: "Tapung Hilir",
          },
          {
            subdistrict_id: "2311",
            subdistrict_name: "Tapung Hulu",
          },
          {
            subdistrict_id: "2312",
            subdistrict_name: "XIII Koto Kampar",
          },
        ],
      },
      {
        city_id: "187",
        type: "Kabupaten",
        city_name: "Kepulauan Meranti",
        subdistricts: [
          {
            subdistrict_id: "2598",
            subdistrict_name: "Merbau",
          },
          {
            subdistrict_id: "2599",
            subdistrict_name: "Pulaumerbau",
          },
          {
            subdistrict_id: "2600",
            subdistrict_name: "Rangsang",
          },
          {
            subdistrict_id: "2601",
            subdistrict_name: "Rangsang Barat",
          },
          {
            subdistrict_id: "2602",
            subdistrict_name: "Rangsang Pesisir",
          },
          {
            subdistrict_id: "2603",
            subdistrict_name: "Tasik Putri Puyu",
          },
          {
            subdistrict_id: "2604",
            subdistrict_name: "Tebing Tinggi",
          },
          {
            subdistrict_id: "2605",
            subdistrict_name: "Tebing Tinggi Barat",
          },
          {
            subdistrict_id: "2606",
            subdistrict_name: "Tebing Tinggi Timur",
          },
        ],
      },
      {
        city_id: "207",
        type: "Kabupaten",
        city_name: "Kuantan Singingi",
        subdistricts: [
          {
            subdistrict_id: "2897",
            subdistrict_name: "Benai",
          },
          {
            subdistrict_id: "2898",
            subdistrict_name: "Cerenti",
          },
          {
            subdistrict_id: "2899",
            subdistrict_name: "Gunung Toar",
          },
          {
            subdistrict_id: "2900",
            subdistrict_name: "Hulu Kuantan",
          },
          {
            subdistrict_id: "2901",
            subdistrict_name: "Inuman",
          },
          {
            subdistrict_id: "2902",
            subdistrict_name: "Kuantan Hilir",
          },
          {
            subdistrict_id: "2903",
            subdistrict_name: "Kuantan Hilir Seberang",
          },
          {
            subdistrict_id: "2904",
            subdistrict_name: "Kuantan Mudik",
          },
          {
            subdistrict_id: "2905",
            subdistrict_name: "Kuantan Tengah",
          },
          {
            subdistrict_id: "2906",
            subdistrict_name: "Logas Tanah Darat",
          },
          {
            subdistrict_id: "2907",
            subdistrict_name: "Pangean",
          },
          {
            subdistrict_id: "2908",
            subdistrict_name: "Pucuk Rantau",
          },
          {
            subdistrict_id: "2909",
            subdistrict_name: "Sentajo Raya",
          },
          {
            subdistrict_id: "2910",
            subdistrict_name: "Singingi",
          },
          {
            subdistrict_id: "2911",
            subdistrict_name: "Singingi Hilir",
          },
        ],
      },
      {
        city_id: "350",
        type: "Kota",
        city_name: "Pekanbaru",
        subdistricts: [
          {
            subdistrict_id: "4914",
            subdistrict_name: "Bukit Raya",
          },
          {
            subdistrict_id: "4915",
            subdistrict_name: "Lima Puluh",
          },
          {
            subdistrict_id: "4916",
            subdistrict_name: "Marpoyan Damai",
          },
          {
            subdistrict_id: "4917",
            subdistrict_name: "Payung Sekaki",
          },
          {
            subdistrict_id: "4918",
            subdistrict_name: "Pekanbaru Kota",
          },
          {
            subdistrict_id: "4919",
            subdistrict_name: "Rumbai",
          },
          {
            subdistrict_id: "4920",
            subdistrict_name: "Rumbai Pesisir",
          },
          {
            subdistrict_id: "4921",
            subdistrict_name: "Sail",
          },
          {
            subdistrict_id: "4922",
            subdistrict_name: "Senapelan",
          },
          {
            subdistrict_id: "4923",
            subdistrict_name: "Sukajadi",
          },
          {
            subdistrict_id: "4924",
            subdistrict_name: "Tampan",
          },
          {
            subdistrict_id: "4925",
            subdistrict_name: "Tenayan Raya",
          },
        ],
      },
      {
        city_id: "351",
        type: "Kabupaten",
        city_name: "Pelalawan",
        subdistricts: [
          {
            subdistrict_id: "4926",
            subdistrict_name: "Bandar Petalangan",
          },
          {
            subdistrict_id: "4927",
            subdistrict_name: "Bandar Sei Kijang",
          },
          {
            subdistrict_id: "4928",
            subdistrict_name: "Bunut",
          },
          {
            subdistrict_id: "4929",
            subdistrict_name: "Kerumutan",
          },
          {
            subdistrict_id: "4930",
            subdistrict_name: "Kuala Kampar",
          },
          {
            subdistrict_id: "4931",
            subdistrict_name: "Langgam",
          },
          {
            subdistrict_id: "4932",
            subdistrict_name: "Pangkalan Kerinci",
          },
          {
            subdistrict_id: "4933",
            subdistrict_name: "Pangkalan Kuras",
          },
          {
            subdistrict_id: "4934",
            subdistrict_name: "Pangkalan Lesung",
          },
          {
            subdistrict_id: "4935",
            subdistrict_name: "Pelalawan",
          },
          {
            subdistrict_id: "4936",
            subdistrict_name: "Teluk Meranti",
          },
          {
            subdistrict_id: "4937",
            subdistrict_name: "Ukui",
          },
        ],
      },
      {
        city_id: "381",
        type: "Kabupaten",
        city_name: "Rokan Hilir",
        subdistricts: [
          {
            subdistrict_id: "5303",
            subdistrict_name: "Bagan Sinembah",
          },
          {
            subdistrict_id: "5304",
            subdistrict_name: "Bangko",
          },
          {
            subdistrict_id: "5305",
            subdistrict_name: "Bangko Pusaka (Pusako)",
          },
          {
            subdistrict_id: "5306",
            subdistrict_name: "Batu Hampar",
          },
          {
            subdistrict_id: "5307",
            subdistrict_name: "Kubu",
          },
          {
            subdistrict_id: "5308",
            subdistrict_name: "Kubu Babussalam",
          },
          {
            subdistrict_id: "5309",
            subdistrict_name: "Pasir Limau Kapas",
          },
          {
            subdistrict_id: "5310",
            subdistrict_name: "Pekaitan",
          },
          {
            subdistrict_id: "5311",
            subdistrict_name: "Pujud",
          },
          {
            subdistrict_id: "5312",
            subdistrict_name: "Rantau Kopar",
          },
          {
            subdistrict_id: "5313",
            subdistrict_name: "Rimba Melintang",
          },
          {
            subdistrict_id: "5314",
            subdistrict_name: "Simpang Kanan",
          },
          {
            subdistrict_id: "5315",
            subdistrict_name: "Sinaboi (Senaboi)",
          },
          {
            subdistrict_id: "5316",
            subdistrict_name: "Tanah Putih",
          },
          {
            subdistrict_id: "5317",
            subdistrict_name: "Tanah Putih Tanjung Melawan",
          },
        ],
      },
      {
        city_id: "382",
        type: "Kabupaten",
        city_name: "Rokan Hulu",
        subdistricts: [
          {
            subdistrict_id: "5318",
            subdistrict_name: "Bangun Purba",
          },
          {
            subdistrict_id: "5319",
            subdistrict_name: "Bonai Darussalam",
          },
          {
            subdistrict_id: "5320",
            subdistrict_name: "Kabun",
          },
          {
            subdistrict_id: "5321",
            subdistrict_name: "Kepenuhan",
          },
          {
            subdistrict_id: "5322",
            subdistrict_name: "Kepenuhan Hulu",
          },
          {
            subdistrict_id: "5323",
            subdistrict_name: "Kunto Darussalam",
          },
          {
            subdistrict_id: "5324",
            subdistrict_name: "Pagaran Tapah Darussalam",
          },
          {
            subdistrict_id: "5325",
            subdistrict_name: "Pendalian IV Koto",
          },
          {
            subdistrict_id: "5326",
            subdistrict_name: "Rambah",
          },
          {
            subdistrict_id: "5327",
            subdistrict_name: "Rambah Hilir",
          },
          {
            subdistrict_id: "5328",
            subdistrict_name: "Rambah Samo",
          },
          {
            subdistrict_id: "5329",
            subdistrict_name: "Rokan IV Koto",
          },
          {
            subdistrict_id: "5330",
            subdistrict_name: "Tambusai",
          },
          {
            subdistrict_id: "5331",
            subdistrict_name: "Tambusai Utara",
          },
          {
            subdistrict_id: "5332",
            subdistrict_name: "Tandun",
          },
          {
            subdistrict_id: "5333",
            subdistrict_name: "Ujung Batu",
          },
        ],
      },
      {
        city_id: "406",
        type: "Kabupaten",
        city_name: "Siak",
        subdistricts: [
          {
            subdistrict_id: "5602",
            subdistrict_name: "Bunga Raya",
          },
          {
            subdistrict_id: "5603",
            subdistrict_name: "Dayun",
          },
          {
            subdistrict_id: "5604",
            subdistrict_name: "Kandis",
          },
          {
            subdistrict_id: "5605",
            subdistrict_name: "Kerinci Kanan",
          },
          {
            subdistrict_id: "5606",
            subdistrict_name: "Koto Gasib",
          },
          {
            subdistrict_id: "5607",
            subdistrict_name: "Lubuk Dalam",
          },
          {
            subdistrict_id: "5608",
            subdistrict_name: "Mempura",
          },
          {
            subdistrict_id: "5609",
            subdistrict_name: "Minas",
          },
          {
            subdistrict_id: "5610",
            subdistrict_name: "Pusako",
          },
          {
            subdistrict_id: "5611",
            subdistrict_name: "Sabak Auh",
          },
          {
            subdistrict_id: "5612",
            subdistrict_name: "Siak",
          },
          {
            subdistrict_id: "5613",
            subdistrict_name: "Sungai Apit",
          },
          {
            subdistrict_id: "5614",
            subdistrict_name: "Sungai Mandau",
          },
          {
            subdistrict_id: "5615",
            subdistrict_name: "Tualang",
          },
        ],
      },
    ],
  },
  {
    province_id: "27",
    province: "Sulawesi Barat",
    cities: [
      {
        city_id: "253",
        type: "Kabupaten",
        city_name: "Majene",
        subdistricts: [
          {
            subdistrict_id: "3579",
            subdistrict_name: "Banggae",
          },
          {
            subdistrict_id: "3580",
            subdistrict_name: "Banggae Timur",
          },
          {
            subdistrict_id: "3581",
            subdistrict_name: "Malunda",
          },
          {
            subdistrict_id: "3582",
            subdistrict_name: "Pamboang",
          },
          {
            subdistrict_id: "3583",
            subdistrict_name: "Sendana",
          },
          {
            subdistrict_id: "3584",
            subdistrict_name: "Tammeredo Sendana",
          },
          {
            subdistrict_id: "3585",
            subdistrict_name: "Tubo (Tubo Sendana)",
          },
          {
            subdistrict_id: "3586",
            subdistrict_name: "Ulumunda",
          },
        ],
      },
      {
        city_id: "262",
        type: "Kabupaten",
        city_name: "Mamasa",
        subdistricts: [
          {
            subdistrict_id: "3710",
            subdistrict_name: "Aralle (Arrale)",
          },
          {
            subdistrict_id: "3711",
            subdistrict_name: "Balla",
          },
          {
            subdistrict_id: "3712",
            subdistrict_name: "Bambang",
          },
          {
            subdistrict_id: "3713",
            subdistrict_name: "Buntumalangka",
          },
          {
            subdistrict_id: "3714",
            subdistrict_name: "Mamasa",
          },
          {
            subdistrict_id: "3715",
            subdistrict_name: "Mambi",
          },
          {
            subdistrict_id: "3716",
            subdistrict_name: "Mehalaan",
          },
          {
            subdistrict_id: "3717",
            subdistrict_name: "Messawa",
          },
          {
            subdistrict_id: "3718",
            subdistrict_name: "Nosu",
          },
          {
            subdistrict_id: "3719",
            subdistrict_name: "Pana",
          },
          {
            subdistrict_id: "3720",
            subdistrict_name: "Rantebulahan Timur",
          },
          {
            subdistrict_id: "3721",
            subdistrict_name: "Sesena Padang",
          },
          {
            subdistrict_id: "3722",
            subdistrict_name: "Sumarorong",
          },
          {
            subdistrict_id: "3723",
            subdistrict_name: "Tabang",
          },
          {
            subdistrict_id: "3724",
            subdistrict_name: "Tabulahan",
          },
          {
            subdistrict_id: "3725",
            subdistrict_name: "Tanduk Kalua",
          },
          {
            subdistrict_id: "3726",
            subdistrict_name: "Tawalian",
          },
        ],
      },
      {
        city_id: "265",
        type: "Kabupaten",
        city_name: "Mamuju",
        subdistricts: [
          {
            subdistrict_id: "3740",
            subdistrict_name: "Bonehau",
          },
          {
            subdistrict_id: "3741",
            subdistrict_name: "Budong-Budong",
          },
          {
            subdistrict_id: "3742",
            subdistrict_name: "Kalukku",
          },
          {
            subdistrict_id: "3743",
            subdistrict_name: "Kalumpang",
          },
          {
            subdistrict_id: "3744",
            subdistrict_name: "Karossa",
          },
          {
            subdistrict_id: "3745",
            subdistrict_name: "Kep. Bala Balakang",
          },
          {
            subdistrict_id: "3746",
            subdistrict_name: "Mamuju",
          },
          {
            subdistrict_id: "3747",
            subdistrict_name: "Pangale",
          },
          {
            subdistrict_id: "3748",
            subdistrict_name: "Papalang",
          },
          {
            subdistrict_id: "3749",
            subdistrict_name: "Sampaga",
          },
          {
            subdistrict_id: "3750",
            subdistrict_name: "Simboro dan Kepulauan",
          },
          {
            subdistrict_id: "3751",
            subdistrict_name: "Tapalang",
          },
          {
            subdistrict_id: "3752",
            subdistrict_name: "Tapalang Barat",
          },
          {
            subdistrict_id: "3753",
            subdistrict_name: "Tobadak",
          },
          {
            subdistrict_id: "3754",
            subdistrict_name: "Tommo",
          },
          {
            subdistrict_id: "3755",
            subdistrict_name: "Topoyo",
          },
        ],
      },
      {
        city_id: "266",
        type: "Kabupaten",
        city_name: "Mamuju Utara",
        subdistricts: [
          {
            subdistrict_id: "3756",
            subdistrict_name: "Bambaira",
          },
          {
            subdistrict_id: "3757",
            subdistrict_name: "Bambalamotu",
          },
          {
            subdistrict_id: "3758",
            subdistrict_name: "Baras",
          },
          {
            subdistrict_id: "3759",
            subdistrict_name: "Bulu Taba",
          },
          {
            subdistrict_id: "3760",
            subdistrict_name: "Dapurang",
          },
          {
            subdistrict_id: "3761",
            subdistrict_name: "Duripoku",
          },
          {
            subdistrict_id: "3762",
            subdistrict_name: "Lariang",
          },
          {
            subdistrict_id: "3763",
            subdistrict_name: "Pasangkayu",
          },
          {
            subdistrict_id: "3764",
            subdistrict_name: "Pedongga",
          },
          {
            subdistrict_id: "3765",
            subdistrict_name: "Sarjo",
          },
          {
            subdistrict_id: "3766",
            subdistrict_name: "Sarudu",
          },
          {
            subdistrict_id: "3767",
            subdistrict_name: "Tikke Raya",
          },
        ],
      },
      {
        city_id: "362",
        type: "Kabupaten",
        city_name: "Polewali Mandar",
        subdistricts: [
          {
            subdistrict_id: "5055",
            subdistrict_name: "Alu (Allu)",
          },
          {
            subdistrict_id: "5056",
            subdistrict_name: "Anreapi",
          },
          {
            subdistrict_id: "5057",
            subdistrict_name: "Balanipa",
          },
          {
            subdistrict_id: "5058",
            subdistrict_name: "Binuang",
          },
          {
            subdistrict_id: "5059",
            subdistrict_name: "Bulo",
          },
          {
            subdistrict_id: "5060",
            subdistrict_name: "Campalagian",
          },
          {
            subdistrict_id: "5061",
            subdistrict_name: "Limboro",
          },
          {
            subdistrict_id: "5062",
            subdistrict_name: "Luyo",
          },
          {
            subdistrict_id: "5063",
            subdistrict_name: "Mapilli",
          },
          {
            subdistrict_id: "5064",
            subdistrict_name: "Matakali",
          },
          {
            subdistrict_id: "5065",
            subdistrict_name: "Matangnga",
          },
          {
            subdistrict_id: "5066",
            subdistrict_name: "Polewali",
          },
          {
            subdistrict_id: "5067",
            subdistrict_name: "Tapango",
          },
          {
            subdistrict_id: "5068",
            subdistrict_name: "Tinambung",
          },
          {
            subdistrict_id: "5069",
            subdistrict_name: "Tubbi Taramanu (Tutar/Tutallu)",
          },
          {
            subdistrict_id: "5070",
            subdistrict_name: "Wonomulyo",
          },
        ],
      },
    ],
  },
  {
    province_id: "28",
    province: "Sulawesi Selatan",
    cities: [
      {
        city_id: "38",
        type: "Kabupaten",
        city_name: "Bantaeng",
        subdistricts: [
          {
            subdistrict_id: "529",
            subdistrict_name: "Bantaeng",
          },
          {
            subdistrict_id: "530",
            subdistrict_name: "Bissappu",
          },
          {
            subdistrict_id: "531",
            subdistrict_name: "Eremerasa",
          },
          {
            subdistrict_id: "532",
            subdistrict_name: "Gantarang Keke (Gantareng Keke)",
          },
          {
            subdistrict_id: "533",
            subdistrict_name: "Pajukukang",
          },
          {
            subdistrict_id: "534",
            subdistrict_name: "Sinoa",
          },
          {
            subdistrict_id: "535",
            subdistrict_name: "Tompobulu",
          },
          {
            subdistrict_id: "536",
            subdistrict_name: "Uluere",
          },
        ],
      },
      {
        city_id: "47",
        type: "Kabupaten",
        city_name: "Barru",
        subdistricts: [
          {
            subdistrict_id: "666",
            subdistrict_name: "Balusu",
          },
          {
            subdistrict_id: "667",
            subdistrict_name: "Barru",
          },
          {
            subdistrict_id: "668",
            subdistrict_name: "Mallusetasi",
          },
          {
            subdistrict_id: "669",
            subdistrict_name: "Pujananting",
          },
          {
            subdistrict_id: "670",
            subdistrict_name: "Soppeng Riaja",
          },
          {
            subdistrict_id: "671",
            subdistrict_name: "Tanete Riaja",
          },
          {
            subdistrict_id: "672",
            subdistrict_name: "Tanete Rilau",
          },
        ],
      },
      {
        city_id: "87",
        type: "Kabupaten",
        city_name: "Bone",
        subdistricts: [
          {
            subdistrict_id: "1172",
            subdistrict_name: "Ajangale",
          },
          {
            subdistrict_id: "1173",
            subdistrict_name: "Amali",
          },
          {
            subdistrict_id: "1174",
            subdistrict_name: "Awangpone",
          },
          {
            subdistrict_id: "1175",
            subdistrict_name: "Barebbo",
          },
          {
            subdistrict_id: "1176",
            subdistrict_name: "Bengo",
          },
          {
            subdistrict_id: "1177",
            subdistrict_name: "Bontocani",
          },
          {
            subdistrict_id: "1178",
            subdistrict_name: "Cenrana",
          },
          {
            subdistrict_id: "1179",
            subdistrict_name: "Cina",
          },
          {
            subdistrict_id: "1180",
            subdistrict_name: "Dua Boccoe",
          },
          {
            subdistrict_id: "1181",
            subdistrict_name: "Kahu",
          },
          {
            subdistrict_id: "1182",
            subdistrict_name: "Kajuara",
          },
          {
            subdistrict_id: "1183",
            subdistrict_name: "Lamuru",
          },
          {
            subdistrict_id: "1184",
            subdistrict_name: "Lappariaja",
          },
          {
            subdistrict_id: "1185",
            subdistrict_name: "Libureng",
          },
          {
            subdistrict_id: "1186",
            subdistrict_name: "Mare",
          },
          {
            subdistrict_id: "1187",
            subdistrict_name: "Palakka",
          },
          {
            subdistrict_id: "1188",
            subdistrict_name: "Patimpeng",
          },
          {
            subdistrict_id: "1189",
            subdistrict_name: "Ponre",
          },
          {
            subdistrict_id: "1190",
            subdistrict_name: "Salomekko",
          },
          {
            subdistrict_id: "1191",
            subdistrict_name: "Sibulue",
          },
          {
            subdistrict_id: "1192",
            subdistrict_name: "Tanete Riattang",
          },
          {
            subdistrict_id: "1193",
            subdistrict_name: "Tanete Riattang Barat",
          },
          {
            subdistrict_id: "1194",
            subdistrict_name: "Tanete Riattang Timur",
          },
          {
            subdistrict_id: "1195",
            subdistrict_name: "Tellu Limpoe",
          },
          {
            subdistrict_id: "1196",
            subdistrict_name: "Tellu Siattinge",
          },
          {
            subdistrict_id: "1197",
            subdistrict_name: "Tonra",
          },
          {
            subdistrict_id: "1198",
            subdistrict_name: "Ulaweng",
          },
        ],
      },
      {
        city_id: "95",
        type: "Kabupaten",
        city_name: "Bulukumba",
        subdistricts: [
          {
            subdistrict_id: "1288",
            subdistrict_name: "Bonto Bahari",
          },
          {
            subdistrict_id: "1289",
            subdistrict_name: "Bontotiro",
          },
          {
            subdistrict_id: "1290",
            subdistrict_name: "Bulukumba (Bulukumpa)",
          },
          {
            subdistrict_id: "1291",
            subdistrict_name: "Gantorang/Gantarang (Gangking)",
          },
          {
            subdistrict_id: "1292",
            subdistrict_name: "Hero Lange-Lange (Herlang)",
          },
          {
            subdistrict_id: "1293",
            subdistrict_name: "Kajang",
          },
          {
            subdistrict_id: "1294",
            subdistrict_name: "Kindang",
          },
          {
            subdistrict_id: "1295",
            subdistrict_name: "Rilau Ale",
          },
          {
            subdistrict_id: "1296",
            subdistrict_name: "Ujung Bulu",
          },
          {
            subdistrict_id: "1297",
            subdistrict_name: "Ujung Loe",
          },
        ],
      },
      {
        city_id: "123",
        type: "Kabupaten",
        city_name: "Enrekang",
        subdistricts: [
          {
            subdistrict_id: "1671",
            subdistrict_name: "Alla",
          },
          {
            subdistrict_id: "1672",
            subdistrict_name: "Anggeraja",
          },
          {
            subdistrict_id: "1673",
            subdistrict_name: "Baraka",
          },
          {
            subdistrict_id: "1674",
            subdistrict_name: "Baroko",
          },
          {
            subdistrict_id: "1675",
            subdistrict_name: "Bungin",
          },
          {
            subdistrict_id: "1676",
            subdistrict_name: "Buntu Batu",
          },
          {
            subdistrict_id: "1677",
            subdistrict_name: "Cendana",
          },
          {
            subdistrict_id: "1678",
            subdistrict_name: "Curio",
          },
          {
            subdistrict_id: "1679",
            subdistrict_name: "Enrekang",
          },
          {
            subdistrict_id: "1680",
            subdistrict_name: "Maiwa",
          },
          {
            subdistrict_id: "1681",
            subdistrict_name: "Malua",
          },
          {
            subdistrict_id: "1682",
            subdistrict_name: "Masalle",
          },
        ],
      },
      {
        city_id: "132",
        type: "Kabupaten",
        city_name: "Gowa",
        subdistricts: [
          {
            subdistrict_id: "1810",
            subdistrict_name: "Bajeng",
          },
          {
            subdistrict_id: "1811",
            subdistrict_name: "Bajeng Barat",
          },
          {
            subdistrict_id: "1812",
            subdistrict_name: "Barombong",
          },
          {
            subdistrict_id: "1813",
            subdistrict_name: "Biringbulu",
          },
          {
            subdistrict_id: "1814",
            subdistrict_name: "Bontolempangang",
          },
          {
            subdistrict_id: "1815",
            subdistrict_name: "Bontomarannu",
          },
          {
            subdistrict_id: "1816",
            subdistrict_name: "Bontonompo",
          },
          {
            subdistrict_id: "1817",
            subdistrict_name: "Bontonompo Selatan",
          },
          {
            subdistrict_id: "1818",
            subdistrict_name: "Bungaya",
          },
          {
            subdistrict_id: "1819",
            subdistrict_name: "Manuju",
          },
          {
            subdistrict_id: "1820",
            subdistrict_name: "Pallangga",
          },
          {
            subdistrict_id: "1821",
            subdistrict_name: "Parangloe",
          },
          {
            subdistrict_id: "1822",
            subdistrict_name: "Parigi",
          },
          {
            subdistrict_id: "1823",
            subdistrict_name: "Pattallassang",
          },
          {
            subdistrict_id: "1824",
            subdistrict_name: "Somba Opu (Upu)",
          },
          {
            subdistrict_id: "1825",
            subdistrict_name: "Tinggimoncong",
          },
          {
            subdistrict_id: "1826",
            subdistrict_name: "Tombolo Pao",
          },
          {
            subdistrict_id: "1827",
            subdistrict_name: "Tompobulu",
          },
        ],
      },
      {
        city_id: "162",
        type: "Kabupaten",
        city_name: "Jeneponto",
        subdistricts: [
          {
            subdistrict_id: "2237",
            subdistrict_name: "Arungkeke",
          },
          {
            subdistrict_id: "2238",
            subdistrict_name: "Bangkala",
          },
          {
            subdistrict_id: "2239",
            subdistrict_name: "Bangkala Barat",
          },
          {
            subdistrict_id: "2240",
            subdistrict_name: "Batang",
          },
          {
            subdistrict_id: "2241",
            subdistrict_name: "Binamu",
          },
          {
            subdistrict_id: "2242",
            subdistrict_name: "Bontoramba",
          },
          {
            subdistrict_id: "2243",
            subdistrict_name: "Kelara",
          },
          {
            subdistrict_id: "2244",
            subdistrict_name: "Rumbia",
          },
          {
            subdistrict_id: "2245",
            subdistrict_name: "Tamalatea",
          },
          {
            subdistrict_id: "2246",
            subdistrict_name: "Tarowang",
          },
          {
            subdistrict_id: "2247",
            subdistrict_name: "Turatea",
          },
        ],
      },
      {
        city_id: "244",
        type: "Kabupaten",
        city_name: "Luwu",
        subdistricts: [
          {
            subdistrict_id: "3448",
            subdistrict_name: "Bajo",
          },
          {
            subdistrict_id: "3449",
            subdistrict_name: "Bajo Barat",
          },
          {
            subdistrict_id: "3450",
            subdistrict_name: "Basse Sangtempe Utara",
          },
          {
            subdistrict_id: "3451",
            subdistrict_name: "Bassesang Tempe (Bastem)",
          },
          {
            subdistrict_id: "3452",
            subdistrict_name: "Belopa",
          },
          {
            subdistrict_id: "3453",
            subdistrict_name: "Belopa Utara",
          },
          {
            subdistrict_id: "3454",
            subdistrict_name: "Bua",
          },
          {
            subdistrict_id: "3455",
            subdistrict_name: "Bua Ponrang (Bupon)",
          },
          {
            subdistrict_id: "3456",
            subdistrict_name: "Kamanre",
          },
          {
            subdistrict_id: "3457",
            subdistrict_name: "Lamasi",
          },
          {
            subdistrict_id: "3458",
            subdistrict_name: "Lamasi Timur",
          },
          {
            subdistrict_id: "3459",
            subdistrict_name: "Larompong",
          },
          {
            subdistrict_id: "3460",
            subdistrict_name: "Larompong Selatan",
          },
          {
            subdistrict_id: "3461",
            subdistrict_name: "Latimojong",
          },
          {
            subdistrict_id: "3462",
            subdistrict_name: "Ponrang",
          },
          {
            subdistrict_id: "3463",
            subdistrict_name: "Ponrang Selatan",
          },
          {
            subdistrict_id: "3464",
            subdistrict_name: "Suli",
          },
          {
            subdistrict_id: "3465",
            subdistrict_name: "Suli Barat",
          },
          {
            subdistrict_id: "3466",
            subdistrict_name: "Walenrang",
          },
          {
            subdistrict_id: "3467",
            subdistrict_name: "Walenrang Barat",
          },
          {
            subdistrict_id: "3468",
            subdistrict_name: "Walenrang Timur",
          },
          {
            subdistrict_id: "3469",
            subdistrict_name: "Walenrang Utara",
          },
        ],
      },
      {
        city_id: "245",
        type: "Kabupaten",
        city_name: "Luwu Timur",
        subdistricts: [
          {
            subdistrict_id: "3470",
            subdistrict_name: "Angkona",
          },
          {
            subdistrict_id: "3471",
            subdistrict_name: "Burau",
          },
          {
            subdistrict_id: "3472",
            subdistrict_name: "Kalaena",
          },
          {
            subdistrict_id: "3473",
            subdistrict_name: "Malili",
          },
          {
            subdistrict_id: "3474",
            subdistrict_name: "Mangkutana",
          },
          {
            subdistrict_id: "3475",
            subdistrict_name: "Nuha",
          },
          {
            subdistrict_id: "3476",
            subdistrict_name: "Tomoni",
          },
          {
            subdistrict_id: "3477",
            subdistrict_name: "Tomoni Timur",
          },
          {
            subdistrict_id: "3478",
            subdistrict_name: "Towuti",
          },
          {
            subdistrict_id: "3479",
            subdistrict_name: "Wasuponda",
          },
          {
            subdistrict_id: "3480",
            subdistrict_name: "Wotu",
          },
        ],
      },
      {
        city_id: "246",
        type: "Kabupaten",
        city_name: "Luwu Utara",
        subdistricts: [
          {
            subdistrict_id: "3481",
            subdistrict_name: "Baebunta",
          },
          {
            subdistrict_id: "3482",
            subdistrict_name: "Bone-Bone",
          },
          {
            subdistrict_id: "3483",
            subdistrict_name: "Limbong",
          },
          {
            subdistrict_id: "3484",
            subdistrict_name: "Malangke",
          },
          {
            subdistrict_id: "3485",
            subdistrict_name: "Malangke Barat",
          },
          {
            subdistrict_id: "3486",
            subdistrict_name: "Mappedeceng",
          },
          {
            subdistrict_id: "3487",
            subdistrict_name: "Masamba",
          },
          {
            subdistrict_id: "3488",
            subdistrict_name: "Rampi",
          },
          {
            subdistrict_id: "3489",
            subdistrict_name: "Sabbang",
          },
          {
            subdistrict_id: "3490",
            subdistrict_name: "Seko",
          },
          {
            subdistrict_id: "3491",
            subdistrict_name: "Sukamaju",
          },
          {
            subdistrict_id: "3492",
            subdistrict_name: "Tana Lili",
          },
        ],
      },
      {
        city_id: "254",
        type: "Kota",
        city_name: "Makassar",
        subdistricts: [
          {
            subdistrict_id: "3587",
            subdistrict_name: "Biring Kanaya",
          },
          {
            subdistrict_id: "3588",
            subdistrict_name: "Bontoala",
          },
          {
            subdistrict_id: "3589",
            subdistrict_name: "Makassar",
          },
          {
            subdistrict_id: "3590",
            subdistrict_name: "Mamajang",
          },
          {
            subdistrict_id: "3591",
            subdistrict_name: "Manggala",
          },
          {
            subdistrict_id: "3592",
            subdistrict_name: "Mariso",
          },
          {
            subdistrict_id: "3593",
            subdistrict_name: "Panakkukang",
          },
          {
            subdistrict_id: "3594",
            subdistrict_name: "Rappocini",
          },
          {
            subdistrict_id: "3595",
            subdistrict_name: "Tallo",
          },
          {
            subdistrict_id: "3596",
            subdistrict_name: "Tamalanrea",
          },
          {
            subdistrict_id: "3597",
            subdistrict_name: "Tamalate",
          },
          {
            subdistrict_id: "3598",
            subdistrict_name: "Ujung Pandang",
          },
          {
            subdistrict_id: "3599",
            subdistrict_name: "Ujung Tanah",
          },
          {
            subdistrict_id: "3600",
            subdistrict_name: "Wajo",
          },
        ],
      },
      {
        city_id: "275",
        type: "Kabupaten",
        city_name: "Maros",
        subdistricts: [
          {
            subdistrict_id: "3862",
            subdistrict_name: "Bantimurung",
          },
          {
            subdistrict_id: "3863",
            subdistrict_name: "Bontoa (Maros Utara)",
          },
          {
            subdistrict_id: "3864",
            subdistrict_name: "Camba",
          },
          {
            subdistrict_id: "3865",
            subdistrict_name: "Cenrana",
          },
          {
            subdistrict_id: "3866",
            subdistrict_name: "Lau",
          },
          {
            subdistrict_id: "3867",
            subdistrict_name: "Mallawa",
          },
          {
            subdistrict_id: "3868",
            subdistrict_name: "Mandai",
          },
          {
            subdistrict_id: "3869",
            subdistrict_name: "Maros Baru",
          },
          {
            subdistrict_id: "3870",
            subdistrict_name: "Marusu",
          },
          {
            subdistrict_id: "3871",
            subdistrict_name: "Moncongloe",
          },
          {
            subdistrict_id: "3872",
            subdistrict_name: "Simbang",
          },
          {
            subdistrict_id: "3873",
            subdistrict_name: "Tanralili",
          },
          {
            subdistrict_id: "3874",
            subdistrict_name: "Tompu Bulu",
          },
          {
            subdistrict_id: "3875",
            subdistrict_name: "Turikale",
          },
        ],
      },
      {
        city_id: "328",
        type: "Kota",
        city_name: "Palopo",
        subdistricts: [
          {
            subdistrict_id: "4625",
            subdistrict_name: "Bara",
          },
          {
            subdistrict_id: "4626",
            subdistrict_name: "Mungkajang",
          },
          {
            subdistrict_id: "4627",
            subdistrict_name: "Sendana",
          },
          {
            subdistrict_id: "4628",
            subdistrict_name: "Telluwanua",
          },
          {
            subdistrict_id: "4629",
            subdistrict_name: "Wara",
          },
          {
            subdistrict_id: "4630",
            subdistrict_name: "Wara Barat",
          },
          {
            subdistrict_id: "4631",
            subdistrict_name: "Wara Selatan",
          },
          {
            subdistrict_id: "4632",
            subdistrict_name: "Wara Timur",
          },
          {
            subdistrict_id: "4633",
            subdistrict_name: "Wara Utara",
          },
        ],
      },
      {
        city_id: "333",
        type: "Kabupaten",
        city_name: "Pangkajene Kepulauan",
        subdistricts: [
          {
            subdistrict_id: "4700",
            subdistrict_name: "Balocci",
          },
          {
            subdistrict_id: "4701",
            subdistrict_name: "Bungoro",
          },
          {
            subdistrict_id: "4702",
            subdistrict_name: "Labakkang",
          },
          {
            subdistrict_id: "4703",
            subdistrict_name: "Liukang Kalmas (Kalukuang Masalima)",
          },
          {
            subdistrict_id: "4704",
            subdistrict_name: "Liukang Tangaya",
          },
          {
            subdistrict_id: "4705",
            subdistrict_name: "Liukang Tupabbiring",
          },
          {
            subdistrict_id: "4706",
            subdistrict_name: "Liukang Tupabbiring Utara",
          },
          {
            subdistrict_id: "4707",
            subdistrict_name: "Mandalle",
          },
          {
            subdistrict_id: "4708",
            subdistrict_name: "Marang (Ma Rang)",
          },
          {
            subdistrict_id: "4709",
            subdistrict_name: "Minasa Tene",
          },
          {
            subdistrict_id: "4710",
            subdistrict_name: "Pangkajene",
          },
          {
            subdistrict_id: "4711",
            subdistrict_name: "Segeri",
          },
          {
            subdistrict_id: "4712",
            subdistrict_name: "Tondong Tallasa",
          },
        ],
      },
      {
        city_id: "336",
        type: "Kota",
        city_name: "Parepare",
        subdistricts: [
          {
            subdistrict_id: "4730",
            subdistrict_name: "Bacukiki",
          },
          {
            subdistrict_id: "4731",
            subdistrict_name: "Bacukiki Barat",
          },
          {
            subdistrict_id: "4732",
            subdistrict_name: "Soreang",
          },
          {
            subdistrict_id: "4733",
            subdistrict_name: "Ujung",
          },
        ],
      },
      {
        city_id: "360",
        type: "Kabupaten",
        city_name: "Pinrang",
        subdistricts: [
          {
            subdistrict_id: "5030",
            subdistrict_name: "Batulappa",
          },
          {
            subdistrict_id: "5031",
            subdistrict_name: "Cempa",
          },
          {
            subdistrict_id: "5032",
            subdistrict_name: "Duampanua",
          },
          {
            subdistrict_id: "5033",
            subdistrict_name: "Lanrisang",
          },
          {
            subdistrict_id: "5034",
            subdistrict_name: "Lembang",
          },
          {
            subdistrict_id: "5035",
            subdistrict_name: "Mattiro Bulu",
          },
          {
            subdistrict_id: "5036",
            subdistrict_name: "Mattiro Sompe",
          },
          {
            subdistrict_id: "5037",
            subdistrict_name: "Paleteang",
          },
          {
            subdistrict_id: "5038",
            subdistrict_name: "Patampanua",
          },
          {
            subdistrict_id: "5039",
            subdistrict_name: "Suppa",
          },
          {
            subdistrict_id: "5040",
            subdistrict_name: "Tiroang",
          },
          {
            subdistrict_id: "5041",
            subdistrict_name: "Watang Sawitto",
          },
        ],
      },
      {
        city_id: "396",
        type: "Kabupaten",
        city_name: "Selayar (Kepulauan Selayar)",
        subdistricts: [
          {
            subdistrict_id: "5454",
            subdistrict_name: "Benteng",
          },
          {
            subdistrict_id: "5455",
            subdistrict_name: "Bontoharu",
          },
          {
            subdistrict_id: "5456",
            subdistrict_name: "Bontomanai",
          },
          {
            subdistrict_id: "5457",
            subdistrict_name: "Bontomatene",
          },
          {
            subdistrict_id: "5458",
            subdistrict_name: "Bontosikuyu",
          },
          {
            subdistrict_id: "5459",
            subdistrict_name: "Buki",
          },
          {
            subdistrict_id: "5460",
            subdistrict_name: "Pasilambena",
          },
          {
            subdistrict_id: "5461",
            subdistrict_name: "Pasimarannu",
          },
          {
            subdistrict_id: "5462",
            subdistrict_name: "Pasimassunggu",
          },
          {
            subdistrict_id: "5463",
            subdistrict_name: "Pasimasunggu Timur",
          },
          {
            subdistrict_id: "5464",
            subdistrict_name: "Takabonerate",
          },
        ],
      },
      {
        city_id: "408",
        type: "Kabupaten",
        city_name: "Sidenreng Rappang/Rapang",
        subdistricts: [
          {
            subdistrict_id: "5620",
            subdistrict_name: "Baranti",
          },
          {
            subdistrict_id: "5621",
            subdistrict_name: "Dua Pitue",
          },
          {
            subdistrict_id: "5622",
            subdistrict_name: "Kulo",
          },
          {
            subdistrict_id: "5623",
            subdistrict_name: "Maritengngae",
          },
          {
            subdistrict_id: "5624",
            subdistrict_name: "Panca Lautan (Lautang)",
          },
          {
            subdistrict_id: "5625",
            subdistrict_name: "Panca Rijang",
          },
          {
            subdistrict_id: "5626",
            subdistrict_name: "Pitu Raise/Riase",
          },
          {
            subdistrict_id: "5627",
            subdistrict_name: "Pitu Riawa",
          },
          {
            subdistrict_id: "5628",
            subdistrict_name: "Tellu Limpoe",
          },
          {
            subdistrict_id: "5629",
            subdistrict_name: "Watang Pulu",
          },
          {
            subdistrict_id: "5630",
            subdistrict_name: "Wattang Sidenreng (Watang Sidenreng)",
          },
        ],
      },
      {
        city_id: "416",
        type: "Kabupaten",
        city_name: "Sinjai",
        subdistricts: [
          {
            subdistrict_id: "5739",
            subdistrict_name: "Bulupoddo",
          },
          {
            subdistrict_id: "5740",
            subdistrict_name: "Pulau Sembilan",
          },
          {
            subdistrict_id: "5741",
            subdistrict_name: "Sinjai Barat",
          },
          {
            subdistrict_id: "5742",
            subdistrict_name: "Sinjai Borong",
          },
          {
            subdistrict_id: "5743",
            subdistrict_name: "Sinjai Selatan",
          },
          {
            subdistrict_id: "5744",
            subdistrict_name: "Sinjai Tengah",
          },
          {
            subdistrict_id: "5745",
            subdistrict_name: "Sinjai Timur",
          },
          {
            subdistrict_id: "5746",
            subdistrict_name: "Sinjai Utara",
          },
          {
            subdistrict_id: "5747",
            subdistrict_name: "Tellu Limpoe",
          },
        ],
      },
      {
        city_id: "423",
        type: "Kabupaten",
        city_name: "Soppeng",
        subdistricts: [
          {
            subdistrict_id: "5819",
            subdistrict_name: "Citta",
          },
          {
            subdistrict_id: "5820",
            subdistrict_name: "Donri-Donri",
          },
          {
            subdistrict_id: "5821",
            subdistrict_name: "Ganra",
          },
          {
            subdistrict_id: "5822",
            subdistrict_name: "Lalabata",
          },
          {
            subdistrict_id: "5823",
            subdistrict_name: "Lili Rilau",
          },
          {
            subdistrict_id: "5824",
            subdistrict_name: "Liliraja (Lili Riaja)",
          },
          {
            subdistrict_id: "5825",
            subdistrict_name: "Mario Riawa",
          },
          {
            subdistrict_id: "5826",
            subdistrict_name: "Mario Riwawo",
          },
        ],
      },
      {
        city_id: "448",
        type: "Kabupaten",
        city_name: "Takalar",
        subdistricts: [
          {
            subdistrict_id: "6189",
            subdistrict_name: "Galesong",
          },
          {
            subdistrict_id: "6190",
            subdistrict_name: "Galesong Selatan",
          },
          {
            subdistrict_id: "6191",
            subdistrict_name: "Galesong Utara",
          },
          {
            subdistrict_id: "6192",
            subdistrict_name: "Mangara Bombang",
          },
          {
            subdistrict_id: "6193",
            subdistrict_name: "Mappakasunggu",
          },
          {
            subdistrict_id: "6194",
            subdistrict_name: "Patallassang",
          },
          {
            subdistrict_id: "6195",
            subdistrict_name: "Polombangkeng Selatan (Polobangkeng)",
          },
          {
            subdistrict_id: "6196",
            subdistrict_name: "Polombangkeng Utara (Polobangkeng)",
          },
          {
            subdistrict_id: "6197",
            subdistrict_name: "Sanrobone",
          },
        ],
      },
      {
        city_id: "451",
        type: "Kabupaten",
        city_name: "Tana Toraja",
        subdistricts: [
          {
            subdistrict_id: "6214",
            subdistrict_name: "Bittuang",
          },
          {
            subdistrict_id: "6215",
            subdistrict_name: "Bonggakaradeng",
          },
          {
            subdistrict_id: "6216",
            subdistrict_name: "Gandang Batu Sillanan",
          },
          {
            subdistrict_id: "6217",
            subdistrict_name: "Kurra",
          },
          {
            subdistrict_id: "6218",
            subdistrict_name: "Makale",
          },
          {
            subdistrict_id: "6219",
            subdistrict_name: "Makale Selatan",
          },
          {
            subdistrict_id: "6220",
            subdistrict_name: "Makale Utara",
          },
          {
            subdistrict_id: "6221",
            subdistrict_name: "Malimbong Balepe",
          },
          {
            subdistrict_id: "6222",
            subdistrict_name: "Mappak",
          },
          {
            subdistrict_id: "6223",
            subdistrict_name: "Masanda",
          },
          {
            subdistrict_id: "6224",
            subdistrict_name: "Mengkendek",
          },
          {
            subdistrict_id: "6225",
            subdistrict_name: "Rano",
          },
          {
            subdistrict_id: "6226",
            subdistrict_name: "Rantetayo",
          },
          {
            subdistrict_id: "6227",
            subdistrict_name: "Rembon",
          },
          {
            subdistrict_id: "6228",
            subdistrict_name: "Saluputti",
          },
          {
            subdistrict_id: "6229",
            subdistrict_name: "Sangalla (Sanggala)",
          },
          {
            subdistrict_id: "6230",
            subdistrict_name: "Sangalla Selatan",
          },
          {
            subdistrict_id: "6231",
            subdistrict_name: "Sangalla Utara",
          },
          {
            subdistrict_id: "6232",
            subdistrict_name: "Simbuang",
          },
        ],
      },
      {
        city_id: "486",
        type: "Kabupaten",
        city_name: "Toraja Utara",
        subdistricts: [
          {
            subdistrict_id: "6738",
            subdistrict_name: "Awan Rante Karua",
          },
          {
            subdistrict_id: "6739",
            subdistrict_name: "Balusu",
          },
          {
            subdistrict_id: "6740",
            subdistrict_name: "Bangkelekila",
          },
          {
            subdistrict_id: "6741",
            subdistrict_name: "Baruppu",
          },
          {
            subdistrict_id: "6742",
            subdistrict_name: "Buntao",
          },
          {
            subdistrict_id: "6743",
            subdistrict_name: "Buntu Pepasan",
          },
          {
            subdistrict_id: "6744",
            subdistrict_name: "Dende' Piongan Napo",
          },
          {
            subdistrict_id: "6745",
            subdistrict_name: "Kapalla Pitu (Kapala Pitu)",
          },
          {
            subdistrict_id: "6746",
            subdistrict_name: "Kesu",
          },
          {
            subdistrict_id: "6747",
            subdistrict_name: "Nanggala",
          },
          {
            subdistrict_id: "6748",
            subdistrict_name: "Rantebua",
          },
          {
            subdistrict_id: "6749",
            subdistrict_name: "Rantepao",
          },
          {
            subdistrict_id: "6750",
            subdistrict_name: "Rindingallo",
          },
          {
            subdistrict_id: "6751",
            subdistrict_name: "Sa'dan",
          },
          {
            subdistrict_id: "6752",
            subdistrict_name: "Sanggalangi",
          },
          {
            subdistrict_id: "6753",
            subdistrict_name: "Sesean",
          },
          {
            subdistrict_id: "6754",
            subdistrict_name: "Sesean Suloara",
          },
          {
            subdistrict_id: "6755",
            subdistrict_name: "Sopai",
          },
          {
            subdistrict_id: "6756",
            subdistrict_name: "Tallunglipu",
          },
          {
            subdistrict_id: "6757",
            subdistrict_name: "Tikala",
          },
          {
            subdistrict_id: "6758",
            subdistrict_name: "Tondon",
          },
        ],
      },
      {
        city_id: "493",
        type: "Kabupaten",
        city_name: "Wajo",
        subdistricts: [
          {
            subdistrict_id: "6840",
            subdistrict_name: "Belawa",
          },
          {
            subdistrict_id: "6841",
            subdistrict_name: "Bola",
          },
          {
            subdistrict_id: "6842",
            subdistrict_name: "Gilireng",
          },
          {
            subdistrict_id: "6843",
            subdistrict_name: "Keera",
          },
          {
            subdistrict_id: "6844",
            subdistrict_name: "Majauleng",
          },
          {
            subdistrict_id: "6845",
            subdistrict_name: "Maniang Pajo",
          },
          {
            subdistrict_id: "6846",
            subdistrict_name: "Pammana",
          },
          {
            subdistrict_id: "6847",
            subdistrict_name: "Penrang",
          },
          {
            subdistrict_id: "6848",
            subdistrict_name: "Pitumpanua",
          },
          {
            subdistrict_id: "6849",
            subdistrict_name: "Sabbang Paru",
          },
          {
            subdistrict_id: "6850",
            subdistrict_name: "Sajoanging",
          },
          {
            subdistrict_id: "6851",
            subdistrict_name: "Takkalalla",
          },
          {
            subdistrict_id: "6852",
            subdistrict_name: "Tana Sitolo",
          },
          {
            subdistrict_id: "6853",
            subdistrict_name: "Tempe",
          },
        ],
      },
    ],
  },
  {
    province_id: "29",
    province: "Sulawesi Tengah",
    cities: [
      {
        city_id: "25",
        type: "Kabupaten",
        city_name: "Banggai",
        subdistricts: [
          {
            subdistrict_id: "384",
            subdistrict_name: "Balantak",
          },
          {
            subdistrict_id: "385",
            subdistrict_name: "Balantak Selatan",
          },
          {
            subdistrict_id: "386",
            subdistrict_name: "Balantak Utara",
          },
          {
            subdistrict_id: "387",
            subdistrict_name: "Batui",
          },
          {
            subdistrict_id: "388",
            subdistrict_name: "Batui Selatan",
          },
          {
            subdistrict_id: "389",
            subdistrict_name: "Bualemo (Boalemo)",
          },
          {
            subdistrict_id: "390",
            subdistrict_name: "Bunta",
          },
          {
            subdistrict_id: "391",
            subdistrict_name: "Kintom",
          },
          {
            subdistrict_id: "392",
            subdistrict_name: "Lamala",
          },
          {
            subdistrict_id: "393",
            subdistrict_name: "Lobu",
          },
          {
            subdistrict_id: "394",
            subdistrict_name: "Luwuk",
          },
          {
            subdistrict_id: "395",
            subdistrict_name: "Luwuk Selatan",
          },
          {
            subdistrict_id: "396",
            subdistrict_name: "Luwuk Timur",
          },
          {
            subdistrict_id: "397",
            subdistrict_name: "Luwuk Utara",
          },
          {
            subdistrict_id: "398",
            subdistrict_name: "Mantoh",
          },
          {
            subdistrict_id: "399",
            subdistrict_name: "Masama",
          },
          {
            subdistrict_id: "400",
            subdistrict_name: "Moilong",
          },
          {
            subdistrict_id: "401",
            subdistrict_name: "Nambo",
          },
          {
            subdistrict_id: "402",
            subdistrict_name: "Nuhon",
          },
          {
            subdistrict_id: "403",
            subdistrict_name: "Pagimana",
          },
          {
            subdistrict_id: "404",
            subdistrict_name: "Simpang Raya",
          },
          {
            subdistrict_id: "405",
            subdistrict_name: "Toili",
          },
          {
            subdistrict_id: "406",
            subdistrict_name: "Toili Barat",
          },
        ],
      },
      {
        city_id: "26",
        type: "Kabupaten",
        city_name: "Banggai Kepulauan",
        subdistricts: [
          {
            subdistrict_id: "407",
            subdistrict_name: "Banggai",
          },
          {
            subdistrict_id: "408",
            subdistrict_name: "Banggai Selatan",
          },
          {
            subdistrict_id: "409",
            subdistrict_name: "Banggai Tengah",
          },
          {
            subdistrict_id: "410",
            subdistrict_name: "Banggai Utara",
          },
          {
            subdistrict_id: "411",
            subdistrict_name: "Bangkurung",
          },
          {
            subdistrict_id: "412",
            subdistrict_name: "Bokan Kepulauan",
          },
          {
            subdistrict_id: "413",
            subdistrict_name: "Buko",
          },
          {
            subdistrict_id: "414",
            subdistrict_name: "Buko Selatan",
          },
          {
            subdistrict_id: "415",
            subdistrict_name: "Bulagi",
          },
          {
            subdistrict_id: "416",
            subdistrict_name: "Bulagi Selatan",
          },
          {
            subdistrict_id: "417",
            subdistrict_name: "Bulagi Utara",
          },
          {
            subdistrict_id: "418",
            subdistrict_name: "Labobo (Lobangkurung)",
          },
          {
            subdistrict_id: "419",
            subdistrict_name: "Liang",
          },
          {
            subdistrict_id: "420",
            subdistrict_name: "Peling Tengah",
          },
          {
            subdistrict_id: "421",
            subdistrict_name: "Tinangkung",
          },
          {
            subdistrict_id: "422",
            subdistrict_name: "Tinangkung Selatan",
          },
          {
            subdistrict_id: "423",
            subdistrict_name: "Tinangkung Utara",
          },
          {
            subdistrict_id: "424",
            subdistrict_name: "Totikum (Totikung)",
          },
          {
            subdistrict_id: "425",
            subdistrict_name: "Totikum Selatan",
          },
        ],
      },
      {
        city_id: "98",
        type: "Kabupaten",
        city_name: "Buol",
        subdistricts: [
          {
            subdistrict_id: "1325",
            subdistrict_name: "Biau",
          },
          {
            subdistrict_id: "1326",
            subdistrict_name: "Bokat",
          },
          {
            subdistrict_id: "1327",
            subdistrict_name: "Bukal",
          },
          {
            subdistrict_id: "1328",
            subdistrict_name: "Bunobogu",
          },
          {
            subdistrict_id: "1329",
            subdistrict_name: "Gadung",
          },
          {
            subdistrict_id: "1330",
            subdistrict_name: "Karamat",
          },
          {
            subdistrict_id: "1331",
            subdistrict_name: "Lakea (Lipunoto)",
          },
          {
            subdistrict_id: "1332",
            subdistrict_name: "Momunu",
          },
          {
            subdistrict_id: "1333",
            subdistrict_name: "Paleleh",
          },
          {
            subdistrict_id: "1334",
            subdistrict_name: "Paleleh Barat",
          },
          {
            subdistrict_id: "1335",
            subdistrict_name: "Tiloan",
          },
        ],
      },
      {
        city_id: "119",
        type: "Kabupaten",
        city_name: "Donggala",
        subdistricts: [
          {
            subdistrict_id: "1617",
            subdistrict_name: "Balaesang",
          },
          {
            subdistrict_id: "1618",
            subdistrict_name: "Balaesang Tanjung",
          },
          {
            subdistrict_id: "1619",
            subdistrict_name: "Banawa",
          },
          {
            subdistrict_id: "1620",
            subdistrict_name: "Banawa Selatan",
          },
          {
            subdistrict_id: "1621",
            subdistrict_name: "Banawa Tengah",
          },
          {
            subdistrict_id: "1622",
            subdistrict_name: "Damsol (Dampelas Sojol)",
          },
          {
            subdistrict_id: "1623",
            subdistrict_name: "Labuan",
          },
          {
            subdistrict_id: "1624",
            subdistrict_name: "Pinembani",
          },
          {
            subdistrict_id: "1625",
            subdistrict_name: "Rio Pakava (Riopakawa)",
          },
          {
            subdistrict_id: "1626",
            subdistrict_name: "Sindue",
          },
          {
            subdistrict_id: "1627",
            subdistrict_name: "Sindue Tobata",
          },
          {
            subdistrict_id: "1628",
            subdistrict_name: "Sindue Tombusabora",
          },
          {
            subdistrict_id: "1629",
            subdistrict_name: "Sirenja",
          },
          {
            subdistrict_id: "1630",
            subdistrict_name: "Sojol",
          },
          {
            subdistrict_id: "1631",
            subdistrict_name: "Sojol Utara",
          },
          {
            subdistrict_id: "1632",
            subdistrict_name: "Tanantovea",
          },
        ],
      },
      {
        city_id: "291",
        type: "Kabupaten",
        city_name: "Morowali",
        subdistricts: [
          {
            subdistrict_id: "4090",
            subdistrict_name: "Bahodopi",
          },
          {
            subdistrict_id: "4091",
            subdistrict_name: "Bumi Raya",
          },
          {
            subdistrict_id: "4092",
            subdistrict_name: "Bungku Barat",
          },
          {
            subdistrict_id: "4093",
            subdistrict_name: "Bungku Pesisir",
          },
          {
            subdistrict_id: "4094",
            subdistrict_name: "Bungku Selatan",
          },
          {
            subdistrict_id: "4095",
            subdistrict_name: "Bungku Tengah",
          },
          {
            subdistrict_id: "4096",
            subdistrict_name: "Bungku Timur",
          },
          {
            subdistrict_id: "4097",
            subdistrict_name: "Bungku Utara",
          },
          {
            subdistrict_id: "4098",
            subdistrict_name: "Lembo",
          },
          {
            subdistrict_id: "4099",
            subdistrict_name: "Lembo Raya",
          },
          {
            subdistrict_id: "4100",
            subdistrict_name: "Mamosalato",
          },
          {
            subdistrict_id: "4101",
            subdistrict_name: "Menui Kepulauan",
          },
          {
            subdistrict_id: "4102",
            subdistrict_name: "Mori Atas",
          },
          {
            subdistrict_id: "4103",
            subdistrict_name: "Mori Utara",
          },
          {
            subdistrict_id: "4104",
            subdistrict_name: "Petasia",
          },
          {
            subdistrict_id: "4105",
            subdistrict_name: "Petasia Barat",
          },
          {
            subdistrict_id: "4106",
            subdistrict_name: "Petasia Timur",
          },
          {
            subdistrict_id: "4107",
            subdistrict_name: "Soyo Jaya",
          },
          {
            subdistrict_id: "4108",
            subdistrict_name: "Wita Ponda",
          },
        ],
      },
      {
        city_id: "329",
        type: "Kota",
        city_name: "Palu",
        subdistricts: [
          {
            subdistrict_id: "4634",
            subdistrict_name: "Mantikulore",
          },
          {
            subdistrict_id: "4635",
            subdistrict_name: "Palu Barat",
          },
          {
            subdistrict_id: "4636",
            subdistrict_name: "Palu Selatan",
          },
          {
            subdistrict_id: "4637",
            subdistrict_name: "Palu Timur",
          },
          {
            subdistrict_id: "4638",
            subdistrict_name: "Palu Utara",
          },
          {
            subdistrict_id: "4639",
            subdistrict_name: "Tatanga",
          },
          {
            subdistrict_id: "4640",
            subdistrict_name: "Tawaeli",
          },
          {
            subdistrict_id: "4641",
            subdistrict_name: "Ulujadi",
          },
        ],
      },
      {
        city_id: "338",
        type: "Kabupaten",
        city_name: "Parigi Moutong",
        subdistricts: [
          {
            subdistrict_id: "4738",
            subdistrict_name: "Ampibabo",
          },
          {
            subdistrict_id: "4739",
            subdistrict_name: "Balinggi",
          },
          {
            subdistrict_id: "4740",
            subdistrict_name: "Bolano",
          },
          {
            subdistrict_id: "4741",
            subdistrict_name: "Bolano Lambunu/Lambulu",
          },
          {
            subdistrict_id: "4742",
            subdistrict_name: "Kasimbar",
          },
          {
            subdistrict_id: "4743",
            subdistrict_name: "Mepanga",
          },
          {
            subdistrict_id: "4744",
            subdistrict_name: "Moutong",
          },
          {
            subdistrict_id: "4745",
            subdistrict_name: "Ongka Malino",
          },
          {
            subdistrict_id: "4746",
            subdistrict_name: "Palasa",
          },
          {
            subdistrict_id: "4747",
            subdistrict_name: "Parigi",
          },
          {
            subdistrict_id: "4748",
            subdistrict_name: "Parigi Barat",
          },
          {
            subdistrict_id: "4749",
            subdistrict_name: "Parigi Selatan",
          },
          {
            subdistrict_id: "4750",
            subdistrict_name: "Parigi Tengah",
          },
          {
            subdistrict_id: "4751",
            subdistrict_name: "Parigi Utara",
          },
          {
            subdistrict_id: "4752",
            subdistrict_name: "Sausu",
          },
          {
            subdistrict_id: "4753",
            subdistrict_name: "Siniu",
          },
          {
            subdistrict_id: "4754",
            subdistrict_name: "Taopa",
          },
          {
            subdistrict_id: "4755",
            subdistrict_name: "Tinombo",
          },
          {
            subdistrict_id: "4756",
            subdistrict_name: "Tinombo Selatan",
          },
          {
            subdistrict_id: "4757",
            subdistrict_name: "Tomini",
          },
          {
            subdistrict_id: "4758",
            subdistrict_name: "Toribulu",
          },
          {
            subdistrict_id: "4759",
            subdistrict_name: "Torue",
          },
        ],
      },
      {
        city_id: "366",
        type: "Kabupaten",
        city_name: "Poso",
        subdistricts: [
          {
            subdistrict_id: "5107",
            subdistrict_name: "Lage",
          },
          {
            subdistrict_id: "5108",
            subdistrict_name: "Lore Barat",
          },
          {
            subdistrict_id: "5109",
            subdistrict_name: "Lore Piore",
          },
          {
            subdistrict_id: "5110",
            subdistrict_name: "Lore Selatan",
          },
          {
            subdistrict_id: "5111",
            subdistrict_name: "Lore Tengah",
          },
          {
            subdistrict_id: "5112",
            subdistrict_name: "Lore Timur",
          },
          {
            subdistrict_id: "5113",
            subdistrict_name: "Lore Utara",
          },
          {
            subdistrict_id: "5114",
            subdistrict_name: "Pamona Barat",
          },
          {
            subdistrict_id: "5115",
            subdistrict_name: "Pamona Puselemba",
          },
          {
            subdistrict_id: "5116",
            subdistrict_name: "Pamona Selatan",
          },
          {
            subdistrict_id: "5117",
            subdistrict_name: "Pamona Tenggara",
          },
          {
            subdistrict_id: "5118",
            subdistrict_name: "Pamona Timur",
          },
          {
            subdistrict_id: "5119",
            subdistrict_name: "Pamona Utara",
          },
          {
            subdistrict_id: "5120",
            subdistrict_name: "Poso Kota",
          },
          {
            subdistrict_id: "5121",
            subdistrict_name: "Poso Kota Selatan",
          },
          {
            subdistrict_id: "5122",
            subdistrict_name: "Poso Kota Utara",
          },
          {
            subdistrict_id: "5123",
            subdistrict_name: "Poso Pesisir",
          },
          {
            subdistrict_id: "5124",
            subdistrict_name: "Poso Pesisir Selatan",
          },
          {
            subdistrict_id: "5125",
            subdistrict_name: "Poso Pesisir Utara",
          },
        ],
      },
      {
        city_id: "410",
        type: "Kabupaten",
        city_name: "Sigi",
        subdistricts: [
          {
            subdistrict_id: "5649",
            subdistrict_name: "Dolo",
          },
          {
            subdistrict_id: "5650",
            subdistrict_name: "Dolo Barat",
          },
          {
            subdistrict_id: "5651",
            subdistrict_name: "Dolo Selatan",
          },
          {
            subdistrict_id: "5652",
            subdistrict_name: "Gumbasa",
          },
          {
            subdistrict_id: "5653",
            subdistrict_name: "Kinovaru",
          },
          {
            subdistrict_id: "5654",
            subdistrict_name: "Kulawi",
          },
          {
            subdistrict_id: "5655",
            subdistrict_name: "Kulawi Selatan",
          },
          {
            subdistrict_id: "5656",
            subdistrict_name: "Lindu",
          },
          {
            subdistrict_id: "5657",
            subdistrict_name: "Marawola",
          },
          {
            subdistrict_id: "5658",
            subdistrict_name: "Marawola Barat",
          },
          {
            subdistrict_id: "5659",
            subdistrict_name: "Nokilalaki",
          },
          {
            subdistrict_id: "5660",
            subdistrict_name: "Palolo",
          },
          {
            subdistrict_id: "5661",
            subdistrict_name: "Pipikoro",
          },
          {
            subdistrict_id: "5662",
            subdistrict_name: "Sigi Biromaru",
          },
          {
            subdistrict_id: "5663",
            subdistrict_name: "Tanambulava",
          },
        ],
      },
      {
        city_id: "482",
        type: "Kabupaten",
        city_name: "Tojo Una-Una",
        subdistricts: [
          {
            subdistrict_id: "6668",
            subdistrict_name: "Ampana Kota",
          },
          {
            subdistrict_id: "6669",
            subdistrict_name: "Ampana Tete",
          },
          {
            subdistrict_id: "6670",
            subdistrict_name: "Togean",
          },
          {
            subdistrict_id: "6671",
            subdistrict_name: "Tojo",
          },
          {
            subdistrict_id: "6672",
            subdistrict_name: "Tojo Barat",
          },
          {
            subdistrict_id: "6673",
            subdistrict_name: "Ulu Bongka",
          },
          {
            subdistrict_id: "6674",
            subdistrict_name: "Una - Una",
          },
          {
            subdistrict_id: "6675",
            subdistrict_name: "Walea Besar",
          },
          {
            subdistrict_id: "6676",
            subdistrict_name: "Walea Kepulauan",
          },
        ],
      },
      {
        city_id: "483",
        type: "Kabupaten",
        city_name: "Toli-Toli",
        subdistricts: [
          {
            subdistrict_id: "6677",
            subdistrict_name: "Baolan",
          },
          {
            subdistrict_id: "6678",
            subdistrict_name: "Basidondo",
          },
          {
            subdistrict_id: "6679",
            subdistrict_name: "Dako Pamean",
          },
          {
            subdistrict_id: "6680",
            subdistrict_name: "Dampal Selatan",
          },
          {
            subdistrict_id: "6681",
            subdistrict_name: "Dampal Utara",
          },
          {
            subdistrict_id: "6682",
            subdistrict_name: "Dondo",
          },
          {
            subdistrict_id: "6683",
            subdistrict_name: "Galang",
          },
          {
            subdistrict_id: "6684",
            subdistrict_name: "Lampasio",
          },
          {
            subdistrict_id: "6685",
            subdistrict_name: "Ogo Deide",
          },
          {
            subdistrict_id: "6686",
            subdistrict_name: "Tolitoli Utara",
          },
        ],
      },
    ],
  },
  {
    province_id: "30",
    province: "Sulawesi Tenggara",
    cities: [
      {
        city_id: "53",
        type: "Kota",
        city_name: "Bau-Bau",
        subdistricts: [
          {
            subdistrict_id: "718",
            subdistrict_name: "Batupoaro",
          },
          {
            subdistrict_id: "719",
            subdistrict_name: "Betoambari",
          },
          {
            subdistrict_id: "720",
            subdistrict_name: "Bungi",
          },
          {
            subdistrict_id: "721",
            subdistrict_name: "Kokalukuna",
          },
          {
            subdistrict_id: "722",
            subdistrict_name: "Lea-Lea",
          },
          {
            subdistrict_id: "723",
            subdistrict_name: "Murhum",
          },
          {
            subdistrict_id: "724",
            subdistrict_name: "Sora Walio (Sorowalio)",
          },
          {
            subdistrict_id: "725",
            subdistrict_name: "Wolio",
          },
        ],
      },
      {
        city_id: "85",
        type: "Kabupaten",
        city_name: "Bombana",
        subdistricts: [
          {
            subdistrict_id: "1127",
            subdistrict_name: "Kabaena",
          },
          {
            subdistrict_id: "1128",
            subdistrict_name: "Kabaena Barat",
          },
          {
            subdistrict_id: "1129",
            subdistrict_name: "Kabaena Selatan",
          },
          {
            subdistrict_id: "1130",
            subdistrict_name: "Kabaena Tengah",
          },
          {
            subdistrict_id: "1131",
            subdistrict_name: "Kabaena Timur",
          },
          {
            subdistrict_id: "1132",
            subdistrict_name: "Kabaena Utara",
          },
          {
            subdistrict_id: "1133",
            subdistrict_name: "Kepulauan Masaloka Raya",
          },
          {
            subdistrict_id: "1134",
            subdistrict_name: "Lentarai Jaya S. (Lantari Jaya)",
          },
          {
            subdistrict_id: "1135",
            subdistrict_name: "Mata Oleo",
          },
          {
            subdistrict_id: "1136",
            subdistrict_name: "Mata Usu",
          },
          {
            subdistrict_id: "1137",
            subdistrict_name: "Poleang",
          },
          {
            subdistrict_id: "1138",
            subdistrict_name: "Poleang Barat",
          },
          {
            subdistrict_id: "1139",
            subdistrict_name: "Poleang Selatan",
          },
          {
            subdistrict_id: "1140",
            subdistrict_name: "Poleang Tengah",
          },
          {
            subdistrict_id: "1141",
            subdistrict_name: "Poleang Tenggara",
          },
          {
            subdistrict_id: "1142",
            subdistrict_name: "Poleang Timur",
          },
          {
            subdistrict_id: "1143",
            subdistrict_name: "Poleang Utara",
          },
          {
            subdistrict_id: "1144",
            subdistrict_name: "Rarowatu",
          },
          {
            subdistrict_id: "1145",
            subdistrict_name: "Rarowatu Utara",
          },
          {
            subdistrict_id: "1146",
            subdistrict_name: "Rumbia",
          },
          {
            subdistrict_id: "1147",
            subdistrict_name: "Rumbia Tengah",
          },
          {
            subdistrict_id: "1148",
            subdistrict_name: "Tontonunu (Tontonuwu)",
          },
        ],
      },
      {
        city_id: "101",
        type: "Kabupaten",
        city_name: "Buton",
        subdistricts: [
          {
            subdistrict_id: "1352",
            subdistrict_name: "Batauga",
          },
          {
            subdistrict_id: "1353",
            subdistrict_name: "Batu Atas",
          },
          {
            subdistrict_id: "1354",
            subdistrict_name: "Gu",
          },
          {
            subdistrict_id: "1355",
            subdistrict_name: "Kadatua",
          },
          {
            subdistrict_id: "1356",
            subdistrict_name: "Kapontori",
          },
          {
            subdistrict_id: "1357",
            subdistrict_name: "Lakudo",
          },
          {
            subdistrict_id: "1358",
            subdistrict_name: "Lapandewa",
          },
          {
            subdistrict_id: "1359",
            subdistrict_name: "Lasalimu",
          },
          {
            subdistrict_id: "1360",
            subdistrict_name: "Lasalimu Selatan",
          },
          {
            subdistrict_id: "1361",
            subdistrict_name: "Mawasangka",
          },
          {
            subdistrict_id: "1362",
            subdistrict_name: "Mawasangka Tengah",
          },
          {
            subdistrict_id: "1363",
            subdistrict_name: "Mawasangka Timur",
          },
          {
            subdistrict_id: "1364",
            subdistrict_name: "Pasar Wajo",
          },
          {
            subdistrict_id: "1365",
            subdistrict_name: "Sampolawa",
          },
          {
            subdistrict_id: "1366",
            subdistrict_name: "Sangia Mambulu",
          },
          {
            subdistrict_id: "1367",
            subdistrict_name: "Siompu",
          },
          {
            subdistrict_id: "1368",
            subdistrict_name: "Siompu Barat",
          },
          {
            subdistrict_id: "1369",
            subdistrict_name: "Siontapia (Siontapina)",
          },
          {
            subdistrict_id: "1370",
            subdistrict_name: "Talaga Raya (Telaga Raya)",
          },
          {
            subdistrict_id: "1371",
            subdistrict_name: "Wabula",
          },
          {
            subdistrict_id: "1372",
            subdistrict_name: "Wolowa",
          },
        ],
      },
      {
        city_id: "102",
        type: "Kabupaten",
        city_name: "Buton Utara",
        subdistricts: [
          {
            subdistrict_id: "1373",
            subdistrict_name: "Bonegunu",
          },
          {
            subdistrict_id: "1374",
            subdistrict_name: "Kambowa",
          },
          {
            subdistrict_id: "1375",
            subdistrict_name: "Kulisusu (Kalingsusu/Kalisusu)",
          },
          {
            subdistrict_id: "1376",
            subdistrict_name: "Kulisusu Barat",
          },
          {
            subdistrict_id: "1377",
            subdistrict_name: "Kulisusu Utara",
          },
          {
            subdistrict_id: "1378",
            subdistrict_name: "Wakorumba Utara",
          },
        ],
      },
      {
        city_id: "182",
        type: "Kota",
        city_name: "Kendari",
        subdistricts: [
          {
            subdistrict_id: "2553",
            subdistrict_name: "Abeli",
          },
          {
            subdistrict_id: "2554",
            subdistrict_name: "Baruga",
          },
          {
            subdistrict_id: "2555",
            subdistrict_name: "Kadia",
          },
          {
            subdistrict_id: "2556",
            subdistrict_name: "Kambu",
          },
          {
            subdistrict_id: "2557",
            subdistrict_name: "Kendari",
          },
          {
            subdistrict_id: "2558",
            subdistrict_name: "Kendari Barat",
          },
          {
            subdistrict_id: "2559",
            subdistrict_name: "Mandonga",
          },
          {
            subdistrict_id: "2560",
            subdistrict_name: "Poasia",
          },
          {
            subdistrict_id: "2561",
            subdistrict_name: "Puuwatu",
          },
          {
            subdistrict_id: "2562",
            subdistrict_name: "Wua-Wua",
          },
        ],
      },
      {
        city_id: "198",
        type: "Kabupaten",
        city_name: "Kolaka",
        subdistricts: [
          {
            subdistrict_id: "2752",
            subdistrict_name: "Baula",
          },
          {
            subdistrict_id: "2753",
            subdistrict_name: "Kolaka",
          },
          {
            subdistrict_id: "2754",
            subdistrict_name: "Ladongi",
          },
          {
            subdistrict_id: "2755",
            subdistrict_name: "Lalolae",
          },
          {
            subdistrict_id: "2756",
            subdistrict_name: "Lambandia (Lambadia)",
          },
          {
            subdistrict_id: "2757",
            subdistrict_name: "Latambaga",
          },
          {
            subdistrict_id: "2758",
            subdistrict_name: "Loea",
          },
          {
            subdistrict_id: "2759",
            subdistrict_name: "Mowewe",
          },
          {
            subdistrict_id: "2760",
            subdistrict_name: "Poli Polia",
          },
          {
            subdistrict_id: "2761",
            subdistrict_name: "Polinggona",
          },
          {
            subdistrict_id: "2762",
            subdistrict_name: "Pomalaa",
          },
          {
            subdistrict_id: "2763",
            subdistrict_name: "Samaturu",
          },
          {
            subdistrict_id: "2764",
            subdistrict_name: "Tanggetada",
          },
          {
            subdistrict_id: "2765",
            subdistrict_name: "Tinondo",
          },
          {
            subdistrict_id: "2766",
            subdistrict_name: "Tirawuta",
          },
          {
            subdistrict_id: "2767",
            subdistrict_name: "Toari",
          },
          {
            subdistrict_id: "2768",
            subdistrict_name: "Uluiwoi",
          },
          {
            subdistrict_id: "2769",
            subdistrict_name: "Watumbangga (Watubanggo)",
          },
          {
            subdistrict_id: "2770",
            subdistrict_name: "Wolo",
          },
          {
            subdistrict_id: "2771",
            subdistrict_name: "Wundulako",
          },
        ],
      },
      {
        city_id: "199",
        type: "Kabupaten",
        city_name: "Kolaka Utara",
        subdistricts: [
          {
            subdistrict_id: "2772",
            subdistrict_name: "Batu Putih",
          },
          {
            subdistrict_id: "2773",
            subdistrict_name: "Katoi",
          },
          {
            subdistrict_id: "2774",
            subdistrict_name: "Kodeoha",
          },
          {
            subdistrict_id: "2775",
            subdistrict_name: "Lasusua",
          },
          {
            subdistrict_id: "2776",
            subdistrict_name: "Lombai (Lambai)",
          },
          {
            subdistrict_id: "2777",
            subdistrict_name: "Ngapa",
          },
          {
            subdistrict_id: "2778",
            subdistrict_name: "Pakue",
          },
          {
            subdistrict_id: "2779",
            subdistrict_name: "Pakue Tengah",
          },
          {
            subdistrict_id: "2780",
            subdistrict_name: "Pakue Utara",
          },
          {
            subdistrict_id: "2781",
            subdistrict_name: "Porehu",
          },
          {
            subdistrict_id: "2782",
            subdistrict_name: "Ranteangin",
          },
          {
            subdistrict_id: "2783",
            subdistrict_name: "Tiwu",
          },
          {
            subdistrict_id: "2784",
            subdistrict_name: "Tolala",
          },
          {
            subdistrict_id: "2785",
            subdistrict_name: "Watunohu",
          },
          {
            subdistrict_id: "2786",
            subdistrict_name: "Wawo",
          },
        ],
      },
      {
        city_id: "200",
        type: "Kabupaten",
        city_name: "Konawe",
        subdistricts: [
          {
            subdistrict_id: "2787",
            subdistrict_name: "Abuki",
          },
          {
            subdistrict_id: "2788",
            subdistrict_name: "Amonggedo",
          },
          {
            subdistrict_id: "2789",
            subdistrict_name: "Anggaberi",
          },
          {
            subdistrict_id: "2790",
            subdistrict_name: "Asinua",
          },
          {
            subdistrict_id: "2791",
            subdistrict_name: "Besulutu",
          },
          {
            subdistrict_id: "2792",
            subdistrict_name: "Bondoala",
          },
          {
            subdistrict_id: "2793",
            subdistrict_name: "Kapoiala (Kapoyala)",
          },
          {
            subdistrict_id: "2794",
            subdistrict_name: "Konawe",
          },
          {
            subdistrict_id: "2795",
            subdistrict_name: "Lalonggasumeeto",
          },
          {
            subdistrict_id: "2796",
            subdistrict_name: "Lambuya",
          },
          {
            subdistrict_id: "2797",
            subdistrict_name: "Latoma",
          },
          {
            subdistrict_id: "2798",
            subdistrict_name: "Meluhu",
          },
          {
            subdistrict_id: "2799",
            subdistrict_name: "Onembute",
          },
          {
            subdistrict_id: "2800",
            subdistrict_name: "Pondidaha",
          },
          {
            subdistrict_id: "2801",
            subdistrict_name: "Puriala",
          },
          {
            subdistrict_id: "2802",
            subdistrict_name: "Routa",
          },
          {
            subdistrict_id: "2803",
            subdistrict_name: "Sampara",
          },
          {
            subdistrict_id: "2804",
            subdistrict_name: "Soropia",
          },
          {
            subdistrict_id: "2805",
            subdistrict_name: "Tongauna",
          },
          {
            subdistrict_id: "2806",
            subdistrict_name: "Uepai (Uwepai)",
          },
          {
            subdistrict_id: "2807",
            subdistrict_name: "Unaaha",
          },
          {
            subdistrict_id: "2808",
            subdistrict_name: "Wawonii Barat",
          },
          {
            subdistrict_id: "2809",
            subdistrict_name: "Wawonii Selatan",
          },
          {
            subdistrict_id: "2810",
            subdistrict_name: "Wawonii Tengah",
          },
          {
            subdistrict_id: "2811",
            subdistrict_name: "Wawonii Tenggara",
          },
          {
            subdistrict_id: "2812",
            subdistrict_name: "Wawonii Timur",
          },
          {
            subdistrict_id: "2813",
            subdistrict_name: "Wawonii Timur Laut",
          },
          {
            subdistrict_id: "2814",
            subdistrict_name: "Wawonii Utara",
          },
          {
            subdistrict_id: "2815",
            subdistrict_name: "Wawotobi",
          },
          {
            subdistrict_id: "2816",
            subdistrict_name: "Wonggeduku",
          },
        ],
      },
      {
        city_id: "201",
        type: "Kabupaten",
        city_name: "Konawe Selatan",
        subdistricts: [
          {
            subdistrict_id: "2817",
            subdistrict_name: "Andoolo",
          },
          {
            subdistrict_id: "2818",
            subdistrict_name: "Angata",
          },
          {
            subdistrict_id: "2819",
            subdistrict_name: "Baito",
          },
          {
            subdistrict_id: "2820",
            subdistrict_name: "Basala",
          },
          {
            subdistrict_id: "2821",
            subdistrict_name: "Benua",
          },
          {
            subdistrict_id: "2822",
            subdistrict_name: "Buke",
          },
          {
            subdistrict_id: "2823",
            subdistrict_name: "Kolono",
          },
          {
            subdistrict_id: "2824",
            subdistrict_name: "Konda",
          },
          {
            subdistrict_id: "2825",
            subdistrict_name: "Laeya",
          },
          {
            subdistrict_id: "2826",
            subdistrict_name: "Lainea",
          },
          {
            subdistrict_id: "2827",
            subdistrict_name: "Lalembuu / Lalumbuu",
          },
          {
            subdistrict_id: "2828",
            subdistrict_name: "Landono",
          },
          {
            subdistrict_id: "2829",
            subdistrict_name: "Laonti",
          },
          {
            subdistrict_id: "2830",
            subdistrict_name: "Moramo",
          },
          {
            subdistrict_id: "2831",
            subdistrict_name: "Moramo Utara",
          },
          {
            subdistrict_id: "2832",
            subdistrict_name: "Mowila",
          },
          {
            subdistrict_id: "2833",
            subdistrict_name: "Palangga",
          },
          {
            subdistrict_id: "2834",
            subdistrict_name: "Palangga Selatan",
          },
          {
            subdistrict_id: "2835",
            subdistrict_name: "Ranomeeto",
          },
          {
            subdistrict_id: "2836",
            subdistrict_name: "Ranomeeto Barat",
          },
          {
            subdistrict_id: "2837",
            subdistrict_name: "Tinanggea",
          },
          {
            subdistrict_id: "2838",
            subdistrict_name: "Wolasi",
          },
        ],
      },
      {
        city_id: "202",
        type: "Kabupaten",
        city_name: "Konawe Utara",
        subdistricts: [
          {
            subdistrict_id: "2839",
            subdistrict_name: "Andowia",
          },
          {
            subdistrict_id: "2840",
            subdistrict_name: "Asera",
          },
          {
            subdistrict_id: "2841",
            subdistrict_name: "Langgikima",
          },
          {
            subdistrict_id: "2842",
            subdistrict_name: "Lasolo",
          },
          {
            subdistrict_id: "2843",
            subdistrict_name: "Lembo",
          },
          {
            subdistrict_id: "2844",
            subdistrict_name: "Molawe",
          },
          {
            subdistrict_id: "2845",
            subdistrict_name: "Motui",
          },
          {
            subdistrict_id: "2846",
            subdistrict_name: "Oheo",
          },
          {
            subdistrict_id: "2847",
            subdistrict_name: "Sawa",
          },
          {
            subdistrict_id: "2848",
            subdistrict_name: "Wiwirano",
          },
        ],
      },
      {
        city_id: "295",
        type: "Kabupaten",
        city_name: "Muna",
        subdistricts: [
          {
            subdistrict_id: "4157",
            subdistrict_name: "Barangka",
          },
          {
            subdistrict_id: "4158",
            subdistrict_name: "Batalaiwaru (Batalaiworu)",
          },
          {
            subdistrict_id: "4159",
            subdistrict_name: "Batukara",
          },
          {
            subdistrict_id: "4160",
            subdistrict_name: "Bone (Bone Tondo)",
          },
          {
            subdistrict_id: "4161",
            subdistrict_name: "Duruka",
          },
          {
            subdistrict_id: "4162",
            subdistrict_name: "Kabangka",
          },
          {
            subdistrict_id: "4163",
            subdistrict_name: "Kabawo",
          },
          {
            subdistrict_id: "4164",
            subdistrict_name: "Katobu",
          },
          {
            subdistrict_id: "4165",
            subdistrict_name: "Kontu Kowuna",
          },
          {
            subdistrict_id: "4166",
            subdistrict_name: "Kontunaga",
          },
          {
            subdistrict_id: "4167",
            subdistrict_name: "Kusambi",
          },
          {
            subdistrict_id: "4168",
            subdistrict_name: "Lasalepa",
          },
          {
            subdistrict_id: "4169",
            subdistrict_name: "Lawa",
          },
          {
            subdistrict_id: "4170",
            subdistrict_name: "Lohia",
          },
          {
            subdistrict_id: "4171",
            subdistrict_name: "Maginti",
          },
          {
            subdistrict_id: "4172",
            subdistrict_name: "Maligano",
          },
          {
            subdistrict_id: "4173",
            subdistrict_name: "Marobo",
          },
          {
            subdistrict_id: "4174",
            subdistrict_name: "Napabalano",
          },
          {
            subdistrict_id: "4175",
            subdistrict_name: "Napano Kusambi",
          },
          {
            subdistrict_id: "4176",
            subdistrict_name: "Parigi",
          },
          {
            subdistrict_id: "4177",
            subdistrict_name: "Pasi Kolaga",
          },
          {
            subdistrict_id: "4178",
            subdistrict_name: "Pasir Putih",
          },
          {
            subdistrict_id: "4179",
            subdistrict_name: "Sawerigadi (Sawerigading/Sewergadi)",
          },
          {
            subdistrict_id: "4180",
            subdistrict_name: "Tiworo Kepulauan",
          },
          {
            subdistrict_id: "4181",
            subdistrict_name: "Tiworo Selatan",
          },
          {
            subdistrict_id: "4182",
            subdistrict_name: "Tiworo Tengah",
          },
          {
            subdistrict_id: "4183",
            subdistrict_name: "Tiworo Utara",
          },
          {
            subdistrict_id: "4184",
            subdistrict_name: "Tongkuno",
          },
          {
            subdistrict_id: "4185",
            subdistrict_name: "Tongkuno Selatan",
          },
          {
            subdistrict_id: "4186",
            subdistrict_name: "Towea",
          },
          {
            subdistrict_id: "4187",
            subdistrict_name: "Wa Daga",
          },
          {
            subdistrict_id: "4188",
            subdistrict_name: "Wakorumba Selatan",
          },
          {
            subdistrict_id: "4189",
            subdistrict_name: "Watopute",
          },
        ],
      },
      {
        city_id: "494",
        type: "Kabupaten",
        city_name: "Wakatobi",
        subdistricts: [
          {
            subdistrict_id: "6854",
            subdistrict_name: "Binongko",
          },
          {
            subdistrict_id: "6855",
            subdistrict_name: "Kaledupa",
          },
          {
            subdistrict_id: "6856",
            subdistrict_name: "Kaledupa Selatan",
          },
          {
            subdistrict_id: "6857",
            subdistrict_name: "Togo Binongko",
          },
          {
            subdistrict_id: "6858",
            subdistrict_name: "Tomia",
          },
          {
            subdistrict_id: "6859",
            subdistrict_name: "Tomia Timur",
          },
          {
            subdistrict_id: "6860",
            subdistrict_name: "Wangi-Wangi",
          },
          {
            subdistrict_id: "6861",
            subdistrict_name: "Wangi-Wangi Selatan",
          },
        ],
      },
    ],
  },
  {
    province_id: "31",
    province: "Sulawesi Utara",
    cities: [
      {
        city_id: "73",
        type: "Kota",
        city_name: "Bitung",
        subdistricts: [
          {
            subdistrict_id: "966",
            subdistrict_name: "Aertembaga (Bitung Timur)",
          },
          {
            subdistrict_id: "967",
            subdistrict_name: "Girian",
          },
          {
            subdistrict_id: "968",
            subdistrict_name: "Lembeh Selatan (Bitung Selatan)",
          },
          {
            subdistrict_id: "969",
            subdistrict_name: "Lembeh Utara",
          },
          {
            subdistrict_id: "970",
            subdistrict_name: "Madidir (Bitung Tengah)",
          },
          {
            subdistrict_id: "971",
            subdistrict_name: "Maesa",
          },
          {
            subdistrict_id: "972",
            subdistrict_name: "Matuari (Bitung Barat)",
          },
          {
            subdistrict_id: "973",
            subdistrict_name: "Ranowulu (Bitung Utara)",
          },
        ],
      },
      {
        city_id: "81",
        type: "Kabupaten",
        city_name: "Bolaang Mongondow (Bolmong)",
        subdistricts: [
          {
            subdistrict_id: "1096",
            subdistrict_name: "Bilalang",
          },
          {
            subdistrict_id: "1097",
            subdistrict_name: "Bolaang",
          },
          {
            subdistrict_id: "1098",
            subdistrict_name: "Bolaang Timur",
          },
          {
            subdistrict_id: "1099",
            subdistrict_name: "Dumoga",
          },
          {
            subdistrict_id: "1100",
            subdistrict_name: "Dumoga Barat",
          },
          {
            subdistrict_id: "1101",
            subdistrict_name: "Dumoga Tengah",
          },
          {
            subdistrict_id: "1102",
            subdistrict_name: "Dumoga Tenggara",
          },
          {
            subdistrict_id: "1103",
            subdistrict_name: "Dumoga Timur",
          },
          {
            subdistrict_id: "1104",
            subdistrict_name: "Dumoga Utara",
          },
          {
            subdistrict_id: "1105",
            subdistrict_name: "Lolak",
          },
          {
            subdistrict_id: "1106",
            subdistrict_name: "Lolayan",
          },
          {
            subdistrict_id: "1107",
            subdistrict_name: "Passi Barat",
          },
          {
            subdistrict_id: "1108",
            subdistrict_name: "Passi Timur",
          },
          {
            subdistrict_id: "1109",
            subdistrict_name: "Poigar",
          },
          {
            subdistrict_id: "1110",
            subdistrict_name: "Sangtombolang",
          },
        ],
      },
      {
        city_id: "82",
        type: "Kabupaten",
        city_name: "Bolaang Mongondow Selatan",
        subdistricts: [
          {
            subdistrict_id: "1111",
            subdistrict_name: "Bolaang Uki",
          },
          {
            subdistrict_id: "1112",
            subdistrict_name: "Pinolosian",
          },
          {
            subdistrict_id: "1113",
            subdistrict_name: "Pinolosian Tengah",
          },
          {
            subdistrict_id: "1114",
            subdistrict_name: "Pinolosian Timur",
          },
          {
            subdistrict_id: "1115",
            subdistrict_name: "Posigadan",
          },
        ],
      },
      {
        city_id: "83",
        type: "Kabupaten",
        city_name: "Bolaang Mongondow Timur",
        subdistricts: [
          {
            subdistrict_id: "1116",
            subdistrict_name: "Kotabunan",
          },
          {
            subdistrict_id: "1117",
            subdistrict_name: "Modayag",
          },
          {
            subdistrict_id: "1118",
            subdistrict_name: "Modayag Barat",
          },
          {
            subdistrict_id: "1119",
            subdistrict_name: "Nuangan",
          },
          {
            subdistrict_id: "1120",
            subdistrict_name: "Tutuyan",
          },
        ],
      },
      {
        city_id: "84",
        type: "Kabupaten",
        city_name: "Bolaang Mongondow Utara",
        subdistricts: [
          {
            subdistrict_id: "1121",
            subdistrict_name: "Bintauna",
          },
          {
            subdistrict_id: "1122",
            subdistrict_name: "Bolang Itang Barat",
          },
          {
            subdistrict_id: "1123",
            subdistrict_name: "Bolang Itang Timur",
          },
          {
            subdistrict_id: "1124",
            subdistrict_name: "Kaidipang",
          },
          {
            subdistrict_id: "1125",
            subdistrict_name: "Pinogaluman",
          },
          {
            subdistrict_id: "1126",
            subdistrict_name: "Sangkub",
          },
        ],
      },
      {
        city_id: "188",
        type: "Kabupaten",
        city_name: "Kepulauan Sangihe",
        subdistricts: [
          {
            subdistrict_id: "2607",
            subdistrict_name: "Kendahe",
          },
          {
            subdistrict_id: "2608",
            subdistrict_name: "Kepulauan Marore",
          },
          {
            subdistrict_id: "2609",
            subdistrict_name: "Manganitu",
          },
          {
            subdistrict_id: "2610",
            subdistrict_name: "Manganitu Selatan",
          },
          {
            subdistrict_id: "2611",
            subdistrict_name: "Nusa Tabukan",
          },
          {
            subdistrict_id: "2612",
            subdistrict_name: "Tabukan Selatan",
          },
          {
            subdistrict_id: "2613",
            subdistrict_name: "Tabukan Selatan Tengah",
          },
          {
            subdistrict_id: "2614",
            subdistrict_name: "Tabukan Selatan Tenggara",
          },
          {
            subdistrict_id: "2615",
            subdistrict_name: "Tabukan Tengah",
          },
          {
            subdistrict_id: "2616",
            subdistrict_name: "Tabukan Utara",
          },
          {
            subdistrict_id: "2617",
            subdistrict_name: "Tahuna",
          },
          {
            subdistrict_id: "2618",
            subdistrict_name: "Tahuna Barat",
          },
          {
            subdistrict_id: "2619",
            subdistrict_name: "Tahuna Timur",
          },
          {
            subdistrict_id: "2620",
            subdistrict_name: "Tamako",
          },
          {
            subdistrict_id: "2621",
            subdistrict_name: "Tatoareng",
          },
        ],
      },
      {
        city_id: "190",
        type: "Kabupaten",
        city_name: "Kepulauan Siau Tagulandang Biaro (Sitaro)",
        subdistricts: [
          {
            subdistrict_id: "2624",
            subdistrict_name: "Biaro",
          },
          {
            subdistrict_id: "2625",
            subdistrict_name: "Siau Barat",
          },
          {
            subdistrict_id: "2626",
            subdistrict_name: "Siau Barat Selatan",
          },
          {
            subdistrict_id: "2627",
            subdistrict_name: "Siau Barat Utara",
          },
          {
            subdistrict_id: "2628",
            subdistrict_name: "Siau Tengah",
          },
          {
            subdistrict_id: "2629",
            subdistrict_name: "Siau Timur",
          },
          {
            subdistrict_id: "2630",
            subdistrict_name: "Siau Timur Selatan",
          },
          {
            subdistrict_id: "2631",
            subdistrict_name: "Tagulandang",
          },
          {
            subdistrict_id: "2632",
            subdistrict_name: "Tagulandang Selatan",
          },
          {
            subdistrict_id: "2633",
            subdistrict_name: "Tagulandang Utara",
          },
        ],
      },
      {
        city_id: "192",
        type: "Kabupaten",
        city_name: "Kepulauan Talaud",
        subdistricts: [
          {
            subdistrict_id: "2653",
            subdistrict_name: "Beo",
          },
          {
            subdistrict_id: "2654",
            subdistrict_name: "Beo Selatan",
          },
          {
            subdistrict_id: "2655",
            subdistrict_name: "Beo Utara",
          },
          {
            subdistrict_id: "2656",
            subdistrict_name: "Damao (Damau)",
          },
          {
            subdistrict_id: "2657",
            subdistrict_name: "Essang",
          },
          {
            subdistrict_id: "2658",
            subdistrict_name: "Essang Selatan",
          },
          {
            subdistrict_id: "2659",
            subdistrict_name: "Gemeh",
          },
          {
            subdistrict_id: "2660",
            subdistrict_name: "Kabaruan",
          },
          {
            subdistrict_id: "2661",
            subdistrict_name: "Kalongan",
          },
          {
            subdistrict_id: "2662",
            subdistrict_name: "Lirung",
          },
          {
            subdistrict_id: "2663",
            subdistrict_name: "Melonguane",
          },
          {
            subdistrict_id: "2664",
            subdistrict_name: "Melonguane Timur",
          },
          {
            subdistrict_id: "2665",
            subdistrict_name: "Miangas",
          },
          {
            subdistrict_id: "2666",
            subdistrict_name: "Moronge",
          },
          {
            subdistrict_id: "2667",
            subdistrict_name: "Nanusa",
          },
          {
            subdistrict_id: "2668",
            subdistrict_name: "Pulutan",
          },
          {
            subdistrict_id: "2669",
            subdistrict_name: "Rainis",
          },
          {
            subdistrict_id: "2670",
            subdistrict_name: "Salibabu",
          },
          {
            subdistrict_id: "2671",
            subdistrict_name: "Tampan Amma",
          },
        ],
      },
      {
        city_id: "204",
        type: "Kota",
        city_name: "Kotamobagu",
        subdistricts: [
          {
            subdistrict_id: "2870",
            subdistrict_name: "Kotamobagu Barat",
          },
          {
            subdistrict_id: "2871",
            subdistrict_name: "Kotamobagu Selatan",
          },
          {
            subdistrict_id: "2872",
            subdistrict_name: "Kotamobagu Timur",
          },
          {
            subdistrict_id: "2873",
            subdistrict_name: "Kotamobagu Utara",
          },
        ],
      },
      {
        city_id: "267",
        type: "Kota",
        city_name: "Manado",
        subdistricts: [
          {
            subdistrict_id: "3768",
            subdistrict_name: "Bunaken",
          },
          {
            subdistrict_id: "3769",
            subdistrict_name: "Bunaken Kepulauan",
          },
          {
            subdistrict_id: "3770",
            subdistrict_name: "Malalayang",
          },
          {
            subdistrict_id: "3771",
            subdistrict_name: "Mapanget",
          },
          {
            subdistrict_id: "3772",
            subdistrict_name: "Paal Dua",
          },
          {
            subdistrict_id: "3773",
            subdistrict_name: "Sario",
          },
          {
            subdistrict_id: "3774",
            subdistrict_name: "Singkil",
          },
          {
            subdistrict_id: "3775",
            subdistrict_name: "Tikala",
          },
          {
            subdistrict_id: "3776",
            subdistrict_name: "Tuminiting",
          },
          {
            subdistrict_id: "3777",
            subdistrict_name: "Wanea",
          },
          {
            subdistrict_id: "3778",
            subdistrict_name: "Wenang",
          },
        ],
      },
      {
        city_id: "285",
        type: "Kabupaten",
        city_name: "Minahasa",
        subdistricts: [
          {
            subdistrict_id: "4006",
            subdistrict_name: "Eris",
          },
          {
            subdistrict_id: "4007",
            subdistrict_name: "Kakas",
          },
          {
            subdistrict_id: "4008",
            subdistrict_name: "Kakas Barat",
          },
          {
            subdistrict_id: "4009",
            subdistrict_name: "Kawangkoan",
          },
          {
            subdistrict_id: "4010",
            subdistrict_name: "Kawangkoan Barat",
          },
          {
            subdistrict_id: "4011",
            subdistrict_name: "Kawangkoan Utara",
          },
          {
            subdistrict_id: "4012",
            subdistrict_name: "Kombi",
          },
          {
            subdistrict_id: "4013",
            subdistrict_name: "Langowan Barat",
          },
          {
            subdistrict_id: "4014",
            subdistrict_name: "Langowan Selatan",
          },
          {
            subdistrict_id: "4015",
            subdistrict_name: "Langowan Timur",
          },
          {
            subdistrict_id: "4016",
            subdistrict_name: "Langowan Utara",
          },
          {
            subdistrict_id: "4017",
            subdistrict_name: "Lembean Timur",
          },
          {
            subdistrict_id: "4018",
            subdistrict_name: "Mandolang",
          },
          {
            subdistrict_id: "4019",
            subdistrict_name: "Pineleng",
          },
          {
            subdistrict_id: "4020",
            subdistrict_name: "Remboken",
          },
          {
            subdistrict_id: "4021",
            subdistrict_name: "Sonder",
          },
          {
            subdistrict_id: "4022",
            subdistrict_name: "Tombariri",
          },
          {
            subdistrict_id: "4023",
            subdistrict_name: "Tombariri Timur",
          },
          {
            subdistrict_id: "4024",
            subdistrict_name: "Tombulu",
          },
          {
            subdistrict_id: "4025",
            subdistrict_name: "Tompaso",
          },
          {
            subdistrict_id: "4026",
            subdistrict_name: "Tompaso Barat",
          },
          {
            subdistrict_id: "4027",
            subdistrict_name: "Tondano Barat",
          },
          {
            subdistrict_id: "4028",
            subdistrict_name: "Tondano Selatan",
          },
          {
            subdistrict_id: "4029",
            subdistrict_name: "Tondano Timur",
          },
          {
            subdistrict_id: "4030",
            subdistrict_name: "Tondano Utara",
          },
        ],
      },
      {
        city_id: "286",
        type: "Kabupaten",
        city_name: "Minahasa Selatan",
        subdistricts: [
          {
            subdistrict_id: "4031",
            subdistrict_name: "Amurang",
          },
          {
            subdistrict_id: "4032",
            subdistrict_name: "Amurang Barat",
          },
          {
            subdistrict_id: "4033",
            subdistrict_name: "Amurang Timur",
          },
          {
            subdistrict_id: "4034",
            subdistrict_name: "Kumelembuai",
          },
          {
            subdistrict_id: "4035",
            subdistrict_name: "Maesaan",
          },
          {
            subdistrict_id: "4036",
            subdistrict_name: "Modoinding",
          },
          {
            subdistrict_id: "4037",
            subdistrict_name: "Motoling",
          },
          {
            subdistrict_id: "4038",
            subdistrict_name: "Motoling Barat",
          },
          {
            subdistrict_id: "4039",
            subdistrict_name: "Motoling Timur",
          },
          {
            subdistrict_id: "4040",
            subdistrict_name: "Ranoyapo",
          },
          {
            subdistrict_id: "4041",
            subdistrict_name: "Sinonsayang",
          },
          {
            subdistrict_id: "4042",
            subdistrict_name: "Suluun Tareran",
          },
          {
            subdistrict_id: "4043",
            subdistrict_name: "Tareran",
          },
          {
            subdistrict_id: "4044",
            subdistrict_name: "Tatapaan",
          },
          {
            subdistrict_id: "4045",
            subdistrict_name: "Tenga",
          },
          {
            subdistrict_id: "4046",
            subdistrict_name: "Tompaso Baru",
          },
          {
            subdistrict_id: "4047",
            subdistrict_name: "Tumpaan",
          },
        ],
      },
      {
        city_id: "287",
        type: "Kabupaten",
        city_name: "Minahasa Tenggara",
        subdistricts: [
          {
            subdistrict_id: "4048",
            subdistrict_name: "Belang",
          },
          {
            subdistrict_id: "4049",
            subdistrict_name: "Pasan",
          },
          {
            subdistrict_id: "4050",
            subdistrict_name: "Pusomaen",
          },
          {
            subdistrict_id: "4051",
            subdistrict_name: "Ratahan",
          },
          {
            subdistrict_id: "4052",
            subdistrict_name: "Ratahan Timur",
          },
          {
            subdistrict_id: "4053",
            subdistrict_name: "Ratatotok",
          },
          {
            subdistrict_id: "4054",
            subdistrict_name: "Silian Raya",
          },
          {
            subdistrict_id: "4055",
            subdistrict_name: "Tombatu",
          },
          {
            subdistrict_id: "4056",
            subdistrict_name: "Tombatu Timur",
          },
          {
            subdistrict_id: "4057",
            subdistrict_name: "Tombatu Utara",
          },
          {
            subdistrict_id: "4058",
            subdistrict_name: "Touluaan",
          },
          {
            subdistrict_id: "4059",
            subdistrict_name: "Touluaan Selatan",
          },
        ],
      },
      {
        city_id: "288",
        type: "Kabupaten",
        city_name: "Minahasa Utara",
        subdistricts: [
          {
            subdistrict_id: "4060",
            subdistrict_name: "Airmadidi",
          },
          {
            subdistrict_id: "4061",
            subdistrict_name: "Dimembe",
          },
          {
            subdistrict_id: "4062",
            subdistrict_name: "Kalawat",
          },
          {
            subdistrict_id: "4063",
            subdistrict_name: "Kauditan",
          },
          {
            subdistrict_id: "4064",
            subdistrict_name: "Kema",
          },
          {
            subdistrict_id: "4065",
            subdistrict_name: "Likupang Barat",
          },
          {
            subdistrict_id: "4066",
            subdistrict_name: "Likupang Selatan",
          },
          {
            subdistrict_id: "4067",
            subdistrict_name: "Likupang Timur",
          },
          {
            subdistrict_id: "4068",
            subdistrict_name: "Talawaan",
          },
          {
            subdistrict_id: "4069",
            subdistrict_name: "Wori",
          },
        ],
      },
      {
        city_id: "485",
        type: "Kota",
        city_name: "Tomohon",
        subdistricts: [
          {
            subdistrict_id: "6733",
            subdistrict_name: "Tomohon Barat",
          },
          {
            subdistrict_id: "6734",
            subdistrict_name: "Tomohon Selatan",
          },
          {
            subdistrict_id: "6735",
            subdistrict_name: "Tomohon Tengah",
          },
          {
            subdistrict_id: "6736",
            subdistrict_name: "Tomohon Timur",
          },
          {
            subdistrict_id: "6737",
            subdistrict_name: "Tomohon Utara",
          },
        ],
      },
    ],
  },
  {
    province_id: "32",
    province: "Sumatera Barat",
    cities: [
      {
        city_id: "12",
        type: "Kabupaten",
        city_name: "Agam",
        subdistricts: [
          {
            subdistrict_id: "176",
            subdistrict_name: "Ampek Nagari (IV Nagari )",
          },
          {
            subdistrict_id: "177",
            subdistrict_name: "Banuhampu",
          },
          {
            subdistrict_id: "178",
            subdistrict_name: "Baso",
          },
          {
            subdistrict_id: "179",
            subdistrict_name: "Candung",
          },
          {
            subdistrict_id: "180",
            subdistrict_name: "IV Angkat Candung (Ampek Angkek)",
          },
          {
            subdistrict_id: "181",
            subdistrict_name: "IV Koto (Ampek Koto)",
          },
          {
            subdistrict_id: "182",
            subdistrict_name: "Kamang Magek",
          },
          {
            subdistrict_id: "183",
            subdistrict_name: "Lubuk Basung",
          },
          {
            subdistrict_id: "184",
            subdistrict_name: "Malakak",
          },
          {
            subdistrict_id: "185",
            subdistrict_name: "Matur",
          },
          {
            subdistrict_id: "186",
            subdistrict_name: "Palembayan",
          },
          {
            subdistrict_id: "187",
            subdistrict_name: "Palupuh",
          },
          {
            subdistrict_id: "188",
            subdistrict_name: "Sungai Pua (Puar)",
          },
          {
            subdistrict_id: "189",
            subdistrict_name: "Tanjung Mutiara",
          },
          {
            subdistrict_id: "190",
            subdistrict_name: "Tanjung Raya",
          },
          {
            subdistrict_id: "191",
            subdistrict_name: "Tilatang Kamang",
          },
        ],
      },
      {
        city_id: "93",
        type: "Kota",
        city_name: "Bukittinggi",
        subdistricts: [
          {
            subdistrict_id: "1276",
            subdistrict_name: "Aur Birugo Tigo Baleh",
          },
          {
            subdistrict_id: "1277",
            subdistrict_name: "Guguk Panjang (Guguak Panjang)",
          },
          {
            subdistrict_id: "1278",
            subdistrict_name: "Mandiangin Koto Selayan",
          },
        ],
      },
      {
        city_id: "116",
        type: "Kabupaten",
        city_name: "Dharmasraya",
        subdistricts: [
          {
            subdistrict_id: "1588",
            subdistrict_name: "Asam Jujuhan",
          },
          {
            subdistrict_id: "1589",
            subdistrict_name: "Koto Baru",
          },
          {
            subdistrict_id: "1590",
            subdistrict_name: "Koto Besar",
          },
          {
            subdistrict_id: "1591",
            subdistrict_name: "Koto Salak",
          },
          {
            subdistrict_id: "1592",
            subdistrict_name: "Padang Laweh",
          },
          {
            subdistrict_id: "1593",
            subdistrict_name: "Pulau Punjung",
          },
          {
            subdistrict_id: "1594",
            subdistrict_name: "Sembilan Koto (IX Koto)",
          },
          {
            subdistrict_id: "1595",
            subdistrict_name: "Sitiung",
          },
          {
            subdistrict_id: "1596",
            subdistrict_name: "Sungai Rumbai",
          },
          {
            subdistrict_id: "1597",
            subdistrict_name: "Timpeh",
          },
          {
            subdistrict_id: "1598",
            subdistrict_name: "Tiumang",
          },
        ],
      },
      {
        city_id: "186",
        type: "Kabupaten",
        city_name: "Kepulauan Mentawai",
        subdistricts: [
          {
            subdistrict_id: "2588",
            subdistrict_name: "Pagai Selatan",
          },
          {
            subdistrict_id: "2589",
            subdistrict_name: "Pagai Utara",
          },
          {
            subdistrict_id: "2590",
            subdistrict_name: "Siberut Barat",
          },
          {
            subdistrict_id: "2591",
            subdistrict_name: "Siberut Barat Daya",
          },
          {
            subdistrict_id: "2592",
            subdistrict_name: "Siberut Selatan",
          },
          {
            subdistrict_id: "2593",
            subdistrict_name: "Siberut Tengah",
          },
          {
            subdistrict_id: "2594",
            subdistrict_name: "Siberut Utara",
          },
          {
            subdistrict_id: "2595",
            subdistrict_name: "Sikakap",
          },
          {
            subdistrict_id: "2596",
            subdistrict_name: "Sipora Selatan",
          },
          {
            subdistrict_id: "2597",
            subdistrict_name: "Sipora Utara",
          },
        ],
      },
      {
        city_id: "236",
        type: "Kabupaten",
        city_name: "Lima Puluh Koto/Kota",
        subdistricts: [
          {
            subdistrict_id: "3351",
            subdistrict_name: "Akabiluru",
          },
          {
            subdistrict_id: "3352",
            subdistrict_name: "Bukik Barisan",
          },
          {
            subdistrict_id: "3353",
            subdistrict_name: "Guguak (Gugu)",
          },
          {
            subdistrict_id: "3354",
            subdistrict_name: "Gunuang Omeh (Gunung Mas)",
          },
          {
            subdistrict_id: "3355",
            subdistrict_name: "Harau",
          },
          {
            subdistrict_id: "3356",
            subdistrict_name: "Kapur IX/Sembilan",
          },
          {
            subdistrict_id: "3357",
            subdistrict_name: "Lareh Sago Halaban",
          },
          {
            subdistrict_id: "3358",
            subdistrict_name: "Luak (Luhak)",
          },
          {
            subdistrict_id: "3359",
            subdistrict_name: "Mungka",
          },
          {
            subdistrict_id: "3360",
            subdistrict_name: "Pangkalan Koto Baru",
          },
          {
            subdistrict_id: "3361",
            subdistrict_name: "Payakumbuh",
          },
          {
            subdistrict_id: "3362",
            subdistrict_name: "Situjuah Limo/Lima Nagari",
          },
          {
            subdistrict_id: "3363",
            subdistrict_name: "Suliki",
          },
        ],
      },
      {
        city_id: "318",
        type: "Kota",
        city_name: "Padang",
        subdistricts: [
          {
            subdistrict_id: "4534",
            subdistrict_name: "Bungus Teluk Kabung",
          },
          {
            subdistrict_id: "4535",
            subdistrict_name: "Koto Tangah",
          },
          {
            subdistrict_id: "4536",
            subdistrict_name: "Kuranji",
          },
          {
            subdistrict_id: "4537",
            subdistrict_name: "Lubuk Begalung",
          },
          {
            subdistrict_id: "4538",
            subdistrict_name: "Lubuk Kilangan",
          },
          {
            subdistrict_id: "4539",
            subdistrict_name: "Nanggalo",
          },
          {
            subdistrict_id: "4540",
            subdistrict_name: "Padang Barat",
          },
          {
            subdistrict_id: "4541",
            subdistrict_name: "Padang Selatan",
          },
          {
            subdistrict_id: "4542",
            subdistrict_name: "Padang Timur",
          },
          {
            subdistrict_id: "4543",
            subdistrict_name: "Padang Utara",
          },
          {
            subdistrict_id: "4544",
            subdistrict_name: "Pauh",
          },
        ],
      },
      {
        city_id: "321",
        type: "Kota",
        city_name: "Padang Panjang",
        subdistricts: [
          {
            subdistrict_id: "4566",
            subdistrict_name: "Padang Panjang Barat",
          },
          {
            subdistrict_id: "4567",
            subdistrict_name: "Padang Panjang Timur",
          },
        ],
      },
      {
        city_id: "322",
        type: "Kabupaten",
        city_name: "Padang Pariaman",
        subdistricts: [
          {
            subdistrict_id: "4568",
            subdistrict_name: "2 X 11 Enam Lingkung",
          },
          {
            subdistrict_id: "4569",
            subdistrict_name: "2 X 11 Kayu Tanam",
          },
          {
            subdistrict_id: "4570",
            subdistrict_name: "Batang Anai",
          },
          {
            subdistrict_id: "4571",
            subdistrict_name: "Batang Gasan",
          },
          {
            subdistrict_id: "4572",
            subdistrict_name: "Enam Lingkung",
          },
          {
            subdistrict_id: "4573",
            subdistrict_name: "IV Koto Aur Malintang",
          },
          {
            subdistrict_id: "4574",
            subdistrict_name: "Lubuk Alung",
          },
          {
            subdistrict_id: "4575",
            subdistrict_name: "Nan Sabaris",
          },
          {
            subdistrict_id: "4576",
            subdistrict_name: "Padang Sago",
          },
          {
            subdistrict_id: "4577",
            subdistrict_name: "Patamuan",
          },
          {
            subdistrict_id: "4578",
            subdistrict_name: "Sintuk/Sintuak Toboh Gadang",
          },
          {
            subdistrict_id: "4579",
            subdistrict_name: "Sungai Geringging/Garingging",
          },
          {
            subdistrict_id: "4580",
            subdistrict_name: "Sungai Limau",
          },
          {
            subdistrict_id: "4581",
            subdistrict_name: "Ulakan Tapakih/Tapakis",
          },
          {
            subdistrict_id: "4582",
            subdistrict_name: "V Koto Kampung Dalam",
          },
          {
            subdistrict_id: "4583",
            subdistrict_name: "V Koto Timur",
          },
          {
            subdistrict_id: "4584",
            subdistrict_name: "VII Koto Sungai Sarik",
          },
        ],
      },
      {
        city_id: "337",
        type: "Kota",
        city_name: "Pariaman",
        subdistricts: [
          {
            subdistrict_id: "4734",
            subdistrict_name: "Pariaman Selatan",
          },
          {
            subdistrict_id: "4735",
            subdistrict_name: "Pariaman Tengah",
          },
          {
            subdistrict_id: "4736",
            subdistrict_name: "Pariaman Timur",
          },
          {
            subdistrict_id: "4737",
            subdistrict_name: "Pariaman Utara",
          },
        ],
      },
      {
        city_id: "339",
        type: "Kabupaten",
        city_name: "Pasaman",
        subdistricts: [
          {
            subdistrict_id: "4760",
            subdistrict_name: "Bonjol",
          },
          {
            subdistrict_id: "4761",
            subdistrict_name: "Duo Koto (II Koto)",
          },
          {
            subdistrict_id: "4762",
            subdistrict_name: "Lubuk Sikaping",
          },
          {
            subdistrict_id: "4763",
            subdistrict_name: "Mapat Tunggul",
          },
          {
            subdistrict_id: "4764",
            subdistrict_name: "Mapat Tunggul Selatan",
          },
          {
            subdistrict_id: "4765",
            subdistrict_name: "Padang Gelugur",
          },
          {
            subdistrict_id: "4766",
            subdistrict_name: "Panti",
          },
          {
            subdistrict_id: "4767",
            subdistrict_name: "Rao",
          },
          {
            subdistrict_id: "4768",
            subdistrict_name: "Rao Selatan",
          },
          {
            subdistrict_id: "4769",
            subdistrict_name: "Rao Utara",
          },
          {
            subdistrict_id: "4770",
            subdistrict_name: "Simpang Alahan Mati",
          },
          {
            subdistrict_id: "4771",
            subdistrict_name: "Tigo Nagari (III Nagari)",
          },
        ],
      },
      {
        city_id: "340",
        type: "Kabupaten",
        city_name: "Pasaman Barat",
        subdistricts: [
          {
            subdistrict_id: "4772",
            subdistrict_name: "Gunung Tuleh",
          },
          {
            subdistrict_id: "4773",
            subdistrict_name: "Kinali",
          },
          {
            subdistrict_id: "4774",
            subdistrict_name: "Koto Balingka",
          },
          {
            subdistrict_id: "4775",
            subdistrict_name: "Lembah Melintang",
          },
          {
            subdistrict_id: "4776",
            subdistrict_name: "Luhak Nan Duo",
          },
          {
            subdistrict_id: "4777",
            subdistrict_name: "Pasaman",
          },
          {
            subdistrict_id: "4778",
            subdistrict_name: "Ranah Batahan",
          },
          {
            subdistrict_id: "4779",
            subdistrict_name: "Sasak Ranah Pasisir/Pesisie",
          },
          {
            subdistrict_id: "4780",
            subdistrict_name: "Sei Beremas",
          },
          {
            subdistrict_id: "4781",
            subdistrict_name: "Sungai Aur",
          },
          {
            subdistrict_id: "4782",
            subdistrict_name: "Talamau",
          },
        ],
      },
      {
        city_id: "345",
        type: "Kota",
        city_name: "Payakumbuh",
        subdistricts: [
          {
            subdistrict_id: "4842",
            subdistrict_name: "Lamposi Tigo Nagari",
          },
          {
            subdistrict_id: "4843",
            subdistrict_name: "Payakumbuh Barat",
          },
          {
            subdistrict_id: "4844",
            subdistrict_name: "Payakumbuh Selatan",
          },
          {
            subdistrict_id: "4845",
            subdistrict_name: "Payakumbuh Timur",
          },
          {
            subdistrict_id: "4846",
            subdistrict_name: "Payakumbuh Utara",
          },
        ],
      },
      {
        city_id: "357",
        type: "Kabupaten",
        city_name: "Pesisir Selatan",
        subdistricts: [
          {
            subdistrict_id: "4984",
            subdistrict_name: "Airpura",
          },
          {
            subdistrict_id: "4985",
            subdistrict_name: "Basa Ampek Balai Tapan",
          },
          {
            subdistrict_id: "4986",
            subdistrict_name: "Batang Kapas",
          },
          {
            subdistrict_id: "4987",
            subdistrict_name: "Bayang",
          },
          {
            subdistrict_id: "4988",
            subdistrict_name: "IV Jurai",
          },
          {
            subdistrict_id: "4989",
            subdistrict_name: "IV Nagari Bayang Utara",
          },
          {
            subdistrict_id: "4990",
            subdistrict_name: "Koto XI Tarusan",
          },
          {
            subdistrict_id: "4991",
            subdistrict_name: "Lengayang",
          },
          {
            subdistrict_id: "4992",
            subdistrict_name: "Linggo Sari Baganti",
          },
          {
            subdistrict_id: "4993",
            subdistrict_name: "Lunang",
          },
          {
            subdistrict_id: "4994",
            subdistrict_name: "Pancung Soal",
          },
          {
            subdistrict_id: "4995",
            subdistrict_name: "Ranah Ampek Hulu Tapan",
          },
          {
            subdistrict_id: "4996",
            subdistrict_name: "Ranah Pesisir",
          },
          {
            subdistrict_id: "4997",
            subdistrict_name: "Silaut",
          },
          {
            subdistrict_id: "4998",
            subdistrict_name: "Sutera",
          },
        ],
      },
      {
        city_id: "394",
        type: "Kota",
        city_name: "Sawah Lunto",
        subdistricts: [
          {
            subdistrict_id: "5443",
            subdistrict_name: "Barangin",
          },
          {
            subdistrict_id: "5444",
            subdistrict_name: "Lembah Segar",
          },
          {
            subdistrict_id: "5445",
            subdistrict_name: "Silungkang",
          },
          {
            subdistrict_id: "5446",
            subdistrict_name: "Talawi",
          },
        ],
      },
      {
        city_id: "411",
        type: "Kabupaten",
        city_name: "Sijunjung (Sawah Lunto Sijunjung)",
        subdistricts: [
          {
            subdistrict_id: "5664",
            subdistrict_name: "IV Nagari",
          },
          {
            subdistrict_id: "5665",
            subdistrict_name: "Kamang Baru",
          },
          {
            subdistrict_id: "5666",
            subdistrict_name: "Koto VII",
          },
          {
            subdistrict_id: "5667",
            subdistrict_name: "Kupitan",
          },
          {
            subdistrict_id: "5668",
            subdistrict_name: "Lubuak Tarok",
          },
          {
            subdistrict_id: "5669",
            subdistrict_name: "Sijunjung",
          },
          {
            subdistrict_id: "5670",
            subdistrict_name: "Sumpur Kudus",
          },
          {
            subdistrict_id: "5671",
            subdistrict_name: "Tanjung Gadang",
          },
        ],
      },
      {
        city_id: "420",
        type: "Kabupaten",
        city_name: "Solok",
        subdistricts: [
          {
            subdistrict_id: "5796",
            subdistrict_name: "Bukit Sundi",
          },
          {
            subdistrict_id: "5797",
            subdistrict_name: "Danau Kembar",
          },
          {
            subdistrict_id: "5798",
            subdistrict_name: "Gunung Talang",
          },
          {
            subdistrict_id: "5799",
            subdistrict_name: "Hiliran Gumanti",
          },
          {
            subdistrict_id: "5800",
            subdistrict_name: "IX Koto Sei Lasi",
          },
          {
            subdistrict_id: "5801",
            subdistrict_name: "Junjung Sirih",
          },
          {
            subdistrict_id: "5802",
            subdistrict_name: "Kubung",
          },
          {
            subdistrict_id: "5803",
            subdistrict_name: "Lembah Gumanti",
          },
          {
            subdistrict_id: "5804",
            subdistrict_name: "Lembang Jaya",
          },
          {
            subdistrict_id: "5805",
            subdistrict_name: "Pantai Cermin",
          },
          {
            subdistrict_id: "5806",
            subdistrict_name: "Payung Sekaki",
          },
          {
            subdistrict_id: "5807",
            subdistrict_name: "Tigo Lurah",
          },
          {
            subdistrict_id: "5808",
            subdistrict_name: "X Koto Diatas",
          },
          {
            subdistrict_id: "5809",
            subdistrict_name: "X Koto Singkarak",
          },
        ],
      },
      {
        city_id: "421",
        type: "Kota",
        city_name: "Solok",
        subdistricts: [
          {
            subdistrict_id: "5810",
            subdistrict_name: "Lubuk Sikarah",
          },
          {
            subdistrict_id: "5811",
            subdistrict_name: "Tanjung Harapan",
          },
        ],
      },
      {
        city_id: "422",
        type: "Kabupaten",
        city_name: "Solok Selatan",
        subdistricts: [
          {
            subdistrict_id: "5812",
            subdistrict_name: "Koto Parik Gadang Diateh",
          },
          {
            subdistrict_id: "5813",
            subdistrict_name: "Pauh Duo",
          },
          {
            subdistrict_id: "5814",
            subdistrict_name: "Sangir",
          },
          {
            subdistrict_id: "5815",
            subdistrict_name: "Sangir Balai Janggo",
          },
          {
            subdistrict_id: "5816",
            subdistrict_name: "Sangir Batang Hari",
          },
          {
            subdistrict_id: "5817",
            subdistrict_name: "Sangir Jujuan",
          },
          {
            subdistrict_id: "5818",
            subdistrict_name: "Sungai Pagu",
          },
        ],
      },
      {
        city_id: "453",
        type: "Kabupaten",
        city_name: "Tanah Datar",
        subdistricts: [
          {
            subdistrict_id: "6243",
            subdistrict_name: "Batipuh",
          },
          {
            subdistrict_id: "6244",
            subdistrict_name: "Batipuh Selatan",
          },
          {
            subdistrict_id: "6245",
            subdistrict_name: "Lima Kaum",
          },
          {
            subdistrict_id: "6246",
            subdistrict_name: "Lintau Buo",
          },
          {
            subdistrict_id: "6247",
            subdistrict_name: "Lintau Buo Utara",
          },
          {
            subdistrict_id: "6248",
            subdistrict_name: "Padang Ganting",
          },
          {
            subdistrict_id: "6249",
            subdistrict_name: "Pariangan",
          },
          {
            subdistrict_id: "6250",
            subdistrict_name: "Rambatan",
          },
          {
            subdistrict_id: "6251",
            subdistrict_name: "Salimpaung",
          },
          {
            subdistrict_id: "6252",
            subdistrict_name: "Sepuluh Koto (X Koto)",
          },
          {
            subdistrict_id: "6253",
            subdistrict_name: "Sungai Tarab",
          },
          {
            subdistrict_id: "6254",
            subdistrict_name: "Sungayang",
          },
          {
            subdistrict_id: "6255",
            subdistrict_name: "Tanjung Baru",
          },
          {
            subdistrict_id: "6256",
            subdistrict_name: "Tanjung Emas",
          },
        ],
      },
    ],
  },
  {
    province_id: "33",
    province: "Sumatera Selatan",
    cities: [
      {
        city_id: "40",
        type: "Kabupaten",
        city_name: "Banyuasin",
        subdistricts: [
          {
            subdistrict_id: "554",
            subdistrict_name: "Air Kumbang",
          },
          {
            subdistrict_id: "555",
            subdistrict_name: "Air Salek",
          },
          {
            subdistrict_id: "556",
            subdistrict_name: "Banyuasin I",
          },
          {
            subdistrict_id: "557",
            subdistrict_name: "Banyuasin II",
          },
          {
            subdistrict_id: "558",
            subdistrict_name: "Banyuasin III",
          },
          {
            subdistrict_id: "559",
            subdistrict_name: "Betung",
          },
          {
            subdistrict_id: "560",
            subdistrict_name: "Makarti Jaya",
          },
          {
            subdistrict_id: "561",
            subdistrict_name: "Muara Padang",
          },
          {
            subdistrict_id: "562",
            subdistrict_name: "Muara Sugihan",
          },
          {
            subdistrict_id: "563",
            subdistrict_name: "Muara Telang",
          },
          {
            subdistrict_id: "564",
            subdistrict_name: "Pulau Rimau",
          },
          {
            subdistrict_id: "565",
            subdistrict_name: "Rambutan",
          },
          {
            subdistrict_id: "566",
            subdistrict_name: "Rantau Bayur",
          },
          {
            subdistrict_id: "567",
            subdistrict_name: "Sembawa",
          },
          {
            subdistrict_id: "568",
            subdistrict_name: "Suak Tapeh",
          },
          {
            subdistrict_id: "569",
            subdistrict_name: "Sumber Marga Telang",
          },
          {
            subdistrict_id: "570",
            subdistrict_name: "Talang Kelapa",
          },
          {
            subdistrict_id: "571",
            subdistrict_name: "Tanjung Lago",
          },
          {
            subdistrict_id: "572",
            subdistrict_name: "Tungkal Ilir",
          },
        ],
      },
      {
        city_id: "121",
        type: "Kabupaten",
        city_name: "Empat Lawang",
        subdistricts: [
          {
            subdistrict_id: "1640",
            subdistrict_name: "Lintang Kanan",
          },
          {
            subdistrict_id: "1641",
            subdistrict_name: "Muara Pinang",
          },
          {
            subdistrict_id: "1642",
            subdistrict_name: "Pasemah Air Keruh",
          },
          {
            subdistrict_id: "1643",
            subdistrict_name: "Pendopo",
          },
          {
            subdistrict_id: "1644",
            subdistrict_name: "Pendopo Barat",
          },
          {
            subdistrict_id: "1645",
            subdistrict_name: "Saling",
          },
          {
            subdistrict_id: "1646",
            subdistrict_name: "Sikap Dalam",
          },
          {
            subdistrict_id: "1647",
            subdistrict_name: "Talang Padang",
          },
          {
            subdistrict_id: "1648",
            subdistrict_name: "Tebing Tinggi",
          },
          {
            subdistrict_id: "1649",
            subdistrict_name: "Ulu Musi",
          },
        ],
      },
      {
        city_id: "220",
        type: "Kabupaten",
        city_name: "Lahat",
        subdistricts: [
          {
            subdistrict_id: "3083",
            subdistrict_name: "Gumay Talang",
          },
          {
            subdistrict_id: "3084",
            subdistrict_name: "Gumay Ulu",
          },
          {
            subdistrict_id: "3085",
            subdistrict_name: "Jarai",
          },
          {
            subdistrict_id: "3086",
            subdistrict_name: "Kikim Barat",
          },
          {
            subdistrict_id: "3087",
            subdistrict_name: "Kikim Selatan",
          },
          {
            subdistrict_id: "3088",
            subdistrict_name: "Kikim Tengah",
          },
          {
            subdistrict_id: "3089",
            subdistrict_name: "Kikim Timur",
          },
          {
            subdistrict_id: "3090",
            subdistrict_name: "Kota Agung",
          },
          {
            subdistrict_id: "3091",
            subdistrict_name: "Lahat",
          },
          {
            subdistrict_id: "3092",
            subdistrict_name: "Merapi Barat",
          },
          {
            subdistrict_id: "3093",
            subdistrict_name: "Merapi Selatan",
          },
          {
            subdistrict_id: "3094",
            subdistrict_name: "Merapi Timur",
          },
          {
            subdistrict_id: "3095",
            subdistrict_name: "Muarapayang",
          },
          {
            subdistrict_id: "3096",
            subdistrict_name: "Mulak Ulu",
          },
          {
            subdistrict_id: "3097",
            subdistrict_name: "Pagar Gunung",
          },
          {
            subdistrict_id: "3098",
            subdistrict_name: "Pajar Bulan",
          },
          {
            subdistrict_id: "3099",
            subdistrict_name: "Pseksu",
          },
          {
            subdistrict_id: "3100",
            subdistrict_name: "Pulau Pinang",
          },
          {
            subdistrict_id: "3101",
            subdistrict_name: "Sukamerindu",
          },
          {
            subdistrict_id: "3102",
            subdistrict_name: "Tanjung Sakti Pumi",
          },
          {
            subdistrict_id: "3103",
            subdistrict_name: "Tanjung Sakti Pumu",
          },
          {
            subdistrict_id: "3104",
            subdistrict_name: "Tanjung Tebat",
          },
        ],
      },
      {
        city_id: "242",
        type: "Kota",
        city_name: "Lubuk Linggau",
        subdistricts: [
          {
            subdistrict_id: "3419",
            subdistrict_name: "Lubuk Linggau Barat Dua (II)",
          },
          {
            subdistrict_id: "3420",
            subdistrict_name: "Lubuk Linggau Barat Satu (I)",
          },
          {
            subdistrict_id: "3421",
            subdistrict_name: "Lubuk Linggau Selatan Dua (II)",
          },
          {
            subdistrict_id: "3422",
            subdistrict_name: "Lubuk Linggau Selatan Satu (I)",
          },
          {
            subdistrict_id: "3423",
            subdistrict_name: "Lubuk Linggau Timur Dua (II)",
          },
          {
            subdistrict_id: "3424",
            subdistrict_name: "Lubuk Linggau Timur Satu (I)",
          },
          {
            subdistrict_id: "3425",
            subdistrict_name: "Lubuk Linggau Utara Dua (II)",
          },
          {
            subdistrict_id: "3426",
            subdistrict_name: "Lubuk Linggau Utara Satu (I)",
          },
        ],
      },
      {
        city_id: "292",
        type: "Kabupaten",
        city_name: "Muara Enim",
        subdistricts: [
          {
            subdistrict_id: "4109",
            subdistrict_name: "Abab",
          },
          {
            subdistrict_id: "4110",
            subdistrict_name: "Benakat",
          },
          {
            subdistrict_id: "4111",
            subdistrict_name: "Gelumbang",
          },
          {
            subdistrict_id: "4112",
            subdistrict_name: "Gunung Megang",
          },
          {
            subdistrict_id: "4113",
            subdistrict_name: "Kelekar",
          },
          {
            subdistrict_id: "4114",
            subdistrict_name: "Lawang Kidul",
          },
          {
            subdistrict_id: "4115",
            subdistrict_name: "Lembak",
          },
          {
            subdistrict_id: "4116",
            subdistrict_name: "Lubai",
          },
          {
            subdistrict_id: "4117",
            subdistrict_name: "Muara Belida",
          },
          {
            subdistrict_id: "4118",
            subdistrict_name: "Muara Enim",
          },
          {
            subdistrict_id: "4119",
            subdistrict_name: "Penukal (Penukal Abab)",
          },
          {
            subdistrict_id: "4120",
            subdistrict_name: "Penukal Utara",
          },
          {
            subdistrict_id: "4121",
            subdistrict_name: "Rambang",
          },
          {
            subdistrict_id: "4122",
            subdistrict_name: "Rambang Dangku",
          },
          {
            subdistrict_id: "4123",
            subdistrict_name: "Semendo Darat Laut",
          },
          {
            subdistrict_id: "4124",
            subdistrict_name: "Semendo Darat Tengah",
          },
          {
            subdistrict_id: "4125",
            subdistrict_name: "Semendo Darat Ulu",
          },
          {
            subdistrict_id: "4126",
            subdistrict_name: "Sungai Rotan",
          },
          {
            subdistrict_id: "4127",
            subdistrict_name: "Talang Ubi",
          },
          {
            subdistrict_id: "4128",
            subdistrict_name: "Tanah Abang",
          },
          {
            subdistrict_id: "4129",
            subdistrict_name: "Tanjung Agung",
          },
          {
            subdistrict_id: "4130",
            subdistrict_name: "Ujan Mas",
          },
        ],
      },
      {
        city_id: "297",
        type: "Kabupaten",
        city_name: "Musi Banyuasin",
        subdistricts: [
          {
            subdistrict_id: "4200",
            subdistrict_name: "Babat Supat",
          },
          {
            subdistrict_id: "4201",
            subdistrict_name: "Babat Toman",
          },
          {
            subdistrict_id: "4202",
            subdistrict_name: "Batanghari Leko",
          },
          {
            subdistrict_id: "4203",
            subdistrict_name: "Bayung Lencir",
          },
          {
            subdistrict_id: "4204",
            subdistrict_name: "Keluang",
          },
          {
            subdistrict_id: "4205",
            subdistrict_name: "Lais",
          },
          {
            subdistrict_id: "4206",
            subdistrict_name: "Lalan (Sungai Lalan)",
          },
          {
            subdistrict_id: "4207",
            subdistrict_name: "Lawang Wetan",
          },
          {
            subdistrict_id: "4208",
            subdistrict_name: "Plakat Tinggi (Pelakat Tinggi)",
          },
          {
            subdistrict_id: "4209",
            subdistrict_name: "Sanga Desa",
          },
          {
            subdistrict_id: "4210",
            subdistrict_name: "Sekayu",
          },
          {
            subdistrict_id: "4211",
            subdistrict_name: "Sungai Keruh",
          },
          {
            subdistrict_id: "4212",
            subdistrict_name: "Sungai Lilin",
          },
          {
            subdistrict_id: "4213",
            subdistrict_name: "Tungkal Jaya",
          },
        ],
      },
      {
        city_id: "298",
        type: "Kabupaten",
        city_name: "Musi Rawas",
        subdistricts: [
          {
            subdistrict_id: "4214",
            subdistrict_name: "Batukuning Lakitan Ulu (BTS)/Cecar",
          },
          {
            subdistrict_id: "4215",
            subdistrict_name: "Jaya Loka",
          },
          {
            subdistrict_id: "4216",
            subdistrict_name: "Karang Dapo",
          },
          {
            subdistrict_id: "4217",
            subdistrict_name: "Karang Jaya",
          },
          {
            subdistrict_id: "4218",
            subdistrict_name: "Megang Sakti",
          },
          {
            subdistrict_id: "4219",
            subdistrict_name: "Muara Beliti",
          },
          {
            subdistrict_id: "4220",
            subdistrict_name: "Muara Kelingi",
          },
          {
            subdistrict_id: "4221",
            subdistrict_name: "Muara Lakitan",
          },
          {
            subdistrict_id: "4222",
            subdistrict_name: "Nibung",
          },
          {
            subdistrict_id: "4223",
            subdistrict_name: "Purwodadi",
          },
          {
            subdistrict_id: "4224",
            subdistrict_name: "Rawas Ilir",
          },
          {
            subdistrict_id: "4225",
            subdistrict_name: "Rawas Ulu",
          },
          {
            subdistrict_id: "4226",
            subdistrict_name: "Rupit",
          },
          {
            subdistrict_id: "4227",
            subdistrict_name: "Selangit",
          },
          {
            subdistrict_id: "4228",
            subdistrict_name: "STL Ulu Terawas",
          },
          {
            subdistrict_id: "4229",
            subdistrict_name: "Sukakarya",
          },
          {
            subdistrict_id: "4230",
            subdistrict_name: "Sumber Harta",
          },
          {
            subdistrict_id: "4231",
            subdistrict_name: "Tiang Pumpung Kepungut",
          },
          {
            subdistrict_id: "4232",
            subdistrict_name: "Tuah Negeri",
          },
          {
            subdistrict_id: "4233",
            subdistrict_name: "Tugumulyo",
          },
          {
            subdistrict_id: "4234",
            subdistrict_name: "Ulu Rawas",
          },
        ],
      },
      {
        city_id: "312",
        type: "Kabupaten",
        city_name: "Ogan Ilir",
        subdistricts: [
          {
            subdistrict_id: "4437",
            subdistrict_name: "Indralaya",
          },
          {
            subdistrict_id: "4438",
            subdistrict_name: "Indralaya Selatan",
          },
          {
            subdistrict_id: "4439",
            subdistrict_name: "Indralaya Utara",
          },
          {
            subdistrict_id: "4440",
            subdistrict_name: "Kandis",
          },
          {
            subdistrict_id: "4441",
            subdistrict_name: "Lubuk Keliat",
          },
          {
            subdistrict_id: "4442",
            subdistrict_name: "Muara Kuang",
          },
          {
            subdistrict_id: "4443",
            subdistrict_name: "Payaraman",
          },
          {
            subdistrict_id: "4444",
            subdistrict_name: "Pemulutan",
          },
          {
            subdistrict_id: "4445",
            subdistrict_name: "Pemulutan Barat",
          },
          {
            subdistrict_id: "4446",
            subdistrict_name: "Pemulutan Selatan",
          },
          {
            subdistrict_id: "4447",
            subdistrict_name: "Rambang Kuang",
          },
          {
            subdistrict_id: "4448",
            subdistrict_name: "Rantau Alai",
          },
          {
            subdistrict_id: "4449",
            subdistrict_name: "Rantau Panjang",
          },
          {
            subdistrict_id: "4450",
            subdistrict_name: "Sungai Pinang",
          },
          {
            subdistrict_id: "4451",
            subdistrict_name: "Tanjung Batu",
          },
          {
            subdistrict_id: "4452",
            subdistrict_name: "Tanjung Raja",
          },
        ],
      },
      {
        city_id: "313",
        type: "Kabupaten",
        city_name: "Ogan Komering Ilir",
        subdistricts: [
          {
            subdistrict_id: "4453",
            subdistrict_name: "Air Sugihan",
          },
          {
            subdistrict_id: "4454",
            subdistrict_name: "Cengal",
          },
          {
            subdistrict_id: "4455",
            subdistrict_name: "Jejawi",
          },
          {
            subdistrict_id: "4456",
            subdistrict_name: "Kayu Agung",
          },
          {
            subdistrict_id: "4457",
            subdistrict_name: "Lempuing",
          },
          {
            subdistrict_id: "4458",
            subdistrict_name: "Lempuing Jaya",
          },
          {
            subdistrict_id: "4459",
            subdistrict_name: "Mesuji",
          },
          {
            subdistrict_id: "4460",
            subdistrict_name: "Mesuji Makmur",
          },
          {
            subdistrict_id: "4461",
            subdistrict_name: "Mesuji Raya",
          },
          {
            subdistrict_id: "4462",
            subdistrict_name: "Pampangan",
          },
          {
            subdistrict_id: "4463",
            subdistrict_name: "Pangkalan Lampam",
          },
          {
            subdistrict_id: "4464",
            subdistrict_name: "Pedamaran",
          },
          {
            subdistrict_id: "4465",
            subdistrict_name: "Pedamaran Timur",
          },
          {
            subdistrict_id: "4466",
            subdistrict_name: "Sirah Pulau Padang",
          },
          {
            subdistrict_id: "4467",
            subdistrict_name: "Sungai Menang",
          },
          {
            subdistrict_id: "4468",
            subdistrict_name: "Tanjung Lubuk",
          },
          {
            subdistrict_id: "4469",
            subdistrict_name: "Teluk Gelam",
          },
          {
            subdistrict_id: "4470",
            subdistrict_name: "Tulung Selapan",
          },
        ],
      },
      {
        city_id: "314",
        type: "Kabupaten",
        city_name: "Ogan Komering Ulu",
        subdistricts: [
          {
            subdistrict_id: "4471",
            subdistrict_name: "Baturaja Barat",
          },
          {
            subdistrict_id: "4472",
            subdistrict_name: "Baturaja Timur",
          },
          {
            subdistrict_id: "4473",
            subdistrict_name: "Lengkiti",
          },
          {
            subdistrict_id: "4474",
            subdistrict_name: "Lubuk Batang",
          },
          {
            subdistrict_id: "4475",
            subdistrict_name: "Lubuk Raja",
          },
          {
            subdistrict_id: "4476",
            subdistrict_name: "Muara Jaya",
          },
          {
            subdistrict_id: "4477",
            subdistrict_name: "Pengandonan",
          },
          {
            subdistrict_id: "4478",
            subdistrict_name: "Peninjauan",
          },
          {
            subdistrict_id: "4479",
            subdistrict_name: "Semidang Aji",
          },
          {
            subdistrict_id: "4480",
            subdistrict_name: "Sinar Peninjauan",
          },
          {
            subdistrict_id: "4481",
            subdistrict_name: "Sosoh Buay Rayap",
          },
          {
            subdistrict_id: "4482",
            subdistrict_name: "Ulu Ogan",
          },
        ],
      },
      {
        city_id: "315",
        type: "Kabupaten",
        city_name: "Ogan Komering Ulu Selatan",
        subdistricts: [
          {
            subdistrict_id: "4483",
            subdistrict_name: "Banding Agung",
          },
          {
            subdistrict_id: "4484",
            subdistrict_name: "Buana Pemaca",
          },
          {
            subdistrict_id: "4485",
            subdistrict_name: "Buay Pemaca",
          },
          {
            subdistrict_id: "4486",
            subdistrict_name: "Buay Pematang Ribu Ranau Tengah",
          },
          {
            subdistrict_id: "4487",
            subdistrict_name: "Buay Rawan",
          },
          {
            subdistrict_id: "4488",
            subdistrict_name: "Buay Runjung",
          },
          {
            subdistrict_id: "4489",
            subdistrict_name: "Buay Sandang Aji",
          },
          {
            subdistrict_id: "4490",
            subdistrict_name: "Kisam Ilir",
          },
          {
            subdistrict_id: "4491",
            subdistrict_name: "Kisam Tinggi",
          },
          {
            subdistrict_id: "4492",
            subdistrict_name: "Mekakau Ilir",
          },
          {
            subdistrict_id: "4493",
            subdistrict_name: "Muaradua",
          },
          {
            subdistrict_id: "4494",
            subdistrict_name: "Muaradua Kisam",
          },
          {
            subdistrict_id: "4495",
            subdistrict_name: "Pulau Beringin",
          },
          {
            subdistrict_id: "4496",
            subdistrict_name: "Runjung Agung",
          },
          {
            subdistrict_id: "4497",
            subdistrict_name: "Simpang",
          },
          {
            subdistrict_id: "4498",
            subdistrict_name: "Sindang Danau",
          },
          {
            subdistrict_id: "4499",
            subdistrict_name: "Sungai Are",
          },
          {
            subdistrict_id: "4500",
            subdistrict_name: "Tiga Dihaji",
          },
          {
            subdistrict_id: "4501",
            subdistrict_name: "Warkuk Ranau Selatan",
          },
        ],
      },
      {
        city_id: "316",
        type: "Kabupaten",
        city_name: "Ogan Komering Ulu Timur",
        subdistricts: [
          {
            subdistrict_id: "4502",
            subdistrict_name: "Belitang",
          },
          {
            subdistrict_id: "4503",
            subdistrict_name: "Belitang II",
          },
          {
            subdistrict_id: "4504",
            subdistrict_name: "Belitang III",
          },
          {
            subdistrict_id: "4505",
            subdistrict_name: "Belitang Jaya",
          },
          {
            subdistrict_id: "4506",
            subdistrict_name: "Belitang Madang Raya",
          },
          {
            subdistrict_id: "4507",
            subdistrict_name: "Belitang Mulya",
          },
          {
            subdistrict_id: "4508",
            subdistrict_name: "Buay Madang",
          },
          {
            subdistrict_id: "4509",
            subdistrict_name: "Buay Madang Timur",
          },
          {
            subdistrict_id: "4510",
            subdistrict_name: "Buay Pemuka Bangsa Raja",
          },
          {
            subdistrict_id: "4511",
            subdistrict_name: "Buay Pemuka Beliung / Peliung",
          },
          {
            subdistrict_id: "4512",
            subdistrict_name: "Bunga Mayang",
          },
          {
            subdistrict_id: "4513",
            subdistrict_name: "Cempaka",
          },
          {
            subdistrict_id: "4514",
            subdistrict_name: "Jayapura",
          },
          {
            subdistrict_id: "4515",
            subdistrict_name: "Madang Suku I",
          },
          {
            subdistrict_id: "4516",
            subdistrict_name: "Madang Suku II",
          },
          {
            subdistrict_id: "4517",
            subdistrict_name: "Madang Suku III",
          },
          {
            subdistrict_id: "4518",
            subdistrict_name: "Martapura",
          },
          {
            subdistrict_id: "4519",
            subdistrict_name: "Semendawai Barat",
          },
          {
            subdistrict_id: "4520",
            subdistrict_name: "Semendawai Suku III",
          },
          {
            subdistrict_id: "4521",
            subdistrict_name: "Semendawai Timur",
          },
        ],
      },
      {
        city_id: "324",
        type: "Kota",
        city_name: "Pagar Alam",
        subdistricts: [
          {
            subdistrict_id: "4591",
            subdistrict_name: "Dempo Selatan",
          },
          {
            subdistrict_id: "4592",
            subdistrict_name: "Dempo Tengah",
          },
          {
            subdistrict_id: "4593",
            subdistrict_name: "Dempo Utara",
          },
          {
            subdistrict_id: "4594",
            subdistrict_name: "Pagar Alam Selatan",
          },
          {
            subdistrict_id: "4595",
            subdistrict_name: "Pagar Alam Utara",
          },
        ],
      },
      {
        city_id: "327",
        type: "Kota",
        city_name: "Palembang",
        subdistricts: [
          {
            subdistrict_id: "4609",
            subdistrict_name: "Alang-Alang Lebar",
          },
          {
            subdistrict_id: "4610",
            subdistrict_name: "Bukit Kecil",
          },
          {
            subdistrict_id: "4611",
            subdistrict_name: "Gandus",
          },
          {
            subdistrict_id: "4612",
            subdistrict_name: "Ilir Barat I",
          },
          {
            subdistrict_id: "4613",
            subdistrict_name: "Ilir Barat II",
          },
          {
            subdistrict_id: "4614",
            subdistrict_name: "Ilir Timur I",
          },
          {
            subdistrict_id: "4615",
            subdistrict_name: "Ilir Timur II",
          },
          {
            subdistrict_id: "4616",
            subdistrict_name: "Kalidoni",
          },
          {
            subdistrict_id: "4617",
            subdistrict_name: "Kemuning",
          },
          {
            subdistrict_id: "4618",
            subdistrict_name: "Kertapati",
          },
          {
            subdistrict_id: "4619",
            subdistrict_name: "Plaju",
          },
          {
            subdistrict_id: "4620",
            subdistrict_name: "Sako",
          },
          {
            subdistrict_id: "4621",
            subdistrict_name: "Seberang Ulu I",
          },
          {
            subdistrict_id: "4622",
            subdistrict_name: "Seberang Ulu II",
          },
          {
            subdistrict_id: "4623",
            subdistrict_name: "Sematang Borang",
          },
          {
            subdistrict_id: "4624",
            subdistrict_name: "Sukarami",
          },
        ],
      },
      {
        city_id: "367",
        type: "Kota",
        city_name: "Prabumulih",
        subdistricts: [
          {
            subdistrict_id: "5126",
            subdistrict_name: "Cambai",
          },
          {
            subdistrict_id: "5127",
            subdistrict_name: "Prabumulih Barat",
          },
          {
            subdistrict_id: "5128",
            subdistrict_name: "Prabumulih Selatan",
          },
          {
            subdistrict_id: "5129",
            subdistrict_name: "Prabumulih Timur",
          },
          {
            subdistrict_id: "5130",
            subdistrict_name: "Prabumulih Utara",
          },
          {
            subdistrict_id: "5131",
            subdistrict_name: "Rambang Kapak Tengah",
          },
        ],
      },
    ],
  },
  {
    province_id: "34",
    province: "Sumatera Utara",
    cities: [
      {
        city_id: "15",
        type: "Kabupaten",
        city_name: "Asahan",
        subdistricts: [
          {
            subdistrict_id: "214",
            subdistrict_name: "Aek Kuasan",
          },
          {
            subdistrict_id: "215",
            subdistrict_name: "Aek Ledong",
          },
          {
            subdistrict_id: "216",
            subdistrict_name: "Aek Songsongan",
          },
          {
            subdistrict_id: "217",
            subdistrict_name: "Air Batu",
          },
          {
            subdistrict_id: "218",
            subdistrict_name: "Air Joman",
          },
          {
            subdistrict_id: "219",
            subdistrict_name: "Bandar Pasir Mandoge",
          },
          {
            subdistrict_id: "220",
            subdistrict_name: "Bandar Pulau",
          },
          {
            subdistrict_id: "221",
            subdistrict_name: "Buntu Pane",
          },
          {
            subdistrict_id: "222",
            subdistrict_name: "Kisaran Barat Kota",
          },
          {
            subdistrict_id: "223",
            subdistrict_name: "Kisaran Timur Kota",
          },
          {
            subdistrict_id: "224",
            subdistrict_name: "Meranti",
          },
          {
            subdistrict_id: "225",
            subdistrict_name: "Pulau Rakyat",
          },
          {
            subdistrict_id: "226",
            subdistrict_name: "Pulo Bandring",
          },
          {
            subdistrict_id: "227",
            subdistrict_name: "Rahuning",
          },
          {
            subdistrict_id: "228",
            subdistrict_name: "Rawang Panca Arga",
          },
          {
            subdistrict_id: "229",
            subdistrict_name: "Sei Dadap",
          },
          {
            subdistrict_id: "230",
            subdistrict_name: "Sei Kepayang",
          },
          {
            subdistrict_id: "231",
            subdistrict_name: "Sei Kepayang Barat",
          },
          {
            subdistrict_id: "232",
            subdistrict_name: "Sei Kepayang Timur",
          },
          {
            subdistrict_id: "233",
            subdistrict_name: "Setia Janji",
          },
          {
            subdistrict_id: "234",
            subdistrict_name: "Silau Laut",
          },
          {
            subdistrict_id: "235",
            subdistrict_name: "Simpang Empat",
          },
          {
            subdistrict_id: "236",
            subdistrict_name: "Tanjung Balai",
          },
          {
            subdistrict_id: "237",
            subdistrict_name: "Teluk Dalam",
          },
          {
            subdistrict_id: "238",
            subdistrict_name: "Tinggi Raja",
          },
        ],
      },
      {
        city_id: "52",
        type: "Kabupaten",
        city_name: "Batu Bara",
        subdistricts: [
          {
            subdistrict_id: "711",
            subdistrict_name: "Air Putih",
          },
          {
            subdistrict_id: "712",
            subdistrict_name: "Limapuluh",
          },
          {
            subdistrict_id: "713",
            subdistrict_name: "Medang Deras",
          },
          {
            subdistrict_id: "714",
            subdistrict_name: "Sei Balai",
          },
          {
            subdistrict_id: "715",
            subdistrict_name: "Sei Suka",
          },
          {
            subdistrict_id: "716",
            subdistrict_name: "Talawi",
          },
          {
            subdistrict_id: "717",
            subdistrict_name: "Tanjung Tiram",
          },
        ],
      },
      {
        city_id: "70",
        type: "Kota",
        city_name: "Binjai",
        subdistricts: [
          {
            subdistrict_id: "934",
            subdistrict_name: "Binjai Barat",
          },
          {
            subdistrict_id: "935",
            subdistrict_name: "Binjai Kota",
          },
          {
            subdistrict_id: "936",
            subdistrict_name: "Binjai Selatan",
          },
          {
            subdistrict_id: "937",
            subdistrict_name: "Binjai Timur",
          },
          {
            subdistrict_id: "938",
            subdistrict_name: "Binjai Utara",
          },
        ],
      },
      {
        city_id: "110",
        type: "Kabupaten",
        city_name: "Dairi",
        subdistricts: [
          {
            subdistrict_id: "1517",
            subdistrict_name: "Berampu (Brampu)",
          },
          {
            subdistrict_id: "1518",
            subdistrict_name: "Gunung Sitember",
          },
          {
            subdistrict_id: "1519",
            subdistrict_name: "Lae Parira",
          },
          {
            subdistrict_id: "1520",
            subdistrict_name: "Parbuluan",
          },
          {
            subdistrict_id: "1521",
            subdistrict_name: "Pegagan Hilir",
          },
          {
            subdistrict_id: "1522",
            subdistrict_name: "Sidikalang",
          },
          {
            subdistrict_id: "1523",
            subdistrict_name: "Siempat Nempu",
          },
          {
            subdistrict_id: "1524",
            subdistrict_name: "Siempat Nempu Hilir",
          },
          {
            subdistrict_id: "1525",
            subdistrict_name: "Siempat Nempu Hulu",
          },
          {
            subdistrict_id: "1526",
            subdistrict_name: "Silahi Sabungan",
          },
          {
            subdistrict_id: "1527",
            subdistrict_name: "Silima Pungga-Pungga",
          },
          {
            subdistrict_id: "1528",
            subdistrict_name: "Sitinjo",
          },
          {
            subdistrict_id: "1529",
            subdistrict_name: "Sumbul",
          },
          {
            subdistrict_id: "1530",
            subdistrict_name: "Tanah Pinem",
          },
          {
            subdistrict_id: "1531",
            subdistrict_name: "Tiga Lingga",
          },
        ],
      },
      {
        city_id: "112",
        type: "Kabupaten",
        city_name: "Deli Serdang",
        subdistricts: [
          {
            subdistrict_id: "1537",
            subdistrict_name: "Bangun Purba",
          },
          {
            subdistrict_id: "1538",
            subdistrict_name: "Batang Kuis",
          },
          {
            subdistrict_id: "1539",
            subdistrict_name: "Beringin",
          },
          {
            subdistrict_id: "1540",
            subdistrict_name: "Biru-Biru",
          },
          {
            subdistrict_id: "1541",
            subdistrict_name: "Deli Tua",
          },
          {
            subdistrict_id: "1542",
            subdistrict_name: "Galang",
          },
          {
            subdistrict_id: "1543",
            subdistrict_name: "Gunung Meriah",
          },
          {
            subdistrict_id: "1544",
            subdistrict_name: "Hamparan Perak",
          },
          {
            subdistrict_id: "1545",
            subdistrict_name: "Kutalimbaru",
          },
          {
            subdistrict_id: "1546",
            subdistrict_name: "Labuhan Deli",
          },
          {
            subdistrict_id: "1547",
            subdistrict_name: "Lubuk Pakam",
          },
          {
            subdistrict_id: "1548",
            subdistrict_name: "Namo Rambe",
          },
          {
            subdistrict_id: "1549",
            subdistrict_name: "Pagar Merbau",
          },
          {
            subdistrict_id: "1550",
            subdistrict_name: "Pancur Batu",
          },
          {
            subdistrict_id: "1551",
            subdistrict_name: "Pantai Labu",
          },
          {
            subdistrict_id: "1552",
            subdistrict_name: "Patumbak",
          },
          {
            subdistrict_id: "1553",
            subdistrict_name: "Percut Sei Tuan",
          },
          {
            subdistrict_id: "1554",
            subdistrict_name: "Sibolangit",
          },
          {
            subdistrict_id: "1555",
            subdistrict_name: "Sinembah Tanjung Muda Hilir",
          },
          {
            subdistrict_id: "1556",
            subdistrict_name: "Sinembah Tanjung Muda Hulu",
          },
          {
            subdistrict_id: "1557",
            subdistrict_name: "Sunggal",
          },
          {
            subdistrict_id: "1558",
            subdistrict_name: "Tanjung Morawa",
          },
        ],
      },
      {
        city_id: "137",
        type: "Kota",
        city_name: "Gunungsitoli",
        subdistricts: [
          {
            subdistrict_id: "1895",
            subdistrict_name: "Gunungsitoli",
          },
          {
            subdistrict_id: "1896",
            subdistrict_name: "Gunungsitoli Alo'oa",
          },
          {
            subdistrict_id: "1897",
            subdistrict_name: "Gunungsitoli Barat",
          },
          {
            subdistrict_id: "1898",
            subdistrict_name: "Gunungsitoli Idanoi",
          },
          {
            subdistrict_id: "1899",
            subdistrict_name: "Gunungsitoli Selatan",
          },
          {
            subdistrict_id: "1900",
            subdistrict_name: "Gunungsitoli Utara",
          },
        ],
      },
      {
        city_id: "146",
        type: "Kabupaten",
        city_name: "Humbang Hasundutan",
        subdistricts: [
          {
            subdistrict_id: "2006",
            subdistrict_name: "Bakti Raja",
          },
          {
            subdistrict_id: "2007",
            subdistrict_name: "Dolok Sanggul",
          },
          {
            subdistrict_id: "2008",
            subdistrict_name: "Lintong Nihuta",
          },
          {
            subdistrict_id: "2009",
            subdistrict_name: "Onan Ganjang",
          },
          {
            subdistrict_id: "2010",
            subdistrict_name: "Pakkat",
          },
          {
            subdistrict_id: "2011",
            subdistrict_name: "Paranginan",
          },
          {
            subdistrict_id: "2012",
            subdistrict_name: "Parlilitan",
          },
          {
            subdistrict_id: "2013",
            subdistrict_name: "Pollung",
          },
          {
            subdistrict_id: "2014",
            subdistrict_name: "Sijama Polang",
          },
          {
            subdistrict_id: "2015",
            subdistrict_name: "Tara Bintang",
          },
        ],
      },
      {
        city_id: "173",
        type: "Kabupaten",
        city_name: "Karo",
        subdistricts: [
          {
            subdistrict_id: "2420",
            subdistrict_name: "Barus Jahe",
          },
          {
            subdistrict_id: "2421",
            subdistrict_name: "Brastagi (Berastagi)",
          },
          {
            subdistrict_id: "2422",
            subdistrict_name: "Dolat Rayat",
          },
          {
            subdistrict_id: "2423",
            subdistrict_name: "Juhar",
          },
          {
            subdistrict_id: "2424",
            subdistrict_name: "Kabanjahe",
          },
          {
            subdistrict_id: "2425",
            subdistrict_name: "Kuta Buluh",
          },
          {
            subdistrict_id: "2426",
            subdistrict_name: "Laubaleng",
          },
          {
            subdistrict_id: "2427",
            subdistrict_name: "Mardinding",
          },
          {
            subdistrict_id: "2428",
            subdistrict_name: "Merdeka",
          },
          {
            subdistrict_id: "2429",
            subdistrict_name: "Merek",
          },
          {
            subdistrict_id: "2430",
            subdistrict_name: "Munte",
          },
          {
            subdistrict_id: "2431",
            subdistrict_name: "Nama Teran",
          },
          {
            subdistrict_id: "2432",
            subdistrict_name: "Payung",
          },
          {
            subdistrict_id: "2433",
            subdistrict_name: "Simpang Empat",
          },
          {
            subdistrict_id: "2434",
            subdistrict_name: "Tiga Binanga",
          },
          {
            subdistrict_id: "2435",
            subdistrict_name: "Tiga Panah",
          },
          {
            subdistrict_id: "2436",
            subdistrict_name: "Tiganderket",
          },
        ],
      },
      {
        city_id: "217",
        type: "Kabupaten",
        city_name: "Labuhan Batu",
        subdistricts: [
          {
            subdistrict_id: "3061",
            subdistrict_name: "Bilah Barat",
          },
          {
            subdistrict_id: "3062",
            subdistrict_name: "Bilah Hilir",
          },
          {
            subdistrict_id: "3063",
            subdistrict_name: "Bilah Hulu",
          },
          {
            subdistrict_id: "3064",
            subdistrict_name: "Panai Hilir",
          },
          {
            subdistrict_id: "3065",
            subdistrict_name: "Panai Hulu",
          },
          {
            subdistrict_id: "3066",
            subdistrict_name: "Panai Tengah",
          },
          {
            subdistrict_id: "3067",
            subdistrict_name: "Pangkatan",
          },
          {
            subdistrict_id: "3068",
            subdistrict_name: "Rantau Selatan",
          },
          {
            subdistrict_id: "3069",
            subdistrict_name: "Rantau Utara",
          },
        ],
      },
      {
        city_id: "218",
        type: "Kabupaten",
        city_name: "Labuhan Batu Selatan",
        subdistricts: [
          {
            subdistrict_id: "3070",
            subdistrict_name: "Kampung Rakyat",
          },
          {
            subdistrict_id: "3071",
            subdistrict_name: "Kota Pinang",
          },
          {
            subdistrict_id: "3072",
            subdistrict_name: "Sei/Sungai Kanan",
          },
          {
            subdistrict_id: "3073",
            subdistrict_name: "Silangkitang",
          },
          {
            subdistrict_id: "3074",
            subdistrict_name: "Torgamba",
          },
        ],
      },
      {
        city_id: "219",
        type: "Kabupaten",
        city_name: "Labuhan Batu Utara",
        subdistricts: [
          {
            subdistrict_id: "3075",
            subdistrict_name: "Aek Kuo",
          },
          {
            subdistrict_id: "3076",
            subdistrict_name: "Aek Natas",
          },
          {
            subdistrict_id: "3077",
            subdistrict_name: "Kuala Ledong (Kualuh Leidong)",
          },
          {
            subdistrict_id: "3078",
            subdistrict_name: "Kualuh Hilir",
          },
          {
            subdistrict_id: "3079",
            subdistrict_name: "Kualuh Hulu",
          },
          {
            subdistrict_id: "3080",
            subdistrict_name: "Kualuh Selatan",
          },
          {
            subdistrict_id: "3081",
            subdistrict_name: "Marbau",
          },
          {
            subdistrict_id: "3082",
            subdistrict_name: "Na IX-X",
          },
        ],
      },
      {
        city_id: "229",
        type: "Kabupaten",
        city_name: "Langkat",
        subdistricts: [
          {
            subdistrict_id: "3260",
            subdistrict_name: "Babalan",
          },
          {
            subdistrict_id: "3261",
            subdistrict_name: "Bahorok",
          },
          {
            subdistrict_id: "3262",
            subdistrict_name: "Batang Serangan",
          },
          {
            subdistrict_id: "3263",
            subdistrict_name: "Besitang",
          },
          {
            subdistrict_id: "3264",
            subdistrict_name: "Binjai",
          },
          {
            subdistrict_id: "3265",
            subdistrict_name: "Brandan Barat",
          },
          {
            subdistrict_id: "3266",
            subdistrict_name: "Gebang",
          },
          {
            subdistrict_id: "3267",
            subdistrict_name: "Hinai",
          },
          {
            subdistrict_id: "3268",
            subdistrict_name: "Kuala",
          },
          {
            subdistrict_id: "3269",
            subdistrict_name: "Kutambaru",
          },
          {
            subdistrict_id: "3270",
            subdistrict_name: "Padang Tualang",
          },
          {
            subdistrict_id: "3271",
            subdistrict_name: "Pangkalan Susu",
          },
          {
            subdistrict_id: "3272",
            subdistrict_name: "Pematang Jaya",
          },
          {
            subdistrict_id: "3273",
            subdistrict_name: "Salapian",
          },
          {
            subdistrict_id: "3274",
            subdistrict_name: "Sawit Seberang",
          },
          {
            subdistrict_id: "3275",
            subdistrict_name: "Secanggang",
          },
          {
            subdistrict_id: "3276",
            subdistrict_name: "Sei Binge (Bingai)",
          },
          {
            subdistrict_id: "3277",
            subdistrict_name: "Sei Lepan",
          },
          {
            subdistrict_id: "3278",
            subdistrict_name: "Selesai",
          },
          {
            subdistrict_id: "3279",
            subdistrict_name: "Sirapit (Serapit)",
          },
          {
            subdistrict_id: "3280",
            subdistrict_name: "Stabat",
          },
          {
            subdistrict_id: "3281",
            subdistrict_name: "Tanjungpura",
          },
          {
            subdistrict_id: "3282",
            subdistrict_name: "Wampu",
          },
        ],
      },
      {
        city_id: "268",
        type: "Kabupaten",
        city_name: "Mandailing Natal",
        subdistricts: [
          {
            subdistrict_id: "3779",
            subdistrict_name: "Batahan",
          },
          {
            subdistrict_id: "3780",
            subdistrict_name: "Batang Natal",
          },
          {
            subdistrict_id: "3781",
            subdistrict_name: "Bukit Malintang",
          },
          {
            subdistrict_id: "3782",
            subdistrict_name: "Huta Bargot",
          },
          {
            subdistrict_id: "3783",
            subdistrict_name: "Kotanopan",
          },
          {
            subdistrict_id: "3784",
            subdistrict_name: "Langga Bayu (Lingga Bayu)",
          },
          {
            subdistrict_id: "3785",
            subdistrict_name: "Lembah Sorik Merapi",
          },
          {
            subdistrict_id: "3786",
            subdistrict_name: "Muara Batang Gadis",
          },
          {
            subdistrict_id: "3787",
            subdistrict_name: "Muara Sipongi",
          },
          {
            subdistrict_id: "3788",
            subdistrict_name: "Naga Juang",
          },
          {
            subdistrict_id: "3789",
            subdistrict_name: "Natal",
          },
          {
            subdistrict_id: "3790",
            subdistrict_name: "Pakantan",
          },
          {
            subdistrict_id: "3791",
            subdistrict_name: "Panyabungan Barat",
          },
          {
            subdistrict_id: "3792",
            subdistrict_name: "Panyabungan Kota",
          },
          {
            subdistrict_id: "3793",
            subdistrict_name: "Panyabungan Selatan",
          },
          {
            subdistrict_id: "3794",
            subdistrict_name: "Panyabungan Timur",
          },
          {
            subdistrict_id: "3795",
            subdistrict_name: "Panyabungan Utara",
          },
          {
            subdistrict_id: "3796",
            subdistrict_name: "Puncak Sorik Marapi/Merapi",
          },
          {
            subdistrict_id: "3797",
            subdistrict_name: "Ranto Baek/Baik",
          },
          {
            subdistrict_id: "3798",
            subdistrict_name: "Siabu",
          },
          {
            subdistrict_id: "3799",
            subdistrict_name: "Sinunukan",
          },
          {
            subdistrict_id: "3800",
            subdistrict_name: "Tambangan",
          },
          {
            subdistrict_id: "3801",
            subdistrict_name: "Ulu Pungkut",
          },
        ],
      },
      {
        city_id: "278",
        type: "Kota",
        city_name: "Medan",
        subdistricts: [
          {
            subdistrict_id: "3906",
            subdistrict_name: "Medan Amplas",
          },
          {
            subdistrict_id: "3907",
            subdistrict_name: "Medan Area",
          },
          {
            subdistrict_id: "3908",
            subdistrict_name: "Medan Barat",
          },
          {
            subdistrict_id: "3909",
            subdistrict_name: "Medan Baru",
          },
          {
            subdistrict_id: "3910",
            subdistrict_name: "Medan Belawan Kota",
          },
          {
            subdistrict_id: "3911",
            subdistrict_name: "Medan Deli",
          },
          {
            subdistrict_id: "3912",
            subdistrict_name: "Medan Denai",
          },
          {
            subdistrict_id: "3913",
            subdistrict_name: "Medan Helvetia",
          },
          {
            subdistrict_id: "3914",
            subdistrict_name: "Medan Johor",
          },
          {
            subdistrict_id: "3915",
            subdistrict_name: "Medan Kota",
          },
          {
            subdistrict_id: "3916",
            subdistrict_name: "Medan Labuhan",
          },
          {
            subdistrict_id: "3917",
            subdistrict_name: "Medan Maimun",
          },
          {
            subdistrict_id: "3918",
            subdistrict_name: "Medan Marelan",
          },
          {
            subdistrict_id: "3919",
            subdistrict_name: "Medan Perjuangan",
          },
          {
            subdistrict_id: "3920",
            subdistrict_name: "Medan Petisah",
          },
          {
            subdistrict_id: "3921",
            subdistrict_name: "Medan Polonia",
          },
          {
            subdistrict_id: "3922",
            subdistrict_name: "Medan Selayang",
          },
          {
            subdistrict_id: "3923",
            subdistrict_name: "Medan Sunggal",
          },
          {
            subdistrict_id: "3924",
            subdistrict_name: "Medan Tembung",
          },
          {
            subdistrict_id: "3925",
            subdistrict_name: "Medan Timur",
          },
          {
            subdistrict_id: "3926",
            subdistrict_name: "Medan Tuntungan",
          },
        ],
      },
      {
        city_id: "307",
        type: "Kabupaten",
        city_name: "Nias",
        subdistricts: [
          {
            subdistrict_id: "4362",
            subdistrict_name: "Bawolato",
          },
          {
            subdistrict_id: "4363",
            subdistrict_name: "Botomuzoi",
          },
          {
            subdistrict_id: "4364",
            subdistrict_name: "Gido",
          },
          {
            subdistrict_id: "4365",
            subdistrict_name: "Hili Serangkai (Hilisaranggu)",
          },
          {
            subdistrict_id: "4366",
            subdistrict_name: "Hiliduho",
          },
          {
            subdistrict_id: "4367",
            subdistrict_name: "Idano Gawo",
          },
          {
            subdistrict_id: "4368",
            subdistrict_name: "Ma'u",
          },
          {
            subdistrict_id: "4369",
            subdistrict_name: "Sogae Adu (Sogaeadu)",
          },
          {
            subdistrict_id: "4370",
            subdistrict_name: "Somolo-Molo (Samolo)",
          },
          {
            subdistrict_id: "4371",
            subdistrict_name: "Ulugawo",
          },
        ],
      },
      {
        city_id: "308",
        type: "Kabupaten",
        city_name: "Nias Barat",
        subdistricts: [
          {
            subdistrict_id: "4372",
            subdistrict_name: "Lahomi (Gahori)",
          },
          {
            subdistrict_id: "4373",
            subdistrict_name: "Lolofitu Moi",
          },
          {
            subdistrict_id: "4374",
            subdistrict_name: "Mandrehe",
          },
          {
            subdistrict_id: "4375",
            subdistrict_name: "Mandrehe Barat",
          },
          {
            subdistrict_id: "4376",
            subdistrict_name: "Mandrehe Utara",
          },
          {
            subdistrict_id: "4377",
            subdistrict_name: "Moro'o",
          },
          {
            subdistrict_id: "4378",
            subdistrict_name: "Sirombu",
          },
          {
            subdistrict_id: "4379",
            subdistrict_name: "Ulu Moro'o (Ulu Narwo)",
          },
        ],
      },
      {
        city_id: "309",
        type: "Kabupaten",
        city_name: "Nias Selatan",
        subdistricts: [
          {
            subdistrict_id: "4380",
            subdistrict_name: "Amandraya",
          },
          {
            subdistrict_id: "4381",
            subdistrict_name: "Aramo",
          },
          {
            subdistrict_id: "4382",
            subdistrict_name: "Boronadu",
          },
          {
            subdistrict_id: "4383",
            subdistrict_name: "Fanayama",
          },
          {
            subdistrict_id: "4384",
            subdistrict_name: "Gomo",
          },
          {
            subdistrict_id: "4385",
            subdistrict_name: "Hibala",
          },
          {
            subdistrict_id: "4386",
            subdistrict_name: "Hilimegai",
          },
          {
            subdistrict_id: "4387",
            subdistrict_name: "Hilisalawa'ahe (Hilisalawaahe)",
          },
          {
            subdistrict_id: "4388",
            subdistrict_name: "Huruna",
          },
          {
            subdistrict_id: "4389",
            subdistrict_name: "Lahusa",
          },
          {
            subdistrict_id: "4390",
            subdistrict_name: "Lolomatua",
          },
          {
            subdistrict_id: "4391",
            subdistrict_name: "Lolowau",
          },
          {
            subdistrict_id: "4392",
            subdistrict_name: "Maniamolo",
          },
          {
            subdistrict_id: "4393",
            subdistrict_name: "Mazino",
          },
          {
            subdistrict_id: "4394",
            subdistrict_name: "Mazo",
          },
          {
            subdistrict_id: "4395",
            subdistrict_name: "O'o'u (Oou)",
          },
          {
            subdistrict_id: "4396",
            subdistrict_name: "Onohazumba",
          },
          {
            subdistrict_id: "4397",
            subdistrict_name: "Pulau-Pulau Batu",
          },
          {
            subdistrict_id: "4398",
            subdistrict_name: "Pulau-Pulau Batu Barat",
          },
          {
            subdistrict_id: "4399",
            subdistrict_name: "Pulau-Pulau Batu Timur",
          },
          {
            subdistrict_id: "4400",
            subdistrict_name: "Pulau-Pulau Batu Utara",
          },
          {
            subdistrict_id: "4401",
            subdistrict_name: "Sidua'ori",
          },
          {
            subdistrict_id: "4402",
            subdistrict_name: "Simuk",
          },
          {
            subdistrict_id: "4403",
            subdistrict_name: "Somambawa",
          },
          {
            subdistrict_id: "4404",
            subdistrict_name: "Susua",
          },
          {
            subdistrict_id: "4405",
            subdistrict_name: "Tanah Masa",
          },
          {
            subdistrict_id: "4406",
            subdistrict_name: "Teluk Dalam",
          },
          {
            subdistrict_id: "4407",
            subdistrict_name: "Toma",
          },
          {
            subdistrict_id: "4408",
            subdistrict_name: "Ulunoyo",
          },
          {
            subdistrict_id: "4409",
            subdistrict_name: "Ulususua",
          },
          {
            subdistrict_id: "4410",
            subdistrict_name: "Umbunasi",
          },
        ],
      },
      {
        city_id: "310",
        type: "Kabupaten",
        city_name: "Nias Utara",
        subdistricts: [
          {
            subdistrict_id: "4411",
            subdistrict_name: "Afulu",
          },
          {
            subdistrict_id: "4412",
            subdistrict_name: "Alasa",
          },
          {
            subdistrict_id: "4413",
            subdistrict_name: "Alasa Talumuzoi",
          },
          {
            subdistrict_id: "4414",
            subdistrict_name: "Lahewa",
          },
          {
            subdistrict_id: "4415",
            subdistrict_name: "Lahewa Timur",
          },
          {
            subdistrict_id: "4416",
            subdistrict_name: "Lotu",
          },
          {
            subdistrict_id: "4417",
            subdistrict_name: "Namohalu Esiwa",
          },
          {
            subdistrict_id: "4418",
            subdistrict_name: "Sawo",
          },
          {
            subdistrict_id: "4419",
            subdistrict_name: "Sitolu Ori",
          },
          {
            subdistrict_id: "4420",
            subdistrict_name: "Tugala Oyo",
          },
          {
            subdistrict_id: "4421",
            subdistrict_name: "Tuhemberua",
          },
        ],
      },
      {
        city_id: "319",
        type: "Kabupaten",
        city_name: "Padang Lawas",
        subdistricts: [
          {
            subdistrict_id: "4545",
            subdistrict_name: "Aek Nabara Barumun",
          },
          {
            subdistrict_id: "4546",
            subdistrict_name: "Barumun",
          },
          {
            subdistrict_id: "4547",
            subdistrict_name: "Barumun Selatan",
          },
          {
            subdistrict_id: "4548",
            subdistrict_name: "Barumun Tengah",
          },
          {
            subdistrict_id: "4549",
            subdistrict_name: "Batang Lubu Sutam",
          },
          {
            subdistrict_id: "4550",
            subdistrict_name: "Huristak",
          },
          {
            subdistrict_id: "4551",
            subdistrict_name: "Huta Raja Tinggi",
          },
          {
            subdistrict_id: "4552",
            subdistrict_name: "Lubuk Barumun",
          },
          {
            subdistrict_id: "4553",
            subdistrict_name: "Sihapas Barumun",
          },
          {
            subdistrict_id: "4554",
            subdistrict_name: "Sosa",
          },
          {
            subdistrict_id: "4555",
            subdistrict_name: "Sosopan",
          },
          {
            subdistrict_id: "4556",
            subdistrict_name: "Ulu Barumun",
          },
        ],
      },
      {
        city_id: "320",
        type: "Kabupaten",
        city_name: "Padang Lawas Utara",
        subdistricts: [
          {
            subdistrict_id: "4557",
            subdistrict_name: "Batang Onang",
          },
          {
            subdistrict_id: "4558",
            subdistrict_name: "Dolok",
          },
          {
            subdistrict_id: "4559",
            subdistrict_name: "Dolok Sigompulon",
          },
          {
            subdistrict_id: "4560",
            subdistrict_name: "Halongonan",
          },
          {
            subdistrict_id: "4561",
            subdistrict_name: "Hulu Sihapas",
          },
          {
            subdistrict_id: "4562",
            subdistrict_name: "Padang Bolak",
          },
          {
            subdistrict_id: "4563",
            subdistrict_name: "Padang Bolak Julu",
          },
          {
            subdistrict_id: "4564",
            subdistrict_name: "Portibi",
          },
          {
            subdistrict_id: "4565",
            subdistrict_name: "Simangambat",
          },
        ],
      },
      {
        city_id: "323",
        type: "Kota",
        city_name: "Padang Sidempuan",
        subdistricts: [
          {
            subdistrict_id: "4585",
            subdistrict_name: "Padang Sidempuan Angkola Julu",
          },
          {
            subdistrict_id: "4586",
            subdistrict_name: "Padang Sidempuan Batunadua",
          },
          {
            subdistrict_id: "4587",
            subdistrict_name: "Padang Sidempuan Hutaimbaru",
          },
          {
            subdistrict_id: "4588",
            subdistrict_name: "Padang Sidempuan Selatan",
          },
          {
            subdistrict_id: "4589",
            subdistrict_name: "Padang Sidempuan Tenggara",
          },
          {
            subdistrict_id: "4590",
            subdistrict_name: "Padang Sidempuan Utara (Padangsidimpuan)",
          },
        ],
      },
      {
        city_id: "325",
        type: "Kabupaten",
        city_name: "Pakpak Bharat",
        subdistricts: [
          {
            subdistrict_id: "4596",
            subdistrict_name: "Kerajaan",
          },
          {
            subdistrict_id: "4597",
            subdistrict_name: "Pagindar",
          },
          {
            subdistrict_id: "4598",
            subdistrict_name: "Pergetteng Getteng Sengkut",
          },
          {
            subdistrict_id: "4599",
            subdistrict_name: "Salak",
          },
          {
            subdistrict_id: "4600",
            subdistrict_name: "Siempat Rube",
          },
          {
            subdistrict_id: "4601",
            subdistrict_name: "Sitellu Tali Urang Jehe",
          },
          {
            subdistrict_id: "4602",
            subdistrict_name: "Sitellu Tali Urang Julu",
          },
          {
            subdistrict_id: "4603",
            subdistrict_name: "Tinada",
          },
        ],
      },
      {
        city_id: "353",
        type: "Kota",
        city_name: "Pematang Siantar",
        subdistricts: [
          {
            subdistrict_id: "4952",
            subdistrict_name: "Siantar Barat",
          },
          {
            subdistrict_id: "4953",
            subdistrict_name: "Siantar Marihat",
          },
          {
            subdistrict_id: "4954",
            subdistrict_name: "Siantar Marimbun",
          },
          {
            subdistrict_id: "4955",
            subdistrict_name: "Siantar Martoba",
          },
          {
            subdistrict_id: "4956",
            subdistrict_name: "Siantar Selatan",
          },
          {
            subdistrict_id: "4957",
            subdistrict_name: "Siantar Sitalasari",
          },
          {
            subdistrict_id: "4958",
            subdistrict_name: "Siantar Timur",
          },
          {
            subdistrict_id: "4959",
            subdistrict_name: "Siantar Utara",
          },
        ],
      },
      {
        city_id: "389",
        type: "Kabupaten",
        city_name: "Samosir",
        subdistricts: [
          {
            subdistrict_id: "5385",
            subdistrict_name: "Harian",
          },
          {
            subdistrict_id: "5386",
            subdistrict_name: "Nainggolan",
          },
          {
            subdistrict_id: "5387",
            subdistrict_name: "Onan Runggu",
          },
          {
            subdistrict_id: "5388",
            subdistrict_name: "Palipi",
          },
          {
            subdistrict_id: "5389",
            subdistrict_name: "Pangururan",
          },
          {
            subdistrict_id: "5390",
            subdistrict_name: "Ronggur Nihuta",
          },
          {
            subdistrict_id: "5391",
            subdistrict_name: "Sianjur Mula-Mula",
          },
          {
            subdistrict_id: "5392",
            subdistrict_name: "Simanindo",
          },
          {
            subdistrict_id: "5393",
            subdistrict_name: "Sitio-Tio",
          },
        ],
      },
      {
        city_id: "404",
        type: "Kabupaten",
        city_name: "Serdang Bedagai",
        subdistricts: [
          {
            subdistrict_id: "5575",
            subdistrict_name: "Bandar Khalifah",
          },
          {
            subdistrict_id: "5576",
            subdistrict_name: "Bintang Bayu",
          },
          {
            subdistrict_id: "5577",
            subdistrict_name: "Dolok Masihul",
          },
          {
            subdistrict_id: "5578",
            subdistrict_name: "Dolok Merawan",
          },
          {
            subdistrict_id: "5579",
            subdistrict_name: "Kotarih",
          },
          {
            subdistrict_id: "5580",
            subdistrict_name: "Pantai Cermin",
          },
          {
            subdistrict_id: "5581",
            subdistrict_name: "Pegajahan",
          },
          {
            subdistrict_id: "5582",
            subdistrict_name: "Perbaungan",
          },
          {
            subdistrict_id: "5583",
            subdistrict_name: "Sei Bamban",
          },
          {
            subdistrict_id: "5584",
            subdistrict_name: "Sei Rampah",
          },
          {
            subdistrict_id: "5585",
            subdistrict_name: "Serba Jadi",
          },
          {
            subdistrict_id: "5586",
            subdistrict_name: "Silinda",
          },
          {
            subdistrict_id: "5587",
            subdistrict_name: "Sipispis",
          },
          {
            subdistrict_id: "5588",
            subdistrict_name: "Tanjung Beringin",
          },
          {
            subdistrict_id: "5589",
            subdistrict_name: "Tebing Syahbandar",
          },
          {
            subdistrict_id: "5590",
            subdistrict_name: "Tebing Tinggi",
          },
          {
            subdistrict_id: "5591",
            subdistrict_name: "Teluk Mengkudu",
          },
        ],
      },
      {
        city_id: "407",
        type: "Kota",
        city_name: "Sibolga",
        subdistricts: [
          {
            subdistrict_id: "5616",
            subdistrict_name: "Sibolga Kota",
          },
          {
            subdistrict_id: "5617",
            subdistrict_name: "Sibolga Sambas",
          },
          {
            subdistrict_id: "5618",
            subdistrict_name: "Sibolga Selatan",
          },
          {
            subdistrict_id: "5619",
            subdistrict_name: "Sibolga Utara",
          },
        ],
      },
      {
        city_id: "413",
        type: "Kabupaten",
        city_name: "Simalungun",
        subdistricts: [
          {
            subdistrict_id: "5693",
            subdistrict_name: "Bandar",
          },
          {
            subdistrict_id: "5694",
            subdistrict_name: "Bandar Huluan",
          },
          {
            subdistrict_id: "5695",
            subdistrict_name: "Bandar Masilam",
          },
          {
            subdistrict_id: "5696",
            subdistrict_name: "Bosar Maligas",
          },
          {
            subdistrict_id: "5697",
            subdistrict_name: "Dolok Batu Nanggar",
          },
          {
            subdistrict_id: "5698",
            subdistrict_name: "Dolok Panribuan",
          },
          {
            subdistrict_id: "5699",
            subdistrict_name: "Dolok Pardamean",
          },
          {
            subdistrict_id: "5700",
            subdistrict_name: "Dolok Silau",
          },
          {
            subdistrict_id: "5701",
            subdistrict_name: "Girsang Sipangan Bolon",
          },
          {
            subdistrict_id: "5702",
            subdistrict_name: "Gunung Malela",
          },
          {
            subdistrict_id: "5703",
            subdistrict_name: "Gunung Maligas",
          },
          {
            subdistrict_id: "5704",
            subdistrict_name: "Haranggaol Horison",
          },
          {
            subdistrict_id: "5705",
            subdistrict_name: "Hatonduhan",
          },
          {
            subdistrict_id: "5706",
            subdistrict_name: "Huta Bayu Raja",
          },
          {
            subdistrict_id: "5707",
            subdistrict_name: "Jawa Maraja Bah Jambi",
          },
          {
            subdistrict_id: "5708",
            subdistrict_name: "Jorlang Hataran",
          },
          {
            subdistrict_id: "5709",
            subdistrict_name: "Panei",
          },
          {
            subdistrict_id: "5710",
            subdistrict_name: "Panombeian Panei",
          },
          {
            subdistrict_id: "5711",
            subdistrict_name: "Pematang Bandar",
          },
          {
            subdistrict_id: "5712",
            subdistrict_name: "Pematang Sidamanik",
          },
          {
            subdistrict_id: "5713",
            subdistrict_name: "Pematang Silima Huta",
          },
          {
            subdistrict_id: "5714",
            subdistrict_name: "Purba",
          },
          {
            subdistrict_id: "5715",
            subdistrict_name: "Raya",
          },
          {
            subdistrict_id: "5716",
            subdistrict_name: "Raya Kahean",
          },
          {
            subdistrict_id: "5717",
            subdistrict_name: "Siantar",
          },
          {
            subdistrict_id: "5718",
            subdistrict_name: "Sidamanik",
          },
          {
            subdistrict_id: "5719",
            subdistrict_name: "Silimakuta",
          },
          {
            subdistrict_id: "5720",
            subdistrict_name: "Silou Kahean",
          },
          {
            subdistrict_id: "5721",
            subdistrict_name: "Tanah Jawa",
          },
          {
            subdistrict_id: "5722",
            subdistrict_name: "Tapian Dolok",
          },
          {
            subdistrict_id: "5723",
            subdistrict_name: "Ujung Padang",
          },
        ],
      },
      {
        city_id: "459",
        type: "Kota",
        city_name: "Tanjung Balai",
        subdistricts: [
          {
            subdistrict_id: "6337",
            subdistrict_name: "Datuk Bandar",
          },
          {
            subdistrict_id: "6338",
            subdistrict_name: "Datuk Bandar Timur",
          },
          {
            subdistrict_id: "6339",
            subdistrict_name: "Sei Tualang Raso",
          },
          {
            subdistrict_id: "6340",
            subdistrict_name: "Tanjung Balai Selatan",
          },
          {
            subdistrict_id: "6341",
            subdistrict_name: "Tanjung Balai Utara",
          },
          {
            subdistrict_id: "6342",
            subdistrict_name: "Teluk Nibung",
          },
        ],
      },
      {
        city_id: "463",
        type: "Kabupaten",
        city_name: "Tapanuli Selatan",
        subdistricts: [
          {
            subdistrict_id: "6371",
            subdistrict_name: "Aek Bilah",
          },
          {
            subdistrict_id: "6372",
            subdistrict_name: "Angkola Barat (Padang Sidempuan)",
          },
          {
            subdistrict_id: "6373",
            subdistrict_name: "Angkola Sangkunur",
          },
          {
            subdistrict_id: "6374",
            subdistrict_name: "Angkola Selatan (Siais)",
          },
          {
            subdistrict_id: "6375",
            subdistrict_name: "Angkola Timur (Padang Sidempuan)",
          },
          {
            subdistrict_id: "6376",
            subdistrict_name: "Arse",
          },
          {
            subdistrict_id: "6377",
            subdistrict_name: "Batang Angkola",
          },
          {
            subdistrict_id: "6378",
            subdistrict_name: "Batang Toru",
          },
          {
            subdistrict_id: "6379",
            subdistrict_name: "Marancar",
          },
          {
            subdistrict_id: "6380",
            subdistrict_name: "Muara Batang Toru",
          },
          {
            subdistrict_id: "6381",
            subdistrict_name: "Saipar Dolok Hole",
          },
          {
            subdistrict_id: "6382",
            subdistrict_name: "Sayur Matinggi",
          },
          {
            subdistrict_id: "6383",
            subdistrict_name: "Sipirok",
          },
          {
            subdistrict_id: "6384",
            subdistrict_name: "Tano Tombangan Angkola",
          },
        ],
      },
      {
        city_id: "464",
        type: "Kabupaten",
        city_name: "Tapanuli Tengah",
        subdistricts: [
          {
            subdistrict_id: "6385",
            subdistrict_name: "Andam Dewi",
          },
          {
            subdistrict_id: "6386",
            subdistrict_name: "Badiri",
          },
          {
            subdistrict_id: "6387",
            subdistrict_name: "Barus",
          },
          {
            subdistrict_id: "6388",
            subdistrict_name: "Barus Utara",
          },
          {
            subdistrict_id: "6389",
            subdistrict_name: "Kolang",
          },
          {
            subdistrict_id: "6390",
            subdistrict_name: "Lumut",
          },
          {
            subdistrict_id: "6391",
            subdistrict_name: "Manduamas",
          },
          {
            subdistrict_id: "6392",
            subdistrict_name: "Pandan",
          },
          {
            subdistrict_id: "6393",
            subdistrict_name: "Pasaribu Tobing",
          },
          {
            subdistrict_id: "6394",
            subdistrict_name: "Pinangsori",
          },
          {
            subdistrict_id: "6395",
            subdistrict_name: "Sarudik",
          },
          {
            subdistrict_id: "6396",
            subdistrict_name: "Sibabangun",
          },
          {
            subdistrict_id: "6397",
            subdistrict_name: "Sirandorung",
          },
          {
            subdistrict_id: "6398",
            subdistrict_name: "Sitahuis",
          },
          {
            subdistrict_id: "6399",
            subdistrict_name: "Sorkam",
          },
          {
            subdistrict_id: "6400",
            subdistrict_name: "Sorkam Barat",
          },
          {
            subdistrict_id: "6401",
            subdistrict_name: "Sosor Gadong",
          },
          {
            subdistrict_id: "6402",
            subdistrict_name: "Suka Bangun",
          },
          {
            subdistrict_id: "6403",
            subdistrict_name: "Tapian Nauli",
          },
          {
            subdistrict_id: "6404",
            subdistrict_name: "Tukka",
          },
        ],
      },
      {
        city_id: "465",
        type: "Kabupaten",
        city_name: "Tapanuli Utara",
        subdistricts: [
          {
            subdistrict_id: "6405",
            subdistrict_name: "Adian Koting",
          },
          {
            subdistrict_id: "6406",
            subdistrict_name: "Garoga",
          },
          {
            subdistrict_id: "6407",
            subdistrict_name: "Muara",
          },
          {
            subdistrict_id: "6408",
            subdistrict_name: "Pagaran",
          },
          {
            subdistrict_id: "6409",
            subdistrict_name: "Pahae Jae",
          },
          {
            subdistrict_id: "6410",
            subdistrict_name: "Pahae Julu",
          },
          {
            subdistrict_id: "6411",
            subdistrict_name: "Pangaribuan",
          },
          {
            subdistrict_id: "6412",
            subdistrict_name: "Parmonangan",
          },
          {
            subdistrict_id: "6413",
            subdistrict_name: "Purbatua",
          },
          {
            subdistrict_id: "6414",
            subdistrict_name: "Siatas Barita",
          },
          {
            subdistrict_id: "6415",
            subdistrict_name: "Siborong-Borong",
          },
          {
            subdistrict_id: "6416",
            subdistrict_name: "Simangumban",
          },
          {
            subdistrict_id: "6417",
            subdistrict_name: "Sipahutar",
          },
          {
            subdistrict_id: "6418",
            subdistrict_name: "Sipoholon",
          },
          {
            subdistrict_id: "6419",
            subdistrict_name: "Tarutung",
          },
        ],
      },
      {
        city_id: "470",
        type: "Kota",
        city_name: "Tebing Tinggi",
        subdistricts: [
          {
            subdistrict_id: "6485",
            subdistrict_name: "Bajenis",
          },
          {
            subdistrict_id: "6486",
            subdistrict_name: "Padang Hilir",
          },
          {
            subdistrict_id: "6487",
            subdistrict_name: "Padang Hulu",
          },
          {
            subdistrict_id: "6488",
            subdistrict_name: "Rambutan",
          },
          {
            subdistrict_id: "6489",
            subdistrict_name: "Tebing Tinggi Kota",
          },
        ],
      },
      {
        city_id: "481",
        type: "Kabupaten",
        city_name: "Toba Samosir",
        subdistricts: [
          {
            subdistrict_id: "6652",
            subdistrict_name: "Ajibata",
          },
          {
            subdistrict_id: "6653",
            subdistrict_name: "Balige",
          },
          {
            subdistrict_id: "6654",
            subdistrict_name: "Bonatua Lunasi",
          },
          {
            subdistrict_id: "6655",
            subdistrict_name: "Bor-Bor",
          },
          {
            subdistrict_id: "6656",
            subdistrict_name: "Habinsaran",
          },
          {
            subdistrict_id: "6657",
            subdistrict_name: "Laguboti",
          },
          {
            subdistrict_id: "6658",
            subdistrict_name: "Lumban Julu",
          },
          {
            subdistrict_id: "6659",
            subdistrict_name: "Nassau",
          },
          {
            subdistrict_id: "6660",
            subdistrict_name: "Parmaksian",
          },
          {
            subdistrict_id: "6661",
            subdistrict_name: "Pintu Pohan Meranti",
          },
          {
            subdistrict_id: "6662",
            subdistrict_name: "Porsea",
          },
          {
            subdistrict_id: "6663",
            subdistrict_name: "Siantar Narumonda",
          },
          {
            subdistrict_id: "6664",
            subdistrict_name: "Sigumpar",
          },
          {
            subdistrict_id: "6665",
            subdistrict_name: "Silaen",
          },
          {
            subdistrict_id: "6666",
            subdistrict_name: "Tampahan",
          },
          {
            subdistrict_id: "6667",
            subdistrict_name: "Uluan",
          },
        ],
      },
    ],
  },
];

export function cityIndex(cities, cityID) {
  return cities.findIndex((x)=> x.city_id === cityID)
}