import * as React from "react";
import { NavLink } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Selesai from "layouts/billing/components/Bill/kontenSelesai";
import { useGetKontenSelesai } from "context/content";
import { useState } from "react";
import { Alert, CircularProgress } from "@mui/material";

export default function KontenSelesai() {
  const { onProcess, loading, data, res } = useGetKontenSelesai();

  useState(() => {
    onProcess();
  }, [onProcess]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox id="delete-account">
        <MDBox mt={2}>
          <MDTypography variant="h5" fontWeight="medium">
            <MDButton
              variant="outlined"
              size="small"
              color="primary"
              component={NavLink}
              to="/dashboard"
              iconOnly
            >
              <ArrowBackIcon />
            </MDButton>
            &nbsp;&nbsp; Konten Selesai
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          width: "100%",
          mt: { md: 2 },
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          minHeight: { md: "68vh" },
          pb: 2,
        }}
      >
        {loading ? (
          <CircularProgress style={{ margin: 5 }} size={25} />
        ) : res.message || data.array?.length === 0 ? (
          <Alert severity={res.message ? "error" : "warning"}>{res.message || "No Data"}</Alert>
        ) : (
          data.array?.map((x) => <Selesai key={x[0]} bulan={x[0]} konten={x[1]} />)
        )}
      </MDBox>
    </DashboardLayout>
  );
}
