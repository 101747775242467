import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useEffect } from "react";
import { CircularProgress, Skeleton } from "@mui/material";
import { useGetRankingLKS } from "context/content";

const marks = [
  {
    value: 0,
    label: "Pemula",
  },
  {
    value: 125,
    label: "Pejuang",
  },
  {
    value: 250,
    label: "Kapten",
  },
  {
    value: 375,
    label: "Jendral",
  },
  {
    value: 495,
    label: "Pahlawan",
  },
];

function valuetext(value) {
  return `${value}`;
}

export default function RatingLks({ loading, data }) {
  return (
    <Box sx={{ width: "100%", minWidth: { xs: "70vw", sm: "50vw", md: "100%" } }}>
      {loading ? (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      ) : (
        <Slider
          disabled
          aria-valuetext="Always visible"
          getAriaValueText={valuetext}
          defaultValue={data.ranking}
          step={0.0001}
          marks={marks}
          max={500}
          sx={{
            fontSize: "8px",
            color: "rgba(0,0,0,0.87)",
            height: 4,
            "& .MuiSlider-thumb": {
              width: 16,
              height: 16,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0px 0px 0px 8px ${"rgb(0 0 0 / 16%)"}`,
              },
              "&.Mui-active": {
                width: 20,
                height: 20,
              },
            },
            "& .MuiSlider-rail": {
              opacity: 0.28,
              background: "#4a4644",
              height: "8px",
            },
            "& .MuiSlider-markLabel": {
              fontSize: { xs: "10px", sm: "16px", md: "1rem" },
            },
            "& .MuiSlider-mark": {
              height: "9px",
              width: "9px",
              borderRadius: "50px",
              background: "#a1a1a1",
              zIndex: 1,
              transform: 0,
            },
            "& .MuiSlider-track": {
              height: "8px",
              background: "#bd454f",
              border: "2px solid black",
            },
            "& .MuiSlider-markActive": {
              background: "#bd454f",
            },
            "span.MuiSlider-markActive[data-index='0']": {
              background: "white",
            },
            "span.MuiSlider-markActive[data-index='1']": {
              background: "yellow",
            },
            "span.MuiSlider-markActive[data-index='2']": {
              background: "green",
            },
            "span.MuiSlider-markActive[data-index='3']": {
              background: "blue",
            },
            "span.MuiSlider-markActive[data-index='4']": {
              background: "red",
            },
          }}
        />
      )}
    </Box>
  );
}
