import { Fragment, useEffect, useState } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//sweetalert2
import Swal from "sweetalert2";
import IconKirim from "assets/icon-oper.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Alert, Chip, Divider, IconButton, Snackbar } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { convertDate } from "helper/convertDate";
import { useActionContent } from "context/content";
import { useNavigate } from "react-router-dom";
import { useActionKirim } from "context/content";

function Bill({ kontenID, name, komisi, paid, notes, linkProduct, tanggal, cod, contohLink }) {
  const navigate = useNavigate();
  const { loading, onProcess } = useActionContent();
  const { loadingKirim, onProcessKirim } = useActionKirim();
  const [copySuccess, setCopySuccess] = useState(false);
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    const arr = linkProduct?.split(",") || [];
    setUrls(arr);
  }, [linkProduct]);

  return (
    <MDBox
      component="li"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mt={2}
      sx={{ overflowWrap: "anywhere" }}
    >
      <MDBox display="flex">
        <MDBox
          borderRadius="lg"
          bgColor={paid === true ? "#5fa776" : "#4a4644"}
          sx={{ pl: 2, pr: 2, pb: 2, pt: 1, mb: -2, boxShadow: "5" }}
        >
          <MDTypography
            fontWeight="medium"
            textTransform="capitalize"
            color="white"
            sx={{ fontSize: "16px" }}
          >
            {paid === true ? "Rp. 13.000,-" : "FREE"}
          </MDTypography>
        </MDBox>
        <MDBox
          bgColor="#bd454f"
          sx={{
            pl: 1,
            pr: 1,
            pb: "10px",
            mb: "-15px",
            mt: "10px",
            borderTopRightRadius: "8px",
            boxShadow: "5",
          }}
        >
          <MDTypography
            fontWeight="medium"
            textTransform="capitalize"
            color="white"
            sx={{ fontSize: "14px" }}
          >
            Komisi : {komisi}%
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        bgColor="grey-100"
        sx={{
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          borderBottomLeftRadius: "8px",
          boxShadow: "5",
        }}
      >
        <MDBox
          display="flex"
          // justifyContent="space-between"
          // alignItems="flex-start"
          flexDirection={{ xs: "column", md: "row" }}
          p={1}
        >
          <MDBox textAlign="end">
            {cod ? (
              <Chip
                label={"Bisa COD"}
                color={"success"}
                variant="outlined"
                size="small"
                sx={{ mr: 0.5 }}
              />
            ) : null}
            <Chip
              variant="filled"
              style={{
                fontWeight: "bolder",
                backgroundColor: "#eeeef",
                color: "black",
              }}
              size="small"
              label={kontenID}
            />
          </MDBox>
          <MDBox
            lineHeight={0}
            width="90px"
            minWidth="120px"
            alignSelf="center"
            textAlign={{ xs: "center", md: "start" }}
          >
            <MDTypography variant="h6" fontWeight="small" textTransform="capitalize">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{convertDate(tanggal)}</MDTypography>
          </MDBox>
          <MDBox mt={1} lineHeight={0}>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                Notes:&nbsp;&nbsp;&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" fontWeight="medium">
                {notes.length <= 0 ? "-" : notes}
              </MDTypography>
            </MDBox>
            <Divider />
            <MDTypography variant="caption" color="text">
              Link Product:&nbsp;&nbsp;&nbsp;
            </MDTypography>
            <br />
            <MDBox>
              {urls.map((url, index) => {
                return (
                  <MDTypography variant="caption" fontWeight="medium" key={index}>
                    <br />
                    {index + 1}{" "}
                    <a
                      href={/http/i.test(url) ? url : `https://${url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {url.length > 40 ? url.substring(0, 40) + "..." : url}
                    </a>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(url);
                        setCopySuccess(true);
                        setTimeout(() => {
                          setCopySuccess(false);
                        }, 3000);
                      }}
                    >
                      &nbsp;
                      <ContentCopy style={{ width: "0.9rem", height: "0.9rem" }} />
                    </IconButton>
                  </MDTypography>
                );
              })}
              <Snackbar anchorOrigin={{ horizontal: "center", vertical: "top" }} open={copySuccess}>
                <Alert severity={"success"}>Copied to Clipboard!</Alert>
              </Snackbar>
            </MDBox>
            <Divider />
            <MDTypography variant="caption" color="text">
              Contoh Video:&nbsp;&nbsp;&nbsp;
            </MDTypography>
            <br />
            <MDBox>
              <MDTypography variant="caption" fontWeight="medium">
                <br />
                {" - "}
                <a
                  href={/http/i.test(contohLink) ? contohLink : `https://${contohLink}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {contohLink?.length > 40 ? contohLink.substring(0, 40) + "..." : contohLink}
                </a>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          flexDirection={{ xs: "row", sm: "column" }}
          width={{ sm: "102px" }}
          height={{ xs: "60px", sm: "106px" }}
          alignSelf={{ sm: "end" }}
        >
          <MDBox
            sx={{
              width: { xs: "50%", sm: "100%" },
              height: { xs: "100%", sm: "50%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              borderTopRightRadius: { sm: "8px" },
              borderBottomLeftRadius: { xs: "8px", sm: "0px", md: "0px" },
            }}
            onClick={() => {
              // onProcessKirim(kontenID, linkProduct);
              // window.location.reload()
              Swal.fire({
                title: "Kamu Yakin?",
                text: "Jika kamu sibuk kamu bisa oper konten ke kreator lain!",
                imageUrl: IconKirim,
                imageWidth: 150,
                imageHeight: 45,
                showCancelButton: true,
                confirmButtonColor: "#bd454f",
                cancelButtonColor: "#a1a1a1",
                confirmButtonText: "Ya, Kirim",
              }).then((result) => {
                if (result.isConfirmed) {
                  onProcessKirim(kontenID, linkProduct);
                  if (loadingKirim) {
                    Swal.showLoading();
                  } else {
                    Swal.fire("Terkirim!", "Konten terkirim ke KOL lain.", "success");
                    navigate("/dashboard");
                  }
                }
              });

              // setTimeout(() => {
              //   window.location.reload();
              // }, 500);
            }}
          >
            <MDTypography variant="caption" color="dark" fontWeight="bold">
              KIRIM / OPER
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{
              minWidth: "102px",
              width: { xs: "50%", sm: "100%" },
              height: { xs: "100%", sm: "50%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
            onClick={() => {
              onProcess({
                method: "put",
                data: { approval_status: "accept" },
                hardContentId: kontenID,
              });
              navigate("/dashboard");
            }}
          >
            <MDBox
              sx={{
                width: "90%",
                height: "70%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                background: "#bd454f",
                borderRadius: "8px",
              }}
            >
              <MDTypography variant="caption" color="white" fontWeight="bold">
                TERIMA
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the Bill
Bill.propTypes = {
  kontenID: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  komisi: PropTypes.number.isRequired,
  notes: PropTypes.string.isRequired,
  linkProduct: PropTypes.string.isRequired,
  paid: PropTypes.bool.isRequired,
  tanggal: PropTypes.string.isRequired,
};

export default Bill;
