/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate, Outlet } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Typography, Container, Grid } from "@mui/material";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React routes
import routes from "routes";

// signin sign up
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import { UIAlertGlobal } from "components/spam";
import { AuthContext } from "context/utils/auth";
import { VerificationPage } from "layouts/authentication/verification";
import { ChangePasswordPage } from "layouts/authentication/change-password";
import KonsepVideo from "layouts/profile/konsepVideo";

import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import TawaranKonten from "examples/Lists/Konten/TawaranKonten";
import IsiLinkKonten from "examples/Lists/Konten/IsiLinkKonten";
import KontenSelesai from "examples/Lists/Konten/KontenSelesai";
import LKS2 from "examples/Lists/LKS/LKS2";
import LKSDibantu from "examples/Lists/LKS/LKSDibantu";
import Etalase from "examples/Lists/Konten/Etalase";

function Layout() {
  return (
    <div className="container">
      <UIAlertGlobal />
      <Outlet />
    </div>
  );
}

function PrivateRoute() {
  const { auth } = useContext(AuthContext);
  const { state } = useLocation();

  if (!auth.id || auth.id === null) {
    return <Navigate to="/" />;
  }
  // else if (
  // auth &&
  // auth.id &&
  // auth.konsep_video.ads === false &&
  // auth.konsep_video.freestyle === false &&
  // auth.konsep_video.duet === false &&
  // auth.konsep_video.slideShow === false &&
  // auth.konsep_video.storyTelling === false
  // ) {
  // return <Navigate to={state?.from ? -1 : "/konsep-video"} />;
  // }

  return <Outlet />;
}

function PublicRoute() {
  const { auth } = useContext(AuthContext);
  const { state } = useLocation();

  if (auth && auth.id && Object.values(auth.konsep_video).includes(true)) {
    return <Navigate to={state?.from ? -1 : "/dashboard"} />;
  } else if (
    auth &&
    auth.id &&
    auth.konsep_video.ads === false &&
    auth.konsep_video.freestyle === false &&
    auth.konsep_video.duet === false &&
    auth.konsep_video.slideShow === false &&
    auth.konsep_video.storyTelling === false
  ) {
    return <Navigate to={state?.from ? -1 : "/konsep-video"} />;
  }

  return <Outlet />;
}

function KonsepVideoRoute() {
  const { auth } = useContext(AuthContext);

  if (
    auth.konsep_video.ads === false &&
    auth.konsep_video.freestyle === false &&
    auth.konsep_video.duet === false &&
    auth.konsep_video.slideShow === false &&
    auth.konsep_video.storyTelling === false
  )
    return <Navigate to={"/konsep-video"} />;

  return <Outlet />;
}

function NotFound() {
  const push = useNavigate();
  const { state } = useLocation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">Halaman yang anda masukkan tidak ada.</Typography>
            <MDButton
              mt={2}
              onClick={() => push(state?.from ? -1 : "/dashboard")}
              variant="gradient"
              color="inherit"
              type="submit"
              sx={{
                backgroundColor: "#bd454f",
              }}
            >
              Kembali ke Beranda
            </MDButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

// !export app()
export default function App() {
  const [controller] = useMaterialUIController();
  const { direction } = controller;
  const { pathname } = useLocation();
  const { auth } = useContext(AuthContext);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<PublicRoute />}>
            <Route path="" element={<SignIn />} />
            <Route path="login" element={<SignIn />} />
            <Route path="register" element={<SignUp />} />
            <Route path="change-password" element={<ChangePasswordPage />} />
            <Route path="verification" element={<VerificationPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="" element={<PrivateRoute />}>
            <Route path="konsep-video" element={<KonsepVideo />} />
            <Route path="" element={<KonsepVideoRoute />}>
              {/* {getRoutes(routes)} */}
              <Route path="" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="konten/penawaran" element={<TawaranKonten />} />
              <Route path="konten/isi-link" element={<IsiLinkKonten />} />
              <Route path="konten/selesai" element={<KontenSelesai />} />
              <Route path="konten/etalase" element={<Etalase />} />
              <Route path="lks/dibantu" element={<LKSDibantu />} />
              <Route path="lks/gabungan" element={<LKS2 />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
