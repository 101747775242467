// @mui material components
import { NavLink } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import IsiLink from "layouts/billing/components/Bill/isiLink";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Icon
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContentList } from "context/content";
import { useEffect } from "react";
import { Alert, Box, CircularProgress, Skeleton } from "@mui/material";

function IsiLinkKonten() {
  const { onProcess, loading, res, data } = useContentList();

  useEffect(() => {
    onProcess("paid");
  }, [onProcess]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox id="delete-account">
        <MDBox mt={2}>
          <MDTypography variant="h5" fontWeight="medium">
            <MDButton
              variant="outlined"
              size="small"
              color="primary"
              component={NavLink}
              to="/dashboard"
              iconOnly
            >
              <ArrowBackIcon />
            </MDButton>
            &nbsp; Isi Link Konten
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {loading ? (
              // <CircularProgress style={{ margin: 5 }} size={25} />
              <>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                </Box>
              </>
            ) : res.message || data.data.length === 0 ? (
              <Alert severity={res.message ? "error" : "warning"}>{res.message || "No Data"}</Alert>
            ) : (
              data.data.map((result, index) => {
                return (
                  <IsiLink
                    key={index}
                    kontenID={result.id}
                    name={result.User.username}
                    komisi={result.commission}
                    notes={result.note}
                    linkProduct={result.url_product}
                    paid={result.paid}
                    tanggal={result.createdAt}
                    cod={result.Addon ? result.Addon.cod : false}
                    contohLink={result.Addon?.contoh_video ? result.Addon.contoh_video : ""}
                  />
                );
              })
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default IsiLinkKonten;
