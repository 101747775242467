// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import MDButton from "components/MDButton";
import { Close, WhatsApp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useCountAll } from "context/content";
// import { useContentList } from "context/content";

function Dashboard() {
  const [openDialog, setOpenDialog] = useState(false);
  const { onProcess, loading, res, data } = useCountAll();
  const [isi, setIsi] = useState("");
  // const [ske, setSke] = useState(true);

  async function handleSubmit(e) {
    e.preventDefault();
    const webhookUrl =
      "https://discord.com/api/webhooks/1103250265752211466/YBtdXRPm4WSoWnYHsA_O-QUefbqUVOO_MEPTgZXmJJ9z-jNwAHtiK9-cJMWKVbvyw3Mx";
    const data = { content: isi };

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      console.log("Sent message to Discord: ", isi);
    } catch (error) {
      console.error("Failed to send message to Discord: ", error);
    }
  }

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mt={2}
        sx={{ display: "flex", justifyContent: "center", boxShadow: 5, borderRadius: 5 }}
      >
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={120} />
        ) : (
          <Card sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", py: 1 }}>
              <Grid container>
                <Grid item xs={4} md={4} lg={4}>
                  <NavLink to="/konten/selesai">
                    <MDBox>
                      <ComplexStatisticsCard
                        title="Konten"
                        title2="Selesai"
                        count={loading ? <CircularProgress /> : data.konten_selesai}
                      />
                    </MDBox>
                  </NavLink>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <NavLink to="/lks/dibantu">
                    <MDBox
                      sx={{
                        borderRight: 1,
                        borderLeft: 1,
                      }}
                    >
                      <ComplexStatisticsCard
                        title="Bantuan"
                        title2="Konten"
                        count={loading ? <CircularProgress /> : data.total_dibantu}
                      />
                    </MDBox>
                  </NavLink>
                </Grid>

                <Grid item xs={4} md={4} lg={4}>
                  <NavLink to="/konten/etalase">
                    <MDBox>
                      <ComplexStatisticsCard
                        title="Etalase"
                        title2=""
                        count={loading ? <CircularProgress /> : data.display}
                      />
                    </MDBox>
                  </NavLink>
                </Grid>

                {/* <Grid item xs={4} md={4} lg={4}>
                  <MDBox onClick={() => setOpenDialog(true)}>
                    <ComplexStatisticsCard
                      title="Etalase"
                      title2=""
                      count={loading ? <CircularProgress /> : "0"}
                    />
                  </MDBox>
                  <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Coming Soon!"}
                      <IconButton
                        aria-label="close"
                        onClick={() => setOpenDialog(false)}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <Close />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Jika Ada Pertanyaan Terkait Fitur "Seller Kamu" silahkan tanya Admin
                        JualKirim Melalui Tombol WhatsApp dibawah ini.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <MDButton
                        color="success"
                        onClick={() => {
                          window.open(
                            `https://api.whatsapp.com/send?phone=6285774379459&text=${encodeURIComponent(
                              `Hallo kak, saya mau tanya`
                            )}`,
                            "_blank"
                          );
                        }}
                      >
                        <WhatsApp />
                      </MDButton>
                    </DialogActions>
                  </Dialog>
                </Grid> */}
              </Grid>
            </Box>
          </Card>
        )}
      </MDBox>
      <Divider />
      <MDBox sx={{ display: "flex", justifyContent: "center" }}>
        <MDBox sx={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={6}>
              <MDBox>
                {loading ? (
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                ) : (
                  <ReportsBarChart
                    title={loading ? <CircularProgress /> : data.pending}
                    description="Tawaran Konten"
                    name="Klik Disini"
                  />
                )}
              </MDBox>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <MDBox>
                {loading ? (
                  <Skeleton variant="rectangular" width="100%" height="135px" />
                ) : (
                  <ReportsBarChart
                    title={loading ? <CircularProgress /> : data.accepted}
                    description="Isi Link Konten"
                    name="Klik Disini"
                  />
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox sx={{ display: "flex", justifyContent: "center", boxShadow: 5, borderRadius: 5 }}>
        <MDBox sx={{ width: "100%" }}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={60} />
              ) : (
                <NavLink to="/lks/gabungan">
                  <Badge badgeContent={data.semuaLKS} max={99} color="error" sx={{ width: "100%" }}>
                    <Button variant="contained" color="error" sx={{ width: "100%" }}>
                      Like, Komen, Share
                    </Button>
                  </Badge>
                </NavLink>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {/* <form onSubmit={handleSubmit}>
        <input type="text" value={isi} onChange={(e) => setIsi(e.target.value)} />
        <button type="submit">Submit</button>
      </form> */}
    </DashboardLayout>
  );
}

export default Dashboard;
