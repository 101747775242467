import moment from "moment";

export function getQueryUrl(query) {
  try {
    if (/[&?=]/.test(String(query).toLowerCase())) {
      let result = {};
      query
        .split("?")[1]
        .split("&")
        .forEach((el, i) => (result[el.split("=")[0]] = el.split("=")[1]));
      return result;
    }
  } catch (e) {}
  return false;
}

export const convertDataToQuery = (data) => {
  if (data) {
    const obj = Object.keys(data);
    let query = ``;
    for (let i = 0; i < obj.length; i++) {
      if (query.length === 0) {
        query += `?${obj[i]}=${data[obj[i]]}`;
        continue;
      }
      query += `&${obj[i]}=${data[obj[i]]}`;
    }

    return query;
  }
  return "";
};

export const convertDate = (_date) => {
  if (!_date) return { date: "-", time: "-" };
  const _d = (() => {
      const stringDate = _date.toString();

      if (stringDate.length > 10 && !/\./i.test(stringDate))
        return new Date(typeof _date === "number" ? _date : _date);

      return new Date(typeof _date === "number" ? _date * 1000 : _date);
    })(),
    date = moment(_d).format("DD-MM-YYYY"),
    time = _d.toLocaleTimeString();

  return { date, time };
};

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export const replaceUrl = (text) => {
  const entering = text.replace(/\n/g, (txt) => `${txt}<br/>`);
  return entering.replace(
    /(https?:\/\/[^\s]+)/g,
    (url) => `<a href='${url}' target='_blank'>${url}</a>`
  );
};

export function getStatus(data) {
  const { approval_status, payment_status = false, expired_date, paid = false } = data;

  if (approval_status === "accepted") {
    if (payment_status) return "paid";
    return "Menunggu Pembayaran";
  }

  if (approval_status === "done") {
    if (paid && Math.floor(+new Date() / 1000) > expired_date) return "expired";
    return "active";
  }

  if (approval_status === "pending-promoted") {
    if (paid && Math.floor(+new Date() / 1000) > expired_date) return "";
    return "Menunggu Persetujuan";
  }

  return approval_status;
}

export function checkExpiration(date) {
  const _date = new Date(typeof date === "number" ? date * 1000 : date),
    moments = moment(_date).endOf("hour").fromNow(),
    expire = /ago/i.test(moments),
    convert = convertDate(date);

  let d = new Date();
  d.setDate(d.getDate() + 5);

  return {
    expire,
    status: expire
      ? "expire"
      : Math.floor(_date / 1000) < Math.floor(+d / 1000)
      ? "almost"
      : "active",
    convert,
    moments,
  };
}

export function rupiah(angka = 0) {
  var rupiah = "";
  var angkarev = angka.toString().split("").reverse().join("");
  for (var i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + ".";
  return (
    "Rp. " +
    rupiah
      .split("", rupiah.length - 1)
      .reverse()
      .join("")
  );
}

export function convertHari(tanggal) {
  let date = new Date(tanggal * 1000);
  console.log(date.getDay());
  const jam = new Date(tanggal * 1000).toLocaleTimeString();

  const year = date.getFullYear();
  let nameOfTheMonth = date.getMonth() + 1;

  if (nameOfTheMonth === 1) nameOfTheMonth = "Januari";
  if (nameOfTheMonth === 2) nameOfTheMonth = "Februari";
  if (nameOfTheMonth === 3) nameOfTheMonth = "Maret";
  if (nameOfTheMonth === 4) nameOfTheMonth = "April";
  if (nameOfTheMonth === 5) nameOfTheMonth = "Mei";
  if (nameOfTheMonth === 6) nameOfTheMonth = "Juni";
  if (nameOfTheMonth === 7) nameOfTheMonth = "Juli";
  if (nameOfTheMonth === 8) nameOfTheMonth = "Agustus";
  if (nameOfTheMonth === 9) nameOfTheMonth = "September";
  if (nameOfTheMonth === 10) nameOfTheMonth = "Oktober";
  if (nameOfTheMonth === 11) nameOfTheMonth = "November";
  if (nameOfTheMonth === 12) nameOfTheMonth = "Desember";

  const day = date.getDate();
  let nameOfTheDay = date.getDay();

  if (nameOfTheDay === 1) nameOfTheDay = "Senin";
  if (nameOfTheDay === 2) nameOfTheDay = "Selasa";
  if (nameOfTheDay === 3) nameOfTheDay = "Rabu";
  if (nameOfTheDay === 4) nameOfTheDay = "Kamis";
  if (nameOfTheDay === 5) nameOfTheDay = "Jum'at";
  if (nameOfTheDay === 6) nameOfTheDay = "Sabtu";
  if (nameOfTheDay === 0) nameOfTheDay = "Minggu";

  return `${nameOfTheDay}, ${day} ${nameOfTheMonth} ${year}, ${jam}`;
}
