import React, { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Modal from "@mui/material/Modal";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { provinceWithCityWithSubdistrict, cityIndex } from "helper/address.jsx";
import { useActionCreateSampleRequest } from "context/content";

import { GlobalContext } from "context/utils/global";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ModalSample({ open, setOpen, result, setAfterRequest }) {
  const { loading, onProcess } = useActionCreateSampleRequest();
  const [values, setValues] = React.useState({
    province_id: "",
    province: "",
    city_id: "",
    city_type: "",
    city_name: "",
    city_index: "",
    subdistrict_id: "",
    subdistrict_name: "",
    kol_address: "",
    phone_number: "",
  });

  function handleChange(type, data) {
    if (type === "province") {
      if (values.province_id) setValues({ ...values, province_id: "" });

      setTimeout(() => {
        setValues({
          kol_address: "",
          phone_number: "",
          city_id: "",
          city_type: "",
          city_name: "",
          city_index: "",
          subdistrict_id: "",
          subdistrict_name: "",
          province: data.province,
          province_id: data.province_id,
        });
      }, 10);
    }

    if (type === "city") {
      if (values.province_id && values.city_id) setValues({ ...values, city_id: "" });

      const findCityIndex = cityIndex(
        provinceWithCityWithSubdistrict[values?.province_id - 1]?.cities,
        data?.city_id
      );

      setTimeout(() => {
        setValues({
          ...values,
          city_id: data.city_id,
          city_type: data.type,
          city_name: data.city_name,
          city_index: findCityIndex,
          subdistrict_id: "",
          subdistrict_name: "",
          kol_address: "",
          phone_number: "",
        });
      }, 10);
    }

    if (type === "subdistrict") {
      if (values.province_id && values.city_id && values.subdistrict_id)
        setValues({ ...values, subdistrict_id: "" });

      setTimeout(() => {
        setValues({
          ...values,
          subdistrict_id: data.subdistrict_id,
          subdistrict_name: data.subdistrict_name,
          kol_address: "",
          phone_number: "",
        });
      }, 10);
    }

    if (type === "address") {
      setValues({
        ...values,
        kol_address: data,
      });
    }

    if (type === "phone_number") {
      setValues({
        ...values,
        phone_number: data,
      });
    }
  }

  function createSampleRequest() {
    onProcess({
      ...values,
      url_product: result.display_link_product,
      seller_id: result.user_id,
      note: result.item_notes,
      commission: result.item_commission,
      cod: result.item_cod,
      DisplayId: result.id,
    });

    setTimeout(() => {
      setOpen(false);
    }, 1000);
    setTimeout(() => {
      setAfterRequest("Create Sample Succes");
      setAlertMessage("Pengajuan Sampel Berhasil");
      setOpenAlert(true);
    }, 300);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        setValues({
          province_id: "",
          province: "",
          city_id: "",
          city_type: "",
          city_name: "",
          subdistrict_id: "",
          subdistrict_name: "",
        });
        setOpen(false);
      }}
    >
      <MDBox sx={style} textAlign="-webkit-center">
        <MDTypography> isi Alamat Untuk Pengiriman Sample </MDTypography>
        <Autocomplete
          disablePortal
          id="combo-box-province"
          options={provinceWithCityWithSubdistrict}
          getOptionLabel={(option) => option.province}
          sx={{ width: 300, mb: 1, mt: 2 }}
          renderInput={(params) => <TextField {...params} label="Provinsi" />}
          onChange={(e, newValue) => {
            handleChange("province", newValue);
          }}
        />
        {values?.province_id ? (
          <Autocomplete
            disabled={!values.province_id}
            disablePortal
            id="combo-box-city"
            options={provinceWithCityWithSubdistrict[values?.province_id - 1]?.cities}
            getOptionLabel={(option) => `${option.type} ${option.city_name}`}
            sx={{ width: 300, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Kota/ Kabupaten" />}
            onChange={(e, newValue) => {
              handleChange("city", newValue);
            }}
          />
        ) : null}

        {values?.province_id && values?.city_id ? (
          <Autocomplete
            disabled={!values?.city_id}
            disablePortal
            id="combo-box-city"
            options={
              provinceWithCityWithSubdistrict[values?.province_id - 1]?.cities[values?.city_index]
                ?.subdistricts
            }
            getOptionLabel={(option) => option.subdistrict_name}
            sx={{ width: 300, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Kecamatan" />}
            onChange={(e, newValue) => {
              handleChange("subdistrict", newValue);
            }}
          />
        ) : null}

        {values?.province_id && values?.city_id && values?.subdistrict_id ? (
          <>
            <TextField
              label="Alamat"
              multiline
              placeholder="Nama Jalan, No. Rumah, Kelurahan/ Desa (Bila Perlu)"
              sx={{ width: 300, mb: 1 }}
              onChange={(e) => {
                handleChange("address", e.target.value);
              }}
            />
            <TextField
              label="No Kontak"
              placeholder="No Telepon/ HP yang Bisa Dihubungi"
              sx={{ width: 300, mb: 1 }}
              onChange={(e) => {
                handleChange("phone_number", e.target.value);
              }}
            />
            <MDButton color="error" onClick={() => createSampleRequest()}>
              {" "}
              Ajukan Sample{" "}
            </MDButton>
          </>
        ) : null}
      </MDBox>
    </Modal>
  );
}
