import { useEffect, useState } from "react";

// react-router-dom components
import { Link, NavLink } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import LogoSign from "./logo-navbar-jualkirim.png";
import { useLogin } from "context/auth";
import { SignalCellularNull, Visibility, VisibilityOff } from "@mui/icons-material";
import { useForgotPassword } from "context/auth";
import { useRetryVerification } from "context/auth";

function Basic() {
  const { data, onChange, res, loading, onProcess } = useLogin();
  const { onProcess: forgotPass, loading: loadForgot, res: resForgot } = useForgotPassword();
  const { onProcess: retrySend, loading: loadRetry } = useRetryVerification();
  const [show, setShow] = useState(false);
  const [retry, setRetry] = useState(false);
  const [forgot, setForgot] = useState(false);

  useEffect(() => {
    if (resForgot.status === "ok" && resForgot.title === "Lupa Password") setForgot(false);
  }, [resForgot]);

  useEffect(() => {
    if (res.message) {
      if (/kirim ulang/i.test(res.message)) setRetry(true);
      else setRetry(false);
    }
  }, [res.message]);

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="default"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          sx={{
            bgcolor: "#bd454f",
          }}
        >
          <Grid container justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <MDTypography component={MuiLink} variant="h6" color="white" noWrap>
              <img alt="jualkirim Logo" src={LogoSign} alignItems="center" />
            </MDTypography>
          </Grid>
          <MDTypography variant="h4" fontWeight="medium" color="white">
            {forgot ? "Lupa Password" : "Login Page"}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="small" color="white">
            {forgot
              ? "Silahkan Masukkan Identitas untuk dikirimkan Link"
              : "Silahkan Masukkan Email & Password untuk Lanjut"}
          </MDTypography>
        </MDBox>
        <MDBox pt={5} pb={3} px={3}>
          <form className="form-input" onSubmit={forgot ? forgotPass(data.request) : onProcess}>
            <MDBox component="form" role="form">
              <MDBox mb={3}>
                <TextField
                  name="request"
                  label="Email / Phone Number"
                  placeholder="eg: jualkirim@example.com"
                  fullWidth
                  error={res.status === "error" && /email/i.test(res.message) === true}
                  InputProps={{
                    startAdornment:
                      data.request && !isNaN(data.request) ? (
                        <InputAdornment position="start">
                          <Typography variant="caption" color="black" fontWeight="bolder">
                            +62
                          </Typography>
                        </InputAdornment>
                      ) : null,
                  }}
                  onChange={onChange}
                  value={data.request}
                />
              </MDBox>
              {!forgot ? (
                <MDBox mb={3}>
                  <TextField
                    label="Password"
                    fullWidth
                    name="password"
                    type={show ? "text" : "password"}
                    onChange={onChange}
                    value={data.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            variant="standard"
                            onClick={(_) => setShow((prev) => !prev)}
                          >
                            {show ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="eg: pass"
                  />
                </MDBox>
              ) : null}
              <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                {!forgot ? (
                  <MDTypography
                    variant="caption"
                    fontWeight="medium"
                    textGradient
                    color="info"
                    onClick={() => setForgot(true)}
                  >
                    Lupa Password
                  </MDTypography>
                ) : (
                  <MDTypography
                    variant="caption"
                    fontWeight="medium"
                    textGradient
                    color="info"
                    onClick={() => setForgot(false)}
                  >
                    Kembali Login
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="inherit"
                  fullWidth
                  type="submit"
                  sx={{
                    backgroundColor: "#bd454f",
                  }}
                  onClick={forgot ? forgotPass(data.request) : onProcess}
                >
                  {loading || loadForgot ? (
                    <CircularProgress size={20} />
                  ) : !forgot ? (
                    "Login"
                  ) : (
                    "Kirim"
                  )}
                </MDButton>

                {retry ? (
                  <MDButton
                    mt={2}
                    variant="text"
                    color="error"
                    fullWidth
                    onClick={retrySend(data.request, (ok) => (ok ? setRetry(false) : null))}
                  >
                    {loadRetry ? <CircularProgress size={25} /> : "Kirim Ulang"}
                  </MDButton>
                ) : null}
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  klik{" "}
                  <MDTypography
                    component={Link}
                    to="/register"
                    variant="button"
                    fontWeight="medium"
                    textGradient
                    color="info"
                  >
                    Daftar{" "}
                  </MDTypography>
                  Jika anda KOL Baru!
                </MDTypography>
              </MDBox>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
