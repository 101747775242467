// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider } from "@mui/material";

function ComplexStatisticsCard({ title, title2, count }) {
  return (
    <MDBox
      display="flex"
      flexDirection={{ xs: "column" }}
      justifyContent={{ md: "space-between" }}
      alignItems={{ xs: "center" }}
      pt={1}
      px={2}
    >
      <MDBox lineHeight={0.5} mb={1} mt={1}>
        <MDTypography textAlign="center" variant="h3">
          {count}
        </MDTypography>
        <Divider variant="fullwidht" />
        <MDTypography
          textAlign="center"
          fontSize={{ xs: "3.1vw", sm: "15px", md: "15px" }}
          fontWeight="medium"
          variant="h5"
        >
          {title}<br /> {title2}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of ComplexStatisticsCard
// ComplexStatisticsCard.defaultProps = {
//   color: "info",
//   percentage: {
//     color: "success",
//     text: "",
//     label: "",
//   },
// };

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
};

export default ComplexStatisticsCard;
